import React from 'react';

import { DateRangePicker, OnChangeProps, Range, RangeFocus } from 'react-date-range';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Action } from 'redux';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { Radio } from 'antd';
import { ThunkDispatch } from 'redux-thunk';
import { isEmpty } from 'lodash';
import { enUS } from 'date-fns/esm/locale'
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import addMonths from 'date-fns/addMonths';
import subWeeks from 'date-fns/subWeeks';
import subDays from 'date-fns/subDays';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';
import setMonth from 'date-fns/setMonth';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

import {
  getStayForText,
  getSelectedMonthText,
  events,
  Responsive,
  getAccountLeadTime
} from '@share/utils';
import {
  compare,
  formatDateCheckInOut,
  getDateCheckInOut,
  getDateCheckInOutISOString,
  getPreferenceFromAccount,
  UrlUtils,
} from '@share/utils';
import { BlueButton, MonthSelect } from '@share/components';
import {
  condoFlexibleDatePickerActions,
  condosActions,
  condoStrictDatesActions,
  ICondoDetailsState,
  ICondoFlexibleDatePickerState,
  ICondosState,
  ICondoStrictDatesState,
} from '@share/store/slices';
import { RootState } from '@share/utils';
import {
  StayForEnum,
} from '@share/common-types';
import {
  ICondoCheckinStatuses,
  ICondoDaysToChek,
  CustomEvents,
} from '@share/common-types';
import {
  DateSearchTypeEnum,
  MonthsEnum,
  SEARCH_ICONS_COLOR_FIELD_KEY,
} from '@share/common-types';
import {
  CONDO_IS_FLEXIBLE_LABEL,
  DATE_FORMAT,
  DEFAULT_MEDIA_POINT,
  MONTHS,
  DATE_ONLY_MONTH_FORMAT,
} from '@share/constants';
import { ILoginState } from '@share/store/slices';

import { getAccountDateFnsLocale } from '@i18n';

import {
  ArrowLeftSvg,
  ArrowRightSvg,
  CloseSvg,
  GreyLoaderSvg,
  LightSvg,
} from '@share/assets';

import { CondoFlexibleDatePicker, CondoFlexibleDatePickerComponent } from '../condo-flexible-date-picker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import './style.scss';


interface IMapStateToProps {
  condoDetailsStore: ICondoDetailsState;
  condoStrictDatesStore: ICondoStrictDatesState;
  condoFlexibleDatePickerStore?: ICondoFlexibleDatePickerState;
  condosStore?: ICondosState;
  loginStore: ILoginState;
  isSearch: boolean;
}

interface IMapDispatchToProps {
  setDates: (dates: { startDate: Date; endDate: Date }) => void;
  setDatesExperiences: (dates: { startDate: Date; endDate: Date }) => void;
  clearError: () => void;
  setSearchType: (type: DateSearchTypeEnum) => void;
  setSearchTypeExperiences: (type: DateSearchTypeEnum) => void;
  resetDates: (value?: boolean) => void;
  resetFlexibleState: () => void;
  setIsFocusDatePicker?: (bool: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  matches?: boolean;
  isExperiences?: boolean;
  isFromDetails?: boolean;
  isShortStaysSupported?: boolean;

  getCheckinStatus?: (start: string, end: string) => void;

  selectMonths?: (month: string) => void;
  selectMonthsExperiences?: (month: string) => void;
}

interface IState {
  isFocused: boolean;
  startDate: Date;
  endDate: Date;
  key: string;
  isEndDateSelected: boolean;
  focusedRange: RangeFocus;
  isMobile: boolean;
  isInitial: boolean;
  isStartedText: boolean;
}

const zero = 0;
const oneItem = 1;
const seven = 7;
const six = 6;
const resizeDebounceTime = 300;
const mobileMonthCount = 12;
const maxDateMonths = 24;
const maxRangeWeeks = 8;
const fullMonthCount = 2;
const defaultRange = 0;
const isStart = 0;
const percentsForFriday = 50;
const percentsForSaturday = 33.3;
const percentsForSunday = 16.6;
const isResetDates = false;
const emptyDatePartDays = 'T00:00:00.000Z';
const monthOffset = 350;
const startTime = 'T00:00:01.000Z';
const isoFormat = 'yyyy-MM-dd';

export class CondoStrictDatePickerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  changeShownDate: (count: number | Date, type: string) => void;
  isInitial = true;
  callShowOptimalWeekDates = false;
  statuses: ICondoCheckinStatuses;
  lastCheckinEndDate = '';
  isChangeSearchType = false;

  constructor(props: IProps) {
    super(props);

    const startDate = props.condoStrictDatesStore.startDate
      ? getDateCheckInOut(props.condoStrictDatesStore.startDate)
      : new Date();
    const endDate = props.condoStrictDatesStore.endDate
      ? getDateCheckInOut(props.condoStrictDatesStore.endDate)
      : new Date();

    this.state = {
      isFocused: false,
      startDate,
      endDate,
      key: props.condoStrictDatesStore.key,
      isEndDateSelected: true,
      focusedRange: [defaultRange, isStart],
      isMobile: document.body.offsetWidth <= DEFAULT_MEDIA_POINT,
      isInitial: true,
      isStartedText: false,
    };
    const { condoDetailsStore } = this.props;
    this.statuses = condoDetailsStore.statuses;
  }

  getSearchTypeOptions = (): LabeledValue[] => {
    const options = [
      { value: DateSearchTypeEnum.Flexible, label: <FormattedMessage id="flexible.dates" /> },
      { value: DateSearchTypeEnum.Strict, label: <FormattedMessage id="specific.dates" /> },
    ];

    return options;
  };

  static getDerivedStateFromProps(props: IProps, state: IState): IState {
    const { startDate, endDate } = props.condoStrictDatesStore;
    const stateStart = getDateCheckInOutISOString(state.startDate);
    const stateEnd = getDateCheckInOutISOString(state.endDate);

    if (
      state.isInitial &&
      startDate &&
      endDate &&
      startDate !== stateStart &&
      endDate !== stateEnd
    ) {
      const start = getDateCheckInOut(startDate);
      const end = getDateCheckInOut(endDate);

      return {
        ...state,
        startDate: start,
        endDate: end,
        isInitial: false,
      };
    }

    if (!startDate && !endDate && !state.isInitial && state.isEndDateSelected) {
      return {
        ...state,
        startDate: new Date(),
        endDate: new Date(),
        isInitial: true,
        focusedRange: [defaultRange, isStart],
        isEndDateSelected: true,
      };
    }

    return null;
  }

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true, isStartedText: false });
    }
  };

  blur = (): void => {
    const { isFocused, startDate, endDate } = this.state;

    if (isFocused) {
      if (!this.state.isEndDateSelected && startDate.toISOString() === endDate.toISOString()) {
        this.setState(
          {
            startDate,
            endDate: addDays(new Date(startDate), seven),
          },
          this.updateProps,
        );
      }

      this.setState({
        isFocused: false,
        isEndDateSelected: true,
        focusedRange: [defaultRange, isStart],
      });
    }
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      (this.wrapperRef && !this.wrapperRef.current.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('condo-strict-date-picker__fixed-bg')
    ) {
      this.blur();
    }
  };

  onResize = debounce(() => {
    if (document.body.offsetWidth <= DEFAULT_MEDIA_POINT && !this.state.isMobile) {
      const { startDate, endDate } = this.state;
      this.setState({ isMobile: true, startDate: new Date(), endDate: new Date() });

      setTimeout(() => {
        this.setState({ startDate, endDate });
      });
    } else if (document.body.offsetWidth > DEFAULT_MEDIA_POINT && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }, resizeDebounceTime);

  getClosestMonth = (
    scrollTop: number,
    months: NodeListOf<HTMLDivElement>,
  ): HTMLDivElement | null => {
    if (months && months.length) {
      const nums: number[] = [];
      let index = zero;

      months.forEach((month: HTMLDivElement) => {
        nums.push(month.offsetTop - scrollTop - monthOffset);
      });

      nums.sort(compare);

      for (let i = zero; i < nums.length; i++) {
        if (nums[i] >= zero) {
          index = i;
          break;
        }
      }

      return months[index];
    }

    return null;
  };

  onWrapperScroll = debounce((e) => {
    const { getCheckinStatus } = this.props;
    const months = document.querySelectorAll('.rdrMonth');
    const { target } = e;

    if (target) {
      const { scrollTop } = target as HTMLDivElement;
      const month = this.getClosestMonth(scrollTop, months as NodeListOf<HTMLDivElement>);

      if (month) {
        const monthName: HTMLDivElement = month.querySelector('.rdrMonthName');

        if (monthName) {
          const { innerText } = monthName;
          const [monthLatinName, year] = innerText.split(', ');
          const monthObj = MONTHS.find(({ name }) => {
            return name === monthLatinName;
          });

          const current = new Date(`${year}-${monthObj.number}`);
          const latest = new Date(this.lastCheckinEndDate);

          if (isAfter(current, latest)) {
            getCheckinStatus(
              `${format(current, 'yyyy-MM-dd')}${emptyDatePartDays}`,
              `${format(
                subDays(addMonths(current, oneItem), oneItem),
                'yyyy-MM-dd',
              )}${emptyDatePartDays}`,
            );
          }
        }
      }
    }
  }, zero);

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.onResize);
    this.showOptimalDatesTip();

    if (this.props.isFromDetails) {
      const pickerWrapper = document.querySelector('.condo-strict-date-picker__picker');

      if (pickerWrapper) {
        pickerWrapper.addEventListener('scroll', this.onWrapperScroll);
      }

      if (this.statuses) {
        this.renderCheckinDates();
      }

      events.on(CustomEvents.CheckinStatusesUpdated, this.onCheckinStatusesChange);
      events.on(CustomEvents.CondoStartEndDateChanged, this.onStartEndDateChange);
      events.on(CustomEvents.CheckinStatusesLoading, this.onCheckinStatusesLoading);
      events.on(CustomEvents.LastCheckinEndDate, this.setLastCheckinEndDate);
    }

    const values = UrlUtils.getValues();

    if (!values[CONDO_IS_FLEXIBLE_LABEL]) {
      this.setState({ isStartedText: true });
    }
  }

  setLastCheckinEndDate = (endDate: string): void => {
    this.lastCheckinEndDate = endDate;
  };

  onCheckinStatusesLoading = (payload: boolean): void => {
    const datePicker: HTMLDivElement = document.querySelector('.condo-strict-date-picker');
    const months: HTMLDivElement = document.querySelector('.rdrMonths');
    const wrapper: HTMLDivElement = document.querySelector(
      '.condo-strict-date-picker__loader-disable',
    );
    const loadingClass = 'loading';

    if (datePicker && months && wrapper) {
      if (payload) {
        datePicker.classList.add(loadingClass);
        wrapper.style.height = `${months.offsetHeight}px`;
      } else {
        datePicker.classList.remove(loadingClass);
        wrapper.style.height = `${zero}`;
      }
    }
  };

  onStartEndDateChange = ({ from, to }: { from: string; to: string }): void => {
    if (this.props.isFromDetails && from && to) {
      this.setState({
        startDate: getDateCheckInOut(from),
        endDate: getDateCheckInOut(to),
      });
    }
  };

  onCheckinStatusesChange = (): void => {
    const { condoDetailsStore } = this.props;
    this.statuses = condoDetailsStore.statuses;
    this.renderCheckinDates();
  };

  getPercentLineNode = (percents: number, additionalClass = 'default'): Node => {
    const div = document.createElement('div');
    div.classList.add('percent-line');
    div.classList.add(additionalClass);
    div.innerHTML = `
      <div class="percent-line__line" style="width: ${percents}%;" />
    `;

    return div;
  };

  showOptimalDatesForWeek = (): void => {
    if (this.props.isFromDetails) {
      return;
    }

    const start = document.querySelector('.rdrStartEdge');
    this.clearWeerPercents();

    if (start && !this.state.isEndDateSelected) {
      const days = document.querySelectorAll('.rdrDay');
      let parentIndex = 0;

      days.forEach((node: HTMLElement, index: number) => {
        if (!!node.querySelector('.rdrStartEdge')) {
          parentIndex = index;
        }
      });

      parentIndex++;
      let resultSibling;
      let prevResultSibling;
      let i = 1;
      let j = 0;
      const max = 30;

      while (i < seven && j < max) {
        j++;
        parentIndex++;
        const sibling = days[parentIndex];

        if (
          sibling &&
          !sibling.classList.contains('rdrDayPassive') &&
          !sibling.classList.contains('rdrDayDisabled')
        ) {
          i++;

          if (i === seven) {
            resultSibling = sibling;
          }

          if (i === six) {
            prevResultSibling = sibling;
          }
        }
      }

      if (resultSibling) {
        resultSibling.appendChild(this.getPercentLineNode(percentsForSaturday, 'week'));
        prevResultSibling.appendChild(this.getPercentLineNode(percentsForSunday, 'week'));
      } else if (this.changeShownDate) {
        this.changeShownDate(+oneItem, 'monthOffset');
        this.callShowOptimalWeekDates = true;
      }
    }
  };

  componentDidUpdate(): void {
    const { condosStore, setIsFocusDatePicker } = this.props;
    const values = UrlUtils.getValues();

    if (values[CONDO_IS_FLEXIBLE_LABEL] && this.state.isStartedText) {
      this.setState({ isStartedText: false });
    }

    if (this.callShowOptimalWeekDates) {
      this.showOptimalDatesForWeek();
      this.callShowOptimalWeekDates = false;
    }

    if (!isUndefined(condosStore) && condosStore.isFocusDatePicker && !this.state.isFocused) {
      this.setState({ isFocused: condosStore.isFocusDatePicker, isStartedText: false });
      setIsFocusDatePicker(false);
    }
  }

  handleSearchType = (searchType: DateSearchTypeEnum) => {
    const { isExperiences, setSearchTypeExperiences, setSearchType } = this.props;
    if (isExperiences) {
      setSearchTypeExperiences(searchType);
    } else {
      setSearchType(searchType);
    }
  }

  showOptimalDatesTip = (): void => {
    if (this.props.isFromDetails) {
      return;
    }

    const sundays = document.querySelectorAll('.rdrDayEndOfWeek');

    sundays.forEach((sunday) => {
      const saturday: HTMLElement = (sunday as HTMLElement)?.previousSibling as HTMLElement;
      const friday: HTMLElement = saturday?.previousSibling as HTMLElement;

      friday.appendChild(this.getPercentLineNode(percentsForFriday));
      saturday.appendChild(this.getPercentLineNode(percentsForSaturday));
      sunday.appendChild(this.getPercentLineNode(percentsForSunday));
    });
  };

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.onResize);

    if (this.props.isFromDetails) {
      const pickerWrapper = document.querySelector('.condo-strict-date-picker__picker');

      if (pickerWrapper) {
        pickerWrapper.removeEventListener('scroll', this.onWrapperScroll);
      }

      events.off(CustomEvents.CheckinStatusesUpdated, this.onCheckinStatusesChange);
      events.off(CustomEvents.CondoStartEndDateChanged, this.onStartEndDateChange);
      events.off(CustomEvents.CheckinStatusesLoading, this.onCheckinStatusesLoading);
      events.off(CustomEvents.LastCheckinEndDate, this.setLastCheckinEndDate);
    }
  }

  onDone = (): void => {
    this.blur();
  };

  clearWeerPercents = (): void => {
    const prev = document.querySelectorAll('.percent-line.week');

    prev.forEach((node) => node.remove());
  };

  onSelect = (ranges: OnChangeProps): void => {
    this.props.clearError();
    const range = ranges as { selection: Range };
    this.isInitial = false;

    if (range.selection) {
      const startDate = new Date(
        range.selection.startDate.getFullYear(),
        range.selection.startDate.getMonth(),
        range.selection.startDate.getDate());
      const endDate = new Date(
        range.selection.endDate.getFullYear(),
        range.selection.endDate.getMonth(),
        range.selection.endDate.getDate());
      const afterStateUpdate = () => {
        this.updateProps();
        this.showOptimalDatesForWeek();
      };

      if (!this.state.isEndDateSelected && startDate.toString() === endDate.toString()) {
        this.setState(
          {
            startDate,
            endDate: addDays(new Date(startDate), seven),
            isInitial: false,
          },
          afterStateUpdate,
        );
      } else {
        this.setState(
          {
            startDate,
            endDate,
            isInitial: false,
          },
          afterStateUpdate,
        );
      }
    }
  };

  updateProps = (): void => {
    const { isExperiences, setDates, setDatesExperiences } = this.props;
    if (isExperiences) {
      setDatesExperiences({ startDate: this.state.startDate, endDate: this.state.endDate });
    } else {
      setDates({ startDate: this.state.startDate, endDate: this.state.endDate });
    }
  };

  onRangeFocusChange = (focusedRange: RangeFocus): void => {
    this.setState({ focusedRange, isEndDateSelected: !focusedRange[oneItem] });
  };

  updateWeekOptimalStats = (): void => {
    setTimeout(() => {
      this.showOptimalDatesForWeek();
    });

    setTimeout(() => {
      this.renderCheckinDates();

      const { getCheckinStatus } = this.props;
      const { startDate, endDate } = this.state;
      const statuses = this.statuses;

      if (getCheckinStatus && statuses && startDate && endDate) {
        const currentLeft: HTMLDivElement = document.querySelector('.date-picker__months-left');
        const currentRight: HTMLDivElement = document.querySelector('.date-picker__months-right');

        if (currentLeft && currentRight) {
          const today = new Date();
          const leftYear = +currentLeft.dataset.year;
          const leftMonth = +currentLeft.dataset.month;
          const rightYear = +currentRight.dataset.year;
          const rightMonth = +currentRight.dataset.month;
          const isLeftExist =
            statuses.availableDays[leftYear] && statuses.availableDays[leftYear][leftMonth];
          const isRightExist =
            statuses.availableDays[rightYear] && statuses.availableDays[rightYear][rightMonth];
          const isSameMonth = today.getMonth() === leftMonth - oneItem;
          let startDate;
          let endDate;

          const start = new Date(leftYear, leftMonth - oneItem, oneItem);
          const startWithDay = new Date(
            leftYear,
            leftMonth - oneItem,
            isSameMonth ? addDays(today, oneItem).getDate() : oneItem,
          );

          if (!isLeftExist && !isRightExist) {
            startDate = startWithDay;
            endDate = addMonths(start, fullMonthCount);
          } else if (!isLeftExist && isRightExist) {
            startDate = startWithDay;
            endDate = addMonths(start, oneItem);
          } else if (isLeftExist && !isRightExist) {
            startDate = addMonths(start, oneItem);
            endDate = addMonths(start, fullMonthCount);
          }

          if (start && endDate) {
            getCheckinStatus(
              `${format(startDate, 'yyyy-MM-dd')}${emptyDatePartDays}`,
              `${format(subDays(endDate, oneItem), 'yyyy-MM-dd')}${emptyDatePartDays}`,
            );
          }
        }
      }
    });
  };

  getNavigatorRender = (
    currentFocusedDate: Date,
    changeShownDate: (count: number | Date, type: string) => void,
  ): React.ReactElement => {
    const today = new Date();
    this.changeShownDate = changeShownDate;

    return (
      <div onMouseUp={(e) => e.stopPropagation()}>
        <div className="date-picker__arrows">
          <div
            className="date-picker__prev"
            onClick={() => {
              this.updateWeekOptimalStats();
              changeShownDate(-oneItem, 'monthOffset');
            }}
          >
            <ArrowLeftSvg />
          </div>
          <div
            className="date-picker__next"
            onClick={() => {
              this.updateWeekOptimalStats();
              changeShownDate(+oneItem, 'monthOffset');
            }}
          >
            <ArrowRightSvg />
          </div>
        </div>
        <div className="date-picker__months">
          <div
            className="date-picker__months-left"
            data-month={currentFocusedDate.getMonth() + oneItem}
            data-year={currentFocusedDate.getFullYear()}
          >
            <MonthSelect
              onChange={(val: SelectValue) => {
                this.updateWeekOptimalStats();
                changeShownDate(val as number, 'setMonth');
              }}
              value={currentFocusedDate.getMonth()}
              date={currentFocusedDate}
              minMonth={today.getMonth()}
            />
          </div>
          <div
            className="date-picker__months-right"
            data-month={addMonths(currentFocusedDate, oneItem).getMonth() + oneItem}
            data-year={addMonths(currentFocusedDate, oneItem).getFullYear()}
          >
            <MonthSelect
              onChange={(val: SelectValue) => {
                if (val === MonthsEnum.January) {
                  changeShownDate(
                    setMonth(subYears(currentFocusedDate, oneItem), MonthsEnum.December),
                    'set',
                  );
                } else {
                  if (currentFocusedDate.getMonth() === MonthsEnum.December) {
                    changeShownDate(
                      setMonth(addYears(currentFocusedDate, oneItem), val as number),
                      'set',
                    );
                  } else {
                    changeShownDate((val as number) - oneItem, 'setMonth');
                  }
                }

                this.updateWeekOptimalStats();
              }}
              minMonth={today.getMonth() + oneItem}
              value={addMonths(currentFocusedDate, oneItem).getMonth()}
              date={addMonths(currentFocusedDate, oneItem)}
            />
          </div>
        </div>
      </div>
    );
  };

  clearDates = (): void => {
    if (this.props.condoStrictDatesStore.searchType === DateSearchTypeEnum.Strict) {
      this.clearWeerPercents();
      this.props.resetDates(!isResetDates);
      this.setState({
        startDate: new Date(),
        endDate: new Date(),
        isInitial: true,
        focusedRange: [defaultRange, isStart],
        isEndDateSelected: true,
      });
    } else {
      if (this.props.resetFlexibleState) {
        this.props.resetFlexibleState();
      }
    }
  };

  getFlexibleFooterMessage = (): React.ReactElement => {
    const { condoFlexibleDatePickerStore } = this.props;

    if (condoFlexibleDatePickerStore) {
      const { selectedMonths, stayFor } = condoFlexibleDatePickerStore;
      const isMonthEmpty = selectedMonths.length === zero;

      if (isMonthEmpty && stayFor !== StayForEnum.Week) {
        return <FormattedMessage id="condos.available.week" />;
      } else if (isMonthEmpty) {
        return <FormattedMessage id="select.up.to.three.months" />;
      } else if (selectedMonths.length > zero) {
        return <FormattedMessage id="you.can.select.up.to.three.months" />;
      }
    }

    return null;
  };

  resetToAnyTime = (): void => {
    if (this.props.resetFlexibleState) {
      this.props.resetFlexibleState();
    }
  };

  getStrictMaxDate = (): Date => {
    const { isEndDateSelected } = this.state;
    const { condoStrictDatesStore } = this.props;
    const globalMax = subWeeks(addMonths(new Date(), maxDateMonths), oneItem);
    const endDateMax = addWeeks(new Date(condoStrictDatesStore.startDate), maxRangeWeeks);

    if (condoStrictDatesStore.startDate && !isEndDateSelected && isBefore(endDateMax, globalMax)) {
      return endDateMax;
    }

    return globalMax;
  };

  renderCheckinForMonth = (
    months: { [key: number]: number[] },
    month: number,
    wrapper: HTMLDivElement,
    className: string,
  ): void => {
    const days = months[month];

    if (days && days.length) {
      const daysWrappers = wrapper.querySelectorAll('.rdrDay:not(.rdrDayPassive)');

      days.forEach((day) => {
        const dayWrapper = daysWrappers[day - oneItem];

        if (dayWrapper) {
          const text = dayWrapper.querySelector('.rdrDayNumber span');

          if (text) {
            text.classList.add(className);
          }
        }
      });
    }
  };

  renderCheckinUtilMobile = (daysToChek: ICondoDaysToChek, className: string): void => {
    const monthWrappers = document.querySelectorAll('.rdrMonth');

    monthWrappers.forEach((wrapper: HTMLDivElement) => {
      const monthName: HTMLDivElement = wrapper.querySelector('.rdrMonthName');

      if (monthName) {
        const { innerText } = monthName;
        const [monthLatinName, year] = innerText.split(', ');
        const monthObj = MONTHS.find(({ name }) => {
          return name === monthLatinName;
        });
        const monthNumber = +monthObj.number;

        if (daysToChek) {
          const months = daysToChek[+year];

          if (months) {
            this.renderCheckinForMonth(months, monthNumber, wrapper, className);
          }
        }
      }
    });
  };

  renderCheckinUtil = (daysToChek: ICondoDaysToChek, className: string): void => {
    if (!this.props.matches) {
      this.renderCheckinUtilMobile(daysToChek, className);
    } else {
      const currentLeft: HTMLDivElement = document.querySelector('.date-picker__months-left');
      const currentRight: HTMLDivElement = document.querySelector('.date-picker__months-right');
      const monthWrappers = document.querySelectorAll('.rdrMonth');
      const monthWrapperLeft = monthWrappers[zero];
      const monthWrapperRight = monthWrappers[oneItem];

      if (currentLeft && currentRight && monthWrapperLeft && monthWrapperRight) {
        const leftYear = +currentLeft.dataset.year;
        const leftMonth = +currentLeft.dataset.month;
        const rightYear = +currentRight.dataset.year;
        const rightMonth = +currentRight.dataset.month;

        if (daysToChek) {
          const leftMonths = daysToChek[leftYear];
          const rightMonths = daysToChek[rightYear];

          if (leftMonths) {
            this.renderCheckinForMonth(
              leftMonths,
              leftMonth,
              monthWrapperLeft as HTMLDivElement,
              className,
            );
          }

          if (rightMonths) {
            this.renderCheckinForMonth(
              rightMonths,
              rightMonth,
              monthWrapperRight as HTMLDivElement,
              className,
            );
          }
        }
      }
    }
  };

  renderCheckinDates = (): void => {
    if (!this.props.isFromDetails) {
      return;
    }

    const statuses = this.statuses;
    const checkinClass = 'custom-checkin';
    const unavailableClass = 'custom-unavailable';
    const checkinItems = document.querySelectorAll(`.${checkinClass}`);
    const unavailableItems = document.querySelectorAll(`.${unavailableClass}`);

    checkinItems.forEach((element: HTMLDivElement) => {
      element.classList.remove(checkinClass);
    });

    unavailableItems.forEach((element: HTMLDivElement) => {
      element.classList.remove(unavailableClass);
    });

    if (statuses) {
      const { availableDays = {}, unavailableDays = {} } = statuses;

      if (availableDays) {
        this.renderCheckinUtil(availableDays, checkinClass);
      }

      if (unavailableDays) {
        this.renderCheckinUtil(unavailableDays, unavailableClass);
      }
    }
  };

  getDetailsText = (): React.ReactNode => {
    const { startDate } = this.state;

    if (startDate && this.statuses && this.statuses.availableDays) {
      const { availableDays } = this.statuses;
      const date = new Date(startDate);
      const month = date.getMonth() + oneItem;
      const year = date.getFullYear();
      const day = date.getDate();
      const isAllowedToCheckIn =
        availableDays[year] &&
        availableDays[year][month] &&
        availableDays[year][month]?.length &&
        availableDays[year][month]?.includes(day);

      //const { loginStore } = this.props;
      //const { account } = loginStore;
      // For now we for english
      const locale = enUS;//getAccountDateFnsLocale(account);

      if (!isAllowedToCheckIn) {
        let closest = '';
        const years = Object.keys(availableDays)
          .map((availableYear) => +availableYear)
          .filter((availableYear) => availableYear >= +year)
          .sort(compare);

        years.forEach((availableYear) => {
          if (closest) {
            return;
          }

          const availableMonths = Object.keys(availableDays[availableYear])
            .map((availableMonth) => +availableMonth)
            .filter((availableMonth) => availableMonth >= +month)
            .sort(compare);

          if (availableMonths && availableMonths.length) {
            availableMonths.forEach((availableMonth) => {
              if (closest) {
                return;
              }

              if (availableMonth > +month && availableDays[availableYear][availableMonth]) {
                closest = format(
                  new Date(
                    availableYear,
                    availableMonth - oneItem,
                    availableDays[availableYear][availableMonth][zero],
                  ),
                  'MMMM dd',
                  { locale }
                );
              } else {
                const availableDates = availableDays[availableYear][availableMonth]
                  .map((availableDate) => +availableDate)
                  .filter((availableDate) => availableDate >= +day)
                  .sort(compare);

                if (availableDates && availableDates.length) {
                  closest = format(
                    new Date(availableYear, availableMonth - oneItem, +availableDates[zero]),
                    'MMMM dd',
                    { locale }
                  );
                }
              }
            });
          }
        });

        return (
          <>
            <FormattedMessage id="not.available.for.checkin" />
            {closest ? (
              <>
                <FormattedMessage id="closest.checkin.date" /> {closest}
              </>
            ) : null}
          </>
        );
      }
    }

    if (this.props.isShortStaysSupported) {
      return <FormattedMessage id="condo.allows.short.stays" />;
    } else {
      return <FormattedMessage id="condo.available.only.for.7.night" />;
    }
  };

  render(): React.ReactNode {
    const {
      isExperiences,
      condoStrictDatesStore,
      matches,
      condoFlexibleDatePickerStore,
      isSearch,
      isFromDetails = false,
      loginStore
    } = this.props;
    const { error, searchType } = condoStrictDatesStore;
    const { selectedMonths, stayFor } = condoFlexibleDatePickerStore || {};
    const {
      isFocused,
      endDate,
      startDate,
      key,
      focusedRange,
      isEndDateSelected,
      isMobile,
      isStartedText,
    } = this.state;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const selectionRange = {
      startDate: start,
      endDate: end,
      key,
    };
    const { account } = loginStore;

    const hideSelection: boolean = isEndDateSelected && start?.toISOString() === end?.toISOString();
    const focusZIndex = 4;
    const blurZIndex = 1;
    const showPercents = !condoStrictDatesStore.startDate && !condoStrictDatesStore.endDate;
    const isStrictSearch = searchType === DateSearchTypeEnum.Strict;

    const locale = getAccountDateFnsLocale(account);
    const leadTime = getAccountLeadTime(account);

    const color: any = account ? getPreferenceFromAccount(account, SEARCH_ICONS_COLOR_FIELD_KEY) : null;
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div
        className={`condo-strict-date-picker
          ${showPercents ? '' : 'hide-percents'}
          ${!isEmpty(error) ? 'error' : ''}
          ${isFromDetails ? 'is-from-details' : ''}
          ${isFocused ? 'is-focused' : ''}`}
        ref={this.wrapperRef}
      >
        <div
          className={`
            condo-strict-date-picker__wrapper
            ${isFromDetails ? 'from-details' : ''}
            ${!isEmpty(error) ? 'error' : ''}
            ${isFocused && isEndDateSelected ? 'selected' : ''}
            ${isFocused ? 'open' : ''}
            ${!isStrictSearch && matches ? 'flexible' : ''}
          `}
          onClick={this.focus}
          style={{
            zIndex: isFocused ? focusZIndex : blurZIndex,
          }}
        >
          {(isStrictSearch || !isFocused || (!matches && !isStrictSearch)) && (
            <div className="condo-strict-date-picker__left">
              <div className="condo-strict-date-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </div>
          )}
          <div className="condo-strict-date-picker__right">
            {isStrictSearch ? (
              <>
                {matches ? (
                  <>
                    <div className="condo-strict-date-picker__label">
                      {isFocused || isFromDetails ? (
                        <FormattedMessage id="check.in" />
                      ) : (
                        <>
                          <FormattedMessage id="check.in" /> / <FormattedMessage id="check.out" />
                        </>
                      )}
                    </div>
                    <div className="condo-strict-date-picker__header">
                      {isFocused ? (
                        start && !this.state.isInitial && condoStrictDatesStore.startDate ? (
                          <span className="condo-strict-date-picker__date">
                            {formatDateCheckInOut(condoStrictDatesStore.startDate, DATE_FORMAT, locale)}
                          </span>
                        ) : (
                          <FormattedMessage id="select.date" />
                        )
                      ) : (
                        <>
                          {condoStrictDatesStore.startDate &&
                            condoStrictDatesStore.endDate &&
                            !this.state.isInitial ? (
                            <>
                              {isFromDetails ? (
                                <span className="fw600">
                                  {formatDateCheckInOut(condoStrictDatesStore.startDate, DATE_FORMAT, locale)}
                                </span>
                              ) : (
                                <span className="condo-strict-date-picker__date">
                                  {formatDateCheckInOut(condoStrictDatesStore.startDate,
                                    DATE_ONLY_MONTH_FORMAT,
                                    locale
                                  )}{' '}
                                  - {formatDateCheckInOut(condoStrictDatesStore.endDate, DATE_FORMAT, locale)}
                                </span>
                              )}
                            </>
                          ) : isSearch ? (
                            <span className="fw600">
                              <FormattedMessage id="anytime" />
                            </span>
                          ) : isFromDetails ? (
                            <span className="fw600">
                              <FormattedMessage id="select.date" />
                            </span>
                          ) : (
                            <FormattedMessage id="add.dates" />
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="condo-strict-date-picker__label">
                      <FormattedMessage id="check.in" /> / <FormattedMessage id="check.out" />
                    </div>
                    <div className="condo-strict-date-picker__header">
                      {!condoStrictDatesStore.startDate && !condoStrictDatesStore.endDate ? (
                        isFocused ? (
                          <FormattedMessage id="select.date" />
                        ) : isSearch ? (
                          <span className="fw600">
                            <FormattedMessage id="anytime" />
                          </span>
                        ) : (
                          <FormattedMessage id="add.dates" />
                        )
                      ) : (
                        <>
                          {condoStrictDatesStore.startDate &&
                            condoStrictDatesStore.endDate &&
                            isEndDateSelected ? (
                            <>
                              <span className="condo-strict-date-picker__date">
                                {formatDateCheckInOut(condoStrictDatesStore.startDate,
                                  isFocused ? DATE_FORMAT : DATE_ONLY_MONTH_FORMAT, locale
                                )}{' '}
                                - {formatDateCheckInOut(condoStrictDatesStore.endDate, DATE_FORMAT, locale)}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="condo-strict-date-picker__date">
                                {formatDateCheckInOut(condoStrictDatesStore.startDate, DATE_FORMAT, locale)} -{' '}
                                <FormattedMessage id="anytime" />
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {matches ? (
                  <>
                    <div className="condo-strict-date-picker__label grey">
                      {isFocused ? (
                        <FormattedMessage id="length.of.stay" />
                      ) : (
                        <>
                          <FormattedMessage id="travel.period" />
                        </>
                      )}
                    </div>
                    <div className="condo-strict-date-picker__header">
                      {isFocused ? (
                        <>{getStayForText()}</>
                      ) : !isStartedText ? (
                        <>
                          {selectedMonths.length ? (
                            <>
                              {getStayForText()}{' '}
                              <span className="condo-strict-date-picker__bold ttn">
                                <FormattedMessage id="in" />{' '}
                                {getSelectedMonthText(selectedMonths, this.props.intl)}
                              </span>
                            </>
                          ) : (
                            <span className="bold ttl">
                              <FormattedMessage id="anytime" />
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="bold tti">
                          <FormattedMessage id="click.to.select.period" />
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="condo-strict-date-picker__label grey">
                      <FormattedMessage id="travel.period" />
                    </div>
                    <div className="condo-strict-date-picker__header">
                      {!isStartedText ? (
                        <>
                          {selectedMonths.length ? (
                            <>
                              {getStayForText()}{' '}
                              <span className="condo-strict-date-picker__bold ttn">
                                <FormattedMessage id="in" />{' '}
                                {getSelectedMonthText(selectedMonths, this.props.intl)}
                              </span>
                            </>
                          ) : (
                            <span className="bold ttl">
                              <FormattedMessage id="anytime" />
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="bold tti">
                          <FormattedMessage id="click.to.select.period" />
                        </span>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {isStrictSearch && isFromDetails && matches && !isFocused ? (
          <div
            className={`
                condo-strict-date-picker__wrapper no-border checkout
                ${isFromDetails ? 'from-details' : ''}
                ${isFocused && isEndDateSelected ? 'selected' : ''}
                ${isFocused ? 'open' : ''}
                ${!isStrictSearch && matches ? 'flexible' : ''}
            `}
            onClick={this.focus}
            style={{
              zIndex: isFocused ? focusZIndex : blurZIndex,
            }}
          >
            <div className="condo-strict-date-picker__left">
              <div className="condo-strict-date-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </div>
            <div className="condo-strict-date-picker__right">
              <div className="condo-strict-date-picker__label">
                <FormattedMessage id="check.out" />
              </div>
              <div className="condo-strict-date-picker__header">
                {condoStrictDatesStore.endDate ? (
                  <span className="condo-strict-date-picker__date">
                    {formatDateCheckInOut(condoStrictDatesStore.endDate, DATE_FORMAT, locale)}
                  </span>) : (
                  <span className="fw600" style={{ height: '22px' }}>
                    <FormattedMessage id="select.date" />
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div
          className="condo-strict-date-picker__dropdown"
          style={{ display: isFocused ? 'block' : 'none' }}
        >
          <div
            className={`condo-strict-date-picker__footer ${!isStrictSearch && selectedMonths.length === zero && stayFor === StayForEnum.Week
              ? 'hidden'
              : ''
              }`}
          >
            <span className="condo-strict-date-picker__loader">
              <GreyLoaderSvg />
              <FormattedMessage id="loading" />
            </span>
            {isStrictSearch ? (
              <>
                {matches ? (
                  <>
                    <div className="condo-strict-date-picker__footer-tip-one">
                      <LightSvg />
                      <div className="condo-strict-date-picker__footer-text">
                        {isFromDetails ? (
                          this.getDetailsText()
                        ) : (
                          <>
                            {condoStrictDatesStore.startDate && condoStrictDatesStore.endDate ? (
                              <FormattedMessage id="condos.available.week" />
                            ) : (
                              <>
                                <FormattedMessage id="travel.dates.left" />{' '}
                                <span className="condo-strict-date-picker__footer-green-text">
                                  <FormattedMessage id="friday" />,{' '}
                                  <FormattedMessage id="saturday" /> <FormattedMessage id="and" />{' '}
                                  <FormattedMessage id="sunday" />{' '}
                                </span>
                                <FormattedMessage id="travel.dates.right" />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="condo-strict-date-picker__footer-actions-wrapper">
                      {condoStrictDatesStore.startDate && condoStrictDatesStore.endDate ? (
                        <div
                          className="condo-strict-date-picker__footer-clear-dates"
                          onClick={this.clearDates}
                        >
                          <FormattedMessage id="clear.dates" />
                        </div>
                      ) : null}
                      <BlueButton onClick={this.onDone}>
                        <FormattedMessage id="done" />
                      </BlueButton>
                    </div>
                  </>
                ) : (
                  <>
                    {condoStrictDatesStore.startDate && condoStrictDatesStore.endDate ? (
                      <>
                        <div className="condo-strict-date-picker__footer-actions-wrapper">
                          <div
                            className="condo-strict-date-picker__footer-clear-dates"
                            onClick={this.clearDates}
                          >
                            <FormattedMessage id="clear.dates" />
                          </div>
                          <BlueButton onClick={this.onDone}>
                            <FormattedMessage id="done" />
                          </BlueButton>
                        </div>
                      </>
                    ) : (
                      <>
                        <LightSvg />
                        <div className="condo-strict-date-picker__footer-text">
                          {isFromDetails ? (
                            this.getDetailsText()
                          ) : (
                            <>
                              <FormattedMessage id="travel.dates.left" />{' '}
                              <span className="condo-strict-date-picker__footer-green-text">
                                <FormattedMessage id="friday" />, <FormattedMessage id="saturday" />{' '}
                                <FormattedMessage id="and" /> <FormattedMessage id="sunday" />{' '}
                              </span>
                              <FormattedMessage id="travel.dates.right" />
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {matches ? (
                  <>
                    <div className="condo-strict-date-picker__footer-tip-one" />
                    <div className="condo-strict-date-picker__footer-actions-wrapper">
                      {selectedMonths.length !== zero || stayFor !== StayForEnum.Week ? (
                        <div
                          className="condo-strict-date-picker__footer-clear-dates"
                          onClick={this.resetToAnyTime}
                        >
                          <FormattedMessage id="reset.to.anytime" />
                        </div>
                      ) : null}
                      <BlueButton onClick={this.onDone}>
                        <FormattedMessage id="done" />
                      </BlueButton>
                    </div>
                  </>
                ) : (
                  <>
                    {selectedMonths.length !== zero || stayFor !== StayForEnum.Week ? (
                      <div className="condo-strict-date-picker__footer-actions-wrapper">
                        <div
                          className="condo-strict-date-picker__footer-clear-dates"
                          onClick={this.resetToAnyTime}
                        >
                          <FormattedMessage id="reset.to.anytime" />
                        </div>
                        <BlueButton onClick={this.onDone}>
                          <FormattedMessage id="done" />
                        </BlueButton>
                      </div>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>

          <div className="condo-strict-date-picker__loader-disable" />
          <div
            className="condo-strict-date-picker__mobile-header"
            style={{ display: isMobile ? 'block' : 'none' }}
          >
            <div className="condo-strict-date-picker__mobile-tabs">
              <div
                className={`condo-strict-date-picker__mobile-tab ${isStrictSearch ? 'selected' : ''
                  }`}
                onClick={() => this.handleSearchType(DateSearchTypeEnum.Strict)}
              >
                <FormattedMessage id="specific.dates" />
              </div>
              <div
                className={`condo-strict-date-picker__mobile-tab ${!isStrictSearch ? 'selected' : ''
                  }`}
                onClick={() => this.handleSearchType(DateSearchTypeEnum.Flexible)}
              >
                <FormattedMessage id="flexible.dates" />
              </div>
            </div>
          </div>
          <div
            className={`
              condo-strict-date-picker__wrapper right
              ${isFromDetails ? 'from-details' : ''}
              ${!isEndDateSelected ? 'selected' : ''}`}
          >
            <div className="condo-strict-date-picker__left">
              <div className="condo-strict-date-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </div>
            <div className="condo-strict-date-picker__right">
              <div className="condo-strict-date-picker__label">
                {isStrictSearch ? (
                  <FormattedMessage id="check.out" />
                ) : (
                  <FormattedMessage id="travel.period" />
                )}
              </div>
              <div className="condo-strict-date-picker__header">
                {isStrictSearch ? (
                  <>
                    {end &&
                      !this.state.isInitial &&
                      isEndDateSelected &&
                      condoStrictDatesStore.endDate ? (
                      <span className="condo-strict-date-picker__date">
                        {formatDateCheckInOut(condoStrictDatesStore.endDate, DATE_FORMAT, locale)}
                      </span>
                    ) : !this.state.isInitial && condoStrictDatesStore.endDate ? (
                      <FormattedMessage id="select.date" />
                    ) : (
                      <FormattedMessage id="anytime" />
                    )}
                  </>
                ) : (
                  <span className="condo-strict-date-picker__bold ttn">
                    {selectedMonths.length ? (
                      <>
                        <FormattedMessage id="in" />{' '}
                        {getSelectedMonthText(selectedMonths, this.props.intl)}
                      </>
                    ) : (
                      <div style={{ height: '22px' }}>
                        <FormattedMessage id="select.date" />
                      </div>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="condo-strict-date-picker__close-icon" onClick={this.blur}>
            <CloseSvg />
          </div>
          <div className="condo-strict-date-picker__types-select">
            <Radio.Group value={searchType} onChange={(e) => this.handleSearchType(e.target.value)}>
              {this.getSearchTypeOptions().map((item, index) => (
                <Radio.Button key={`${index}-${item.value}`} value={item.value}>
                  {item.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
          <div
            className={`condo-strict-date-picker__picker ${hideSelection ? 'hide-selection' : ''}`}
            style={{ display: isStrictSearch ? 'block' : 'none' }}
          >
            <div className="condo-strict-date-picker__picker-wrapper">
              <DateRangePicker
                onChange={this.onSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={isMobile ? mobileMonthCount : fullMonthCount}
                monthDisplayFormat="MMMM, yyyy"
                ranges={[selectionRange]}
                direction={isMobile ? 'vertical' : 'horizontal'}
                weekStartsOn={0}
                weekdayDisplayFormat="EEEEEE"
                preventSnapRefocus
                minDate={addDays(new Date(), leadTime)}
                focusedRange={focusedRange}
                onRangeFocusChange={this.onRangeFocusChange}
                maxDate={this.getStrictMaxDate()}
                
                // @ts-ignore
                navigatorRenderer={this.getNavigatorRender}
              />
            </div>
          </div>
          <Responsive>
            {!isFromDetails ? (
              <CondoFlexibleDatePicker isExperiences={isExperiences} style={{ display: !isStrictSearch ? 'block' : 'none' }} />) : (
              <CondoFlexibleDatePickerComponent 
                isExperiences={isExperiences}
                style={{ display: !isStrictSearch ? 'block' : 'none' }}
                intl={this.props.intl}
                loginStore={{...loginStore}}
                condoFlexibleDatePickerStore={{...condoFlexibleDatePickerStore}}
                resetState={this.props.resetFlexibleState}
                selectMonths={this.props.selectMonths}
                selectMonthsExperiences={this.props.selectMonthsExperiences}
              />)}
          </Responsive>          
        </div>
        {isFocused && <div className="condo-strict-date-picker__fixed-bg" />}        
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoDetailsStore: state.condoDetailsStore,
    condoStrictDatesStore: state.condoStrictDatesStore,
    condoFlexibleDatePickerStore: state.condoFlexibleDatePickerStore,
    isSearch: state.condosStore.isSearch,
    condosStore: state.condosStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDates: (dates: { startDate: Date; endDate: Date }) => {
    dispatch(
      condoStrictDatesActions.setDates({
        startDate: format(dates.startDate, isoFormat) + startTime,
        endDate: format(dates.endDate, isoFormat) + startTime,
      }),
    );
  },
  setDatesExperiences: (dates: { startDate: Date; endDate: Date }) => {
    dispatch(
      condoStrictDatesActions.setDatesExperiences({
        startDate: format(dates.startDate, isoFormat) + startTime,
        endDate: format(dates.endDate, isoFormat) + startTime,
      }),
    );
  },
  setSearchType: (type: DateSearchTypeEnum) => {
    dispatch(condoStrictDatesActions.setSearchType(type));
  },
  setSearchTypeExperiences: (type: DateSearchTypeEnum) => {
    dispatch(condoStrictDatesActions.setSearchTypeExperiences(type));
  },
  clearError: () => {
    dispatch(condoStrictDatesActions.setError(''));
  },
  resetDates: (value?: boolean) => {
    dispatch(condoStrictDatesActions.resetDates(value));
  },
  resetFlexibleState: () => {
    dispatch(condoFlexibleDatePickerActions.resetState());
  },
  setIsFocusDatePicker: (value: boolean) => {
    dispatch(condosActions.setIsFocusDatePicker(value));
  },
});

export const CondoStrictDatePickerComponentWithIntl = injectIntl(CondoStrictDatePickerComponent);

export const CondoStrictDatePicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CondoStrictDatePickerComponent));
