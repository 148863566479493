import React from "react";
import { Skeleton, Card } from "antd";

const FlexRoomSkeleton = () => {
  return (
    <section className="mt-0 pt-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card card-body border shadow-sm">
              <div className="d-sm-flex justify-content-start align-items-center">
                <div className="pe-3">
                  <Skeleton.Avatar size="large" shape="circle" active />
                </div>
                <div>
                  <Skeleton.Input className="mt-2" style={{ width: 300 }} active size="small" />
                  <Skeleton className="mt-2" active paragraph={{ rows: 0 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlexRoomSkeleton;
