import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IGoodToKnowList } from '@share/common-types';
import { CheckMarkSvg } from '@assets';
import { CloseSvg } from '@share/assets';

import './style.scss';

interface IProps {
  goodToKnowList: IGoodToKnowList[];
}

export class TrustYouCategory extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { goodToKnowList } = this.props;

    if (!goodToKnowList) {
      return null;
    }

    return (
      <div className="categories">
        <p className="categories__title">
          <FormattedMessage id="good.to.know" />
        </p>
        {goodToKnowList.map((category, index) => (
          <div className="categories__wrapper" key={`${index} + ${category.text}`}>
            {category.sentiment === 'pos' ? <CheckMarkSvg /> : <CloseSvg />}
            <p className="categories__name">{category.text}</p>
          </div>
        ))}
      </div>
    );
  }
}
