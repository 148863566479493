import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  IGetawayBookInfo,
  GetawaysLocationEnum,
  GetawaysCondoSubCategoryEnum,
  ICustomGetawaysListHeaderInfo,
} from '@share/common-types';
import GetawaysCruise from '@assets/images/getaways/getaways-cruise.png';
import GetawaysFantasy from '@assets/images/getaways/getaways-fantasy.png';
import GetawaysCondoValue from '@assets/images/getaways/getaways-condo-value.png';
import GetawaysCondoPremium from '@assets/images/getaways/getaways-condo-premium.png';
import GetawaysStaycations from '@assets/images/getaways/getaways-staycations.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { Tooltip } from 'antd';

export const updateGetawayBookInfo = (
  infoObject: IGetawayBookInfo,
  value: { [key: string]: string | number | boolean },
): IGetawayBookInfo => {
  const newInfo = {
    ...infoObject,
    ...value,
  };
  return newInfo;
};

export const renderGetawayListHeaderInfo = (
  specialTypeName: string,
): ICustomGetawaysListHeaderInfo => {
  switch (specialTypeName) {
    case GetawaysCondoSubCategoryEnum.PremierEscapes: {
      return {
        headerImg: GetawaysCondoPremium,
        headerTitle: 'getaways.condo.premium.title',
        headerSubtitle: 'getaways.condo.premium.text',
      };
    }
    case GetawaysCondoSubCategoryEnum.ValueGetaways: {
      return {
        headerImg: GetawaysCondoValue,
        headerTitle: 'getaways.condo.value.title',
        headerSubtitle: 'getaways.condo.value.text',
      };
    }
    case GetawaysLocationEnum.Fantasy: {
      return {
        headerImg: GetawaysFantasy,
        headerTitle: 'getaway.fantasy.title',
        headerSubtitle: 'getaway.fantasy.subtitle',
      };
    }
    case GetawaysLocationEnum.Cruise: {
      return {
        headerImg: GetawaysCruise,
        headerTitle: 'getaway.cruise.title',
        headerSubtitle: 'getaway.cruise.subtitle',
      };
    }
    case GetawaysLocationEnum.Staycation: {
      return {
        headerImg: GetawaysStaycations,
        headerTitle: 'getaway.staycations.custom.title',
        headerSubtitle: 'getaway.staycations.subtitle',
      };
    }
    default:
      return null;
  }
};

export const renderPriceBasedOn = (specialTypeName: string): React.ReactNode => {
  switch (specialTypeName) {
    case GetawaysLocationEnum.Fantasy:
    case GetawaysLocationEnum.Cruise: {
      return <FormattedMessage id="per.person" />;
    }
    case GetawaysLocationEnum.Staycation: {
      return <FormattedMessage id="total.to.pay" />;
    }
    case GetawaysLocationEnum.Condo: {
      return <FormattedMessage id="per.week" />;
    }
    default:
      return null;
  }
};

export const renderGetawaysCardDate = (
  specialTypeName: string,
  formattedDate: string,
  staycationDate: string,
  lengthOfStay: string,
  city: string,
  numberOfNights: number,
  state: string,
): React.ReactNode => {
  switch (specialTypeName) {
    case GetawaysLocationEnum.Fantasy: {
      const lengthOfStaySplit = lengthOfStay?.split('/');
      return <span>{lengthOfStaySplit?.length >= 2 ? <><FormattedMessage id="days" values={{ count: lengthOfStaySplit[0] }} /> / <FormattedMessage id="nights" values={{ count: lengthOfStaySplit[1] }} /></>  : get(lengthOfStaySplit,'[0]')}</span>;
    }
    case GetawaysLocationEnum.Cruise: {
      return (
        <span>
          <FormattedMessage id="nights" values={{ count: numberOfNights }} />{!formattedDate ? '' : ', ' + formattedDate}
        </span>
      );
    }
    case GetawaysLocationEnum.Staycation: {
      return (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <Tooltip title={`${city}, ${state}`} placement="top">{city}, {state}</Tooltip>
          </span>
          {staycationDate ? (<span>{staycationDate}</span>) : null}
        </span>
      );
    }
    case GetawaysLocationEnum.Condo: {
      const stays = lengthOfStay.split('/');
      const days = stays.length === 2 ? parseInt(stays[0]) : null;
      const nights = stays.length === 2 ? parseInt(stays[1]) : stays.length === 1 ? parseInt(stays[0]) : null;

      return (
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ display: 'flex', alignItems: 'center', marginRight: '10px', color: 'var(--pacific-blue)' }}>
            <FontAwesomeIcon icon={faCalendar} style={{ fontSize: '1.5em' }} />
          </span>
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              <FormattedMessage id="check.in" />:<span style={{ marginLeft: '6px', marginRight: '6px' }}>{formattedDate}</span>
            </span>
            <span>
              <FormattedMessage id="length.stay" />:
              <label style={{ marginLeft: '6px' }}>
                {days ? (<FormattedMessage id="days" values={{ count: days }} />) : null}
                {(days && nights) ? (<span style={{ marginRight: '4px', marginLeft: '4px' }}>/</span>) : null}
                {nights ? (<FormattedMessage id="nights" values={{ count: nights }} />) : null}
              </label>
            </span>
          </span>
        </span>);
    }
    default:
      return null;
  }
};

export const renderGetawaysDetailsTitle = (specialTypeName: string): React.ReactNode => {
  switch (specialTypeName) {
    case GetawaysLocationEnum.Fantasy: {
      return <FormattedMessage id="getaways.details.fantasy" />;
    }
    case GetawaysLocationEnum.Cruise: {
      return <FormattedMessage id="getaways.details.cruise" />;
    }
    case GetawaysLocationEnum.Staycation: {
      return <FormattedMessage id="getaways.details.staycation" />;
    }
    case GetawaysLocationEnum.Condo: {
      return <FormattedMessage id="getaways.details.condo" />;
    }
    default:
      return null;
  }
};

export const GETAWAY_BACKGROUND_TRANSPARENT = 'rgba(32, 39, 77, 0.2)';
