import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ITreatYourselfLogos } from '@common-types';
import './style.scss';

interface IProps {
  logos: ITreatYourselfLogos[];
  customerServiceNumber: string;
}

export class TreatYourself extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { logos, customerServiceNumber } = this.props;

    return (
      <div className="treat-yourself">
        <div className="treat-yourself__wrapper">
          <p className="treat-yourself__title">
            <FormattedMessage id="treat.yourself.direct.line" />
            <a href={`tel:${customerServiceNumber}`} className="treat-yourself__contact-phone">
              {customerServiceNumber}
            </a>
          </p>
          <p className="treat-yourself__description">
            <FormattedMessage id="your.award.winning.travel" />
          </p>
          <p className="treat-yourself__info-text">
            <FormattedMessage id="we.have.been.recognized" />
          </p>
          <div className="treat-yourself__logos-wrapper">
            {logos.map((item, index) => (
              <div key={`${index}-${item.name}`}>
                <div className="treat-yourself__img-wrapper">
                  <img className="treat-yourself__img" src={item.image} alt={item.name} />
                </div>
                <div>
                  <p className="treat-yourself__logo-name">
                    <FormattedMessage id={item.name} />
                  </p>
                  <p className="treat-yourself__logo-reward">
                    <FormattedMessage id={item.reward} />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
