
import { IAdminHotelBookingDetails } from '@common-types';
import { IReservationCancellationFees } from '@share/common-types';
import { RootState } from '@share/utils';

import { IHotelAdminDetailsState } from './slice';

export const selectAdminHotelBookingsDetailsState = (state: RootState): IHotelAdminDetailsState =>
  state.adminHotelDetailsStore;

export const selectAdminHotelBookingsDetails = (state: RootState): IAdminHotelBookingDetails =>
  selectAdminHotelBookingsDetailsState(state).hotelDetails;

export const selectAdminHotelBookingsDetailsShowReminderModal = (state: RootState): boolean =>
  selectAdminHotelBookingsDetailsState(state).showReminderModal;

export const selectAdminHotelBookingsDetailsShowCanceledModal = (state: RootState): boolean =>
  selectAdminHotelBookingsDetailsState(state).showCanceledModal;

export const selectAdminHotelBookingsDetailsOptionalFees = (
  state: RootState,
): IReservationCancellationFees[] => selectAdminHotelBookingsDetails(state).optionalFees;

export const selectAdminHotelBookingsDetailsPayAtPropertyFees = (
  state: RootState,
): IReservationCancellationFees[] => selectAdminHotelBookingsDetails(state).payAtPropertyFees;

