import React from "react";
import img from "@assets/images/dashboard/no-hotel-image.png";
import { useHistory } from "react-router-dom";

interface ErrorMessageProps {
  heading: string;
  subHeading: string;
  text: string;
  button?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ heading, subHeading, text, button }) => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div className="ms-3 mt-5 d-flex justify-content-center flex-direction-col">
        <img src={img} width={400} alt="placeholder" />
      </div>
      <div className="no-hotel-found">
        <h4>
          <strong>{heading}</strong>
        </h4>
        <p className="no-hotel-desc">{subHeading}</p>
        <p className="no-hotel-suggestion">{text}</p>
        {button && (
          <button onClick={handleBack} className="btn btn-primary">
            {button}
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
