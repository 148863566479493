import React from 'react';

export const CartSvg: React.FunctionComponent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6185 9.59629H4.47686L4.88564 8.76367L11.6778 8.75137C11.9075 8.75137 12.1044 8.5873 12.1454 8.36035L13.086 3.09531C13.1106 2.95723 13.0737 2.81504 12.9835 2.70703C12.9389 2.65388 12.8833 2.61106 12.8205 2.58155C12.7577 2.55204 12.6892 2.53654 12.6198 2.53613L3.9792 2.50742L3.90537 2.16016C3.85889 1.93867 3.65928 1.77734 3.43232 1.77734H1.32002C1.19202 1.77734 1.06927 1.82819 0.978758 1.9187C0.888249 2.00921 0.837402 2.13196 0.837402 2.25996C0.837402 2.38796 0.888249 2.51071 0.978758 2.60122C1.06927 2.69173 1.19202 2.74258 1.32002 2.74258H3.04131L3.36397 4.27656L4.1583 8.12246L3.13564 9.7918C3.08254 9.86348 3.05055 9.94859 3.0433 10.0375C3.03605 10.1264 3.05383 10.2156 3.09463 10.2949C3.17666 10.4576 3.34209 10.5602 3.52529 10.5602H4.38389C4.20085 10.8033 4.10198 11.0994 4.10225 11.4037C4.10225 12.1775 4.73115 12.8064 5.50498 12.8064C6.27881 12.8064 6.90772 12.1775 6.90772 11.4037C6.90772 11.0988 6.80654 10.8021 6.62607 10.5602H8.82861C8.64557 10.8033 8.5467 11.0994 8.54697 11.4037C8.54697 12.1775 9.17588 12.8064 9.94971 12.8064C10.7235 12.8064 11.3524 12.1775 11.3524 11.4037C11.3524 11.0988 11.2513 10.8021 11.0708 10.5602H12.6198C12.8851 10.5602 13.1024 10.3441 13.1024 10.0775C13.1016 9.94967 13.0503 9.82731 12.9596 9.73715C12.869 9.647 12.7463 9.59636 12.6185 9.59629ZM4.18018 3.45898L12.0524 3.48496L11.2813 7.80254L5.09756 7.81348L4.18018 3.45898ZM5.50498 11.8357C5.26709 11.8357 5.07295 11.6416 5.07295 11.4037C5.07295 11.1658 5.26709 10.9717 5.50498 10.9717C5.74287 10.9717 5.93701 11.1658 5.93701 11.4037C5.93701 11.5183 5.89149 11.6282 5.81047 11.7092C5.72945 11.7902 5.61956 11.8357 5.50498 11.8357ZM9.94971 11.8357C9.71182 11.8357 9.51768 11.6416 9.51768 11.4037C9.51768 11.1658 9.71182 10.9717 9.94971 10.9717C10.1876 10.9717 10.3817 11.1658 10.3817 11.4037C10.3817 11.5183 10.3362 11.6282 10.2552 11.7092C10.1742 11.7902 10.0643 11.8357 9.94971 11.8357Z"
      fill="#0D99D6"
    />
  </svg>
);
