import React from 'react';

import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Routes } from '@share/constants';
import { getAccountUsernameFromPath, isVacationRentalsFromPath, RootState, VACATIONS_PAGE } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import GeneralError from '@assets/images/general-error.png';


import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends RouteComponentProps, IMapStateToProps {}

class ErrorPageComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const accountNamePath = getAccountUsernameFromPath(this.props.history);
    const isVacationRentals = isVacationRentalsFromPath(this.props.history);

    const accountNameStore = this.props.loginStore?.account;

    const accountName = !isEmpty(accountNamePath) ? 
                            accountNamePath :
                            accountNameStore ? 
                              accountNameStore?.name :
                              null;
    return (
      <div className="error-page">
        <img className="error-page__image" src={GeneralError} />
        <label className="error-page__title"><FormattedMessage id="error.custom.error.title" /></label>
        <label className="error-page__message"><FormattedMessage id="error.custom.error.retry" /></label>
        <label className="error-page__message-retry"><FormattedMessage id="error.custom.error.contact_support" /></label>
        {!isEmpty(accountName) ? (<label className="error-page__message-retry"><Link to={isVacationRentals ? `/${accountName}${Routes.Search}/${VACATIONS_PAGE}` : `/${accountName}${Routes.Search}`}><FormattedMessage id="error.custom.error.return_home" /></Link></label>) :  null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const ErrorPage = connect(mapStateToProps)(withRouter(ErrorPageComponent));
