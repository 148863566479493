import React from 'react';

import { FormattedMessage } from 'react-intl';

import GeneralError from '@assets/images/general-error.png';

import './style.scss';

interface IProps {}

export class NotAuthorizedPage extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="not-authorized-page">
        <img src={GeneralError} />
        <label><FormattedMessage id="error.code.NOTAUTHORIZED" /></label>
      </div>
    );
  }
}
