import { AxiosPromise } from 'axios';
import {
  IHotelBookingsParams,
  IAdminHotelBookings,
  IHotelAutocompleteResult,
  IExportHotelBookingsCsv,
  IAdminHotelBookingDetails,
} from '@common-types';
import { ICancelHotelBooking } from '@share/common-types';
import { axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';

export interface IHotelBookingsResponse {
  orders: IAdminHotelBookings[];
  totalCount: number;
}

export interface IHotelAutocompleteResponse {
  results: IHotelAutocompleteResult[];
  totalCount: number;
}

export interface ICancelData {
  orderId: number;
  reason: string;
}

export const hotelBookingsAdminAPI = {
  createHotelBookings(data: IHotelBookingsParams): AxiosPromise<IHotelBookingsResponse> {
    return axiosInstance.post(Urls.HotelBookings, data, getHeaders());
  },
  searchHotelBookings(queryString: string): AxiosPromise<IHotelAutocompleteResponse> {
    return axiosInstance.get(Urls.SearchHotelBookings, {
      params: { queryString },
      ...getHeaders(),
    });
  },
  exportedHotelBookings(data: IExportHotelBookingsCsv): AxiosPromise<IExportHotelBookingsCsv> {
    return axiosInstance.post(Urls.ExportHotelBookings, data, getHeaders());
  },

  cancel(data: ICancelData): AxiosPromise<ICancelHotelBooking> {
    return axiosInstance.post(Urls.HotelBookingsOrderDetailsCancel, data, getHeaders());
  },
  getHotelBookingsDetails(
    orderId: number,
  ): AxiosPromise<{ hotelOrderDetails: IAdminHotelBookingDetails }> {
    return axiosInstance.post(Urls.HotelBookingsOrderDetails, { orderId }, getHeaders());
  },
};
