import React from 'react';
import isUndefined from 'lodash/isUndefined';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { Menu, Tooltip } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { IMenuState, setVisible } from '@share/store/slices';
import { IHotelsState, ILoginState, resetHotelsFull } from '@share/store/slices';
import { Routes } from '@share/constants';
import { GetHomeParams, getLocalURI, getPreferenceFromAccount, isExternalUrl, RootState, VACATIONS_PAGE } from '@share/utils';
import { HEADER_FONTS_COLOR_FIELD_KEY, HEADER_BACKGROUND_COLOR_FIELD_KEY } from '@share/common-types';

import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  resetHotelsFull: () => void;
  setVisible: (visible: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  matches: boolean;
  menuStore: IMenuState;
  isVacationRentals?: boolean;
}

const HOTELS = 'Hotels';

class NavMenu extends React.Component<IProps> {
  reloadSamePage = (event: { target: HTMLInputElement }, namePage: string) => {
    this.props.setVisible(false);

    if (event.target.innerText === namePage && this.props.hotelsStore.hotels) {
      const { loginStore, isVacationRentals } = this.props;
      const { account } = loginStore;

      const homeParams = GetHomeParams(account);

      this.props.resetHotelsFull();
      if (isVacationRentals) {
        this.props.history.push(`/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
      } else {
        this.props.history.push(`/${account?.name}${Routes.Search}${homeParams}`);
      }
    }
  };

  // @ts-ignore
  menuItem = (url: string, title: string, index: number, className: string): React.ReactNode => {
    const { loginStore, isVacationRentals, intl } = this.props;
    const { account } = loginStore;

    let homeParams = GetHomeParams(account);
  
    const footerFontsColorDB = getPreferenceFromAccount(account, HEADER_FONTS_COLOR_FIELD_KEY);
    const styleFonts: any = {};
    if (!isEmpty(footerFontsColorDB)) {
      styleFonts.color = footerFontsColorDB;
    }

    const styleBackground: any = {};
    const headerBackgroundColorDB = getPreferenceFromAccount(account, HEADER_BACKGROUND_COLOR_FIELD_KEY);
    if (!isEmpty(headerBackgroundColorDB)) {
      styleBackground.backgroundColor = headerBackgroundColorDB;
    } else {
      styleBackground.backgroundColor = '#FFFFFF';
    }
    if (!isEmpty(footerFontsColorDB)) {
      styleBackground.color = footerFontsColorDB;
    }

    const goTo = !isUndefined(url) ? 
                      `/${account?.name}${getLocalURI(url)}${homeParams}` : 
                      isVacationRentals ? 
                            `/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}` :
                            `/${account?.name}${Routes.Search}${homeParams}`;

    const isRsi = account?.isRSITemplate && account?.isPrivateTokenAccountType;

    return (
      <Menu.Item key={`${title}-${index}`} className="header__item-menu">
        {url === 'disabled' ?
          <Tooltip title={intl.formatMessage({ id: 'menu.link.disable' })} placement="top">
            <div style={{ width: '100%' }} className={`${className} ant-menu-item-disabled`}>
              {isRsi ? (<FormattedMessage id={title} />) : title}
            </div>
          </Tooltip> :
          isExternalUrl(!isUndefined(url) ? url : '') ? (
            <a className={className} style={styleBackground} href={url} target={account.disableNewTabOpen ? '_self' : '_blank'} rel="noreferrer">
              {isRsi ? (<FormattedMessage id={title} />) : title}
            </a>) : (
            <Link
              to={goTo}
              className={className}
              // @ts-ignore
              onClick={(event) => this.reloadSamePage(event, HOTELS)}
              style={styleFonts}
            >
              {isRsi ? (<FormattedMessage id={title} />) : title}
            </Link>)}
      </Menu.Item>
   )};

  render(): React.ReactNode {
    const { SubMenu } = Menu;
    const { matches, menuStore } = this.props;
    const { visible, items } = menuStore;

    const { loginStore } = this.props;
    const { account } = loginStore;

    const footerFontsColorDB = getPreferenceFromAccount(account, HEADER_FONTS_COLOR_FIELD_KEY);
    const styleFonts: any = {};
    if (!isEmpty(footerFontsColorDB)) {
      styleFonts.color = footerFontsColorDB;
    }


    const styleBackground: any = {};
    const headerBackgroundColorDB = getPreferenceFromAccount(account, HEADER_BACKGROUND_COLOR_FIELD_KEY);
    if (!isEmpty(headerBackgroundColorDB)) {
      styleBackground.backgroundColor = headerBackgroundColorDB;
      document.documentElement.style.setProperty('--background-color', headerBackgroundColorDB);
    } else {
      styleBackground.backgroundColor = '#FFFFFF';
      document.documentElement.style.setProperty('--background-color', '#FFFFFF');
    }
    if (!isEmpty(footerFontsColorDB)) {
      styleBackground.color = footerFontsColorDB;
      document.documentElement.style.setProperty('--header-font-color', footerFontsColorDB);
    }
    
    const mainItems = items?.main;

    return (
      <Menu
        mode={visible && !matches ? 'inline' : 'horizontal'}
        style={{
          display: !visible ? (!matches ? 'none' : 'block') : 'block',
          ...styleBackground
        }}
      >
        {mainItems?.map((item, index) =>
          !item.childItems ? (
            this.menuItem(item.url, item.title, index, 'header__link-nav')
          ) : (
            <SubMenu
              key={`${item.title}-${index}`}
              title={<span style={styleBackground}><FormattedMessage id={item.title} /></span>}
              className="header__sub-menu"
              popupClassName="menu-submenu-popup"
              style={styleBackground}
            >
              {item?.childItems?.map((childItem) =>
                this.menuItem(childItem.url, childItem.title, index, 'header__link-nav-child'),
              )}
            </SubMenu>
          ),
        )}
      </Menu>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    hotelsStore: state.hotelsStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  resetHotelsFull: () => {
    dispatch(resetHotelsFull());
  },
  setVisible: () => {
    dispatch(setVisible());
  },
});

const NavMenuIntl = injectIntl(NavMenu);

export const NavigationMenu = connect(mapStateToProps, mapDispatchToProps)(withRouter(NavMenuIntl));
