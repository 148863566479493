
import React from 'react';

import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

import GeneralError from '@share/assets/images/general-error.png';
import NoHotelResults from '@share/assets/images/no_hotel_results.png';

import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { IAccount } from '@share/common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps extends RouteComponentProps {
  message?: string;
  account?: IAccount;
  displayExclamationIcon?: boolean;
}

const SODL_OUT = 'SOLDOUT';

const MESSAGE_EXCLUDE = [SODL_OUT, 'SESSIONEXPIRED', 'UNAUTHORIZED', 'WIDGET_ERROR', 'INVALIDSESSION'];
const BUTTON_INCLUDE = [SODL_OUT];

class CustomErrorPageComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const state: any = this.props.history.location.state;

    let message = this.props.message;
    if (isEmpty(message)) {
      if (state?.data) {
        message = state.data;
      }
    }

    const displayMessages = !MESSAGE_EXCLUDE.includes(message);
    const displayGoBackSearchButton = BUTTON_INCLUDE.includes(message);

    const image = message === SODL_OUT ? GeneralError : NoHotelResults;

    return (
      <div className="error-page">
        {this.props.displayExclamationIcon ? (<label style={{ color: 'red' }}><FontAwesomeIcon icon={faTriangleExclamation} size="4x" /></label>) : (<img className="error-page__image" src={image} />)}
        {isEmpty(message) ? (<label className="error-page__title"><FormattedMessage id={'error.custom.error.title'} /></label>) : null}
        {!isEmpty(message) ? (<label className="error-page__external-message"><FormattedMessage id={`error.code.${message}`} /></label>) : null}
        {displayMessages ? (<label className="error-page__message"><FormattedMessage id={'error.custom.error.retry'} /></label>) : null}
        {displayMessages ? (<label className="error-page__message-retry"><FormattedMessage id={'error.custom.error.contact_support'} /></label>) : null}
        {displayGoBackSearchButton ? (<BlueButton onClick={() => this.props.history.push(`/${this.props.account?.name}${Routes.Search}${location.search}`)}><FormattedMessage id={'go.back.search'} /></BlueButton>) : null}
      </div>
    );
  }
}

export const CustomErrorPage = withRouter(CustomErrorPageComponent);