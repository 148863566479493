import React from 'react';
import Card from '../card/component';

const RenderFeatureCard = ({ card }: any) => (
  <Card
    imgSrc={card.imageSrc}
    title={card.title}
    description={card.description}
    buttonText={card.buttonText}
  />
);

export default RenderFeatureCard;
