import React from 'react';

export const MenuUnfoldOutlinedSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="white" />
    <path
      d="M6.14314 6.75056H14.7146C14.7931 6.75056 14.8574 6.68627 14.8574 6.6077V5.6077C14.8574 5.52913 14.7931 5.46484 14.7146 5.46484H6.14314C6.06456 5.46484 6.00028 5.52913 6.00028 5.6077V6.6077C6.00028 6.68627 6.06456 6.75056 6.14314 6.75056ZM6.00028 10.3934C6.00028 10.472 6.06456 10.5363 6.14314 10.5363H14.7146C14.7931 10.5363 14.8574 10.472 14.8574 10.3934V9.39341C14.8574 9.31484 14.7931 9.25056 14.7146 9.25056H6.14314C6.06456 9.25056 6.00028 9.31484 6.00028 9.39341V10.3934ZM15.0003 1.71484H1.00028C0.921708 1.71484 0.857422 1.77913 0.857422 1.8577V2.8577C0.857422 2.93627 0.921708 3.00056 1.00028 3.00056H15.0003C15.0788 3.00056 15.1431 2.93627 15.1431 2.8577V1.8577C15.1431 1.77913 15.0788 1.71484 15.0003 1.71484ZM15.0003 13.0006H1.00028C0.921708 13.0006 0.857422 13.0648 0.857422 13.1434V14.1434C0.857422 14.222 0.921708 14.2863 1.00028 14.2863H15.0003C15.0788 14.2863 15.1431 14.222 15.1431 14.1434V13.1434C15.1431 13.0648 15.0788 13.0006 15.0003 13.0006ZM0.918136 8.12377L3.70921 10.322C3.81278 10.4041 3.96635 10.3309 3.96635 10.1988V5.80234C3.96635 5.6702 3.81456 5.59699 3.70921 5.67913L0.918136 7.87734C0.899369 7.89193 0.884182 7.91062 0.873736 7.93197C0.863289 7.95333 0.857859 7.97679 0.857859 8.00056C0.857859 8.02433 0.863289 8.04779 0.873736 8.06914C0.884182 8.0905 0.899369 8.10918 0.918136 8.12377Z"
      fill="black"
    />
  </svg>
);
