import React from 'react';
import debounce from 'lodash/debounce';
import Highlighter from 'react-highlight-words';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Select, Spin } from 'antd';
import { OptionGroupData } from 'rc-select/lib/interface';
import { RefSelectProps, SelectValue } from 'antd/lib/select';
import { ThunkDispatch } from 'redux-thunk';

import { RootState, getPreferenceFromAccount } from '@share/utils';
import { CONDO_RECENT_SEARCHES_LABEL, ENTER_KEY } from '@share/constants';
import { ICondosLocationPickerState, getCondoLocations, condosLocationPickerActions } from '@share/store/slices';
import { CondoLocationsEnum, ICondoLocation, SEARCH_ICONS_COLOR_FIELD_KEY } from '@share/common-types';
import { ILoginState } from '@share/store/slices';

import { ClearSvg, LocationGreySvg, CondoSvg } from '@share/assets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

import { ModalCondoLocationDestinations } from '../modal-condo-location-destinations';

import './style.scss';

interface IMapStateToProps {
  condosLocationPickerStore: ICondosLocationPickerState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getCondoLocations: (search: string, selectedLocation: ICondoLocation | undefined, onlyCities: boolean) => void;
  selectLocation: (location: ICondoLocation) => void;
  selectLocationLabel: (locationLabel: string) => void;
  selectLocationExperiences: (location: ICondoLocation) => void;
  selectLocationLabelExperiences: (locationLabel: string) => void;
  setLocations: (locations: ICondoLocation[]) => void;
  setLoading: () => void;
  clearError: () => void;
  setShouldFocus: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  isExperiences?: boolean;
  isOnlyPicker?: boolean;
  isSmall?: boolean;

  disable?: boolean;

  openOnClick?: boolean;

  onFireSearch?: () => void;
  onSelect?: () => void;
}

interface IState {
  isFocused: boolean;
  isDropdownOpen: boolean;
  displayAllDestinations: boolean;
}

const zero = 0;
const MIN_SEARCH_LENGTH = 3;
const LIST_HEIGHT = 500;
const SEARCH_DEBOUNCE = 500;
const { Option, OptGroup } = Select;

class CondoLocationPickerComponent extends React.Component<IProps, IState> {
  selectRef: React.RefObject<RefSelectProps> = React.createRef();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    isFocused: false,
    isDropdownOpen: false,
    displayAllDestinations: false
  };
  lastSearch = '';
  lastSelectedLocation: ICondoLocation;

  getGroupedLocation = (): {
    destinations: ICondoLocation[];
    condos: ICondoLocation[];
    noAvail: ICondoLocation[];
  } => {
    const { condosLocationPickerStore } = this.props;
    const { locations, selectedLocationLabel } = condosLocationPickerStore;
    const popularLocations = this.getPopularLocations();
    const locationsToShow: ICondoLocation[] = !selectedLocationLabel ? popularLocations : locations;
    const destinations: ICondoLocation[] = [];
    const condos: ICondoLocation[] = [];
    const noAvail: ICondoLocation[] = [];

    locationsToShow.forEach((location: ICondoLocation) => {
      switch (location.locationType) {
        case CondoLocationsEnum.GenericRegion: {
          destinations.push(location);
          break;
        }
        case CondoLocationsEnum.GenericCondo: {
          condos.push(location);
          break;
        }
        case CondoLocationsEnum.NoAvailability: {
          noAvail.push(location);
          break;
        }
        default:
          return null;
      }
    });

    return {
      destinations: !selectedLocationLabel ? destinations.slice(0, 3) : destinations,
      condos: condos.slice(0, 3),
      noAvail: !selectedLocationLabel ? noAvail.slice(0, 1) : noAvail.slice(0, 3)
    };
  };

  getOptionGroup = (
    array: ICondoLocation[],
    label: string,
    icon: React.ReactNode,
  ): React.ReactNode | null => {
    return array.length ? (
      <OptGroup label={<FormattedMessage id={label} />}>
        {array.map((location: ICondoLocation) => {
          const subhead = this.getOptionSubheadeing(location);
          return (
            <Option
              key={location.id}
              value={location.id}
              label={location.name}
              className="locations-dropdown__option condo"
            >
              <span className="locations-dropdown__option-icon">{icon}</span>
              <span className="locations-dropdown__location-name-wrapper">
                <span className="locations-dropdown__location-name">
                  <Highlighter
                    highlightClassName="bold-text"
                    searchWords={[this.lastSearch.trim()]}
                    autoEscape={true}
                    textToHighlight={location.name}
                  />
                </span>
                {this.getOptionSubheadeingFragment(subhead)}
              </span>
            </Option>
          );
        })}
      </OptGroup>
    ) : null;
  };

  onClear = () => {
    const { openOnClick, selectLocation, selectLocationLabel, setLocations } = this.props;
    if (openOnClick) {
      this.setState({ isFocused: false, isDropdownOpen: false }, () => {
        selectLocation(undefined);
        selectLocationLabel('');
        setLocations([]);
      });
    }
  }
    
  onFocus = () => {
    const { isFocused } = this.state;
    const { openOnClick } = this.props;

    if (!openOnClick) {
      if (!isFocused) {
        this.onSearch(this.props.condosLocationPickerStore.selectedLocationLabel || '');
      }
  
      this.setState({ isFocused: true, isDropdownOpen: true });
    }
  };

  debouncedSearch = debounce((search: string) => {
    this.props.getCondoLocations(search, this.props.condosLocationPickerStore.selectedLocation, this.props.isExperiences);
  }, SEARCH_DEBOUNCE);

  onSearch = (search: string) => {
    const { openOnClick } = this.props;

    this.lastSearch = search;
    this.setState({ isDropdownOpen: !openOnClick });

    if (search.length >= MIN_SEARCH_LENGTH) {
      this.props.setLoading();
      this.debouncedSearch(search);
    } else {
      this.props.setLocations([]);
    }
  };

  onChange = (value: SelectValue, option: OptionGroupData) => {
    const {
      isExperiences,
      openOnClick,
      selectLocation,
      selectLocationLabel,
      selectLocationExperiences,
      selectLocationLabelExperiences,
      setLocations,
      clearError,
      onSelect,
      condosLocationPickerStore,
    } = this.props;
    const { locations } = condosLocationPickerStore;
    let location = locations.find(({ id }) => id === (option?.key as string));
    const locationName = location?.name ? location.name : option?.label ?? value;

    if (openOnClick && !this.state.displayAllDestinations) {
      this.setState({ displayAllDestinations: true, isFocused: false });
    } else {
      if (!location) {
        const popularLocations = this.getPopularLocations();
        location = popularLocations.find(({ id }) => id === (option?.key as string));
      }
  
      if (!value) {
        if (openOnClick) {
          this.setState({ isDropdownOpen: false, isFocused: true });
        } else {
          this.setState({ isDropdownOpen: true, isFocused: true });
        }
      } else {
        this.setState({ isDropdownOpen: false, isFocused: true });
      }
  
      if (isExperiences) {
        selectLocationLabelExperiences(locationName as string);
        selectLocationExperiences(location);
      } else {
        selectLocationLabel(locationName as string);
        selectLocation(location);
  
        if (onSelect && location) {
          onSelect();
        }
      }
  
      setLocations([]);
  
      this.lastSelectedLocation = location;
  
      if (option?.key) {
        clearError();
      }
    }
  };

  focusSelect = () => {
    if (this.selectRef && this.selectRef.current && !this.state.displayAllDestinations) {
      this.selectRef.current.focus();
    }
  };

  componentDidUpdate() {
    if (this.props.condosLocationPickerStore.shouldFocus) {
      this.focusSelect();
      this.props.setShouldFocus();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getPopularLocations = (): ICondoLocation[] => {
    const { isExperiences } = this.props;
    const recentSearchesStorage = localStorage.getItem(CONDO_RECENT_SEARCHES_LABEL);
    const recentSearches = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
    let popularLocations = [];
    if (recentSearches?.length) {
      popularLocations = recentSearches.filter((rs: any) => !isExperiences || rs?.location?.locationType === CondoLocationsEnum.GenericRegion).map((rs: any) => rs.location).filter((v: any, i: number, a: any)=>a.findIndex((v2: any) => v2.id === v.id) === i).slice(0, 5);
    }

    return popularLocations;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (!this.props.openOnClick) {

      if (
        (this.wrapperRef &&
          !this.wrapperRef.current.contains(event.target as Node) &&
          !(event.target as HTMLElement).closest('.ant-select-clear')) ||
        (event.target as HTMLElement).classList.contains('condo-location-picker__fixed-bg')
      ) {
        this.setState({ isFocused: false, isDropdownOpen: false });
        this.props.setLocations([]);
  
        this.lastSearch = '';
      }
  

    }
  };

  getPopupContainer = () => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  handleSelectDestination = (location: ICondoLocation) => {
    const { isExperiences, selectLocation, selectLocationExperiences, setLocations, selectLocationLabel, selectLocationLabelExperiences, clearError } = this.props;
    
    this.setState({ isDropdownOpen: false, isFocused: true });

    if (isExperiences) {
      selectLocationLabelExperiences(location.name);
      selectLocationExperiences(location);
    } else {
      selectLocationLabel(location.name);
      selectLocation(location);
    }

    setLocations([]);

    clearError();
    this.lastSelectedLocation = location;
  }

  getDropdown = (menu: React.ReactElement): React.ReactElement => {
    const { openOnClick } = this.props;

    if (openOnClick) {
      return null;
    }

    return (
      <div className="locations-dropdown">
        {menu}
      </div>
    );
  };

  getNotFoundContent = (): React.ReactNode => {
    const { condosLocationPickerStore } = this.props;
    const { selectedLocationLabel, loading } = condosLocationPickerStore;
    let message: React.ReactNode;

    if (selectedLocationLabel?.length < MIN_SEARCH_LENGTH) {
      message = <FormattedMessage id="search.min.symbols" />;
    } else if (loading) {
      message = <FormattedMessage id="searching" />;
    } else {
      message = (
        <div><FormattedMessage id="unfortunately.there.are.no.results" />
          <div><FormattedMessage id="please.try.again.or.see.all.destinations" /></div>
        </div>
      );
    }

    return <div className="locations-dropdown__nothing-found">{message}</div>;
  };

  onClick = (event: React.MouseEvent<HTMLElement>) => {
    const {
      condosLocationPickerStore,
      openOnClick,
      setLocations
    } = this.props;
    const { selectedLocationLabel } = condosLocationPickerStore;
    const { isFocused, isDropdownOpen } = this.state;

    if (openOnClick) {
      this.setState({ displayAllDestinations: true });
    } else {
      if (
        selectedLocationLabel &&
        isFocused &&
        !isDropdownOpen &&
        event.target &&
        (event.target as HTMLElement).classList.contains('ant-select-selection-search-input')
      ) {
        this.onSearch(selectedLocationLabel);
      } else if (
        (event.target as HTMLElement).classList.contains('ant-select-item-option-selected') ||
        (event.target as HTMLElement).closest('.ant-select-item-option-selected')
      ) {
        if (this.lastSelectedLocation) {
          this.setState({ isDropdownOpen: false, isFocused: true });
          setLocations([]);
        }
      }
    }
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    const { isDropdownOpen, isFocused } = this.state;
    const {
      isExperiences,
      condosLocationPickerStore,
      selectLocationLabel,
      selectLocation,
      selectLocationLabelExperiences,
      selectLocationExperiences,
      setLocations,
      clearError,
    } = this.props;
    const { condos, destinations, noAvail } = this.getGroupedLocation();
    const { locations } = condosLocationPickerStore;

    if (
      e.code === ENTER_KEY &&
      isDropdownOpen &&
      isFocused &&
      locations.length &&
      !this.lastSelectedLocation
    ) {
      let item = destinations[zero];

      if (!item) {
        item = condos[zero];
      }

      if (!item) {
        item = noAvail[zero];
      }

      if (item) {
        this.setState({ isDropdownOpen: false, isFocused: true });

        if (isExperiences) {
          selectLocationLabelExperiences(item.name);
          selectLocationExperiences(item);
        } else {
          selectLocationLabel(item.name);
          selectLocation(item);
        }

        setLocations([]);

        clearError();
      }
    }
  };

  onSelect = (value: SelectValue, option: OptionGroupData) => {
    if (!this.props.openOnClick) {

      if (value && !this.lastSelectedLocation) {
        const { isExperiences, condosLocationPickerStore, selectLocation, selectLocationExperiences } = this.props;
        const { locations } = condosLocationPickerStore;
        let location = locations.find(({ id }) => id === (option?.key as string));

        if (!location) {
          const popularLocations = this.getPopularLocations();
          location = popularLocations.find(({ id }) => id === (option?.key as string));
        }

        if (isExperiences) {
          selectLocationExperiences(location);
        } else {
          selectLocation(location);
        }

        this.lastSelectedLocation = location;
      }

    }
  };

  getOptionSubheadeing = (location: ICondoLocation): string | null => {
    let text = location.keywords?.join(', ');
    if (location.locationType === CondoLocationsEnum.GenericCondo && (location.region?.length > 0)) {
      text = text?.length > 0
        ? `${text}, ${location.region}`
        : location.region;
    }

    return text;
  };

  getOptionSubheadeingFragment = (subhead?: string): React.ReactNode | null => {
    return subhead?.length > 0 ? (
      <span className="locations-dropdown__location-keywords">
        <Highlighter
          highlightClassName="bold-text"
          searchWords={[this.lastSearch.trim()]}
          autoEscape={true}
          textToHighlight={subhead}
        />
      </span>
    ) : null;
  };

  render(): React.ReactNode {
    const { condosLocationPickerStore, loginStore, isOnlyPicker, isSmall, disable, openOnClick } = this.props;
    const { selectedLocationLabel, loading, error } = condosLocationPickerStore;
    const { isFocused, isDropdownOpen, displayAllDestinations } = this.state;
    const { condos, destinations, noAvail } = this.getGroupedLocation();
    
    const account = loginStore?.account;
    const color: any = account ? getPreferenceFromAccount(account, SEARCH_ICONS_COLOR_FIELD_KEY) : null;
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div className={`condo-location-picker ${isSmall ? 'small' : ''} ${!!error ? 'error' : ''}`} ref={this.wrapperRef}>
        <div
          className={`condo-location-picker__wrapper ${isOnlyPicker ? 'only-picker' : ''} ${isFocused ? 'selected' : ''} ${isDropdownOpen ? 'dd-open' : ''}`}
          onClick={this.focusSelect}
        >
          <div className="condo-location-picker__left">
            <div className="condo-location-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
          </div>
          <div className="condo-location-picker__right">
            {!isSmall && (
              <div className="condo-location-picker__label">
                <FormattedMessage id="location" />
              </div>)}

            <div className={`condo-location-picker__input ${openOnClick ? 'open-click' : ''}`}>
              <AutoComplete
                onKeyDown={this.onKeyDown}
                virtual={false}
                showSearch={true}
                placeholder={openOnClick ? <FormattedMessage id="condo.search.description.click" /> : <FormattedMessage id="condo.search.description" />}
                onChange={this.onChange}
                onSearch={this.onSearch}
                onFocus={this.onFocus}
                onClear={this.onClear}
                showArrow={false}
                ref={this.selectRef}
                open={isDropdownOpen}
                defaultActiveFirstOption={false}
                filterOption={() => true}
                autoClearSearchValue={false}
                dropdownRender={this.getDropdown}
                getPopupContainer={this.getPopupContainer}
                listHeight={LIST_HEIGHT}
                allowClear={!openOnClick}
                value={selectedLocationLabel}
                onSelect={this.onSelect}
                notFoundContent={this.getNotFoundContent()}
                onClick={this.onClick}
                disabled={disable}
                clearIcon={
                  <span className="condo-location-picker__clear">
                    {loading && isDropdownOpen ? <Spin /> : <ClearSvg />}
                  </span>
                }
              >
                {this.getOptionGroup(destinations, 'destinations.prequote.availability', <LocationGreySvg />)}
                {this.getOptionGroup(condos, 'condo.prequote.availability', <CondoSvg />)}
                {this.getOptionGroup(noAvail, 'condo.prequote.noavailability', <LocationGreySvg />)}
              </AutoComplete>
            </div>
          </div>
        </div>

        <ModalCondoLocationDestinations 
          visible={displayAllDestinations}
          onSelect={this.handleSelectDestination}
          onQuick={this.props.onFireSearch}
          onCancel={() => this.setState({ displayAllDestinations: false, isFocused: false }, () => this.selectRef?.current?.blur())}
        />

        {isDropdownOpen && <div className="condo-location-picker__fixed-bg" />}
        {error && <div className="condo-location-picker__error">{error}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosLocationPickerStore: state.condosLocationPickerStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getCondoLocations: (search: string, selectedLocation: ICondoLocation | undefined, onlyCities: boolean) => {
    dispatch(getCondoLocations(search, selectedLocation, onlyCities));
  },
  setLocations: (locations: ICondoLocation[]) => {
    dispatch(condosLocationPickerActions.setLocations(locations));
  },
  selectLocation: (location: ICondoLocation) => {
    dispatch(condosLocationPickerActions.selectLocation(location));
  },
  selectLocationLabel: (locationLabel: string) => {
    dispatch(condosLocationPickerActions.selectLocationLabel(locationLabel));
  },
  selectLocationExperiences: (location: ICondoLocation) => {
    dispatch(condosLocationPickerActions.selectLocationExperiences(location));
  },
  selectLocationLabelExperiences: (locationLabel: string) => {
    dispatch(condosLocationPickerActions.selectLocationLabelExperiences(locationLabel));
  },
  setLoading: () => {
    dispatch(condosLocationPickerActions.setLoading(true));
  },
  clearError: () => {
    dispatch(condosLocationPickerActions.setError(''));
  },
  setShouldFocus: () => {
    dispatch(condosLocationPickerActions.setShouldFocus(false));
  },
});

export const CondoLocationPicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondoLocationPickerComponent);
