import { isEmpty } from 'lodash';


const defaultLocale = 'en-US';
const CURRENCY_STYLE = 'currency';
const CURRENCY = 'USD';

export const formatMoneyCurrency = (currency?: string) => {
    return new Intl.NumberFormat(defaultLocale, {
      style: CURRENCY_STYLE,
      currency: !isEmpty(currency) ? currency : CURRENCY,
    })
  };
  