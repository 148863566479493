import React, { useEffect, useState } from 'react';
import SideNav from '../../components/dashboard/side-nav/component';
import useApi from '../../../api-hook/component';
import { Pagination, Spin, Input } from 'antd';
import FilterableHeader from './filterable-header';
import BookingsTable from './bookingsTable';
import { BOOKING_STATUS } from '../../../constants/index';

const { Search } = Input;

const Bookings = () => {
  const { fetchData } = useApi('https://qa2-api.travcoding.com:4000');
  const [loading, setLoading] = useState(true);
  const [bookingData, setBookingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchUserData = async (page = 1, pageSize = 10, search = '') => {
    setLoading(true);
    try {
      const searchParam = search ? `&propertyName=${encodeURIComponent(search)}` : '';
      const apiUrl = `/bookings/browsebookingbyuser?orderBy=createdAt&sortOrder=desc${searchParam}&page=${page}&results=${pageSize}`;

      const result = await fetchData(apiUrl, {}, 'get');

      setBookingData(result?.items);
      setTotalResults(result?.totalResults || 0);
      setLoading(false);
    } catch (error) {
      console.log('Error in getting user data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(currentPage, pageSize, searchQuery);
  }, [currentPage, pageSize, searchQuery]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const filterBookingsByStatus = (status) => {
    return bookingData?.filter((booking) => booking?.bookingStatusEnum === status);
  };

  const activeBookings = filterBookingsByStatus(BOOKING_STATUS.CONFIRMED);
  const canceledBookings = filterBookingsByStatus(BOOKING_STATUS.CANCELLED);
  const errorBookings = filterBookingsByStatus(BOOKING_STATUS.ERROR);
  const unconfirmedBookings = filterBookingsByStatus(BOOKING_STATUS.UNCONFIRMED);

  const bookingsData = [
    {
      key: 'tab-active',
      title: 'Active trips',
      bookings: activeBookings,
    },
    {
      key: 'tab-canceled',
      title: 'Canceled trips',
      bookings: canceledBookings,
    },
    {
      key: 'tab-error',
      title: 'Error trips',
      bookings: errorBookings,
    },
    {
      key: 'tab-unconfirmed',
      title: 'Unconfirmed trips',
      bookings: unconfirmedBookings,
    },
  ];

  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1); // Reset to first page on new search
  };

  return (
    <section className="pt-3">
      <div className="container">
        <div className="row">
          <SideNav />
          <div className="col-lg-8 col-xl-9">
            <div className="card shadow-sm bg-transparent border">
              <div className="card-header bg-transparent border-bottom">
                <h4 className="card-header-title">
                  <i className="bi bi-suitcase-lg mx-2"></i>My Bookings
                </h4>
              </div>
              <div className="card-body p-2 p-sm-4">
                <Spin spinning={loading}>
                  <FilterableHeader
                    activeBookings={activeBookings}
                    canceledBookings={canceledBookings}
                    errorBookings={errorBookings}
                    unconfirmedBookings={unconfirmedBookings}
                  />

                  <div className="custom-table-search d-flex justify-content-end">
                    <Search
                      placeholder="Search bookings"
                      allowClear
                      onSearch={handleSearch}
                      className="booking-search-input"
                    />
                  </div>
                  <div className="tab-content">
                    {bookingsData?.map((tab, index) => (
                      <div
                        className={`tab-pane mt-3 ${index === 0 ? 'active show' : ''}`}
                        id={tab.key}
                        key={tab.key}
                      >
                        {!tab?.bookings?.length ? (
                          <div className="bg-secondary bg-opacity-10 text-center py-5">
                            <i className="bi bi-suitcase-lg h4 text-secondary mb-3"></i>
                            <div className="h5 text-secondary mb-2 fw-medium">
                              No {tab.title.toLowerCase()}
                            </div>
                            <div className="h6 text-secondary">
                              There are currently no {tab.title.toLowerCase()} to display.
                            </div>
                          </div>
                        ) : (
                          <BookingsTable bookings={tab.bookings} />
                        )}
                      </div>
                    ))}
                  </div>
                </Spin>

                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalResults}
                  onChange={handlePageChange}
                  showSizeChanger
                  pageSizeOptions={['10', '25', '50', '100']}
                  className="paginations-page-size"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bookings;
