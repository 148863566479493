import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Carousel, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { CarouselRef } from 'antd/lib/carousel';
import {
  IDetails,
  ISurrounding,
  ISurroundingItem,
} from '@share/common-types';
import {
  ProximityType,
  SurroundingType,
} from '@share/common-types';
import { Map } from '@utils';

import { LocationMapSvg, InfoSvg } from '@assets';
import MapMarker from '@assets/images/map-bed-marker.svg';

import './style.scss';

interface IProps {
  condoDetails: IDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
  showBigMap: () => void;
}

interface IState {
  currentIndexSlide: number;
  isEllipsis: boolean;
}

const ZERO = 0;
const ONE = 1;

const { Paragraph } = Typography;

export class CondoLocation extends React.Component<IProps, IState> {
  state: IState = {
    currentIndexSlide: null,
    isEllipsis: true,
  };

  smallMap: React.RefObject<HTMLDivElement> = React.createRef();
  smallMapInstance: google.maps.Map;
  carouselRef: React.RefObject<CarouselRef> = React.createRef();

  handleNext = (): void => {
    this.carouselRef.current.next();

    setTimeout(() => {
      const { currentSlide } = this.carouselRef.current.innerSlider.state;
      this.setState({ currentIndexSlide: currentSlide + ONE });
    });
  };

  handlePrev = (): void => {
    this.carouselRef.current.prev();

    setTimeout(() => {
      const { currentSlide } = this.carouselRef.current.innerSlider.state;
      this.setState({ currentIndexSlide: currentSlide + ONE });
    });
  };

  getPoximityMessage = (proximity: ProximityType = ProximityType.nearby): React.ReactNode => (
    <FormattedMessage id={`proximity.${proximity}.title`} />
  );

  getDistance = (item: ISurroundingItem): React.ReactNode => {
    return (
      (item.distanceInKms > ZERO || item.distanceInMiles > ZERO) && (
        <p className="condo-location__distance">
          (
          {item.distanceInKms > ZERO ? (
            <FormattedMessage id="distance.km" values={{ distance: item.distanceInKms }} />
          ) : null}
          {item.distanceInKms > ZERO && item.distanceInMiles > ZERO && '/'}
          {item.distanceInMiles > ZERO ? (
            <FormattedMessage id="distance.miles" values={{ distance: item.distanceInMiles }} />
          ) : null}
          )
        </p>
      )
    );
  };

  getItems = (surrounding: ISurrounding): React.ReactNode => {
    return surrounding.items.map((item, i) => {
      return (
        <div className="condo-location__items" key={i}>
          <p className="condo-location__items-name">{item.name}</p>
          <div className="condo-location__items-distance">
            {surrounding.type === SurroundingType.closestAirports
              ? null
              : this.getPoximityMessage(item.proximity)}
            {this.getDistance(item)}
          </div>
        </div>
      );
    });
  };

  getAttractions = (): ISurrounding[] => {
    const { surroundings } = this.props.condoDetails;
    return isArray(surroundings) ? surroundings : [];
  };

  componentDidMount(): void {
    const { condoDetails } = this.props;
    const { location, id } = condoDetails;

    if (!isEmpty(this.getAttractions())) {
      this.setState({
        currentIndexSlide: this.carouselRef.current.innerSlider.state.currentSlide + ONE,
      });
    }

    if (this.smallMap && this.smallMap.current) {
      this.smallMapInstance = Map.initializeMap(
        this.smallMap.current,
        Map.getGoogleLocation(location),
        { disableDefaultUI: true },
      );
      Map.addMarkers(this.smallMapInstance, [{ location, id }], MapMarker);
    }
  }

  showMore = (): void => {
    this.setState({ isEllipsis: !this.state.isEllipsis });
  };

  render(): React.ReactNode {
    const { currentIndexSlide, isEllipsis } = this.state;
    const { condoDetails, refAnchor, showBigMap } = this.props;
    const { address, city, country, drivingDirectionsFromNearestAirport } = condoDetails;

    return (
      <div className="condo-location" ref={refAnchor}>
        <h2 className="condo-location__title">
          <FormattedMessage id="location" />
        </h2>
        <div className="condo-location__wrapper">
          <div className="condo-location__address-wrapper mobile">
            <LocationMapSvg />
            <p className="condo-location__address">
              {address}, {city}, {country}
            </p>
          </div>
          <div className="condo-location__info-wrapper">
            <div className="condo-location__address-wrapper">
              <LocationMapSvg />
              <p className="condo-location__address">
                {address}, {city}, {country}
              </p>
            </div>
            {!isEmpty(this.getAttractions()) && (
              <div className="condo-location__surroundings-wrapper">
                <Carousel dots={false} ref={this.carouselRef}>
                  {this.getAttractions().map((item: ISurrounding, index) => {
                    return (
                      <div className="condo-location__surroundings" key={index}>
                        <p className="condo-location__surroundings-title">
                          <FormattedMessage id={`surrounding.${item.type}.title`} />
                        </p>
                        {this.getItems(item)}
                        <div className="condo-location__surroundings-info">
                          <InfoSvg />
                          <p className="condo-location__surroundings-info-text">
                            <FormattedMessage id="all.distances.are.measured" />
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
                {this.getAttractions().length !== ONE ? (
                  <div className="condo-location__arrows">
                    <p className="condo-location__arrow arrow-prev" onClick={this.handlePrev} />
                    <p className="condo-location__arrows-info">
                      {currentIndexSlide}-{this.getAttractions().length} from{' '}
                      {this.getAttractions().length}
                    </p>
                    <p className="condo-location__arrow arrow-next" onClick={this.handleNext} />
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div className="condo-location__map-wrapper">
            <div className="condo-location__map" ref={this.smallMap} />
            <div className="condo-location__map-button" onClick={showBigMap}>
              <FormattedMessage id="view.on.map" />
            </div>
          </div>
        </div>
        {!isEmpty(drivingDirectionsFromNearestAirport) && (
          <div className="condo-location__directions">
            <p className="condo-location__directions-title">
              <FormattedMessage id="driving.directions" />:
            </p>
            <Paragraph
              className="condo-location__directions-description"
              ellipsis={isEllipsis ? { rows: 2, expandable: true } : false}
            >
              {drivingDirectionsFromNearestAirport}
            </Paragraph>

            <p className="condo-location__read-more-button capitalize" onClick={this.showMore}>
              {isEllipsis ? <FormattedMessage id="more" /> : <FormattedMessage id="hide" />}
            </p>
          </div>
        )}
      </div>
    );
  }
}

