import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorAlertSvg } from '@assets';
import './style.scss';

interface IProps {
  supportPhone: string;
}

export class CancellationUnknown extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { supportPhone } = this.props;

    return (
      <div className="cancellation-unknown">
        <div>
          <ErrorAlertSvg />
          <p className="cancellation-unknown__title">
            <FormattedMessage id="cancellation.unknown" />
          </p>
          <p className="cancellation-unknown__description">
            <FormattedMessage id="unfortunately.cancellation" />
          </p>
          <p className="cancellation-unknown__description">
            <FormattedMessage id="please.try.again.later" />
          </p>
        </div>
        <div className="cancellation-unknown__footer">
          <p className="cancellation-unknown__footer-text">
            <FormattedMessage
              id="cancellation.failed.if.you.have.any.questions"
              values={{ phone: supportPhone }}
            />
          </p>
        </div>
      </div>
    );
  }
}
