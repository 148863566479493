import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  IPopularDestinationsOffersState,
} from '@store/slices';
import { getPreferenceFromAccount, RootState } from '@share/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import { isEmpty } from 'lodash';
import { ABOUTUS_FACEBOOK_FIELD_KEY, ABOUTUS_ICON_COLOR_FIELD_KEY, ABOUTUS_INSTAGRAM_FIELD_KEY, ABOUTUS_MESSAGE_FIELD_KEY, ABOUTUS_PINTEREST_FIELD_KEY, ABOUTUS_TWITTER_FIELD_KEY, ABOUTUS_VIDEO_FIELD_KEY, ABOUTUS_YOUTUVE_FIELD_KEY } from '@share/common-types';

interface IMapStateToProps {
  popularDestinationsOffersStore: IPopularDestinationsOffersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class AboutUsComponent extends React.Component<IProps, null> {

  render() {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const facebookUrl = getPreferenceFromAccount(account, ABOUTUS_FACEBOOK_FIELD_KEY);
    const instagramUrl = getPreferenceFromAccount(account, ABOUTUS_INSTAGRAM_FIELD_KEY);
    const pinterestUrl = getPreferenceFromAccount(account, ABOUTUS_PINTEREST_FIELD_KEY);
    const twitterUrl = getPreferenceFromAccount(account, ABOUTUS_TWITTER_FIELD_KEY);
    const youtuveUrl = getPreferenceFromAccount(account, ABOUTUS_YOUTUVE_FIELD_KEY);
    const videoUrl = getPreferenceFromAccount(account, ABOUTUS_VIDEO_FIELD_KEY);

    if (isEmpty(facebookUrl) && isEmpty(instagramUrl) && isEmpty(pinterestUrl) && isEmpty(twitterUrl) && isEmpty(youtuveUrl) && isEmpty(videoUrl)) {
      return null;
    }

    const color: any = account ? getPreferenceFromAccount(account, ABOUTUS_ICON_COLOR_FIELD_KEY) : null;
    const message: any = account ? getPreferenceFromAccount(account, ABOUTUS_MESSAGE_FIELD_KEY) : null;
    const styleColor = !isEmpty(color)? { color, border: `2px solid ${color}` } : {};  

    return (
      <div className="about-us">
        <p className="about-us__title">
          <FormattedMessage id="about-us.title" />
        </p>
        
        <div className="about-us__wrapper">
          <div className="about-us__text">
            <div className="message">
              {message && !isEmpty(message) ? message : <FormattedMessage id={'about-us.message'} />}
            </div>
            <div className="social">
              {!isEmpty(facebookUrl) && (<a href={`https://www.facebook.com/${facebookUrl}`} target="_blanc">
                <div className="social-item" style={styleColor}>
                  <FontAwesomeIcon icon={faFacebookF} size={'2x'} />
                </div>
              </a>)}
              {!isEmpty(youtuveUrl) && (<a href={`https://www.youtube.com/c/${youtuveUrl}`} target="_blanc">
                <div className="social-item" style={styleColor}>
                  <FontAwesomeIcon icon={faYoutube} size={'2x'} />
                </div>
              </a>)}
              {!isEmpty(instagramUrl) && (<a href={`https://www.instagram.com/${instagramUrl}`} target="_blanc">
                <div className="social-item" style={styleColor}>
                  <FontAwesomeIcon icon={faInstagram} size={'2x'} />
                </div>
              </a>)}
              {!isEmpty(twitterUrl) && (<a href={`https://twitter.com/${twitterUrl}`} target="_blanc">
                <div className="social-item" style={styleColor}>
                  <FontAwesomeIcon icon={faTwitter} size={'2x'} />
                </div>
              </a>)}
              {!isEmpty(pinterestUrl) && (<a href={`https://pinterest.com/${pinterestUrl}`} target="_blanc">
                <div className="social-item" style={styleColor}>
                  <FontAwesomeIcon icon={faPinterest} size={'2x'} />
                </div>
              </a>)}
            </div>
          </div>
          {!isEmpty(videoUrl) && (<div className="about-us__video">
            <iframe
              width="100%"
              height="100%"
              src={`${videoUrl}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="About us"
            />
          </div>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    popularDestinationsOffersStore: state.popularDestinationsOffersStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {};

export const AboutUs = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutUsComponent);
