import React from 'react';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { formatMoneyCurrency } from '../../../../utils/currency';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface HotelImage {
  src: string;
  alt: string;
}

interface HotelDetails {
  name: string;
  location: string;
  guestReviews: number;
  guestReviewsCount: number;
  ratingStars: number;
  ratingHalf: boolean;
  originalPrice?: number;
  pricePerNight: number;
  totalPrice: number;
  refundable: boolean;
  savings?: any;
  public_price?: any;
  currency?: any;
  details?: any;
  sessionKey?: any;
}
interface HotelCardProps {
  images: HotelImage[];
  details: any;
  hotelId: string;
  decryptedData: any;
  sessionKey?: any;
  completeHotelResponse?: any;
  counters?: any;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

const HotelCard: React.FC<HotelCardProps> = ({
  images,
  details,
  decryptedData,
  completeHotelResponse,
  hotelId,
  sessionKey,
  counters,
}) => {
  const history = useHistory();
  const login = useSelector((state: IMapStateToProps) => state.loginStore);

  const dateObject = {
    checkIn: decryptedData.hotelsRequest.checkIn,
    checkOut: decryptedData.hotelsRequest.checkOut,
  };

  const handleSubmit = () => {
    history.push(
      `/${login.account.name}/hotel/${hotelId}?dates=${Base64.encode(
        JSON.stringify(dateObject),
      )}&&rooms=${Base64.encode(
        JSON.stringify(decryptedData.hotelsRequest.rooms),
      )}&&location=${Base64.encode(
        JSON.stringify(decryptedData.hotelsRequest.location),
      )}&&sessionKey=${Base64.encode(JSON.stringify(sessionKey))}`,
    );
  };

  const formatter = formatMoneyCurrency(details.currency);

  const renderStars = (rating) => {
    const fullStars = parseInt(rating);
    const totalStars = 5;
    return Array.from({ length: totalStars }, (_, i) =>
      i < fullStars ? 'bi bi-star-fill text-warning' : 'bi bi-star text-warning',
    );
  };

  return (
    <div className="card custom-hotel-card shadow p-2">
      <div className="row g-0 pt-2">
        <div className="col-md-5 position-relative">
          <div className="position-absolute top-0 start-0 z-index-1 m-2">
            <div className="badge text-bg-success">
              Save {Math.floor((details?.savings / details?.public_price || 0) * 100)}%
            </div>
          </div>

          <div className="tiny-slider arrow-round arrow-xs arrow-dark overflow-hidden rounded-2 ">
            {images?.map((image: any, index) => (
              <img
                className="d-block w-100 custom-carosuel-card object-fit-cover"
                key={index}
                src={image.url}
                alt={image.alt}
              />
            ))}
            {/* <Carousel controls={true} indicators={false} fade={true}>
              {images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 custom-carosuel-card object-fit-cover"
                    src={image.url}
                    alt={image.alt}
                  />
                </Carousel.Item>
              ))}
            </Carousel> */}
          </div>
        </div>

        <div className="col-md-7">
          <div className="card-body py-md-2 d-flex flex-column h-100 position-relative">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="list-inline mb-1">
                {renderStars(details?.guestReviews).map((starClass, index) => (
                  <li key={index} className="list-inline-item me-0">
                    <i className={starClass}></i>
                  </li>
                ))}
              </ul>
            </div>

            <h5 className="card-title mb-1">{details?.name}</h5>
            <p>
              <i className="bi bi-geo-alt me-2"></i>
              {details?.location}
            </p>

            <ul className="nav mt-2 mb-2">
              <li className="nav-item rating">{details?.guestReviews?.toFixed(1)}</li>
              <li className="nav-item pt-1 mx-2 public_price">
                ({details?.guestReviewsCount} reviews)
              </li>
            </ul>

            <div className="d-sm-flex justify-content-sm-between align-items-center mt-3 mt-md-auto">
              <div className="d-flex flex-column justify-content-end height-full">
                <div className="public_price">
                  <span className="me-2">Public</span>
                  <span className="text-decoration-line-through mb-0">
                    {formatter.format(details?.public_price)}
                  </span>
                </div>
                <span className="savings-text fw-bold mt-2">
                  Savings {formatter.format(Math.abs(details?.savings))}
                  {/* {details.currency}{" "}
                  {Math.abs(Math.floor(details?.savings)).toLocaleString()} */}
                </span>
                <div className="d-flex align-items-center mt-2">
                  <h5 className="fw-bold mb-0">
                    {formatter.format(details?.pricePerNight)}
                    {/* {details.currency} {details?.pricePerNight.toLocaleString()} */}
                  </h5>
                  <span className="mb-0">/night</span>
                </div>
              </div>

              <div className="mt-3 mt-sm-0 text-sm-end pb-0">
                {details.refundable === 'nonrefundable' && (
                  <ul className="list-group list-group-borderless small mb-0 mt-2">
                    <li className="list-group-item d-flex text-danger p-0">
                      <i className="bi bi-patch-check-fill me-2"></i>Non Refundable
                    </li>
                  </ul>
                )}
                {details?.refundable === 'refundable' && (
                  <ul className="list-group list-group-borderless small mb-0 mt-0">
                    <li className="list-group-item d-flex text-success p-0">
                      <i className="bi bi-patch-check-fill me-2"></i>
                      Refundable Rooms
                    </li>
                  </ul>
                )}
                <div className="mb-2 pb-0 mt-2">
                  {formatter.format(details?.totalPrice)} for {counters?.totalNights}{' '}
                  {Math.round(details?.public_price / details.pricePerNight) > 1
                    ? 'nights'
                    : 'night'}
                </div>
                <button onClick={handleSubmit} className="btn btn-primary btn-sm mb-0 w-100 mt-0">
                  View Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
