import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { HotelDetailsWrapper } from '@components';
import {
  getTimeout,
  UrlUtils,
  scrollTop,
  getAccountUsernameFromPath,
  VACATIONS_PAGE,
} from '@share/utils';
import { ISessionKey, TRAVCODING_V2 } from '@share/common-types';
import { Routes, SESSION_KEY_LABEL } from '@share/constants';
import LogInNav from '../../components/common/log-in-nav/component';
import Footer from '../../components/new-home/footer/component';
import { DashboardHotel } from '../../pages/dashboard-hotel';
import { RootState } from '@share/utils';

import './style.scss';
import { ILoginState } from '@share/store/slices';

interface IProps extends RouteComponentProps {
  isFromAdminPage?: boolean;
  isVacationRentals?: boolean;
  loginStore: ILoginState;
}
interface IMapStateToProps {
  loginStore: ILoginState;
}

const zero = 0;

class HotelDetailPageComponent extends React.Component<IProps, null> {
  componentDidMount(): void {
    scrollTop();
    const values = UrlUtils.getValues();
    const session: ISessionKey = values[SESSION_KEY_LABEL] as ISessionKey;
    const accountName = getAccountUsernameFromPath(this.props.history);

    if (session && session.expireDate && getTimeout(session.expireDate) <= zero) {
      if (this.props.isVacationRentals) {
        history.replaceState(
          null,
          null,
          `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}`,
        );
      } else {
        history.replaceState(null, null, `/${accountName}${Routes.Search}${location.search}`);
      }

      location.reload();
    }
  }

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    return (
      <div className="hotel-details-page">
        {account?.homePageTemplate === TRAVCODING_V2 ? (
          <DashboardHotel />
        ) : (
          <HotelDetailsWrapper
            isFromAdminPage={this.props.isFromAdminPage}
            isVacationRentals={this.props.isVacationRentals}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const HotelDetailPage = connect(mapStateToProps)(withRouter(HotelDetailPageComponent));
