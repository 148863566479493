import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardAside from '../../components/dashboard2/dashboard-aside/component';
import TopNav from '../../components/dashboard2/top-nav/component';
import HotelCard from '../../components/dashboard/hotel-card/component';
import { Base64 } from 'js-base64';
import useApi from '../../../api-hook/component';
import HotelCardSkeleton from '../../components/dashboard/hotel-card-skeleton/component';
import InfiniteScroll from 'react-infinite-scroll-component';
import ErrorMessage from '../../components/common/error-message/component';
import { QUERY_PARAMS } from '../../../constants/index';
import { API_END_POINT } from '../../../constants/api-enums';

interface HotelImage {
  src: string;
  alt: string;
}
interface Hotel {
  hotelId: number;
  displayName: string;
  starRating: number;
  countryCode: string;
  zipCode: string;
  originalPrice?: number;
  discountedPrice?: number;
  totalPrice?: number;
  refundable?: boolean;
  images?: HotelImage[];
  currency: string;
}
const DashboardSearch = () => {
  const { fetchData } = useApi();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationQuery = queryParams.get(QUERY_PARAMS.LOCATION);
  const roomsQuery = queryParams.get(QUERY_PARAMS.ROOMS);
  const dateQuery = queryParams.get(QUERY_PARAMS.DATE);
  const locationData = Base64.decode(locationQuery);
  const parsedLocationData = JSON.parse(locationData) || [];
  const roomsData = Base64.decode(roomsQuery);
  const parsedRoomsData = JSON.parse(roomsData) || [];
  const datesData = Base64.decode(dateQuery);
  const parsedDate = JSON.parse(datesData) || [];
  const { checkIn, checkOut } = parsedDate;
  const [searchHotels, setSearchHotels] = useState<Hotel[]>([]);
  const [counters, setCounters] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [sessionKey, setSessionKey] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [payload, setPayload] = useState({
    hotelsRequest: {
      residency: 'US',
      locationCode: '657822',
      location: parsedLocationData || [],
      checkIn: checkIn || [],
      checkOut: checkOut || [],
      rooms: parsedRoomsData || [],
    },
    filter: {
      accessibilities: [],
      accommodationType: [],
      amenities: [],
      boardNames: [],
      budgetRanges: [],
      neighbourhoods: [],
      popularLocations: [],
      refundable: [],
      starRatings: [],
      propertyName: "",
      distanceInMiles: 10,
      exactSearch: true,
    },
    sortBy: 'BiggestSavingsPercentage',
    pageNumber: 1,
    pageSize: 10,
    quick: false,
    searchHomes: 'All',
    currency: 'USD',
  });

  async function fetchHotels(payload: any, loadingFunc?: any) {
    if (loadingFunc) {
      loadingFunc();
    }
    try {
      const result = await fetchData(API_END_POINT.HOTELS_SEARCH, payload, 'post');

      const totalPages = Math.ceil(result?.counters?.totalFilteredHotels / payload.pageSize);

      if (payload.pageNumber < totalPages) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      if (loadingFunc) {
        setSearchHotels(result?.searchHotels);
      } else {
        setSearchHotels((prev) => [...prev, ...result?.searchHotels]);
      }
      setCounters(result?.counters);
      setSessionKey(result?.sessionKey);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPayload({
      ...payload,
      hotelsRequest: {
        ...payload.hotelsRequest,
        location: parsedLocationData,
        checkIn: checkIn || [],
        checkOut: checkOut || [],
        rooms: parsedRoomsData || [],
      },
      pageNumber: 1,
    });

    fetchHotels(
      {
        ...payload,
        hotelsRequest: {
          ...payload.hotelsRequest,
          location: parsedLocationData,
          checkIn: checkIn || [],
          checkOut: checkOut || [],
          rooms: parsedRoomsData || [],
        },
        pageNumber: 1,
      },
      () => {
        setLoading(true);
      },
    );
  }, [locationQuery, dateQuery, roomsQuery]);

  return (
    <>
      <TopNav
        location={parsedLocationData}
        checkInDate={checkIn}
        checkOutDate={checkOut}
        rooms={parsedRoomsData}
        setPageNumber={setPageNumber}
      />
      <section className="pt-0">
        <div className="container">
          <div className="row">
            <DashboardAside
              setSearchHotels={setSearchHotels}
              setLoading={setLoading}
              fetchHotels={fetchHotels}
              payload={payload}
              setPayload={setPayload}
              setPageNumber={setPageNumber}
            />
            <div className={'col-xl-8 col-xxl-9'}>
              <div className="vstack gap-4">
                {!searchHotels?.length && !loading ? (
                  <ErrorMessage
                    heading="Oops! We got lost on the way..."
                    subHeading="For some reason we did not find any result for your search in"
                    text="Don't worry! Try again but changing your search criteria"
                  />
                ) : loading ? (
                  [...Array(2)]?.map((_, index) => <HotelCardSkeleton key={index} />)
                ) : (
                  <InfiniteScroll
                    dataLength={pageNumber * 10}
                    next={async () => {
                      await fetchHotels({
                        ...payload,
                        pageNumber: pageNumber + 1,
                      });
                      setPageNumber((prev) => prev + 1);
                    }}
                    hasMore={hasMore}
                    loader={[...Array(2)]?.map((_, index) => (
                      <HotelCardSkeleton key={index} />
                    ))}
                  >
                    {searchHotels?.length > 0 &&
                      searchHotels?.map((hotel: any, i) => (
                        <HotelCard
                          key={i}
                          sessionKey={sessionKey}
                          completeHotelResponse={searchHotels}
                          hotelId={hotel.hotelId}
                          decryptedData={payload}
                          images={hotel.images}
                          counters={counters}
                          details={{
                            name: hotel.displayName,
                            location: `${hotel.address}${hotel.city ? `, ${hotel.city}` : ''}${
                              hotel.state ? `, ${hotel.state}` : ''
                            }${hotel.zipCode ? `, ${hotel.zipCode}` : ''}`,
                            guestReviews: hotel?.rating?.value,
                            guestReviewsCount: hotel?.rating?.reviewsCount,
                            ratingStars: hotel?.starRating,
                            ratingHalf: hotel?.starRating % 10 === 5,
                            savings: hotel?.savings || 0,
                            public_price:
                              (hotel?.publicPrices && hotel?.publicPrices[0]?.price) || 0,
                            pricePerNight: hotel?.pricePerNight || 0,
                            totalPrice: hotel?.price || 0,
                            refundable: hotel?.refundability || false,
                            currency: hotel?.currency || 'USD',
                          }}
                        />
                      ))}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DashboardSearch;
