import React, { useRef } from 'react';
import { Carousel, Col, Row, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MoreFeatures } from '../../../../../utils/discover-features';
import RenderFeatureCard from './feature-card';

const DiscoverFeatures = () => {
  const carouselRef = useRef(null);

  const goToNext = () => {
    carouselRef.current.next();
  };

  const goToPrev = () => {
    carouselRef.current.prev();
  };

  return (
    <div
      className="discover-features-container"
      style={{ position: 'relative' }}
    >
      <Row justify="center" className="mb-4">
        <Col span={24} style={{ textAlign: 'center' }}>
          <h3>Discover more features</h3>
        </Col>
      </Row>
      <div className="discover-features-card mb-5">
        {MoreFeatures.map((card, index) => (
          <RenderFeatureCard key={index} card={card} />
        ))}
      </div>
      <div className="carsousel mb-6">
        <Button
          style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
          type="text"
          onClick={goToPrev}
        >
          <LeftOutlined style={{ fontSize: '24px' }} />
        </Button>
        <Carousel ref={carouselRef} dots={false}>
          {MoreFeatures.map((card, index) => (
            <div key={index}>
              <RenderFeatureCard card={card} />
            </div>
          ))}
        </Carousel>
        <Button
          style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
          type="text"
          onClick={goToNext}
        >
          <RightOutlined style={{ fontSize: '24px' }} />
        </Button>
      </div>
    </div>
  );
};

export default DiscoverFeatures;
