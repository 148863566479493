import React from 'react';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Checkbox, Form, Spin } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { v4 } from 'uuid';
import { get, isEmpty } from 'lodash';

import {
  getCondoBookPackageWithCard,
  ICondoReviewBookState,
  ICouponState,
  setCondoBookingComplete,
  setCondoGuest,
  setCondoPriceChangedError,
  setErrorMessageExpirationDate,
  setErrorMessageCardNumber,
  setErrorMessageZipCode,
  IReviewBookState,
  IInsuranceState,
  InsuranceSelection,
} from '@store/slices';
import { IMenuState, LoginType, getNavigationMenu, setLoginRedirect } from '@share/store/slices';
import { AppThunk, GetWalletNumber, RootState, getSelectedCurrency } from '@share/utils';
import {
  GetPreferenceValue,
  MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY,
  getAvailabilityId,
  getCondoId,
  getMemberPhone,
  removingDashFromString,
  updateCondoGuest,
} from '@utils';
import { getSessionObj, renderNumber, getFullUrl, UrlUtils } from '@share/utils';
import { BlueButton, Loading, LoadingSise } from '@share/components';
import {
  CONDO_SESSION_KEY,
  CONDO_UNITS_SESSION_KEY_LABEL,
  C_C_COMPLETE_BOOKING,
  C_PP,
  C_TERMS,
  Routes,
  QUOTE_LABEL,
  SESSION_KEY_TOKEN_NAME_PARAM,
  LIFE_STYLE_NAME_PARAM
} from '@share/constants';
import { ICondoGuestBooking, ICondoBookPackageWithCard, IFormErrors, ScrollBehaviorEnum } from '@common-types';
import { NON_REFUNDABLE_TYPE, countriesCode } from '@constants';
import { BookingErrorsEnum } from '@share/common-types';
import { ISessionKey } from '@share/common-types';
import { CondoGuestInfo } from '../condo-guest-info';
import { CondoPaymentMethod } from '../condo-payment-method';
import { CondoConfirmationEmail } from '../condo-confirmation-email';
import { CondoCancellationPolicies } from '../condo-cancellation-policies';
import { BookingConfirmed } from '../../notification';
import { CondoBookingFailedWarning } from '../../condo-booking-failed-warning';
import { CondoBookingPendingNotification } from '../booking-pending-notification';
import { CondoUnitNotAvailableNotification } from '../condo-unit-not-available';
import { ILoginState } from '@share/store/slices';
import { CondoAvailabilityStatusModal, ModalMessage, ModalConfirmation, ThreeDSModal, Insurance } from '@components';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  condoReviewBookStore: ICondoReviewBookState;
  redeemCodeStore: ICouponState;
  menuStore: IMenuState;
  loginStore: ILoginState;
  insuranceStore: IInsuranceState;
}

interface IMapDispatchToProps {
  getCondoBookPackageWithCard: (bookWithCard: ICondoBookPackageWithCard, onErrorCallback: (errorCode: string) => void) => void;
  setCondoGuest: (guest: ICondoGuestBooking) => void;
  setCondoPriceChangedError: (priceChanged: boolean) => void;
  setCondoBookingComplete: (bookingComplete: boolean) => void;
  setErrorMessageExpirationDate: (value: boolean) => void;
  setErrorMessageCardNumber: (value: boolean) => void;
  setErrorMessageZipCode: (value: boolean) => void;
  getNavigationMenu: () => AppThunk;
  setLoginRedirect: (redirect: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {}

interface IState {
  isAccept: boolean;
  isAcceptKnowBeforeYouGo: boolean;
  isAcceptNoChangesAllowed: boolean;
  isAcceptCancellationPolicy: boolean;
  isAcceptIsInsurance: boolean;
  isSomeoneElse: boolean;
  isClientCash: boolean;
  isClientCashNotRefundable: boolean;
  showNotRefundableMessage: boolean;
  showNotRefundableConfirmModal: boolean;
}

const ZERO = 0;
const SECOND_ELEMENT = 2;
const TEN_SEC = 10000;
const PRIVACY = 'privacy1';

class CondoReviewBookComponent extends React.Component<IProps, IState> {
  state: IState = {
    isAccept: false,
    isAcceptKnowBeforeYouGo: false,
    isAcceptNoChangesAllowed: false,
    isAcceptCancellationPolicy: false,
    isAcceptIsInsurance: false,
    isSomeoneElse: false,
    isClientCash: false,
    isClientCashNotRefundable: false,
    showNotRefundableMessage: false,
    showNotRefundableConfirmModal: false,
  };
  formRef = React.createRef<FormInstance>();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  onChangeAccept = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAccept: e.target.checked });
  };

  onChangeAcceptKnowBeforeYouGo = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptKnowBeforeYouGo: e.target.checked });
  };
  
  onChangeAcceptNoChangesAllowed = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptNoChangesAllowed: e.target.checked });
  };

  onChangeAcceptCancellationPolicy = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptCancellationPolicy: e.target.checked });
  };

  onChangeAcceptClientCash = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isClientCash: e.target.checked });
  };

  onChangeAcceptIsInsurance = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptIsInsurance: e.target.checked });
  };

  onChangeAcceptClientCashNotRefundable = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isClientCashNotRefundable: e.target.checked, showNotRefundableMessage: e.target.checked });
  };

  handleOnCompleteBooking = () => {
    const { condoReviewBookStore, loginStore } = this.props;
    const { account, userWallet } = loginStore;
    const { condoBookingSummary, bookingComplete, booking, selectedCondoReviewClientCash } = condoReviewBookStore;
    const { refundability } = condoBookingSummary;

    const hasClientCash = account?.hasClientCash;
    const walletIsExternal = account?.walletIsExternal;

    const isLogged = !!userWallet;

    const usedClientCash = (bookingComplete && booking) ? get(booking, 'clientCash', 0) : 0;

    const refundabilityFinal = (!walletIsExternal && hasClientCash && isLogged && (selectedCondoReviewClientCash > 0 || usedClientCash > 0)) ? NON_REFUNDABLE_TYPE : refundability;

    if (NON_REFUNDABLE_TYPE === refundabilityFinal) {
      this.setState({ showNotRefundableConfirmModal: true });
    } else {
      this.onCompleteBooking()
    }
  }

  onCompleteBooking = () => {
    const { guest } = this.props.condoReviewBookStore;

    this.formRef.current.setFieldsValue({ email: guest.email });
    this.formRef.current.setFieldsValue({ givenName: guest.givenName });
    this.formRef.current.setFieldsValue({ surname: guest.surname });
    this.formRef.current.setFieldsValue({ phone: guest.phone });

    Promise.all([this.formRef.current.validateFields()])
      .then(() => {
        this.completeBooking();
      })
      .catch((error: IFormErrors) => {
        const { errorFields } = error;
        const { name } = errorFields[ZERO];
        const { scrollToField } = this.formRef.current;

        (() => {
          scrollToField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
        })();
      });
  };

  completeBooking = () => {
    const { condoBookingSummary, card, guest, selectedCondoReviewClientCash } = this.props.condoReviewBookStore;
    const { account, userWallet, userWalletData } = this.props.loginStore;
    const { coupon } = this.props.redeemCodeStore;
    const { payNowPrice } = condoBookingSummary.bookingCard.bookingPrice;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
    const finalBalanceIntermediate = payNowPrice - (selectedCondoReviewClientCash ? selectedCondoReviewClientCash * convertionRate : 0);
    const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;

    const values = UrlUtils.getValues();
    const sessionKey = getSessionObj(
      values[CONDO_UNITS_SESSION_KEY_LABEL] as ISessionKey,
      values[CONDO_SESSION_KEY] as ISessionKey,
    );
    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
    }

    const creditCard = { ...card };

    const data: any = {
      sessionKey,
      bookingPrice: condoBookingSummary.bookingCard.bookingPrice.payNowPrice,
      condoId: getCondoId(),
      availabilityId: getAvailabilityId(),
      couponCode: coupon,
      guests: [{ ...guest, phone: removingDashFromString(guest.phone) }],
      supplierType: condoBookingSummary.supplierType,
      quote
    };

    const isPrivateWithToken = account?.type === LoginType.PrivateWithToken;
    const privateTokenFromUrl = account?.privateTokenFromUrl;
    if (isPrivateWithToken && privateTokenFromUrl) {
      const ssoSessionKey = UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM);
      data.ssoSessionKey = ssoSessionKey;
      data.lifeType = UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM);
    }

    const hasClientCash = account?.hasClientCash;
    const isLogged = !!userWallet;

    const paymentMethod = { paymentMethodId: card?.id, CVC: card?.cvv };

    if (hasClientCash && isLogged) {
      data.clientCashAmount = selectedCondoReviewClientCash ? selectedCondoReviewClientCash : 0;

      if (finalBalance > 0) {
        if (!isEmpty(card?.id)) {
          data.paymentMethod = paymentMethod;
        } else {
          data.card = creditCard;
        }
      }
    } else {
      if (!isEmpty(card?.id)) {
        data.paymentMethod = paymentMethod;
      } else {
        data.card = creditCard;
      }
    }

    this.props.setErrorMessageExpirationDate(true);
    this.props.setErrorMessageCardNumber(true);
    this.props.setErrorMessageZipCode(true);
    this.props.getCondoBookPackageWithCard(data, () => {
      this.scrollToError();
    });

    if (hasClientCash && isLogged) {
      data.clientCashAmount = selectedCondoReviewClientCash ? selectedCondoReviewClientCash : 0;
    }

    ReactGA.event({
      category: account.name,
      action: `${C_C_COMPLETE_BOOKING}_${account.name.toUpperCase()}`,
      label: `User clicked complete booking on book`,
      nonInteraction: false,
    });

  }

  scrollToError = () => {
    this.wrapperRef.current.scrollIntoView({ behavior: ScrollBehaviorEnum.Smooth });
  }

  onChangeSomeoneElse = (e: { target: CheckboxChangeEventTarget }) => {
    const { memberType } = this.props.menuStore.items;
    const { guest, condoBookingSummary } = this.props.condoReviewBookStore;
    const { givenName, surname } = condoBookingSummary.mainGuest;

    if (!e.target.checked) {
      this.formRef.current.setFieldsValue({ givenName: givenName });
      this.formRef.current.setFieldsValue({ surname: surname });
      this.formRef.current.setFieldsValue({
        phone: getMemberPhone(memberType, condoBookingSummary.mainGuest.phone),
      });
    }

    this.setState({ isSomeoneElse: e.target.checked });
    this.props.setCondoGuest({
      ...(updateCondoGuest(guest, {
        isBookingForSomeoneElse: e.target.checked,
        givenName: !e.target.checked ? givenName : '',
        surname: !e.target.checked ? surname : '',
        phone: !e.target.checked
          ? getMemberPhone(memberType, condoBookingSummary.mainGuest.phone)
          : '',
      }) as ICondoGuestBooking),
      isBookingForSomeoneElse: e.target.checked,
    });
  };

  componentDidMount() {
    const { items } = this.props.menuStore;
    const { givenName, surname, email, phone, namePrefix } =
      this.props.condoReviewBookStore.condoBookingSummary.mainGuest;

    this.props.setCondoGuest({
      id: v4(),
      givenName: givenName ? givenName : '',
      namePrefix: namePrefix ? namePrefix : '',
      surname: surname ? surname : '',
      countryCode: '',
      phoneCountryCode: countriesCode[ZERO].dialCode,
      phone:
        items && getMemberPhone(items.memberType, phone)
          ? getMemberPhone(items.memberType, phone)
          : '',
      email: email ? email : '',
      additionalEmail: '',
      isBookingForSomeoneElse: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      stateCode: '',
      zipCode: '',
    });

    this.props.setCondoBookingComplete(false);
  }

  getBookingErrorWarning = (): React.ReactNode | null => {
    const { bookingErrorCode } = this.props.condoReviewBookStore;

    let header = "something.went.wrong";
    let description = bookingErrorCode as string;

    if (bookingErrorCode === BookingErrorsEnum.PaymentFailed) {
      header = "payment.authorize.failed";
      description = "check.card.details.or.use.another.payment.method";
    } else if (bookingErrorCode === BookingErrorsEnum.GuestInformationFailed) {
      header = "issue.with.guest.information";
      description = "check.you.entered.valid.guest.information";
    } else if (bookingErrorCode === BookingErrorsEnum.RoomsUnavailable || bookingErrorCode === BookingErrorsEnum.SoldOut) {
      return <CondoAvailabilityStatusModal />;
    } else if (bookingErrorCode === BookingErrorsEnum.General) {
      header = "something.went.wrong";
      description = "error.code.general";
    } else if (bookingErrorCode === BookingErrorsEnum.UnexpectedResponse) {
      header = "something.went.wrong";
      description = "there.may.be.an.issue";
    }

    return (
      <CondoBookingFailedWarning
        header={header}
        description={description}
      />
    );
  };

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { bookingComplete, errorBooking } = this.props.condoReviewBookStore;

    if (
      !prevProps.condoReviewBookStore?.bookingErrorCode ||
      prevProps.condoReviewBookStore?.bookingErrorCode !==
        this.props.condoReviewBookStore?.bookingErrorCode
    ) {
      if (this.props.condoReviewBookStore.bookingErrorCode === BookingErrorsEnum.PaymentFailed) {
        this.formRef.current.setFields([
          { name: 'holderName', errors: [''] },
          { name: 'cardNumber', errors: [''] },
          { name: 'months', errors: [''] },
          { name: 'years', errors: [''] },
          { name: 'code', errors: [''] },
        ]);
      } else if (
        this.props.condoReviewBookStore.bookingErrorCode ===
        BookingErrorsEnum.GuestInformationFailed
      ) {
        this.formRef.current.setFields([
          { name: 'prefixName', errors: [''] },
          { name: 'givenName', errors: [''] },
          { name: 'surname', errors: [''] },
          { name: 'phone', errors: [''] },
        ]);
      }
    }

    if (
      (bookingComplete && bookingComplete !== prevProps.condoReviewBookStore.bookingComplete) ||
      (errorBooking && errorBooking !== prevProps.condoReviewBookStore.errorBooking)
    ) {
      setTimeout(() => {
        this.props.getNavigationMenu();
      }, TEN_SEC);
    }
  }

  searchCondo = () => {
    UrlUtils.removeFromUrl(CONDO_UNITS_SESSION_KEY_LABEL);
    UrlUtils.removeFromUrl(CONDO_SESSION_KEY);

    this.props.history.push(`/${this.props.loginStore.account?.name}${Routes.CondoSearch}${location.search}`);
  };

  bookAnotherUnit = () => {
    const condoId = Number(this.props.history.location.pathname.split('/')[SECOND_ELEMENT]);

    this.props.history.push(`/${this.props.loginStore.account?.name}${Routes.Condo}/${condoId}${location.search}`);
  };

  hangleGoToLogin = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.Login}`);
  }

  hangleGoToSignUp = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.SignUp}`);
  }

  handleUpdateAddress = () => {
    const { condoReviewBookStore } = this.props;
    const { guest } = condoReviewBookStore;
    
    this.formRef.current.setFieldsValue({ addressLine1: guest.addressLine1 });
    this.formRef.current.setFieldsValue({ addressLine2: guest.addressLine2 });
    this.formRef.current.setFieldsValue({ city: guest.city });
    this.formRef.current.setFieldsValue({ country: guest.country });
    this.formRef.current.setFieldsValue({ state: guest.stateCode });
    this.formRef.current.setFieldsValue({ postalCode: guest.zipCode });
  }

  render(): React.ReactNode {
    const { condoReviewBookStore, menuStore, loginStore, reviewBookStore, insuranceStore, intl } = this.props;
    const { isSomeoneElse, isAccept, isAcceptKnowBeforeYouGo, isAcceptNoChangesAllowed, isAcceptCancellationPolicy, showNotRefundableMessage } = this.state;
    const {
      card,
      guest,
      condoBookingSummary,
      loadingBooking,
      bookingComplete,
      bookingErrorCode,
      isBookingPending,
      isAlternativeUnitsAvailable,
      selectedCondoReviewClientCash
    } = condoReviewBookStore;
    const { threeDSLoading } = reviewBookStore;
    const { account, userWallet, userWalletData } = loginStore;
    const isNotification =
      bookingComplete ||
      isBookingPending ||
      bookingErrorCode === BookingErrorsEnum.SoldOut;
    const { balance, bookingPrice } = condoBookingSummary.bookingCard;
    const { maxWalletClientCash, payNowPrice } = bookingPrice;
    const { selection, insurance } = insuranceStore;

    const chargeDescription = GetPreferenceValue(account, MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY);
    const walletIsExternal = account?.walletIsExternal;
    const walletNoDecimals = account?.walletNoDecimals;
    const walletNoPassEarnings = account?.walletNoPassEarnings;
    const hasClientCash = account?.hasClientCash;
    const clientCashPercentage = account?.clientCashPercentage;

    const maxWalletClientCashInt = maxWalletClientCash;

    const isLogged = !!userWallet;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;

    const finalBalanceIntermediate = payNowPrice - (selectedCondoReviewClientCash ? selectedCondoReviewClientCash : 0);
    const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;
    const eligibleClientCash = (finalBalance > 0 ? finalBalance : 0) * (!isEmpty(clientCashPercentage) ? parseInt(clientCashPercentage) : 0) / 100;
    const isRequired = !hasClientCash || finalBalance > 0;
    const currency = getSelectedCurrency(account);
    const balanceCalculated = balance / convertionRate;

    const maxWalletClientCashCalculated = maxWalletClientCashInt ? walletNoDecimals ? Math.floor(maxWalletClientCashInt / convertionRate) : maxWalletClientCashInt / convertionRate : null;
    const userWalletDataBalanceCalculated = (walletNoDecimals && userWalletData?.balance) ? Math.floor(userWalletData?.balance) : userWalletData?.balance;
    const maxWalletAmountIntermediated = walletNoPassEarnings ? 
                              maxWalletClientCashCalculated ?
                                (maxWalletClientCashCalculated && userWalletDataBalanceCalculated > maxWalletClientCashCalculated) ? 
                                  maxWalletClientCashCalculated : 
                                  userWalletDataBalanceCalculated : 
                                0 :
                              userWalletDataBalanceCalculated > balanceCalculated ? 
                                balanceCalculated : 
                                userWalletDataBalanceCalculated;

    const maxWalletAmount = (maxWalletClientCashCalculated && maxWalletAmountIntermediated > maxWalletClientCashCalculated) ? maxWalletClientCashCalculated : maxWalletAmountIntermediated;

    const clientCash = selectedCondoReviewClientCash ? GetWalletNumber(selectedCondoReviewClientCash, account) : Number(0).toFixed(walletNoDecimals ? 0 : 2);

    const insuranceBalance = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price) ? insurance?.productDetails?.price : 0;
    const isInsuranceSelected = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price);

    return (
      guest && (
        <div className="condo-review-book-left-wrapper print" ref={this.wrapperRef}>
          {bookingErrorCode ? this.getBookingErrorWarning() : null}
          <div className={`condo-review-book ${isNotification ? 'hide-block' : ''}`}>
            <Form name="condo-review-book-rule" ref={this.formRef}>
              <CondoGuestInfo
                formRef={this.formRef}
                onChangeSomeoneElse={this.onChangeSomeoneElse}
                isSomeoneElse={isSomeoneElse}
                onUpdateAddress={this.handleUpdateAddress}
                onChangeCountryState={() => this.setState({ isAcceptIsInsurance: false })}
              />

              <CondoConfirmationEmail formRef={this.formRef} isSomeoneElse={isSomeoneElse} />
              
              <CondoPaymentMethod
                formRef={this.formRef}
                display={finalBalance > 0}
                isRequired={isRequired}
              />

              {!card?.id ? (
                <Insurance onChange={() => this.setState({ isAcceptIsInsurance: false })} />) : null}
            </Form>

            <CondoCancellationPolicies displayCheckbox={!bookingComplete} disable={loadingBooking} onChangeAcceptPolicy={this.onChangeAcceptCancellationPolicy} condoBookingSummary={condoBookingSummary} />

            <div className="condo-review-book__wrapper">
              <Checkbox onChange={this.onChangeAccept} disabled={loadingBooking} />
              <p className="condo-review-book__accept-text">
                
                <FormattedMessage
                  id="accept.you.will.be.charged"
                  values={{
                    price: renderNumber(finalBalance + insuranceBalance, 2),
                    type: <FormattedMessage id="condo" />,
                    reservation_name: (!isEmpty(chargeDescription) && condoBookingSummary?.customProvider) ? chargeDescription : <FormattedMessage id="accept.you.will.be.charged.travcoding" />,
                    currency
                  }}
                />
              </p>
            </div>

            <div className="review-book__wrapper">
              <Checkbox onChange={this.onChangeAcceptKnowBeforeYouGo} disabled={loadingBooking} />
              <p className="review-book__accept-text">
                <FormattedMessage id="accetp.no.changes.allow" />
              </p>
            </div>

            <div className="review-book__wrapper">
              <Checkbox onChange={this.onChangeAcceptNoChangesAllowed} disabled={loadingBooking} />
              <p className="review-book__accept-text">
                <FormattedMessage id="by.checking.this.box.you.acknowledge.that.you.have.read.know.before.you.go" />
              </p>
            </div>

            {hasClientCash && isLogged &&
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptClientCash} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  {!walletIsExternal ? 
                    (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge" values={{ clientCash, reward: walletNoDecimals ? Math.floor(eligibleClientCash).toFixed(0) : eligibleClientCash.toFixed(2), clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />) :
                    (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge.external" values={{ clientCash, clientCashName: account?.walletClientCashName }} />)}
                </p>
              </div>}

            {!walletIsExternal && hasClientCash && isLogged && selectedCondoReviewClientCash > 0 &&
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptClientCashNotRefundable} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  <FormattedMessage id="wallet.client.not_refundable.acknowledge" values={{ clientCash, currency, clientCashName: account?.walletClientCashName }} />
                </p>

                <ModalMessage text={intl.formatMessage({ id: 'wallet.client.not_refundable.modal.acknowledge'}, { clientCashName: account?.walletClientCashName })} disableTextLocalization disableTitleLocalization={true} visible={showNotRefundableMessage} onCancel={() => this.setState({ showNotRefundableMessage: false })} />
              </div>}

            {isInsuranceSelected ? (
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptIsInsurance} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  <FormattedMessage id="insurance.policy.disclaimer" />
                </p>
              </div>) : null}

            {hasClientCash && !isLogged &&
              <div className="review-book__wrapper client-cash-message">
                <p className="review-book__accept-text">
                  <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.acknowledge" values={{ reward: eligibleClientCash.toFixed(2), currency, clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />
                </p>
                <p className="booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
                  <BlueButton onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
                </p>
              </div>}

            <div className="condo-review-book__wrapper">
              <p className="condo-review-book__agree-navigation-text">
                <FormattedMessage 
                  id="agree.booking"
                  values={{
                    pp: (msg: string) => (
                      <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/privacy-policy/" onClick={() => {
                        ReactGA.event({
                          category: account.name,
                          action: `${C_PP}_${account.name.toUpperCase()}`,
                          label: `User clicked privacy policy on book`,
                          nonInteraction: false,
                        });
                      }}>
                        {msg}
                      </a>
                    ),
                    tu: (msg: string) => (
                      <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/terms-of-use/" onClick={() => {
                        ReactGA.event({
                          category: account.name,
                          action: `${C_TERMS}_${account.name.toUpperCase()}`,
                          label: `User clicked terms of use on book`,
                          nonInteraction: false,
                        });
                      }}>
                        {msg}
                      </a>
                    )
                  }}
                />
                {menuStore.items?.baseUrl ? (
                  <a
                    className="condo-review-book__agree-navigation-link"
                    href={getFullUrl(menuStore.items?.baseUrl, PRIVACY)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="privacy.policy" />
                  </a>
                ) : null}
              </p>

              <div className="condo-review-book__complete-btn">
                <BlueButton
                  onClick={this.handleOnCompleteBooking}
                  disabled={
                    !isAccept || 
                    !isAcceptKnowBeforeYouGo || 
                    !isAcceptNoChangesAllowed ||
                    (!isAcceptCancellationPolicy && !isEmpty(condoBookingSummary.cancellationPolicyText)) ||
                    (isInsuranceSelected && !this.state.isAcceptIsInsurance) || 
                    loadingBooking || 
                    (hasClientCash && isLogged && (!this.state.isClientCash)) || 
                    (hasClientCash && isLogged && !this.state.isClientCashNotRefundable && selectedCondoReviewClientCash > 0 && !walletIsExternal) || 
                    (hasClientCash && isLogged && selectedCondoReviewClientCash > maxWalletAmount) || 
                    (hasClientCash && isLogged && !!selectedCondoReviewClientCash && selectedCondoReviewClientCash <= maxWalletAmount && walletNoDecimals && Math.floor(selectedCondoReviewClientCash) != selectedCondoReviewClientCash)
                  }
                >
                  <FormattedMessage id="complete.booking" />
                  {loadingBooking && <Spin />}
                </BlueButton>
              </div>
            </div>
          </div>

          <BookingConfirmed isBookingComplete={bookingComplete} />

          {isBookingPending && <CondoBookingPendingNotification />}

          {bookingErrorCode === BookingErrorsEnum.SoldOut && (
            <CondoUnitNotAvailableNotification
              isAlternativeUnitsAvailable={isAlternativeUnitsAvailable}
              searchCondos={this.searchCondo}
              bookAnotherUnit={this.bookAnotherUnit}
            />)}

          <ThreeDSModal isCondo={true} />

          {threeDSLoading ? (
            <div className="condo-review-book__loading-container">
              <Loading size={LoadingSise.Medium} />
            </div>) : null}

          <ModalConfirmation
            message="non.refundable.reservation.message"
            title="non.refundable.reservation"
            yesMessage="yes"
            noMessage="no"
            visible={this.state.showNotRefundableConfirmModal}
            onCancel={() => this.setState({ showNotRefundableConfirmModal: false })}
            onConfirm={() => this.setState({ showNotRefundableConfirmModal: false }, this.onCompleteBooking)}
          />

        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    condoReviewBookStore: state.condoReviewBookStore,
    menuStore: state.navigationMenuStore,
    redeemCodeStore: state.redeemCodeStore,
    loginStore: state.loginStore,
    insuranceStore: state.insuranceStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setCondoGuest,
  getCondoBookPackageWithCard,
  setCondoPriceChangedError,
  setCondoBookingComplete,
  setErrorMessageExpirationDate,
  setErrorMessageCardNumber,
  setErrorMessageZipCode,
  getNavigationMenu,
  setLoginRedirect,
};

export const CondoReviewBookComponentIntl = injectIntl(CondoReviewBookComponent);

export const CondoReviewBook = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CondoReviewBookComponentIntl));
