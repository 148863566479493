import { v4 as createID } from 'uuid';

import { ITravelItem, ITravelResource } from '@common-types';

import Baby from '@assets/images/resources-page/travel-items/baby.jpeg';
import Travel from '@assets/images/resources-page/travel-items/travel.jpeg';
import Excursions from '@assets/images/resources-page/travel-items/excursions.jpeg';
import Transportation from '@assets/images/resources-page/travel-items/transportation.jpeg';
import Golf from '@assets/images/resources-page/travel-items/golf.jpeg';
import Passport from '@assets/images/resources-page/travel-resources/passport.png';
import Safety from '@assets/images/resources-page/travel-resources/safety.png';
import Weather from '@assets/images/resources-page/travel-resources/weather.png';

export const TravelItems: ITravelItem[] = [
  {
    id: createID(),
    src: Baby,
    alt: 'Baby sleeping in a crib',
    heading: 'resources.travel.items.baby.heading',
    text: 'resources.travel.items.baby.text',
    link: 'https://www.babyquip.com/?a=6082a24',
  },
  {
    id: createID(),
    src: Travel,
    alt: 'Insurance logo',
    heading: 'resources.travel.items.travel.heading',
    text: 'resources.travel.items.travel.text',
    link: 'https://www.travelguard.com/agentlink.asp?pcode=&ta_arc=10754866',
  },
  {
    id: createID(),
    src: Excursions,
    alt: 'Boat in the sea',
    heading: 'resources.travel.items.excursions.heading',
    text: 'resources.travel.items.excursions.text',
    textBold: 'resources.travel.items.excursions.text.bold',
    link: 'https://www.shoreexcursionsgroup.com/?ID=143&Click=202496',
  },
  {
    id: createID(),
    src: Golf,
    alt: 'Map',
    heading: 'resources.travel.items.golf.heading',
    text: 'resources.travel.items.golf.text',
    link: 'http://www.golfpactravel.com',
  },
  {
    id: createID(),
    src: Transportation,
    alt: 'Boat parked at the shore',
    heading: 'resources.travel.items.transportation.heading',
    text: 'resources.travel.items.transportation.text',
    link: 'https://www.carrentalpro.com/home/memberhome',
  },
  /*{
    id: createID(),
    src: Excursions,
    alt: 'Boat parked at the shore',
    heading: 'resources.travel.items.excursions.heading',
    text: 'resources.travel.items.excursions.text',
    textBold: 'resources.travel.items.excursions.text.bold',
    link: 'https://www.toursales.com/Articles.asp?ID=143&Click=124456',
  },
  /*{
    id: createID(),
    src: Covid,
    alt: 'Girl with mask',
    heading: 'resources.travel.items.covid.heading',
    text: 'resources.travel.items.covid.text',
    link: 'https://www.cdc.gov/coronavirus/2019-ncov/travelers/index.html',
  },*/
];

export const TravelResources: ITravelResource[] = [
  {
    id: createID(),
    src: Passport,
    alt: 'American passport picture',
    heading: 'resources.travel.resources.passport.heading',
    text: 'resources.travel.resources.passport.text',
    link: 'https://travel.state.gov/content/travel/en/passports.html',
  },
  {
    id: createID(),
    src: Safety,
    alt: 'A travelling woman with backpack in a station',
    heading: 'resources.travel.resources.safety.heading',
    text: 'resources.travel.resources.safety.text',
    link: 'https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages.html',
  },
  {
    id: createID(),
    src: Weather,
    alt: 'Sun and cloud icons',
    heading: 'resources.travel.resources.weather.heading',
    text: 'resources.travel.resources.weather.text',
    link: 'https://weather.com/',
  },
];
