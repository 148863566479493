import { Spin, Table } from "antd";
import React from "react";

const UsersTable = (props) => {
  const { filteredData, handleEdit, loading } = props;

  const columns = [
    {
      title: "Team Member",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Loyalty",
      dataIndex: "loyalty",
      key: "loyalty",
      render: () => "N/A",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Edit",
      key: "edit",
      className: "text-center",
      render: (text, record) => (
        <a onClick={() => handleEdit(record)}>
          <i className="bi bi-pencil-square"></i>
        </a>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <Spin spinning={loading} />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          className="display responsive nowrap table-width"
        />
      )}
    </div>
  );
};

export default UsersTable;
