import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  BookingStatusEnum,
  IBookingSubStatus,
  HotelBookingStatusEnum,
} from '@common-types';
import './style.scss';

interface IProps {
  status: BookingStatusEnum | HotelBookingStatusEnum;
  subStatus?: IBookingSubStatus[] | string;
  withCircle?: boolean;
}

export class BookingsStatus extends React.Component<IProps> {
  getText = (): React.ReactNode => {
    const { status } = this.props;
    const map: { [key: string]: React.ReactNode } = {
      [BookingStatusEnum.Completed]: <FormattedMessage id="completed" />,
      [BookingStatusEnum.Failed]: <FormattedMessage id="failed" />,
      [BookingStatusEnum.Draft]: <FormattedMessage id="draft" />,
      [BookingStatusEnum.Pending]: <FormattedMessage id="pending" />,
      [BookingStatusEnum.Confirmed]: <FormattedMessage id="confirmed" />,
      [BookingStatusEnum.Cancelled]: <FormattedMessage id="cancelled" />,
      [BookingStatusEnum.CancellationInProgress]: <FormattedMessage id="cancelled" />,
      [BookingStatusEnum.CancellationFailed]: <FormattedMessage id="cancelled" />,
      [HotelBookingStatusEnum.InProgress]: <FormattedMessage id="inProgress" />,
      [HotelBookingStatusEnum.Completed]: <FormattedMessage id="completed" />,
      [HotelBookingStatusEnum.Failed]: <FormattedMessage id="failed" />,
      [HotelBookingStatusEnum.Pending]: <FormattedMessage id="pending" />,
      [HotelBookingStatusEnum.Confirmed]: <FormattedMessage id="confirmed" />,
      [HotelBookingStatusEnum.Cancelled]: <FormattedMessage id="cancelled" />,
    };

    return map[status];
  };

  getClass = (): string => {
    const { status } = this.props;
    const map: { [key: string]: string } = {
      [BookingStatusEnum.Completed]: 'completed',
      [BookingStatusEnum.Failed]: 'failed',
      [BookingStatusEnum.Draft]: 'draft',
      [BookingStatusEnum.Pending]: 'draft',
      [BookingStatusEnum.Confirmed]: 'confirmed',
      [BookingStatusEnum.Cancelled]: 'cancelled',
      [BookingStatusEnum.CancellationFailed]: 'cancelled',
      [BookingStatusEnum.CancellationInProgress]: 'cancelled',
      [HotelBookingStatusEnum.InProgress]: 'draft',
      [HotelBookingStatusEnum.Completed]: 'completed',
      [HotelBookingStatusEnum.Failed]: 'failed',
      [HotelBookingStatusEnum.Pending]: 'draft',
      [HotelBookingStatusEnum.Confirmed]: 'confirmed',
      [HotelBookingStatusEnum.Cancelled]: 'cancelled',
    };

    return map[status];
  };

  render(): React.ReactNode {
    const { subStatus, withCircle = true } = this.props;

    return (
      <div
        className={`
        booking-status
        ${this.getClass()}
        ${withCircle ? '' : 'without-circle'}
      `}
      >
        {this.getText()}
        {subStatus?.length ? (
          <span className="booking-status__sub-status">
            {Array.isArray(subStatus)
              ? subStatus.map(({ displayValue }) => displayValue).join(', ')
              : subStatus}
          </span>
        ) : null}
      </div>
    );
  }
}
