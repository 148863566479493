import React from 'react';
import upperCase from 'lodash/upperCase';
import Cookies from 'js-cookie';

import { Drawer, Menu, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { RootState, AppThunk, isExternalUrl, getPreferenceFromAccount, getLocalURI, isAnyReservationCancelFromPath, IsImpersonating, isBookFromPath, DecodeTokenData, isSignInFromPath, isSignUpFromPath, VACATIONS_PAGE, GetHomeParams, GetWalletNumber, isCondoEngineFromPath } from '@share/utils';
import { ILoginState, LoginType, logout, setLoginRedirect } from '@share/store/slices';
import { getPreferenceFileUrlFromAccount } from '@share/utils';
import { HEADER_BACKGROUND_COLOR_FIELD_KEY, HEADER_FONTS_COLOR_FIELD_KEY, HOME_PAGE_URL_FIELD_KEY, ISideKickStatus, LOGOUT_FIELD_KEY, LOGO_FIELD_KEY } from '@share/common-types';
import { COOKIE1_TOKEN_NAME, COOKIE_TOKEN_NAME, Routes, USER_BASE_URL } from '@share/constants';
import { NavigationMenu, WhiteButton, SkeletonHeader, ModalInternalization } from '@components';
import { PointsLocationsEnum } from '@common-types';

import { UserSvg } from '@assets';
import { CloseSvg } from '@share/assets';

import Logo from '@assets/images/logo_black.png';

import { Marginator } from '../../marginator';

import {
  getNavigationMenu,
  IMenuState,
  setVisible,
  setIsUpdateMenu,
  getBenefits,
  IBenefitsState,
  resetHotelsFull,
  endImpersonation
} from '@share/store/slices';
import { ICondoReviewBookState, IReviewBookState } from '@store/slices';
import { resetCondosFull } from '@share/store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  benefitsStore: IBenefitsState;
  reviewBookStore: IReviewBookState;
  condoReviewBookStore: ICondoReviewBookState;
}

interface IMapDispatchToProps {
  setVisible: (visible: boolean) => void;
  setIsUpdateMenu: (value: boolean) => void;
  setLoginRedirect: (redirect: string) => void;

  getNavigationMenu: () => AppThunk;
  resetHotelsFull: () => AppThunk;
  resetCondosFull: () => AppThunk;
  logout: () => AppThunk;
  endImpersonation: () => AppThunk;
  getBenefits: (userId: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  matches?: boolean;
  isError?: boolean;
  isVacationRentals?: boolean;
}

const ONE = 1;
const TWO = 2;

interface IState {
  showDrawerUserInfo: boolean;
  isShowCredits: boolean;
}

class HeaderComponent extends React.Component<IProps, IState> {
  state: IState = {
    showDrawerUserInfo: false,
    isShowCredits: false,
  };

  showDrawer = () => {
    this.props.setVisible(!this.props.menuStore.visible);
  };

  onClose = () => {
    this.props.setVisible(false);
  };

  showUserLinks = () => {
    this.setState({ showDrawerUserInfo: true });
  };

  onCloseUserLinks = () => {
    this.setState({ showDrawerUserInfo: false });
  };

  onCondoOrHotelPage = (): void => {
    if (this.props.menuStore?.items) {
      const { pointsSettings } = this.props.menuStore?.items;
      const isCondoPage =
        this.props.location.pathname.split('/')[ONE] === PointsLocationsEnum.Condo;
      const isCondoBookingPage =
        this.props.location.pathname.split('/')[ONE] === PointsLocationsEnum.CondoBooking;
      const isHotelSearchPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.Search;
      const isHotelPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.Hotel;
      const isHotelBookingPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.HotelBooking;
      const isCondo =
        (isCondoPage || isCondoBookingPage) && pointsSettings?.condo?.isPointsControlEnabled && !this.props.menuStore?.items?.promo;
      const isHotel =
        (isHotelSearchPage || isHotelPage || isHotelBookingPage) && pointsSettings?.hotel?.isPointsControlEnabled && !this.props.menuStore?.items?.promo;

      if (isCondo || isHotel) {
        this.setState({ isShowCredits: true });
      } else {
        this.setState({ isShowCredits: false });
      }
    }
  };

  componentDidMount() {
    const { user, account } = this.props.loginStore;
    if (user) {
      this.props.getNavigationMenu();
      const isRSITemplate = account?.isRSITemplate;
      if (isRSITemplate) {
        this.props.getBenefits(user.keyid);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.menuStore.isUpdateMenu) {
      this.props.getNavigationMenu();
    }

    if (
      prevProps.menuStore.loading !== this.props.menuStore.loading &&
      !this.props.menuStore.loading
    ) {
      this.onCondoOrHotelPage();
    }

    if (prevProps.location !== this.props.location) {
      this.onCondoOrHotelPage();
    }
  }

  renderPhoneInformation = () => {
    const { menuStore } = this.props;
    const { items } = menuStore;
    if (items?.phone && items?.showContactInfo) {
      return (
        <div className="header__info-call">
          <p className="header__call-text">
            <FormattedMessage id="call.gent.now" />
          </p>
          <p className="header__call-text mobile">
            <FormattedMessage id="talk.to.our.team" />:
          </p>
          <p className="header__call-text mobile-small">
            <FormattedMessage id="concierge.contact.button" />:
          </p>
          <a href={`tel:${items?.phone}`} className="header__call-number">
            {items?.phone}
          </a>
          {items?.phone2 ? ' ' : ''}
          {items?.phone2 ? (
            <a href={`tel:${items?.phone2}`} className="header__call-number">
              {items?.phone2}
            </a>) : null}          
        </div>
      );
    }
    return null;
  }

  renderBoucherLink = () => {
    const { matches, menuStore, loginStore } = this.props;
    const { items } = menuStore;
    const { account } = loginStore;
    
    if (items?.brochureUrl && items?.showBrochure) {
      return (
        <a
          href={items?.brochureUrl}
          target={account.disableNewTabOpen ? '_self' : '_blank'}
          rel="noreferrer"
          className="header__link-member"
        >
          {matches ? (
            <FormattedMessage id="member.brochure" />
          ) : (
            <FormattedMessage id="member.brochure.short" />
          )}
        </a>
      );
    }
    return null;
  }

  handleLogout = () => {
    this.props.logout();

    const { loginStore } = this.props;
    const { account } = loginStore;

    const isPublicAccountType = account?.type === LoginType.Public;
    const hasClientCash = account?.hasClientCash;

    if (!isPublicAccountType || !hasClientCash) {
      this.hangleGoToLogin();
    }

  }

  hangleGoToLogin = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.Login}`);
  }

  hangleGoToSignUp = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.SignUp}`);
  }
  
  handleLogoutToken = () => {
    Cookies.remove(COOKIE_TOKEN_NAME);
    Cookies.remove(COOKIE1_TOKEN_NAME);
  }

  renderNavidationMenu = () => {
    const { SubMenu } = Menu;
    const { matches, menuStore, loginStore, history, intl, isVacationRentals } = this.props;
    const { visible, items } = menuStore;
    const { account, user, userWallet, userWalletData } = loginStore;

    const isCancelRoute = isAnyReservationCancelFromPath(history)
    if (isCancelRoute) {
      return null;
    }

    const isPublicAccountType = account?.type === LoginType.Public;
    const isPrivateWithLoginAccountType = account?.type === LoginType.PrivateWithLogin;
    const isPrivateWithTokenAccountType = account?.type === LoginType.PrivateWithToken;

    const userNavigation = (items?.menu)? items?.menu[items.menu?.length - ONE] : null;

    const styleBackground: any = this.getHeaderColorStyle();
    const isRSITemplate = account?.isRSITemplate;

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutUrlToken = get(DecodeTokenData(), 'logoutUrl', null);
    const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
    const logoutUrl = items?.logoutUrl ? items.logoutUrl : !isEmpty(logoutUrlStorage) ? logoutUrlStorage : !isEmpty(logoutUrlToken) ? logoutUrlToken : logoutPref;
    
    const isWhiteBackgroundColor = !styleBackground?.backgroundColor || styleBackground?.backgroundColor?.toUpperCase() === '#FFFFFF';
    const headerFontColor = !isEmpty(styleBackground?.color) ? styleBackground?.color : '#FFFFFF';

    const isSignInRoute = isSignInFromPath(history);
    const isSignUpRoute = isSignUpFromPath(history);

    const hasClientCash = account?.hasClientCash;
    const isLogged = !!userWallet;
    const loggedUser = !hasClientCash ? user : userWallet;
    const homeParams = GetHomeParams(account);

    const isRsi = isRSITemplate && account?.isPrivateTokenAccountType;

    return (
      <div className={`header__content-navigation ${isRSITemplate? 'rsi-home' : ''}`}>
        <div
          className={`header__hamburger-btn ${visible ? 'header__show-hamburger-btn' : ''}`}
          onClick={this.showDrawer}
        >
          <span className={`header__hamburger-item ${isWhiteBackgroundColor ? 'white' : ''}`} style={{ background: headerFontColor }} />
          <span className={`header__hamburger-item ${isWhiteBackgroundColor ? 'white' : ''}`} style={{ background: headerFontColor }} />
          <span className={`header__hamburger-item ${isWhiteBackgroundColor ? 'white' : ''}`} style={{ background: headerFontColor }} />
        </div>
        {matches ? 
          <NavigationMenu matches={matches} menuStore={menuStore} isVacationRentals={isVacationRentals} /> :
          (<Drawer
            className={`header__navigation ${isWhiteBackgroundColor ? 'white' : ''}`}
            width={'100%'}
            closable={true}
            onClose={this.onClose}
            visible={visible}
            style={styleBackground}
          >
            <div className="header__navigation-actions">
              <ModalInternalization onOpen={this.onClose} />
              <Marginator onOpen={this.onClose} />
            </div>

            <NavigationMenu matches={matches} menuStore={menuStore} isVacationRentals={isVacationRentals} />

            {(!isPublicAccountType || hasClientCash) && (
              <div className="header__user-info drawer" style={{ ...styleBackground, marginLeft: '10px' }}>

                {(hasClientCash && isLogged) ? (
                  <>
                    <p className="header__user-drawer-name">
                      {!loggedUser ? items?.firstName : loggedUser?.firstName } {!loggedUser ? items?.lastName[0] : loggedUser?.lastName}
                    </p>

                    <p className="header__user-drawer-member">
                      <FormattedMessage id="wallet.menu.active_cash" values={{ clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
                      {': '}
                      {GetWalletNumber(userWalletData?.balance, account)} <Tooltip title={intl.formatMessage({ id: 'wallet.menu.active_cash.tooltip' }, { clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName })}><FontAwesomeIcon icon={faCircleInfo} /></Tooltip>
                    </p>

                    {!account?.walletIsExternal ? (
                      <p className="header__user-drawer-member" style={{ marginBottom: '20px' }}>
                        <FormattedMessage id="wallet.menu.pending_cash" values={{ clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
                        {': '}
                        {GetWalletNumber(userWalletData?.pendingBalance, account)} <Tooltip title={intl.formatMessage({ id: 'wallet.menu.pending_cash.tooltip' }, { clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName })}><FontAwesomeIcon icon={faCircleInfo} /></Tooltip>
                      </p>) : null}
                  </>) : null}

                <Menu mode="inline">
                  {(isPrivateWithTokenAccountType && !account?.isCapitalVacationsCondoRes && (!isRSITemplate || items?.showMyAccount)) ? (
                    <Menu.Item className="header__item-menu" style={styleBackground}>
                      <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.RsiAccount}${homeParams}`} onClick={this.onClose}>
                        <FormattedMessage id={'my.account.title'} />
                      </Link>
                    </Menu.Item>) : null}

                  {(isPrivateWithTokenAccountType && account?.isCapitalVacations) ? (
                    <Menu.Item className="header__item-menu" style={styleBackground}>
                      <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.RsiAccount}/reservations${homeParams}`} onClick={this.onClose}>
                        <FormattedMessage id={'reservation.search.title'} />
                      </Link>
                    </Menu.Item>) : null}
                  
                  {isPrivateWithLoginAccountType || (hasClientCash && isLogged && !account?.isCapitalVacations) ? (
                    <Menu.Item className="header__item-menu" style={styleBackground}>
                      <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.Reservations}${homeParams}`} onClick={this.onClose}>
                        <FormattedMessage id="reservation.search.title" />
                      </Link>
                    </Menu.Item>) : null}
                  
                  {userNavigation && !userNavigation.childItems ? (
                    <Menu.Item
                      className="header__item-menu"
                      style={styleBackground}
                    >
                      {userNavigation?.url === 'disabled' ? 
                        isRsi ? (
                          <Tooltip title={intl.formatMessage({ id: '"menu.link.disable' })} placement="top">
                            <div style={{ width: '100%' }} className="header__link-nav ant-menu-item-disabled">
                              <FormattedMessage id={upperCase(userNavigation.title)} />
                            </div>
                          </Tooltip>) :
                          upperCase(userNavigation.title) : 
                        <a className="header__link-nav" style={styleBackground} href={userNavigation.url} onClick={this.onClose}>
                          {isRsi ? (<FormattedMessage id={upperCase(userNavigation.title)} />) : upperCase(userNavigation.title)}
                        </a>}
                    </Menu.Item>) : null}

                  {userNavigation && userNavigation.childItems ? (
                    <SubMenu
                      title={<FormattedMessage id={upperCase(userNavigation?.title)} />}
                      className="header__sub-menu"
                      popupClassName="welcome-menu-submenu-popup"
                      style={styleBackground}
                    >
                      {userNavigation.childItems?.map((childItem, index) => (
                        <Menu.Item
                          key={`${childItem.title}-${index}`}
                          className="header__item-menu"
                          style={styleBackground}
                        >
                          {childItem?.url === 'disabled' ?
                            <Tooltip title={intl.formatMessage({ id: '"menu.link.disable' })} placement="top">
                              <div style={{ width: '100%' }} className="header__link-nav-child ant-menu-item-disabled">
                                <FormattedMessage id={upperCase(childItem.title)} />
                              </div>
                            </Tooltip> :
                            <a className="header__link-nav-child" style={styleBackground} href={childItem.url} onClick={this.onClose}>
                              <FormattedMessage id={upperCase(childItem.title)} />
                            </a>}
                        </Menu.Item>
                      ))}
                    </SubMenu>) : null}

                  {(isPrivateWithTokenAccountType && !account?.isCapitalVacations) ? (
                    <Menu.Item
                      key={`logout-199`}
                      className="header__user-info-navigation"
                      style={{ ...styleBackground, marginBottom: '15px' }}
                      
                    >
                      <a href={logoutUrl} className="header__user-drawer-btn-wrapper" style={{ padding: '0px' }} onClick={this.onClose}>
                        <WhiteButton>
                          <FormattedMessage id="log.out" />
                        </WhiteButton>
                      </a>
                    </Menu.Item>) : null}

                  {(isPrivateWithLoginAccountType || (hasClientCash && isLogged && !account?.isCapitalVacations)) ? (
                    <Menu.Item
                      key={`logout-200`}
                      className="header__user-info-navigation"
                      style={{ ...styleBackground, marginBottom: '15px' }}
                    >
                      <div className="header__link-nav-child" style={{ ...styleBackground, padding: '0px' }} onClick={this.handleLogout}>
                        <FormattedMessage id="log.out" />
                      </div>
                    </Menu.Item>) : null}

                  {(hasClientCash && !isLogged && !isSignInRoute && !isSignUpRoute) ? (
                    <Menu.Item
                      key={`logout-201`}
                      className="header__user-info-navigation"
                      style={{ ...styleBackground, marginBottom: '15px' }}
                    >
                      <div className="header__link-nav-child">
                        <div className="header__link-nav-child-link" onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></div> <FormattedMessage id="or" /> <div className="header__link-nav-child-link" style={{ marginLeft: '6px' }} onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></div>
                      </div>
                    </Menu.Item>) : null}
                </Menu>
              </div>)}
          </Drawer>
          )}
      </div>
    );
  }

  renderWelcome = () => {
    const { matches, menuStore, loginStore, history, intl } = this.props;
    const { items } = menuStore;
    const { account, user, userWallet, userWalletData } = loginStore;
    const { showDrawerUserInfo, isShowCredits } = this.state;

    const isPublicAccountType = account?.type === LoginType.Public;
    const isPrivateLoginAccountType = account?.type === LoginType.PrivateWithLogin;
    const isPrivateTokenAccountType = account?.type === LoginType.PrivateWithToken;
    const isCancelRoute = isAnyReservationCancelFromPath(history)
    const isImpersonating = IsImpersonating();

    const hasClientCash = account?.hasClientCash;
    const isRSITemplate = account?.isRSITemplate;
    const isLogged = !!userWallet;

    const loggedUser = !hasClientCash ? user : userWallet;

    if (matches && (!isPublicAccountType || (hasClientCash && isLogged)) && !!loggedUser && !isCancelRoute) {
      const isCapitalVacations = account?.isCapitalVacations;
      const isCapitalVacationsCondoRes = account?.isCapitalVacationsCondoRes;
      const homeParams = GetHomeParams(account);

      const isParentSidekick = items?.sideKickStatus === ISideKickStatus.PARENT;

      return (
        <div className="header__user-info">          
          <p className="header__user-info-item">
            {isImpersonating && account?.isTravelAgentPro && 'You are signed in as '}
            {!loggedUser ? items?.firstName : loggedUser?.firstName } {!loggedUser ? items?.lastName[0] : loggedUser?.lastName}
            {isImpersonating && account?.isTravelAgentPro && <Button onClick={this.props.endImpersonation} type="text" danger size={'small'}>End impersonation</Button>}
            {(items?.rsiId && !(hasClientCash && isLogged) && !items?.isMLM) ? (<span style={{ fontSize: '12px' }}>{"\n"}<FormattedMessage id="keyid" />{': '}{items?.rsiId}</span>) : null}
            {(items?.email && items?.isMLM) ? (<span style={{ fontSize: '12px' }}>{"\n"}{items?.email}</span>) : null}
          </p>

          {(!!isShowCredits && !account?.isMLM) ? (
            <p className="header__user-info-credits">{items?.leisureCredits}</p>) : null}

          <div onClick={this.showUserLinks} className="header__user-hamburger-btn">
            {matches ? (
              <>
                <span className="header__user-hamburger-item" />
                <span className="header__user-hamburger-item" />
                <span className="header__user-hamburger-item" />
              </>
            ) : (
              <UserSvg />
            )}
          </div>

          <Drawer
            className={`header__user-drawer ${!showDrawerUserInfo ? 'hide' : ''}`}
            placement="right"
            closable={true}
            onClose={this.onCloseUserLinks}
            visible={showDrawerUserInfo}
            closeIcon={<CloseSvg />}
          >
            <div className="header__user-drawer-wrapper">
              <div className="header__user-drawer-info">
                <p className="header__user-drawer-name">
                  {!loggedUser ? items?.firstName : loggedUser?.firstName } {!loggedUser ? items?.lastName[0] : loggedUser?.lastName}
                </p>

                {items?.isMLM ? (
                  <p className="header__user-drawer-member">
                    {items?.email}
                  </p>) : null}

                {(!(hasClientCash && isLogged) && !items?.isMLM) ? (
                  <p className="header__user-drawer-member">
                    <FormattedMessage id={isCapitalVacations ? isCapitalVacationsCondoRes ? 'condores.ID' : 'owner.ID' : 'member.ID'} />
                    {': '}
                    {items?.rsiId}
                  </p>) : null}

                {(hasClientCash && isLogged) ? (
                  <>
                    <p className="header__user-drawer-member">
                      <FormattedMessage id="wallet.menu.active_cash" values={{ clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
                      {': '}
                      {GetWalletNumber(userWalletData?.balance, account)} <Tooltip title={intl.formatMessage({ id: 'wallet.menu.active_cash.tooltip' }, { clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName })}><FontAwesomeIcon icon={faCircleInfo} /></Tooltip>
                    </p>
                    {!account?.walletIsExternal ? (
                      <p className="header__user-drawer-member">
                        <FormattedMessage id="wallet.menu.pending_cash" values={{ clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
                        {': '}
                        {GetWalletNumber(userWalletData?.pendingBalance, account)} <Tooltip title={intl.formatMessage({ id: 'wallet.menu.pending_cash.tooltip' }, { clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName })}><FontAwesomeIcon icon={faCircleInfo} /></Tooltip>
                      </p>) : null}
                  </>) : null}
              </div>
              {(!!isShowCredits && !account?.isMLM) ? (
                <p className="header__user-drawer-credits">{items?.leisureCredits}</p>) : null}
            </div>

            {items?.user ? (
              <div className="header__user-drawer-links">
                {items?.user.map((item, index) =>
                  isExternalUrl(item.url) ? (
                    <a
                      key={`${item.title}${index}`}
                      className="header__user-drawer-link"
                      href={item.url}
                      onClick={this.onCloseUserLinks}
                    >
                      <FormattedMessage id={item.title} />
                    </a>
                  ) : (
                    <Link
                      key={`${item.title}${index}`}
                      className="header__user-drawer-link"
                      to={`/${account?.name}${getLocalURI(item.url)}`}
                      onClick={this.onCloseUserLinks}
                    >
                      <FormattedMessage id={item.title} />
                    </Link>
                  ),
                )}
              </div>) : null}

            {(isPrivateTokenAccountType && !account?.isCapitalVacationsCondoRes && (!isRSITemplate || items?.showMyAccount)) ? (
              <div className="header__user-drawer-links">
                <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.RsiAccount}${homeParams}`} onClick={this.onCloseUserLinks}>
                  <FormattedMessage id={'my.account.title'} />
                </Link>
              </div>) : null}

            {(isPrivateTokenAccountType && account?.isCapitalVacations) ? (
              <div className="header__user-drawer-links">
                <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.RsiAccount}/reservations${homeParams}`} onClick={this.onCloseUserLinks}>
                  <FormattedMessage id={'reservation.search.title'} />
                </Link>
              </div>) : null}

            {(isPrivateLoginAccountType || (hasClientCash && isLogged && !account?.isCapitalVacations)) ? (
              <div className="header__user-drawer-links">
                <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.Reservations}${homeParams}`} onClick={this.onCloseUserLinks}>
                  <FormattedMessage id="reservation.search.title" />
                </Link>

                {(!items?.isMLM && !account?.isCapitalVacations) ? (
                  <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.ChangePassword}`} onClick={this.onCloseUserLinks}>
                    <FormattedMessage id="change.password" />
                  </Link>) : null}

                {(items?.isMLM && isParentSidekick) ? (
                  <Link className="header__user-drawer-link" to={`/${account?.name}${Routes.RsiTestDriveManager}`} onClick={this.onCloseUserLinks}>
                    {!isEmpty(items?.sideKickShortName) ? items?.sideKickShortName : (<FormattedMessage id="test.drive.manager" />)}
                  </Link>) : null}
              </div>) : null}

            {this.renderLogout()}
          </Drawer>
        </div>
      );
    }

    return null;
  }

  renderLogout = () => {
    const { menuStore, loginStore } = this.props;
    const { items } = menuStore;
    const { account, userWallet } = loginStore;

    const hasClientCash = account?.hasClientCash;
    const isCapitalVacations = account?.isCapitalVacations;

    if (isCapitalVacations && !account?.isCapitalVacationsAgent) {
      return (
        <div className="header__user-drawer-btn-wrapper" style={{ padding: '10px' }}>
        </div>
      );
    }

    const isPrivateLoginAccountType = account?.type === LoginType.PrivateWithLogin;
    const isLogged = !!userWallet;

    if (!isPrivateLoginAccountType && !(hasClientCash && isLogged)) { 
      const logoutURLPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
      const logoutUrl = account?.type === LoginType.PrivateWithToken ? !isEmpty(items?.logoutUrl)? items?.logoutUrl : !isEmpty(logoutURLPref)? logoutURLPref : null : null;

      return (
        <a href={logoutUrl} onClick={this.handleLogoutToken} className="header__user-drawer-btn-wrapper">
          <WhiteButton>
            <FormattedMessage id="log.out" />
          </WhiteButton>
        </a>);
    } else {
      return (
        <div onClick={this.handleLogout} className="header__user-drawer-btn-wrapper">
          <WhiteButton>
            <FormattedMessage id="log.out" />
          </WhiteButton>
        </div>);
    }
  }

  getHeaderColorStyle = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    const styleBackground: any = {};

    const footerFontsColorDB = getPreferenceFromAccount(account, HEADER_FONTS_COLOR_FIELD_KEY);
    const headerBackgroundColorDB = getPreferenceFromAccount(account, HEADER_BACKGROUND_COLOR_FIELD_KEY);
    if (!isEmpty(headerBackgroundColorDB)) {
      styleBackground.backgroundColor = headerBackgroundColorDB;
      document.documentElement.style.setProperty('--header-background-color', headerBackgroundColorDB);
    } else {
      styleBackground.backgroundColor = '#FFFFFF';
      document.documentElement.style.setProperty('--header-background-color', '#FFFFFF');
    }
    if (!isEmpty(footerFontsColorDB)) {
      styleBackground.color = footerFontsColorDB;
      document.documentElement.style.setProperty('--header-font-color', footerFontsColorDB);
    }
    return styleBackground;
  }

  goHome = () => {
    const { loginStore, menuStore, history, isVacationRentals } = this.props;
    const { account } = loginStore;
    const { items } = menuStore;

    const homeParams = GetHomeParams(account);

    if (!isAnyReservationCancelFromPath(history)) {
      const homePageDB = getPreferenceFromAccount(account, HOME_PAGE_URL_FIELD_KEY);
      const homePageMenu = items?.homeUrl;
      const redirectPage = !isEmpty(homePageMenu) ? homePageMenu : homePageDB;

      if (!isEmpty(redirectPage)) {
        this.props.history.push(`/${account?.name}${getLocalURI(redirectPage)}`);
      } else {
        if (isCondoEngineFromPath(history)) {
          this  .props.resetCondosFull();
          this.props.history.push(`/${account?.name}${Routes.CondoSearch}${homeParams}`);  
        } else {
          this.props.resetHotelsFull();
          if (isVacationRentals) {
            this.props.history.push(`/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);  
          } else {
            this.props.history.push(`/${account?.name}${Routes.Search}${homeParams}`);  
          }
        }
      }
    }
  }

  renderInformation = () => {
    const { menuStore, loginStore, history, intl } = this.props;
    const { items } = menuStore;
    const { account, user } = loginStore;

    const isPublicAccountType = account?.type === LoginType.Public;
    const isPrivateTokenAccountType = account?.type === LoginType.PrivateWithToken;
    const isCancelRoute = isAnyReservationCancelFromPath(history)

    const isBookRoute = isBookFromPath(history);
    const homeParams = GetHomeParams(account);

    if (!isCancelRoute && !isPublicAccountType && !!user) {
      const isRSITemplate = account?.isRSITemplate;

      const displayMenu = !!items && !!items?.header && !items?.isMLM;
      const displayQuotes = isRSITemplate && !items?.isMLM;
      const displayMyAccount = (isPrivateTokenAccountType && !account?.isCapitalVacationsCondoRes && (!isRSITemplate || items?.showMyAccount));

      const hasAnotherOption = displayMenu || displayQuotes || displayMyAccount;

      const displayPhoneInformation = items?.phone && items?.showContactInfo;
      const displayBoucherLink = items?.brochureUrl && items?.showBrochure;
      const diplayInfo = displayPhoneInformation || displayBoucherLink;

      return (
        <div className={`header__info ${items?.isMLM ? 'is-mlm' : ''} ${!diplayInfo ? 'no-info' : ''}`}>
          <div className="header__info-item">
            {this.renderPhoneInformation()}
            {this.renderBoucherLink()}
          </div>
          <div className="header__items">
            {!isBookRoute ? (
              <div className={`header__user-info information ${!hasAnotherOption ? 'no-padding' : ''}`}>
                <ModalInternalization onOpen={this.onClose} forceWhite />
              </div>) : null}

            {displayMenu
              ? items.header.map((item, index) =>
                  item?.url === 'disabled' ?
                    <label style={{ cursor: 'not-allowed' }}><Tooltip title={intl.formatMessage({ id: '"menu.link.disable' })} placement="top"><FormattedMessage id={item.title} /></Tooltip></label> :
                    isExternalUrl(item.url) ? (
                      <a
                        key={`${index}${item.title}`}
                        href={item.url}
                        target={account.disableNewTabOpen ? '_self' : '_blank'}
                        rel="noreferrer"
                        className="header__item-navigation"
                      >
                        <FormattedMessage id={item.title} />
                      </a>) : (
                      <Link
                        key={`${index}${item.title}`}
                        to={`/${account?.name}${getLocalURI(item.url)}`}
                        className="header__item-navigation"
                      >
                        <FormattedMessage id={item.title} />
                      </Link>)
                ) : null}

              {(displayQuotes && (!isRSITemplate || items?.showMyAccount)) ? (
                <Link key={`0myquotes`} to={`/${account?.name}${Routes.RsiQuotes}`} className="header__item-navigation">
                  <FormattedMessage id="my.quotes" />
                </Link>) : null}

              {displayMyAccount ?
                <Link key={`0account`} to={`/${account?.name}${getLocalURI("/account")}${homeParams}`} className="header__item-navigation">
                  <FormattedMessage id="my.account.title" />
                </Link> : null}
              
          </div>
        </div>
      );
    }
    return null;
  }

  render(): React.ReactNode {
    const { loginStore, menuStore, history, isError } = this.props;
    const { account, user, userWallet } = loginStore;
    const { loading, items } = menuStore;

    const logoDB = getPreferenceFileUrlFromAccount(account, LOGO_FIELD_KEY);
    const homePageDB = getPreferenceFromAccount(account, HOME_PAGE_URL_FIELD_KEY);
    const homePageMenu = items?.homeUrl;
    const headerBackgroundColorDB = getPreferenceFromAccount(account, HEADER_BACKGROUND_COLOR_FIELD_KEY);
    const isDisableHomes = account?.isDisableHomes;
    const disableHomesRedirect = account?.disableHomesRedirect;

    const logo = !isEmpty(items?.logoUrl)? items?.logoUrl : !isEmpty(logoDB)? logoDB : Logo;
    const isPublicAccountType = account?.type === LoginType.Public;

    const redirectPage = !isEmpty(homePageMenu) ? homePageMenu : homePageDB;
    const isCustomRedirect = !isEmpty(redirectPage) && isExternalUrl(redirectPage);
    const isCancelRoute = isAnyReservationCancelFromPath(history);
    const isBookRoute = isBookFromPath(history);
    const isSignInRoute = isSignInFromPath(history);
    const isSignUpRoute = isSignUpFromPath(history);
        
    const style: any = { backgroundColor: (!isEmpty(headerBackgroundColorDB)) ? headerBackgroundColorDB : '#FFFFFF' };

    const hasRenderInformation = !isCancelRoute && !isPublicAccountType && !!user;

    const hasClientCash = account?.hasClientCash;
    const isRSITemplate = account?.isRSITemplate;
    const isLogged = !!userWallet;

    if (account?.forceIframe) {
      return null;
    }

    return (
      <>
        {(!loading && !isError) ? (
          <div className="header" style={style}>        
            {this.renderInformation()}
            <div className={`header__content ${(isRSITemplate && account?.name === 'rsi') ? 'rsi-template' : ''}`}>
              {(!isCustomRedirect && !isDisableHomes) && (
                <div className={`header__logo ${isCancelRoute ? '' : 'go-home'}`} onClick={this.goHome}>
                  <img className="logo-icon" src={logo} alt={'logo'} />
                </div>)}

              {(isCustomRedirect && !isDisableHomes) ? 
                items?.isMLM ? (
                  <Link className="header__logo" to={`/${account?.name}/home`} rel="noreferrer">
                    <img className="logo-icon" src={logo} alt={'logo'} />
                  </Link>) : (
                  <a href={redirectPage} className="header__logo" target={account.disableNewTabOpen ? '_self' : '_blank'} rel="noreferrer">
                    <img className="logo-icon" src={logo} alt={'logo'} />
                  </a>) : null}

              {(isDisableHomes && isEmpty(disableHomesRedirect)) && (
                <div className="header__logo">
                  <img className="logo-icon" src={logo} alt={'logo'} />
                </div>)}

              {(isDisableHomes && !isEmpty(disableHomesRedirect)) && (
                <a href={disableHomesRedirect} className="header__logo" target={account.disableNewTabOpen ? '_self' : '_blank'} rel="noreferrer">
                  <img className="logo-icon" src={logo} alt={'logo'} />
                </a>)}

              {this.renderNavidationMenu()}

              {(!hasRenderInformation && !isBookRoute)? (
                <div className="header__user-info desktop">
                  <ModalInternalization onOpen={this.onClose} />
                </div>) : null}

              {this.renderWelcome()}

              {(hasClientCash && !isLogged && !isSignInRoute && !isSignUpRoute) && (
                <div className="header__user-info desktop">
                  <div className="header__link-nav-child-link" style={{ marginRight: '6px' }} onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></div> <FormattedMessage id="or" /> <div className="header__link-nav-child-link" style={{ marginLeft: '6px' }} onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></div>
                </div>)}

            </div>
          </div>) : null}

        {(loading || isError) ? <SkeletonHeader active={!isError} /> : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
    reviewBookStore: state.reviewBookStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    benefitsStore: state.benefitsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { 
  getNavigationMenu, 
  setLoginRedirect,
  setVisible,
  setIsUpdateMenu,
  logout,
  endImpersonation,
  getBenefits,
  resetHotelsFull,
  resetCondosFull
};

export const Header = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(HeaderComponent)));
