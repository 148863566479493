import React from 'react';

import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Select, Checkbox } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/es/checkbox/Checkbox';

import {
  ACCOUNTMENU_FIELD_KEY,
  BACKGROUND_COLOR_FIELD_KEY,
  BACKGROUND_PHOTO_FIELD_KEY,
  BUTTONS_COLOR_FIELD_KEY,
  EXPIRATION_FIELD_KEY,
  FOOTERMENU_FIELD_KEY,
  HEADERMENU_FIELD_KEY,
  IAccount,
  IAccountPreference,
  LOGO_FIELD_KEY, 
  TOKEN_SSO_URL_FIELD_KEY, 
  USER_MENU_SSO_URL_FIELD_KEY,
  USER_SSO_URL_FIELD_KEY
} from '@share/common-types';
import {
  saveAccount,
  getSelectedAccountDetails,
  IAccountsState,
  IAccountSaveState,
} from '@store/slices';
import {
  LoginType,
  ILoginState
} from '@share/store/slices';
import { getAccountIdFromAdminPath, RootState } from '@share/utils';
import { Routes } from '@share/constants';
import { ModalConfirmation, ModalMenu } from '@components';
import { Loading, LoadingSise } from '@share/components';
import { countriesCode } from '@constants';
import { IMenuItem } from '@common-types';
import { GetPreferenceValue, GetPreferenceValueParsed } from '@utils';

import { 
  DOMAIN_FIELD_NAME,
  EMAIL_FIELD_NAME,
  FORM_FIELDS_VALIDATIONS,
  NAME_FIELD_NAME,
  SITEID_FIELD_NAME,
  IFormValidation,
  IFormValidations,
  IsFormValid,
  IsFormTouched,
  EXPIRATION_VALIDATION_FIELD_NAME,
  TYPE_FIELD_NAME,
  INITIAL_VALIDATION,
  ValidateForm,
  IsExpirationFormFieldValid,
  PROPERTY_FIELD_NAME,
  COUNTRY_FIELD_NAME,
  IsFormFieldValidByField,
  ValidateSiteIdForm,
  USER_TOKEN_URL_VALIDATION_FIELD_NAME,
  USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME,
  TOKEN_URL_VALIDATION_FIELD_NAME
} from './form_utils';

import './style.scss';

export const accessTypes = [
  { value: LoginType.Public, title: 'Public' },
  { value: LoginType.PrivateWithLogin, title: 'Private With Login' },
  { value: LoginType.PrivateWithToken, title: 'Private With Token' }
];

export const properties = [
  { value: 'Hotel', title: 'Hotel' },
  { value: 'Condo', title: 'Condo' },
  { value: 'Both', title: 'Both' }
];

interface IMapStateToProps {
  accountsStore: IAccountsState;
  accountSaveStore: IAccountSaveState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getSelectedAccountDetails: (accountId: number) => void;
  saveAccount: (account: IAccount, logoFile: any, backgroundPhotoFile: any) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {}

interface IState {
  displayColorPicker: boolean;
  displayButtonsColorPicker: boolean;
  formValidations: IFormValidations;
  isUpdate: boolean;
  isExpirationChecked: boolean;
  showConfirmation: boolean;
  showAccountMenu: boolean;
  showHeaderMenu: boolean;
  showFooterMenu: boolean;
  account: IAccount;
  logoFile: any;
  backgroundPhotoFile: any;
}

class AccountDetailsComponent extends React.Component<IProps, IState> {

  state: IState = {
    displayColorPicker: false,
    displayButtonsColorPicker: false,
    formValidations: INITIAL_VALIDATION,
    isUpdate: false,
    isExpirationChecked: false,
    showConfirmation: false,
    showAccountMenu: false,
    showHeaderMenu: false,
    showFooterMenu: false,
    account: new IAccount(),
    logoFile: null,
    backgroundPhotoFile: null
  };

  componentDidMount() {
    const accountId = getAccountIdFromAdminPath(this.props.history);
    this.props.getSelectedAccountDetails(accountId);

    const isUpdate = !!accountId;
    const account = this.state.account;
    if (!isUpdate) {
      const accountLoggedUser = this.props.loginStore.account;
      account.siteId = accountLoggedUser.siteId;
    }

    this.setState({ account, isUpdate });

    const formValidations = ValidateSiteIdForm(account);
    this.setState({ formValidations });

  }

  componentDidUpdate(prevProps: IProps) {
    if (this.state.isUpdate &&
        !prevProps.accountsStore.selectedAccount &&
        this.props.accountsStore.selectedAccount?.id) {
      const account = this.props.accountsStore.selectedAccount;
      const pref = GetPreferenceValueParsed(account, EXPIRATION_FIELD_KEY, "");
      if (!isEmpty(pref) && (!isEmpty(pref.value) || !isEmpty(pref.type))) {
        this.setState({ isExpirationChecked: true });
      }
      this.setState({ account });

      this.handleValidate(account);
    }
  }
  
  handleColorChange = (color: any) => {
    this.handleUpdatePreference(color.hex, BACKGROUND_COLOR_FIELD_KEY);
  };

  handleButtonsColorChange = (color: any) => {
    this.handleUpdatePreference(color.hex, BUTTONS_COLOR_FIELD_KEY);
  };

  handleUpdatePreference = (value: string, field: string) => {
    const account: IAccount = { ...this.state.account };
    let preferences: IAccountPreference[] = account.preferences;
    if (isEmpty(preferences)) {
      preferences = [{
        key: field,
        value     
      }];
    } else {
      let prefDB: any = { ...preferences.find(p => p.key === field) };
      const preferencesDb = [...preferences.filter(p => p.key !== field)];
      if (isEmpty(prefDB)) {
        prefDB = { key: field };
      }
      prefDB.value = value;
      preferencesDb.push(prefDB);
      preferences = [...preferencesDb];
    }
    account.preferences = preferences;
    this.setState({ account });
  }

  handleRemovePreference = (field: string) => {
    const account: IAccount = { ...this.state.account };
    let preferences: IAccountPreference[] = account.preferences;
    if (!isEmpty(preferences)) {
      const preferencesDb = [...preferences.filter(p => p.key !== field)];
      preferences = [...preferencesDb];
    }
    account.preferences = preferences;
    this.setState({ account });
  }

  handleClickThemeColor = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };
  handleClickButtonsColor = () => {
    this.setState({ displayButtonsColorPicker: !this.state.displayButtonsColorPicker })
  };

  handleCloseThemePicker = () => {
    this.setState({ displayColorPicker: false })
  };
  handleCloseButtonsPicker = () => {
    this.setState({ displayButtonsColorPicker: false })
  };

  handleValidate = (account: IAccount) => {
    const formValidations = ValidateForm(account);
    this.setState({ formValidations });
  }

  handleInputChange = (value: string, field: string, isPreference?: boolean) => {
    const account: IAccount = { ...this.state.account };
    if (isPreference) {
      this.handleUpdatePreference(value, field);
    } else {
      (account as any)[field] = value;
      this.setState({ account });
    }

    const formValidation: IFormValidation = (FORM_FIELDS_VALIDATIONS as any)[field];

    if(formValidation) {
      const formValidations = this.state.formValidations;

      if (field === TYPE_FIELD_NAME) {
        const isPrivateWithTokenType = value === LoginType.PrivateWithToken;
        const isUserSSOUrlValid = !isPrivateWithTokenType || !isEmpty(this.getUserSSOData());
        const isUserMenuSSOUrlValid = !isPrivateWithTokenType || !isEmpty(this.getUserMenuSSOData());
        const isTokenSSOUrlValid = !isPrivateWithTokenType || !isEmpty(this.getTokenSSOData());
    
        formValidations[USER_TOKEN_URL_VALIDATION_FIELD_NAME] = { valid: isUserSSOUrlValid, touched: true };
        formValidations[USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME] = { valid: isUserMenuSSOUrlValid, touched: true };
        formValidations[TOKEN_URL_VALIDATION_FIELD_NAME] = { valid: isTokenSSOUrlValid, touched: true };
      }

      const formKey = formValidation.field as keyof IFormValidations;
      formValidations[formKey] = { valid: formValidation.validation(value), touched: true };

      this.setState({ formValidations });
    }
  }

  handleCancel = () => {
    if (this.isFormTocuhed()) { 
      this.setState({ showConfirmation: true });
    } else {
      this.props.history.push(Routes.Accounts);
    }
  }

  handleSaveAccount = () => {
    if (this.isFormValid()) {
      const { logoFile, backgroundPhotoFile } = this.state;
      const account: IAccount = { ...this.state.account };
      this.props.saveAccount(account, logoFile, backgroundPhotoFile);
    }
  }
  
  handleCancelModal = (): void => {
    this.setState({ showConfirmation: false });
  };

  handleConfirmModal = () => {
    this.setState({ showConfirmation: false });
    this.props.history.push(Routes.Accounts);
  }

  handleLogoFile = (event: any) => {
    const logoFile = event.target.files[0];
		this.setState({ logoFile });
    const value = JSON.stringify({ Key: logoFile.name, Value: null });
    this.handleUpdatePreference(value, LOGO_FIELD_KEY);
	};

  handleBackgroundPhotoFile = (event: any) => {
    const backgroundPhotoFile = event.target.files[0];
		this.setState({ backgroundPhotoFile });
    const value = JSON.stringify({ Key: backgroundPhotoFile.name, Value: null });
    this.handleUpdatePreference(value, BACKGROUND_PHOTO_FIELD_KEY);
	};

  handleExpirationType = (value: string, field: string): void => {
    const expirationData = this.getExpirationData();
    (expirationData as any)[field] = value;
    this.handleUpdatePreference(JSON.stringify(expirationData), EXPIRATION_FIELD_KEY);
    this.handleExpirationCheckBase(expirationData, this.state.isExpirationChecked);
  };

  handleExpirationCheck = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isExpirationChecked: e.target.checked });
    if (!e.target.checked) {
      this.handleRemovePreference(EXPIRATION_FIELD_KEY);
    } 
    this.handleExpirationCheckBase(null, e.target.checked);
  }

  handleExpirationCheckBase = (expirationData: any, isExpirationChecked: boolean): void => {
    let isExpirationValid = true;
    if (isExpirationChecked) {
      if (isEmpty(expirationData?.value) || isEmpty(expirationData?.type)) {
        isExpirationValid = false;
      }
    }
    this.setState({ formValidations: {
      ...this.state.formValidations,
      [EXPIRATION_VALIDATION_FIELD_NAME]: { valid: isExpirationValid, touched: true }
    }});
  }
  
  handleAccountMenu = () => {
    this.setState({ showAccountMenu: true, showHeaderMenu: false, showFooterMenu: false });
  }
  handleHeaderMenu = () => {
    this.setState({ showAccountMenu: false, showHeaderMenu: true, showFooterMenu: false });
  }
  handleFooterMenu = () => {
    this.setState({ showAccountMenu: false, showFooterMenu: true, showHeaderMenu: false });
  }
  handleCancelMenuModal = () => {
    this.setState({ showAccountMenu: false, showFooterMenu: false, showHeaderMenu: false });
  }
  handleSaveAccountMenuModal = (items: IMenuItem[]) => {
    this.handleUpdatePreference(JSON.stringify(items), ACCOUNTMENU_FIELD_KEY);
    this.setState({ showAccountMenu: false, showFooterMenu: false, showHeaderMenu: false });
  }
  handleSaveHeaderMenuModal = (items: IMenuItem[]) => {
    this.handleUpdatePreference(JSON.stringify(items), HEADERMENU_FIELD_KEY);
    this.setState({ showAccountMenu: false, showFooterMenu: false, showHeaderMenu: false });
  }
  handleSaveFooterMenuModal = (items: IMenuItem[]) => {
    this.handleUpdatePreference(JSON.stringify(items), FOOTERMENU_FIELD_KEY);
    this.setState({ showAccountMenu: false, showFooterMenu: false, showHeaderMenu: false });
  }
  
  getAccountBackgroundColor = () => {
    return this.getPreferenceValue(BACKGROUND_COLOR_FIELD_KEY, '#ffffff');
  }
  getAccountButtonsColor = () => {
    return this.getPreferenceValue(BUTTONS_COLOR_FIELD_KEY, '#ffffff');
  }
  getLogoFileName = () => {
    return this.getFilePreferenceValue(LOGO_FIELD_KEY);
  }
  getBackgroundPhotoFileName = () => {
    return this.getFilePreferenceValue(BACKGROUND_PHOTO_FIELD_KEY);
  }
  getExpirationData = () => {
    const pref = this.getPreferenceValue(EXPIRATION_FIELD_KEY, '{}');
    return JSON.parse(pref);
  }
  getUserSSOData = () => {
    const pref = this.getPreferenceValue(USER_SSO_URL_FIELD_KEY, '{}');
    return JSON.parse(pref);
  }
  getUserMenuSSOData = () => {
    const pref = this.getPreferenceValue(USER_MENU_SSO_URL_FIELD_KEY, '{}');
    return JSON.parse(pref);
  }
  getTokenSSOData = () => {
    const pref = this.getPreferenceValue(TOKEN_SSO_URL_FIELD_KEY, '{}');
    return JSON.parse(pref);
  }
  getFilePreferenceValue = (field: string) => {
    const value = this.getPreferenceValue(field, null);
    if (!isEmpty(value)) {
      const preference = JSON.parse(value);
      if (!isEmpty(preference?.Key)) {
        return preference.Key;
      }
    }
    return 'No file chosen';
  }

  getPreferenceValue = (key: string, defaultValue: string = null) => {
    const { account } = this.state;
    return GetPreferenceValue(account, key, defaultValue);
  }

  getFieldValue = (value: string) => {
    return value? value : '';
  } 

  isFormValid = () => {
    return IsFormTouched(this.state.formValidations) && IsFormValid(this.state.formValidations); 
  } 

  isFormTocuhed = () => {
    const accountDbStr = JSON.stringify(this.props.accountsStore.selectedAccount);
    const accountStateStr = JSON.stringify(this.state.account);
    return accountDbStr !== accountStateStr;
  }

  renderColorPicker = (display: boolean, color: string, handleColorChange: any, handleClosePicker: any) => {
    if(display) {
      return (
        <div className="color-picker">
          <div className="cover" onClick={ handleClosePicker }/>
          <SketchPicker
            color={ color }
            onChange={ handleColorChange }
          />
        </div>
      );
    } 
    return null;
  }

  renderPrivateWithUserURL = () => {
    const { account } = this.state;
    const display = account.type === LoginType.PrivateWithToken;
    return this.renderInput(true, USER_SSO_URL_FIELD_KEY, 'User Data SSO URL', display, false, true);
  }

  renderPrivateWithTokenURL = () => {
    const { account } = this.state;
    const display = account.type === LoginType.PrivateWithToken;
    return this.renderInput(true, TOKEN_SSO_URL_FIELD_KEY, 'Token SSO URL', display, false, true);
  }

  renderPrivateWithMenuURL = () => {
    const { account } = this.state;
    const display = account.type === LoginType.PrivateWithToken;
    return this.renderInput(true, USER_MENU_SSO_URL_FIELD_KEY, 'User Menu SSO URL', display, false, false);
  }

  renderInput = (isAccountPreference: boolean, key: string, title: string, display: boolean, showCounter: boolean, mandatory: boolean, externalMessage?: string) => {
    const { account, formValidations } = this.state;
    let value;
    if (isAccountPreference) {
      value = this.getPreferenceValue(key, '');
    } else {
      value = get(account, `${key}`, '');
    }

    const isValid = IsFormFieldValidByField(key, formValidations);

    if (display) {
      return (
        <div className="row">
          <div className="col-4">
            <label className={`title ${isValid? '' : 'invalid'}`}>
              {mandatory && <label className="mandatory">*</label>}
              {title}:
            </label>
          </div>
          <div className="col-8 form-input-container">
            <div className={`form-input-content ${isValid? '' : 'invalid'}`}>
              <input
                value={this.getFieldValue(value)}
                className={`form-input ${isValid? '' : 'invalid'}`}
                placeholder={`Enter ${title}`}
                onChange={(e) => this.handleInputChange(e.target.value, key, isAccountPreference)}
              ></input>
              {showCounter && (<div className={`counter ${isValid? '' : 'invalid'}`}>
                {isEmpty(value) ? '0' : value.length}/30
              </div>)}
            </div>
            <label className={`domain ${isValid? '' : 'invalid'}`}>{externalMessage}</label>
          </div>
        </div>
      );  
    }
    return null;
  }

  renderInputSelect = (key: string, title: string, options: any[], mandatory?: boolean) => {
    const { account, formValidations } = this.state;
    const value = get(account, `${key}`, '');
    const isValid = IsFormFieldValidByField(key, formValidations);
    return (
      <div className="row">
        <div className="col-4">
          <label className={`title ${isValid? '' : 'invalid'}`}>
            {mandatory && <label className="mandatory">*</label>}
            {title}:
          </label>
        </div>
        <div className="col-8">
          <Select
            showSearch={true}
            placeholder={title}
            value={this.getFieldValue(value)}
            className={`form-input ${isValid? '' : 'invalid'}`}
            style={{ width: '175px' }}
            onChange={(e) => this.handleInputChange(e, key, false)}
          >
            {options.map((option, index) => <Select.Option key={index} value={option.value}>{option.title}</Select.Option>)}
          </Select>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    const loadingSave = this.props.accountSaveStore.loading;
    const loadingGet = this.props.accountsStore.loading;
    if (loadingGet || loadingSave) {
      return (
        <div className="save-account">
          <div className="save-account__wrapper loading-container">
            <Loading size={LoadingSise.Medium} />
          </div>
        </div>
      );
    } else {
      const saveButtonClassname = `save-button ${this.isFormValid() ? '' : 'disabled'}`;
      const { account, isExpirationChecked, displayColorPicker, displayButtonsColorPicker, formValidations } = this.state;
      const expirationData = this.getExpirationData();

      const expirationValid = IsExpirationFormFieldValid(formValidations);
      
      const accountMenuPreference = GetPreferenceValueParsed(account, ACCOUNTMENU_FIELD_KEY, "[]");
      const headerMenuPreference = GetPreferenceValueParsed(account, HEADERMENU_FIELD_KEY, "[]");
      const footerMenuPreference = GetPreferenceValueParsed(account, FOOTERMENU_FIELD_KEY, "[]");
      const hasAccountMenu = accountMenuPreference?.length > 0;
      const hasHeaderMenu = headerMenuPreference?.length > 0;
      const hasFooterMenu = footerMenuPreference?.length > 0;
      
      const countries = countriesCode.map(country => ({ value: country.isoCode, title: country.name }));

      return (
        <div className="save-account">
          <div className="save-account__wrapper">
            <div className="save-account__fields container-fluid">
              <div className="row">
                <div className="col-lg-12 mandatory-message">
                  <label className="mandatory">*</label>Mandatory fields
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 save-account__title">
                  Account Details
                </div>
              </div>

              <div className="row grid-row">
                <div className="col-lg-6">
                  {this.renderInput(false, DOMAIN_FIELD_NAME, 'Domain', true, true, true)}
                  {this.renderInput(false, SITEID_FIELD_NAME, 'Site Id', true, true, true)}
                  {this.renderInputSelect(TYPE_FIELD_NAME, 'Access Type', accessTypes, true)}
                  {this.renderPrivateWithUserURL()}
                  {this.renderPrivateWithTokenURL()}
                  {this.renderPrivateWithMenuURL()}
                </div>

                <div className="col-lg-6">
                  {this.renderInput(false, NAME_FIELD_NAME, 'Company Name', true, true, true)}
                  {this.renderInputSelect(COUNTRY_FIELD_NAME, 'Country', countries, true)}
                  {this.renderInput(false, EMAIL_FIELD_NAME, 'Email', true, true, true)}
                  {this.renderInputSelect(PROPERTY_FIELD_NAME, 'Properties', properties, true)}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 save-account__title" style={{ marginTop: '50px' }}>
                  Account Preferences
                </div>
              </div>

              <div className="row grid-row">
                <div className="col-lg-6">
                  <div className="row">
                      <div className="col-4">
                        <label className="title">Logo:</label>
                      </div>
                      <div className="col-8">
                        <label htmlFor="logo" className="file-upload">Chose a file</label>
                        <label className="file-uploaded">{this.getLogoFileName()}</label>
                        <input type="file" id="logo" onChange={this.handleLogoFile} />
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className="title">Background photo:</label>
                    </div>
                    <div className="col-8">
                      <label htmlFor="background" className="file-upload">Chose a file</label>
                      <label className="file-uploaded">{this.getBackgroundPhotoFileName()}</label>
                      <input type="file" id="background" onChange={this.handleBackgroundPhotoFile} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className="title">Account Menu:</label>
                    </div>
                    <div className="col-8">
                      <label className="file-upload" onClick={this.handleAccountMenu}>{ !hasAccountMenu ?  'Create' : 'Edit'} Menu</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className="title">Header Menu:</label>
                    </div>
                    <div className="col-8">
                      <label className="file-upload" onClick={this.handleHeaderMenu}>{ !hasHeaderMenu ?  'Create' : 'Edit'} Menu</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className="title">Footer Menu:</label>
                    </div>
                    <div className="col-8">
                      <label className="file-upload" onClick={this.handleFooterMenu}>{ !hasFooterMenu ?  'Create' : 'Edit'} Menu</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-4">
                      <label className="title">Theme Color:</label>
                    </div>
                    <div className="col-8">
                      <div className="color-button" onClick={this.handleClickThemeColor}>
                        <div className="color" style={{ background: this.getAccountBackgroundColor() }} />
                      </div>
                      {this.renderColorPicker(displayColorPicker, this.getAccountBackgroundColor(), this.handleColorChange, this.handleCloseThemePicker)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className="title">Buttons Color:</label>
                    </div>
                    <div className="col-8">
                      <div className="color-button" onClick={this.handleClickButtonsColor}>
                        <div className="color" style={{ background: this.getAccountButtonsColor() }} />
                      </div>
                      {this.renderColorPicker(displayButtonsColorPicker, this.getAccountButtonsColor(), this.handleButtonsColorChange, this.handleCloseButtonsPicker)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className={`title ${expirationValid? '' : 'invalid'}`}>Expiration:</label>
                    </div>

                    <div className="col-8 form-input-container expiration-container">
                      <Checkbox
                        checked={isExpirationChecked}
                        onChange={this.handleExpirationCheck}
                      >
                      </Checkbox>
                      <div className="form-input-content" style={{ width: '100%', marginTop: '10px' }}>
                        <div className="row">
                          <div className="col-1">
                            <label>Set:</label> 
                          </div>
                          <div className="col-6">
                            <input 
                              type="number"
                              className="expiration-input form-input"
                              value={this.getFieldValue(expirationData.value)}
                              onChange={(e) => this.handleExpirationType(e.target.value, 'value')}
                            ></input>
                          </div>
                          <div className="col-4">
                            <Select
                              showSearch={true}
                              placeholder={'Number'}
                              value={expirationData.type}
                              onChange={(e) => this.handleExpirationType(e, 'type')}
                            >
                              <Select.Option value={'DAYS'}>Days</Select.Option>
                              <Select.Option value={'HOURS'}>Hours</Select.Option>
                              <Select.Option value={'MONTHS'}>Months</Select.Option>
                              <Select.Option value={'YEARS'}>Years</Select.Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 buttons">
                  <button className="cancel-button" onClick={this.handleCancel}>Cancel</button>
                  <button className={saveButtonClassname} onClick={this.handleSaveAccount}>Save</button>
                </div>
              </div>
            </div>
          </div>
          <ModalConfirmation
            message="unsaved.changes"
            title="confirm.cancel"
            yesMessage="confirm.button"
            noMessage="cancel.button"
            visible={this.state.showConfirmation}
            onCancel={this.handleCancelModal}
            onConfirm={this.handleConfirmModal}
          />
          <ModalMenu
            title={hasHeaderMenu ? 'edit.account.menu.modal.title' : 'create.account.menu.modal.title'}
            items={accountMenuPreference}
            maxLevel={0}
            visible={this.state.showAccountMenu}
            onCancel={this.handleCancelMenuModal}
            onSave={this.handleSaveAccountMenuModal}
          />
          <ModalMenu
            title={hasHeaderMenu ? 'edit.header.menu.modal.title' : 'create.header.menu.modal.title'}
            items={headerMenuPreference}
            maxLevel={3}
            visible={this.state.showHeaderMenu}
            onCancel={this.handleCancelMenuModal}
            onSave={this.handleSaveHeaderMenuModal}
          />
          <ModalMenu
            title={hasFooterMenu ? 'edit.footer.menu.modal.title' : 'create.footer.menu.modal.title'}
            items={footerMenuPreference}
            maxLevel={0}
            visible={this.state.showFooterMenu}
            onCancel={this.handleCancelMenuModal}
            onSave={this.handleSaveFooterMenuModal}
          />
          
        </div>
      );
    }
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    accountsStore: state.accountsStore,
    accountSaveStore: state.accountSaveStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getSelectedAccountDetails,
  saveAccount
};

const AccountDetailsComponentWithRouter = withRouter(injectIntl(AccountDetailsComponent));

export const AccountDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDetailsComponentWithRouter);
