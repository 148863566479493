import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { OfficesList } from '@constants';
import './style.scss';

export class ConciergeOffices extends Component {
  render(): React.ReactNode {
    return (
      <div className="offices-wrapper">
        <div className="offices-wrapper__wrapper">
          <h2 className="offices-wrapper__heading">
            <FormattedMessage id="concierge.offices.heading" />
          </h2>
          <div className="offices-wrapper__images-wrapper">
            {OfficesList.map(({ src, alt, id }) => (
              <img key={id} className="offices-wrapper__image" src={src} alt={alt} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
