
export const SEARCH_TITLE_FIELD_KEY = 'search_title';
export const SEARCH_MESSAGE_FIELD_KEY = 'search_message';
export const VACATION_RENTALS_SEARCH_TITLE_FIELD_KEY = 'vacation_rentals_search_title';
export const VACATION_RENTALS_SEARCH_MESSAGE_FIELD_KEY = 'vacation_rentals_search_message';
export const LOGOUT_FIELD_KEY = 'logout_url';
export const BACKGROUND_COLOR_FIELD_KEY = 'background_color';
export const BUTTONS_COLOR_FIELD_KEY = 'buttons_color';
export const BACKGROUND_PHOTO_FIELD_KEY = 'background_photo';
export const BACKGROUND_PHOTO_MOBILE_FIELD_KEY = 'background_photo_mobile';
export const CONDOS_BACKGROUND_PHOTO_FIELD_KEY = 'background_photo';//'condos_background_photo';
export const WIDGET_BANNER_PHOTO_FIELD_KEY = 'widget_banner_photo';
export const EXPIRATION_FIELD_KEY = 'expiration';
export const LOGO_FIELD_KEY = 'logo';
export const ACCOUNTMENU_FIELD_KEY = 'account_menu';
export const HEADERMENU_FIELD_KEY = 'header_menu';
export const FOOTERMENU_FIELD_KEY = 'footer_menu';
export const USER_SSO_URL_FIELD_KEY = 'user_sso_url';
export const TOKEN_SSO_URL_FIELD_KEY = 'token_sso_url';
export const RSI_MASTER_CLASS_REGISTRATION_URL_FIELD_KEY = 'master_class_registration_url';
export const USER_MENU_SSO_URL_FIELD_KEY = 'user_menu_sso_url';
export const HOME_PAGE_URL_FIELD_KEY = 'home_page_url';
export const SEARCH_TYPES_FIELD_KEY = 'search_types';
export const HOME_PAGE_TEMPLATE_FIELD_KEY = 'home_page_template';
export const HEADER_BACKGROUND_COLOR_FIELD_KEY = 'header_background_color';
export const ENABLE_WALLET_FIELD_NAME = 'enableWallet';
export const WALLET_PERCENTAGE_FIELD_KEY = 'walletPercentage';
export const WALLET_EXPIRATION_NUMBER_FIELD_KEY = 'walletExpirationNumber';
export const WALLET_EXPIRATION_PERIOD_FIELD_KEY = 'walletExpirationPeriod';
export const HEADER_FONTS_COLOR_FIELD_KEY = 'header_fonts_color';
export const FOOTER_BACKGROUND_COLOR_FIELD_KEY = 'footer_background_color';
export const FOOTER_FONTS_COLOR_FIELD_KEY = 'footer_fonts_color';
export const BANNER_PRIMARY_PHOTO_FIELD_KEY = 'banner_primary_photo';
export const BANNER_PRIMARY_PHOTO_URL_FIELD_KEY = 'banner_primary_photo_url';
export const BANNER_SECONDARY_PHOTO_FIELD_KEY = 'banner_secondary_photo';
export const BANNER_SECONDARY_PHOTO_URL_FIELD_KEY = 'banner_secondary_photo_url';
export const BANNER_SEARCH_RESULT_PHOTO_FIELD_KEY = 'banner_search_result_photo';
export const BANNER_SEARCH_RESULT_PHOTO_URL_FIELD_KEY = 'banner_search_result_photo_url';
export const HAS_PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME = 'hasPublicFixedToken';
export const PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME = 'publicFixedToken';
export const PUBLIC_WITH_FIXED_REDIRECT_FIELD_NAME = 'publicFixedRedirect';
export const DEFAULT_CURRENCY_FIELD_KEY = 'default_currency';
export const SALES_ASSISTANTS_FIELD_KEY = 'sales_assistants';
export const SUPPORT_ASSISTANTS_FIELD_KEY = 'support_assistants';
export const DISPLAY_CONTACT_SECTION_FIELD_NAME = 'displayContactSection';
export const DISPLAY_TRUSTED_BRANDS_SECTION_FIELD_NAME = 'displayTrustedBrandsSection';
export const FAVICON_FIELD_KEY = 'favicon';
export const PAGE_TITLE_FIELD_KEY = 'page_title';
export const CONDOS_SEARCH_TITLE_FIELD_KEY = 'condos_search_title';
export const CONDOS_SEARCH_MESSAGE_FIELD_KEY = 'condos_search_message';
export const ACCOUNT_MINSAVING_FIELD_NAME = 'savings_percentaje_min';
export const FORCE_IFRAME_FIELD_NAME = 'force_iframe';
export const WALLET_USE_SLIDER_FIELD_KEY = 'walletUseSlider';
export const WALLET_NO_MORE_EARNINGS_FIELD_KEY = 'walletNoMoreThanEarnings';
export const HAS_PUBLIC_TOKEN_EXPIRATION_FIELD_NAME = 'hasPublicTokenExpiration';
export const TOKEN_FROM_URL_FIELD_KEY = 'privateTokenFromUrl';
export const WALLET_EXTERNAL_FIELD_KEY = 'walletIsExternal';
export const WALLET_EXTERNAL_PROFILE_FIELD_KEY = 'walletExternalProfile';
export const WALLET_CLIENT_CASH_NAME_FIELD_KEY = 'walletClientCashName';
export const WALLET_NO_DECIMALS_FIELD_KEY = 'walletNoDecimals';
export const FORCE_DISABLE_VACATION_RENTALS_FIELD_NAME = 'filter_vacation_rentals';
export const WALLET_SLIDER_ON_SEARCH_FIELD_KEY = 'walletHasSliderOnSearch';
export const FORCE_FOOTER_VERTICAL_FIELD_KEY = 'forceFooterVertical';
export const BREAKDOWN_TAXES_FIELD_NAME = 'breakdown_taxes';
export const FEATURED_DESTINATIONS_FIELD_KEY = 'featured_destinations';
export const WALLET_HIDE_AMOUNT_FIELD_KEY = 'walletHideAmount';
export const TRAVEL_AGENT_PRO_DISABLE_IMPERSONATE_FIELD_NAME = 'disableImpersonate';
export const TRAVEL_AGENT_PRO_FORCE_IMPERSONATE_FIELD_NAME = 'forceImpersonate';
export const WALLET_DISPLAY_B2C_SAVINGS_FIELD_KEY = 'walletDisplayB2CSavings';
export const WALLET_DISPLAY_MESSAGE_GET_MORE_CLIENT_CASH_FIELD_KEY = 'walletDisplayGetMoreClientCash';
export const CONTACT_ASSISTANTS_FIELD_KEY = 'contact_assistants';

export const DISABLE_HOMES_FIELD_NAME = 'disableHomes';
export const DISABLE_HOMES_REDIRECT_FIELD_NAME = 'disableHomesRedirect';

export const DISABLE_LANGUAGES_FIELD_NAME = 'disableLanguages';
export const DISABLE_CURRENCIES_FIELD_NAME = 'disableCurrencies';

export const DISPLAY_GETAWAYS_HOME_HOTELS_SECTION_FIELD_NAME = 'displayGetawaysHomeHotels';
export const DISPLAY_GETAWAYS_HOME_HOTELS_SLIDER_FIELD_NAME = 'displayGetawaysHomeHotelsSlider';
export const DISPLAY_GETAWAYS_HOME_CONDOS_SECTION_FIELD_NAME = 'displayGetawaysHomeCondos';
export const DISPLAY_GETAWAYS_HOME_CONDOS_SLIDER_FIELD_NAME = 'displayGetawaysHomeCondosSlider';

export const HIDE_THINGS_MATTER_SECTION_FIELD_NAME = 'hideThingsMatterSection';

export const DISABLE_HOTELS_SEARCH_OPTIONS_FIELD_NAME = 'forceDisableHotelSearchOptions';

export const ENABLE_INSURANCE_FIELD_NAME = 'enable_insurance';

export const LEAD_TIME_FIELD_NAME = 'lead_time';

export const DEFAULT_LANGUAGE_FIELD_KEY = 'default_language';
export const GOOGLE_ANALITYCS_CODE_FIELD_KEY = 'google_analitysc_code';
export const DISABLE_NEW_TAB_FIELD_NAME = 'disableNewTabOpen';
export const FORCE_MEMBER_FIELD_KEY = 'forceMember';

export const ABOUTUS_FACEBOOK_FIELD_KEY = 'aboutus_facebook_url';
export const ABOUTUS_INSTAGRAM_FIELD_KEY = 'aboutus_instagram_url';
export const ABOUTUS_PINTEREST_FIELD_KEY = 'aboutus_pinterest_url';
export const ABOUTUS_TWITTER_FIELD_KEY = 'aboutus_twitter_url';
export const ABOUTUS_YOUTUVE_FIELD_KEY = 'aboutus_youtuve_url';
export const ABOUTUS_VIDEO_FIELD_KEY = 'aboutus_video_url';
export const ABOUTUS_ICON_COLOR_FIELD_KEY = 'aboutus_icon_color_url';
export const ABOUTUS_MESSAGE_FIELD_KEY = 'aboutus_message_url';

export const PRIMARY_BANNER_TYPE = 'primary';
export const SECONDARY_BANNER_TYPE = 'secondary';
export const SEARCH_BANNER_TYPE = 'search';
export const SEARCH_ICONS_COLOR_FIELD_KEY = 'search_icons_color';

export const TRAVCODING_HOME_TEMPLATE = 'travcoding';
export const RSI_HOME_TEMPLATE = 'rsi';
export const TRAVCODING_V2 = 'travcoding_v2';

export const TOKEN_PROFILE_FIELD_KEY = 'sso_profile';
export const TOKEN_SUB_PROFILE_FIELD_KEY = 'sso_sub_profile';

export const HAS_QUICK = 'quick';

export const CONDORES_SUB_PROFILE = 'Condores';

export class IClientCash {
  selectedSearchClientCash?: number;
  selectedPropertyClientCash?: number;
  selectedPropertyReviewClientCash?: number;
}

export class IAccountPreference {
  id?: number;
  key: string;
  value: string;
}

export class IAccount {
  id?: number;
  baseAddressSite: string;
  username: string;
  description: string;
  name: string;
  email: string;
  type: string;
  siteId: string;
  property: string;
  countryCode: string;
  active: number;
  isTravelAgentPro: boolean;
  hasQuick: boolean;
  isB2C: boolean;
  preferences: IAccountPreference[];

  hasClientCash?: boolean;
  forceMemberWording?: boolean;
  disableNewTabOpen?: boolean;
  clientCashPercentage?: string;
  forceIframe?: boolean;
  walletIsExternal?: boolean;
  walletClientCashName?: string;
  
  isDisableHomes?: boolean;
  disableHomesRedirect?: string;

  buttonColor?: string;

  walletNoDecimals?: boolean;
  walletUseSlider?: boolean;
  walletNoPassEarnings?: boolean;
  walletHasSliderOnSearch?: boolean;
  
  privateTokenFromUrl?: boolean;
  hasPublicAccessToken?: boolean;

  forceDisableVacationRentals?: boolean;
  ssoProfile?: string;
  ssoSubProfile?: string;
  isCapitalVacations?: boolean;
  isCapitalVacationsAgent?: boolean;
  isCapitalVacationsCondoRes?: boolean;
  forceFooterVertical?: boolean;
  breakdownTaxes?: boolean;
  walletHideAmount?: boolean;
  disableImpersonate?: boolean;
  forceImpersonate?: boolean;
  walletDisplayB2CSavings?: boolean;
  walletWalletSavings?: boolean;
  walletDisplayGetMoreClientCash?: boolean;
  disableLanguages?: boolean;
  disableCurrencies?: boolean;
  displayGetawaysHomeHotels?: boolean;
  displayGetawaysHomeCondos?: boolean;
  forceDisableHotelSearchOptions?: boolean;
  isRSITemplate?: boolean;
  homePageTemplate?: string;
  isPrivateTokenAccountType?: boolean;
  isPrivateLoginAccountType?: boolean;
  displayGetawaysHomeHotelsSlider?: boolean;
  displayGetawaysHomeCondosSlider?: boolean;
  enableInsurance?: boolean;
  hideThingsMatterSection?: boolean;
  
  footerBackgroundColorDB?: string;
  footerFontsColorDB?: string;
  logoDB?: string;
  
  contactAssistants?: IAccountContacts;

  isMLM?: boolean;
  
  featuredDestinations?: any[];
}

export class IAccountContacts {
  title: string;
  mainMessage: string;
  subMessage: string;

  contacts: IContactAssitant[];
}

export class IContactAssitant {
  type: string;
  value: string;
  message: string;
}
