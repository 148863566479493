import React from 'react';
import { connect } from 'react-redux';

import { ILoginState } from '@share/store/slices';
import { TRAVCODING_HOME_TEMPLATE } from '@share/common-types';
import { HomeRsi, HomeTravcoding } from '@components';
import { RootState, scrollTop } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState
}

interface IProps extends IMapStateToProps {}

class HomePageComponent extends React.Component<IProps, null> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    
    const homePageTemplate = account?.homePageTemplate;
    const isRSITemplate = account?.isRSITemplate;

    return (
      <div className="home-page">
        {(!homePageTemplate || homePageTemplate === TRAVCODING_HOME_TEMPLATE) && (<HomeTravcoding />)}
        {isRSITemplate && (<HomeRsi />)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const HomePage = connect(mapStateToProps)(HomePageComponent);

