import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { v4 } from 'uuid';
import {
  IAdminBookings,
  BookingStatusEnum,
  IBookingsParamsBase,
  IBookingsParams,
  IAutocompleteResult,
  ISubStatus,
  IExportCsv,
  IFilterOptions,
  IFilterDateRange,
} from '@common-types';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';
import { getSortOrder } from '@utils';
import { UrlUtils } from '@share/utils';
import {
  Urls,
  BOOKING_TABLE_PAGINATION,
  BOOKING_STATUS_LABEL,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_TABLE_SEARCH,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_SUB_STATUSES_LABEL,
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  BOOKING_TABLE_MATCHED_BY 
} from '@share/constants';
import { TAdminHotelBookingsSorter } from '@store/slices';

export interface IAdminBookingsState {
  bookings: IAdminBookings[];
  loading: boolean;
  isSearchLoading: boolean;
  search: string;
  selectedSearchItem: IAutocompleteResult;
  autocompleteResults: IAutocompleteResult[];
  error: string;
  totalOrderCount: number;
  pagination: TablePaginationConfig;
  status: BookingStatusEnum;
  sort: TAdminHotelBookingsSorter;
  checkInDateRangeFilter: IFilterDateRange;
  checkOutDateRangeFilter: IFilterDateRange;
  bookedOnDateRangeFilter: IFilterDateRange;
  statusesFilters: string[];
  supplierFilters: string[];
  subStatusesFilters: string[];
  subStatuses: ISubStatus[];
  exportCsv: IExportCsv;
  exportLoading: boolean;
  exportError: string;
}

const one = 1;
const ten = 10;
const MIN_SEARCH_LENGTH = 3;

const initialState: IAdminBookingsState = {
  bookings: [],
  subStatuses: [],
  loading: true,
  error: '',
  search: '',
  isSearchLoading: false,
  autocompleteResults: [],
  totalOrderCount: null,
  selectedSearchItem: null,
  pagination: {
    current: one,
    pageSize: ten,
  },
  status: null,
  sort: null,
  checkInDateRangeFilter: null,
  checkOutDateRangeFilter: null,
  bookedOnDateRangeFilter: null,
  statusesFilters: [],
  supplierFilters: [],
  subStatusesFilters: [],
  exportCsv: null,
  exportLoading: true,
  exportError: '',
};

const adminBookingsSlice = createSlice({
  name: 'adminBookings',
  initialState,
  reducers: {
    setCheckInDateRangeFilter: (
      state: IAdminBookingsState,
      {
        payload,
      }: PayloadAction<{
        from: string;
        to: string;
      }>,
    ) => {
      state.checkInDateRangeFilter = payload;
    },
    setCheckOutDateRangeFilter: (
      state: IAdminBookingsState,
      {
        payload,
      }: PayloadAction<{
        from: string;
        to: string;
      }>,
    ) => {
      state.checkOutDateRangeFilter = payload;
    },
    setBookedOnDateRangeFilter: (
      state: IAdminBookingsState,
      {
        payload,
      }: PayloadAction<{
        from: string;
        to: string;
      }>,
    ) => {
      state.bookedOnDateRangeFilter = payload;
    },
    setSort: (
      state: IAdminBookingsState,
      { payload }: PayloadAction<TAdminHotelBookingsSorter>,
    ) => {
      state.sort = payload;
    },
    setLoading: (state: IAdminBookingsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setSubStatuses: (state: IAdminBookingsState, { payload }: PayloadAction<ISubStatus[]>) => {
      state.subStatuses = payload;
    },
    setSearch: (state: IAdminBookingsState, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    resetSearch: (state: IAdminBookingsState) => {
      state.search = '';
    },
    setIsSearchLoading: (state: IAdminBookingsState, { payload }: PayloadAction<boolean>) => {
      state.isSearchLoading = payload;
    },
    setAutocompleteResults: (
      state: IAdminBookingsState,
      { payload }: PayloadAction<IAutocompleteResult[]>,
    ) => {
      state.autocompleteResults = payload;
    },
    setSelectedSearchItem: (
      state: IAdminBookingsState,
      { payload }: PayloadAction<IAutocompleteResult>,
    ) => {
      state.selectedSearchItem = payload;
    },
    setError: (state: IAdminBookingsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setBookings: (state: IAdminBookingsState, { payload }: PayloadAction<IAdminBookings[]>) => {
      state.bookings = payload;
    },
    setTotalOrderCount: (state: IAdminBookingsState, { payload }: PayloadAction<number>) => {
      state.totalOrderCount = payload;
    },
    setPagination: (
      state: IAdminBookingsState,
      { payload }: PayloadAction<TablePaginationConfig>,
    ) => {
      state.pagination = payload;
    },
    setStatus: (state: IAdminBookingsState, { payload }: PayloadAction<BookingStatusEnum>) => {
      state.status = payload;

      if (payload) {
        state.statusesFilters = [payload];
      } else {
        state.statusesFilters = null;
      }
    },
    setStatusesFilters: (
      state: IAdminBookingsState,
      { payload }: PayloadAction<BookingStatusEnum[]>,
    ) => {
      state.statusesFilters = payload;

      if (payload) {
        state.status = null;
      }
    },
    setSubStatusesFilters: (state: IAdminBookingsState, { payload }: PayloadAction<string[]>) => {
      state.subStatusesFilters = payload;
    },
    setSupplierTypesFilters: (state: IAdminBookingsState, { payload }: PayloadAction<string[]>) => {
      state.supplierFilters = payload;
    },
    resetFilters: (state: IAdminBookingsState) => {
      state.status = null;
      state.statusesFilters = null;
      state.subStatusesFilters = null;
      state.supplierFilters = null;
      state.checkInDateRangeFilter = null;
      state.checkOutDateRangeFilter = null;
      state.bookedOnDateRangeFilter = null;
    },
    resetSort: (state: IAdminBookingsState) => {
      state.sort = null;
    },
    setExportCsv: (state: IAdminBookingsState, { payload }: PayloadAction<IExportCsv>) => {
      state.exportCsv = payload;
    },
    setExportCsvLoading: (state: IAdminBookingsState, { payload }: PayloadAction<boolean>) => {
      state.exportLoading = payload;
    },
    setExportCsvError: (state: IAdminBookingsState, { payload }: PayloadAction<string>) => {
      state.exportError = payload;
    },
  },
});

export const adminBookingsActions = adminBookingsSlice.actions;

export const adminBookingsReducer = adminBookingsSlice.reducer;

const setParams = <T extends IBookingsParamsBase>(
  params: T,
  adminBookingsStore: IAdminBookingsState,
) => {
  const {
    sort,
    checkInDateRangeFilter,
    checkOutDateRangeFilter,
    bookedOnDateRangeFilter,
    supplierFilters,
    subStatusesFilters,
    statusesFilters,
    search,
    status,
    subStatuses,
    selectedSearchItem,
  } = adminBookingsStore;

  const sortOrder = getSortOrder(sort);

  if (statusesFilters && statusesFilters.length) {
    params.statuses = statusesFilters;
  }

  if (!statusesFilters && status) {
    params.statuses = [status];
  }

  if (supplierFilters && supplierFilters.length) {
    params.supplierTypes = supplierFilters;
  }

  if (subStatusesFilters && subStatusesFilters.length && subStatuses && subStatuses.length) {
    const fullObjects: ISubStatus[] = subStatusesFilters
      .map((value: string) => {
        return subStatuses.find(({ displayValue }) => displayValue === value);
      })
      .filter((obj: ISubStatus) => {
        return !!obj;
      });

    params.subStatuses = fullObjects;
  }

  if (sortOrder) {
    params.bookingOrderBy = sortOrder;
  }

  const filterOptions: IFilterOptions = {};
  params.filterOptions = filterOptions;

  if (checkInDateRangeFilter?.from || checkInDateRangeFilter?.to) {
    filterOptions.checkInDateRange = {
      from: checkInDateRangeFilter.from,
      to: checkInDateRangeFilter.to,
    };
  }

  if (checkOutDateRangeFilter?.from || checkOutDateRangeFilter?.to) {
    filterOptions.checkOutDateRange = {
      from: checkOutDateRangeFilter.from,
      to: checkOutDateRangeFilter.to,
    };
  }

  if (bookedOnDateRangeFilter?.from || bookedOnDateRangeFilter?.to) {
    filterOptions.bookedOnDateRange = {
      from: bookedOnDateRangeFilter.from,
      to: bookedOnDateRangeFilter.to,
    };
  }

  if (search) {
    params.searchQuery = search;
  }

  if (selectedSearchItem) {
    params.searchBy = selectedSearchItem.matchedBy;
  }
};

export const getAdminBookings = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(adminBookingsActions.setLoading(true));

    try {
      const { adminBookingsStore, loginStore } = getState();
      const { user } = loginStore;
      const params: IBookingsParams = {
        pageNumber: adminBookingsStore.pagination.current,
        pageSize: adminBookingsStore.pagination.pageSize,
      };

      setParams(params, adminBookingsStore);

      const { data } = await axiosInstance.post(Urls.AdminBooking, params, {
        ...getHeaders(),
      });

      dispatch(adminBookingsActions.setBookings(data.orders));
      dispatch(adminBookingsActions.setTotalOrderCount(data.totalOrderCount));
      dispatch(adminBookingsActions.setLoading(false));

      const {
        sort,
        checkInDateRangeFilter,
        checkOutDateRangeFilter,
        bookedOnDateRangeFilter,
        supplierFilters,
        subStatusesFilters,
        statusesFilters,
        search,
        selectedSearchItem,
      } = adminBookingsStore;

      if (supplierFilters && supplierFilters.length) {
        UrlUtils.setUrl(BOOKING_SUPPLIER_TYPES_LABEL, supplierFilters);
      } else {
        UrlUtils.removeFromUrl(BOOKING_SUPPLIER_TYPES_LABEL);
      }

      if (subStatusesFilters && subStatusesFilters.length) {
        UrlUtils.setUrl(BOOKING_SUB_STATUSES_LABEL, subStatusesFilters);
      } else {
        UrlUtils.removeFromUrl(BOOKING_SUB_STATUSES_LABEL);
      }

      if (statusesFilters && statusesFilters.length) {
        UrlUtils.setUrl(BOOKING_STATUS_LABEL, statusesFilters);
      } else {
        UrlUtils.removeFromUrl(BOOKING_STATUS_LABEL);
      }

      if (sort) {
        UrlUtils.setUrl(BOOKING_TABLE_SORT_ORDER, sort as Record<string, unknown>);
      } else {
        UrlUtils.removeFromUrl(BOOKING_TABLE_SORT_ORDER);
      }

      if (search) {
        UrlUtils.setUrl(BOOKING_TABLE_SEARCH, search);
      } else {
        UrlUtils.removeFromUrl(BOOKING_TABLE_SEARCH);
      }

      if (selectedSearchItem?.matchedBy) {
        UrlUtils.setUrl(BOOKING_TABLE_MATCHED_BY, selectedSearchItem.matchedBy);
      } else {
        UrlUtils.removeFromUrl(BOOKING_TABLE_MATCHED_BY);
      }

      if (checkInDateRangeFilter?.from && checkInDateRangeFilter?.to) {
        UrlUtils.setUrl(BOOKING_TABLE_DATE_CHECK_IN_RANGE, checkInDateRangeFilter);
      } else {
        UrlUtils.removeFromUrl(BOOKING_TABLE_DATE_CHECK_IN_RANGE);
      }

      if (checkOutDateRangeFilter?.from && checkOutDateRangeFilter?.to) {
        UrlUtils.setUrl(BOOKING_TABLE_DATE_CHECK_OUT_RANGE, checkOutDateRangeFilter);
      } else {
        UrlUtils.removeFromUrl(BOOKING_TABLE_DATE_CHECK_OUT_RANGE);
      }

      if (bookedOnDateRangeFilter?.from && bookedOnDateRangeFilter?.to) {
        UrlUtils.setUrl(BOOKING_TABLE_DATE_BOOKED_ON_RANGE, bookedOnDateRangeFilter);
      } else {
        UrlUtils.removeFromUrl(BOOKING_TABLE_DATE_BOOKED_ON_RANGE);
      }

      UrlUtils.setUrl(BOOKING_TABLE_PAGINATION, {
        current: adminBookingsStore.pagination.current,
        pageSize: adminBookingsStore.pagination.pageSize,
      });
    } catch (error) {
      console.error(error);
      dispatch(adminBookingsActions.setError(error.toString()));
      dispatch(adminBookingsActions.setLoading(false));
    }
  };
};

export const getAdminBookingsSearch = (search: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(adminBookingsActions.setIsSearchLoading(true));

      const { loginStore } = getState();
      const { user } = loginStore;
      const { data } = await axiosInstance.get(Urls.AdminBookingSearch, {
        params: { queryString: search },
        ...getHeaders(),
      });

      if (getState().adminBookingsStore.search.length >= MIN_SEARCH_LENGTH) {
        dispatch(
          adminBookingsActions.setAutocompleteResults(
            data.condoAutocompleteItems.map((item: IAutocompleteResult) => {
              return {
                ...item,
                id: v4(),
              };
            }),
          ),
        );
      }

      dispatch(adminBookingsActions.setIsSearchLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminBookingsActions.setIsSearchLoading(false));
    }
  };
};

export const getSubStatusList = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const { data } = await axiosInstance.get(Urls.CondoBookingsSubStatuses, {
        ...getHeaders(),
      });

      dispatch(adminBookingsActions.setSubStatuses(data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getExportCsv = (fileName: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(adminBookingsActions.setExportCsvLoading(true));

    try {
      const { adminBookingsStore, loginStore } = getState();
      const { user } = loginStore;

      const params: IExportCsv = {
        fileName: fileName,
      };

      setParams(params, adminBookingsStore);

      const res = await axiosInstance.post(Urls.CondoBookingsExportCsv, params, {
        ...getHeaders(),
      });

      dispatch(adminBookingsActions.setExportCsv(res.data));
      dispatch(adminBookingsActions.setExportCsvLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminBookingsActions.setExportCsvError(error.toString()));
      dispatch(adminBookingsActions.setExportCsvLoading(false));
    }
  };
};
