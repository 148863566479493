import { Button, Form, Input, Spin } from "antd";
import React from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

interface UpdatePasswordProps {
  handleSubmit: (values: FormData) => void;
  resetPassLoading: boolean;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = (props) => {
  const { handleSubmit, resetPassLoading } = props;
  const [form] = Form.useForm();

  return (
    <div className="card shadow-sm mb-5 border">
      <div className="card-header border-bottom">
        <h4 className="card-header-title">
          <i className="bi bi-shield-lock mx-2"></i>Update Password
        </h4>
      </div>

      <Spin spinning={resetPassLoading}>
        <Form
          form={form}
          onFinish={handleSubmit}
          className="card-body p-4"
          layout="vertical"
        >
          <Form.Item
            name="currentPassword"
            label="Current password"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Enter current password"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="Enter new password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Enter your new password"
              iconRender={(visible) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                </div>
              )}
            />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            label="Confirm new password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm your new password"
              className="update-password-input"
            />
          </Form.Item>

          <div className="text-end">
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default UpdatePassword;
