import React from "react";
import img1 from "@assets/images/logo/LOGO-2-LIGHT.svg";
import img2 from "@assets/images/element/paypal.svg";
import img3 from "@assets/images/element/visa.svg";
import img4 from "@assets/images/element/mastercard.svg";
import img5 from "@assets/images/element/expresscard.svg";
import {
  contactInfo,
  footerSections,
  socialIcons,
} from "../../../../_mock-api's/index";

const Footer = () => {
  return (
    <>
      <footer className="bg-charcoal pt-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-3">
              <a href="index.php">
                <img className="h-40px" src={img1} alt="logo" />
              </a>
              {contactInfo.map((info, index) => (
                <p key={index} className="mb-2 mt-3">
                  <span className="fw-bold" style={{ color: "#747579" }}>
                    {info.label}
                  </span>{" "}
                  <br />
                  <a
                    href={`tel:${info.phone}`}
                    className="text-body-secondary text-primary-hover"
                    style={{ color: index === 1 ? "white" : "#747579" }}
                  >
                    {info.phone}
                  </a>{" "}
                </p>
              ))}
            </div>

            <div className="col-lg-8 ms-auto">
              <div className="row g-4">
                {footerSections.map((section, index) => (
                  <FooterSection
                    key={index}
                    title={section.title}
                    links={section.links}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="row g-4 justify-content-between mt-3 mt-md-4">
            <div className="col-sm-7 col-md-6 col-lg-4">
              <h5 className="text-white mb-2">Secure Payments:</h5>
              <ul className="list-inline mb-0 mt-3">
                {[img2, img3, img4, img5].map((img, index) => (
                  <li key={index} className="list-inline-item">
                    <a href="#">
                      <img src={img} className="h-30px" alt="" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-sm-5 col-md-6 col-lg-3 text-sm-end">
              <h5 className="text-white mb-2">Connect with us</h5>
              <ul className="list-inline mb-0 mt-3">
                {socialIcons.map((icon, index) => (
                  <li key={index} className="list-inline-item">
                    <a
                      className={`btn btn-sm shadow px-2 bg-${icon.color} mb-0`}
                      href={icon.link}
                    >
                      <i className={`fab fa-fw fa-${icon.icon}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="mt-4 mb-0" />

          <div className="row">
            <div className="container">
              <div className="d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start">
                <div className="text-body-secondary text-primary-hover">
                  {" "}
                  Copyright ©2024 TravBusiness. Built by{" "}
                  <a
                    href="https://www.travcoding.com/"
                    className="text-body-secondary"
                  >
                    &lt;travcoding&gt;
                  </a>{" "}
                </div>
                <div className="nav mt-2 mt-lg-0">
                  <ul className="list-inline text-primary-hover mx-auto mb-0">
                    <li className="list-inline-item me-0">
                      <a className="nav-link text-body-secondary py-1" href="#">
                        Privacy policy
                      </a>
                    </li>
                    <li className="list-inline-item me-0">
                      <a className="nav-link text-body-secondary py-1" href="#">
                        Terms and conditions
                      </a>
                    </li>
                    <li className="list-inline-item me-0">
                      <a
                        className="nav-link text-body-secondary py-1 pe-0"
                        href="#"
                      >
                        Refund policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="back-top">ads</div>
    </>
  );
};

const FooterSection = ({ title, links }) => (
  <div className="col-6 col-md-3">
    <h5 className="text-white mb-2 mb-md-4">{title}</h5>
    <ul className="nav flex-column text-primary-hover">
      {links.map((link, index) => (
        <li key={index} className="nav-item">
          <a className="nav-link text-body-secondary" href="#">
            {link}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Footer;
