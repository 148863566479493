import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { TravelItems } from '@constants';
import { TravelItemLinkIcon } from '@assets';
import './style.scss';

const ZERO = 0;

export class ResourcesTravelItems extends Component {
  render(): React.ReactNode {
    return (
      <div className="travel-items-wrapper">
        <div className="travel-items-wrapper__wrapper">
          <h2 className="travel-items-wrapper__heading">
            <FormattedMessage id="resources.travel.items.heading" />
          </h2>
          <div className="travel-items-wrapper__items">
            {TravelItems.map(({ id, src, alt, heading, text, textBold, link }, i) => (
              <a
                key={id}
                className="travel-items-wrapper__item"
                href={link}
                target="__blank"
                rel="noreferrer"
              >
                <img
                  className={`travel-items-wrapper__image${i === ZERO ? ' position-right' : ''}`}
                  src={src}
                  alt={alt}
                />
                <div className="travel-items-wrapper__text-wrapper">
                  <h3 className="travel-items-wrapper__subheading">
                    <FormattedMessage id={heading} />{' '}
                  </h3>
                  <p className="travel-items-wrapper__paragraph">
                    <FormattedMessage id={text} />
                    {textBold ? (
                      <div className="travel-items-wrapper__paragraph-bold">
                        <FormattedMessage id={textBold} />
                      </div>
                    ) : null}
                  </p>
                </div>

                <i className="travel-items-wrapper__icon">
                  <TravelItemLinkIcon />
                </i>
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
