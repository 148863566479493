import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import './style.scss';

interface IProps {
  title: string;
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  options?: {
    name: React.ReactNode;
    value: number;
  }[];
}

const defaultValue = 1;
const defaultOptions = [
  {
    name: <FormattedMessage id="mile" values={{ count: 1 }} />,
    value: 1,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 2 }} />,
    value: 2,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 5 }} />,
    value: 5,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 10 }} />,
    value: 10,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 25 }} />,
    value: 25,
  },
];

export class FilterDistance extends React.Component<IProps, null> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { title, onChange, value, disabled = false, options = defaultOptions } = this.props;
    const { Option } = Select;

    return (
      <div className="filter" ref={this.wrapperRef}>
        <p className="filter__title">
          <FormattedMessage id={title} />
        </p>
        <Select
          disabled={disabled}
          value={value || defaultValue}
          onChange={onChange}
          getPopupContainer={this.getPopupContainer}
        >
          {options.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
