import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { YellowConfirmationSvg } from '@assets';
import { CONDO_SUPPORT_EMAIL } from '@constants';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

class CondoBookingPendingNotificationComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { menuStore } = this.props;

    return (
      <div className="booking-pending-notification">
        <div className="booking-pending-notification__icon">
          <YellowConfirmationSvg />
        </div>
        <div className="booking-pending-notification__header">
          <FormattedMessage id="booking.is.in.progress" />
        </div>
        <div className="booking-pending-notification__description">
          <div className="booking-pending-notification__text">
            <FormattedMessage id="your.booking.still.being.processed" />
          </div>
          <div className="booking-pending-notification__text">
            <FormattedMessage id="contact.us.if.any.questions" />
          </div>
        </div>
        <div className="booking-pending-notification__wrapper-info">
          <p className="booking-pending-notification__title">
            <FormattedMessage id="phone" />:
          </p>
          <a
            className="booking-pending-notification__description-link"
            href={`tel:${menuStore?.items?.customerServiceNumber}`}
          >
            {menuStore?.items?.customerServiceNumber}
          </a>
        </div>
        <div className="booking-pending-notification__wrapper-info">
          <p className="booking-pending-notification__title pt-0">
            <FormattedMessage id="email" />:
          </p>
          <a
            className="booking-pending-notification__description-link"
            href={`mailto:${CONDO_SUPPORT_EMAIL}`}
          >
            {CONDO_SUPPORT_EMAIL}
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const CondoBookingPendingNotification = connect(
  mapStateToProps,
  null,
)(CondoBookingPendingNotificationComponent);
