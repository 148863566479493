import React from 'react';

import { Carousel } from 'antd';

import { IMedia, IImage } from '@share/common-types';

import './style.scss';

interface IProps {
  images: (IMedia | IImage)[];
}

const speed = 500;
const slidesToShow = 1;
const slidesToScroll = 1;

export class MobileSlider extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const propsCarousel = {
      dots: false,
      infinite: true,
      arrows: true,
      speed,
      slidesToShow,
      slidesToScroll,
    };
    const { images } = this.props;

    return (
      <div className="mobile-slider">
        <Carousel {...propsCarousel} lazyLoad="ondemand">
          {images.map((image: IMedia | IImage, i: number) => {
            return (
              <div className="custom-carousel-image" key={i}>
                <img
                  className="custom-carousel-image__item"
                  src={image.url}
                  alt={image.description}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  }
}
