import React from 'react';
import { Skeleton } from 'antd';

import './style.scss';

export interface IProps {}

export class MemberStorySkeleton extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="member-story-skeleton">
        <Skeleton className="member-story-skeleton__img" active paragraph={false} />
        <div className="member-story-skeleton__text-block">
          <Skeleton className="member-story-skeleton__description" active />
          <Skeleton className="member-story-skeleton__author" active paragraph={false} />
        </div>
      </div>
    );
  }
}
