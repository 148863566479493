import React, { useState } from "react";
import img from "@assets/images/element/signin.svg";
import Cookies from "js-cookie";
import { Spin } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { ILoginState, login } from "@share/store/slices";
interface LOGIN {
  setUserData?: any;
}
interface IMapStateToProps {
  loginStore: ILoginState;
}

const Login: React.FC<LOGIN> = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    dispatch(login(username, password));
  };
  return (
    <section className="vh-xxl-100">
      <div className="container h-100 d-flex px-0 px-sm-4">
        <div className="row justify-content-center align-items-center m-auto">
          <div className="col-12">
            <div className="bg-mode shadow rounded-3 overflow-hidden">
              <div className="row g-0">
                <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                  <div className="p-3 p-lg-5">
                    <img src={img} alt="Login Side" />
                  </div>
                  <div className="vr opacity-1 d-none d-lg-block"></div>
                </div>

                <div className="col-lg-6 order-1">
                  <div className="p-4 p-sm-7">
                    <h1 className="mb-2 h3">Welcome back!</h1>
                    <p className="mb-0">
                      Don't have an account?
                      <a href="register"> Register for free.</a>
                    </p>
                    <p className="mb-0">
                      Your company already registered?
                      <a href="request-access"> Request access.</a>
                    </p>

                    <form className="mt-4 text-start" onSubmit={handleLogin}>
                      <div className="mb-3">
                        <label className="form-label">Company Email</label>
                        <input
                          type="email"
                          className="form-control"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 position-relative">
                        <label className="form-label">Account Password</label>
                        <input
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          id="psw-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="position-absolute top-50 end-0 translate-middle-y p-0 mt-3 toggle-password-login"
                          onClick={togglePasswordVisibility}
                        >
                          <i
                            className={`fas ${
                              showPassword ? "fa-eye" : "fa-eye-slash"
                            } p-2`}
                          ></i>
                        </span>
                      </div>
                      <div className="mb-3 d-sm-flex justify-content-between">
                        <div>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberCheck"
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="rememberCheck"
                          >
                            Remember me?
                          </label>
                        </div>
                        <a href="forgot-password.php">Forgot password?</a>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary w-100 mb-0"
                        disabled={loading}
                      >
                        {loading ? (
                          <div>
                            <Spin size="small" />
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
