import React from 'react';
import { Radio, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { IFilterOption } from '@common-types';
import './style.scss';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';

interface IProps extends WrappedComponentProps {
  title: string;
  titleInformation?: string;
  options: IFilterOption[];
  value: string;
  onChange: (values: string[]) => void;
  disabled?: boolean;
  withShowMore?: boolean;
  displayZero?: boolean;
}

interface IState {
  shownMore: boolean;
}

const zero = 0;
const defaultOptionsCount = 5;

export class FilterRadioComponent extends React.Component<IProps, IState> {
  state: IState = {
    shownMore: false,
  };

  toggleOptions = (): void => {
    this.setState((prevState: IState) => ({
      shownMore: !prevState.shownMore,
    }));
  };

  handleOnChange = (selectedValue: RadioChangeEvent) => {
    this.props.onChange([selectedValue.target.value]);
  }

  render(): React.ReactNode {
    const { shownMore } = this.state;
    const { displayZero, title, titleInformation, options, value, onChange, disabled = false, withShowMore = false } = this.props;
    const showShowMore = withShowMore && options.length > defaultOptionsCount && !disabled;
    const resOptions = showShowMore
      ? shownMore
        ? options
        : options.slice(zero, defaultOptionsCount)
      : options;

    return (
      <div className="filter filter-radio">
        {!isEmpty(title) ? (
          <p className="filter__title">
            <FormattedMessage id={title} />
            {!isEmpty(titleInformation) ? (
              <Tooltip title={this.props.intl.formatMessage({ id: titleInformation })} placement="top">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>) : null}
          </p>) : null}
        <Radio.Group value={value} onChange={this.handleOnChange} disabled={disabled}>
          {resOptions?.map((item: IFilterOption) => {
            if (!item.count && !displayZero) {
              return null;
            }

            return (
              <div key={item.value}>
                <Radio value={item.value} disabled={disabled}>
                  <span className="filter__option-name">{item.label}</span>
                  {item.count > zero ? (
                    <span className="filter__option-quantity">{item.count}</span>
                  ) : null}
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
        {showShowMore ? (
          <div className="filter__show-link" onClick={this.toggleOptions}>
            {shownMore ? <FormattedMessage id="show.less" /> : <FormattedMessage id="show.more" />}
          </div>
        ) : null}
      </div>
    );
  }
}


export const FilterRadio = connect()(injectIntl(FilterRadioComponent));
