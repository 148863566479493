import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Form, Input, Modal, Select, AutoComplete } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import toNumber from 'lodash/toNumber';
import { FormInstance } from 'antd/lib/form';
import { INewPresentationRule } from '@common-types';
import {
  MAX_LENGTH_DESCRIPTION,
  NEGATIVE,
  VALUE_ALL,
  MAX_LENGTH_SITE_ID,
  MIN_LENGTH_SITE_ID,
} from '@constants';
import { getSiteIds, getCurrentSuppliers } from '@utils';
import { checkInLatinLetters } from '@share/utils';
import {
  IAdminPriceRulesState,
  adminChangePriceRuleActions,
  IAdminPresentationRulesState,
  IAdminChangePresentationRuleState,
  changeSelectedPresentationRule,
  setChangePresentationRule,
} from '@store/slices';
import { RootState } from '@share/utils';
import { RedButton, ConfirmModal } from '@components';
import { BlueButton } from '@share/components';
import './style.scss';

interface IMapStateToProps {
  adminPresentationRules: IAdminPresentationRulesState;
  adminPresentationRule: IAdminChangePresentationRuleState;
  adminPriceRules: IAdminPriceRulesState;
}

interface IMapDispatchToProps {
  changeSelectedPresentationRule: (data: INewPresentationRule) => void;
  setChangePresentationRule: (data: INewPresentationRule) => void;
  setErrorText: (text: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isModalConfigPriceRule: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  product: string;
  presentationRuleId: number;
  isHideActivateButton: boolean;
  isDisabled: boolean;
}

interface IState {
  siteIdValue: string;
  isSetValue: boolean;
  isConfirmModal: boolean;
}

const ZERO = 0;
const ONE = 1;

class ConfigPresentationRuleComponent extends React.Component<IProps, IState> {
  state: IState = {
    siteIdValue: '',
    isSetValue: false,
    isConfirmModal: false,
  };

  formConfigRef = React.createRef<FormInstance>();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  updatePriceRule = (value: string | number | bigint | boolean, key: string) => {
    const updateRule = {
      ...this.props.adminPresentationRule.changePresentationRule,
      [key]: value,
    };

    this.props.setChangePresentationRule(updateRule);
  };

  onChangeSupplier = (value: string): void => {
    this.updatePriceRule(value === VALUE_ALL ? '' : value, 'supplier');
  };

  onChangeSiteId = (value: string): void => {
    this.setState({ siteIdValue: value });
    this.updatePriceRule(toNumber(value) ? +value : NEGATIVE, 'siteId');
    this.formConfigRef.current.setFieldsValue({ siteId: toNumber(value) ? +value : NEGATIVE });
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.formConfigRef.current.setFieldsValue({ siteId: e.target.value });

    if (e.target.value !== VALUE_ALL && isNaN(Number(e.target.value))) {
      this.setState({ siteIdValue: '' });
    }
  };

  onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.updatePriceRule(e.target.value, 'name');
  };

  onCompleteChangePriceRule = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { changePresentationRule, loading, error } = this.props.adminPresentationRule;

    this.formConfigRef.current.setFieldsValue({ siteId: changePresentationRule.siteId });
    this.props.changeSelectedPresentationRule(changePresentationRule);
    this.props.onCancel(e);

    if (!loading && error.length !== ZERO) {
      this.props.onCancel(e);
      this.props.setChangePresentationRule(null);
      this.setState({ siteIdValue: '', isSetValue: false });
    }
  };

  onOpenConfirmModal = () => {
    this.setState({ isConfirmModal: true });
  };

  onToggleActivatePriceRule = () => {
    const { changePresentationRule } = this.props.adminPresentationRule;

    this.updatePriceRule(!changePresentationRule.isActive, 'isActive');
    this.props.changeSelectedPresentationRule({
      ...changePresentationRule,
      isActive: !changePresentationRule.isActive,
    });
    this.setState({ isConfirmModal: false });
  };

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  afterClose = () => {
    this.setState({ isSetValue: false });
    this.props.setErrorText('');
  };

  handleCancelConfirm = () => {
    this.setState({ isConfirmModal: false });
  };

  componentDidUpdate() {
    const { isSetValue } = this.state;
    const { isModalConfigPriceRule, adminPresentationRule } = this.props;
    const { changePresentationRule } = adminPresentationRule;

    if (
      changePresentationRule &&
      !isSetValue &&
      isModalConfigPriceRule &&
      this.formConfigRef.current
    ) {
      this.formConfigRef.current.setFieldsValue({ siteId: changePresentationRule.siteId });
      this.setState({
        siteIdValue:
          changePresentationRule?.siteId !== NEGATIVE
            ? changePresentationRule?.siteId === ZERO
              ? ''
              : String(changePresentationRule?.siteId)
            : VALUE_ALL,
        isSetValue: true,
      });
    }
  }

  render(): React.ReactNode {
    const { siteIdValue, isConfirmModal } = this.state;
    const {
      intl,
      adminPresentationRules,
      adminPresentationRule,
      adminPriceRules,
      isModalConfigPriceRule,
      onCancel,
      presentationRuleId,
      isHideActivateButton,
      isDisabled,
    } = this.props;
    const { changePresentationRule, errorText, error } = adminPresentationRule;

    return (
      changePresentationRule && (
        <Modal
          className={`config-presentation-rule ${isConfirmModal ? 'transform-modal' : ''}`}
          wrapClassName="config-presentation-rule__wrapper"
          title={<FormattedMessage id="rule.id" values={{ id: presentationRuleId }} />}
          visible={isModalConfigPriceRule}
          onCancel={onCancel}
          afterClose={this.afterClose}
          footer={false}
        >
          <Form
            className="config-presentation-rule_form"
            name="config-presentation-rule"
            ref={this.formConfigRef}
            initialValues={{
              product: changePresentationRule?.productType,
              name: changePresentationRule?.name,
            }}
          >
            <div className="config-presentation-rule__block-info" ref={this.wrapperRef}>
              <Form.Item
                name="product"
                label={intl.formatMessage({ id: 'product' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.message.product' }),
                    pattern: checkInLatinLetters,
                  },
                ]}
              >
                <div>
                  <Select
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'product' })}
                    value={changePresentationRule?.productType}
                    getPopupContainer={this.getPopupContainer}
                    disabled
                  />
                </div>
              </Form.Item>
              <Form.Item name="supplier" label={intl.formatMessage({ id: 'supplier' })}>
                <div>
                  <Select
                    disabled={isDisabled}
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'supplier' })}
                    value={
                      changePresentationRule?.supplier === ''
                        ? VALUE_ALL
                        : changePresentationRule?.supplier
                    }
                    onChange={this.onChangeSupplier}
                    getPopupContainer={this.getPopupContainer}
                  >
                    {changePresentationRule &&
                      getCurrentSuppliers(
                        changePresentationRule.productType,
                        adminPriceRules.adminSuppliers,
                      ).map((item, index) => {
                        return (
                          <Select.Option key={index + ONE} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item
                name="siteId"
                label={intl.formatMessage({ id: 'site.id' })}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.message.site.id' }),
                  },
                  () => ({
                    validator(_, value) {
                      if (value !== VALUE_ALL && isNaN(Number(value))) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'error.message.correct.site.id',
                            }),
                          ),
                        );
                      }
                      if (
                        (value.length < MIN_LENGTH_SITE_ID || value.length > MAX_LENGTH_SITE_ID) &&
                        value.length !== ZERO
                      ) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'error.message.min.max.site.id',
                            }),
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <div>
                  <AutoComplete
                    disabled={isDisabled}
                    // maxLength={MAX_LENGTH_SITE_ID}
                    placeholder={intl.formatMessage({ id: 'site.id' })}
                    options={getSiteIds(
                      adminPresentationRules.presentationRules,
                      changePresentationRule,
                    )}
                    value={siteIdValue}
                    onChange={this.onChangeSiteId}
                    onSelect={this.onChangeSiteId}
                    onBlur={this.onBlur}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== NEGATIVE
                    }
                    getPopupContainer={this.getPopupContainer}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="name"
                label={intl.formatMessage({ id: 'description' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.message.site.description' }),
                  },
                  {
                    max: MAX_LENGTH_DESCRIPTION,
                    message: intl.formatMessage({ id: 'error.message.alphanumeric.chars' }),
                  },
                ]}
                className="config-presentation-rule__description"
              >
                <Input.TextArea
                  value={changePresentationRule?.name}
                  onChange={this.onChangeDescription}
                />
                <p className="config-presentation-rule__description-length">
                  {changePresentationRule?.name ? changePresentationRule?.name.length : ZERO}/
                  {MAX_LENGTH_DESCRIPTION}
                </p>
              </Form.Item>
            </div>
            {error.length > ZERO && <p className="config-presentation-rule__error">{errorText}</p>}
            <div className="config-presentation-rule__btn-wrapper">
              {!isHideActivateButton ? (
                <div
                  onClick={this.onOpenConfirmModal}
                  className={changePresentationRule?.isActive ? 'btn-deactivate' : 'btn-activate'}
                >
                  <RedButton>
                    <FormattedMessage
                      id={changePresentationRule?.isActive ? 'deactivate' : 'activate'}
                    />
                  </RedButton>
                </div>
              ) : null}
              <BlueButton
                htmlType="submit"
                disabled={
                  !!this.formConfigRef.current
                    ?.getFieldsError()
                    .filter((item) => item.errors.length).length
                }
                onClick={this.onCompleteChangePriceRule}
              >
                <FormattedMessage id="save.changes" />
              </BlueButton>
            </div>
          </Form>
          <ConfirmModal
            isActiveRule={!changePresentationRule?.isActive}
            priseRuleId={presentationRuleId}
            isConfirmModal={isConfirmModal}
            handleCancelConfirm={this.handleCancelConfirm}
            onToggleActivatePriceRule={this.onToggleActivatePriceRule}
            mask={false}
          />
        </Modal>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPresentationRules: state.adminPresentationRulesStore,
    adminPresentationRule: state.adminChangePresentationRulesStore,
    adminPriceRules: state.adminPriceRulesStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setChangePresentationRule: (data: INewPresentationRule) => {
    dispatch(setChangePresentationRule(data));
  },
  changeSelectedPresentationRule: (data: INewPresentationRule) =>
    dispatch(changeSelectedPresentationRule(data)),
  setErrorText: (test: string) => dispatch(adminChangePriceRuleActions.setErrorText(test)),
});

export const ConfigPresentationRules = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ConfigPresentationRuleComponent));

