import React from 'react';

export const SmallCloseCircleSvg: React.FunctionComponent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.37051 4.83792C9.37051 4.77777 9.32129 4.72855 9.26114 4.72855L8.35879 4.73265L6.99981 6.35277L5.64219 4.73401L4.73848 4.72991C4.67832 4.72991 4.6291 4.77777 4.6291 4.83929C4.6291 4.86527 4.63867 4.88987 4.65508 4.91038L6.43379 7.02952L4.65508 9.1473C4.63856 9.16733 4.62939 9.19242 4.6291 9.21839C4.6291 9.27855 4.67832 9.32776 4.73848 9.32776L5.64219 9.32366L6.99981 7.70355L8.35742 9.3223L9.25977 9.3264C9.31993 9.3264 9.36914 9.27855 9.36914 9.21702C9.36914 9.19105 9.35957 9.16644 9.34317 9.14593L7.56719 7.02816L9.3459 4.90902C9.36231 4.88987 9.37051 4.8639 9.37051 4.83792Z"
      fill="black"
      fillOpacity="0.85"
    />
    <path
      d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7 12.0859C4.1918 12.0859 1.91406 9.8082 1.91406 7C1.91406 4.1918 4.1918 1.91406 7 1.91406C9.8082 1.91406 12.0859 4.1918 12.0859 7C12.0859 9.8082 9.8082 12.0859 7 12.0859Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);
