import React from 'react';
import googlePlayImg from '@assets/images/element/google-play.svg';
import appStoreImg from '@assets/images/element/app-store.svg';
import { footerFeatures } from "../../../../_mock-api's/index";

const HomeFooter = () => {
  return (
    <section className="bg-light">
      <div className="container">
        <div className="row g-4">
          {footerFeatures?.map((feature, index) => (
            <div key={index} className="col-md-6 col-xxl-4">
              <div className="bg-body d-flex rounded-3 h-100 p-4">
                <h3>
                  <i className={`fa-solid fa-${feature.icon}`}></i>
                </h3>
                <div className="ms-3">
                  <h5>{feature.title}</h5>
                  <p className="mb-0">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-lg-6 col-xl-5 col-xxl-3 ms-xxl-auto">
            <h5 className="mb-4">Download app</h5>
            <div className="row g-3">
              <div className="col-6 col-sm-4 col-md-3 col-lg-6">
                <a href="#">
                  {' '}
                  <img src={googlePlayImg} alt="Google Play" />{' '}
                </a>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-6">
                <a href="#">
                  <img src={appStoreImg} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { HomeFooter };
