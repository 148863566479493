
import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import './style.scss';

interface IProps {
  text: string;
  title?: string;
  
  disableTitleLocalization?: boolean;
  disableTextLocalization?: boolean;
  textValues?: any;

  visible: boolean;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export class ModalMessage extends React.Component<IProps, null> {

  render(): React.ReactNode {
    const {
      text,
      textValues,
      title,
      visible,
      disableTitleLocalization,
      disableTextLocalization,
      onCancel,
    } = this.props;

    const values = textValues ? { ...textValues } : {};
    return (
      <Modal
        className="modal-message"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-message__wrapper"
      >
        <div>
          {!isEmpty(title) && (
            <h4 className="modal-message__title">
              {disableTitleLocalization ? title : (<FormattedMessage id={title} />)}
            </h4>)}

          <div>{disableTextLocalization ? text : (<FormattedMessage id={text} values={values} />)}</div>
        </div>
      </Modal>
    );
  }
}
