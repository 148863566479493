import React, { useState } from 'react';
import logoDark from '@assets/images/logo/LOGO-4-DARK.svg';
import logoLight from '@assets/images/logo/LOGO-2-LIGHT.svg';
import avatar1 from '@assets/images/avatar/avatar01.png';
import Cookies from 'js-cookie';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginState, logout } from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

const LogInNav = () => {
  const history = useHistory();
  const login = useSelector((state: IMapStateToProps) => state.loginStore);

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const signOut = () => {
    dispatch(logout());
    history.push(`/${login.account.name}/log-in`);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavLinkClick = (path) => {
    history.push(path);
    setDropdownOpen(false);
  };

  return (
    <header className="navbar-light header-sticky">
      <nav className="navbar navbar-expand-xl">
        <div className="container">
          <div className="navbar-brand">
            <img
              className="light-mode-item navbar-brand-item logo-pointer"
              src={logoDark}
              alt="logo"
              onClick={() => history.push(`/${login.account.name}`)}
            />
            <img
              className="dark-mode-item navbar-brand-item logo-pointer"
              src={logoLight}
              alt="logo"
              onClick={() => history.push(`/${login.account.name}`)}
            />
          </div>


          <div className="navbar-collapse collapse" id="navbarCollapse">
            <ul className="navbar-nav navbar-nav-scroll me-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-link-bold dropdown-toggle"
                  href="#"
                  id="listingMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Find a Stay
                </a>
                <ul className="dropdown-menu menu-v2" aria-labelledby="listingMenu">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => history.push(`/${login.account.name}/dashboard`)}
                    >
                      Individual Trip
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => history.push(`/${login.account.name}/dashboard`)}
                    >
                      Group Trip
                    </button>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-link-bold dropdown-toggle"
                  id="pagesMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  My Company
                </a>
                <ul className="dropdown-menu menu-v2" aria-labelledby="pagesMenu">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => history.push(`/${login.account.name}/company-settings`)}
                    >
                      Company Settings
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => history.push(`/${login.account.name}/company-team`)}
                    >
                      Team Members
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => history.push(`/${login.account.name}/account-payment`)}
                    >
                      Billing &amp; Payments
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <ul className="nav flex-row align-items-center list-unstyled ms-xl-auto">
          <button
            className="navbar-toggler ms-auto ms-sm-0 p-0 p-sm-2 me-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-animation">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
            <li className="nav-item dropdown">
              <a
                className="nav-notification btn btn-light p-0 mb-0"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <i className="bi bi-bell fa-fw"></i>
              </a>
              <span className="notif-badge animation-blink"></span>

              <div className="dropdown-menu  dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0 menu-v2">
                <div className="card bg-transparent">
                  <div className="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
                    <h6 className="m-0">
                      Notifications{' '}
                      <span className="badge bg-danger bg-opacity-10 text-danger ms-2">1 new</span>
                    </h6>
                    <a className="small" href="#">
                      Clear all
                    </a>
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-group list-group-flush list-unstyled p-2">
                      <li>
                        <a
                          href="#"
                          className="list-group-item list-group-item-action rounded notif-unread border-0 mb-1 p-3"
                        >
                          <h6 className="mb-2">Your New York stay is 3 days away!</h6>
                          <p className="mb-0 small">
                            Review your itinerary in case you need to make changes!
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="list-group-item list-group-item-action rounded border-0 mb-1 p-3"
                        >
                          <h6 className="mb-2">You booked a stay in New York!</h6>
                          <p className="mb-0 small">
                            Nice choice! Click here to review your stay or make changes.
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-footer bg-transparent text-center border-top">
                    <a href="#" className="btn btn-sm btn-link mb-0 p-0">
                      See all incoming activity
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item ms-3 dropdown">
              <a
                className="avatar avatar-sm p-0"
                onClick={toggleDropdown}
                href="#"
                id="profileDropdown"
                role="button"
                data-bs-auto-close="outside"
                data-bs-display="static"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img className="avatar-img rounded-1" src={avatar1} alt="avatar" />
              </a>

              <ul
                className={`dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3 ${
                  dropdownOpen ? 'show' : ''
                } menu-v2`}
                aria-labelledby="profileDropdown"
              >
                <li className="px-3 mb-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar me-3">
                      <img
                        className="avatar-img rounded-circle shadow"
                        src={avatar1}
                        alt="avatar"
                      />
                    </div>
                    <div>
                      <NavLink to={`/${login?.account?.name}/account-settings`} className="h6 mt-2 mt-sm-0 capitalize">{login?.user?.firstName}</NavLink>
                      <p className="small m-0">Account Owner</p>
                    </div>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button
                    className="dropdown-item capitalize"
                    onClick={() => handleNavLinkClick(`/${login?.account?.name}/company-settings`)}
                  >
                    {login?.account?.name}
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button
                    className="dropdown-item "
                    onClick={() => handleNavLinkClick(`/${login.account.name}/account-settings`)}
                  >
                    <i className="bi bi-gear fa-fw me-2"></i> Account Settings
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => handleNavLinkClick(`/${login.account.name}/register`)}
                  >
                    <i className="bi bi-building fa-fw me-2"></i>Register Company
                  </button>

                  <button
                    className="dropdown-item"
                    onClick={() => handleNavLinkClick(`/${login.account.name}/bookings`)}
                  >
                    <i className="bi bi-suitcase-lg fa-fw me-2"></i>
                    My Bookings
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item "
                    onClick={() => handleNavLinkClick(`/${login.account.name}/account-credit`)}
                  >
                    <i className="bi bi-coin fa-fw me-2"></i>My Trip Credits
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item "
                    onClick={() => handleNavLinkClick(`/${login.account.name}/account-saved`)}
                  >
                    <i className="bi bi-bookmark-check fa-fw me-2"></i>Saved Properties
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button className="dropdown-item bg-danger-soft-hover" onClick={signOut}>
                    <i className="bi bi-power fa-fw me-2"></i>Sign Out
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default LogInNav;
