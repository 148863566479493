import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

import {
  IFilterField,
  IReservation,
  IReservationsFilters,
  IReservationsParams,
  IStatic,
  ITextSearch,
} from '@share/common-types';
import { AppThunk, Toaster, CallbackFunction } from '@share/utils';
import { ReservationsAPI } from '@share/api';
import { BOOKING_ID_MATCHEDBY, LEAD_NAME_MATCHEDBY, PROPERTY_NAME_MATCHEDBY } from '@share/constants';

export interface IReservationsState {
  loading: boolean;
  error: string;
  result: {
    reservations: IReservation[];
    totalOrderCount: number;
    staticData: IStatic;
  },
  pagination: TablePaginationConfig;
  sort: SorterResult<IReservation>;
  filters: IReservationsFilters;
  textSearch: ITextSearch;
  textSearchLabel: string;
}

const one = 1;
const ten = 10;

const InitialFiltersReservations: IReservationsFilters = {
  bookingId: { value: null },
  propertyName: { value: null },
  leadName: { value: null },
  bookingType: { value: null },
}

const initialState: IReservationsState = {
  loading: true,
  error: '',
  result: {
    reservations: [],
    totalOrderCount: null,
    staticData: null
  },
  pagination: {
    current: one,
    pageSize: ten,
  },
  sort: null,
  textSearch: null,
  textSearchLabel: '',
  filters: { ...InitialFiltersReservations }
};

const processFilter = (state: IReservationsState, field: keyof IReservationsFilters, payload: any) => {
  state.filters[field] = payload;
}

const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    setFilters: (state: IReservationsState, { payload }: PayloadAction<IReservationsFilters>) => {
      state.filters = payload;
    },

    setBookingId: (
      state: IReservationsState,
      { payload }: PayloadAction<IFilterField<number>>) => {
      processFilter(state, 'bookingId', payload);
    },
    setPropertyName: (
      state: IReservationsState,
      { payload }: PayloadAction<IFilterField<string>>) => {
      processFilter(state, 'propertyName', payload);
    },
    setBookingType: (
      state: IReservationsState,
      { payload }: PayloadAction<IFilterField<string>>) => {
      processFilter(state, 'bookingType', payload);
    },
    setLeadName: (
      state: IReservationsState,
      { payload }: PayloadAction<IFilterField<string>>) => {
      processFilter(state, 'leadName', payload);
    },
    
    resetFilters: (state: IReservationsState) => {
      state.filters.bookingId = { value: null };
      state.filters.propertyName = { value: null };
      state.filters.leadName = { value: null };
      state.filters.bookingType = { value: null };
    
      state.textSearch = null;
      state.textSearchLabel = '';
    },

    setSort: (
      state: IReservationsState,
      { payload }: PayloadAction<SorterResult<IReservation>>,
    ) => {
      state.sort = payload;
    },
    setLoading: (state: IReservationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setTextSearch: (state: IReservationsState, { payload }: PayloadAction<ITextSearch>) => {
      state.textSearch = payload;
    },
    setTextSearchLabel: (state: IReservationsState, { payload }: PayloadAction<string>) => {
      state.textSearchLabel = payload;
    },
    setError: (state: IReservationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setReservations: (state: IReservationsState, { payload }: PayloadAction<IReservation[]>) => {
      state.result.reservations = payload;
    },
    setStaticData: (state: IReservationsState, { payload }: PayloadAction<IStatic>) => {
      state.result.staticData = payload;
    },
    setTotalOrderCount: (state: IReservationsState, { payload }: PayloadAction<number>) => {
      state.result.totalOrderCount = payload;
    },
    setPagination: (
      state: IReservationsState,
      { payload }: PayloadAction<TablePaginationConfig>) => {
      state.pagination = payload;
    },
  },
});

export const reservationsActions = reservationsSlice.actions;

export const reservationsReducer = reservationsSlice.reducer;

export const getReservations = (bookStatus?: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(reservationsActions.setLoading(true));
    dispatch(reservationsActions.setReservations([]));
    dispatch(reservationsActions.setTotalOrderCount(null));

    try {
      const { reservationsStore } = getState();
      const { pagination } = reservationsStore;
      
      const params: IReservationsParams = getReservationParams(reservationsStore, bookStatus);

      params.page = pagination.current;
      params.pageSize = pagination.pageSize;

      const { data } = await ReservationsAPI.getReservations(params);

      dispatch(reservationsActions.setLoading(false));

      if (!data.status) {
        dispatch(reservationsActions.setReservations(data?.items));
        dispatch(reservationsActions.setTotalOrderCount(data?.totalResults));
      } else {
        dispatch(reservationsActions.setError(`[${data.status}] ${data.title}: ${data.detail}`));
        Toaster.error(`An error occurred. [${data.status}] ${data.title}: ${data.detail}. If the problem persists, please contact the system administrator.`);
      }
    } catch (error) {
      console.error(error);
      dispatch(reservationsActions.setError(error.toString()));
      dispatch(reservationsActions.setLoading(false));
      Toaster.error(`An error occurred: ${error.toString()}. If the problem persists, please contact the system administrator.`);
    }
  };
};

export const getStatic = (id: number): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(reservationsActions.setStaticData(null));
      const { data } = await ReservationsAPI.getStatic(id);

      dispatch(reservationsActions.setStaticData(data));

    } catch (error) {
      console.error(error);
      dispatch(reservationsActions.setError(error.toString()));
      Toaster.error(`An error occurred: ${error.toString()}. If the problem persists, please contact the system administrator.`);
    }
  };
};

export const cancelBooking = (bookingId: string, comment: string, callback: CallbackFunction): AppThunk => {
  return async (dispatch) => {
    dispatch(reservationsActions.setLoading(true));

    try {
      await ReservationsAPI.cancelBookingAsync(bookingId, comment);

      Toaster.success(`Booking cancelled successfully`);

      dispatch(getReservations(null));

      callback();
    } catch (error) {
      console.error(error);
      dispatch(reservationsActions.setError(error.toString()));
      dispatch(reservationsActions.setLoading(false));

      Toaster.error(`An error occurred. If the problem persists, please contact the system administrator.`);
    }
  }
}


export const voucherBooking = (bookingId: string, recepientEmail: string, additionalEmail: string, callback: CallbackFunction): AppThunk => {
  return async (dispatch) => {
    dispatch(reservationsActions.setLoading(true));

    try {
      await ReservationsAPI.voucherBookingAsync(bookingId, recepientEmail, additionalEmail);

      Toaster.success(`Voucher sent successfully`);

      dispatch(getReservations(null));

      callback();
    } catch (error) {
      console.error(error);
      dispatch(reservationsActions.setError(error.toString()));
      dispatch(reservationsActions.setLoading(false));

      Toaster.error(`An error occurred. If the problem persists, please contact the system administrator.`);
    }
  }
}

const getReservationParams = (reservationsStore: IReservationsState, status: string) => {
  const { sort, filters, textSearch } = reservationsStore;
  
  const params: IReservationsParams = {} as IReservationsParams;

  if (filters) {
    if (filters.bookingId.value) {
      params.bookingId = filters.bookingId.value;
    }
    if (filters.propertyName.value) {
      params.propertyName = filters.propertyName.value;
    }
    if (filters.leadName.value) {
      params.leadName = filters.leadName.value;
    }
    if (filters.bookingType.value) {
      params.bookingType = filters.bookingType.value;
    }
  }

  params.status = 'active';
  if (status)
    params.status = status;

  if (textSearch) {
    if (textSearch.matchedBy === BOOKING_ID_MATCHEDBY) {
      params.bookingId = parseInt(textSearch.value); 
    }
    if (textSearch.matchedBy === PROPERTY_NAME_MATCHEDBY) {
      params.propertyName = textSearch.value; 
    }
    if (textSearch.matchedBy === LEAD_NAME_MATCHEDBY) {
      params.leadName = textSearch.value; 
    }
  }

  if (sort) {
    const field = sort.field as string;
    params.orderBy = field;
    params.sortOrder = sort.order === 'descend' ? 'desc' : 'asc';
  }
  return params;
}