import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { CondoDestinationsConsider, BackgroundColor } from '@constants';
import { CompanyLogos, ConciergeTeam, Membership, SkeletonBenefitsItems } from '@components';
import { IBenefitsState, ILoginState, IMenuState } from '@share/store/slices';
import { getLocalURI, RootState } from '@share/utils';
import { HotelsCompanyLogos } from '@common-types';
import { BenefitsEnum } from '@share/common-types';
import { getBenefitInfo, isExternalUrl } from '@share/utils';

import CondominiumsBackground from '@share/assets/images/condo/bg-condominiums.png';
import CondominiumsImage from '@share/assets/images/condo/condominiums.jpg';

import './style.scss';
import { get } from 'lodash';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {}

const ZERO = 0;

class CondominiumsComponent extends React.Component<IProps, null> {
  render() {
    const { menuStore, benefitsStore, loginStore } = this.props;
    const { account } = loginStore;

    return (
      <div className="condominiums">
        <div
          className="condominiums__header-wrapper"
          style={{
            backgroundImage: `url(${CondominiumsBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="condominiums__wrapper">
            <div className="condominiums__header-title">
              <FormattedMessage id="condominiums.header" />
            </div>
          </div>
        </div>
        <div className="condominiums__wrapper">
          <div className="condominiums__hot-deals">
            <p className="condominiums__hot-deals-title">
              <FormattedMessage id="hot.deals.or.more.choices" />
            </p>
            {benefitsStore.benefits?.items ? (
              <div className="condominiums__hot-deals-wrapper">
                {(get(getBenefitInfo(benefitsStore.benefits, BenefitsEnum.Condos), `[${ZERO}].childItems`) as any[])?.map(
                  (item, index) =>
                    isExternalUrl(item.url) ? (
                      <a
                        href={item.url}
                        key={`${index}${item.title}`}
                        className="condominiums__hot-deal"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="condominiums__hot-deal-img-wrapper">
                          <img src={item.imageUrl} alt={item.title} />
                        </div>
                        <p className="condominiums__hot-deal-title"><FormattedMessage id={item.title} /></p>
                        <p className="condominiums__hot-deal-content"><FormattedMessage id={item.description} /></p>
                      </a>

                    ) : (
                      <Link
                        to={`/${account?.name}${getLocalURI(item.url)}`}
                        key={`${index}${item.title}`}
                        className="condominiums__hot-deal"
                      >
                        <div className="condominiums__hot-deal-img-wrapper">
                          <img src={item.imageUrl} alt={item.title} />
                        </div>
                        <p className="condominiums__hot-deal-title"><FormattedMessage id={item.title} /></p>
                        <p className="condominiums__hot-deal-content"><FormattedMessage id={item.description} /></p>
                      </Link>
                    ),
                )}
              </div>
            ) : (
              <SkeletonBenefitsItems />
            )}
          </div>
        </div>
        <div className="condominiums__main-image">
          <img src={CondominiumsImage} alt="Condominiums" />
        </div>
        <div className="condominiums__wrapper">
          <p className="condominiums__condo-destinations-consider-title">
            <FormattedMessage id="first.choice.for.members" />
          </p>
          <p className="condominiums__condo-destinations-consider-title-small">
            <FormattedMessage id="few.great.condo.destinations.to.consider" />
          </p>
          <div className="condominiums__condo-destinations-consider-wrapper">
            {CondoDestinationsConsider.map((item, index) => (
              <p
                key={`${index}${item.content}`}
                className="condominiums__condo-destinations-consider"
              >
                <FormattedMessage id={item.content} values={{ st: (message: string) => (<strong>{message}</strong>) }} />
              </p>
            ))}
          </div>
          <p className="condominiums__condo-destinations-consider-footer-title">
            <FormattedMessage id="home.away.from.home" />
          </p>
          <p className="condominiums__condo-destinations-consider-footer-content">
            <FormattedMessage id="travel.is.changing.for.our.members.everyday" />
          </p>
        </div>
        <ConciergeTeam
          customerServiceNumber={menuStore.items?.customerServiceNumber}
          backgroundColor={BackgroundColor.dark}
          isImageSizeSmaller={false}
        />
        <CompanyLogos isBackgroundColorDark={false} logos={HotelsCompanyLogos} />
        <Membership />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
  };
};

export const Condominiums = connect(mapStateToProps, null)(CondominiumsComponent);
