import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { splitText } from '@share/utils';
import './style.scss';

interface IProps {
  cancellationPoliciesText: string;
  refundabilityText?: string;
  visible: boolean;
  isForCondo?: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export class ModalCancellationPolicies extends React.Component<IProps, null> {
  getCancellationPoliciesSplitText = (): React.ReactNode => {
    const arrayTexts = this.props.cancellationPoliciesText.split(splitText);

    return (
      <ul className="modal-cancellation-policies__description">
        {arrayTexts.map((item, index) => (
          <li className="modal-cancellation-policies__description-row" key={index}>
            {item}
          </li>
        ))}
      </ul>
    );
  };

  render(): React.ReactNode {
    const {
      refundabilityText,
      visible,
      onCancel,
      cancellationPoliciesText,
      isForCondo = false,
    } = this.props;

    return (
      <Modal
        className="modal-cancellation-policies"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-cancellation-policies__wrapper"
      >
        <div>
          <h4 className="modal-cancellation-policies__title">
            <FormattedMessage id="cancellation.policies" />
          </h4>
          {isForCondo ? (
            <div>{cancellationPoliciesText}</div>
          ) : (
            this.getCancellationPoliciesSplitText()
          )}
          {!!refundabilityText && (
            <div className="modal-cancellation-policies__refundability">
              <p className="modal-cancellation-policies__refundability-title">
                <FormattedMessage id="refundability" />:
              </p>
              <p className="modal-cancellation-policies__refundability-description">
                {refundabilityText}
              </p>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
