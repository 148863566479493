import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { FormInstance } from 'antd/lib/form';
import { Form, Modal, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { InfoSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { postReminder, IAdminReminderState } from '@store/slices';
import { mailFormat, RootState } from '@share/utils';
import { IAdminReminder } from '@common-types';
import {
  BookingTabsEnum,
  LastReminderOrderIdKeyEnum,
} from '@common-types';
import './style.scss';

interface IMapStateToProps {
  currentTab: BookingTabsEnum;
  adminReminderStore: IAdminReminderState;
}

interface IMapDispatchToProps {
  postReminder: (reminderData: IAdminReminder) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  visible: boolean;
  onCancel: () => void;
  orderId: number;
}

interface IState {
  isAdditionalEmailAdded: boolean;
  email: string;
  additionalEmail: string;
}

const modalWidth = 400;
const MAX_LENGTH = 100;

class ReminderModalComponent extends React.Component<IProps, IState> {
  formRef = React.createRef<FormInstance>();

  constructor(props: IProps) {
    super(props);

    const { lastReminder } = this.props.adminReminderStore;

    this.state = {
      isAdditionalEmailAdded: false,
      email: lastReminder.email,
      additionalEmail: lastReminder.additionalEmail,
    };
  }

  addEmail = () => {
    this.setState({ isAdditionalEmailAdded: true });
  };

  removeEmail = () => {
    this.setState({
      additionalEmail: '',
      isAdditionalEmailAdded: false,
    });
  };

  validateEmail = (e: { target: HTMLInputElement }) => {
    this.setState({ email: e.target.value });
  };

  validateAdditionalEmail = (e: { target: HTMLInputElement }) => {
    this.setState({ additionalEmail: e.target.value });
  };

  onFocus = (e: { target: HTMLInputElement }) => {
    this.formRef.current.setFieldsValue({ email: e.target.value });
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      email: '',
      additionalEmail: '',
      isAdditionalEmailAdded: false,
    });
  };

  onSendReminder = () => {
    const { orderId, currentTab } = this.props;
    const { email, additionalEmail } = this.state;
    const currentTabOrderKey =
      currentTab === BookingTabsEnum.Condos
        ? LastReminderOrderIdKeyEnum.CondoOrderId
        : LastReminderOrderIdKeyEnum.HotelOrderId;

    const data = {
      [currentTabOrderKey]: orderId,
      email: email,
      additionalEmail: additionalEmail,
    };

    this.formRef.current.setFieldsValue({ email: email });

    Promise.all([this.formRef.current.validateFields()]).then(() => {
      this.props.postReminder(data);
      this.onCancel();
    });
  };

  render(): React.ReactNode {
    const { visible, onCancel, intl, adminReminderStore } = this.props;
    const { lastReminder } = adminReminderStore;

    return (
      <div className="reminder-modal">
        <Modal
          className="reminder-modal-wrapper"
          wrapClassName="reminder-modal-wrapper__wrap"
          visible={visible}
          footer={null}
          onCancel={onCancel}
          closable={true}
          destroyOnClose={true}
          closeIcon={<CloseSvg />}
          width={modalWidth}
          title={<FormattedMessage id="send.reminder" />}
        >
          <Form name="reminder" ref={this.formRef}>
            <div className="reminder-modal-wrapper__body">
              {!isEmpty(lastReminder.lastSentDate) ? (
                <div className="reminder-modal-wrapper__warning">
                  <InfoSvg /> <FormattedMessage id="last.reminder.was.sent.at" />{' '}
                  {lastReminder.lastSentDate}
                </div>
              ) : null}
              <div className="reminder-modal-wrapper__list">
                <div className="reminder-modal-wrapper__left">
                  <FormattedMessage id="recepient.email" />
                </div>
                <div className="reminder-modal-wrapper__right">
                  <div className="reminder-modal-wrapper__inputs">
                    <Form.Item
                      name="email"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(mailFormat),
                          message: intl.formatMessage({ id: 'error.message.email.validation' }),
                        },
                      ]}
                    >
                      <div className="">
                        <Input
                          placeholder={intl.formatMessage({ id: 'email.address' })}
                          value={this.state.email}
                          maxLength={MAX_LENGTH}
                          onChange={this.validateEmail}
                          onFocus={this.onFocus}
                        />
                      </div>
                    </Form.Item>
                    {this.state.isAdditionalEmailAdded ? (
                      <Form.Item
                        name="additional-email"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(mailFormat),
                            message: intl.formatMessage({ id: 'error.message.email.validation' }),
                          },
                        ]}
                      >
                        <div className="">
                          <Input
                            placeholder={intl.formatMessage({ id: 'email.address' })}
                            value={this.state.additionalEmail}
                            maxLength={MAX_LENGTH}
                            onChange={this.validateAdditionalEmail}
                            onFocus={this.onFocus}
                          />
                        </div>
                      </Form.Item>
                    ) : null}
                  </div>
                  {this.state.isAdditionalEmailAdded ? (
                    <div
                      className="reminder-modal-wrapper__remove-email"
                      onClick={this.removeEmail}
                    >
                      <FormattedMessage id="remove" />
                    </div>
                  ) : (
                    <div className="reminder-modal-wrapper__add-email" onClick={this.addEmail}>
                      <FormattedMessage id="add.additional.email" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="reminder-modal-wrapper__footer">
              <WhiteButton onClick={this.onCancel}>
                <FormattedMessage id="cancel" />
              </WhiteButton>
              <BlueButton onClick={this.onSendReminder}>
                <FormattedMessage id="send.email" />
              </BlueButton>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    currentTab: state.adminAllBookingsStore.currentTab,
    adminReminderStore: state.adminReminderStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  postReminder: (reminderData) => {
    dispatch(postReminder(reminderData));
  },
});

export const ReminderModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ReminderModalComponent));

