import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import forEach from 'lodash/forEach';
import compose from 'lodash/fp/compose';
import pull from 'lodash/fp/pull';
import { Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { notification, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from '@share/utils';
import {
  adminHotelBookingsActions,
  adminBookingsActions,
  TAdminHotelBookingsSorter,
  getAdminHotelBookings,
  selectAdminHotelBookingsStatus,
  selectAdminHotelBookingsList,
  selectAdminHotelBookingsLoading,
  selectAdminHotelBookingsPagination,
  selectAdminHotelBookingsSort,
  selectAdminHotelBookingsCheckInDateRangeFilter,
  selectAdminHotelBookingsCheckOutDateRangeFilter,
  selectAdminHotelBookingsBookedOnDateRangeFilter,
  selectAdminHotelBookingsSupplierFilters,
  selectAdminHotelBookingsStatusesFilters,
  selectAdminHotelBookingsTotalCount,
  IAdminHotelBookingsState,
} from '@store/slices';
import {
  IAdminHotelBookings,
  IFilterDateRange,
  HotelBookingStatusEnum,
  BookingStatusEnum,
  IHotelAutocompleteResult,
  MatchedByEnum,
} from '@common-types';
import {
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_PAGINATION,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_TABLE_SEARCH,
} from '@constants';
import {
  BOOKING_STATUS_LABEL,
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  BOOKING_TABLE_MATCHED_BY,
} from '@share/constants';
import { ErrorSmallSvg, RefreshSvg, SuccessSmallSvg } from '@assets';
import { HotelBookingsSearch } from '../../../hotel-bookings-search';
import { TableHeader } from '../table-hader';
import { getAllColumns } from './getAllColumns';
import { BlueButton } from '@share/components';
import { UrlUtils } from '@share/utils';
import { WhiteButton } from '@components';
import { getHotelsStatusByCondos } from '@utils';

import './style.scss';

interface IMapStateToProps {
  status: HotelBookingStatusEnum;
  bookings: IAdminHotelBookings[];
  loading: boolean;
  orderCount: number;
  pagination: TablePaginationConfig;
  sort: TAdminHotelBookingsSorter;
  checkInDateRangeFilter: IFilterDateRange;
  checkOutDateRangeFilter: IFilterDateRange;
  bookedOnDateRangeFilter: IFilterDateRange;
  supplierFilters: string[];
  statusesFilters: string[];
  adminHotelBookingsStore: IAdminHotelBookingsState;
}

interface IMapDispatchToProps {
  getAdminHotelBookings: () => void;
  resetFilters: () => void;
  resetCondoBookingsFilters: () => void;
  setPagination: (pagination: TablePaginationConfig) => void;
  setSort: (sort: SorterResult<IAdminHotelBookings>) => void;
  setCheckInDateRangeFilter: (range: { from: string; to: string }) => void;
  setCheckOutDateRangeFilter: (range: { from: string; to: string }) => void;
  setBookedOnDateRangeFilter: (range: { from: string; to: string }) => void;
  setStatusesFilters: (val: string[]) => void;
  setSupplierFilters: (val: string[]) => void;
  setSearch: (search: string) => void;
  setSelectedSearchItem: (result: IHotelAutocompleteResult) => void;
}

type TFilterMap = { [key: string]: (value: unknown) => void };

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {
  showModalExportCsv: () => void;
}

const zero = 0;
const one = 1;
const three = 3;
const pageSizeOptions = ['10', '20', '50'];
const pullStatus = pull<string>('status');

class HotelBookingsComponent extends React.Component<IProps> {
  setParamsToStore() {
    const values = UrlUtils.getValues();

    const filterMap: TFilterMap = {
      [BOOKING_TABLE_DATE_CHECK_IN_RANGE]: (value: IFilterDateRange) => {
        this.props.setCheckInDateRangeFilter(value);
      },
      [BOOKING_TABLE_DATE_CHECK_OUT_RANGE]: (value: IFilterDateRange) => {
        this.props.setCheckOutDateRangeFilter(value);
      },
      [BOOKING_TABLE_DATE_BOOKED_ON_RANGE]: (value: IFilterDateRange) => {
        this.props.setBookedOnDateRangeFilter(value);
      },

      [BOOKING_SUPPLIER_TYPES_LABEL]: (value: string[]) => {
        this.props.setSupplierFilters(value);
      },
      [BOOKING_TABLE_SORT_ORDER]: (value: SorterResult<IAdminHotelBookings>) => {
        this.props.setSort(value);
      },
      [BOOKING_STATUS_LABEL]: (value: (HotelBookingStatusEnum | BookingStatusEnum)[]) => {
        const statusesFilters = value.map?.(getHotelsStatusByCondos);
        this.props.setStatusesFilters(statusesFilters);
      },
      [BOOKING_TABLE_PAGINATION]: (value: TablePaginationConfig) => {
        this.props.setPagination(value);
      },
      [BOOKING_TABLE_SEARCH]: (value: string) => {
        this.props.setSearch(value);
      },
      [BOOKING_TABLE_MATCHED_BY]: (value: MatchedByEnum) => {
        if (value) {
          this.props.setSelectedSearchItem({ matchedBy: value });
        }
      },
    };

    forEach(values, (value, key) => {
      filterMap[key]?.(value);
    });
  }

  getAllColumnsByStatus = (): ColumnsType<IAdminHotelBookings> => {
    const {
      status,
      sort,
      checkInDateRangeFilter,
      checkOutDateRangeFilter,
      bookedOnDateRangeFilter,
      supplierFilters,
      statusesFilters,
    } = this.props;

    const allColumns = getAllColumns({
      sort,
      checkOutDateRangeFilter,
      checkInDateRangeFilter,
      bookedOnDateRangeFilter,
      supplierFilters,
      statusesFilters,
    });

    const finishedStatuses = [
      HotelBookingStatusEnum.Confirmed,
      HotelBookingStatusEnum.Completed,
      HotelBookingStatusEnum.Cancelled,
    ];

    const defaultFields = [
      'orderId',
      'member.siteId',
      'member.id',
      'member.name',
      'checkIn',
      'checkOut',
      'guest.name',
      'bookedOn',
      'providerOrderId',
      'supplier',
      'supplierConfirmationCode',
      'hotelName',
      'propertyAddress.address',
      'propertyAddress.cityName',
      'propertyAddress.countryName',
      'roomsCount',
      'room.name',
      'room.basis',
      'bookingPrice.publicPrice',
      'bookingPrice.totalSupplierPrice',
      'bookingPrice.markup',
      'bookingPrice.savings',
      'bookingPrice.couponAmount',
      'bookingPrice.totalProfitAmount',
      'bookingPrice.totalNetPrice',
    ];

    if (!status) {
      defaultFields.splice(three, zero, 'status');

      const supplierIndex = defaultFields.findIndex((key) => key === 'supplierType');
      defaultFields.splice(supplierIndex + one, zero, 'supplierId');
    }

    if (status === HotelBookingStatusEnum.Failed) {
      defaultFields.splice(three, zero, 'subStatuses');
    }

    if (status && !UrlUtils.getValues()[BOOKING_STATUS_LABEL]) {
      return this.getFilteredFields(allColumns, pullStatus(defaultFields));
    }

    if (finishedStatuses.includes(status)) {
      const supplierIndex = defaultFields.findIndex((key) => key === 'supplierType');
      defaultFields.splice(supplierIndex + one, zero, 'supplierId');
    }

    return this.getFilteredFields(allColumns, defaultFields);
  };

  getFilteredFields(allColumns: ColumnsType<IAdminHotelBookings>, defaultFields: string[]) {
    return allColumns.filter((col) => defaultFields.includes(col.key as string));
  }

  getTotalTitle = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span>
        {range[zero]}-{range[one]} <FormattedMessage id="of" />{' '}
        <FormattedMessage id="items.count" values={{ count: total }} />
      </span>
    );
  };

  onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<IAdminHotelBookings>,
  ): void => {
    if (filters) {
      this.props.setCheckInDateRangeFilter(null);
      this.props.setCheckOutDateRangeFilter(null);
      this.props.setBookedOnDateRangeFilter(null);
      this.props.setSupplierFilters(null);
      this.props.setStatusesFilters(null);

      if (filters.checkIn && filters.checkIn[zero]) {
        const [from, to] = filters.checkIn[zero].toString().split('~');
        this.props.setCheckInDateRangeFilter({ from, to });
      }

      if (filters.checkOut && filters.checkOut[zero]) {
        const [from, to] = filters.checkOut[zero].toString().split('~');
        this.props.setCheckOutDateRangeFilter({ from, to });
      }

      if (filters.bookedOn && filters.bookedOn[zero]) {
        const [from, to] = filters.bookedOn[zero].toString().split('~');
        this.props.setBookedOnDateRangeFilter({ from, to });
      }

      if (filters.supplier && filters.supplier.length) {
        this.props.setSupplierFilters(filters.supplier as string[]);
      }

      if (filters.status && filters.status.length) {
        this.props.setStatusesFilters(filters.status as string[]);
      }
    }

    this.props.setSort({ ...sorter, column: null });
    this.props.setPagination(pagination);
    this.onRefresh();
  };

  onRefresh = (): void => {
    this.props.getAdminHotelBookings();
  };

  resetAllFilters = (): void => {
    this.props.resetFilters();
    this.props.resetCondoBookingsFilters();
    this.onRefresh();
  };

  getFiltersCount = (): number => {
    const {
      checkInDateRangeFilter,
      checkOutDateRangeFilter,
      bookedOnDateRangeFilter,
      statusesFilters,
      supplierFilters,
    } = this.props;
    let res = 0;

    if (statusesFilters && statusesFilters.length) {
      res += statusesFilters.length;
    }

    if (supplierFilters && supplierFilters.length) {
      res += supplierFilters.length;
    }

    if (checkInDateRangeFilter && checkInDateRangeFilter.to && checkInDateRangeFilter.from) {
      res++;
    }

    if (checkOutDateRangeFilter && checkOutDateRangeFilter.to && checkOutDateRangeFilter.from) {
      res++;
    }

    if (bookedOnDateRangeFilter && bookedOnDateRangeFilter.to && bookedOnDateRangeFilter.from) {
      res++;
    }

    return res;
  };

  componentDidMount(): void {
    this.setParamsToStore();
    this.onRefresh();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { adminHotelBookingsStore, intl } = this.props;
    const { exportCsv, exportLoading, exportError } = adminHotelBookingsStore;

    if (
      prevProps.adminHotelBookingsStore.exportLoading !== exportLoading &&
      exportCsv &&
      !exportLoading
    ) {
      notification['success']({
        message: intl.formatMessage({ id: 'export.is.successful' }),
        icon: <SuccessSmallSvg />,
        className: 'all-bookings__notification success',
      });
    }
    if (
      prevProps.adminHotelBookingsStore.exportError.length !== exportError.length &&
      exportError.length !== zero
    ) {
      notification['error']({
        message: intl.formatMessage({ id: 'export.is.failed' }),
        icon: <ErrorSmallSvg />,
        className: 'all-bookings__notification error',
      });
    }
  }

  render(): React.ReactNode {
    const filtersCount = this.getFiltersCount();
    const { bookings, orderCount, loading, pagination, showModalExportCsv } = this.props;

    return (
      <>
        <div className="all-hotel-bookings__filters-header-wrapper">
          {filtersCount > zero && (
            <div className="all-hotel-bookings__filters-header">
              <div className="all-hotel-bookings__filters-text">
                <FormattedMessage id="filters.count" values={{ count: filtersCount }} />{' '}
                <FormattedMessage id="have.been.applied" />
              </div>
              <WhiteButton onClick={this.resetAllFilters}>
                <FormattedMessage id="reset.all" />
              </WhiteButton>
            </div>
          )}
          <div className="all-hotel-bookings__actions">
            <div className="all-hotel-bookings__search">
              <HotelBookingsSearch />
            </div>
            <div className="all-bookings__btn-wrapper">
              <BlueButton disabled={bookings.length === zero} onClick={showModalExportCsv}>
                <FormattedMessage id="export" />
              </BlueButton>
            </div>
            <div className="all-hotel-bookings__refresh" onClick={this.onRefresh}>
              <RefreshSvg />
            </div>
          </div>
        </div>
        <div className="all-hotel-bookings__table">
          <Table
            locale={{
              emptyText: !loading ? <TableHeader onClick={this.resetAllFilters} /> : null,
            }}
            loading={loading}
            onChange={this.onTableChange}
            rowKey={(row: IAdminHotelBookings) => `${row.orderId}`}
            columns={this.getAllColumnsByStatus()}
            dataSource={bookings}
            scroll={{
              x: this.getAllColumnsByStatus().reduce((res, { width }) => {
                return res + (width as number);
              }, 0),
              y: 'calc(100vh - 420px)',
            }}
            pagination={{
              ...pagination,
              showSizeChanger: true,
              pageSizeOptions,
              total: orderCount,
              showTotal: this.getTotalTitle,
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    status: selectAdminHotelBookingsStatus(state),
    bookings: selectAdminHotelBookingsList(state),
    loading: selectAdminHotelBookingsLoading(state),
    orderCount: selectAdminHotelBookingsTotalCount(state),
    pagination: selectAdminHotelBookingsPagination(state),
    sort: selectAdminHotelBookingsSort(state),
    checkInDateRangeFilter: selectAdminHotelBookingsCheckInDateRangeFilter(state),
    checkOutDateRangeFilter: selectAdminHotelBookingsCheckOutDateRangeFilter(state),
    bookedOnDateRangeFilter: selectAdminHotelBookingsBookedOnDateRangeFilter(state),
    supplierFilters: selectAdminHotelBookingsSupplierFilters(state),
    statusesFilters: selectAdminHotelBookingsStatusesFilters(state),
    adminHotelBookingsStore: state.adminHotelBookingsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getAdminHotelBookings,
  setPagination: adminHotelBookingsActions.setPagination,
  setSort: adminHotelBookingsActions.setSort,
  setCheckInDateRangeFilter: adminHotelBookingsActions.setCheckInDateRangeFilter,
  setCheckOutDateRangeFilter: adminHotelBookingsActions.setCheckOutDateRangeFilter,
  setBookedOnDateRangeFilter: adminHotelBookingsActions.setBookedOnDateRangeFilter,
  setStatusesFilters: adminHotelBookingsActions.setStatusesFilters,
  setSupplierFilters: adminHotelBookingsActions.setSupplierFilters,
  setSearch: adminHotelBookingsActions.setSearch,
  setSelectedSearchItem: adminHotelBookingsActions.setSelectedSearchItem,
  resetFilters: adminHotelBookingsActions.resetFilters,
  resetCondoBookingsFilters: adminBookingsActions.resetFilters,
};

export const HotelBookings = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter,
)(HotelBookingsComponent);
