import {
  IAutocompleteGroupedItem,
  TAutocompleteGroupConfigs,
  ISearchAutocomplete,
} from '../interfaces';

export const mapAutocompleteGroups = (
  autocompleteResults: ISearchAutocomplete[],
  autocompleteGroupConfigs: TAutocompleteGroupConfigs[],
): IAutocompleteGroupedItem[] => {
  return autocompleteGroupConfigs.map((group) => {
    const autocompleteItems = autocompleteResults.filter(
      (result) => result.matchedBy === group.name,
    );

    return {
      ...group,
      autocompleteItems,
    };
  });
};
