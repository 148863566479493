import React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { GoTo } from '@components';

import NoCondoResutls from '@assets/images/no_condos_results.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {}

class CondoResultsEmptyComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const isRSITemplate = account?.isRSITemplate;

    return (
      <div className="condo-results-empty">
        <img className="no-result" src={NoCondoResutls} alt="" />

        <h3><FormattedMessage id="no.result.oops" /></h3>

        <h4><FormattedMessage id="demand.is.quite.high.for.this.area" /></h4>

        <h5><FormattedMessage id={isRSITemplate ? 'no.result.try.change.chat' : 'no.result.try.change'} /></h5>

        <GoTo isCondo={true} />
      </div>
    );
  }
}


const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const CondoResultsEmpty = connect(mapStateToProps)(withRouter(CondoResultsEmptyComponent));
