import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import toNumber from 'lodash/toNumber';
import { Form, Select, Input, Checkbox, Spin, AutoComplete } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormInstance } from 'antd/lib/form';
import { connect } from 'react-redux';
import {
  PRODUCTS,
  DEFAULT_NEW_PRICE_RULE,
  DEFAULT_AMOUNT,
  MAX_LENGTH_DESCRIPTION,
  MarginTypeEnum,
  NEGATIVE,
  CURRENCY_TYPE,
  VALUE_ALL,
  MAX_LENGTH_SITE_ID,
  MIN_LENGTH_SITE_ID,
  DEFAULT_NEW_PRESENTATION_RULE,
  SuppliersEnum,
} from '@constants';
import {
  Routes,
  PERCENT_TYPE
} from '@share/constants';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import {
  AppThunk,
  checkInLatinLetters,
  receiptOfAmount,
} from '@share/utils';
import {
  getSiteIds,
  getCurrentSuppliers,
} from '@utils';
import {
  INewPresentationRule,
  INewPresentationRuleRequest,
  INewPriceRule,
  ISavingNewPriseRule,
} from '@common-types';
import {
  IAdminPriceRulesState,
  setNewPriceRule,
  setNewPresentationRule,
  addNewPriceRule,
  addNewPresentationRule,
  setSavingNewPriseRule,
  setSavingNewPresentationRule,
  setRuleExists,
  setPresentationRuleExists,
  getPriceRules,
  getPresentationRules,
  getAdminSuppliers,
  IAdminPresentationRulesState,
} from '@store/slices';
import { RootState } from '@share/utils';
import { SuccessfullyNewRule } from '../notification';
import { RuleAlreadyExists } from '../notification';
import './style.scss';

interface IMapStateToProps {
  adminPriceRules: IAdminPriceRulesState;
  adminPresentationRules: IAdminPresentationRulesState;
}

interface IMapDispatchToProps {
  getPriceRules: () => AppThunk;
  getPresentationRules: () => AppThunk;
  getAdminSuppliers: () => AppThunk;
  setNewPriceRule: (newPriceRule: INewPriceRule) => void;
  addNewPriceRule: (newPriceRule: INewPriceRule) => void;
  addNewPresentationRule: (newPriceRule: INewPresentationRuleRequest) => void;
  setSavingNewPriseRule: (data: ISavingNewPriseRule | null) => void;
  setSavingNewPresentationRule: (data: ISavingNewPriseRule | null) => void;
  setRuleExists: (value: boolean) => void;
  setPresentationRuleExists: (value: boolean) => void;
  setNewPresentationRule: (newPresentationRule: INewPresentationRule) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    WrappedComponentProps,
    RouteComponentProps {
  isPresentationRule?: boolean;
}

interface IState {
  siteIdValue: string;
  isLoadingData: boolean;
}

enum NewRuleEnum {
  SiteId = 'siteId',
  Supplier = 'supplier',
  Margin = 'margin',
  IsActive = 'isActive',
  Name = 'name',
}

const ZERO = 0;
const RCI = 'Rci';
const PRIORITY = 200;

class NewRuleComponent extends React.Component<IProps, IState> {
  state: IState = {
    siteIdValue: '',
    isLoadingData: false,
  };

  formRef = React.createRef<FormInstance>();

  updateNewRule = (value: string | number | bigint | boolean, key: string) => {
    if (this.props.isPresentationRule) {
      const updateRule = {
        ...this.props.adminPresentationRules.newPresentationRule,
        [key]: value,
      };

      return this.props.setNewPresentationRule(updateRule);
    }
    const updateRule = {
      ...this.props.adminPriceRules.newPriceRule,
      [key]: value,
    };

    this.props.setNewPriceRule(updateRule);
  };

  updateMultipleData = (
    priceRule: INewPriceRule,
    value: { [key: string]: string | number },
  ): INewPriceRule => {
    return {
      ...priceRule,
      ...value,
    };
  };

  updateData = (
    object: INewPriceRule,
    value: { [key: string]: string | number },
  ): INewPriceRule => {
    return {
      ...object,
      ...value,
    };
  };

  onChangeProduct = (value: string): void => {
    const { newPriceRule } = this.props.adminPriceRules;

    this.props.setNewPriceRule(
      this.updateData(newPriceRule, {
        productType: value,
        siteId: '',
        supplier: null,
      }),
    );
  };

  onChangeSupplier = (value: string): void => {
    this.updateNewRule(value, NewRuleEnum.Supplier);
  };

  onChangeSiteId = (value: string): void => {
    this.setState({ siteIdValue: value });
    this.updateNewRule(toNumber(value) ? +value : NEGATIVE, NewRuleEnum.SiteId);
  };

  onChangeViewMarkup = (value: string): void => {
    this.props.setNewPriceRule(
      this.updateMultipleData(this.props.adminPriceRules.newPriceRule, {
        margin: DEFAULT_AMOUNT,
        marginType: value === MarginTypeEnum.Percent ? PERCENT_TYPE : CURRENCY_TYPE,
      }) as INewPriceRule,
    );
    this.formRef.current.setFieldsValue({ amount: DEFAULT_AMOUNT });
  };

  onChangeAmount = (e: { target: HTMLInputElement }): void => {
    const { marginType } = this.props.adminPriceRules.newPriceRule;

    if (Number(e.target.value) === ZERO && marginType === CURRENCY_TYPE) {
      this.updateNewRule(ZERO, NewRuleEnum.Margin);
    } else {
      this.updateNewRule(receiptOfAmount(Number(e.target.value), marginType), NewRuleEnum.Margin);
    }
  };

  onChangeActive = (e: { target: CheckboxChangeEventTarget }): void => {
    this.updateNewRule(e.target.checked, NewRuleEnum.IsActive);
  };

  onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.updateNewRule(e.target.value, NewRuleEnum.Name);
  };

  getSupplierName = (name: string) => {
    if (name === RCI) {
      return SuppliersEnum.Rci;
    }
    return SuppliersEnum.Intervals;
  };

  onCompleteNewRule = () => {
    const { isPresentationRule, adminPriceRules, adminPresentationRules } = this.props;

    if (isPresentationRule) {
      const { siteId, name, supplier, isActive } = adminPresentationRules.newPresentationRule;

      return this.props.addNewPresentationRule({
        siteId: siteId === VALUE_ALL ? NEGATIVE : +siteId,
        description: name,
        supplierPriorities: [{ supplierId: this.getSupplierName(supplier), priority: PRIORITY }],
        activate: isActive,
      });
    } else {
      const { productType, name, siteId, supplier, marginType, margin, isActive } =
        adminPriceRules.newPriceRule;

      this.props.addNewPriceRule({
        productType,
        siteId: siteId === VALUE_ALL ? NEGATIVE : +siteId,
        supplier: supplier === VALUE_ALL ? '' : supplier,
        name,
        marginType,
        margin,
        isActive,
      });
    }
  };

  createAnotherRule = () => {
    if (this.props.isPresentationRule) {
      this.setState({ siteIdValue: '' });
      this.props.setSavingNewPresentationRule(null);
      this.props.setNewPresentationRule({
        siteId: '',
        supplier: null,
        ...DEFAULT_NEW_PRESENTATION_RULE,
      });
    } else {
      const { product } = this.props.adminPriceRules;

      this.setState({ siteIdValue: '' });
      this.props.setSavingNewPriseRule(null);
      this.props.setNewPriceRule({
        productType: product,
        siteId: '',
        supplier: null,
        ...DEFAULT_NEW_PRICE_RULE,
      });
    }
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.formRef.current.setFieldsValue({ siteId: event.target.value });

    if (event.target.value !== VALUE_ALL && isNaN(Number(event.target.value))) {
      this.setState({ siteIdValue: '' });
    }
  };

  onFocusAmount = (e: { target: HTMLInputElement }) => {
    this.formRef.current.setFieldsValue({ amount: e.target.value });
  };

  isDisabledButton = (
    isPresentationRule: boolean,
    newPresentationRule: INewPresentationRule,
    newPriceRule: INewPriceRule,
  ) => {
    const newRule = isPresentationRule ? newPresentationRule : newPriceRule;

    return (
      !!this.formRef.current?.getFieldsError().filter((item) => item.errors.length).length ||
      (newRule && Object.values(newRule).some((v) => v === ''))
    );
  };

  componentDidMount() {
    if (this.props.isPresentationRule) {
      const { product } = this.props.adminPriceRules;

      this.props.getPresentationRules();
      this.props.getAdminSuppliers();
      this.props.setNewPresentationRule({
        productType: product,
        siteId: '',
        supplier: null,
        ...DEFAULT_NEW_PRESENTATION_RULE,
      });
      this.props.setSavingNewPresentationRule(null);
      this.props.setPresentationRuleExists(false);
    } else {
      const { product } = this.props.adminPriceRules;

      this.props.getPriceRules();
      this.props.getAdminSuppliers();
      this.props.setNewPriceRule({
        productType: product,
        siteId: '',
        supplier: null,
        ...DEFAULT_NEW_PRICE_RULE,
      });
      this.props.setSavingNewPriseRule(null);
      this.props.setRuleExists(false);
    }
  }

  componentDidUpdate() {
    const { isPresentationRule, adminPresentationRules, adminPriceRules } = this.props;
    const { newPriceRule, priceRules } = adminPriceRules;
    const { newPresentationRule, presentationRules } = adminPresentationRules;

    if (
      isPresentationRule &&
      newPresentationRule &&
      presentationRules &&
      !this.state.isLoadingData
    ) {
      this.setState({
        siteIdValue:
          newPresentationRule?.siteId !== NEGATIVE
            ? newPresentationRule?.siteId === ZERO
              ? ''
              : String(newPresentationRule?.siteId)
            : VALUE_ALL,
        isLoadingData: !this.state.isLoadingData,
      });
    }

    if (!isPresentationRule && newPriceRule && priceRules && !this.state.isLoadingData) {
      this.setState({
        siteIdValue:
          newPriceRule?.siteId !== NEGATIVE
            ? newPriceRule?.siteId === ZERO
              ? ''
              : String(newPriceRule?.siteId)
            : VALUE_ALL,
        isLoadingData: !this.state.isLoadingData,
      });
    }
  }

  render(): React.ReactNode {
    const { siteIdValue } = this.state;
    const { intl, adminPriceRules, adminPresentationRules, isPresentationRule } = this.props;
    const {
      newPriceRule,
      loadingNewPriceRule,
      savingNewPriseRule,
      ruleExists,
      product,
      priceRules,
      adminSuppliers,
    } = adminPriceRules;
    const {
      loadingNewPresentationRule,
      savingNewPresentationRule,
      presentationRuleExists,
      presentationRules,
      newPresentationRule,
    } = adminPresentationRules;
    const isSavingNewPriseRule = savingNewPriseRule !== null;

    const isPriceRuleForm =
      !isPresentationRule && !isSavingNewPriseRule && !ruleExists && newPriceRule && priceRules;

    const isPresentationRuleForm =
      isPresentationRule &&
      !savingNewPresentationRule &&
      !presentationRuleExists &&
      newPresentationRule &&
      presentationRules;

    return (
      adminSuppliers && (
        <div className="new-rule">
          {isPriceRuleForm || isPresentationRuleForm ? (
            <Form
              className="new-rule_form"
              name="new-price-rules"
              ref={this.formRef}
              initialValues={{
                product,
                viewMarkup: MarginTypeEnum.Currency,
                active: true,
              }}
            >
              <div className="new-rule_block-info">
                <Form.Item
                  name="product"
                  label={intl.formatMessage({ id: 'product' })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'error.message.product' }),
                      pattern: checkInLatinLetters,
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'product' })}
                    value={newPriceRule?.productType}
                    onChange={this.onChangeProduct}
                    disabled={isPresentationRule}
                  >
                    {PRODUCTS.map(({ name }, index) => (
                      <Select.Option key={`${index}-${name}`} value={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="siteId"
                  label={intl.formatMessage({ id: 'site.id' })}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'error.message.site.id' }),
                    },
                    () => ({
                      validator(_, value) {
                        if (value !== VALUE_ALL && isNaN(Number(value))) {
                          return Promise.reject(
                            new Error(
                              intl.formatMessage({
                                id: 'error.message.correct.site.id',
                              }),
                            ),
                          );
                        }
                        if (
                          (value.length < MIN_LENGTH_SITE_ID ||
                            value.length > MAX_LENGTH_SITE_ID) &&
                          value.length !== ZERO
                        ) {
                          return Promise.reject(
                            new Error(
                              intl.formatMessage({
                                id: 'error.message.min.max.site.id',
                              }),
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <div>
                    <AutoComplete
                      maxLength={MAX_LENGTH_SITE_ID}
                      placeholder={intl.formatMessage({ id: 'site.id' })}
                      options={
                        isPresentationRule
                          ? getSiteIds(presentationRules, newPresentationRule, isPresentationRule)
                          : getSiteIds(priceRules, newPriceRule)
                      }
                      value={siteIdValue}
                      onChange={this.onChangeSiteId}
                      onSelect={this.onChangeSiteId}
                      onBlur={this.onBlur}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== NEGATIVE
                      }
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name={intl.formatMessage({ id: 'supplier' })}
                  label={intl.formatMessage({ id: 'supplier' })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'error.message.supplier' }),
                      pattern: checkInLatinLetters,
                    },
                  ]}
                >
                  <div>
                    <Select
                      showSearch={true}
                      placeholder={intl.formatMessage({ id: 'supplier' })}
                      value={
                        isPresentationRule ? newPresentationRule?.supplier : newPriceRule?.supplier
                      }
                      onChange={this.onChangeSupplier}
                    >
                      {!isPresentationRule ? (
                        <Select.Option key="0" value={VALUE_ALL}>
                          {VALUE_ALL}
                        </Select.Option>
                      ) : null}

                      {isPresentationRule
                        ? getCurrentSuppliers(newPresentationRule?.productType, adminSuppliers).map(
                            (item, index) => (
                              <Select.Option key={`${index}-${item}`} value={item}>
                                {item}
                              </Select.Option>
                            ),
                          )
                        : getCurrentSuppliers(newPriceRule?.productType, adminSuppliers).map(
                            (item, index) => (
                              <Select.Option key={`${index}-${item}`} value={item}>
                                {item}
                              </Select.Option>
                            ),
                          )}
                    </Select>
                  </div>
                </Form.Item>
                {!isPresentationRule ? (
                  <Form.Item name="markup" label={intl.formatMessage({ id: 'markup' })}>
                    <Input.Group compact>
                      <Form.Item name="viewMarkup" noStyle>
                        <Select
                          className="select-view-markup"
                          value={newPriceRule?.marginType}
                          onChange={this.onChangeViewMarkup}
                        >
                          {Object.values(MarginTypeEnum).map((value: string) => {
                            return (
                              <Select.Option key={value} value={value}>
                                {value}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="amount"
                        noStyle
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'error.message.site.markup' }),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                Number(value) === ZERO &&
                                getFieldValue('viewMarkup') === MarginTypeEnum.Percent
                              ) {
                                return Promise.reject(
                                  new Error(
                                    intl.formatMessage({
                                      id: 'error.message.site.markup.percentage',
                                    }),
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <div className="new-rule__input-amount">
                          <Input
                            type="number"
                            className="input-amount"
                            placeholder={intl.formatMessage({ id: 'amount' })}
                            onChange={this.onChangeAmount}
                            value={newPriceRule?.margin}
                            onFocus={this.onFocusAmount}
                          />
                        </div>
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                ) : null}
                {!isPresentationRule ? (
                  <Form.Item name="active" valuePropName="checked">
                    <div>
                      <Checkbox checked={newPriceRule?.isActive} onChange={this.onChangeActive}>
                        <FormattedMessage id="activate.when.saving" />
                      </Checkbox>
                    </div>
                  </Form.Item>
                ) : null}
                <Form.Item
                  name="description"
                  label={intl.formatMessage({ id: 'description' })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'error.message.site.description' }),
                    },
                    {
                      max: MAX_LENGTH_DESCRIPTION,
                      message: intl.formatMessage({ id: 'error.message.alphanumeric.chars' }),
                    },
                  ]}
                >
                  <div>
                    <Input.TextArea
                      value={isPresentationRule ? newPresentationRule?.name : newPriceRule?.name}
                      maxLength={MAX_LENGTH_DESCRIPTION}
                      onChange={this.onChangeDescription}
                    />
                    <p className="new-rule__description-length">
                      {isPresentationRule
                        ? newPresentationRule?.name
                          ? newPresentationRule?.name.length
                          : ZERO
                        : newPriceRule?.name
                        ? newPriceRule?.name.length
                        : ZERO}
                      /{MAX_LENGTH_DESCRIPTION}
                    </p>
                  </div>
                </Form.Item>
                {isPresentationRule ? (
                  <Form.Item name="active" valuePropName="checked">
                    <div>
                      <Checkbox
                        checked={newPresentationRule?.isActive}
                        onChange={this.onChangeActive}
                      >
                        <FormattedMessage id="activate.when.saving" />
                      </Checkbox>
                    </div>
                  </Form.Item>
                ) : null}
              </div>
              <div className="new-rule__btn-wrapper">
                <Link to={isPresentationRule ? Routes.PresentationRules : Routes.PriceRules}>
                  <WhiteButton>
                    <FormattedMessage id="cancel" />
                  </WhiteButton>
                </Link>
                <BlueButton
                  htmlType="submit"
                  disabled={this.isDisabledButton(
                    isPresentationRule,
                    newPresentationRule,
                    newPriceRule,
                  )}
                  onClick={this.onCompleteNewRule}
                >
                  <FormattedMessage id="save" />
                </BlueButton>
              </div>
            </Form>
          ) : null}
          {(!isPresentationRule && loadingNewPriceRule) ||
          (isPresentationRule && loadingNewPresentationRule) ? (
            <div className="new-rule__loading">
              <Spin />
              <p className="new-rule__loading-text">
                <FormattedMessage id="saving" />
              </p>
            </div>
          ) : null}
          {(!isPresentationRule && isSavingNewPriseRule) ||
          (isPresentationRule && savingNewPresentationRule) ? (
            <SuccessfullyNewRule
              createAnotherRule={this.createAnotherRule}
              isPresentationRule={isPresentationRule}
            />
          ) : null}
          {(!isPresentationRule && ruleExists) || (isPresentationRule && presentationRuleExists) ? (
            <RuleAlreadyExists isPresentationRule={isPresentationRule} />
          ) : null}
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPriceRules: state.adminPriceRulesStore,
    adminPresentationRules: state.adminPresentationRulesStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setNewPriceRule,
  addNewPriceRule,
  setSavingNewPriseRule,
  setSavingNewPresentationRule,
  setRuleExists,
  getPriceRules,
  getPresentationRules,
  getAdminSuppliers,
  addNewPresentationRule,
  setNewPresentationRule,
  setPresentationRuleExists,
};

export const NewRule = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(NewRuleComponent)));

