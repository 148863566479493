import { push } from 'react-router-redux';
import { get, isEmpty } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Base64 } from 'js-base64';

import ReactGA from 'react-ga4';
import moment from 'moment';

import {
  GetUserByAccountNameKeyId,
  GetUserById,
  GetUserByUsernameAndPassword,
  GetUserByImpersonate,
  EndImpersonate,
  GetUserWalletById,
  SignUpUser,
  GetAccountFeaturedDestinations,
} from '@share/services';
import {
  GOOGLE_ANALITYCS_CODE_FIELD_KEY,
  HAS_PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME,
  HOME_PAGE_TEMPLATE_FIELD_KEY,
  IUser,
  LOGOUT_FIELD_KEY,
  PUBLIC_WITH_FIXED_REDIRECT_FIELD_NAME,
  PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME,
  RSI_HOME_TEMPLATE,
  HAS_QUICK,
  ENABLE_WALLET_FIELD_NAME,
  WALLET_PERCENTAGE_FIELD_KEY,
  WALLET_EXPIRATION_NUMBER_FIELD_KEY,
  WALLET_EXPIRATION_PERIOD_FIELD_KEY,
  IUserWallet,
  DISABLE_NEW_TAB_FIELD_NAME,
  FORCE_MEMBER_FIELD_KEY,
  DISABLE_HOMES_FIELD_NAME,
  DISABLE_HOMES_REDIRECT_FIELD_NAME,
  FORCE_IFRAME_FIELD_NAME,
  TOKEN_FROM_URL_FIELD_KEY,
  WALLET_EXTERNAL_FIELD_KEY,
  HAS_PUBLIC_TOKEN_EXPIRATION_FIELD_NAME,
  WALLET_CLIENT_CASH_NAME_FIELD_KEY,
  WALLET_NO_DECIMALS_FIELD_KEY,
  WALLET_USE_SLIDER_FIELD_KEY,
  WALLET_NO_MORE_EARNINGS_FIELD_KEY,
  BUTTONS_COLOR_FIELD_KEY,
  WALLET_SLIDER_ON_SEARCH_FIELD_KEY,
  TOKEN_PROFILE_FIELD_KEY,
  FORCE_DISABLE_VACATION_RENTALS_FIELD_NAME,
  FORCE_FOOTER_VERTICAL_FIELD_KEY,
  BREAKDOWN_TAXES_FIELD_NAME,
  FEATURED_DESTINATIONS_FIELD_KEY,
  WALLET_EXTERNAL_PROFILE_FIELD_KEY,
  TRAVEL_AGENT_PRO_FORCE_IMPERSONATE_FIELD_NAME,
  TRAVEL_AGENT_PRO_DISABLE_IMPERSONATE_FIELD_NAME,
  DISABLE_LANGUAGES_FIELD_NAME,
  DISABLE_CURRENCIES_FIELD_NAME,
  WALLET_HIDE_AMOUNT_FIELD_KEY,
  WALLET_DISPLAY_B2C_SAVINGS_FIELD_KEY,
  WALLET_DISPLAY_MESSAGE_GET_MORE_CLIENT_CASH_FIELD_KEY,
  IBenefitsItems,
  DISPLAY_GETAWAYS_HOME_HOTELS_SECTION_FIELD_NAME,
  DISPLAY_GETAWAYS_HOME_CONDOS_SECTION_FIELD_NAME,
  DISABLE_HOTELS_SEARCH_OPTIONS_FIELD_NAME,
  TOKEN_SUB_PROFILE_FIELD_KEY,
  CONDORES_SUB_PROFILE,
  CONTACT_ASSISTANTS_FIELD_KEY,
  DISPLAY_GETAWAYS_HOME_HOTELS_SLIDER_FIELD_NAME,
  DISPLAY_GETAWAYS_HOME_CONDOS_SLIDER_FIELD_NAME,
  ENABLE_INSURANCE_FIELD_NAME,
  HIDE_THINGS_MATTER_SECTION_FIELD_NAME,
  FOOTER_FONTS_COLOR_FIELD_KEY,
  FOOTER_BACKGROUND_COLOR_FIELD_KEY,
  LOGO_FIELD_KEY,
  TRAVCODING_V2,
} from '@share/common-types';
import { IAccount } from '@share/common-types';
import {
  ACCESS_TOKEN_EXPIRATION_LABEL,
  ACCESS_TOKEN_LABEL,
  LANGUAGE_LABEL,
  LIFE_STYLE_NAME_PARAM,
  LOGGED_USER_LABEL,
  Routes,
  SESSION_KEY_TOKEN_NAME_PARAM,
  Urls,
  USER_BASE_URL,
  WALLET_LOGGED_USER_LABEL,
} from '@share/constants';
import {
  Toaster,
  getHeaders,
  axiosInstance,
  AppThunk,
  getPreferenceFromAccount,
  IsImpersonating,
  RemoveToken,
  SaveToken,
  IsClientCashEnabled,
  UrlUtils,
  getToken,
  GetHomeParams,
  getPreferenceFileUrlFromAccount,
} from '@share/utils';
import {
  getBenefits,
  getNavigationMenu,
  setMenu,
  setLoading,
  resetHotelsFull,
  getNavigationMenutCancelDetail,
  getCountries,
  getAllCondoDestinations,
  condosActions,
  benefitsActions,
  getUserCards,
} from '@share/store/slices';
import { SELECTED_LANGUAGE_LABEL } from '@share/constants';
import { getAccountLocale, getLocaleWithDefault } from '@i18n';

import { getReservations } from './reservations';
import { hotelsActions } from './hotels-search';
import axios from 'axios';

export enum LoginType {
  Public = 'Public',
  PrivateWithLogin = 'PrivateWithLogin',
  PrivateWithToken = 'PrivateWithToken',
}

export enum ErrorType {
  GeneralLoginError = 'GeneralLoginError',
  InvalidUser = 'InvalidUser',
  InactiveUser = 'InactiveUser',
  UnauthorizedUser = 'UnauthorizedUser',
  InvalidUserAccount = 'InvalidUserAccount',
  InactiveUserAccount = 'InactiveUserAccount',
  InvalidWhiteLabelAccount = 'InvalidWhiteLabelAccount',
  ImpersonatorInvalidMemberId = 'ImpersonatorInvalidMemberId',
}

export interface ILoginError {
  type: ErrorType;
  error: string;
}

export interface ILoginState {
  account: IAccount;
  accountFeaturedDestinations: any[];
  user: IUser;
  userWallet: IUser;
  userWalletData: IUserWallet;
  userLanguage: string;
  accessToken: string;
  sessionToken: string;
  lifeStyle: string;
  redirect: string;
  loading: boolean;
  loadingImpersonate: boolean;
  error: ILoginError;
  isTokenExpired: boolean;
}

const initialState: ILoginState = {
  account: null,
  accountFeaturedDestinations: null,
  user: null,
  userWallet: null,
  userWalletData: null,
  userLanguage: 'en',
  accessToken: null,
  sessionToken: null,
  lifeStyle: null,
  redirect: null,
  loading: false,
  loadingImpersonate: false,
  error: null,
  isTokenExpired: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginLoading: (state: ILoginState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLoginLoadingImpersonate: (state: ILoginState, { payload }: PayloadAction<boolean>) => {
      state.loadingImpersonate = payload;
    },
    setIsTokenExpired: (state: ILoginState, { payload }: PayloadAction<boolean>) => {
      state.isTokenExpired = payload;
    },
    setLoginError: (state: ILoginState, { payload }: PayloadAction<ILoginError>) => {
      state.error = payload;
    },
    setAccountFeaturedDestinations: (state: ILoginState, { payload }: PayloadAction<any[]>) => {
      state.accountFeaturedDestinations = payload;
    },
    setLoginRedirect: (state: ILoginState, { payload }: PayloadAction<string>) => {
      state.redirect = payload;
    },
    setUserLanguage: (state: ILoginState, { payload }: PayloadAction<string>) => {
      state.userLanguage = payload;

      localStorage.setItem(SELECTED_LANGUAGE_LABEL, payload);
    },
    setAccessToken: (state: ILoginState, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    setSessionToken: (state: ILoginState, { payload }: PayloadAction<string>) => {
      state.sessionToken = payload;
    },
    setLifeStyle: (state: ILoginState, { payload }: PayloadAction<string>) => {
      state.lifeStyle = payload;
    },
    setLoginAccount: (state: ILoginState, { payload }: PayloadAction<IAccount>) => {
      if (payload) {
        payload.hasQuick = !!JSON.parse(getPreferenceFromAccount(payload, HAS_QUICK));

        const disableNewTabOpen = JSON.parse(
          getPreferenceFromAccount(payload, DISABLE_NEW_TAB_FIELD_NAME, 'false'),
        );
        const forceMemberWording = JSON.parse(
          getPreferenceFromAccount(payload, FORCE_MEMBER_FIELD_KEY, 'false'),
        );
        const enableClientCash = JSON.parse(
          getPreferenceFromAccount(payload, ENABLE_WALLET_FIELD_NAME, 'false'),
        );
        const clientCashPercentage = getPreferenceFromAccount(payload, WALLET_PERCENTAGE_FIELD_KEY);
        const walletIsExternal = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_EXTERNAL_FIELD_KEY, 'false'),
        );
        const forceIframe = JSON.parse(
          getPreferenceFromAccount(payload, FORCE_IFRAME_FIELD_NAME, 'false'),
        );
        const walletNoDecimals = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_NO_DECIMALS_FIELD_KEY, 'false'),
        );
        const walletUseSlider = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_USE_SLIDER_FIELD_KEY, 'false'),
        );
        const walletNoPassEarnings = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_NO_MORE_EARNINGS_FIELD_KEY, 'false'),
        );
        const privateTokenFromUrl = JSON.parse(
          getPreferenceFromAccount(payload, TOKEN_FROM_URL_FIELD_KEY, 'false'),
        );
        const hasPublicAccessToken = JSON.parse(
          getPreferenceFromAccount(payload, HAS_PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME, 'false'),
        );
        const walletHasSliderOnSearch = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_SLIDER_ON_SEARCH_FIELD_KEY, 'false'),
        );
        const forceFooterVertical = JSON.parse(
          getPreferenceFromAccount(payload, FORCE_FOOTER_VERTICAL_FIELD_KEY, 'false'),
        );
        const breakdownTaxes = JSON.parse(
          getPreferenceFromAccount(payload, BREAKDOWN_TAXES_FIELD_NAME, 'false'),
        );
        const walletHideAmount = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_HIDE_AMOUNT_FIELD_KEY, 'false'),
        );
        const isDisableHomes = JSON.parse(
          getPreferenceFromAccount(payload, DISABLE_HOMES_FIELD_NAME, 'false'),
        );
        const forceDisableVacationRentals = JSON.parse(
          getPreferenceFromAccount(payload, FORCE_DISABLE_VACATION_RENTALS_FIELD_NAME, 'false'),
        );
        const disableImpersonate = JSON.parse(
          getPreferenceFromAccount(
            payload,
            TRAVEL_AGENT_PRO_DISABLE_IMPERSONATE_FIELD_NAME,
            'false',
          ),
        );
        const forceImpersonate = JSON.parse(
          getPreferenceFromAccount(payload, TRAVEL_AGENT_PRO_FORCE_IMPERSONATE_FIELD_NAME, 'false'),
        );
        const walletDisplayB2CSavings = JSON.parse(
          getPreferenceFromAccount(payload, WALLET_DISPLAY_B2C_SAVINGS_FIELD_KEY, 'false'),
        );
        const walletDisplayGetMoreClientCash = JSON.parse(
          getPreferenceFromAccount(
            payload,
            WALLET_DISPLAY_MESSAGE_GET_MORE_CLIENT_CASH_FIELD_KEY,
            'false',
          ),
        );
        const disableLanguages = JSON.parse(
          getPreferenceFromAccount(payload, DISABLE_LANGUAGES_FIELD_NAME, 'false'),
        );
        const disableCurrencies = JSON.parse(
          getPreferenceFromAccount(payload, DISABLE_CURRENCIES_FIELD_NAME, 'false'),
        );
        const displayGetawaysHomeHotels = JSON.parse(
          getPreferenceFromAccount(
            payload,
            DISPLAY_GETAWAYS_HOME_HOTELS_SECTION_FIELD_NAME,
            'false',
          ),
        );
        const displayGetawaysHomeHotelsSlider = JSON.parse(
          getPreferenceFromAccount(
            payload,
            DISPLAY_GETAWAYS_HOME_HOTELS_SLIDER_FIELD_NAME,
            'false',
          ),
        );
        const displayGetawaysHomeCondos = JSON.parse(
          getPreferenceFromAccount(
            payload,
            DISPLAY_GETAWAYS_HOME_CONDOS_SECTION_FIELD_NAME,
            'false',
          ),
        );
        const displayGetawaysHomeCondosSlider = JSON.parse(
          getPreferenceFromAccount(
            payload,
            DISPLAY_GETAWAYS_HOME_CONDOS_SLIDER_FIELD_NAME,
            'false',
          ),
        );
        const forceDisableHotelSearchOptions = JSON.parse(
          getPreferenceFromAccount(payload, DISABLE_HOTELS_SEARCH_OPTIONS_FIELD_NAME, 'false'),
        );
        const enableInsurance = JSON.parse(
          getPreferenceFromAccount(payload, ENABLE_INSURANCE_FIELD_NAME, 'false'),
        );
        const hideThingsMatterSection = JSON.parse(
          getPreferenceFromAccount(payload, HIDE_THINGS_MATTER_SECTION_FIELD_NAME, 'false'),
        );

        const clientCashExpirationPeriodValue = getPreferenceFromAccount(
          payload,
          WALLET_EXPIRATION_NUMBER_FIELD_KEY,
        );
        const clientCashExpirationPeriod = getPreferenceFromAccount(
          payload,
          WALLET_EXPIRATION_PERIOD_FIELD_KEY,
        );
        const disableHomesRedirect = getPreferenceFromAccount(
          payload,
          DISABLE_HOMES_REDIRECT_FIELD_NAME,
        );
        const walletClientCashName = getPreferenceFromAccount(
          payload,
          WALLET_CLIENT_CASH_NAME_FIELD_KEY,
        );
        const buttonColor = getPreferenceFromAccount(payload, BUTTONS_COLOR_FIELD_KEY);
        const walletExternalProfile = getPreferenceFromAccount(
          payload,
          WALLET_EXTERNAL_PROFILE_FIELD_KEY,
        );
        const ssoProfile = getPreferenceFromAccount(payload, TOKEN_PROFILE_FIELD_KEY);
        const homePageTemplate = getPreferenceFromAccount(payload, HOME_PAGE_TEMPLATE_FIELD_KEY);

        const contactAssistantsInt = getPreferenceFromAccount(
          payload,
          CONTACT_ASSISTANTS_FIELD_KEY,
        );
        const contactAssistants = !isEmpty(contactAssistantsInt)
          ? JSON.parse(contactAssistantsInt)
          : null;

        const isRSITemplate = homePageTemplate === RSI_HOME_TEMPLATE;

        const ssoSubProfile = getPreferenceFromAccount(payload, TOKEN_SUB_PROFILE_FIELD_KEY);

        const footerBackgroundColorDB = getPreferenceFromAccount(
          payload,
          FOOTER_BACKGROUND_COLOR_FIELD_KEY,
        );
        const footerFontsColorDB = getPreferenceFromAccount(payload, FOOTER_FONTS_COLOR_FIELD_KEY);
        const logoDB = getPreferenceFileUrlFromAccount(payload, LOGO_FIELD_KEY);

        const featuredDestinations = JSON.parse(
          getPreferenceFromAccount(payload, FEATURED_DESTINATIONS_FIELD_KEY, '[]'),
        );

        const hasClientCash =
          enableClientCash &&
          !isEmpty(clientCashPercentage) &&
          (walletIsExternal ||
            (!isEmpty(clientCashExpirationPeriodValue) && !isEmpty(clientCashExpirationPeriod)));

        const isPrivateTokenAccountType = payload?.type === LoginType.PrivateWithToken;
        const isPrivateLoginAccountType = payload?.type === LoginType.PrivateWithLogin;

        const isCapitalVacationsMain =
          isPrivateTokenAccountType && privateTokenFromUrl && ssoProfile === 'capitalvacations';
        const isCapitalVacationsAgent =
          isPrivateLoginAccountType &&
          hasClientCash &&
          walletIsExternal &&
          walletExternalProfile === 'CapitalVacations';
        const isCapitalVacations = isCapitalVacationsMain || isCapitalVacationsAgent;

        const walletWalletSavings =
          hasClientCash && walletDisplayB2CSavings && payload?.isB2C && walletNoPassEarnings;

        payload.hasClientCash = hasClientCash;
        payload.forceMemberWording = forceMemberWording;
        payload.clientCashPercentage = clientCashPercentage;
        payload.forceIframe = forceIframe;
        payload.disableNewTabOpen = disableNewTabOpen;
        payload.isDisableHomes = isDisableHomes;
        payload.disableHomesRedirect = disableHomesRedirect;
        payload.walletIsExternal = walletIsExternal;
        payload.walletClientCashName = isEmpty(walletClientCashName)
          ? 'Client Cash'
          : walletClientCashName;
        payload.walletNoDecimals = walletNoDecimals;
        payload.walletUseSlider = walletUseSlider;
        payload.walletNoPassEarnings = walletNoPassEarnings;
        payload.privateTokenFromUrl = privateTokenFromUrl;
        payload.buttonColor = buttonColor;
        payload.hasPublicAccessToken = hasPublicAccessToken;
        payload.walletHasSliderOnSearch = walletHasSliderOnSearch;
        payload.ssoProfile = ssoProfile;
        payload.ssoProfile = ssoSubProfile;
        payload.isCapitalVacations = isCapitalVacations;
        payload.isCapitalVacationsAgent = isCapitalVacationsAgent;
        payload.isCapitalVacationsCondoRes = ssoSubProfile === CONDORES_SUB_PROFILE;
        payload.forceDisableVacationRentals = forceDisableVacationRentals;
        payload.forceFooterVertical = forceFooterVertical;
        payload.breakdownTaxes = breakdownTaxes;
        payload.featuredDestinations = featuredDestinations;
        payload.walletHideAmount = walletHideAmount;
        payload.disableImpersonate = disableImpersonate;
        payload.forceImpersonate = forceImpersonate;
        payload.walletDisplayB2CSavings = walletDisplayB2CSavings;
        payload.walletWalletSavings = walletWalletSavings;
        payload.walletDisplayGetMoreClientCash = walletDisplayGetMoreClientCash;
        payload.disableLanguages = disableLanguages;
        payload.disableCurrencies = disableCurrencies;
        payload.displayGetawaysHomeHotels = displayGetawaysHomeHotels;
        payload.displayGetawaysHomeCondos = displayGetawaysHomeCondos;
        payload.forceDisableHotelSearchOptions = forceDisableHotelSearchOptions;
        payload.homePageTemplate = homePageTemplate;
        payload.isRSITemplate = isRSITemplate;
        payload.isPrivateTokenAccountType = isPrivateTokenAccountType;
        payload.isPrivateLoginAccountType = isPrivateLoginAccountType;
        payload.contactAssistants = contactAssistants;
        payload.displayGetawaysHomeHotelsSlider = displayGetawaysHomeHotelsSlider;
        payload.displayGetawaysHomeCondosSlider = displayGetawaysHomeCondosSlider;
        payload.contactAssistants = contactAssistants;
        payload.enableInsurance = enableInsurance;
        payload.hideThingsMatterSection = hideThingsMatterSection;
        payload.footerBackgroundColorDB = footerBackgroundColorDB;
        payload.footerFontsColorDB = footerFontsColorDB;
        payload.logoDB = logoDB;
      }

      state.account = payload;
    },
    forceSetLoginAccount: (state: ILoginState, { payload }: PayloadAction<IAccount>) => {
      state.account = payload;
    },
    setLoginUser: (state: ILoginState, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
    },
    setLoginUserWallet: (state: ILoginState, { payload }: PayloadAction<IUser>) => {
      state.userWallet = payload;
    },
    setLoginUserWalletData: (state: ILoginState, { payload }: PayloadAction<IUserWallet>) => {
      state.userWalletData = payload;
    },
  },
});

export const {
  setLoginAccount,
  setLoginUser,
  setLoginUserWallet,
  setLoginRedirect,
  setLoginLoading,
  setLoginLoadingImpersonate,
  setAccessToken,
  setSessionToken,
  setUserLanguage,
  setLoginError,
  setIsTokenExpired,
  setLoginUserWalletData,
  forceSetLoginAccount,
  setAccountFeaturedDestinations,
} = loginSlice.actions;

export const loginActions = loginSlice.actions;

export const loginReducer = loginSlice.reducer;

export const loginSelector = (state: { loginStore: ILoginState }): ILoginState => {
  return state.loginStore;
};

export const Impersonate = (isLogout: boolean): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoginLoadingImpersonate(true));

      const { loginStore, marginatorStore } = getState();
      const isImpersonating = IsImpersonating();
      let responseUser;
      if (isImpersonating) {
        responseUser = await EndImpersonate();
      } else {
        responseUser = await GetUserByImpersonate(
          loginStore.user.userId,
          marginatorStore.impersonator.memberId,
          marginatorStore.impersonator.storeType,
        );
      }

      const user = responseUser?.data;
      const hasClientCash = loginStore.account?.hasClientCash;

      if (responseUser?.status !== 200 || !user) {
        dispatch(setLoginError({ type: ErrorType.InvalidUser, error: 'Invalid User' }));
        Toaster.error('The combination username/password is incorrect!');
      } else if (user.active === 0) {
        dispatch(setLoginError({ type: ErrorType.InactiveUser, error: 'Inactive User' }));
        Toaster.error('Unauthorized User!');
      } else {
        dispatch(setLoginUser(user));
        SaveToken(responseUser.data.accessToken);

        if (!hasClientCash) {
          localStorage.setItem(
            LOGGED_USER_LABEL,
            Base64.encode(JSON.stringify({ user, date: moment().format('yyyy-MM-DDTHH:mm:ss') })),
          );
        }
      }

      if (isLogout) {
        dispatch(setLoginLoadingImpersonate(false));
        dispatch(push(`/${loginStore.account.name}${Routes.Search}`));
      } else {
        dispatch(setLoginLoadingImpersonate(false));
        dispatch(
          push(
            `/${loginStore.account.name}${Routes.Search}${
              !isEmpty(marginatorStore.impersonator.storeType)
                ? `?${LIFE_STYLE_NAME_PARAM}=${marginatorStore.impersonator.storeType}`
                : ''
            }`,
          ),
        );
      }

      if (hasClientCash) {
        dispatch(getUserWallet(user));
      }

      if (isLogout) {
        dispatch(setLoginLoading(true));
        const responseAccount = await axiosInstance.get(`${Urls.Accounts}/${user.accountId}`, {
          ...getHeaders(),
        });

        const accountService = responseAccount?.data;
        if (responseAccount?.status !== 200 || !accountService) {
          Toaster.error('Invalid Account');
          dispatch(setLoginError({ type: ErrorType.InvalidUserAccount, error: 'Invalid Account' }));
          dispatch(push(Routes.NotFound));
        } else {
          const { account } = loginStore;
          const newpreferences = isImpersonating
            ? accountService.preferences
            : [
                ...accountService.preferences,
                ...account.preferences.filter(
                  (d: any) =>
                    !new Set(accountService.preferences.map((d: any) => d.key)).has(d.key),
                ),
              ];
          const accountUser = {
            ...accountService,
            type: account.type,
            isTravelAgentPro: account.isTravelAgentPro,
            preferences: newpreferences,
          };
          dispatch(setLoginAccount(accountUser));

          if (!checkIframe(dispatch, accountUser, false)) {
            return;
          }
        }
      }

      dispatch(setLoginError(null));
      dispatch(setLoginLoading(false));
      dispatch(setLoginLoadingImpersonate(false));
    } catch (error) {
      let erroMessage = error?.response?.data
        ? get(JSON.parse(error?.response?.data), 'reason', null)
        : null;
      if (isEmpty(erroMessage)) {
        erroMessage = error.toString();
      }
      dispatch(setLoginError({ type: ErrorType.ImpersonatorInvalidMemberId, error: erroMessage }));
      dispatch(setLoginLoading(false));
      dispatch(setLoginLoadingImpersonate(false));
    }
  };
};

export const endImpersonation = (): AppThunk => {
  return async (dispatch, getState) => {
    const { loginStore } = getState();
    const { account, user } = loginStore;

    if (IsClientCashEnabled(account)) {
      localStorage.setItem(
        WALLET_LOGGED_USER_LABEL,
        Base64.encode(JSON.stringify({ user, date: moment().format('yyyy-MM-DDTHH:mm:ss') })),
      );
    } else {
      localStorage.removeItem(LOGGED_USER_LABEL);
    }

    dispatch(setLoginError(null));
    dispatch(setLoginLoading(false));
    dispatch(setIsTokenExpired(false));
    dispatch(setMenu(null));

    dispatch(Impersonate(true));
    RemoveToken();
  };
};

export const getUserWallet = (user: IUser): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoginUserWallet(user));
    localStorage.setItem(
      WALLET_LOGGED_USER_LABEL,
      Base64.encode(JSON.stringify({ user, date: moment().format('yyyy-MM-DDTHH:mm:ss') })),
    );

    const { loginStore, hotelsStore, condosStore } = getState();
    const { account, lifeStyle } = loginStore;
    const { selectedHotelSearchClientCash } = hotelsStore;
    const { selectedCondoSearchClientCash } = condosStore;

    try {
      if (user?.userId) {
        const isPrivateWithTokenAccountType = account?.type === LoginType.PrivateWithToken;
        const privateTokenFromUrl = account?.privateTokenFromUrl;

        const token =
          isPrivateWithTokenAccountType && privateTokenFromUrl
            ? UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM)
            : null;
        const response = await GetUserWalletById(user.userId, token, lifeStyle);

        const walletData = { ...response.data };
        const selectedSearchClientCash = walletData?.balance;

        dispatch(setLoginUserWalletData({ ...walletData, balance: selectedSearchClientCash }));

        if (walletData?.balance < selectedHotelSearchClientCash?.selectedSearchClientCash) {
          dispatch(
            hotelsActions.setSelectedHotelSearchClientCash({
              ...selectedHotelSearchClientCash,
              selectedSearchClientCash,
            }),
          );
        }

        if (walletData?.balance < selectedCondoSearchClientCash?.selectedSearchClientCash) {
          dispatch(
            condosActions.setSelectedCondoSearchClientCash({
              ...selectedCondoSearchClientCash,
              selectedSearchClientCash,
            }),
          );
        }

        if (account?.walletWalletSavings) {
          dispatch(
            hotelsActions.setSelectedHotelSearchClientCash({
              ...selectedHotelSearchClientCash,
              selectedSearchClientCash,
            }),
          );

          dispatch(
            condosActions.setSelectedCondoSearchClientCash({
              ...selectedCondoSearchClientCash,
              selectedSearchClientCash,
            }),
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const getAccountFeaturedDestinations = (accountId: string): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await GetAccountFeaturedDestinations(accountId);
      dispatch(setAccountFeaturedDestinations(response.data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const login = (
  username: string,
  password: string,
  bookingId?: string,
  isAdmin?: boolean,
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { account } = loginStore;

      dispatch(setLoginLoading(true));

      if (!IsClientCashEnabled(account)) {
        RemoveToken();
        dispatch(setIsTokenExpired(false));
      }

      const responseUser = await GetUserByUsernameAndPassword(
        account.id,
        username,
        password,
        bookingId,
      );
      const user = responseUser?.data;
      const isTravCoding_V2 = account?.homePageTemplate === TRAVCODING_V2;

      // TODO add get account if admin
      if (responseUser?.status !== 200 || !user) {
        dispatch(setLoginError({ type: ErrorType.InvalidUser, error: 'Invalid User' }));
        Toaster.error('The combination username/password is incorrect!');
      } else if (user.active === 0) {
        dispatch(setLoginError({ type: ErrorType.InactiveUser, error: 'Inactive User' }));
        Toaster.error('Unauthorized User!');
      } else if (isAdmin && user.admin < 3) {
        dispatch(setLoginError({ type: ErrorType.UnauthorizedUser, error: 'Unauthorized User' }));
        Toaster.error('Unauthorized User!');
      } else if (!isAdmin && user.accountId !== account.id) {
        dispatch(setLoginError({ type: ErrorType.UnauthorizedUser, error: 'Unauthorized User' }));
        Toaster.error('Unauthorized User!');
      } else {
        dispatch(setLoginUser(user));
        if (IsClientCashEnabled(account)) {
          dispatch(getUserWallet(user));
        }
        if (user?.accessToken && isTravCoding_V2) {
          axios.defaults.headers.common.Authorization = `bearer ${user?.accessToken}`;
        }    
        SaveToken(user.accessToken);
        localStorage.setItem(
          LOGGED_USER_LABEL,
          Base64.encode(JSON.stringify({ user, date: moment().format('yyyy-MM-DDTHH:mm:ss') })),
        );

        const { redirect } = loginStore;

        const homeParams = GetHomeParams(account);

        dispatch(
          push(
            isTravCoding_V2
              ? `/${account.name}/dashboard`
              : isAdmin
              ? Routes.AdminPage
              : !isEmpty(redirect) && !redirect.startsWith(`/${account.name}${Routes.Login}`)
              ? redirect
              : `/${account.name}${Routes.Search}${homeParams}`,
          ),
        );
      }

      if (isAdmin) {
        const responseAccount = await axiosInstance.get(`${Urls.Accounts}/${user.accountId}`, {
          ...getHeaders(),
        });

        const accountService = responseAccount?.data;
        if (responseAccount?.status !== 200 || !accountService) {
          Toaster.error('Invalid Account');
          dispatch(setLoginError({ type: ErrorType.InvalidUserAccount, error: 'Invalid Account' }));
          dispatch(push(Routes.NotFound));
        } else {
          dispatch(setLoginAccount(accountService));

          if (!checkIframe(dispatch, accountService, false)) {
            return;
          }

          dispatch(getReservations());
        }
      }

      dispatch(setLoginLoading(false));

      setUserLocale(dispatch, account);
    } catch (error) {
      let erroMessage = null;
      try {
        erroMessage = error?.response?.data
          ? get(JSON.parse(error?.response?.data), 'reason', null)
          : null;
      } catch (e) {
        erroMessage = error?.response?.data ? get(error?.response?.data, 'reason', null) : null;
      }

      if (isEmpty(erroMessage)) {
        erroMessage = error.toString();
      }

      Toaster.error(erroMessage);
      dispatch(setLoginError(erroMessage));
      dispatch(setLoginLoading(false));
    }
  };
};

export const logout = (): AppThunk => {
  return async (dispatch, getState) => {
    const { loginStore } = getState();
    const { account, user } = loginStore;

    RemoveToken();

    const isClientCashEnabled = IsClientCashEnabled(account);
    if (isClientCashEnabled && account?.type === LoginType.Public) {
      dispatch(setLoginUserWallet(null));
      SaveToken(user.accessToken);
      localStorage.removeItem(WALLET_LOGGED_USER_LABEL);
    } else {
      localStorage.removeItem(WALLET_LOGGED_USER_LABEL);
      localStorage.removeItem(LOGGED_USER_LABEL);

      dispatch(setLoginUser(null));
      dispatch(setLoginUserWallet(null));
      dispatch(setLoginError(null));
      dispatch(setLoginLoading(false));
      dispatch(setIsTokenExpired(false));
      dispatch(setMenu(null));
    }
  };
};

export const signUp = (
  username: string,
  firstname: string,
  lastname: string,
  email: string,
  password: string,
  bookingId: string,
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { account } = loginStore;

      dispatch(setLoginLoading(true));

      const responseUser = await SignUpUser(
        account.id,
        username,
        firstname,
        lastname,
        password,
        email,
        bookingId,
      );
      const user = responseUser?.data;

      // TODO add get account if admin
      if (responseUser?.status !== 200 || !user) {
        dispatch(setLoginError({ type: ErrorType.InvalidUser, error: 'Invalid User' }));
        Toaster.error('The combination username/password is incorrect!');
      } else if (user.active === 0) {
        dispatch(setLoginError({ type: ErrorType.InactiveUser, error: 'Inactive User' }));
        Toaster.error('Unauthorized User!');
      } else if (user.accountId !== account.id) {
        dispatch(setLoginError({ type: ErrorType.UnauthorizedUser, error: 'Unauthorized User' }));
        Toaster.error('Unauthorized User!');
      } else {
        dispatch(setLoginUser(user));

        if (IsClientCashEnabled(account)) {
          dispatch(getUserWallet(user));
        }

        SaveToken(user.accessToken);
        localStorage.setItem(
          LOGGED_USER_LABEL,
          Base64.encode(JSON.stringify({ user, date: moment().format('yyyy-MM-DDTHH:mm:ss') })),
        );

        const { redirect } = loginStore;

        const homeParams = GetHomeParams(account);

        dispatch(
          push(
            !isEmpty(redirect) && !redirect.startsWith(`/${account.name}${Routes.SignUp}`)
              ? redirect
              : `/${account.name}${Routes.Search}${homeParams}`,
          ),
        );
      }

      dispatch(setLoginLoading(false));

      setUserLocale(dispatch, account);
    } catch (error) {
      console.error(error);
      let erroMessage = error?.response?.data
        ? get(JSON.parse(error?.response?.data), 'reason', null)
        : null;
      if (isEmpty(erroMessage)) {
        erroMessage = error.toString();
      }

      Toaster.error(erroMessage);
      dispatch(setLoginError(erroMessage));
      dispatch(setLoginLoading(false));
    }
  };
};

const DEFAULT_GETAWAYS = [
  {
    title: 'Condo Getaways',
    imageUrl: 'https://rsistaticdata.s3.amazonaws.com/benefits/getaway-condo.jpg',
    description:
      'Choose from Value or Premium Condo Getaways... Your home away from home awaits! Value Getaways are amazingly low prices for a 7-night stay. Premium Getaways offer higher end stays at great values.',
    url: '/getaway/condos',
    type: 'getaways',
    childItems: [
      {
        title: 'Value Condo Getaways',
        imageUrl: 'https://rsistaticdata.s3.amazonaws.com/benefits/getaway-condo-value.jpg',
        description:
          'Specially curated low cost condo offerings starting as low as $249 for a full week stay!',
        url: '/getaway/condos/valuegetaways',
        type: 'getaways',
      },
      {
        title: 'Premium Condo Getaways',
        imageUrl: 'https://rsistaticdata.s3.amazonaws.com/benefits/getaway-condo-premium.jpg',
        description:
          'More specially curated condo offerings which are just a little nicer with even more savings at a price tag just a little higher than our value selection.',
        url: '/getaway/condos/premierescapes',
        type: 'getaways',
      },
    ],
  },
  {
    title: 'Staycation Getaways',
    imageUrl: 'https://rsistaticdata.s3.amazonaws.com/benefits/getaway-staycation.jpg',
    description:
      'Shorter stays, quick getaways to regional destinations just a few hours away from home by car. Treat yourself!',
    url: '/getaway/staycation',
    type: 'getaways',
  },
];

const DEFAULT_BENEFITS = {
  items: [
    {
      childItems: DEFAULT_GETAWAYS,
      description: 'Amazing deals on a variety of experiences especially curated just for you!',
      imageUrl: 'https://rsistaticdata.s3.amazonaws.com/benefits/getaways.jpg',
      title: 'Getaways',
      type: 'getaways',
      url: '/getaway',
    },
  ] as IBenefitsItems[],
  fromHome: true,
};

export const getLoginUserAccount = (
  accountName: string,
  isWidget: boolean,
  isCondo = false,
  isCancelRoute = false,
  cancelId?: string,
  tempKey?: string,
): AppThunk => {
  return async (dispatch, getState) => {
    let account;
    try {
      dispatch(setLoginLoading(true));
      dispatch(setLoading(true));
      dispatch(setLoginUser(null));
      dispatch(setLoginAccount(null));
      dispatch(setIsTokenExpired(false));

      /* Loading Account */
      const responseUserAccount = await axiosInstance.get(`${Urls.Accounts}/name/${accountName}`, {
        ...getHeaders(isWidget),
      });

      let userToSearch = null;
      let isPublicAccessTokenError = false;

      account = responseUserAccount?.data;

      if (responseUserAccount?.status !== 200 || !account) {
        dispatch(setLoginError({ type: ErrorType.InvalidUserAccount, error: 'Invalid Account' }));
        if (!isWidget) {
          dispatch(setLoading(false));
          dispatch(push(Routes.NotFound));
        }
      } else if (account.active === 0) {
        dispatch(setLoginError({ type: ErrorType.InactiveUserAccount, error: 'Inactive Account' }));
        if (!isWidget) {
          dispatch(setLoading(false));
          dispatch(push(Routes.NotFound));
        }
      } else if (account.name !== accountName) {
        dispatch(
          setLoginError({
            type: ErrorType.InvalidWhiteLabelAccount,
            error: 'Invalid White Label Account',
          }),
        );
        if (!isWidget) {
          dispatch(setLoading(false));
          dispatch(push(Routes.NotFound));
        }
      } else {
        if (!isWidget) {
          const hasPublicAccessToken = account?.hasPublicAccessToken;
          if (
            account.type === LoginType.Public &&
            hasPublicAccessToken === 'true' &&
            !isCancelRoute
          ) {
            const { loginStore } = getState();
            const accessTokenStore = loginStore.accessToken;
            const accessTokenDb = getPreferenceFromAccount(
              account,
              PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME,
            );
            const accessTokenRedirect = getPreferenceFromAccount(
              account,
              PUBLIC_WITH_FIXED_REDIRECT_FIELD_NAME,
            );

            if (isEmpty(accessTokenStore) || accessTokenStore !== accessTokenDb) {
              isPublicAccessTokenError = true;

              if (!isEmpty(accessTokenRedirect)) {
                window.location.replace(accessTokenRedirect);
              }

              dispatch(setAccessToken(null));
              localStorage.removeItem(ACCESS_TOKEN_LABEL);
              localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_LABEL);
            } else {
              const hasEccessTokenExpiration = JSON.parse(
                getPreferenceFromAccount(account, HAS_PUBLIC_TOKEN_EXPIRATION_FIELD_NAME, 'false'),
              );
              if (hasEccessTokenExpiration) {
                const expiration = localStorage.getItem(ACCESS_TOKEN_EXPIRATION_LABEL);
                if (isEmpty(expiration)) {
                  localStorage.setItem(
                    ACCESS_TOKEN_EXPIRATION_LABEL,
                    moment().add(1, 'd').format('yyyy-MM-DD hh:mm:ss'),
                  );
                } else {
                  const expirationDate = moment(expiration, 'yyyy-MM-DD hh:mm:ss');
                  if (moment().isAfter(expirationDate)) {
                    isPublicAccessTokenError = true;

                    if (!isEmpty(accessTokenRedirect)) {
                      window.location.replace(accessTokenRedirect);
                    }

                    dispatch(setAccessToken(null));
                    localStorage.removeItem(ACCESS_TOKEN_LABEL);
                    localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_LABEL);
                  }
                }
              }
            }
          }
        }

        dispatch(setLoginAccount(account));

        if (!checkIframe(dispatch, account, isCancelRoute)) {
          return;
        }

        if (
          account.type === LoginType.Public ||
          account.type === LoginType.PrivateWithToken ||
          isWidget
        ) {
          userToSearch = account.id;
        }
      }

      let userStorage;
      let userStorageWallet;
      let date;
      try {
        const loggedUser = localStorage.getItem(LOGGED_USER_LABEL);
        const userDataStorage = loggedUser ? JSON.parse(Base64.decode(loggedUser)) : null;
        userStorage = userDataStorage?.user;
        date = userDataStorage?.date;
      } catch (error) {}

      try {
        const loggedUser = localStorage.getItem(WALLET_LOGGED_USER_LABEL);
        const userDataStorage = loggedUser ? JSON.parse(Base64.decode(loggedUser)) : null;
        userStorageWallet = userDataStorage?.user;
      } catch (error) {}

      if (account.type === LoginType.PrivateWithLogin) {
        if (!userStorage) {
          dispatch(setLoginLoading(false));
          dispatch(setLoading(false));

          if (!isWidget) {
            return;
          }
        } else {
          userToSearch = userStorage.userId;
        }
      }

      if (!userToSearch) {
        dispatch(setLoginError({ type: ErrorType.InvalidUser, error: 'Invalid User' }));

        if (account.type !== LoginType.PrivateWithToken) {
          dispatch(setLoginAccount(null));
        }

        if (!isWidget) {
          dispatch(setLoginLoading(false));
          dispatch(setLoading(false));
          dispatch(push(Routes.NotFound));
        }
      }

      const isDisableHomes = JSON.parse(
        getPreferenceFromAccount(account, DISABLE_HOMES_FIELD_NAME, 'false'),
      );
      const disableHomesRedirect = getPreferenceFromAccount(
        account,
        DISABLE_HOMES_REDIRECT_FIELD_NAME,
      );
      if (isDisableHomes && isEmpty(location.search) && !isWidget) {
        if (!isEmpty(disableHomesRedirect)) {
          window.location.replace(disableHomesRedirect);
        } else {
          dispatch(push(Routes.NotAuthorized));
        }
        return;
      }

      /* Loading User */
      let responseUser = null;
      if (account.type === LoginType.PrivateWithToken && !isCancelRoute) {
        const data = getToken(account);
        if (!!data) {
          if (account?.privateTokenFromUrl) {
            dispatch(setSessionToken(data));
          }
          responseUser = await GetUserByAccountNameKeyId(account.name, data, isWidget);
        } else {
          const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
          const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
          const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : logoutPref;
          if (!isEmpty(logoutUrl)) {
            window.location.replace(logoutUrl);
          } else {
            dispatch(setLoginError({ type: ErrorType.InvalidUser, error: 'Invalid User' }));

            if (!isWidget) {
              dispatch(setLoginLoading(false));
              dispatch(setLoading(false));
              dispatch(push(Routes.CustomError));
            }
          }
          return;
        }
      } else {
        responseUser = await GetUserById(userToSearch, isWidget);
      }

      const user = responseUser?.data || userStorage;
      let menuCallFired = false;
      if (responseUser?.status !== 200 || !user) {
        dispatch(setLoginError({ type: ErrorType.InvalidUser, error: 'Invalid User' }));
        if (account.type !== LoginType.PrivateWithLogin) {
          dispatch(setLoginAccount(null));

          if (!isWidget) {
            dispatch(setLoginLoading(false));
            dispatch(setLoading(false));
            dispatch(push(Routes.NotFound));
          }
        }
      } else if (user.active === 0) {
        dispatch(setLoginError({ type: ErrorType.InactiveUser, error: 'Inactive User' }));
        if (account.type !== LoginType.PrivateWithLogin) {
          dispatch(setLoginAccount(null));

          if (!isWidget) {
            dispatch(setLoginLoading(false));
            dispatch(setLoading(false));
            dispatch(push(Routes.NotFound));
          }
        }
      } else {
        const accountId = user.accountId;
        if (accountId !== account.id) {
          dispatch(
            setLoginError({
              type: ErrorType.InvalidWhiteLabelAccount,
              error: 'Invalid White Label Account',
            }),
          );
          if (account.type !== LoginType.PrivateWithLogin) {
            dispatch(setLoginAccount(null));

            if (!isWidget) {
              dispatch(setLoginLoading(false));
              dispatch(setLoading(false));
              dispatch(push(Routes.NotFound));
            }
          }
        } else {
          dispatch(setLoginUser(user));

          if (!isWidget) {
            if (!isCancelRoute) {
              dispatch(getNavigationMenu());
              menuCallFired = true;

              const homePageTemplate = getPreferenceFromAccount(
                account,
                HOME_PAGE_TEMPLATE_FIELD_KEY,
              );
              if (homePageTemplate === RSI_HOME_TEMPLATE) {
                dispatch(getBenefits(user.keyid));
              } else {
                dispatch(benefitsActions.setBenefits(DEFAULT_BENEFITS));
              }
            } else {
              if (!isEmpty(tempKey)) {
                dispatch(getNavigationMenutCancelDetail(tempKey));
                menuCallFired = true;
              }
            }
          }

          const travcodingAnalyticsId = process.env.REACT_APP_ANALYTICS_ID;
          const analyticsAccounts = [{ trackingId: travcodingAnalyticsId }];

          const preference = getPreferenceFromAccount(account, GOOGLE_ANALITYCS_CODE_FIELD_KEY);
          if (preference) {
            analyticsAccounts.push({ trackingId: preference });
          }

          if (!isWidget) {
            if (account.type === LoginType.PrivateWithToken) {
              if (account?.hasClientCash) {
                dispatch(getUserWallet(user));
              }
            } else {
              try {
                if (userStorage && date && userStorage.active != 0 && accountId === account.id) {
                  const dateStorageMomment = moment(date, 'yyyy-MM-DDTHH:mm:ss').add(3, 'd');
                  const dateExpireMomment = moment();
                  if (dateStorageMomment.isAfter(dateExpireMomment)) {
                    if (IsClientCashEnabled(account)) {
                      dispatch(getUserWallet(userStorage));
                    } else {
                      dispatch(setLoginUser(userStorage));
                    }

                    SaveToken(userStorage.accessToken);
                  } else {
                    localStorage.removeItem(LOGGED_USER_LABEL);
                  }
                }
              } catch (error) {
                console.error(error);
              }

              try {
                if (
                  userStorageWallet &&
                  userStorageWallet.active != 0 &&
                  IsClientCashEnabled(account)
                ) {
                  dispatch(getUserWallet(userStorageWallet));

                  SaveToken(userStorageWallet.accessToken);
                }
              } catch (error) {
                console.error(error);
              }
            }

            if (
              account.homePageTemplate !== TRAVCODING_V2 &&
              (account.type === LoginType.PrivateWithToken ||
                account.type === LoginType.PrivateWithLogin)
            ) {
              dispatch(getReservations());
            }
          }

          ReactGA.initialize(analyticsAccounts);
          ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
          });
        }

        if (isPublicAccessTokenError && !isWidget) {
          dispatch(resetHotelsFull());

          if (isDisableHomes) {
            if (!isEmpty(disableHomesRedirect)) {
              window.location.replace(disableHomesRedirect);
            } else {
              dispatch(push(Routes.NotAuthorized));
            }
          } else {
            const homeParams = GetHomeParams(account);

            if (isCondo) {
              dispatch(push(`/${account.name}${Routes.CondoSearch}${homeParams}`));
            } else {
              dispatch(push(`/${account.name}${Routes.Search}${homeParams}`));
            }
          }
        }
      }

      dispatch(getAccountFeaturedDestinations(account?.id));

      dispatch(setLoginLoading(false));
      if (!menuCallFired) {
        dispatch(setLoading(false));
      }

      setUserLocale(dispatch, account);

      dispatch(getAllCondoDestinations());
      dispatch(getCountries());
      dispatch(getUserCards());
    } catch (error) {
      handleError(error, account, isWidget, dispatch);
    }
  };
};

const handleError = (error: any, account: any, isWidget: boolean, dispatch: any) => {
  console.error(error);
  dispatch(setLoginUser(null));

  if (account?.type !== LoginType.PrivateWithToken) {
    dispatch(setLoginAccount(null));
  }
  dispatch(setLoginLoading(false));
  dispatch(setLoading(false));

  const code = error?.response?.data?.code?.toString();
  const reason = error?.response?.data?.reason?.toString();

  if (!isWidget) {
    const errors = ['Access not valid', 'SessionKey not valid'];
    dispatch(
      push({
        pathname: Routes.CustomError,
        state: {
          data: errors.includes(reason) ? 'INVALIDSESSION' : code,
        },
      }),
    );
  }
};

const setUserLocale = (dispatch: any, account: any) => {
  const values = UrlUtils.getValues();
  const lang = values[LANGUAGE_LABEL] as string;
  const locale = getLocaleWithDefault(lang);
  if (locale) {
    dispatch(setUserLanguage(lang));
  } else {
    dispatch(setUserLanguage(getAccountLocale(account)));
  }
};

const checkIframe = (dispatch: any, account: any, isCancelRoute: boolean) => {
  if (!isCancelRoute) {
    const forceIframe = JSON.parse(
      getPreferenceFromAccount(account, FORCE_IFRAME_FIELD_NAME, 'false'),
    );
    const inIframe =
      window.location !== window.parent.location ||
      window.self !== window.top ||
      !!window.frameElement;
    if (forceIframe && !inIframe) {
      dispatch(push(Routes.NotAuthorized));
      dispatch(setLoginLoading(false));
      return false;
    }
  }
  return true;
};
