import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Responsive } from '@share/utils';
import { IAmenities, IHotelDetails } from '@common-types';
import { ActivityComponent } from '@components';

import './style.scss';
import { chunk } from 'lodash';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

class ActivitiesComponent extends React.Component<IProps, null> {

  getSplitedList = (activitiesList: IAmenities[]) => {
    return chunk(activitiesList, Math.floor(activitiesList.length / 3) + 1);
  }

  render(): React.ReactNode {
    const { hotel, refAnchor } = this.props;

    const activities: IAmenities[] = [];
    hotel.recreationalActivities.map((activity, i) => {
      activities.push(
        {
          amenityId: i,
          categoryName: '',
          name: activity.replace(/([A-Z])/g, ' $1').trim()
        }
      )
    })

    const fullActivitiesList: IAmenities[] = [...activities].map(a => ({ ...a, categoryName: '' }));
    const splitedList = this.getSplitedList(fullActivitiesList);

    return (activities.length > 1 &&
      <>
        <div className="activities pb-0" ref={refAnchor}>
          <div className="rooms-search__list-text vacation mb-0">
            <div className="rooms-search__header" style={{ fontSize: '24px' }}><FormattedMessage id="tab.activity" /></div>
          </div>
        </div>
        <div className="activities pt-0">

          <div className="activities__activities-list desktop">
            {
              splitedList.map((activities, index) => (
                <div key={index} className="activities__items">
                  <Responsive>
                    <ActivityComponent activities={activities} />
                  </Responsive>
                </div>))
            }
          </div>

          <div className="activities__activities-list mobile">
            <Responsive>
              <ActivityComponent activities={fullActivitiesList} />
            </Responsive>
          </div>
        </div>
      </>
    );
  }
}

export const Activities = ActivitiesComponent;
