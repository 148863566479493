import React, { useState, useEffect } from "react";
import image from "@assets/images/heros/public-homepage2.jpg";
import image1 from "@assets/images/home/chicago.jpg";
import image2 from "@assets/images/home/sanfrancisco.jpg";

const Hero = () => {
  const [savings, setSavings] = useState("3,030,257.33");

  useEffect(() => {
    const fetchSavings = async () => {
      const response = await fetch("/api/savings");
      const data = await response.json();
      setSavings(data.initialSavingsFormatted);
    };

    fetchSavings();
  }, []);

  return (
    <section className="pt-3 pt-lg-5 custom-hero">
      <div className="container">
        <div className="row g-4 g-lg-5 checkout-page-row">
          <div className="col-lg-5 position-relative">
            <img src={image} className="rounded" alt="heroSection" />
            <div className="position-absolute top-0 end-0 z-index-1 mt-n4">
              <div
                className="bg-blur border border-light rounded-3 text-center shadow-lg p-3"
                style={{ backgroundColor: " rgba(255, 255, 255, 0.8)" }}
              >
                <i className="bi bi-headset text-success fs-3"></i>
                <h5 className="text-dark mb-1">24/7</h5>
                <h6 className="text-dark fw-light small mb-0">Support Team!</h6>
              </div>
            </div>
            <div className="vstack gap-5 align-items-center position-absolute top-0 start-0 d-none d-md-flex mt-5 ms-n3">
              <img
                className="icon-xl shadow-lg border border-3 border-white rounded-circle"
                src={image1}
                alt="avatar"
              />
              <img
                className="icon-xxl shadow-lg border border-3 border-white rounded-circle"
                src={image2}
                alt="avatar"
              />
            </div>
          </div>
          <div className="col-lg-7 position-relative mb-4 mb-md-0">
            <h1 className="mb-4 mt-md-5 display-5">
              Cut your business travel cost
              <span
                className="position-relative z-index-9 text-success"
                style={{ fontWeight: 600 }}
              >
                {" "}
                by 60%
              </span>
            </h1>
            <p className="mb-4 lead">
              Sign up for free now and enjoy special rates without any
              contracts, obligations, or mandatory minimum spending.
            </p>

            <form
              className="card shadow-lg rounded-1 position-relative p-2 pe-md-3 pb-3 pb-md-3"
              action="register"
              method="get"
            >
              <div className="row g-4 align-items-center">
                <div className="col-lg-12 px-4">
                  <div className="d-flex">
                    <div className="input-group mt-2">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your work email"
                        name="email"
                      />
                      <button type="submit" className="btn btn-primary register-btn">
                        Register your business for free!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="row g-4 align-items-center">
              <div className="col-lg-12 mt-6">
                <h4 className="text-uppercase fw-medium">
                  2023 &amp; 2024 Member Savings:
                  <br />
                  <strong className="text-success h1" id="savings">
                    ${savings}
                  </strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
