import React from 'react';

import { scrollTop } from '@share/utils';
import { GetawayCondo } from '@components';

import './style.scss';

interface IProps {
  isInternal: boolean;
}

export default class GetawayCondoPage extends React.Component<IProps, null> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return <GetawayCondo isInternal={this.props.isInternal}/>;
  }
}
