import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ModalExportCsv } from '@components';
import { BookingTabsEnum, BookingStatusEnum, HotelBookingStatusEnum } from '@common-types';
import { RootState } from '@share/utils';
import { adminAllBookingsActions, adminBookingsActions, adminHotelBookingsActions, getAdminBookings, selectAdminHotelBookingsStatus } from '@store/slices';
import {
  BOOKING_SUB_STATUSES_LABEL,
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_PAGINATION,
  BOOKING_TABLE_SEARCH,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_STATUS_LABEL,
  BOOKING_TABLE_CURRENT_TAB,
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  BOOKING_TABLE_MATCHED_BY
} from '@share/constants';
import { UrlUtils } from '@share/utils';
import { getAdminHotelBookings } from '@store/slices';
import { CondoBookings, HotelBookings } from './components';
import { getCondosStatusByHotels, getHotelsStatusByCondos } from '@utils';

import './style.scss';

interface IMapStateToProps {
  currentTab: BookingTabsEnum;
  hotelBookingsStatus: HotelBookingStatusEnum;
  condoBookingsStatus: BookingStatusEnum;
}

interface IMapDispatchToProps {
  setCondoStatus: (status: BookingStatusEnum) => void;
  setHotelStatus: (status: HotelBookingStatusEnum) => void;
  resetCondoFilters: () => void;
  resetHotelFilters: () => void;
  resetCondoSort: () => void;
  resetHotelSort: () => void;
  getAdminBookings: () => void;
  getAdminHotelBookings: () => void;
  setTab: (tab: BookingTabsEnum) => void;
  resetCondosSearch: () => void;
  resetHotelsSearch: () => void;
}

interface IProps
  extends IMapDispatchToProps,
    IMapStateToProps,
    RouteComponentProps,
    WrappedComponentProps {}

interface IState {
  isModalExportCsvVisible: boolean;
}

interface ITabConfig {
  tabComponent: React.ReactNode;
  setStatus: () => void;
  onReset: () => void;
}

type TTabConfigMap = {
  [key in BookingTabsEnum]: ITabConfig;
};

class AllBookingsComponent extends React.Component<IProps, IState> {
  state: IState = {
    isModalExportCsvVisible: false,
  };

  constructor(props: IProps) {
    super(props);
    props.setTab(this.getCurrentTabFromURl());
  }

  getTabConfig = (tab: BookingTabsEnum): ITabConfig | undefined => {
    const tabConfigMap: TTabConfigMap = {
      [BookingTabsEnum.Condos]: {
        tabComponent: <CondoBookings showModalExportCsv={this.showModalExportCsv} />,
        setStatus: () => {
          const status = getCondosStatusByHotels(this.props.hotelBookingsStatus);
          this.props.setCondoStatus(status);
        },
        onReset: () => {
          this.props.resetCondoFilters();
          this.props.getAdminBookings();
        },
      },
      [BookingTabsEnum.Hotels]: {
        tabComponent: <HotelBookings showModalExportCsv={this.showModalExportCsv} />,
        setStatus: () => {
          const status = getHotelsStatusByCondos(this.props.condoBookingsStatus);
          this.props.setHotelStatus(status);
        },
        onReset: () => {
          this.props.resetHotelFilters();
          this.props.getAdminHotelBookings();
        },
      },
    };
    return tabConfigMap[tab];
  };

  getCurrentTabFromURl(): BookingTabsEnum {
    const currentTab = UrlUtils.getValues()[BOOKING_TABLE_CURRENT_TAB] as BookingTabsEnum;
    return currentTab || BookingTabsEnum.Hotels;
  }

  setCurrentTabIntoURl(tab: string): void {
    UrlUtils.setUrl(BOOKING_TABLE_CURRENT_TAB, tab);
  }

  resetURLFilters = (): void => {
    const urlParams = [
      BOOKING_TABLE_SEARCH,
      BOOKING_TABLE_MATCHED_BY,
      BOOKING_TABLE_PAGINATION,
      BOOKING_STATUS_LABEL,
      BOOKING_TABLE_SORT_ORDER,
      BOOKING_SUPPLIER_TYPES_LABEL,
      BOOKING_SUB_STATUSES_LABEL,
      BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
      BOOKING_TABLE_DATE_CHECK_IN_RANGE,
      BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
    ];

    urlParams.forEach((param) => {
      UrlUtils.removeFromUrl(param);
    });
  };

  changeTab = (tab: BookingTabsEnum): void => {
    this.resetURLFilters();
    this.setCurrentTabIntoURl(tab);
    this.props.setTab(tab);
    this.props.resetCondosSearch();
    this.props.resetHotelsSearch();
    this.getTabConfig(tab)?.setStatus();
  };

  renderCurrentTab = (): React.ReactNode => {
    const { currentTab } = this.props;

    return this.getTabConfig(currentTab)?.tabComponent;
  };

  onResetExportCsvFilters = () => {
    const { currentTab } = this.props;

    this.getTabConfig(currentTab)?.onReset();
  };

  handleCancelModalExportCsv = (): void => {
    this.setState({ isModalExportCsvVisible: false });
  };

  showModalExportCsv = (): void => {
    this.setState({
      isModalExportCsvVisible: true,
    });
  };

  render(): React.ReactNode {
    const { isModalExportCsvVisible } = this.state;
    const { currentTab } = this.props;

    return (
      <div className="all-bookings">
        <div className="all-bookings__wrapper">
          <div className="all-bookings__header">
            <div className="all-bookings__tabs">
              <Tabs defaultActiveKey={this.getCurrentTabFromURl()} onChange={this.changeTab}>
                <Tabs.TabPane
                  tab={<FormattedMessage id="hotels.title" />}
                  key={BookingTabsEnum.Hotels}
                />
                <Tabs.TabPane
                  tab={<FormattedMessage id="condos.title" />}
                  key={BookingTabsEnum.Condos}
                />
              </Tabs>
            </div>
          </div>
          {this.renderCurrentTab()}
        </div>
        <ModalExportCsv
          visible={isModalExportCsvVisible}
          selectedTab={currentTab}
          onCancel={this.handleCancelModalExportCsv}
          onResetFilters={this.onResetExportCsvFilters}
          handleCancelModalExportCsv={this.handleCancelModalExportCsv}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    currentTab: state.adminAllBookingsStore.currentTab,
    hotelBookingsStatus: selectAdminHotelBookingsStatus(state),
    condoBookingsStatus: state.adminBookingsStore.status,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getAdminBookings,
  getAdminHotelBookings,
  setCondoStatus: adminBookingsActions.setStatus,
  setHotelStatus: adminHotelBookingsActions.setStatus,
  resetCondoFilters: adminBookingsActions.resetFilters,
  resetHotelFilters: adminHotelBookingsActions.resetFilters,
  resetCondoSort: adminBookingsActions.resetSort,
  resetHotelSort: adminHotelBookingsActions.resetSort,
  setTab: adminAllBookingsActions.setTab,
  resetCondosSearch: adminBookingsActions.resetSearch,
  resetHotelsSearch: adminHotelBookingsActions.resetSearch,
};

const ComponentWithIntl = injectIntl(AllBookingsComponent);

export const AllBookings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComponentWithIntl));
