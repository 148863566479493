import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Route, RouteComponentProps, Switch, withRouter, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import {
  ADMIN_PAGE,
  ADMIN_PAGE_URL_INFO,
  MenuItemsEnum,
  ORDER_DETAILS_PAGE,
  BOOKING_DETAILS_PAGE,
} from '@constants';
import {
  Routes,
} from '@share/constants';
import { adminBookingsActions, adminHotelBookingsActions, getAdminBookings, IAdminBookingsState, selectAdminHotelBookingsPagination, selectAdminHotelBookingsStatus, selectAdminHotelBookingsTotalCount } from '@store/slices';
import { BookingStatusEnum, BookingTabsEnum, BreadcrumbEnum, CondoBookingType, HotelBookingStatusEnum } from '@common-types';
import { capitalLettersOfEachWord } from '@share/utils';
import { RootState } from '@share/utils';

import { NavigationMenu } from '../navigation-menu';
import { ActivityUsers, PriceRules } from '../price-rules';
import { PresentationRules } from '../presentation-rules';
import { NewRule } from '../new-rule';
import { AllBookings } from '../bookings';
import { BookingDetails } from '../bookings/booking-details';
import { AccountDetails, AccountInformation, AllAccounts } from '../accounts';
import { BookingHotelDetails } from '../bookings/admin-hotel-details';

import { getCondosStatusByHotels, getHotelsStatusByCondos } from '@utils';
import { getAdminHotelBookings } from '@store/slices';

import './style.scss';

interface IMapStateToProps {
  adminBookingsStore: IAdminBookingsState;
  hotelBookingsStatus: HotelBookingStatusEnum;
  hotelBookingsPagination: TablePaginationConfig;
  currentTab: BookingTabsEnum;
  hotelBookingsOrderCount: number;
}

interface IMapDispatchToProps {
  getAdminBookings: () => void;
  getAdminHotelBookings: () => void;
  setStatus: (status: BookingStatusEnum) => void;
  setPagination: (pagination: TablePaginationConfig) => void;
  setHotelBookingsStatus: (status: HotelBookingStatusEnum) => void;
  setHotelBookingsPagination: (pagination: TablePaginationConfig) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {}

interface IState {
  activeItem: MenuItemsEnum;
}

const ZERO = 0;
const ONE = 1;
const THREE = 3;

class AdminPanelComponent extends React.Component<IProps, IState> {
  state: IState = {
    activeItem: null,
  };

  getBreadcrumb = () => {
    if (location.pathname === Routes.AllBookings) {
      return null;
    }

    if (location.pathname.startsWith(Routes.Account)) {
      const paths = [
        <Breadcrumb.Item key="1">Accounts</Breadcrumb.Item>,
        <Breadcrumb.Item key="2">Account</Breadcrumb.Item>,
      ];
      if (location.pathname === Routes.Account) {
        paths.push(<Breadcrumb.Item key="3">New</Breadcrumb.Item>);
      } else {
        paths.push(<Breadcrumb.Item key="3">Edit</Breadcrumb.Item>);
      }
      return paths;
    }

    return location.pathname.split('/').map((item, index) => {
      if (
        item === ADMIN_PAGE ||
        item === ORDER_DETAILS_PAGE ||
        item === BOOKING_DETAILS_PAGE ||
        item === ''
      ) {
        return;
      }

      const to = BreadcrumbEnum[item as keyof typeof BreadcrumbEnum];
      const title = capitalLettersOfEachWord(item);

      return (
        <Breadcrumb.Item key={index}>
          {isUndefined(to) ? title : <Link to={to}>{title}</Link>}
        </Breadcrumb.Item>
      );
    });
  };

  isPresentationRule = () => {
    const urlArr = location.pathname.split('/');

    if (urlArr.includes('presentation-rules')) {
      return true;
    }

    return false;
  };

  getHeaderInfo = (): string[][] => {
    return Object.entries(ADMIN_PAGE_URL_INFO).filter(([key, value]) => {
      if (String(key) === location.pathname.split('/').pop()) {
        return `${key}, ${value}`;
      }
      if (location.pathname.split('/').filter(p => p === key).length === 1) {
        return `${key}, ${value}`;
      }
    });
  };

  componentDidMount() {
    const { location, history } = this.props;

    if (location.pathname === Routes.AdminPage || location.pathname === `${Routes.AdminPage}/`) {
      history.push(Routes.AllBookings);
    }

    if (location.pathname.split('/')[THREE] === Object.keys(ADMIN_PAGE_URL_INFO)[ZERO]) {
      this.setState({ activeItem: MenuItemsEnum.PriceRules });
    }

    if (location.pathname.split('/')[THREE] === Object.keys(ADMIN_PAGE_URL_INFO)[ONE]) {
      this.setState({ activeItem: MenuItemsEnum.PresentationRules });
    }

    document.body.style.background = 'var(--light-blue)';
  }

  componentDidUpdate() {
    window?.zE('webWidget', 'hide');
  }

  componentWillUnmount() {
    window?.zE('webWidget', 'show');
    document.body.style.background = 'none';
  }

  onSetStatus = (status: BookingStatusEnum): void => {
    const setStatusesMap = {
      [BookingTabsEnum.Hotels]: this.setHotelStatus,
      [BookingTabsEnum.Condos]: this.setCondoStatus,
    };

    setStatusesMap[this.props.currentTab]?.(status);
  };

  setCondoStatus = (status: BookingStatusEnum): void => {
    const { adminBookingsStore, setStatus, getAdminBookings, setPagination } = this.props;

    if (isNull(adminBookingsStore.status)) {
      this.setState({ activeItem: null });
    }

    const currentStatus = getCondosStatusByHotels(status);

    if (adminBookingsStore.status !== currentStatus && isNull(this.state.activeItem)) {
      setStatus(currentStatus);
      setPagination({
        ...adminBookingsStore.pagination,
        current: ONE,
      });
      getAdminBookings();
    } else if (adminBookingsStore.status !== currentStatus) {
      setStatus(currentStatus);
      this.setState({ activeItem: null });
    }
  };

  setHotelStatus = (status: BookingStatusEnum): void => {
    const {
      hotelBookingsStatus,
      setHotelBookingsPagination,
      setHotelBookingsStatus,
      hotelBookingsPagination,
      getAdminHotelBookings,
    } = this.props;

    if (isNull(hotelBookingsStatus)) {
      this.setState({ activeItem: null });
    }

    const currentStatus = getHotelsStatusByCondos(status);

    if (hotelBookingsStatus !== currentStatus && isNull(this.state.activeItem)) {
      setHotelBookingsStatus(currentStatus);
      setHotelBookingsPagination({
        ...hotelBookingsPagination,
        current: ONE,
      });
      getAdminHotelBookings();
    } else if (hotelBookingsStatus !== currentStatus) {
      setHotelBookingsStatus(currentStatus);
      this.setState({ activeItem: null });
    }
  };

  onActiveMenuItem = (item: MenuItemsEnum): void => {
    const { setStatus, setHotelBookingsStatus } = this.props;

    setStatus(null);
    setHotelBookingsStatus(null);
    this.setState({ activeItem: item });
  };

  render(): React.ReactNode {
    const { intl } = this.props;
    const { activeItem } = this.state;
    const title =
      this.getHeaderInfo().length &&
      this.getHeaderInfo()[ZERO][ZERO] === intl.formatMessage({ id: 'new-rule' })
        ? intl.formatMessage({
            id: this.isPresentationRule() ? 'new.presentation.rule' : 'new.price.rules',
          })
        : !isEmpty(this.getHeaderInfo()) &&
          capitalLettersOfEachWord(this.getHeaderInfo()[ZERO][ZERO]);
    const description =
      this.getHeaderInfo().length &&
      this.isPresentationRule() &&
      this.getHeaderInfo()[ZERO][ZERO] === intl.formatMessage({ id: 'new-rule' })
        ? 'new.presentation.rule.description'
        : this.getHeaderInfo().length
        ? this.getHeaderInfo()[ZERO][ONE]
        : null;
    const isAllBookings = location.pathname === Routes.AllBookings;

    return (
      <div className="admin-panel">
        <NavigationMenu
          activeItem={activeItem ? activeItem : this.getStatusByCurrentTab()}
          onBookingStatusSelected={this.onSetStatus}
          onActiveMenuItem={this.onActiveMenuItem}
        />
        <div className="admin-panel__wrapper">
          <div className="admin-panel__header">
            {isAllBookings ? (
              <>
                <p className="admin-panel__header-title">
                  <FormattedMessage id="all.bookings" />
                </p>
                {this.renderTotalCount()}
              </>
            ) : (
              <>
                <Breadcrumb>{this.getBreadcrumb()}</Breadcrumb>
                {title ? <p className="admin-panel__header-title">{title}</p> : null}
                {description ? (
                  <p className="admin-panel__header-description">
                    <FormattedMessage id={description} />
                  </p>
                ) : null}
              </>
            )}
          </div>
          <Switch>
            <Route path={`${Routes.PriceRules}`} exact>
              <PriceRules />
            </Route>
            <Route path={`${Routes.PresentationRules}`} exact>
              <PresentationRules />
            </Route>
            <Route path={`${Routes.AllBookings}`} exact>
              <AllBookings />
            </Route>
            <Route path={`${Routes.BookingDetails}/:id`}>
              <BookingDetails type={CondoBookingType.Booking} />
            </Route>
            <Route path={`${Routes.OrderDetails}/:id`}>
              <BookingDetails type={CondoBookingType.Order} />
            </Route>
            <Route path={`${Routes.AdminHotelBookingDetails}/:id`}>
              <BookingHotelDetails />
            </Route>
            <Route path={`${Routes.NewRule}`}>
              <NewRule />
            </Route>
            <Route path={`${Routes.NewPresentationRule}`}>
              <NewRule isPresentationRule />
            </Route>
            <Route path={`${Routes.ActivityUsers}`}>
              <ActivityUsers />
            </Route>
            <Route path={`${Routes.Accounts}`} exact>
              <AllAccounts />
            </Route>
            <Route path={`${Routes.Account}`} exact>
              <AccountDetails />
            </Route>
            <Route path={`${Routes.Account}/:id`} exact>
              <AccountInformation />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }

  getStatusByCurrentTab(): BookingStatusEnum {
    const { currentTab, adminBookingsStore, hotelBookingsStatus } = this.props;

    const hotelStatus = getCondosStatusByHotels(hotelBookingsStatus);
    const statusTabMap = {
      [BookingTabsEnum.Hotels]: hotelStatus,
      [BookingTabsEnum.Condos]: adminBookingsStore.status,
    };

    return statusTabMap[currentTab];
  }

  renderTotalCount() {
    const { adminBookingsStore, currentTab, hotelBookingsOrderCount } = this.props;
    const tabsMap = {
      [BookingTabsEnum.Hotels]: (
        <>
          {hotelBookingsOrderCount ? (
            <p className="admin-panel__header-count">
              <FormattedMessage id="bookings.num" values={{ count: hotelBookingsOrderCount }} />
            </p>
          ) : null}
        </>
      ),
      [BookingTabsEnum.Condos]: (
        <>
          {adminBookingsStore.totalOrderCount ? (
            <p className="admin-panel__header-count">
              <FormattedMessage
                id="bookings.num"
                values={{ count: adminBookingsStore.totalOrderCount }}
              />
            </p>
          ) : null}
        </>
      ),
    };

    return tabsMap[currentTab] || null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingsStore: state.adminBookingsStore,
    hotelBookingsStatus: selectAdminHotelBookingsStatus(state),
    hotelBookingsPagination: selectAdminHotelBookingsPagination(state),
    hotelBookingsOrderCount: selectAdminHotelBookingsTotalCount(state),
    currentTab: state.adminAllBookingsStore.currentTab,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getAdminBookings,
  getAdminHotelBookings,
  setStatus: adminBookingsActions.setStatus,
  setPagination: adminBookingsActions.setPagination,
  setHotelBookingsStatus: adminHotelBookingsActions.setStatus,
  setHotelBookingsPagination: adminHotelBookingsActions.setPagination,
};

const AdminPanelWithIntl = withRouter(injectIntl(AdminPanelComponent));
export const AdminPanel = connect(mapStateToProps, mapDispatchToProps)(AdminPanelWithIntl);

