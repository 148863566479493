import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import {
  Membership,
  CompanyLogos,
  ConciergeTeam,
  CondoSpecials,
  CondoResultWrapper,
  CondoPopularDestinations,
  CondoRecentSearchesWrapper,
  Banner,
  AboutUs,
  SearchBenefits,
  Contact,
  CustomGetaways,
  GetawayList,
} from '@components';
import { CondoSearch } from '@share/components';
import { IBenefitsState, ICondosState, condosActions, condosLocationPickerActions } from '@share/store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { Responsive } from '@share/utils';
import { getAccountUsernameFromPath, getPreferenceFromAccount, getTimeout, RootState, scrollTop, UrlUtils } from '@share/utils';
import { BackgroundColor } from '@constants';
import { CondosCompanyLogos } from '@common-types';
import { GetawaysCondoSubCategoryEnum, GetawaysLocationEnum, ICondo } from '@share/common-types';
import { DISPLAY_CONTACT_SECTION_FIELD_NAME, DISPLAY_TRUSTED_BRANDS_SECTION_FIELD_NAME, ISessionKey, PRIMARY_BANNER_TYPE, SECONDARY_BANNER_TYPE } from '@share/common-types';
import { CONDO_SESSION_KEY, Routes } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  condosStore: ICondosState;
  menuStore: IMenuState;
  loginStore: ILoginState;
  benefitsStore: IBenefitsState;
}

interface IMapDispatchToProps {
  setDefaultValues: () => void;
  setCondos: (condos: ICondo[]) => void;
  setIsSearch: (value: boolean) => void;
  resetLocations: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps { }

interface IState {
  isGotoBack: boolean;
}

const ZERO = 0;

class CondoPageComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    scrollTop();

    this.state = {
      isGotoBack: false,
    };
  }

  componentDidMount(): void {
    const values = UrlUtils.getValues();
    const session: ISessionKey = values[CONDO_SESSION_KEY] as ISessionKey;
    const accountName = getAccountUsernameFromPath(this.props.history);

    if (session && session.expireDate && getTimeout(session.expireDate) === ZERO) {
      this.props.setDefaultValues();
    }

    if (isEmpty(values)) {
      this.props.setDefaultValues();
      this.props.resetLocations();
      this.props.history.push(`/${accountName}${Routes.CondoSearch}${location.search}`);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const values = UrlUtils.getValues();

    if (isEmpty(values) && this.props.condosStore.condos && !this.state.isGotoBack) {
      this.setState({ isGotoBack: true });
      this.props.setCondos(null);
      this.props.setIsSearch(false);
      this.props.resetLocations();
    }
  }

  render(): React.ReactNode {
    const { condosStore, menuStore, loginStore, benefitsStore } = this.props;
    const { condos } = condosStore;
    const { user, account } = loginStore;
    const { items } = menuStore;
    const { benefits } = benefitsStore;

    const hasDisplayContactSection = getPreferenceFromAccount(account, DISPLAY_CONTACT_SECTION_FIELD_NAME);
    const hasDisplayTrustedBrandsSection = getPreferenceFromAccount(account, DISPLAY_TRUSTED_BRANDS_SECTION_FIELD_NAME);
    const hideThingsMatterSection = account?.hideThingsMatterSection;
    const isRSITemplate = account?.isRSITemplate;

    return (
      <div className="condo-page">
        <CondoSearch />
        {!condos ? (
          <>
            {!!user ? (<CondoRecentSearchesWrapper />) : null}
            {!!user ? (<CondoPopularDestinations />) : null}

            {isRSITemplate ? (
              <CondoSpecials customerServiceNumber={menuStore.items?.customerServiceNumber} />) : null}

            {((benefits?.items && items?.isMLM) || (account?.displayGetawaysHomeCondos && account?.displayGetawaysHomeHotelsSlider)) ? (
              <GetawayList
                getaway={GetawaysLocationEnum.Condos}
                condoSubcategory={GetawaysCondoSubCategoryEnum.ValueGetaways}
              
                isFromProperty
                isInternal
                isHome
                isSlider
              />) : null}

            {isRSITemplate ? (
              <ConciergeTeam
                customerServiceNumber={menuStore.items?.customerServiceNumber}
                backgroundColor={BackgroundColor.light}
                isImageSizeSmaller={true}
              />) : null}

            {!isRSITemplate ? (
              <Banner type={PRIMARY_BANNER_TYPE} />) : null}

            {(account?.displayGetawaysHomeCondos && !account?.displayGetawaysHomeHotelsSlider) ? (
                <CustomGetaways benefits={benefitsStore?.benefits} noPaddingTop isFromHome />) : null}

            {(!isRSITemplate && (isEmpty(hasDisplayContactSection) || hasDisplayContactSection === 'true')) ? (
              <Contact />) : null}

            {isRSITemplate ? (
              <CompanyLogos isBackgroundColorDark={true} logos={CondosCompanyLogos} />) : null}

            {(!isRSITemplate && !hideThingsMatterSection) ? (
              <SearchBenefits />) : null}

            {(!isRSITemplate && (isEmpty(hasDisplayTrustedBrandsSection) || hasDisplayTrustedBrandsSection === 'true')) ? (
              <CompanyLogos logos={CondosCompanyLogos} />) : null}

            {!isRSITemplate ? (
              <AboutUs />) : null}

            {!isRSITemplate ? (
              <Banner type={SECONDARY_BANNER_TYPE} />) : null}

            {isRSITemplate ? (
              <Membership />) : null}
          </>
        ) : null}
        {condos ? (
          <Responsive>
            <CondoResultWrapper />
          </Responsive>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    benefitsStore: state.benefitsStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDefaultValues: () => {
    dispatch(condosActions.setDefaultValues());
  },
  setCondos: (condos: ICondo[]) => {
    dispatch(condosActions.setCondos(condos));
  },
  setIsSearch: (value: boolean) => {
    dispatch(condosActions.setIsSearch(value));
  },
  resetLocations: () => {
    dispatch(condosLocationPickerActions.resetLocations());
  },
});

export const CondoPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(CondoPageComponent));
