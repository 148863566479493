import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import img from "@assets/images/avatar/avatar01.png";
import { personalDetailsFormInfo } from "../../../_mock-api's/index";
import useApi from "../../../api-hook/component";
import createAccountPayload from "../register/payload.js";
import { toast } from "react-toastify";

interface FormValues {
  data?: any;
}

const PersonalInfo: React.FC<FormValues> = ({ data }) => {
  const [form] = Form.useForm<FormValues>();
  const { fetchData } = useApi("https://qa2-api.travcoding.com");
  const [profilePic, setProfilePic] = useState<string>(img);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = (info: any) => {
    const file = info.file.originFileObj as File;
    const reader = new FileReader();
    reader.onload = (e) => {
      setProfilePic(e?.target?.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onFinish = async (values) => {
    data.email = values.email;
    data.name = values.username;
    data.firstName = values.firstName;
    data.lastName = values.lastName;

    setLoading(true);
    try {
      const result = await fetchData(
        "/identity/users/save",
        data,
        "post"
      );

      if (result?.firstName) {
        toast.success("Personal details updated successfully", {
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error parsing hotels data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Card
      title={
        <>
          <UserOutlined /> Personal Information
        </>
      }
      bordered={false}
      className="shadow-sm mb-5"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={data}
          onFinish={onFinish}
        >
          <div className="col-12">
            <label className="form-label">
              Upload your profile photo
              <span className="text-danger">*</span>
            </label>
            <div className="d-flex align-items-center">
              <label
                className="position-relative me-4"
                htmlFor="uploadfile-1"
                title="Replace this pic"
              >
                <span className="avatar avatar-xl">
                  <img
                    id="uploadfile-1-preview"
                    className="avatar-img rounded-circle border border-white border-3 shadow"
                    src={profilePic}
                    alt="profilePic"
                  />
                </span>
              </label>
              <label
                className="btn btn-sm btn-outline-primary mb-0"
                htmlFor="uploadfile-1"
              >
                Upload New
              </label>
              <input
                id="uploadfile-1"
                className="form-control d-none"
                type="file"
                onChange={handleUpload} // Handle file upload
              />
            </div>
          </div>
          <div className="form-items-container">
            <div className="_form-grid">
              {personalDetailsFormInfo?.map((item) => (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  rules={item.rules}
                >
                  <Input
                    placeholder={item.placeholder}
                    type={item.name === "mobileNumber" ? "tel" : "text"}
                    disabled={item?.isDisabled}
                  />
                </Form.Item>
              ))}
            </div>
          </div>
          <Form.Item className="text-end">
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default PersonalInfo;
