import React, { useState } from 'react';

import { Button, DatePicker, Form, Input, Modal, Select, Spin } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { getMyProfile } from '@share/store/slices';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '@share/utils';
import { Action } from '@reduxjs/toolkit';

import './style.scss';
import { MyProfileAPI } from '@share/api';

interface IMapDispatchToProps {
  get: () => void;
}

interface IProps extends IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  visible: boolean;
  disabled: boolean;
  rsiId: number;

  onCancel: () => void;
}

function ModalAddAuthorizedUserComponent(props: IProps) {
  const { visible, rsiId, intl, disabled, onCancel, get } = props;

  const [ loading, setLoading ] = useState(false);
  const [ formValid, setFormValid ] = useState(true);

  const [ form ] = Form.useForm();

  const setButtonDisabled = () => {
    setFormValid(form.getFieldsError().some((field) => field.errors.length > 0))
  };

  const onFinish = (values: any) => {
    if (!loading) {
      setLoading(true);

      const data = { ...values.profile };
      data.rsiId = rsiId;
      data.authId = 0;
      
      MyProfileAPI.addAuthorizedUser(data).then(async () => {
          await get();
          onCancel();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      className="modal-add-authorized-user"
      visible={visible}
      centered
      footer={null}
      onCancel={() => {
        if (!loading) {
          onCancel();
        }
      }}
      wrapClassName="modal-add-authorized-user__wrapper"
      width={500}
      style={{ zIndex: 2000 }}
    >
      <>
        <h4 className="modal-share__title">
          <FormattedMessage id="add.authorized.users" />
        </h4>
        <div className="form-profile">
          <Form name="nest-messages" form={form} onFieldsChange={setButtonDisabled} onFinish={onFinish}>
            <Form.Item>
              <div className="row">
                <div className="col-sm-5">
                  <Form.Item
                    name={['profile', 'firstName']}
                    label={intl.formatMessage({ id: 'name' })}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'first.name' }).toUpperCase()} />
                  </Form.Item>
                </div>

                <div className="col-sm-2">
                  <Form.Item
                    name={['profile', 'middleName']}
                    rules={[{ required: false }]}
                  >
                    <Input placeholder="MI" />
                  </Form.Item>
                </div>

                <div className="col-sm-5">
                  <Form.Item
                    name={['profile', 'lastName']}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'last.name' }).toUpperCase()} />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-12">
                  <Form.Item
                    name={['profile', 'email']}
                    label={intl.formatMessage({ id: 'email' })}
                    rules={[{ required: true, message: 'Please enter value', type: 'email' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'email' }).toUpperCase()} />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
              <div className="col-sm-8">
                  <Form.Item
                    name={['profile', 'phone']}
                    label={intl.formatMessage({ id: 'cell.phone' })}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'cell.phone' }).toUpperCase()} />
                  </Form.Item>
                </div>
                <div className="col-sm-4"
                >
                  <Form.Item
                    name={['profile', 'phoneType']}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Select className="modal-add-authorized-user__select" dropdownClassName="modal-add-authorized-user__select-dropdown" placeholder={intl.formatMessage({ id: 'cell.phone.type' }).toUpperCase()}>
                      <Select.Option value={'Work'}><FormattedMessage id="profile.phone.type.work" /></Select.Option>
                      <Select.Option value={'Home'}><FormattedMessage id="profile.phone.type.home" /></Select.Option>
                      <Select.Option value={'Cell'}><FormattedMessage id="profile.phone.type.cell" /></Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-8">
                  <Form.Item
                    name={['profile', 'altPhone']}
                    label={intl.formatMessage({ id: 'alt.phone' })}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'alt.phone' }).toUpperCase()} />
                  </Form.Item>
                </div>
                <div className="col-sm-4"
                >
                  <Form.Item
                    name={['profile', 'altPhoneType']}
                  >
                    <Select className="modal-add-authorized-user__select" dropdownClassName="modal-add-authorized-user__select-dropdown" placeholder={intl.formatMessage({ id: 'cell.phone.type' }).toUpperCase()}>
                      <Select.Option value={'Work'}><FormattedMessage id="profile.phone.type.work" /></Select.Option>
                      <Select.Option value={'Home'}><FormattedMessage id="profile.phone.type.home" /></Select.Option>
                      <Select.Option value={'Cell'}><FormattedMessage id="profile.phone.type.cell" /></Select.Option>
                    </Select>
                  </Form.Item>
                </div>

              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-12">
                  <Form.Item
                    name={['profile', 'address']}
                    label={intl.formatMessage({ id: 'address' })}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'address' }).toUpperCase()} />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name={['profile', 'city']}
                    label=""
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'city' }).toUpperCase()} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name={['profile', 'state']}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'state' }).toUpperCase()} />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-8">
                  <Form.Item
                    name={['profile', 'country']}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'country' }).toUpperCase()} />
                  </Form.Item>
                </div>
                <div className="col-sm-4">
                  <Form.Item
                    name={['profile', 'postalCode']}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'postal-code' }).toUpperCase()} />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name={['profile', 'birthday']}
                    label={intl.formatMessage({ id: 'birthday' })}
                    rules={[{ required: false }]}
                  >
                    <DatePicker className="modal-add-authorized-user__date-picker" dropdownClassName="modal-add-authorized-user__date-picker-dropdown" placeholder={intl.formatMessage({ id: 'birthday' }).toUpperCase()} format={'yyyy-MM-DD'} />
                  </Form.Item>
                </div>
                <div className="col-sm-6"
                >
                  <Form.Item
                    name={['profile', 'relationship']}
                    label={intl.formatMessage({ id: 'relationship' })}
                    rules={[{ required: true, message: 'Please enter value' }]}
                  >
                    <Select className="modal-add-authorized-user__select" dropdownClassName="modal-add-authorized-user__select-dropdown" placeholder={intl.formatMessage({ id: 'cell.phone.type' }).toUpperCase()}>
                      <Select.Option value={'Friend'}><FormattedMessage id="profile.relationship.Friend" /></Select.Option>
                      <Select.Option value={'Family'}><FormattedMessage id="profile.relationship.Family" /></Select.Option>
                      <Select.Option value={'Spouse'}><FormattedMessage id="profile.relationship.Spouse" /></Select.Option>
                    </Select>
                  </Form.Item>
                </div>

              </div>
            </Form.Item>

            <Form.Item>
              <div className="row">
                <div className="col-sm-12">
                  <Form.Item>
                    <Button block size='large' type="primary" htmlType="submit" disabled={formValid || loading || disabled}>
                      <FormattedMessage id="save.changes" />
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div >
      </>
    </Modal>)
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  get: () => {
    dispatch(getMyProfile());
  },
});


export const ModalAddAuthorizedUser = connect(null, mapDispatchToProps)(injectIntl(withRouter(ModalAddAuthorizedUserComponent)));