import { MiddlewareAPI, Dispatch, PayloadAction, Middleware } from '@reduxjs/toolkit';

import { RootState, UrlUtils } from '@share/utils';
import { IRoom, DateSearchTypeEnum, IClientCash } from '@share/common-types';
import {
  ROOMS_LABEL,
  LOCATION_LABEL,
  LOCATION_OBJECT_LABEL,
  DATES_LABEL,
  SESSION_KEY_LABEL,
  CONDO_SESSION_KEY,
  SESSION_KEY_LABEL_ROOMS,
  CONDO_LOCATIONS_OBJECT_LABEL,
  CONDO_LOCATIONS_LABEL,
  CONDO_DATES_LABEL,
  CONDO_DATES_FLEXIBLE_LABEL,
  CONDO_GUESTS_LABEL,
  CONDO_IS_FLEXIBLE_LABEL,
  MAX_SELECTED_MONTH_COUNT,
  CONDO_GUESTS_ACTIONS_LABEL,
  CONDO_SEARCH_BOUNDS,
  CLIENT_CASH_LABEL,
  CLIENT_CASH_CONDO_LABEL,
} from '@share/constants';
import {
  locationActions,
  datesActions,
  hotelsActions,
  roomsActions,
  condosLocationPickerActions,
  condoStrictDatesActions,
  condoFlexibleDatePickerActions,
  condosActions,
  condoGuestsActions,
} from '@share/store/slices';
import { roomsSearchActions } from '../slices';

const zero = 0;
const one = 1;

export const urlMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, RootState>) => {
  return (next: Dispatch<PayloadAction<Record<string, unknown> | string | number | undefined>>) => {
    return (action: PayloadAction<Record<string, unknown> | string | number | undefined>) => {
      const { getState } = api;
      const { condoFlexibleDatePickerStore, hotelsStore, datesStore, roomsStore, locationsStore } = getState();

      if (!hotelsStore?.isWidget) {
        switch (action.type) {
          case condosActions.setBounds.toString(): {
            UrlUtils.setUrl(CONDO_SEARCH_BOUNDS, action.payload as Record<string, unknown>);
            break;
          }
          case locationActions.applyLocation.toString(): {
            UrlUtils.setUrl(LOCATION_OBJECT_LABEL, locationsStore.selectedLocation);
            UrlUtils.setUrl(LOCATION_LABEL, locationsStore.selectedLocationLabel);
            break;
          }
          case datesActions.applyDates.toString(): {
            UrlUtils.setUrl(DATES_LABEL, { startDate: datesStore.startDateSelected, endDate: datesStore.endDateSelected } as Record<string, unknown>);
            break;
          }
          case roomsActions.applyRooms.toString(): {
            UrlUtils.setUrl(ROOMS_LABEL,
              roomsStore.roomsSelected.map((room: IRoom) => {
                return {
                  adultsCount: room.adultsCount,
                  kids: room.kids.map(({ age }) => age),
                };
              }));
            break;
          }
          case hotelsActions.setSessionKey.toString(): {
            UrlUtils.setUrl(SESSION_KEY_LABEL, action.payload as Record<string, unknown>);
            break;
          }
          case condosActions.setSessionKey.toString(): {
            UrlUtils.setUrl(CONDO_SESSION_KEY, action.payload as Record<string, unknown>);
            break;
          }
          case roomsSearchActions.setSessionKey.toString(): {
            UrlUtils.setUrl(SESSION_KEY_LABEL_ROOMS, action.payload as Record<string, unknown>);
            break;
          }
          case condosLocationPickerActions.selectLocation.toString(): {
            UrlUtils.removeFromUrl(CONDO_LOCATIONS_OBJECT_LABEL);
            UrlUtils.setUrl(CONDO_LOCATIONS_OBJECT_LABEL, action.payload as Record<string, unknown>);
            break;
          }
          case condosLocationPickerActions.selectLocationLabel.toString(): {
            UrlUtils.removeFromUrl(CONDO_LOCATIONS_LABEL);
            UrlUtils.setUrl(CONDO_LOCATIONS_LABEL, action.payload as Record<string, unknown>);
            break;
          }
          case condoStrictDatesActions.setDates.toString(): {
            UrlUtils.setUrl(CONDO_DATES_LABEL, action.payload as Record<string, unknown>);
            UrlUtils.removeFromUrl(CONDO_IS_FLEXIBLE_LABEL);
            UrlUtils.setUrl(CONDO_IS_FLEXIBLE_LABEL, zero);
            UrlUtils.removeFromUrl(CONDO_DATES_FLEXIBLE_LABEL);
            break;
          }
          case condoStrictDatesActions.resetDates.toString(): {
            if (action.payload) {
              UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
              UrlUtils.removeFromUrl(CONDO_IS_FLEXIBLE_LABEL);
              UrlUtils.setUrl(CONDO_IS_FLEXIBLE_LABEL, zero);
            }
            break;
          }
          case condoFlexibleDatePickerActions.resetState.toString(): {
            UrlUtils.removeFromUrl(CONDO_DATES_FLEXIBLE_LABEL);
            UrlUtils.removeFromUrl(CONDO_IS_FLEXIBLE_LABEL);
            UrlUtils.setUrl(CONDO_IS_FLEXIBLE_LABEL, zero);
            break;
          }
          case condoStrictDatesActions.setSearchType.toString(): {
            UrlUtils.removeFromUrl(CONDO_IS_FLEXIBLE_LABEL);
            UrlUtils.setUrl(
              CONDO_IS_FLEXIBLE_LABEL,
              action.payload === DateSearchTypeEnum.Flexible ? one : zero,
            );
            break;
          }
          case condoFlexibleDatePickerActions.setStayFor.toString(): {
            UrlUtils.setUrl(CONDO_DATES_FLEXIBLE_LABEL, {
              ...condoFlexibleDatePickerStore,
              stayFor: action.payload,
            });
            UrlUtils.removeFromUrl(CONDO_IS_FLEXIBLE_LABEL);
            UrlUtils.setUrl(CONDO_IS_FLEXIBLE_LABEL, one);
            UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
            break;
          }

          case hotelsActions.setSelectedHotelSearchClientCash.toString(): {
            UrlUtils.setUrl(CLIENT_CASH_LABEL, action.payload as IClientCash);
            break;
          }

          case condosActions.setSelectedCondoSearchClientCash.toString(): {
            UrlUtils.setUrl(CLIENT_CASH_CONDO_LABEL, action.payload as IClientCash);
            break;
          }
          
          case condoFlexibleDatePickerActions.selectMonths.toString(): {
            let selectedMonths: string[] = condoFlexibleDatePickerStore.selectedMonths.slice();
  
            if (condoFlexibleDatePickerStore.selectedMonths.includes(action.payload as string)) {
              selectedMonths = condoFlexibleDatePickerStore.selectedMonths.filter(
                (month: string) => month !== action.payload,
              );
            } else {
              if (condoFlexibleDatePickerStore.selectedMonths.length < MAX_SELECTED_MONTH_COUNT) {
                selectedMonths.push(action.payload as string);
              }
            }
  
            UrlUtils.setUrl(CONDO_DATES_FLEXIBLE_LABEL, {
              ...condoFlexibleDatePickerStore,
              selectedMonths,
            });
            UrlUtils.removeFromUrl(CONDO_IS_FLEXIBLE_LABEL);
            UrlUtils.setUrl(CONDO_IS_FLEXIBLE_LABEL, one);
            UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
            break;
          }
          default:
            break;
        }
        
        const GUEST_ACTIONS_EXCLUDE = [
          condoGuestsActions.updateAdultsCountExperiences.toString(),
          condoGuestsActions.updateBedroomCountExperiences.toString(),
          condoGuestsActions.updateKidsCountExperiences.toString(),
          condoGuestsActions.updateIncludeStudioExperiences.toString(),
          condoGuestsActions.resetGuest.toString(),
        ];
        if (action.type.includes(CONDO_GUESTS_ACTIONS_LABEL) && !GUEST_ACTIONS_EXCLUDE.includes(action.type)) {
          setTimeout(() => {
            UrlUtils.setUrl(CONDO_GUESTS_LABEL, { ...getState().condoGuestsStore });
          });
        }
      }

      return next(action);
    };
  };
};
