import React from 'react';

import moment from 'moment';
import debounce from 'lodash/debounce';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import setMonth from 'date-fns/setMonth';
import differenceInDays from 'date-fns/differenceInDays';

import { DateRangePicker, OnChangeProps, Range, RangeFocus } from 'react-date-range';
import { FormattedMessage } from 'react-intl';
import { Action } from 'redux';
import { SelectValue } from 'antd/lib/select';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';

import { BlueButton, MonthSelect } from '@share/components';
import { IDatesState, datesActions, ILoginState } from '@share/store/slices';
import { formatDateCheckInOut, getAccountLeadTime, getDateCheckInOut, getPreferenceFromAccount, RootState } from '@share/utils';
import { MonthsEnum, SEARCH_ICONS_COLOR_FIELD_KEY } from '@share/common-types';
import { DATE_FORMAT, DEFAULT_MEDIA_POINT } from '@share/constants';
import { getAccountDateFnsLocale } from '@i18n';

import { CloseSvg, ArrowLeftSvg, ArrowRightSvg } from '@share/assets';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

interface IMapStateToProps {
  datesStore: IDatesState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDatesSelected: (dates: { startDate: Date; endDate: Date }) => void;
  setKey: (key: string) => void;
  clearError: () => void;
  setIsFocused?: (value: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  isRoomsSearch?: boolean;
  isUpdateSearch?: boolean;
  onSelect?: () => void;
}

interface IState {
  isFocused: boolean;
  startDate: Date;
  initialStartDate: Date;
  endDate: Date;
  initialEndDate: Date;
  minDate: Date;
  maxDate: Date;
  key: string;
  isEndDateSelected: boolean;
  focusedRange: RangeFocus;
  isMobile: boolean;
  isInitial: boolean;
  changed: boolean;
}

const oneItem = 1;
const maxDaysRange = 30;
const resizeDebounceTime = 300;
const mobileMonthCount = 12;
const fullMonthCount = 2;
const defaultRange = 0;
const isStart = 0;

export class DatePickerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  isInitial = true;

  constructor(props: IProps) {
    super(props);

    const now = new Date();
    const startDate = props.datesStore.startDate
      ? getDateCheckInOut(props.datesStore.startDate)
      : new Date();
    const endDate = props.datesStore.endDate
      ? getDateCheckInOut(props.datesStore.endDate)
      : new Date();

    this.state = {
      isFocused: false,
      startDate,
      initialStartDate: startDate,
      initialEndDate: endDate,
      endDate,
      minDate: now,
      maxDate: addYears(now, oneItem),
      key: this.props.datesStore.key,
      isEndDateSelected: true,
      focusedRange: [defaultRange, isStart],
      isMobile: document.body.offsetWidth <= DEFAULT_MEDIA_POINT,
      isInitial: true,
      changed: false
    };
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.onResize);
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState {
    const { startDateSelected, endDateSelected } = props.datesStore;

    if (
      state.isInitial &&
      startDateSelected &&
      endDateSelected
    ) {
      const start = getDateCheckInOut(startDateSelected);
      const end = getDateCheckInOut(endDateSelected);

      return {
        ...state,
        startDate: start,
        endDate: end,
        isInitial: false,
      };
    }

    return null;
  }

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true, changed: false });
    }
  };

  blur = (): void => {
    const { isFocused, startDate, endDate, changed } = this.state;

    if (isFocused) {

      if (!this.state.isEndDateSelected && startDate.toISOString() === endDate.toISOString()) {
        this.setState(
          {
            startDate,
            initialStartDate: startDate,
            endDate: addDays(new Date(startDate), oneItem),
            initialEndDate: addDays(new Date(startDate), oneItem),
          },
          this.updateProps,
        );
      }

      this.setState({
        isFocused: false,
        isEndDateSelected: true,
        focusedRange: [defaultRange, isStart],
      });

      if (changed) {
        this.props.onSelect();
      }
    }
  };

  setInitialValues = (): void => {
    const { setDatesSelected } = this.props;
    const { endDate, startDate, initialEndDate, initialStartDate } = this.state;
    const isMoreThenMaxDays = differenceInDays(endDate, startDate) > maxDaysRange;
    const areInitialDatesEqual = initialStartDate.toString() === initialEndDate.toString();
    const equalAfterDateAdd = areInitialDatesEqual
      ? addDays(new Date(startDate), oneItem)
      : initialEndDate;

    if (isMoreThenMaxDays) {
      this.setState({
        startDate: initialStartDate,
        endDate: equalAfterDateAdd,
      });

      setDatesSelected({
        startDate: initialStartDate,
        endDate: equalAfterDateAdd,
      });
    }

    this.blur();
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      (this.wrapperRef && !this.wrapperRef.current.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('date-picker__fixed-bg')
    ) {
      this.setInitialValues();
    }
  };

  onResize = debounce(() => {
    if (document.body.offsetWidth <= DEFAULT_MEDIA_POINT && !this.state.isMobile) {
      const { startDate, endDate } = this.state;
      this.setState({ isMobile: true, startDate: new Date(), endDate: new Date() });

      setTimeout(() => {
        this.setState({ startDate, endDate });
      });
    } else if (document.body.offsetWidth > DEFAULT_MEDIA_POINT && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }, resizeDebounceTime);

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.datesStore.isFocused !== this.props.datesStore.isFocused) {
      this.setState({ isFocused: true });
      this.props.setIsFocused(false);
    }

    if ((!!prevProps.datesStore.endDate && !this.props.datesStore.endDate) ||
        (!!prevProps.datesStore.startDate && !this.props.datesStore.startDate)) {
      this.setState({ startDate: new Date(), endDate: new Date() });
    }
  }

  onDone = (): void => {
    this.setState({ changed: true }, this.blur);
  };

  onSelect = (ranges: OnChangeProps): void => {
    this.props.clearError();
    const range = ranges as { selection: Range };
    this.isInitial = false;

    if (range.selection) {
      const { startDate, endDate } = range.selection;
      const endDateCalculated = 
        (!this.state.isEndDateSelected && startDate.toString() === endDate.toString())?
          addDays(new Date(startDate), oneItem) :
          endDate;
      this.setState(
        {
          startDate,
          endDate: endDateCalculated,
          isInitial: false,
          changed: true,
        },
        this.updateProps,
      );
  }
  };

  updateProps = (): void => {
    const { setDatesSelected } = this.props;

    setDatesSelected({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  onRangeFocusChange = (focusedRange: RangeFocus): void => {
    this.setState({ focusedRange, isEndDateSelected: !focusedRange[oneItem] });
  };

  dayContentRenderer = (date: Date) => {
    const { datesStore } = this.props;
    const { startDate } = this.state;

    const start = new Date(startDate);
    const startMoment = moment(start);
    const dateMoment = moment(date);

    const day = moment(date).date();
    const daysDiff = start && !this.state.isInitial && datesStore.startDateSelected ? dateMoment.diff(startMoment, 'days') : 0;

    if (daysDiff > 0) {
      return (
        <Tooltip placement="top" color="#0d99d6" title={`${daysDiff} night${daysDiff > 1 ? 's' : ''}`}>
          <span>{day}</span>
        </Tooltip>
      );
    }

    return (<span>{day}</span>);
  }

  getNavigatorRender = (
    currentFocusedDate: Date,
    changeShownDate: (count: number | Date, type: string) => void,
  ): React.ReactElement => {
    const today = new Date();

    const { loginStore } = this.props;
    const { account } = loginStore;
    const leadTime = getAccountLeadTime(account);

    return (
      <div onMouseUp={(e) => e.stopPropagation()}>
        <div className="date-picker__arrows">
          <div
            className="date-picker__prev"
            onClick={() => changeShownDate(-oneItem, 'monthOffset')}
          >
            <ArrowLeftSvg />
          </div>
          <div
            className="date-picker__next"
            onClick={() => changeShownDate(+oneItem, 'monthOffset')}
          >
            <ArrowRightSvg />
          </div>
        </div>
        {leadTime > 0 ? (
          <div className="date-picker__lead-time-message">
            <FormattedMessage id="lead.time.message" values={{ leadTime }} />
          </div>) : null}
        <div className="date-picker__months">
          <div className="date-picker__months-left">
            <MonthSelect
              onChange={(val: SelectValue) => changeShownDate(val as number, 'setMonth')}
              value={currentFocusedDate.getMonth()}
              date={currentFocusedDate}
              minMonth={today.getMonth()}
            />
          </div>
          <div className="date-picker__months-right">
            <MonthSelect
              onChange={(val: SelectValue) => {
                if (val === MonthsEnum.January) {
                  changeShownDate(
                    setMonth(subYears(currentFocusedDate, oneItem), MonthsEnum.December),
                    'set',
                  );
                } else {
                  if (currentFocusedDate.getMonth() === MonthsEnum.December) {
                    changeShownDate(
                      setMonth(addYears(currentFocusedDate, oneItem), val as number),
                      'set',
                    );
                  } else {
                    changeShownDate((val as number) - oneItem, 'setMonth');
                  }
                }
              }}
              minMonth={today.getMonth() + oneItem}
              value={addMonths(currentFocusedDate, oneItem).getMonth()}
              date={addMonths(currentFocusedDate, oneItem)}
            />
          </div>
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    const { datesStore, isRoomsSearch, loginStore, isUpdateSearch } = this.props;
    const { error } = datesStore;
    const { account } = loginStore;
    const { isFocused, endDate, startDate, key, focusedRange, isEndDateSelected, isMobile } = this.state;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const selectionRange = {
      startDate: start,
      endDate: end,
      key,
    };
    const hideSelection: boolean = isEndDateSelected && start?.toISOString() === end?.toISOString();
    const focusZIndex = 4;
    const blurZIndex = 1;
    const isMoreThenMaxDays = differenceInDays(end, start) > maxDaysRange;
    const locale = getAccountDateFnsLocale(account);
    const leadTime = getAccountLeadTime(account);

    const color: any = getPreferenceFromAccount(account, SEARCH_ICONS_COLOR_FIELD_KEY);
    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div className={`date-picker ${!!error ? 'error' : ''}`} ref={this.wrapperRef}>
        <div
          className={`
            date-picker__wrapper
            ${isFocused && isEndDateSelected ? 'selected' : ''}
            ${isFocused ? 'open' : ''}
          `}
          onClick={this.focus}
          style={{
            zIndex: isFocused ? focusZIndex : blurZIndex,
          }}
        >
          <div className="date-picker__left">
            <div className="date-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </div>
          <div className="date-picker__right">
            <div className="date-picker__label">
              {isFocused || isRoomsSearch ? (
                <FormattedMessage id="check.in" />
              ) : (
                <>
                  <FormattedMessage id="check.in" /> / <FormattedMessage id="check.out" />
                </>
              )}
            </div>
            <div className="date-picker__header">
              {isFocused || isRoomsSearch ? (
                start && !this.state.isInitial && datesStore.startDateSelected ? (
                  <span className="date-picker__date">
                    {formatDateCheckInOut(datesStore.startDateSelected, DATE_FORMAT, locale)}
                  </span>
                ) : (
                  <FormattedMessage id="select.date" />
                )
              ) : (
                <>
                  {datesStore.startDateSelected && datesStore.endDateSelected && !this.state.isInitial ? (
                    <>
                      <span className="date-picker__date">
                        {formatDateCheckInOut(datesStore.startDateSelected, DATE_FORMAT, locale)} -{' '}
                        {formatDateCheckInOut(datesStore.endDateSelected, DATE_FORMAT, locale)}
                      </span>
                    </>
                  ) : (
                    <FormattedMessage id="add.dates" />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {isRoomsSearch && (
          <div
            className={`date-picker__wrapper right rooms-search-item ${!isEndDateSelected ? 'selected' : ''}`}
            onClick={this.focus}
          >
            <div className="date-picker__left">
              <div className="date-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </div>
            <div className="date-picker__right">
              <div className="date-picker__label">
                <FormattedMessage id="check.out" />
              </div>
              <div className="date-picker__header">
                {end && !this.state.isInitial && isEndDateSelected && datesStore.endDateSelected ? (
                  <span className="date-picker__date">
                    {formatDateCheckInOut(datesStore.endDateSelected, DATE_FORMAT, locale)}
                  </span>
                ) : (
                  <FormattedMessage id="select.date" />
                )}
              </div>
            </div>
          </div>)}
        <div className="date-picker__dropdown" style={{ display: isFocused ? 'block' : 'none' }}>
          <div
            className="date-picker__mobile-header"
            style={{ display: isMobile ? 'block' : 'none' }}
          >
            {isEndDateSelected ? (
              <FormattedMessage id="check.in" />
            ) : (
              <FormattedMessage id="check.out" />
            )}
          </div>

          <div className={`date-picker__wrapper right ${!isEndDateSelected ? 'selected' : ''}`}>
            <div className="date-picker__left">
              <div className="date-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </div>
            <div className="date-picker__right">
              <div className="date-picker__label">
                <FormattedMessage id="check.out" />
              </div>
              <div className="date-picker__header">
                {end && !this.state.isInitial && isEndDateSelected && datesStore.endDateSelected ? (
                  <span className="date-picker__date">
                    {formatDateCheckInOut(datesStore.endDateSelected, DATE_FORMAT, locale)}
                  </span>
                ) : (
                  <FormattedMessage id="select.date" />
                )}
              </div>
            </div>
          </div>
          <div className="date-picker__close-icon" onClick={this.setInitialValues}>
            <CloseSvg />
          </div>
          <div
            className={`date-picker__picker ${hideSelection ? 'hide-selection' : ''} ${isEndDateSelected ? '' : 'show-checkout'}`}
          >
            <div className="date-picker__picker-wrapper">
              <DateRangePicker
                onChange={this.onSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={isMobile ? mobileMonthCount : fullMonthCount}
                monthDisplayFormat="MMMM, yyyy"
                ranges={[selectionRange]}
                locale={locale}
                direction={isMobile ? 'vertical' : 'horizontal'}
                weekStartsOn={0}
                weekdayDisplayFormat="EEEEEE"
                preventSnapRefocus
                minDate={addDays(new Date(), leadTime)}
                maxDate={addYears(new Date(), oneItem)}
                focusedRange={focusedRange}
                onRangeFocusChange={this.onRangeFocusChange}
                dayContentRenderer={this.dayContentRenderer}
                // @ts-ignore
                navigatorRenderer={this.getNavigatorRender}
              />
            </div>
          </div>
          <div className={`date-picker__footer ${isMoreThenMaxDays ? 'right-button' : ''}`}>
            {isMoreThenMaxDays && (
              <div className="date-picker__error-message">
                <FormattedMessage id="maximum.date.range.search" />
              </div>
            )}

            <BlueButton onClick={this.onDone} disabled={isMoreThenMaxDays}>
              {!isUpdateSearch ? (<FormattedMessage id="done" />) : null}
              {isUpdateSearch ? (<FormattedMessage id="search" />) : null}
            </BlueButton>
          </div>
        </div>
        {isFocused && <div className="date-picker__fixed-bg" />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    datesStore: state.datesStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDatesSelected: (dates: { startDate: Date; endDate: Date }) => {
    dispatch(
      datesActions.setDatesSelected({
        startDate: moment(dates.startDate).format('yyyy-MM-DD'),
        endDate: moment(dates.endDate).format('yyyy-MM-DD'),
      }),
    );
  },
  setKey: (key: string) => {
    dispatch(datesActions.setKey(key));
  },
  clearError: () => {
    dispatch(datesActions.setError(''));
  },
  setIsFocused: () => {
    dispatch(datesActions.setIsFocused());
  }
});

export const DatePicker = connect(mapStateToProps, mapDispatchToProps)(DatePickerComponent);
