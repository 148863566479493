import Tripflex from "../assets/images/home/tripflex.jpg";
import Conference from "../assets/images/home/conference.jpg";
import Points from "../assets/images/home/double-points.jpg";
import Stay from "../assets/images/home/extend-stay.jpg";
import invite_team from "../assets/images/home/invite-team.jpg";
import add_card from "../assets/images/home/add-card.jpg";
import approved_hotels from "../assets/images/home/approved-hotels.jpg";

export const Tools = [
    {
      title: "Invite Team Members",
      description:
        "Invite team members to your account. You can control how and where they travel.",
      buttonText: "Send Invitations",
      buttonLink: "#",
      imageSrc: invite_team,
    },
    {
      title: "Add a Payment Method",
      description:
        "Putting a company card on file makes it easy to control how stays are paid.",
      buttonText: "Add a card",
      buttonLink: "#",
      imageSrc: add_card,
    },
    {
      title: "Manage Approved Hotels",
      description:
        "Control which hotels and chains your team members can book!",
      buttonText: "Manage properties",
      buttonLink: "#",
      imageSrc: approved_hotels,
    },
  ];

  export const MoreFeatures = [
    {
      title: "Tripflex",
      description:
        "Tripflex is a new way to travel. It’s the fastest way to book a hotel, flight, and car rental.",
      buttonText: "Learn More",
      buttonLink: "#",
      imageSrc: Tripflex,
    },
    {
      title: "PlanGroupTrip",
      description:
        "Let our experts help your team plan a group trip. Conferences, team building retreats, we do it all!",
      buttonText: "Book a Group ",
      buttonLink: "#",
      imageSrc: Conference,
    },
    {
      title: "Earn 2x Points!",
      description:
        "Earn rewards from both the hotel chains you love, plus points on TravBusiness!",
      buttonText: "View Rewards",
      buttonLink: "#",
      imageSrc: Points,
    },
    {
      title: "Extended Stay",
      description:
        "Plans changed? Add more nights to your booking at the same discount rate!",
      buttonText: "More Information ",
      buttonLink: "#",
      imageSrc: Stay,
    },
  ];