import React from "react";

interface Hotel {
  imgSrc: string;
  location: string;
  hotelName: string;
  price: number;
  rating: number;
}

const HotelCard: React.FC<Hotel> = (props) => {
  const { imgSrc, location, hotelName, price, rating } = props;
  return (
    <div className="col-sm-6 col-xl-3">
      <div className="card card-img-scale overflow-hidden bg-transparent">
        <div className="card-img-scale-wrapper rounded-3">
          <img src={imgSrc} className="card-img" alt="hotel_ImageSection" />
          <div className="position-absolute bottom-0 start-0 p-3">
            <div className="badge text-bg-dark fs-6 rounded-pill stretched-link">
              <i className="bi bi-geo-alt me-2"></i>
              {location}
            </div>
          </div>
        </div>

        <div className="card-body px-2">
          <h5 className="card-title">
            <a href="hotel-detail.html" className="stretched-link">
              {hotelName}
            </a>
          </h5>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-success mb-0">
              ${price} <small className="fw-light">/night</small>{" "}
            </h6>
            <h6 className="mb-0">
              {rating}
              <i className="fa-solid fa-star text-warning ms-1"></i>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
