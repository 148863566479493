import { SorterResult } from 'antd/lib/table/interface';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import {
  IAdminBookings,
  IAdminPriceRules,
  IAdminSuppliers,
  ICondoSuppliers,
  IHotelSuppliers,
  INewPriceRule,
  BookingsOrderEnum,
  IAdminBookingTransactionsLog,
  IAdminBookingDetails,
  INewPresentationRule,
  IAdminPresentationRules,
} from '@common-types';
import { NEGATIVE, ProductsEnum, VALUE_ALL } from '@constants';
import { TAdminHotelBookingsSorter } from '@store/slices';

export type PriceRules = IAdminPriceRules| IAdminPresentationRules;

export const getSiteIds = (
  priceRules: PriceRules[],
  priceRule: INewPriceRule | INewPresentationRule,
  isPresentationRule?: boolean,
): { value: string }[] => {
  if (isPresentationRule) {
    const getAllSiteIds = priceRules
      .map((item) => ({ value: String(item.siteId) }))
      .filter((item) => item.value !== String(NEGATIVE));

    const uniqSiteIds = uniqWith(getAllSiteIds, isEqual);

    return [{ value: VALUE_ALL }, ...uniqSiteIds];
  } else {
    const getAllSiteIds = priceRules
      .filter((item) => item?.productType === priceRule?.productType)
      .map((item) => {
        return { value: String(item.siteId) };
      })
      .filter((item) => item.value !== String(NEGATIVE));

    const uniqSiteIds = uniqWith(getAllSiteIds, isEqual);

    return [{ value: VALUE_ALL }, ...uniqSiteIds];
  }
};

export const getCurrentSuppliers = (
  productType: string,
  adminSuppliers: IAdminSuppliers,
): string[] => {
  if (productType === ProductsEnum.Condo) {
    return adminSuppliers.condoSuppliers.map((item: ICondoSuppliers) => {
      return item.supplierName;
    });
  } else {
    return adminSuppliers.hotelSuppliers
      .filter((item) => !item.isPublicPriceSupplier)
      .map((item: IHotelSuppliers) => {
        return item.supplierName;
      });
  }
};

const sortMap = {
  bookedOn: { ascend: BookingsOrderEnum.BookedOnAsc, descend: BookingsOrderEnum.BookedOnDesc },
  totalFare: { ascend: BookingsOrderEnum.TotalFareAsc, descend: BookingsOrderEnum.TotalFareDesc },
  supplierType: { ascend: BookingsOrderEnum.SupplierAsc, descend: BookingsOrderEnum.SupplierDesc },
  condoName: { ascend: BookingsOrderEnum.CondoNameAsc, descend: BookingsOrderEnum.CondoNameDesc },
  address: {
    ascend: BookingsOrderEnum.PropertyAddressAsc,
    descend: BookingsOrderEnum.PropertyAddressDesc,
  },
  city: { ascend: BookingsOrderEnum.PropertyCityAsc, descend: BookingsOrderEnum.PropertyCityDesc },
  country: {
    ascend: BookingsOrderEnum.PropertyCountryAsc,
    descend: BookingsOrderEnum.PropertyCountryDesc,
  },
  region: { ascend: BookingsOrderEnum.RegionAsc, descend: BookingsOrderEnum.RegionDesc },
  'unit.max': { ascend: BookingsOrderEnum.MaxSleepsAsc, descend: BookingsOrderEnum.MaxSleepsDesc },
  'unit.bedroom': { ascend: BookingsOrderEnum.BedroomAsc, descend: BookingsOrderEnum.BedroomDesc },
  'unit.bathroom': {
    ascend: BookingsOrderEnum.BathroomTypeAsc,
    descend: BookingsOrderEnum.BathroomTypeDesc,
  },
  'bookingPrice.supplier': {
    ascend: BookingsOrderEnum.SupplierPriceAsc,
    descend: BookingsOrderEnum.SupplierPriceDesc,
  },
  'bookingPrice.supplierTaxes': {
    ascend: BookingsOrderEnum.SupplierTaxesAsc,
    descend: BookingsOrderEnum.SupplierTaxesDesc,
  },
  'bookingPrice.publicPrice': {
    ascend: BookingsOrderEnum.PublicPriceAsc,
    descend: BookingsOrderEnum.PublicPriceDesc,
  },
  markup: { ascend: BookingsOrderEnum.MarkupAsc, descend: BookingsOrderEnum.MarkupDesc },
  'bookingPrice.savings': {
    ascend: BookingsOrderEnum.SavingsAsc,
    descend: BookingsOrderEnum.SavingsDesc,
  },
  'bookingPrice.discount': {
    ascend: BookingsOrderEnum.DiscountAsc,
    descend: BookingsOrderEnum.DiscountDesc,
  },
  'bookingPrice.profit': {
    ascend: BookingsOrderEnum.ProfitAsc,
    descend: BookingsOrderEnum.ProfitDesc,
  },
  'bookingPrice.total': {
    ascend: BookingsOrderEnum.TotalPaidAsc,
    descend: BookingsOrderEnum.TotalPaidDesc,
  },
  supplierId: {
    ascend: BookingsOrderEnum.ConfirmationNumberAsc,
    descend: BookingsOrderEnum.ConfirmationNumberDesc,
  },
  checkIn: { ascend: BookingsOrderEnum.CheckinAsc, descend: BookingsOrderEnum.CheckinDesc },
  checkOut: { ascend: BookingsOrderEnum.CheckoutAsc, descend: BookingsOrderEnum.CheckoutDesc },
  'unit.private': {
    ascend: BookingsOrderEnum.PrivacyOccupancyAsc,
    descend: BookingsOrderEnum.PrivacyOccupancyDesc,
  },

  orderId: { ascend: BookingsOrderEnum.OrderIdAsc, descend: BookingsOrderEnum.OrderIdDesc },
  'member.id': { ascend: BookingsOrderEnum.MemberIdAsc, descend: BookingsOrderEnum.MemberIdDesc },
  'member.siteId': {
    ascend: BookingsOrderEnum.MemberSiteIdAsc,
    descend: BookingsOrderEnum.MemberSiteIdDesc,
  },
  'member.name': {
    ascend: BookingsOrderEnum.MemberNameAsc,
    descend: BookingsOrderEnum.MemberNameDesc,
  },
  status: { ascend: BookingsOrderEnum.StatusAsc, descend: BookingsOrderEnum.StatusDesc },
  'guest.name': {
    ascend: BookingsOrderEnum.GuestNameAsc,
    descend: BookingsOrderEnum.GuestNameDesc,
  },
};

export const getSortOrder = (sort: TAdminHotelBookingsSorter): BookingsOrderEnum => {
  if (sort && sort.columnKey && sort.order) {
    // @ts-ignore
    if (sortMap[sort.columnKey]) {
      // @ts-ignore
      return sortMap[sort.columnKey][sort.order];
    }
  }

  return null;
};

export const getLastTransaction = (
  data: IAdminBookingTransactionsLog[],
): IAdminBookingTransactionsLog => {
  return data.reduce((mostRecent, item) =>
    item.transactionDate > mostRecent.transactionDate ? item : mostRecent,
  );
};

export const getAuthorizeTransaction = (
  details: IAdminBookingDetails,
): IAdminBookingTransactionsLog[] => {
  return details.transactionsLog.filter((item) => item.authCode);
};

