import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from '@share/utils';
import { 
  getUsers,
  deleteAccountUser,
  activateAccountUser,
  setAccountUsersPagination,
  setAccountUsersSorting,
  setAccountUsersRetrieveDeleted,
  IUsersState,
  TableSortingConfig
} from '@store/slices';
import { IUser } from '@share/common-types';
import { RefreshSvg } from '@assets';
import { PlusSvg } from '@share/assets';
import { isEmpty } from 'lodash';
import { BlueButton } from '@share/components';
import { ModalConfirmation } from '@components';
import { getAccountIdFromAdminPath } from '@share/utils';
import { UsersSearch } from '..';

import './style.scss';

interface IMapStateToProps {
  accountUsersStore: IUsersState;
}

interface IMapDispatchToProps {
  getUsers: (accountId: number) => void;
  deleteAccountUser: (accountId: number, userId: string) => void;
  activateAccountUser: (accountId: number, userId: string) => void;
  setAccountUsersPagination: (pagination: TablePaginationConfig) => void;
  setAccountUsersSorting: (sorting: TableSortingConfig) => void;
  setAccountUsersRetrieveDeleted: (retrieve: boolean) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {
  onUserSelected: (userId?: string) => void;
}

interface IState {
  userToDeleteActivate: string;
  showDeleteActivateConfirmation: boolean;
  isDeleteAction: boolean;
}

const zero = 0;
const one = 1;
const pageSizeOptions = ['10', '20', '50'];

class AllAccountUsersComponent extends React.Component<IProps, IState> {
  state: IState = {
    showDeleteActivateConfirmation: false,
    userToDeleteActivate: null,
    isDeleteAction: null
  };

  componentDidMount(): void {
    const accountId = getAccountIdFromAdminPath(this.props.history);
    this.props.getUsers(accountId);
  }

  getColumns = (): ColumnsType<IUser> => {
    return [
      {
        title: (
          <span>
            <FormattedMessage id="keyid" /> #
          </span>
        ),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => (
          <div className="search-account-users__link" onClick={() => this.props.onUserSelected(id)}>
            {id}
          </div>
        ),
        width: '10%',
        sorter: (a, b) => a.userId.localeCompare(b.userId)
      },
      {
        title: (
          <span>
            <FormattedMessage id="username" />
          </span>
        ),
        dataIndex: 'username',
        key: 'username',
        render: (username: string) => (
          <div>{username}</div>
        ),
        width: '20%',
        sorter: (a, b) => a.username.localeCompare(b.username)
      },
      {
        title: (
          <span>
            <FormattedMessage id="email" />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        render: (email: string) => (
          <div>{email}</div>
        ),
        width: '20%',
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: <FormattedMessage id="action" />,
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) => {
          if (record?.active === 0) {
            return (
              <div className="search-account-users__link">
                <div className="search-account-users__delete" onClick={() => this.handleActivate(record.userId)}>
                  <FormattedMessage id="activate" />
                </div>
              </div>
            );  
          }
          return (
            <div className="search-account-users__link">
              <div className="search-account-users__delete" onClick={() => this.handleDelete(record.userId)}>
                <FormattedMessage id="delete" />
              </div>
            </div>
          );
        },
        width: '10%',
      },
    ];
  };

  getTotalTitle = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span>
        {range[zero]}-{range[one]} <FormattedMessage id="of" />{' '}
        <FormattedMessage id="items.count" values={{ count: total }} />
      </span>
    );
  };

  onTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any): void => {
    if (!isEmpty(sorter)) {
      const sorting = {
        key: sorter.field,
        type: sorter.order
      };
      this.props.setAccountUsersSorting(sorting);
    } else {
      this.props.setAccountUsersSorting(null);
    }   
    this.props.setAccountUsersPagination(pagination);
    const accountId = getAccountIdFromAdminPath(this.props.history);
    this.props.getUsers(accountId);
  };

  onRefresh = (): void => {
    const accountId = getAccountIdFromAdminPath(this.props.history);
    this.props.getUsers(accountId);
  };

  handleDelete = (userToDeleteActivate: string) => {
    this.setState({
      showDeleteActivateConfirmation: true,
      isDeleteAction: true,
      userToDeleteActivate  
    });
  }

  handleActivate = (userToDeleteActivate: string) => {
    this.setState({
      showDeleteActivateConfirmation: true,
      isDeleteAction: false,
      userToDeleteActivate  
    });
  }

  handleCancelModal = () => {
    this.setState({
      showDeleteActivateConfirmation: false,
      isDeleteAction: null,
      userToDeleteActivate: null 
    });
  }
  handleConfirmModal = () => {
    const accountId = getAccountIdFromAdminPath(this.props.history);
    const { userToDeleteActivate } = this.state;
    if (this.state.isDeleteAction) {
      this.props.deleteAccountUser(accountId, userToDeleteActivate);
    } else {
      this.props.activateAccountUser(accountId, userToDeleteActivate);
    }

    this.setState({
      showDeleteActivateConfirmation: false,
      isDeleteAction: null,
      userToDeleteActivate: null 
    });
  }

  handleDisplayDeletedChange = () => {
    const { accountUsersStore } = this.props;
    const { retrieveDeleted } = accountUsersStore;
    this.props.setAccountUsersRetrieveDeleted(!retrieveDeleted);
    const accountId = getAccountIdFromAdminPath(this.props.history);
    this.props.getUsers(accountId);
  }

  render(): React.ReactNode {
    const { accountUsersStore } = this.props;
    const { users, pagination, loading, retrieveDeleted } = accountUsersStore;
    const { isDeleteAction } = this.state;
    const accountId = getAccountIdFromAdminPath(this.props.history);

    return (
      <div className="search-account-users">
        <div className="search-account-users__wrapper">
          <div className="search-account-users__header">
            <div className="search-account-users__actions">
              <div className="search-account-users__search">
                <UsersSearch accountId={accountId} />
              </div>
              <div className="search-account-users__buttons">
                <BlueButton onClick={() => this.props.onUserSelected(null)}>
                  <PlusSvg />
                  <FormattedMessage id="add.new" />
                </BlueButton>

                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={this.handleDisplayDeletedChange}
                    checked={retrieveDeleted}
                    id="flexSwitchCheckDefault"/>
                  <label 
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault">
                      Show Deleted
                  </label>
                </div> 

                <div onClick={this.onRefresh}>
                  <RefreshSvg />
                </div>
              </div>
            </div>
          </div>
          <div className="search-account-users__table">
            <Table
              loading={loading}
              onChange={this.onTableChange}
              rowKey="id"
              columns={this.getColumns()}
              dataSource={users}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                pageSizeOptions,
                total: pagination.total,
                showTotal: this.getTotalTitle,
              }}
            />
          </div>
        </div>
        <ModalConfirmation
            message={isDeleteAction ? 'Are you sure you want to Delete this account?' : 'Are you sure you want to Activate this account?'}
            title={isDeleteAction ? 'Confirm Delete' : 'Confirm Activate'}
            yesMessage="Confirm"
            noMessage="Cancel"
            visible={this.state.showDeleteActivateConfirmation}
            onCancel={this.handleCancelModal}
            onConfirm={this.handleConfirmModal}
          />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    accountUsersStore: state.accountUsersStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getUsers,
  deleteAccountUser,
  activateAccountUser,
  setAccountUsersRetrieveDeleted,
  setAccountUsersPagination,
  setAccountUsersSorting
};

const ComponentWithIntl = injectIntl(AllAccountUsersComponent);

export const AllAccountUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComponentWithIntl));
