import React from 'react';
import img1 from '@assets/images/home/example-travelmanagers.png';
import img2 from '@assets/images/home/example-operations.png';
import img3 from '@assets/images/home/example-finance.png';
import img4 from '@assets/images/home/example-groups.png';
import TabContent from './tab-content';

const tabs = [
  {
    id: 'travel_managers',
    target: 'travel-managers',
    title: 'Travel Managers',
    content:
      'Our suite of time-saving tools transform travel management, offering streamlined billing, effortless budget control with customizable settings, easy group trip coordination, and more.',
    imgSrc: img1,
  },
  {
    id: 'operations_teams',
    target: 'operations-teams',
    title: 'Operations Teams',
    content:
      'Enjoy exclusive rates, top-tier loyalty rewards, flexible booking options, and award-winning 24/7 support for a seamless travel experience.',
    imgSrc: img2,
  },
  {
    id: 'finance_managers',
    target: 'finance-managers',
    title: 'Finance Managers',
    content:
      'Enjoy exclusive rates, top-tier loyalty rewards, flexible booking options, and award-winning 24/7 support for a seamless travel experience.',
    imgSrc: img3,
  },
  {
    id: 'corporate_groups',
    target: 'corporate-groups',
    title: 'Corporate Groups',
    content:
      'Enjoy exclusive rates, top-tier loyalty rewards, flexible booking options, and award-winning 24/7 support for a seamless travel experience.',
    imgSrc: img4,
  },
];

const SolutionTabs = () => {
  return (
    <>
      <section className="pt-5 mt-5">
        <div className="container">
          <h3 className="mb-3 mb-lg-5 text-center">A travel solution for every business need</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul
                className="nav nav-pills nav-justified nav-responsive bg-primary bg-opacity-10 rounded p-2"
                id="solutions-pills-tab"
                role="tablist"
              >
                {tabs?.map((tab, index) => (
                  <li className="nav-item" role="presentation" key={tab.id}>
                    <button
                      className={`nav-link ${index === 0 ? 'active' : ''} mb-0`}
                      id={tab.id}
                      data-bs-toggle="pill"
                      data-bs-target={`#${tab.target}`}
                      type="button"
                      role="tab"
                      aria-controls={tab.target}
                      aria-selected={index === 0 ? 'true' : 'false'}
                    >
                      {tab.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pt-md-2">
        <div className="container">
          <div className="row g-4 g-md-5">
            <div className="col-xl-12 mt-4">
              <div className="tab-content" id="solutions-pills-tabContent">
                {tabs?.map((tab, index) => (
                  <div
                    className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                    id={tab.target}
                    role="tabpanel"
                    aria-labelledby={tab.target}
                    key={tab.id}
                  >
                    <TabContent title={tab.title} content={tab.content} imgSrc={tab.imgSrc} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { SolutionTabs };
