import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import capitalize from 'lodash/capitalize';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import {
  IAdminHotelBookings,
  IFilterDateRange,
  IAdminBookingsHotelMember,
  IAdminBookingHotelGuest,
  IAdminBookingsHotelSupplier,
  IAdminHotelBookingPrice,
  HotelBookingStatusEnum,
  IAdminBookingsHotelRoom,
  IAddress,
} from '@common-types';
import { DATE_FORMAT_FOR_BOOKINGS, TIME_FORMAT } from '@constants';
import { CheckboxTableFilter, DateRangeTableFilter } from '@components';
import { TAdminHotelBookingsSorter } from '@store/slices';
import { formatDateCheckInOut, renderNumber } from '@share/utils';
import { InfoSvg } from '@assets';
import { BookingsStatus } from '../../../booking-status';
import { Routes } from '@share/constants';

const emptyData = '-';

type TColumnData = {
  sort: TAdminHotelBookingsSorter;
  checkOutDateRangeFilter: IFilterDateRange;
  checkInDateRangeFilter: IFilterDateRange;
  bookedOnDateRangeFilter: IFilterDateRange;
  supplierFilters: string[];
  statusesFilters: string[];
};

export const getAllColumns = ({
  sort,
  checkInDateRangeFilter,
  checkOutDateRangeFilter,
  bookedOnDateRangeFilter,
  supplierFilters,
  statusesFilters,
}: TColumnData): ColumnsType<IAdminHotelBookings> => {
  return [
    {
      title: (
        <span>
          <FormattedMessage id="order" /> #
        </span>
      ),
      sorter: true,
      sortOrder: sort?.columnKey === 'orderId' ? sort.order : null,
      width: 146,
      dataIndex: 'orderId',
      key: 'orderId',
      render(_, order) {
        return (
          <div className="all-hotel-bookings__link">
            <Link to={`${Routes.AdminHotelBookingDetails}/${order.orderId}`}>{order.orderId}</Link>
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="member.siteId" />,
      dataIndex: 'member',
      key: 'member.siteId',
      width: 150,
      sorter: true,
      sortOrder: sort?.columnKey === 'member.siteId' ? sort.order : null,
      render(member: IAdminBookingsHotelMember) {
        return member ? <div className="bold-text">{member.siteId}</div> : emptyData;
      },
    },
    {
      title: <FormattedMessage id="member.id" />,
      dataIndex: 'member',
      key: 'member.id',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'member.id' ? sort.order : null,
      render(member: IAdminBookingsHotelMember) {
        return member ? <div className="bold-text">{member.id}</div> : emptyData;
      },
    },
    {
      title: <FormattedMessage id="substatus" />,
      render(_, booking: IAdminHotelBookings) {
        return (
          <div className="all-bookings__sub-status-column">
            {booking?.subStatus}
            {getFailedInfoMessage({
              bookingStatus: booking?.status,
              subStatus: booking?.subStatus,
            })}
          </div>
        );
      },
      dataIndex: 'subStatuses',
      key: 'subStatuses',
      width: 166,
    },
    {
      title: <FormattedMessage id="member.name" />,
      dataIndex: 'member',
      key: 'member.name',
      width: 200,
      sorter: true,
      sortOrder: sort?.columnKey === 'member.name' ? sort.order : null,
      render(member: IAdminBookingsHotelMember) {
        return member ? (
          <Tooltip title={`${member?.name} ${member?.surname}`} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">
              {member?.name} {member?.surname}
            </div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="status" />,
      dataIndex: 'status',
      key: 'status',
      width: 216,
      filterDropdown(props: FilterDropdownProps) {
        return (
          <CheckboxTableFilter
            {...props}
            options={[
              {
                text: <FormattedMessage id="inProgress" />,
                value: capitalize(HotelBookingStatusEnum.InProgress),
              },
              {
                text: <FormattedMessage id="confirmed" />,
                value: capitalize(HotelBookingStatusEnum.Confirmed),
              },
              {
                text: <FormattedMessage id="cancelled" />,
                value: capitalize(HotelBookingStatusEnum.Cancelled),
              },
              {
                text: <FormattedMessage id="completed" />,
                value: capitalize(HotelBookingStatusEnum.Completed),
              },
              {
                text: <FormattedMessage id="failed" />,
                value: capitalize(HotelBookingStatusEnum.Failed),
              },
            ]}
          />
        );
      },
      filteredValue: statusesFilters && statusesFilters.length ? statusesFilters : null,
      filtered: !!(statusesFilters && statusesFilters.length),
      sorter: true,
      sortOrder: sort?.columnKey === 'status' ? sort.order : null,
      render(status: HotelBookingStatusEnum, booking: IAdminHotelBookings) {
        return (
          <div className="all-hotel-bookings__status-column">
            <BookingsStatus status={status} subStatus={booking?.subStatus} />
            {getFailedInfoMessage({
              bookingStatus: booking?.status,
              subStatus: booking?.subStatus,
            })}
          </div>
        );
      },
    },

    {
      title: <FormattedMessage id="check.in" />,
      dataIndex: 'checkIn',
      key: 'checkIn',
      sorter: true,
      sortOrder: sort?.columnKey === 'checkIn' ? sort.order : null,
      render(checkIn: string) {
        return (
          <div className="all-hotel-bookings__date-time">
            <div className="all-hotel-bookings__date-time-date">
              {formatDateCheckInOut(checkIn, DATE_FORMAT_FOR_BOOKINGS)}
            </div>
          </div>
        );
      },
      width: 150,
      filtered: !!checkInDateRangeFilter,
      filteredValue: checkInDateRangeFilter
        ? [`${checkInDateRangeFilter?.from}~${checkInDateRangeFilter?.to}`]
        : null,
      filterDropdown(props: FilterDropdownProps) {
        return <DateRangeTableFilter {...props} dateRangeFilter={checkInDateRangeFilter} />;
      },
    },
    {
      title: <FormattedMessage id="check.out" />,
      dataIndex: 'checkOut',
      key: 'checkOut',
      sorter: true,
      sortOrder: sort?.columnKey === 'checkOut' ? sort.order : null,
      render(checkOut: string) {
        return (
          <div className="all-hotel-bookings__date-time">
            <div className="all-hotel-bookings__date-time-date">
              {formatDateCheckInOut(checkOut, DATE_FORMAT_FOR_BOOKINGS)}
            </div>
          </div>
        );
      },
      width: 150,
      filtered: !!checkOutDateRangeFilter,
      filteredValue: checkOutDateRangeFilter
        ? [`${checkOutDateRangeFilter?.from}~${checkOutDateRangeFilter?.to}`]
        : null,
      filterDropdown(props: FilterDropdownProps) {
        return <DateRangeTableFilter {...props} dateRangeFilter={checkOutDateRangeFilter} />;
      },
    },
    {
      title: <FormattedMessage id="guest.name" />,
      dataIndex: 'guest',
      key: 'guest.name',
      width: 200,
      sorter: true,
      sortOrder: sort?.columnKey === 'guest.name' ? sort.order : null,
      render(guest: IAdminBookingHotelGuest) {
        return guest ? (
          <Tooltip title={`${guest.givenName}, ${guest.surname}`} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">
              {guest.givenName} {guest.surname}
            </div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="booked.on" />,
      dataIndex: 'bookedOn',
      key: 'bookedOn',
      render(date: string, order: IAdminHotelBookings) {
        return (
          <div className="all-hotel-bookings__date-time">
            <div className="all-hotel-bookings__date-time-date">
              {formatDateCheckInOut(date ? date : order.bookedOn, DATE_FORMAT_FOR_BOOKINGS)}
            </div>
            <div className="all-hotel-bookings__date-time-time">
              {format(new Date(date ? date : order.bookedOn), TIME_FORMAT)}
            </div>
          </div>
        );
      },
      width: 150,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookedOn' ? sort.order : null,
      filtered: !!bookedOnDateRangeFilter,
      filteredValue: bookedOnDateRangeFilter
        ? [`${bookedOnDateRangeFilter?.from}~${bookedOnDateRangeFilter?.to}`]
        : null,
      filterDropdown(props: FilterDropdownProps) {
        return <DateRangeTableFilter {...props} dateRangeFilter={bookedOnDateRangeFilter} />;
      },
    },
    {
      showSorterTooltip: false,
      title: (
        <Tooltip title={<FormattedMessage id="provider.orderId.tooltip" />} placement="topLeft">
          <div className="all-hotel-bookings__table-header-tooltip">
            <span>
              <FormattedMessage id="provider.orderId" />
            </span>
            <InfoSvg />
          </div>
        </Tooltip>
      ),
      width: 200,
      dataIndex: 'providerOrderId',
      key: 'providerOrderId',
      render(_, order) {
        return order ? (
          <Tooltip title={order.providerOrderId} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">{order.providerOrderId}</div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="supplier" />,
      dataIndex: 'supplier',
      key: 'supplier',
      width: 130,
      sorter: true,
      filteredValue: supplierFilters && supplierFilters.length ? supplierFilters : null,
      filtered: !!(supplierFilters && supplierFilters.length),
      sortOrder: sort?.columnKey === 'supplier' ? sort.order : null,
      filterDropdown(props: FilterDropdownProps) {
        return (
          <CheckboxTableFilter
            {...props}
            options={[
              { text: 'EXPEDIA', value: 'expedia' },
              { text: 'EXPEDIA (EPS)', value: 'expedia (eps)' },
              { text: 'DOTW', value: 'dotw' },
              { text: 'BONOTEL', value: 'bonotel' },
              { text: 'HOTELBEDS', value: 'hotelbeds' },
              { text: 'PRICELINEUEP', value: 'pricelineuep' },
            ]}
          />
        );
      },
      render(supplier: IAdminBookingsHotelSupplier) {
        return (
          <div style={{ textTransform: 'uppercase' }}>
            {supplier?.name ? supplier?.name : emptyData}
          </div>
        );
      },
    },
    {
      title: (
        <span>
          <FormattedMessage id="supplier.confirmation" /> #
        </span>
      ),
      width: 230,
      dataIndex: 'supplierConfirmationCode',
      key: 'supplierConfirmationCode',
      render(_, order) {
        return order?.supplier?.confirmationCode ? (
          <Tooltip title={order?.supplier?.confirmationCode} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">
              {order?.supplier?.confirmationCode}
            </div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="hotel.name" />,
      dataIndex: 'hotelName',
      key: 'hotelName',
      width: 380,
      sorter: true,
      sortOrder: sort?.columnKey === 'hotelName' ? sort.order : null,
      render(hotelName: string) {
        return hotelName ? (
          <Tooltip title={hotelName} placement="topLeft">
            <div className="all-hotel-bookings__cut-content bold-text">{hotelName}</div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="property.address" />,
      dataIndex: 'propertyAddress',
      key: 'propertyAddress.address',
      width: 220,
      sorter: true,
      sortOrder: sort?.columnKey === 'propertyAddress.address' ? sort.order : null,
      render(propertyAddress: IAddress) {
        return propertyAddress?.address ? (
          <Tooltip title={propertyAddress?.address} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">{propertyAddress?.address}</div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="property.city" />,
      dataIndex: 'propertyAddress',
      key: 'propertyAddress.cityName',
      width: 160,
      sorter: true,
      sortOrder: sort?.columnKey === 'propertyAddress.cityName' ? sort.order : null,
      render(propertyAddress: IAddress) {
        return propertyAddress?.cityName ? (
          <Tooltip title={propertyAddress?.cityName} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">{propertyAddress?.cityName}</div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: <FormattedMessage id="property.country" />,
      dataIndex: 'propertyAddress',
      key: 'propertyAddress.countryName',
      width: 160,
      sorter: true,
      sortOrder: sort?.columnKey === 'propertyAddress.countryName' ? sort.order : null,
      render(propertyAddress: IAddress) {
        return propertyAddress?.countryName ? (
          <Tooltip title={propertyAddress?.countryName} placement="topLeft">
            <div className="all-hotel-bookings__cut-content">{propertyAddress?.countryName}</div>
          </Tooltip>
        ) : (
          emptyData
        );
      },
    },
    {
      title: (
        <>
          <FormattedMessage id="rooms" /> #
        </>
      ),
      dataIndex: 'roomsCount',
      key: 'roomsCount',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'roomsCount' ? sort.order : null,
      render(roomsCount: number) {
        return <div>{roomsCount ? roomsCount : emptyData}</div>;
      },
    },
    {
      title: <FormattedMessage id="room.name" />,
      dataIndex: 'room',
      key: 'room.name',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'room.name' ? sort.order : null,
      render(room: IAdminBookingsHotelRoom) {
        return <div>{room?.name ? room.name : emptyData}</div>;
      },
    },
    {
      title: <FormattedMessage id="room.basis" />,
      dataIndex: 'room',
      key: 'room.basis',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'room.basis' ? sort.order : null,
      render(room: IAdminBookingsHotelRoom) {
        return <div>{room?.basis ? room.basis : emptyData}</div>;
      },
    },
    {
      title: <FormattedMessage id="public.price.without.colon" />,
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.publicPrice',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.publicPrice' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div>
            {bookingPrice?.publicPrice ? `\$${renderNumber(bookingPrice?.publicPrice)}` : emptyData}
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="supplier.price" />,
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.totalSupplierPrice',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.totalSupplierPrice' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div>
            {bookingPrice?.totalSupplierPrice
              ? `\$${renderNumber(bookingPrice?.totalSupplierPrice)}`
              : emptyData}
          </div>
        );
      },
    },
    {
      showSorterTooltip: false,
      title: (
        <Tooltip title={<FormattedMessage id="markup.tooltip" />} placement="topLeft">
          <div className="all-hotel-bookings__table-header-tooltip">
            <span>
              <FormattedMessage id="markup" />
            </span>
            <InfoSvg />
          </div>
        </Tooltip>
      ),
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.markup',
      width: 110,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.markup' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div>{bookingPrice?.markup ? `\$${renderNumber(bookingPrice?.markup)}` : emptyData}</div>
        );
      },
    },
    {
      title: <FormattedMessage id="savings" />,
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.savings',
      width: 140,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.savings' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div>
            {bookingPrice?.savings ? `\$${renderNumber(bookingPrice?.savings)}` : emptyData}
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="discount" />,
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.couponAmount',
      width: 110,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.couponAmount' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div>
            {bookingPrice?.couponAmount
              ? `\$${renderNumber(bookingPrice?.couponAmount)}`
              : emptyData}
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="profit" />,
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.totalProfitAmount',
      width: 110,
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.totalProfitAmount' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div>
            {bookingPrice?.totalProfitAmount
              ? `\$${renderNumber(bookingPrice?.totalProfitAmount)}`
              : emptyData}
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="total.paid" />,
      dataIndex: 'bookingPrice',
      key: 'bookingPrice.totalNetPrice',
      width: 120,
      fixed: 'right',
      sorter: true,
      sortOrder: sort?.columnKey === 'bookingPrice.totalNetPrice' ? sort.order : null,
      render(bookingPrice: IAdminHotelBookingPrice) {
        return (
          <div className="bold-text">
            {bookingPrice?.payNowPrice ? `\$${renderNumber(bookingPrice?.payNowPrice)}` : emptyData}
          </div>
        );
      },
    },
  ];
};

type TStatusesData = {
  bookingStatus: HotelBookingStatusEnum;
  subStatus: string;
};

const getFailedInfoMessage = ({ bookingStatus, subStatus }: TStatusesData): React.ReactNode => {
  const isBookUnknown =
    subStatus?.toLowerCase() === 'book' && bookingStatus?.toLowerCase() === 'unknown';
  const isCaptureUnknown =
    subStatus?.toLowerCase() === 'capture' && bookingStatus?.toLowerCase() === 'unknown';
  const isFailed = bookingStatus === HotelBookingStatusEnum.Failed;

  if (isFailed && (isBookUnknown || isCaptureUnknown)) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id={
              isBookUnknown
                ? 'information.about.order.status.was.not.received'
                : 'please.check.in.on.details.in.the.payment.system'
            }
          />
        }
        placement="top"
      >
        <div className="bookings-title-wrapper">
          <InfoSvg />
        </div>
      </Tooltip>
    );
  }

  return null;
};
