
import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';

import LifeStyleValue from '@assets/images/lifestyles-value.png';
import LifeStyleChoice from '@assets/images/lifestyles-choice.png';
import LifeStyleUnlimited from '@assets/images/lifestyles-unlimited.png';
import LifeStyle from '@assets/images/lifestyles.png';

import { LifeStylePanel } from './lifestyle-panel';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {}

function LifeStyleMessageComponent(props: IProps) {
  const { loginStore } = props;
  const { account, lifeStyle } = loginStore;

  if (!account?.isCapitalVacations || account?.isCapitalVacationsCondoRes) {
    return null;
  }

  const logo = lifeStyle?.toLocaleLowerCase() === 'value' ?
                  LifeStyleValue :
                  lifeStyle?.toLocaleLowerCase() === 'choice' ?
                    LifeStyleChoice :
                    lifeStyle?.toLocaleLowerCase() === 'unlimited' ?
                      LifeStyleUnlimited :
                      LifeStyle;

  return (
    <div className="lifestyle-message">
      <div className="lifestyle-message__content">
        <img width="250" src={logo} />
        <label className="lifestyle-message__title"><FormattedMessage id="lifestyle.message.title" /></label>
        <label className="lifestyle-message__message"><FormattedMessage id="lifestyle.message.message" /></label>
        <LifeStylePanel title="lifestyle.message.price_guaranty.title" message="lifestyle.message.price_guaranty.message" />
        <LifeStylePanel title="lifestyle.message.policy.title" message="lifestyle.message.policy.message" />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const LifeStyleMessage = connect(mapStateToProps)(withRouter(LifeStyleMessageComponent));
