export interface IAdminPriceRules {
  id: number;
  isActive: boolean;
  margin: number;
  marginType: string;
  name: string;
  supplier: string;
  siteId: number;
  productType: string;
  updateDate: string;
}

export interface INewPriceRule {
  id?: number;
  productType: string;
  name: string;
  supplier: string;
  siteId: number | string;
  marginType: string;
  margin: number;
  isActive: boolean;
}

export interface ISavingNewPriseRule {
  status: string;
  id: number;
}

export interface IAdminSuppliers {
  condoSuppliers: ICondoSuppliers[];
  hotelSuppliers: IHotelSuppliers[];
}

export interface ICondoSuppliers {
  supplierName: string;
  displayName: string;
}

export interface IHotelSuppliers {
  contractId: number;
  displayName: string;
  imagePath?: string;
  imageUrl?: string;
  isPublicPriceSupplier: boolean;
  supplierName: string;
}

export enum BreadcrumbEnum {
  'price-rules' = '/admin-page/business-rules/price-rules',
  'new-rule' = '/admin-page/business-rules/price-rules/new-rule',
  'presentation-rules' = '/admin-page/business-rules/presentation-rules',
  'new-presentation-rule' = '/admin-page/business-rules/presentation-rules/new-rule',
  'all-bookings' = '/admin-page/all-bookings',
}
