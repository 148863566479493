import React from 'react';

export const ExtraChargeSvg: React.FunctionComponent = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill="#EFF2F7" />
    <path
      d="M11.4675 6.90059C11.363 6.60409 11.1722 6.34564 10.9196 6.15852C10.6669 5.97139 10.3641 5.86415 10.05 5.85059H7.95002C7.5323 5.85059 7.13169 6.01652 6.83632 6.31189C6.54095 6.60726 6.37502 7.00787 6.37502 7.42559C6.37502 7.8433 6.54095 8.24391 6.83632 8.53928C7.13169 8.83465 7.5323 9.00059 7.95002 9.00059H10.05C10.4677 9.00059 10.8683 9.16652 11.1637 9.46189C11.4591 9.75726 11.625 10.1579 11.625 10.5756C11.625 10.9933 11.4591 11.3939 11.1637 11.6893C10.8683 11.9846 10.4677 12.1506 10.05 12.1506H7.95002C7.63593 12.137 7.33309 12.0298 7.08047 11.8427C6.82785 11.6555 6.63701 11.3971 6.53252 11.1006"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 12.1504V13.7254M9 4.27539V5.85039V4.27539Z"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
