import React from "react";

interface CreditRowProps {
  title: string;
  linkText: string;
}

const CreditRow: React.FC<CreditRowProps> = (props) => {
  const { title, linkText } = props;
  return (
    <div className="row g-2 justify-content-between border-bottom pb-1">
      <div className="col-11 text-start">
        <div className="fw-medium">{title}</div>
      </div>
      <div className="col-1 text-end">
        <div className="mb-0 fw-bolder">
          <a href="#">
            <i className="bi bi-chevron-right text-dark"></i>
            {linkText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CreditRow;
