import { AxiosPromise } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { BookingTabsEnum, IAdminLastReminder, IAdminReminder, IRedeemCode, LastReminderOrderIdKeyEnum } from '@common-types';
import { AppThunk, axiosInstance, getHeaders } from '@share/utils';

export interface IAdminReminderState {
  lastReminder: IAdminLastReminder;
  remainderStatus: IRedeemCode;
  loading: boolean;
  error: string;
  loadingLastReminder: boolean;
  errorLastReminder: string;
}

const initialState: IAdminReminderState = {
  lastReminder: null,
  remainderStatus: null,
  loading: false,
  error: '',
  loadingLastReminder: false,
  errorLastReminder: '',
};

const adminReminderSlice = createSlice({
  name: 'adminReminder',
  initialState,
  reducers: {
    setLoading: (state: IAdminReminderState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IAdminReminderState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setReminderStatus: (state: IAdminReminderState, { payload }: PayloadAction<IRedeemCode>) => {
      state.remainderStatus = payload;
    },
    setLoadingLastReminder: (state: IAdminReminderState, { payload }: PayloadAction<boolean>) => {
      state.loadingLastReminder = payload;
    },
    setErrorLastReminder: (state: IAdminReminderState, { payload }: PayloadAction<string>) => {
      state.errorLastReminder = payload;
    },
    setLastReminder: (
      state: IAdminReminderState,
      { payload }: PayloadAction<IAdminLastReminder>,
    ) => {
      state.lastReminder = payload;
    },
  },
});

export const adminReminderAction = adminReminderSlice.actions;
export const adminReminderReducer = adminReminderSlice.reducer;

type TBookingTabAPIMap = {
  [key in BookingTabsEnum]: () => AxiosPromise<IAdminLastReminder>
}

export const getLastReminder = (orderId: number, bookingTab: BookingTabsEnum): AppThunk => {
  return async (dispatch) => {
    dispatch(adminReminderAction.setLoadingLastReminder(true));
    const bookingTabAPIMap: TBookingTabAPIMap = {
      [BookingTabsEnum.Hotels]: () => axiosInstance.get(Urls.AdminHotelNotification, {
        params: { [LastReminderOrderIdKeyEnum.HotelOrderId]: orderId },
        ...getHeaders(),
      }),
      [BookingTabsEnum.Condos]: () => axiosInstance.get(Urls.AdminCondoNotification, {
        params: { [LastReminderOrderIdKeyEnum.CondoOrderId]: orderId },
        ...getHeaders(),
      }),
    }

    try {
      const res = await bookingTabAPIMap[bookingTab]?.();

      dispatch(adminReminderAction.setLoadingLastReminder(false));
      dispatch(adminReminderAction.setLastReminder(res.data));
    } catch (error) {
      dispatch(adminReminderAction.setErrorLastReminder(error.toString()));
      dispatch(adminReminderAction.setLoadingLastReminder(false));
    }
  };
};

export const postReminder = (reminderData: IAdminReminder): AppThunk => {
  return async (dispatch, getState) => {
    const { adminAllBookingsStore } = getState();
    dispatch(adminReminderAction.setLoading(true));

    const activeTab = adminAllBookingsStore.currentTab;
    const activeTabUrl =
      activeTab === BookingTabsEnum.Condos
        ? Urls.AdminCondoNotification
        : Urls.AdminHotelNotification;

    try {
      const res = await axiosInstance.post(activeTabUrl, reminderData, {
        ...getHeaders(),
      });
      dispatch(adminReminderAction.setLoading(false));
      dispatch(adminReminderAction.setReminderStatus(res.data));
    } catch (error) {
      dispatch(adminReminderAction.setError(error.toString()));
      dispatch(adminReminderAction.setLoading(false));
    }
  };
};

