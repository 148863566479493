import React from 'react';

const BookingDiscount = ({
  balance,
  totalFarePrice,
  nightsCount,
  roomsCount,
  savings,
  taxes,
  formatter,
}) => {
  return (
    <div className="row g-4">
      <div className="col-md-6 col-xl-12">
        <div className="card shadow rounded-2">
          <div className="card-header border-bottom">
            <h5 className="card-title mb-0">Booking Summary</h5>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-borderless">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="h6 fw-light mb-0">
                 {roomsCount} {roomsCount > 1 ? 'rooms' : 'room'}, {nightsCount} {nightsCount > 1 ? 'nights' : 'night'}
                </span>
                <span className="fs-5">
                  {formatter.format(totalFarePrice)}
                </span>
              </li>

              <li className="mt-1 list-group-item d-flex justify-content-between align-items-center">
                <span className="h6 fw-light mb-0">Taxes &amp; Fees</span>
                <span className="fs-5">{formatter.format(taxes || '0')}</span>
              </li>

              <hr className="m-0" />

              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="h6 fw-light mb-0">Rewards Redemption</span>
                <span className="fs-5 text-success">-${savings?.toLocaleString()}</span>
              </li>
            </ul>
          </div>
          <div className="card-footer border-top">
            <div className="d-flex justify-content-between align-items-center">
              <span className="h5 mb-0">Payable Now</span>
              <span className="h5 mb-0 fw-bold">{formatter.format(balance)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-md-6 col-xl-12">
        <div className="card shadow">
          <div className="card-header border-bottom">
            <div className="cardt-title">
              <h5 className="mb-0">Discount Code</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="input-group">
              <input className="form-control" placeholder="Enter code" />
              <button type="button" className="btn btn-primary">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BookingDiscount;
