import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';


import './style.scss';

export enum MessageType {
  Success,
  Failure,
  Warning
}

interface IProps {
  type: MessageType;
  message: string;
}

export class GeneralMessage extends React.Component<IProps, null> {

  render(): React.ReactNode {
    const { type, message } = this.props;

    const className = `messages-container ${type === MessageType.Success ? 'success' : type === MessageType.Failure ? 'failure' : 'warning'}`
    return (
      <div className={className}>
        <FontAwesomeIcon icon={faInfoCircle} />
        <FormattedMessage id={message} />
      </div>
    );
  }
}
