import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BackgroundColor } from '@constants';
import { ICompanyLogos } from '@common-types';
import './style.scss';

interface IProps {
  isBackgroundColorDark?: boolean;
  logos: ICompanyLogos[];
  max?: number;
}

export class CompanyLogos extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { isBackgroundColorDark = true, logos, max } = this.props;

    const logosTodisplay = max ? logos.slice(0, max) : logos;
    
    return (
      <div
        className={`company-logos ${
          isBackgroundColorDark ? BackgroundColor.dark : BackgroundColor.light
        }`}
      >
        <div className="company-logos__wrapper">
          <p className="company-logos__title">
            <FormattedMessage id="company.you.keep" />
          </p>
          <div className="company-logos__logos-wrapper">
            {logosTodisplay.map((item, index) => (
              <div key={index} className="company-logos__img-wrapper">
                <img className="company-logos__img" src={item.image} alt={item.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
