import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAdminPriceRules,
  IAdminSuppliers,
  INewPriceRule,
  ISavingNewPriseRule,
} from '@common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';

export interface IAdminPriceRulesState {
  priceRules: IAdminPriceRules[];
  loading: boolean;
  error: string;
  visible: boolean;
  newPriceRule: INewPriceRule;
  loadingNewPriceRule: boolean;
  errorNewPriceRule: string;
  savingNewPriseRule: ISavingNewPriseRule;
  ruleExists: boolean;
  product: string;
  adminSuppliers: IAdminSuppliers;
}

const initialState: IAdminPriceRulesState = {
  priceRules: null,
  loading: false,
  error: '',
  visible: false,
  newPriceRule: null,
  loadingNewPriceRule: false,
  errorNewPriceRule: '',
  savingNewPriseRule: null,
  ruleExists: false,
  product: 'Condo',
  adminSuppliers: null,
};

const adminPriceRulesSlice = createSlice({
  name: 'priceRules',
  initialState,
  reducers: {
    setLoadingPriceRules: (state: IAdminPriceRulesState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrorPriceRules: (state: IAdminPriceRulesState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPriceRules: (
      state: IAdminPriceRulesState,
      { payload }: PayloadAction<IAdminPriceRules[]>,
    ) => {
      state.priceRules = payload;
    },
    setNewPriceRule: (state: IAdminPriceRulesState, { payload }: PayloadAction<INewPriceRule>) => {
      state.newPriceRule = payload;
    },
    setLoadingNewPriceRules: (
      state: IAdminPriceRulesState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loadingNewPriceRule = payload;
    },
    setErrorNewPriceRules: (state: IAdminPriceRulesState, { payload }: PayloadAction<string>) => {
      state.errorNewPriceRule = payload;
    },
    setSavingNewPriseRule: (
      state: IAdminPriceRulesState,
      { payload }: PayloadAction<ISavingNewPriseRule>,
    ) => {
      state.savingNewPriseRule = payload;
    },
    setRuleExists: (state: IAdminPriceRulesState, { payload }: PayloadAction<boolean>) => {
      state.ruleExists = payload;
    },
    setProduct: (state: IAdminPriceRulesState, { payload }: PayloadAction<string>) => {
      state.product = payload;
    },
    setAdminSuppliers: (
      state: IAdminPriceRulesState,
      { payload }: PayloadAction<IAdminSuppliers>,
    ) => {
      state.adminSuppliers = payload;
    },
  },
});

export const {
  setLoadingPriceRules,
  setErrorPriceRules,
  setPriceRules,
  setNewPriceRule,
  setLoadingNewPriceRules,
  setErrorNewPriceRules,
  setSavingNewPriseRule,
  setRuleExists,
  setProduct,
  setAdminSuppliers,
} = adminPriceRulesSlice.actions;

export const adminPriceRulesReducer = adminPriceRulesSlice.reducer;

export const getPriceRules = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingPriceRules(true));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.get(Urls.HotelPriceRules, {
        ...getHeaders(),
      });

      dispatch(setLoadingPriceRules(false));
      dispatch(setPriceRules(res.data));
    } catch (error) {
      console.error(error);
      dispatch(setErrorPriceRules(error.toString()));
      dispatch(setLoadingPriceRules(false));
    }
  };
};

export const addNewPriceRule = (newPriceRule: INewPriceRule): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingNewPriceRules(true));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.post(Urls.HotelPriceRules, newPriceRule, {
        ...getHeaders(),
      });

      dispatch(setSavingNewPriseRule(res.data));
      dispatch(setLoadingNewPriceRules(false));
    } catch (error) {
      console.error(error);
      dispatch(setErrorNewPriceRules(error.toString()));
      dispatch(setRuleExists(true));
      dispatch(setLoadingNewPriceRules(false));
    }
  };
};

export const getAdminSuppliers = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.get(Urls.AdminSuppliers, { ...getHeaders(user?.userId) });

      dispatch(setAdminSuppliers(res.data));
    } catch (error) {
      console.error(error);
    }
  };
};
