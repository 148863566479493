import React, { RefObject } from 'react';
import { DateRangePicker, OnChangeProps, Range, RangeFocus } from 'react-date-range';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Action } from 'redux';
import { Form } from 'antd';
import debounce from 'lodash/debounce';
import { SelectValue } from 'antd/lib/select';
import { FormInstance } from 'antd/lib/form';
import { ThunkDispatch } from 'redux-thunk';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import setMonth from 'date-fns/setMonth';
import differenceInDays from 'date-fns/differenceInDays';
import { connect } from 'react-redux';
import { CloseSvg, ArrowLeftSvg, ArrowRightSvg } from '@share/assets';
import { CalendarSvg } from '@assets';
import { BlueButton, MonthSelect } from '@share/components';
import { IGetawayDatesState, getawayDatesActions } from '@store/slices';
import { formatDateCheckInOut, getAccountLeadTime, getDateCheckInOut, getDateCheckInOutISOString, RootState } from '@share/utils';
import { MonthsEnum } from '@common-types';
import { DATE_FORMAT, DEFAULT_MEDIA_POINT } from '@constants';
import './style.scss';
import { getAccountDateFnsLocale } from '@i18n';
import { ILoginState } from '@share/store/slices';

interface IMapStateToProps {
  getawayDatesStore: IGetawayDatesState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDates: (dates: { startDate: Date; endDate: Date }) => void;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setKey: (key: string) => void;
  clearError: () => void;
  resetDates: () => void;
  setIsFocused?: (value: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  matches?: boolean;
}

interface IState {
  isFocused: boolean;
  startDate: Date;
  endDate: Date;
  key: string;
  isEndDateSelected: boolean;
  focusedRange: RangeFocus;
  isMobile: boolean;
  isInitial: boolean;
}

const ZERO = 0;
const oneItem = 1;
const maxDaysRange = 360;
const resizeDebounceTime = 300;
const mobileMonthCount = 12;
const fullMonthCount = 2;
const defaultRange = 0;
const isStart = 0;

export class GetawayDatePickerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  isInitial = true;

  constructor(props: IProps) {
    super(props);

    const startDate = props.getawayDatesStore.startDate
      ? getDateCheckInOut(props.getawayDatesStore.startDate)
      : new Date();
    const endDate = props.getawayDatesStore.endDate
      ? getDateCheckInOut(props.getawayDatesStore.endDate)
      : new Date();

    this.state = {
      isFocused: false,
      startDate,
      endDate,
      key: props.getawayDatesStore.key,
      isEndDateSelected: true,
      focusedRange: [defaultRange, isStart],
      isMobile: document.body.offsetWidth <= DEFAULT_MEDIA_POINT,
      isInitial: true,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState {
    const { startDate, endDate } = props.getawayDatesStore;
    const stateStart = getDateCheckInOutISOString(state.startDate);
    const stateEnd = getDateCheckInOutISOString(state.endDate);

    if (
      state.isInitial &&
      startDate &&
      endDate &&
      startDate !== stateStart &&
      endDate !== stateEnd
    ) {
      const start = getDateCheckInOut(startDate);
      const end = getDateCheckInOut(endDate);

      return {
        ...state,
        startDate: start,
        endDate: end,
        isInitial: false,
      };
    }

    return null;
  }

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true });
    }
  };

  blur = (): void => {
    const { isFocused, startDate, endDate } = this.state;

    if (isFocused) {
      if (!this.state.isEndDateSelected && startDate.toISOString() === endDate.toISOString()) {
        this.setState(
          {
            startDate,
            endDate: addDays(new Date(startDate), oneItem),
          },
          this.updateProps,
        );
      }

      this.setState({
        isFocused: false,
        isEndDateSelected: true,
        focusedRange: [defaultRange, isStart],
      });

      if (this.props.getawayDatesStore.endDate.length !== ZERO) {
        this.props.formRef.current.setFieldsValue({ datepicker: endDate });
      }
    }
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      (this.wrapperRef && !this.wrapperRef.current.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('getaway-date-picker__fixed-bg')
    ) {
      this.blur();
    }
  };

  onResize = debounce(() => {
    if (document.body.offsetWidth <= DEFAULT_MEDIA_POINT && !this.state.isMobile) {
      const { startDate, endDate } = this.state;
      this.setState({ isMobile: true, startDate: new Date(), endDate: new Date() });

      setTimeout(() => {
        this.setState({ startDate, endDate });
      });
    } else if (document.body.offsetWidth > DEFAULT_MEDIA_POINT && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }, resizeDebounceTime);

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.onResize);
    this.props.resetDates();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.getawayDatesStore.isFocused !== this.props.getawayDatesStore.isFocused) {
      this.setState({ isFocused: true });
      this.props.setIsFocused(false);
    }
  }

  onDone = (): void => {
    this.blur();
  };

  onSelect = (ranges: OnChangeProps): void => {
    this.props.clearError();
    const range = ranges as { selection: Range };
    this.isInitial = false;

    if (range.selection) {
      const startDate = new Date(
        range.selection.startDate.getFullYear(),
        range.selection.startDate.getMonth(),
        range.selection.startDate.getDate());
      const endDate = new Date(
        range.selection.endDate.getFullYear(),
        range.selection.endDate.getMonth(),
        range.selection.endDate.getDate());

      if (!this.state.isEndDateSelected && startDate.toString() === endDate.toString()) {
        this.setState(
          {
            startDate,
            endDate: addDays(new Date(startDate), oneItem),
            isInitial: false,
          },
          this.updateProps,
        );
      } else {
        this.setState(
          {
            startDate,
            endDate,
            isInitial: false,
          },
          this.updateProps,
        );
      }
    }
  };

  updateProps = (): void => {
    const { setDates } = this.props;

    setDates({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  onRangeFocusChange = (focusedRange: RangeFocus): void => {
    this.setState({ focusedRange, isEndDateSelected: !focusedRange[oneItem] });
  };

  getNavigatorRender = (
    currentFocusedDate: Date,
    changeShownDate: (count: number | Date, type: string) => void,
  ): React.ReactElement => {
    const today = new Date();

    return (
      <div onMouseUp={(e) => e.stopPropagation()}>
        <div className="getaway-date-picker__arrows">
          <div
            className="getaway-date-picker__prev"
            onClick={() => changeShownDate(-oneItem, 'monthOffset')}
          >
            <ArrowLeftSvg />
          </div>
          <div
            className="getaway-date-picker__next"
            onClick={() => changeShownDate(+oneItem, 'monthOffset')}
          >
            <ArrowRightSvg />
          </div>
        </div>
        <div className="getaway-date-picker__months">
          <div className="getaway-date-picker__months-left">
            <MonthSelect
              onChange={(val: SelectValue) => changeShownDate(val as number, 'setMonth')}
              value={currentFocusedDate.getMonth()}
              date={currentFocusedDate}
              minMonth={today.getMonth()}
            />
          </div>
          <div className="getaway-date-picker__months-right">
            <MonthSelect
              onChange={(val: SelectValue) => {
                if (val === MonthsEnum.January) {
                  changeShownDate(
                    setMonth(subYears(currentFocusedDate, oneItem), MonthsEnum.December),
                    'set',
                  );
                } else {
                  if (currentFocusedDate.getMonth() === MonthsEnum.December) {
                    changeShownDate(
                      setMonth(addYears(currentFocusedDate, oneItem), val as number),
                      'set',
                    );
                  } else {
                    changeShownDate((val as number) - oneItem, 'setMonth');
                  }
                }
              }}
              minMonth={today.getMonth() + oneItem}
              value={addMonths(currentFocusedDate, oneItem).getMonth()}
              date={addMonths(currentFocusedDate, oneItem)}
            />
          </div>
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    const { getawayDatesStore, matches, loginStore, intl } = this.props;
    const { error } = getawayDatesStore;
    const { isFocused, endDate, startDate, key, focusedRange, isEndDateSelected, isMobile } =
      this.state;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const selectionRange = {
      startDate: start,
      endDate: end,
      key,
    };
    const hideSelection: boolean = isEndDateSelected && start?.toISOString() === end?.toISOString();
    const focusZIndex = 4;
    const blurZIndex = 1;
    const isMoreThenMaxDays = differenceInDays(end, start) > maxDaysRange;
    const { account } = loginStore;
    const locale = getAccountDateFnsLocale(account);
    const leadTime = getAccountLeadTime(account);

    return (
      <div className={`getaway-date-picker ${!!error ? 'error' : ''}`} ref={this.wrapperRef}>
        <Form.Item
          className="getaway-date-picker__date-picker-wrapper"
          name="datepicker"
          validateTrigger="onBlur"
          rules={[
            {
              type: 'object' as const,
              required: true,
              message: intl.formatMessage({ id: 'error.message.date.picker' }),
            },
          ]}
        >
          <div
            className={`
            getaway-date-picker__wrapper
            ${isFocused && isEndDateSelected ? 'selected' : ''}
            ${isFocused ? 'open' : ''}
          `}
            onClick={this.focus}
            style={{
              zIndex: isFocused ? focusZIndex : blurZIndex,
            }}
          >
            <div className="getaway-date-picker__left">
              <div className="getaway-date-picker__icon">
                <CalendarSvg />
              </div>
            </div>
            <div className="getaway-date-picker__right">
              <div className="getaway-date-picker__label">
                {isFocused || matches ? (
                  <FormattedMessage id="check.in" />
                ) : (
                  <>
                    <FormattedMessage id="check.in" /> / <FormattedMessage id="check.out" />
                  </>
                )}
              </div>
              <div className="getaway-date-picker__header">
                {isFocused || matches ? (
                  start && !this.state.isInitial && getawayDatesStore.startDate ? (
                    <span className="getaway-date-picker__date">
                      {formatDateCheckInOut(getawayDatesStore.startDate, DATE_FORMAT, locale)}
                    </span>
                  ) : (
                    <FormattedMessage id="select.date" />
                  )
                ) : (
                  <>
                    {getawayDatesStore.startDate &&
                      getawayDatesStore.endDate &&
                      !this.state.isInitial ? (
                      <>
                        <span className="getaway-date-picker__date">
                          {formatDateCheckInOut(getawayDatesStore.startDate, DATE_FORMAT, locale)} -{' '}
                          {formatDateCheckInOut(getawayDatesStore.endDate, DATE_FORMAT, locale)}
                        </span>
                      </>
                    ) : (
                      <FormattedMessage id="add.dates" />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {matches && (
            <div
              className={`getaway-date-picker__wrapper right item ${!isEndDateSelected ? 'selected' : ''
                }`}
              onClick={this.focus}
            >
              <div className="getaway-date-picker__left">
                <div className="getaway-date-picker__icon">
                  <CalendarSvg />
                </div>
              </div>
              <div className="getaway-date-picker__right">
                <div className="getaway-date-picker__label">
                  <FormattedMessage id="check.out" />
                </div>
                <div className="getaway-date-picker__header">
                  {end &&
                    !this.state.isInitial &&
                    isEndDateSelected &&
                    getawayDatesStore.endDate ? (
                    <span className="getaway-date-picker__date">
                      {formatDateCheckInOut(getawayDatesStore.endDate, DATE_FORMAT, locale)}
                    </span>
                  ) : (
                    <FormattedMessage id="select.date" />
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className="getaway-date-picker__dropdown"
            style={{ display: isFocused ? 'block' : 'none' }}
          >
            <div
              className="getaway-date-picker__mobile-header"
              style={{ display: isMobile ? 'block' : 'none' }}
            >
              {isEndDateSelected ? (
                <FormattedMessage id="check.in" />
              ) : (
                <FormattedMessage id="check.out" />
              )}
            </div>
            <div
              className={`getaway-date-picker__wrapper right ${!isEndDateSelected ? 'selected' : ''
                }`}
            >
              <div className="getaway-date-picker__left">
                <div className="getaway-date-picker__icon">
                  <CalendarSvg />
                </div>
              </div>
              <div className="getaway-date-picker__right">
                <div className="getaway-date-picker__label">
                  <FormattedMessage id="check.out" />
                </div>
                <div className="getaway-date-picker__header">
                  {end &&
                    !this.state.isInitial &&
                    isEndDateSelected &&
                    getawayDatesStore.endDate ? (
                    <span className="getaway-date-picker__date">
                      {formatDateCheckInOut(getawayDatesStore.endDate, DATE_FORMAT, locale)}
                    </span>
                  ) : (
                    <FormattedMessage id="select.date" />
                  )}
                </div>
              </div>
            </div>
            <div className="getaway-date-picker__close-icon" onClick={this.blur}>
              <CloseSvg />
            </div>
            <div
              className={`getaway-date-picker__picker ${hideSelection ? 'hide-selection' : ''} ${isEndDateSelected ? '' : 'show-checkout'
                }`}
            >
              <div className="getaway-date-picker__picker-wrapper">
                <DateRangePicker
                  onChange={this.onSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={isMobile ? mobileMonthCount : fullMonthCount}
                  monthDisplayFormat="MMMM, yyyy"
                  ranges={[selectionRange]}
                  direction={isMobile ? 'vertical' : 'horizontal'}
                  weekStartsOn={0}
                  weekdayDisplayFormat="EEEEEE"
                  preventSnapRefocus
                  minDate={addDays(new Date(), leadTime)}
                  maxDate={addYears(new Date(), oneItem)}
                  focusedRange={focusedRange}
                  onRangeFocusChange={this.onRangeFocusChange}
                  // @ts-ignore
                  navigatorRenderer={this.getNavigatorRender}
                />
              </div>
            </div>
            <div
              className={`getaway-date-picker__footer ${isMoreThenMaxDays ? 'right-button' : ''}`}
            >
              {isMoreThenMaxDays && (
                <div className="getaway-date-picker__error-message">
                  <FormattedMessage id="maximum.date.range.search" />
                </div>
              )}
              <BlueButton onClick={this.onDone} disabled={isMoreThenMaxDays}>
                <FormattedMessage id="done" />
              </BlueButton>
            </div>
          </div>
          {isFocused && <div className="getaway-date-picker__fixed-bg" />}
          {error && (
            <div className="getaway-date-picker__error" title={error}>
              {error}
            </div>
          )}
        </Form.Item>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayDatesStore: state.getawayDatesStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDates: (dates: { startDate: Date; endDate: Date }) => {
    dispatch(
      getawayDatesActions.setDates({
        startDate: getDateCheckInOutISOString(dates.startDate),
        endDate: getDateCheckInOutISOString(dates.endDate),
      }),
    );
  },
  setStartDate: (date: Date) => {
    dispatch(getawayDatesActions.setStartDate(getDateCheckInOutISOString(date)));
  },
  setEndDate: (date: Date) => {
    dispatch(getawayDatesActions.setEndDate(getDateCheckInOutISOString(date)));
  },
  setKey: (key: string) => {
    dispatch(getawayDatesActions.setKey(key));
  },
  clearError: () => {
    dispatch(getawayDatesActions.setError(''));
  },
  resetDates: () => {
    dispatch(getawayDatesActions.resetDates());
  },
  setIsFocused: () => {
    dispatch(getawayDatesActions.setIsFocused());
  },
});

export const GetawayDatePicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GetawayDatePickerComponent));
