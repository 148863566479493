import React from 'react';
import { connect } from 'react-redux';
import { Modal, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import format from 'date-fns/format';
import isNull from 'lodash/isNull';
import { DATE_FORMAT_FOR_BOOKINGS } from '@constants';
import { WhiteButton } from '@components';
import { getExportCsv, IAdminBookingsState, IAdminHotelBookingsState, getHotelExportCsv } from '@store/slices';
import './style.scss';
import { RootState } from '@share/utils';
import { BlueButton } from '@share/components';
import { BookingTabsEnum } from '@common-types';

interface IMapStateToProps {
  adminBookingsStore: IAdminBookingsState;
  adminHotelBookingsStore: IAdminHotelBookingsState;
}

interface IMapDispatchToProps {
  getExportCsv: (fileName: string) => void;
  getHotelExportCsv: (fileName: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  visible: boolean;
  selectedTab: string;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  handleCancelModalExportCsv: (value: boolean) => void;
  onResetFilters: () => void;
}

interface IState {
  fileName: string;
}

const ZERO = 0;
const ALL_BOOKING = 'All booking';

class ModalExportCsvComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      fileName: '',
    };
  }

  onChange = (e: { target: HTMLInputElement }): void => {
    this.setState({ fileName: e.target.value });
  };

  getFilterAmount = (): number => {
    const { selectedTab } = this.props;
    const { checkInDateRangeFilter, checkOutDateRangeFilter, status, supplierFilters } =
      this.props.adminBookingsStore;
    const {
      checkInDateRangeFilter: checkInHotelDateRangeFilter,
      checkOutDateRangeFilter: checkOutHotelDateRangeFilter,
      status: statusHotel,
      supplierFilters: supplierHotelFilters,
    } = this.props.adminHotelBookingsStore;

    if (selectedTab === BookingTabsEnum.Condos) {
      return [
        checkInDateRangeFilter,
        checkOutDateRangeFilter,
        status !== null ? status : ALL_BOOKING,
        !isNull(supplierFilters) && supplierFilters.length !== ZERO ? supplierFilters : null,
      ].filter(Boolean).length;
    }

    if (selectedTab === BookingTabsEnum.Hotels) {
      return [
        checkInHotelDateRangeFilter,
        checkOutHotelDateRangeFilter,
        statusHotel !== null ? statusHotel : ALL_BOOKING,
        !isNull(supplierHotelFilters) && supplierHotelFilters.length !== ZERO
          ? supplierHotelFilters
          : null,
      ].filter(Boolean).length;
    }
  };

  isDisabledResetAll = () => {
    const { selectedTab } = this.props;
    const { checkInDateRangeFilter, checkOutDateRangeFilter, statusesFilters, supplierFilters } =
      this.props.adminBookingsStore;
    const {
      checkInDateRangeFilter: checkInHotelDateRangeFilter,
      checkOutDateRangeFilter: checkOutHotelDateRangeFilter,
      statusesFilters: statusesHotelFilters,
      supplierFilters: supplierHotelFilters,
    } = this.props.adminHotelBookingsStore;

    if (selectedTab === BookingTabsEnum.Condos) {
      return (
        [checkInDateRangeFilter, checkOutDateRangeFilter, supplierFilters, statusesFilters].filter(
          Boolean,
        ).length === ZERO
      );
    }

    if (selectedTab === BookingTabsEnum.Hotels) {
      return (
        [
          checkInHotelDateRangeFilter,
          checkOutHotelDateRangeFilter,
          supplierHotelFilters,
          statusesHotelFilters,
        ].filter(Boolean).length === ZERO
      );
    }
  };

  onExport = () => {
    const { fileName } = this.state;

    if (this.props.selectedTab === BookingTabsEnum.Hotels) {
      this.props.getHotelExportCsv(fileName);
    }
    if (this.props.selectedTab === BookingTabsEnum.Condos) {
      this.props.getExportCsv(fileName);
    }
  };

  componentDidMount(): void {
    const { selectedTab } = this.props;

    this.setState({
      fileName: `${this.props.intl.formatMessage({
        id:
          selectedTab === BookingTabsEnum.Hotels
            ? 'hotel.reservation.report'
            : 'condo.reservation.report',
      })}-${format(new Date(), DATE_FORMAT_FOR_BOOKINGS)}`,
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { adminBookingsStore, adminHotelBookingsStore, selectedTab, handleCancelModalExportCsv } =
      this.props;
    const { exportCsv, exportLoading } = adminBookingsStore;
    const { exportCsv: exportHotelCsv, exportLoading: exportHotelLoading } =
      adminHotelBookingsStore;
    const { fileName } = this.state;

    if (prevProps.selectedTab !== this.props.selectedTab) {
      this.setState({
        fileName: `${this.props.intl.formatMessage({
          id:
            selectedTab === BookingTabsEnum.Hotels
              ? 'hotel.reservation.report'
              : 'condo.reservation.report',
        })}-${format(new Date(), DATE_FORMAT_FOR_BOOKINGS)}`,
      });
    }

    if (selectedTab === BookingTabsEnum.Condos) {
      if (
        prevProps.adminBookingsStore.exportLoading !== exportLoading &&
        exportCsv &&
        !exportLoading
      ) {
        const link = document.createElement('a');

        link.setAttribute(
          'href',
          // @ts-ignore
          'data:application/csv;charset=utf-8,' + encodeURIComponent(exportCsv),
        );
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        handleCancelModalExportCsv(false);
      }
    }
    if (selectedTab === BookingTabsEnum.Hotels) {
      if (
        prevProps.adminHotelBookingsStore.exportLoading !== exportHotelLoading &&
        exportHotelCsv &&
        !exportHotelLoading
      ) {
        const link = document.createElement('a');

        link.setAttribute(
          'href',
          // @ts-ignore
          'data:application/csv;charset=utf-8,' + encodeURIComponent(exportHotelCsv),
        );
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        handleCancelModalExportCsv(false);
      }
    }

    if (prevProps.visible !== this.props.visible) {
      this.setState({
        fileName: `${this.props.intl.formatMessage({
          id:
            selectedTab === BookingTabsEnum.Hotels
              ? 'hotel.reservation.report'
              : 'condo.reservation.report',
        })}-${format(new Date(), DATE_FORMAT_FOR_BOOKINGS)}`,
      });
    }
  }

  render(): React.ReactNode {
    const { fileName } = this.state;
    const {
      selectedTab,
      visible,
      onCancel,
      onResetFilters,
      intl,
      adminBookingsStore,
      adminHotelBookingsStore,
    } = this.props;
    const {
      checkInDateRangeFilter,
      checkOutDateRangeFilter,
      status,
      statusesFilters,
      supplierFilters,
    } = adminBookingsStore;
    const {
      checkInDateRangeFilter: checkInHotelDateRangeFilter,
      checkOutDateRangeFilter: checkOutHotelDateRangeFilter,
      status: statusHotel,
      statusesFilters: statusesHotelFilters,
      supplierFilters: supplierHotelFilters,
    } = adminHotelBookingsStore;

    return (
      <Modal
        className="modal-export-csv"
        visible={visible}
        onCancel={onCancel}
        wrapClassName="modal-export-csv__wrapper"
        footer={null}
      >
        <div className="modal-export-csv__wrapper-header">
          <h4 className="modal-export-csv__header-text">
            <FormattedMessage id="export.summary" />
          </h4>
        </div>
        <div className="modal-export-csv__content">
          <div className="modal-export-csv__name-block">
            <p className="modal-export-csv__name-title">
              <FormattedMessage id="name" />:
            </p>
            <Input
              allowClear
              placeholder={intl.formatMessage({ id: 'name' })}
              value={fileName}
              onChange={this.onChange}
            />
          </div>
          {this.getFilterAmount() !== ZERO ? (
            <div className="modal-export-csv__filters-block">
              <p className="modal-export-csv__filters-title">
                <FormattedMessage id="applied.filters" />({this.getFilterAmount()})
              </p>

              {selectedTab === BookingTabsEnum.Condos && !!checkInDateRangeFilter ? (
                <div className="modal-export-csv__filters">
                  <p className="modal-export-csv__filter-title"><FormattedMessage id="check.in.dates"/>:</p>
                  <p className="modal-export-csv__filter-data">
                    {checkInDateRangeFilter.from}
                    {' - '}
                    {checkInDateRangeFilter.to}
                  </p>
                </div>
              ) : null}

              {selectedTab === BookingTabsEnum.Hotels && !!checkInHotelDateRangeFilter ? (
                <div className="modal-export-csv__filters">
                  <p className="modal-export-csv__filter-title"><FormattedMessage id="check.in.dates"/>:</p>
                  <p className="modal-export-csv__filter-data">
                    {checkInHotelDateRangeFilter.from}
                    {' - '}
                    {checkInHotelDateRangeFilter.to}
                  </p>
                </div>
              ) : null}

              {selectedTab === BookingTabsEnum.Condos && !!checkOutDateRangeFilter ? (
                <div className="modal-export-csv__filters">
                  <p className="modal-export-csv__filter-title"><FormattedMessage id="check.out.dates"/>:</p>
                  <p className="modal-export-csv__filter-data">
                    {checkOutDateRangeFilter.from}
                    {' - '}
                    {checkOutDateRangeFilter.to}
                  </p>
                </div>
              ) : null}

              {selectedTab === BookingTabsEnum.Hotels && !!checkOutHotelDateRangeFilter ? (
                <div className="modal-export-csv__filters">
                  <p className="modal-export-csv__filter-title"><FormattedMessage id="check.out.dates"/>:</p>
                  <p className="modal-export-csv__filter-data">
                    {checkOutHotelDateRangeFilter.from}
                    {' - '}
                    {checkOutHotelDateRangeFilter.to}
                  </p>
                </div>
              ) : null}

              <div className="modal-export-csv__filters">
                <p className="modal-export-csv__filter-title">
                  <FormattedMessage id="status" />:
                </p>

                {selectedTab === BookingTabsEnum.Condos ? (
                  !isNull(statusesFilters) && statusesFilters.length !== ZERO ? (
                    <p className="modal-export-csv__filter-data">{statusesFilters.join(', ')}</p>
                  ) : (
                    <p className="modal-export-csv__filter-data">
                      {status !== null ? status : <FormattedMessage id="all.bookings" />}
                    </p>
                  )
                ) : !isNull(statusesHotelFilters) && statusesHotelFilters.length !== ZERO ? (
                  <p className="modal-export-csv__filter-data">{statusesHotelFilters.join(', ')}</p>
                ) : (
                  <p className="modal-export-csv__filter-data">
                    {statusHotel !== null ? statusHotel : <FormattedMessage id="all.bookings" />}
                  </p>
                )}
              </div>

              {!isNull(supplierFilters) && supplierFilters.length !== ZERO ? (
                <div className="modal-export-csv__filters">
                  <p className="modal-export-csv__filter-title">
                    <FormattedMessage id="supplier" />:
                  </p>
                  <p className="modal-export-csv__filter-data">{supplierFilters.join(', ')}</p>
                </div>
              ) : null}

              {!isNull(supplierHotelFilters) && supplierHotelFilters.length !== ZERO ? (
                <div className="modal-export-csv__filters">
                  <p className="modal-export-csv__filter-title">
                    <FormattedMessage id="supplier" />:
                  </p>
                  <p className="modal-export-csv__filter-data">{supplierHotelFilters.join(', ')}</p>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="modal-export-csv__footer">
          <WhiteButton
            key={'reset.all'}
            onClick={onResetFilters}
            disabled={this.isDisabledResetAll()}
          >
            <FormattedMessage id="reset.all" />
          </WhiteButton>
          <BlueButton
            key={'export'}
            onClick={this.onExport}
            download={`${fileName}.csv`}
            disabled={!fileName ? true : false}
          >
            <FormattedMessage id="export" />
          </BlueButton>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingsStore: state.adminBookingsStore,
    adminHotelBookingsStore: state.adminHotelBookingsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getExportCsv,
  getHotelExportCsv,
};

export const ModalExportCsv = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ModalExportCsvComponent));
