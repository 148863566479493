import React from 'react';

interface TestimonialProps {
  imgSrc: string;
  quote: string;
}

const Testimonial: React.FC<TestimonialProps> = (props) => {
  const { imgSrc, quote } = props
  return (
    <div className="row justify-content-between align-items-center">
      <div className="col-md-6 col-lg-5 position-relative">
        <img
          src={imgSrc}
          className="rounded-3 position-relative d-block w-100"
          alt="testimonial from a user"
        />
      </div>
      <div className="col-md-6 col-lg-6">
        <span className="display-3 mb-0 text-primary">
          <i className="bi bi-quote"></i>
        </span>
        <h5 className="fw-light">{quote}</h5>
      </div>
    </div>
  );
};

export default Testimonial;
