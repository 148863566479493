import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { v4 } from 'uuid';
import { hotelBookingsAdminAPI } from '@api';
import {
  IHotelBookingsParams,
  IFilterOptions,
  IFilterDateRange,
  HotelBookingStatusEnum,
  IHotelAutocompleteResult,
  IExportCsv,
  IHotelBookingsParamsBase,
  IAdminHotelBookings,
} from '@common-types';
import {
  BOOKING_TABLE_PAGINATION,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_SEARCH,
} from '@constants';
import {
  BOOKING_STATUS_LABEL,
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  BOOKING_TABLE_MATCHED_BY,
} from '@share/constants';
import { getSortOrder } from '@utils';
import { AppThunk, axiosInstance, getHeaders, UrlUtils } from '@share/utils';
import { Urls } from '@share/constants';

export type TAdminHotelBookingsSorter = SorterResult<IAdminHotelBookings>;

export interface IAdminHotelBookingsState {
  bookings: IAdminHotelBookings[];
  loading: boolean;
  error: string;
  orderCount: number;
  pagination: TablePaginationConfig;
  status: HotelBookingStatusEnum;
  sort: TAdminHotelBookingsSorter;
  checkInDateRangeFilter: IFilterDateRange;
  checkOutDateRangeFilter: IFilterDateRange;
  bookedOnDateRangeFilter: IFilterDateRange;
  statusesFilters: string[];
  supplierFilters: string[];
  selectedSearchItem: IHotelAutocompleteResult;
  autocompleteResults: IHotelAutocompleteResult[];
  isSearchLoading: boolean;
  search: string;
  exportCsv: IExportCsv;
  exportLoading: boolean;
  exportError: string;
}

const one = 1;
const ten = 10;
const MIN_SEARCH_LENGTH = 3;

const initialState: IAdminHotelBookingsState = {
  bookings: [],
  loading: true,
  error: '',
  orderCount: null,
  pagination: {
    current: one,
    pageSize: ten,
  },
  status: null,
  sort: null,
  checkInDateRangeFilter: null,
  checkOutDateRangeFilter: null,
  bookedOnDateRangeFilter: null,
  statusesFilters: [],
  supplierFilters: [],
  isSearchLoading: false,
  selectedSearchItem: null,
  autocompleteResults: [],
  search: '',
  exportCsv: null,
  exportLoading: true,
  exportError: '',
};

const adminHotelBookingsSlice = createSlice({
  name: 'adminHotelBookings',
  initialState,
  reducers: {
    setCheckInDateRangeFilter: (
      state: IAdminHotelBookingsState,
      {
        payload,
      }: PayloadAction<{
        from: string;
        to: string;
      }>,
    ) => {
      state.checkInDateRangeFilter = payload;
    },
    setCheckOutDateRangeFilter: (
      state: IAdminHotelBookingsState,
      {
        payload,
      }: PayloadAction<{
        from: string;
        to: string;
      }>,
    ) => {
      state.checkOutDateRangeFilter = payload;
    },
    setBookedOnDateRangeFilter: (
      state: IAdminHotelBookingsState,
      {
        payload,
      }: PayloadAction<{
        from: string;
        to: string;
      }>,
    ) => {
      state.bookedOnDateRangeFilter = payload;
    },
    setSort: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<SorterResult<IAdminHotelBookings>>,
    ) => {
      state.sort = payload;
    },
    setLoading: (state: IAdminHotelBookingsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IAdminHotelBookingsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setBookings: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<IAdminHotelBookings[]>,
    ) => {
      state.bookings = payload;
    },
    setOrderCount: (state: IAdminHotelBookingsState, { payload }: PayloadAction<number>) => {
      state.orderCount = payload;
    },
    setPagination: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<TablePaginationConfig>,
    ) => {
      state.pagination = payload;
    },
    setStatus: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<HotelBookingStatusEnum>,
    ) => {
      state.status = payload;

      if (payload) {
        state.statusesFilters = [payload];
      } else {
        state.statusesFilters = null;
      }
    },
    setStatusesFilters: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<HotelBookingStatusEnum[]>,
    ) => {
      state.statusesFilters = payload;

      if (payload) {
        state.status = null;
      }
    },
    setSupplierFilters: (state: IAdminHotelBookingsState, { payload }: PayloadAction<string[]>) => {
      state.supplierFilters = payload;
    },
    resetFilters: (state: IAdminHotelBookingsState) => {
      state.status = null;
      state.statusesFilters = [];
      state.supplierFilters = [];
      state.checkInDateRangeFilter = null;
      state.checkOutDateRangeFilter = null;
      state.bookedOnDateRangeFilter = null;
    },
    resetSort: (state: IAdminHotelBookingsState) => {
      state.sort = null;
    },
    setSearch: (state: IAdminHotelBookingsState, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
    resetSearch: (state: IAdminHotelBookingsState) => {
      state.search = '';
    },
    setIsSearchLoading: (state: IAdminHotelBookingsState, { payload }: PayloadAction<boolean>) => {
      state.isSearchLoading = payload;
    },
    setAutocompleteResults: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<IHotelAutocompleteResult[]>,
    ) => {
      state.autocompleteResults = payload;
    },
    setSelectedSearchItem: (
      state: IAdminHotelBookingsState,
      { payload }: PayloadAction<IHotelAutocompleteResult>,
    ) => {
      state.selectedSearchItem = payload;
    },

    setExportCsv: (state: IAdminHotelBookingsState, { payload }: PayloadAction<IExportCsv>) => {
      state.exportCsv = payload;
    },
    setExportCsvLoading: (state: IAdminHotelBookingsState, { payload }: PayloadAction<boolean>) => {
      state.exportLoading = payload;
    },
    setExportCsvError: (state: IAdminHotelBookingsState, { payload }: PayloadAction<string>) => {
      state.exportError = payload;
    },
  },
});

export const adminHotelBookingsActions = adminHotelBookingsSlice.actions;

export const adminHotelBookingsReducer = adminHotelBookingsSlice.reducer;

const setParams = <T extends IHotelBookingsParamsBase>(
  params: T,
  adminBookingsStore: IAdminHotelBookingsState,
) => {
  const {
    sort,
    checkInDateRangeFilter,
    checkOutDateRangeFilter,
    bookedOnDateRangeFilter,
    supplierFilters,
    statusesFilters,
    status,
    search,
    selectedSearchItem,
  } = adminBookingsStore;

  const sortOrder = getSortOrder(sort);

  if (statusesFilters && statusesFilters.length) {
    params.statuses = statusesFilters;
  }

  if (!statusesFilters && status) {
    params.statuses = [status];
  }

  if (supplierFilters && supplierFilters.length) {
    params.suppliers = supplierFilters;
  }

  if (sortOrder) {
    params.bookingOrderBy = sortOrder;
  }

  if (search) {
    params.searchQuery = search;
  }

  if (selectedSearchItem) {
    params.searchBy = selectedSearchItem.matchedBy;
  }

  const filterOptions: IFilterOptions = {};
  params.filterOptions = filterOptions;

  if (checkInDateRangeFilter?.from || checkInDateRangeFilter?.to) {
    filterOptions.checkInDateRange = {
      from: checkInDateRangeFilter.from,
      to: checkInDateRangeFilter.to,
    };
  }

  if (checkOutDateRangeFilter?.from || checkOutDateRangeFilter?.to) {
    filterOptions.checkOutDateRange = {
      from: checkOutDateRangeFilter.from,
      to: checkOutDateRangeFilter.to,
    };
  }

  if (bookedOnDateRangeFilter?.from || bookedOnDateRangeFilter?.to) {
    filterOptions.bookedOnDateRange = {
      from: bookedOnDateRangeFilter.from,
      to: bookedOnDateRangeFilter.to,
    };
  }
};

export const getAdminHotelBookingsSearch = (search: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(adminHotelBookingsActions.setIsSearchLoading(true));

      const { data } = await hotelBookingsAdminAPI.searchHotelBookings(search);

      if (getState().adminHotelBookingsStore.search.length >= MIN_SEARCH_LENGTH) {
        const results = data.results.map((item) => ({
          ...item,
          id: v4(),
        }));

        dispatch(adminHotelBookingsActions.setAutocompleteResults(results));
      }

      dispatch(adminHotelBookingsActions.setIsSearchLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminHotelBookingsActions.setIsSearchLoading(false));
    }
  };
};

export const getAdminHotelBookings = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(adminHotelBookingsActions.setLoading(true));

    try {
      const { adminHotelBookingsStore } = getState();
      const params: IHotelBookingsParams = {
        pageNumber: adminHotelBookingsStore.pagination.current,
        pageSize: adminHotelBookingsStore.pagination.pageSize,
      };

      setParams(params, adminHotelBookingsStore);

      const { data } = await hotelBookingsAdminAPI.createHotelBookings(params);

      dispatch(adminHotelBookingsActions.setBookings(data.orders));
      dispatch(adminHotelBookingsActions.setOrderCount(data.totalCount));
      dispatch(adminHotelBookingsActions.setLoading(false));

      const {
        sort,
        checkInDateRangeFilter,
        checkOutDateRangeFilter,
        bookedOnDateRangeFilter,
        supplierFilters,
        statusesFilters,
        search,
        selectedSearchItem,
      } = adminHotelBookingsStore;

      UrlUtils.removeFromUrl(BOOKING_SUPPLIER_TYPES_LABEL);
      UrlUtils.removeFromUrl(BOOKING_STATUS_LABEL);
      UrlUtils.removeFromUrl(BOOKING_TABLE_SORT_ORDER);
      UrlUtils.removeFromUrl(BOOKING_TABLE_DATE_CHECK_IN_RANGE);
      UrlUtils.removeFromUrl(BOOKING_TABLE_DATE_CHECK_OUT_RANGE);
      UrlUtils.removeFromUrl(BOOKING_TABLE_DATE_BOOKED_ON_RANGE);
      UrlUtils.removeFromUrl(BOOKING_TABLE_SEARCH);
      UrlUtils.removeFromUrl(BOOKING_TABLE_MATCHED_BY);

      if (supplierFilters && supplierFilters.length) {
        UrlUtils.setUrl(BOOKING_SUPPLIER_TYPES_LABEL, supplierFilters);
      }

      if (statusesFilters && statusesFilters.length) {
        UrlUtils.setUrl(BOOKING_STATUS_LABEL, statusesFilters);
      }

      if (sort) {
        UrlUtils.setUrl(BOOKING_TABLE_SORT_ORDER, sort as Record<string, unknown>);
      }

      if (checkInDateRangeFilter?.from && checkInDateRangeFilter?.to) {
        UrlUtils.setUrl(BOOKING_TABLE_DATE_CHECK_IN_RANGE, checkInDateRangeFilter);
      }

      if (checkOutDateRangeFilter?.from && checkOutDateRangeFilter?.to) {
        UrlUtils.setUrl(BOOKING_TABLE_DATE_CHECK_OUT_RANGE, checkOutDateRangeFilter);
      }

      if (bookedOnDateRangeFilter?.from && bookedOnDateRangeFilter?.to) {
        UrlUtils.setUrl(BOOKING_TABLE_DATE_BOOKED_ON_RANGE, bookedOnDateRangeFilter);
      }

      if (search) {
        UrlUtils.setUrl(BOOKING_TABLE_SEARCH, search);
      }

      if (selectedSearchItem?.matchedBy) {
        UrlUtils.setUrl(BOOKING_TABLE_MATCHED_BY, selectedSearchItem.matchedBy);
      }

      UrlUtils.setUrl(BOOKING_TABLE_PAGINATION, {
        current: adminHotelBookingsStore.pagination.current,
        pageSize: adminHotelBookingsStore.pagination.pageSize,
      });
    } catch (error) {
      console.error(error);
      dispatch(adminHotelBookingsActions.setError(error.toString()));
      dispatch(adminHotelBookingsActions.setLoading(false));
    }
  };
};

export const getHotelExportCsv = (fileName: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(adminHotelBookingsActions.setExportCsvLoading(true));

    try {
      const { adminHotelBookingsStore } = getState();

      const params: IExportCsv = {
        fileName: fileName,
      };

      setParams(params, adminHotelBookingsStore);

      const res = await axiosInstance.post(Urls.HotelBookingsExportCsv, params, {
        ...getHeaders(),
      });

      dispatch(adminHotelBookingsActions.setExportCsv(res.data));
      dispatch(adminHotelBookingsActions.setExportCsvLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminHotelBookingsActions.setExportCsvError(error.toString()));
      dispatch(adminHotelBookingsActions.setExportCsvLoading(false));
    }
  };
};
