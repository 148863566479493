import React from 'react';
import { Modal } from 'antd';
import ImageGallery from 'react-image-gallery';
import { BlueButton } from '@share/components';
import { IImage } from '@share/common-types';
import './style.scss';

interface IProps {
  images: IImage[];
  visible: boolean;
  hotelName: string;
  startIndex: number;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export class HotelImagesSlider extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { images, visible, hotelName, startIndex, onCancel } = this.props;

    const imagesGallery = images.map((image) => {
      return {
        original: image.url,
        thumbnail: image.url,
      };
    });

    return (
      <Modal
        className="hotel-images-slider"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="hotel-images-slider-wrapper"
      >
        <div className="hotel-images-slider__header">
          <h4 className="hotel-images-slider__title">{hotelName}</h4>
        </div>
        <ImageGallery startIndex={startIndex} showIndex items={imagesGallery} />
      </Modal>
    );
  }
}

