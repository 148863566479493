
import AmericanLifestyle from '@assets/images/list-clickable-cards/american-lifestyle.png';
import BeforeYouGo from '@assets/images/list-clickable-cards/before-you-go.png';
import LocalBenefits from '@assets/images/list-clickable-cards/local-benefits.png';
import Membership from '@assets/images/list-clickable-cards/membership.png';
import { MEMBERSHIP_LINK } from '@constants';
import { Routes } from '@share/constants';

export const ListCards = [
  {
    title: 'local.benefits',
    content: 'save.money.with.daily.deals',
    image: LocalBenefits,
    url: Routes.DailyBenefits,
  },
  {
    title: 'before.you.go',
    content: 'need.a.crib',
    image: BeforeYouGo,
    url: Routes.Resources,
  },
  {
    title: 'american.lifestyle',
    content: 'our.monthly.magazine',
    image: AmericanLifestyle,
    url: Routes.AmericanLifestyle,
    excludeMLM: true,
  },
  {
    title: 'i.need.to.renew',
    content: 'i.need.to.book',
    image: Membership,
    url: MEMBERSHIP_LINK,
    excludeMLM: true,
  },
];

export interface INotificationBar {
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

