import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDatesState {
  startDateSelected: string;
  endDateSelected: string;
  startDate: string;
  endDate: string;
  key: string;
  error: string;
  isFocused?: boolean;
}

const initialState: IDatesState = {
  startDateSelected: undefined,
  endDateSelected: undefined,
  startDate: undefined,
  endDate: undefined,
  key: 'selection',
  error: '',
  isFocused: false,
};

const datesSlice = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    setDatesSelected: (
      state: IDatesState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDateSelected = payload.startDate;
      state.endDateSelected = payload.endDate;
    },
    setStartDateSelected: (state: IDatesState, { payload }: PayloadAction<string>) => {
      state.startDateSelected = payload;
    },
    setEndDateSelected: (state: IDatesState, { payload }: PayloadAction<string>) => {
      state.endDateSelected = payload;
    },
    setKey: (state: IDatesState, { payload }: PayloadAction<string>) => {
      state.key = payload;
    },
    setError: (state: IDatesState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setIsFocused: (state: IDatesState, { payload }: PayloadAction<boolean>) => {
      state.isFocused = payload;
    },
    resetDates: (state: IDatesState) => {
      state. startDateSelected = undefined;
      state.endDateSelected = undefined;
      state.startDate = undefined;
      state.endDate = undefined;
      state.key = 'selection';
      state.error = '';
      state.isFocused = false;
    },
    applyDates: (state: IDatesState) => {
      state.startDate = state.startDateSelected;
      state.endDate = state.endDateSelected;
    },
    resetSelectedDates: (state: IDatesState) => {
      state.startDateSelected = state.startDate;
      state.endDateSelected = state.endDate;
    },
  },
});

export const datesActions = datesSlice.actions;

export const datesReducer = datesSlice.reducer;
