import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';

import './style.scss';

interface Message {
  message: string;
  values: any;
}

interface IProps {
  message: string | Message;
  title: string;
  yesMessage: string;
  noMessage: string;
  visible: boolean;
  disabled?: boolean;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  onConfirm: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export class ModalConfirmation extends React.Component<IProps, null> {

  render(): React.ReactNode {
    const {
      title,
      message,
      yesMessage,
      noMessage,
      visible,
      disabled,
      onCancel,
      onConfirm
    } = this.props;

    let values = {};
    if (typeof message !== 'string') {
      values = { ...message.values };
    }

    values = { 
      ...values,
      BR: () => <br />,
      title: (message: string) => (<label style={{ width: '100px' }}>{message}</label>),
      strong: (message: string) => (<strong>{message}</strong>)
    };

    return (
      <Modal
        className="modal-cancellation-policies"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-cancellation-policies__wrapper"
      >
        <div>
          <h4 className="modal-cancellation-policies__title">
            <FormattedMessage id={title} />
          </h4>
          <div className="modal-cancellation-policies__refundability">
            <p className="modal-cancellation-policies__refundability-description">
              <FormattedMessage id={typeof message === 'string' ? message : message.message}  values={values} />
            </p>
            <div className="modal-cancellation-policies__buttons">
              <WhiteButton onClick={onCancel} disabled={disabled} >
                <FormattedMessage id={noMessage} />
              </WhiteButton>
              <BlueButton onClick={onConfirm} disabled={disabled} >
                <FormattedMessage id={yesMessage} />
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
