
import { isEmpty } from "lodash";
import { IAccount, LEAD_TIME_FIELD_NAME } from "@share/common-types";
import { LoginType } from "@share/store/slices";
import { LIFE_STYLE_NAME_PARAM, SESSION_KEY_TOKEN_NAME_PARAM } from "../constants/common";
import { UrlUtils } from "./urls";

export const getPreferenceFromAccount = (account: IAccount, key: string, defaultValue?: string): string => {
  if (!isEmpty(account?.preferences)) {
    const pref = account.preferences.find(p => p.key === key);
    if (!isEmpty(pref?.value)) {
      return pref.value;
    }
  }
  return !isEmpty(defaultValue)? defaultValue : null;
}

export const getPreferenceFileUrlFromAccount = (account: IAccount, key: string): string => {
  const preference =  getPreferenceFromAccount(account, key);
  if (!isEmpty(preference)) {
    try {
      const preferenceObj = JSON.parse(preference);
      if (!isEmpty(preferenceObj?.Value)) {
        return preferenceObj.Value;
      }
    } catch (e) {
      console.error('Parsing JSON error')
    }
  }
  return null;
}


export const getAccountLeadTime = (account: IAccount) => {
  const leadTimePref = getPreferenceFromAccount(account, LEAD_TIME_FIELD_NAME, '0');
    
  let leadTime = 0;
  try {
    leadTime = parseInt(leadTimePref);
  } catch(e) {
    leadTime = 0;
    console.error(e);
  }
  return leadTime;
}

export const IsClientCashEnabled = (account: IAccount) => {
  const isPublicAccountType = account?.type === LoginType.Public;
  const isPrivateWithLoginAccountType = account?.type === LoginType.PrivateWithLogin;
  const hasClientCash = account?.hasClientCash;

  return (isPublicAccountType || isPrivateWithLoginAccountType) && hasClientCash;
}

const GetPrivateTokenParam = (account: IAccount) => {
  const isPrivateWithTokenAccountType = account?.type === LoginType.PrivateWithToken;
  const privateTokenFromUrl = account?.privateTokenFromUrl;
  const privateTokenParam = `${(isPrivateWithTokenAccountType && privateTokenFromUrl) ? `${SESSION_KEY_TOKEN_NAME_PARAM}=${UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM)}` : ''}`;

  return privateTokenParam;
}

const GetLifeStyleParam = (account: IAccount) => {
  const hasClientCash = account?.hasClientCash;
  const lifeStyle = UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM);
  const lifeStyleParam = `${(hasClientCash && !isEmpty(lifeStyle)) ? `${LIFE_STYLE_NAME_PARAM}=${lifeStyle}` : ''}`;

  return lifeStyleParam;
}

export const GetHomeParams = (account: IAccount) => {
  const privateTokenParam = GetPrivateTokenParam(account);
  const lifeStyleParam = GetLifeStyleParam(account);

  const hasPrivateTokenParam = !isEmpty(privateTokenParam);
  const hasLifeStyleParam = !isEmpty(lifeStyleParam);
  
  if (hasPrivateTokenParam && hasLifeStyleParam) {
    return `?${privateTokenParam}&${lifeStyleParam}`;
  } else if (hasPrivateTokenParam) {
    return `?${privateTokenParam}`;
  } else if (hasLifeStyleParam) {
    return `?${lifeStyleParam}`;
  } else {
    return '';
  }
}

export const GetWalletNumber = (value: number, account: IAccount) => {
  const balance = (value || value === 0) ? account?.walletNoDecimals ? Math.floor(value) : Math.floor(value * 100) / 100 : null;
  return balance?.toLocaleString('en-US');
}