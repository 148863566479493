import React from 'react';

import { AdministrativeTools } from '../../components/dashboard/administrative-tools/administrative-section';
import { DashboardHero } from '../../components/dashboard/dashboard-hero';
import { RecentActivity } from '../../components/dashboard/recent-activity';
import { ExploreDiscount } from '../../components/dashboard/explore-discount';
import DiscoverFeatures from '../../components/dashboard/discover-features/discover-features-section/component';

const Dashboard = () => {
  return (
    <>
      <DashboardHero />
      <AdministrativeTools />
      <RecentActivity />
      <ExploreDiscount />
      <DiscoverFeatures />
    </>
  );
};

export { Dashboard };
