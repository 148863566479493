import React from 'react';
import { DatePicker, Select } from 'antd';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import Moment from 'moment';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SelectValue } from 'antd/lib/select';
import { FormattedMessage } from 'react-intl';
import { DateRangeFilterOptions } from '@common-types';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import './style.scss';

const { RangePicker } = DatePicker;

interface IProps extends FilterDropdownProps {
  dateRangeFilter: { from: string; to: string };
}

interface IState {
  isRendered: boolean;
  dateString: string[];
  selectedOption: DateRangeFilterOptions;
}

const zero = 0;
const one = 1;
const sevenDays = 7;
const thirtyDays = 30;
const blurDelay = 200;
const dateFormat = 'MM-DD-YYYY';
const dateFnsFormat = 'MM-dd-yyyy';
const selectOptions = [
  { value: DateRangeFilterOptions.SpecificDates, label: <FormattedMessage id="specific.dates" /> },
  {
    value: DateRangeFilterOptions.NextSevenDays,
    label: <FormattedMessage id="next.days" values={{ count: sevenDays }} />,
  },
  {
    value: DateRangeFilterOptions.NextThirtyDays,
    label: <FormattedMessage id="next.days" values={{ count: thirtyDays }} />,
  },
];

export class DateRangeTableFilter extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);
    const { dateRangeFilter } = props;

    this.state = {
      isRendered: false,
      dateString: dateRangeFilter ? [dateRangeFilter?.from, dateRangeFilter?.to] : [],
      selectedOption: DateRangeFilterOptions.SpecificDates,
    };
  }

  getPopupContainer = (): HTMLElement => {
    if (this.wrapperRef?.current) {
      return this.wrapperRef.current;
    }

    return document.body;
  };

  onChange = (value: [Moment.Moment, Moment.Moment], dateString: [string, string]): void => {
    if (
      dateString[zero] &&
      dateString[one] &&
      this.state.dateString[zero] &&
      this.state.dateString[one] &&
      this.state.dateString[zero] !== dateString[zero] &&
      this.state.dateString[one] === dateString[one]
    ) {
      this.setState({
        dateString: [dateString[zero], null],
      });
    } else {
      this.setState({
        dateString,
      });

      if (dateString[zero] && dateString[one]) {
        setTimeout(() => {
          const input: HTMLInputElement = document.querySelector(
            '.ant-table-filter-dropdown .ant-picker-input-active input:not(.ant-select-selection-search-input)',
          );

          if (input) {
            input.blur();
          }
        }, blurDelay);
      }
    }
  };

  onOptionChange = (value: SelectValue): void => {
    this.setState({ selectedOption: value as DateRangeFilterOptions });
  };

  componentDidMount(): void {
    this.setState({ isRendered: true });
  }

  onReset = (): void => {
    const { clearFilters } = this.props;

    this.setState({
      dateString: [],
      selectedOption: DateRangeFilterOptions.SpecificDates,
    });
    clearFilters();
  };

  onApply = (): void => {
    const { selectedOption, dateString } = this.state;
    const { setSelectedKeys, confirm } = this.props;
    const today = new Date();
    const tomorrow = addDays(today, one);
    const tomorrowStr = format(tomorrow, dateFnsFormat);
    const nextWeek = format(addDays(tomorrow, sevenDays), dateFnsFormat);
    const nextThirtyDays = format(addDays(tomorrow, thirtyDays), dateFnsFormat);

    if (
      selectedOption === DateRangeFilterOptions.SpecificDates &&
      dateString[zero] &&
      dateString[one]
    ) {
      setSelectedKeys([`${dateString[zero]}~${dateString[one]}`]);
    } else if (selectedOption === DateRangeFilterOptions.NextSevenDays) {
      setSelectedKeys([`${tomorrowStr}~${nextWeek}`]);
    } else if (selectedOption === DateRangeFilterOptions.NextThirtyDays) {
      setSelectedKeys([`${tomorrowStr}~${nextThirtyDays}`]);
    }

    confirm();
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { dateRangeFilter } = this.props;
    const { dateRangeFilter: prevDateRange } = prevProps;

    if (
      prevDateRange?.from !== dateRangeFilter?.from ||
      prevDateRange?.to !== dateRangeFilter?.to
    ) {
      this.setState({
        dateString: dateRangeFilter ? [dateRangeFilter?.from, dateRangeFilter?.to] : [],
      });
    }
  }

  render(): React.ReactNode {
    const { dateString, selectedOption } = this.state;
    const [from, to] = dateString;

    return (
      <div className="date-range-table-filter">
        <div className="date-range-table-filter__actions" ref={this.wrapperRef}>
          <div className="date-range-table-filter__select">
            <Select
              value={selectedOption}
              onChange={this.onOptionChange}
              dropdownClassName={`date-range-table-filter__select-drop ${
                selectedOption === DateRangeFilterOptions.SpecificDates ? 'full' : ''
              }`}
              getPopupContainer={this.getPopupContainer}
            >
              {selectOptions.map(({ value, label }) => {
                return (
                  <Select.Option value={value} key={value}>
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          {this.state.isRendered && selectedOption === DateRangeFilterOptions.SpecificDates ? (
            <RangePicker
              format={dateFormat}
              autoFocus={true}
              defaultOpen={true}
              open={true}
              allowEmpty={[true, true]}
              dropdownClassName="date-range-table-filter-dropdown"
              getPopupContainer={this.getPopupContainer}
              onChange={this.onChange}
              value={[from ? Moment(from, dateFormat) : null, to ? Moment(to, dateFormat) : null]}
            />
          ) : null}
          <div
            className={`date-range-table-filter__footer ${
              selectedOption !== DateRangeFilterOptions.SpecificDates ? 'border' : ''
            }`}
          >
            <WhiteButton onClick={this.onReset}>
              <FormattedMessage id="reset" />
            </WhiteButton>
            <BlueButton onClick={this.onApply}>
              <FormattedMessage id="apply" />
            </BlueButton>
          </div>
        </div>
      </div>
    );
  }
}
