import React, { useEffect } from "react";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.css";
// import './ImageGallery.css'; // Uncomment and use this line if you have custom CSS

interface Image {
  description: string;
  draggable: boolean;
  height: number;
  url: string;
  width: number;
}

interface TransformedImage {
  href: string;
  type: string;
  source: string;
  width?: number;
}

interface ImageGalleryProps {
  images: Image[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const uniqueImages = getUniqueImagesByDescription(images);

  const transformedImages:any = uniqueImages?.map((image:any) => ({
    href: image.url,
    type: "image",
    source: "local",
    width: image.width,
  }));

  useEffect(() => {
    const lightbox = GLightbox({
      selector: "a[data-glightbox]",
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
    });

    return () => {
      lightbox.destroy();
    };
  }, [transformedImages]);

  function getUniqueImagesByDescription(images) {
    const uniqueImages = {};
    let addedThisLoop;

    do {
      addedThisLoop = false;
      for (const image of images) {
        const normalizedDescription = image.description.trim().toLowerCase();
        if (!uniqueImages[normalizedDescription]) {
          uniqueImages[normalizedDescription] = image;
          addedThisLoop = true;
        }
      }
    } while (addedThisLoop);

    return Object.values(uniqueImages);
  }

  return (
    <section className="card-grid pt-0">
      <div className="container row-gutter">
        <div className="row g-2 row-gutter">
          {transformedImages?.length > 0 && (
            <div className="col-md-6 row-gutter">
              <a
                data-glightbox
                data-gallery="gallery"
                href={transformedImages[0].href}
              >
                <div
                  className="card card-grid-lg card-element-hover card-overlay-hover overflow-hidden"
                  style={{
                    backgroundImage: `url(${transformedImages[0].href})`,
                    backgroundPosition: "center left",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="hover-element position-absolute w-100 h-100">
                    <i className="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                  </div>
                </div>
              </a>
            </div>
          )}
          <div className="col-md-6 row-gutter">
            <div className="row g-2 row-gutter">
              {transformedImages?.slice(1, 2)?.map((image, index) => (
                <div className="col-12 row-gutter" key={`image-1-${index}`}>
                  <a data-glightbox data-gallery="gallery" href={image.href}>
                    <div
                      className="card card-grid-sm card-element-hover card-overlay-hover overflow-hidden"
                      style={{
                        backgroundImage: `url(${image.href})`,
                        backgroundPosition: "center left",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="hover-element position-absolute w-100 h-100">
                        <i className="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {transformedImages?.slice(2, 3)?.map((image, index) => (
                <div className="col-md-6 row-gutter" key={`image-2-${index}`}>
                  <a data-glightbox data-gallery="gallery" href={image.href}>
                    <div
                      className="card card-grid-sm card-element-hover card-overlay-hover overflow-hidden"
                      style={{
                        backgroundImage: `url(${image.href})`,
                        backgroundPosition: "center left",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="hover-element position-absolute w-100 h-100">
                        <i className="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {transformedImages?.length > 4 && (
                <div className="col-md-6 row-gutter">
                  <div
                    className="card card-grid-sm overflow-hidden"
                    style={{
                      backgroundImage: `url(${transformedImages[4].href})`,
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-overlay bg-dark opacity-7"></div>
                    <a
                      data-glightbox
                      data-gallery="gallery"
                      href={transformedImages[0].href}
                      className="stretched-link z-index-9"
                    ></a>
                    {transformedImages.slice(1)?.map((image, index) => (
                      <a
                        key={`image-3-${index}`}
                        data-glightbox
                        data-gallery="gallery"
                        href={image.href}
                      ></a>
                    ))}
                    <div className="card-img-overlay d-flex h-100 w-100">
                      <h6 className="card-title m-auto fw-light text-decoration-underline">
                        <a href="#" className="text-white">
                          <i className="bi bi-images pe-2"></i>View all (
                          {transformedImages.length})
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageGallery;
