import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';

import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';

import './style.scss';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

class ConciergeServicesComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    return (
      <div className="concierge-services">
        <div className="concierge-services__wrapper">
          <div className="concierge-services__info-wrapper">
            <div className="concierge-services__text-wrapper">
              <p className="concierge-services__title">
                <FormattedMessage id="concierge.services" />
              </p>
              <p className="concierge-services__description">
                <FormattedMessage id="you.are.now.member" />
              </p>
            </div>
            <Link to={`/${account?.name}${Routes.ConciergeTeam}`} className="concierge-services__btn-wrapper">
              <BlueButton>
                <FormattedMessage id="talk.to.our.team" />
              </BlueButton>
            </Link>
          </div>
          <div className="concierge-services__img-wrapper">
            <img src={ConciergeTeamImageLarge} alt="Concierge team" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const ConciergeServices = connect(mapStateToProps)(ConciergeServicesComponent);
