import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedCheckMarkSvg } from '@assets';
import './style.scss';

interface IProps {
  header: string;
  description: string;
}

export class CondoBookingFailedWarning extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { description, header } = this.props;

    return (
      <div className="condo-booking-failed-warning">
        <div className="condo-booking-failed-warning__icon">
          <RedCheckMarkSvg />
        </div>
        <div className="condo-booking-failed-warning__text">
          <div className="condo-booking-failed-warning__header">
            <FormattedMessage id={header} />
          </div>
          <div className="condo-booking-failed-warning__description">
            <FormattedMessage id={description} />
          </div>
        </div>
      </div>
    );
  }
}
