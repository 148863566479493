import React, { useEffect, useState } from "react";
import SideNav from "../../components/dashboard/side-nav/component";
import useApi from "../../../api-hook/component";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import UpdatePassword from "./update-password";
import PersonalInfo from "./personal-info";
import { toast } from "react-toastify";

const AccountSettings = () => {
  const { fetchData } = useApi("https://qa2-api.travcoding.com");
  const [resetPassLoading, setResetPassLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState();

  const { getValue } = useLocalStorage();
  const currentUser: any = getValue("user");

  const onFinish = async (values) => {
    setResetPassLoading(true);
    values.userId = currentUser?.userId;
    delete values.confirmNewPassword;
    try {
      const result = await fetchData(
        "/identity/password/change",
        values,
        "post"
      );
      if (result.message === "Password successfully change.") {
        toast.success("Password updated successfully", {
          theme: "colored",
        });
      }
    } catch (e) {
    } finally {
      setResetPassLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const getUser = await fetchData(
        `/identity/users/${currentUser?.userId}`,
        {},
        "get"
      );
      setUserData(getUser);
    };

    fetchUserData();
  }, [currentUser?.userId]);

  return (
    <section className="pt-3">
      <div className="container">
        <div className="row">
          <SideNav />
          <div className="col-lg-8 col-xl-9">
            <PersonalInfo data={userData} />
            <UpdatePassword
              handleSubmit={onFinish}
              resetPassLoading={resetPassLoading}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountSettings;
