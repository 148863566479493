import React from 'react';
import moment from 'moment';
import Icon from '@ant-design/icons';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { DATE_ONLY_MONTH_FORMAT } from '@share/constants';
import { DATE_FORMAT } from '@share/constants';
import { getSelectedMonthText } from '@share/utils';
import { LocationGreySvg, CondoSvg } from '@share/assets';
import { SearchHandlerArgs } from '@common-types';
import { CondoLocationsEnum, ICondoRecentSearches } from '@share/common-types';
import { formatDateCheckInOut } from '@share/utils';

import './style.scss';

interface IProps extends WrappedComponentProps {
  data: ICondoRecentSearches;
  disabled: boolean;
  onSearchHandler: SearchHandlerArgs;
}

const ONE = 1;

class CondoRecentSearchesComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { data, disabled, onSearchHandler, intl } = this.props;
    const {
      months,
      checkIn,
      checkOut,
      location,
      searchType,
      adultsCount,
      stayDuration,
      childrenCount,
      bedroomsCount,
      includeStudio,
    } = data;
    const guestsInfo = {
      adultsCount,
      bedroomsCount,
      kidsCount: childrenCount,
      includeStudio,
    };

    const isGenericRegion = location.locationType === CondoLocationsEnum.GenericRegion;
    const formattedMonths = months?.map((month: any) => moment(month, 'yyyy-MM-DD').format('DD MMM yyyy'));
    const formattedStartDate = checkIn && formatDateCheckInOut(checkIn, DATE_ONLY_MONTH_FORMAT);
    const formattedEndDate = checkOut && formatDateCheckInOut(checkOut, DATE_FORMAT);

    return (
      <div
        role="button"
        className={`recent-searches-picker ${disabled ? 'disabled' : ''}`}
        onClick={() =>{
          if (!disabled) {
            onSearchHandler(
              location,
              searchType,
              stayDuration,
              guestsInfo,
              formattedMonths,
              checkIn,
              checkOut,
            );
          }
        }}
      >

        <Icon component={isGenericRegion ? LocationGreySvg : CondoSvg} />
        <div className="recent-searches-picker__info">
          <p className="recent-searches-picker__title">{location.name + (!location.region ? '' : ', ' + location.region)}</p>
          <p className="recent-searches-picker__subtitle ttl">
            {checkIn ? (
              <span>
                {formattedStartDate} - {formattedEndDate}
              </span>
            ) : (
              <>
                {ONE} <FormattedMessage id="week" values={{ count: ONE }} />{' '}
                {formattedMonths?.length ? (
                  <span>
                    <FormattedMessage id="in" /> {getSelectedMonthText(formattedMonths, intl)}
                  </span>
                ) : (
                  <span>
                    <FormattedMessage id="anytime" />
                  </span>
                )}
              </>
            )}
          </p>
          <p className="recent-searches-picker__subtitle">
            {adultsCount} <FormattedMessage id="adult" values={{ count: adultsCount }} />,{' '}
            {!!childrenCount && (
              <>
                {childrenCount} <FormattedMessage id="child" values={{ count: childrenCount }} />,{' '}
              </>
            )}
            {!guestsInfo?.includeStudio ? (
              <>
                {bedroomsCount} <FormattedMessage id="bedroom" values={{ count: bedroomsCount }} />
              </>) : (
              <>
                <FormattedMessage id="Studio" />
              </>)}
          </p>

          <div className={`search-again ${disabled ? 'disabled' : ''}`}>
            <FormattedMessage id="availability.status.modal.search_again" />
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
    );
  }
}

export const CondoRecentSearches = injectIntl(CondoRecentSearchesComponent);
