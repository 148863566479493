import React from 'react';

import { SideKickRewardsPrivacy, SideKickRewardsTerms } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

class SidekickPrivacyPageComponent extends React.Component<IProps, null> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="sidekick-privacy-page">
        <SideKickRewardsPrivacy />
      </div>
    );
  }
}

export const SidekickPrivacyPage = SidekickPrivacyPageComponent;
