import React from 'react';
import { Card, Row, Col } from 'antd';

interface CardProps {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  imageSrc: string;
}

interface CardCarouselProps {
  Tools: CardProps[];
}

const CardCarousel: React.FC<CardCarouselProps> = ({ Tools }) => {
  return (
    <div className="site-card-wrapper m-auto" style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <div className="header" style={{ textAlign: 'center', margin: '10px' }}>
        <h2>Administrative tools</h2>
        <p>Take control of your account by using these tools to manage your team and bookings</p>
      </div>
      <Row gutter={16}>
        {Tools.map((card, index) => (
          <Col xs={24} sm={12} md={12} lg={8} key={index}>
            <CustomCard {...card} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

const CustomCard: React.FC<CardProps> = ({
  title,
  description,
  buttonText,
  buttonLink,
  imageSrc,
}) => {
  return (
    <div className="card custom-card">
      <img src={imageSrc} className="card-img card-img-height" alt={imageSrc} />
      <div className="position-absolute top-100 start-50 translate-middle w-100">
        <div className="card-body text-center bg-white shadow  rounded mx-4 p-3">
          <h6 className="card-title mb-1">
            <a href={buttonLink}>{title}</a>
          </h6>
          <small>{description}</small>
          <div className="mt-2 ">
            <a href={buttonLink} className="btn btn-sm btn-dark mb-0">
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCarousel;
