
import { Urls } from "@share/constants";
import { axiosInstance, getHeaders } from "@share/utils";
import { isEmpty } from "lodash";
import { IUser } from "../common-types/user";

export const GetUserById = (userId: string, isWidget?: boolean) => {
    return axiosInstance.get(`${Urls.Users}/${userId}`, {
        ...getHeaders(isWidget),
    });
}

export const GetUserByAccountNameKeyId = (accountName: string, data: any, isWidget?: boolean) => {
    return axiosInstance.post(`${Urls.Users}/${accountName}`, data, {
        ...getHeaders(isWidget),
    });
}

export const GetUserByUsernameAndPassword = (accountId: string, username: string, password: string, bookingID: string) => {
    const data: any = { username, password, accountId }; 

    if (!isEmpty(bookingID)) {
        data.bookingID = bookingID;
    }

    return axiosInstance.post(`${Urls.Users}/login`, data);
}

export const SignUpUser = (accountId: string, username: string, firstname: string, lastname: string, password: string, email: string, bookingID: string) => {
    const data: any = { username, firstname, lastname, password, accountId, email }; 

    if (!isEmpty(bookingID)) {
        data.bookingID = bookingID;
    }

    return axiosInstance.post(`${Urls.Users}/sign-up`, data);
}

export const GetUserByImpersonate = (impersonationUser: string, memberId: number, storeType: string) => {
    const data = { Impersonator: { IsImpersonating: true, ImpersonationUser: impersonationUser, MemberId: memberId, StoreType: storeType } }; 
    return axiosInstance.post(`${Urls.Users}/login`, data);
}

export const EndImpersonate = () => {
    return axiosInstance.post(`${Urls.Users}/logout`);
}

export const GetUserWalletById = (userId: string, sessionKey: string, lifyStyle: string) => {
    const sessionKeyParam = !isEmpty(sessionKey) ? `?sessionKey=${sessionKey}` : '';
    const lifyStyleParam = !isEmpty(lifyStyle) ? lifyStyle : 'none';
    return axiosInstance.get(`${Urls.WalletUser}/${userId}/${lifyStyleParam}${sessionKeyParam}`, { ...getHeaders(false) });
}

export const GetUserCreditCards = () => {
    return axiosInstance.get(Urls.UsersCards, {
        ...getHeaders(),
    });
}

export const AddUserCreditCard = (data: any, user: IUser) => {
    const month = data?.expireDate?.substring(0, 2);
    const year = data.expireDate?.substring(data.expireDate.length - 4);

    return axiosInstance.post(Urls.UsersCards, {
        billing: {
            address: {
                line1: data?.addressLine,
                city: data?.city,
                country: data?.country,
                state: data?.state,
                postalCode: data?.zipCode
            },
            email: user?.email,
            phone: data?.phone,
            name: data?.holderName
        },
        Card: {
            Number: data?.cardNumber,
            ExpMonth: month,
            ExpYear: year,
            CVC: data?.cvv
        }
    });
}

export const DeleteUserCreditCard = (paymentId: any) => {
    return axiosInstance.delete(`${Urls.UsersCards}/${paymentId}`);
}

