import React from 'react';
import { FormattedMessage } from 'react-intl';
import './style.scss';

interface IProps {
  customerServiceNumber: string;
}

export class CondoSpecials extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { customerServiceNumber } = this.props;

    return (
      <div className="condo-specials">
        <div className="condo-specials__wrapper">
          <p className="condo-specials__title">
            <FormattedMessage id="condo.specials" />
          </p>
          <div className="condo-specials__descriptions-wrapper">
            <p className="condo-specials__description">
              <FormattedMessage id="every.week.we.will.attempt.to.find.deeply.discounted" />
            </p>
            <p className="condo-specials__description">
              <FormattedMessage id="these.are.call-in.and.they.go.fast" />{' '}
              <a href={`tel:${customerServiceNumber}`} className="condo-specials__contact-phone">
                {customerServiceNumber}
              </a>{' '}
              <FormattedMessage id="to.book.today" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
