import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { RootState } from '@share/utils';
import { 
  getAccounts,
  deleteAccount,
  activateAccount,
  setAccountPagination,
  setAccountSorting,
  setRetrieveDeleted,
  IAccountsState,
  TableSortingConfig
} from '@store/slices';
import { BookingTabsEnum } from '@common-types';
import { IAccount } from '@share/common-types';
import { RefreshSvg } from '@assets';
import { PlusSvg } from '@share/assets';
import { Routes } from '@share/constants';
import { isEmpty } from 'lodash';
import { BlueButton } from '@share/components';
import { ModalConfirmation } from '@components';
import { AccountsSearch } from '..';

import './style.scss';

interface IMapStateToProps {
  accountsStore: IAccountsState;
}

interface IMapDispatchToProps {
  getAccounts: (page?: number) => void;
  deleteAccount: (accountId: number) => void;
  activateAccount: (accountId: number) => void;
  setAccountPagination: (pagination: TablePaginationConfig) => void;
  setAccountSorting: (sorting: TableSortingConfig) => void;
  setRetrieveDeleted: (retrieve: boolean) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {}

interface IState {
  selectedTab: BookingTabsEnum;
  accountToDeleteActivate: number;
  showDeleteActivateConfirmation: boolean;
  isDeleteAction: boolean;
}

const zero = 0;
const one = 1;
const pageSizeOptions = ['10', '20', '50'];

class AllAccountsComponent extends React.Component<IProps, IState> {
  state: IState = {
    selectedTab: BookingTabsEnum.Condos,
    showDeleteActivateConfirmation: false,
    accountToDeleteActivate: null,
    isDeleteAction: null
  };

  changeTab = (tab: string): void => {
    this.setState({
      selectedTab: tab as BookingTabsEnum,
    });
  };

  componentDidMount(): void {
    this.props.getAccounts(1);
  }

  getColumns = (): ColumnsType<IAccount> => {
    return [
      {
        title: (
          <span>
            <FormattedMessage id="id" /> #
          </span>
        ),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => (
          <div className="search-accounts__link">
            <Link to={`${Routes.Account}/${id}`}>{id}</Link>
          </div>
        ),
        width: '10%',
        sorter: (a, b) => a.id - b.id
      },
      {
        title: (
          <span>
            <FormattedMessage id="username" />
          </span>
        ),
        dataIndex: 'username',
        key: 'username',
        render: (username: string) => (
          <div>{username}</div>
        ),
        width: '20%',
        sorter: (a, b) => a.username.localeCompare(b.username)
      },
      {
        title: (
          <span>
            <FormattedMessage id="email" />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        render: (email: string) => (
          <div>{email}</div>
        ),
        width: '20%',
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: (
          <span>
            <FormattedMessage id="name" />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => (
          <div>{name}</div>
        ),
        width: '20%',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: (
          <span>
            <FormattedMessage id="account.type" />
          </span>
        ),
        dataIndex: 'type',
        key: 'type',
        render: (type: string) => (
          <div>{type}</div>
        ),
        width: '10%',
        sorter: (a, b) => a.type.localeCompare(b.type)
      },
      {
        title: <FormattedMessage id="action" />,
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) => {
          if (record?.active === 0) {
            return (
              <div className="search-accounts__link">
                <div className="search-accounts__delete" onClick={() => this.handleActivate(record.id)}>
                  <FormattedMessage id="activate" />
                </div>
              </div>
            );  
          }
          return (
            <div className="search-accounts__link">
              <div className="search-accounts__delete" onClick={() => this.handleDelete(record.id)}>
                <FormattedMessage id="delete" />
              </div>
            </div>
          );
        },
        width: '10%',
      },
    ];
  };

  getTotalTitle = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span>
        {range[zero]}-{range[one]} <FormattedMessage id="of" />{' '}
        <FormattedMessage id="items.count" values={{ count: total }} />
      </span>
    );
  };

  onTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any): void => {
    if (!isEmpty(sorter)) {
      const sorting = {
        key: sorter.field,
        type: sorter.order
      };
      this.props.setAccountSorting(sorting);
    } else {
      this.props.setAccountSorting(null);
    }   
    this.props.setAccountPagination(pagination);
    this.props.getAccounts();
  };

  onRefresh = (): void => {
    this.props.getAccounts();
  };

  handleDelete = (accountToDeleteActivate: number) => {
    this.setState({
      showDeleteActivateConfirmation: true,
      isDeleteAction: true,
      accountToDeleteActivate  
    });
  }

  handleActivate = (accountToDeleteActivate: number) => {
    this.setState({
      showDeleteActivateConfirmation: true,
      isDeleteAction: false,
      accountToDeleteActivate  
    });
  }

  handleCancelModal = () => {
    this.setState({
      showDeleteActivateConfirmation: false,
      isDeleteAction: null,
      accountToDeleteActivate: null 
    });
  }
  handleConfirmModal = () => {
    if (this.state.isDeleteAction) {
      this.props.deleteAccount(this.state.accountToDeleteActivate);
    } else {
      this.props.activateAccount(this.state.accountToDeleteActivate);
    }

    this.setState({
      showDeleteActivateConfirmation: false,
      isDeleteAction: null,
      accountToDeleteActivate: null 
    });
  }

  handleDisplayDeletedChange = () => {
    const { accountsStore } = this.props;
    const { retrieveDeleted } = accountsStore;
    this.props.setRetrieveDeleted(!retrieveDeleted);
    this.props.getAccounts();
  }

  render(): React.ReactNode {
    const { accountsStore } = this.props;
    const { accounts, pagination, loading, retrieveDeleted } = accountsStore;
    const { isDeleteAction } = this.state;
    
    return (
      <div className="search-accounts">
        <div className="search-accounts__wrapper">
          <div className="search-accounts__header">
            <div className="search-accounts__actions">
              <div className="search-accounts__search">
                <AccountsSearch />
              </div>
              <div className="search-accounts__buttons">
                <Link to={`${Routes.Account}`}>
                  <BlueButton>
                    <PlusSvg />
                    <FormattedMessage id="add.new" />
                  </BlueButton>
                </Link>

                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={this.handleDisplayDeletedChange}
                    checked={retrieveDeleted}
                    id="flexSwitchCheckDefault"/>
                  <label 
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault">
                      Show Deleted
                  </label>
                </div> 

                <div onClick={this.onRefresh}>
                  <RefreshSvg />
                </div>
              </div>
            </div>
          </div>
          <div className="search-accounts__table">
            <Table
              loading={loading}
              onChange={this.onTableChange}
              rowKey="id"
              columns={this.getColumns()}
              dataSource={accounts}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                pageSizeOptions,
                total: pagination.total,
                showTotal: this.getTotalTitle,
              }}
            />
          </div>
        </div>
        <ModalConfirmation
            message={isDeleteAction ? 'Are you sure you want to Delete this account?' : 'Are you sure you want to Activate this account?'}
            title={isDeleteAction ? 'Confirm Delete' : 'Confirm Activate'}
            yesMessage="Confirm"
            noMessage="Cancel"
            visible={this.state.showDeleteActivateConfirmation}
            onCancel={this.handleCancelModal}
            onConfirm={this.handleConfirmModal}
          />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    accountsStore: state.accountsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getAccounts,
  deleteAccount,
  activateAccount,
  setRetrieveDeleted,
  setAccountPagination,
  setAccountSorting
};

const ComponentWithIntl = injectIntl(AllAccountsComponent);

export const AllAccounts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComponentWithIntl));
