import React from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ICondoReviewBookState } from '@store/slices';
import { ICondoDetailsState } from '@share/store/slices';
import { getAccountUsernameFromPath, RootState } from '@share/utils';
import { Routes } from '@share/constants';
import { BookingErrorsEnum } from '@share/common-types';

import Image from '@assets/images/availability-status.png';

import './style.scss';
interface IMapStateToProps {
  condoDetailsStore: ICondoDetailsState;
  condoReviewBookStore: ICondoReviewBookState;
}
interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {}
interface IState {
  visible: boolean;
}
const modalWidth = 400;
class CondoAvailabilityStatusModalComponent extends React.Component<IProps, IState> {
  state: IState = { visible: true };
  handleGoToDetails = () => {
    const { condoReviewBookStore } = this.props;
    const accountName = getAccountUsernameFromPath(this.props.history);
    this.props.history.push(`/${accountName}${Routes.Condo}/${condoReviewBookStore.condoBookingSummary.bookingCard.condoId}/${location.search}`);
  }
  handleGoToSearch = () => {
    const accountName = getAccountUsernameFromPath(this.props.history);
    this.props.history.push(`/${accountName}${Routes.CondoSearch}${location.search}`);
  }
  render(): React.ReactNode {
    const { visible } = this.state;
    const { condoDetailsStore, condoReviewBookStore } = this.props;
    const hasSoldout = BookingErrorsEnum.SoldOut === condoDetailsStore.error || BookingErrorsEnum.SoldOut === condoReviewBookStore.bookingErrorCode;
    const isUnavailable = BookingErrorsEnum.RoomsUnavailable === condoDetailsStore.error || BookingErrorsEnum.RoomsUnavailable === condoReviewBookStore.bookingErrorCode;;
    return (
      <div className="price-change-modal">
        <Modal
          className="price-change-modal-wrapper"
          wrapClassName="price-change-modal-wrapper__wrap"
          visible={visible && (hasSoldout || isUnavailable)}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={modalWidth}
        >
          <div className="price-change-modal-wrapper__wrapper">
            <img src={Image} alt="" />
            <span className="price-change-modal-wrapper__title"><FormattedMessage id={'unit.is.no.available'} /></span>
            <span className="price-change-modal-wrapper__message">
              <FormattedMessage id={'condo.availability.status.modal.message'} />
              <Tooltip placement="rightBottom" title={this.props.intl.formatMessage({ id: 'condo.availability.status.modal.tooltip' })}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
            <div className={`price-change-modal-wrapper__buttons`}>
              <div className="accept-button" onClick={this.handleGoToSearch}><FormattedMessage id={'availability.status.modal.search_again'} /></div>
              {condoReviewBookStore.bookingErrorCode && <div className="go-to-search-button" onClick={this.handleGoToDetails}><FormattedMessage id={'availability.status.modal.back_detail'} /></div>}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoDetailsStore: state.condoDetailsStore,
    condoReviewBookStore: state.condoReviewBookStore,
  };
};
export const CondoAvailabilityStatusModal = connect(mapStateToProps)(injectIntl(withRouter(CondoAvailabilityStatusModalComponent)));
