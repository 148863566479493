
import moment from 'moment';
import axios, { Canceler } from 'axios';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { isEmpty } from 'lodash';

import { IBounds, IClientCash, ICondoLocation, ISessionKey } from '@share/common-types';
import { DEFAULT_PAGE_NUMBER, DEFAULT_MAP_LIMIT, SortTypes, CONDO_RECENT_SEARCHES_LABEL } from '@share/constants';
import {
  AppThunk,
  axiosInstance,
  getHeaders,
  RootState,
} from '@share/utils';
import { getTimeout } from '@share/utils';
import { condoFiltersActions } from '@share/store/slices';

import { trustYouReviewActions } from './trust-you-review';
import { condoDetailsActions } from './condo-details';
import { condosLocationPickerActions } from './condo-location-picker';
import { condoGuestsActions } from './condo-guests';
import { condoFlexibleDatePickerActions } from './condo-flexible-date-picker';
import { condoStrictDatesActions } from './condo-strcit-date-picker';


import {
  CondoRequestTypeEnum,
  ICondo,
  ICondoCounters,
  ICondoRecentSearches,
  ICondoSearchResponse,
  SearchTypeEnum,
} from '@share/common-types';
import {
  getCondoRequestType,
  getCondoRequestUrl,
  getCondoSearchBody,
  shouldDisplayAnytimeWarning,
} from '@share/utils';

const zero = 0;

export interface ICondosState {
  isSearch: boolean;
  isWidget: boolean;
  condos: ICondo[];
  prevCondos: ICondo[];
  mapCondos: ICondo[];
  selectedCompareCondos: ICondo[];
  loading: boolean;
  loadingMore: boolean;
  error: string;
  pageNumber: number;
  counters: ICondoCounters;
  sessionKey: ISessionKey;
  isSessionExpired: boolean;
  bounds: IBounds;
  cachedSelectedLocation: ICondoLocation;
  isFinishedWithError: boolean;
  lastRequestType: CondoRequestTypeEnum;
  cachedStartDate: string;
  cachedEndDate: string;
  selectedCondo: ICondo;
  isFocusDatePicker: boolean;
  forceHideFilters: boolean;
  isAnytimeSearchWarningVisible: boolean;
  selectedCondoSearchClientCash: IClientCash;

}

const initialState: ICondosState = {
  isSearch: false,
  isWidget: false,
  condos: null,
  prevCondos: null,
  mapCondos: null,
  selectedCompareCondos: [],
  loading: false,
  loadingMore: false,
  error: '',
  pageNumber: DEFAULT_PAGE_NUMBER,
  counters: null,
  sessionKey: null,
  isSessionExpired: false,
  bounds: null,
  cachedSelectedLocation: null,
  isFinishedWithError: false,
  lastRequestType: CondoRequestTypeEnum.Anytime,
  cachedStartDate: null,
  cachedEndDate: null,
  selectedCondo: null,
  isFocusDatePicker: false,
  forceHideFilters: false,
  isAnytimeSearchWarningVisible: false,
  selectedCondoSearchClientCash: null,
};

const condosSlice = createSlice({
  name: 'condos',
  initialState,
  reducers: {
    setIsSearch: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.isSearch = payload;
    },
    setLastRequestType: (state: ICondosState, { payload }: PayloadAction<CondoRequestTypeEnum>) => {
      state.lastRequestType = payload;
    },
    setIsFinishedWithError: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.isFinishedWithError = payload;
    },
    setLoading: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLoadingMore: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.loadingMore = payload;
    },
    setError: (state: ICondosState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPageNumber: (state: ICondosState, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setCounters: (state: ICondosState, { payload }: PayloadAction<ICondoCounters>) => {
      state.counters = payload;
    },
    setSelectedCondoSearchClientCash: (state: ICondosState, { payload }: PayloadAction<IClientCash>) => {
      state.selectedCondoSearchClientCash = payload;
    },
    setSelectedCompareCondos: (state: ICondosState, { payload }: PayloadAction<ICondo[]>) => {
      state.selectedCompareCondos = payload;
    },
    setSessionKey: (state: ICondosState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKey = payload;
    },
    setIsSessionExpired: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.isSessionExpired = payload;
    },
    setCondos: (state: ICondosState, { payload }: PayloadAction<ICondo[]>) => {
      state.condos = payload;
    },
    setPrevCondos: (state: ICondosState, { payload }: PayloadAction<ICondo[]>) => {
      state.prevCondos = payload;
    },
    setBounds: (state: ICondosState, { payload }: PayloadAction<IBounds>) => {
      state.bounds = payload;
    },
    setIsWidget: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.isWidget = payload;
    },
    setCachedSelectedLocation: (
      state: ICondosState,
      { payload }: PayloadAction<ICondoLocation>,
    ) => {
      state.cachedSelectedLocation = payload;
    },
    addCondos: (state: ICondosState, { payload }: PayloadAction<ICondo[]>) => {
      state.condos = [...(state.condos || []), ...payload];
    },
    setSelectedCondo: (state: ICondosState, { payload }: PayloadAction<ICondo>) => {
      state.selectedCondo = payload;
    },
    setMapCondos: (state: ICondosState, { payload }: PayloadAction<ICondo[]>) => {
      state.mapCondos = payload;
    },
    addMapCondo: (state: ICondosState, { payload }: PayloadAction<ICondo>) => {
      state.mapCondos.push(payload);
    },
    addMapCondos: (state: ICondosState, { payload }: PayloadAction<ICondo[]>) => {
      state.mapCondos = [...(state.mapCondos || []), ...payload];
    },
    setCachedDates: (
      state: ICondosState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.cachedStartDate = payload.startDate;
      state.cachedEndDate = payload.endDate;
    },
    setIsFocusDatePicker: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.isFocusDatePicker = payload;
    },
    setForceHideFilters: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.forceHideFilters = payload;
    },
    setDefaultValues: () => {
      return initialState;
    },
    setIsAnytimeSearchWarningVisible: (state: ICondosState, { payload }: PayloadAction<boolean>) => {
      state.isAnytimeSearchWarningVisible = payload;
    },
  },
});

export const condosActions = condosSlice.actions;

export const condosReducer = condosSlice.reducer;

let cancelRequest: Canceler;
let expirationTimer: number;

const setSessionExpirationTimer = (
  session: ISessionKey,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
): void => {
  if (expirationTimer) {
    clearTimeout(expirationTimer);
  }

  // @ts-ignore
  expirationTimer = setTimeout(() => {
    dispatch(condosActions.setIsSessionExpired(true));
  }, getTimeout(session?.expireDate));
};

export const getCondos = (searchType: SearchTypeEnum, isReuse = false): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const {
        condosStore,
        condosLocationPickerStore,
        condoStrictDatesStore,
        condoFlexibleDatePickerStore,
        condoFiltersStore,
        condoGuestsStore,
        loginStore
      } = getState();
      const { cachedSelectedLocation } = condosStore;
      const { account } = loginStore;
      const requestType = getCondoRequestType(condoStrictDatesStore);

      if (!isReuse && condosStore.pageNumber === DEFAULT_PAGE_NUMBER) {
        dispatch(condosActions.setIsAnytimeSearchWarningVisible(
          shouldDisplayAnytimeWarning(
            requestType,
            condoFlexibleDatePickerStore)));
      }

      const walletWalletSavings = loginStore.account.walletWalletSavings;
      if (
        (requestType === CondoRequestTypeEnum.Anytime &&
         condoFiltersStore.sortType !== SortTypes.MostPopular &&
         condoFiltersStore.sortType !== SortTypes.Nearest) || (account?.isB2C && !walletWalletSavings)
      ) {
        dispatch(condoFiltersActions.setSortType(SortTypes.MostPopular));
      }

      dispatch(condosActions.setIsFinishedWithError(false));
      dispatch(condosActions.setSelectedCondo(null));

      if (searchType === SearchTypeEnum.Pagination) {
        dispatch(condosActions.setLoadingMore(true));
      } else {
        dispatch(condosActions.setLoading(true));
        dispatch(condosActions.setBounds(null));    
        dispatch(condosActions.setCounters(null));    
        dispatch(condosActions.setMapCondos([]));    
        dispatch(condosActions.setPrevCondos(null));    
        dispatch(condosActions.setCondos([]));
        dispatch(condosActions.setSelectedCompareCondos([]));
      }

      if (!condosStore.condos) {
        dispatch(condosActions.setCondos([]));
      }

      if (cancelRequest) {
        cancelRequest();
      }

      const condoRequestBody = getCondoSearchBody(searchType, getState, isReuse);
      
      const { data }: { data: ICondoSearchResponse } = await axiosInstance.post(
        getCondoRequestUrl(
          searchType,
          condoStrictDatesStore,
          isReuse,
        ),
        condoRequestBody,
        {
          ...getHeaders(),
          cancelToken: new axios.CancelToken((canceler: Canceler) => {
            cancelRequest = canceler;
          }),
        },
      );

      dispatch(
        condosActions.setCachedSelectedLocation(
          condosLocationPickerStore.selectedLocation
            ? condosLocationPickerStore.selectedLocation
            : cachedSelectedLocation,
        ),
      );
      dispatch(
        condosActions.setCachedDates({
          startDate: condoStrictDatesStore.startDate,
          endDate: condoStrictDatesStore.endDate,
        }),
      );
      dispatch(condosActions.setLoading(false));

      if (data.sessionKey) {
        const sessionKey = { ...data.sessionKey } as ISessionKey;
        dispatch(condosActions.setSessionKey(sessionKey));
        dispatch(condosActions.setIsSessionExpired(false));
        
        setSessionExpirationTimer(sessionKey, dispatch);
      }

      dispatch(condosActions.setLoadingMore(false));
      dispatch(condosActions.setPrevCondos(data.searchCondos));

      if (searchType === SearchTypeEnum.Pagination) {
        dispatch(condosActions.addCondos(data.searchCondos));
        dispatch(condosActions.addMapCondos(data.searchCondos));
      } else {
        dispatch(condosActions.setCondos(data.searchCondos));
        dispatch(condosActions.setMapCondos(data.searchCondos.slice(zero, DEFAULT_MAP_LIMIT)));
      }

      if (searchType === SearchTypeEnum.NewSearch && !data.searchCondos?.length) {
        dispatch(condosActions.setForceHideFilters(true));
      } else {
        dispatch(condosActions.setForceHideFilters(false));
      }

      
      if (data.counters) {
        dispatch(condosActions.setCounters(data.counters));
      } else {
        dispatch(condosActions.setCounters(null));
      }

      if (searchType === SearchTypeEnum.NewSearch || searchType === SearchTypeEnum.SortsFilters
        ) {
        if (data.bounds) {
          dispatch(condosActions.setBounds(data.bounds));
        } else {
          dispatch(condosActions.setBounds(null)); 
        }
      }

      dispatch(condosActions.setLastRequestType(requestType));
      dispatch(condosActions.setIsFinishedWithError(false));
      dispatch(trustYouReviewActions.setReviewModule(null));
      dispatch(condoDetailsActions.setCondoDetails(null));

      // Adding the search to the storage
      const recentSearchesStorage = localStorage.getItem(CONDO_RECENT_SEARCHES_LABEL);
      const recentSearchesObject = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
      const recentSearchesFiltered = recentSearchesObject?.length ?
        recentSearchesObject.filter((search: ICondoRecentSearches) => {
          if (search.checkIn && search.checkOut) {
            return moment().startOf('day').isSameOrBefore(moment(search.checkIn, 'yyyy-MM-DD'));
          }
          return true;
        }) : [];
      const recentSearchesValid = recentSearchesFiltered.length > 10 ? recentSearchesFiltered.slice(0,8) : recentSearchesFiltered;
      const currentSearch = { 
        ...condoRequestBody.condosRequest,
        searchType: requestType
      };
      const currentSearchFinal = { ...currentSearch, bedroomsCount: condoGuestsStore.bedroomsCount, includeStudio: condoGuestsStore.includeStudio };
      const currentSearchStr = JSON.stringify(currentSearchFinal);
      if (!recentSearchesValid.map((r: any) => JSON.stringify(r)).includes(currentSearchStr)) {
        localStorage.setItem(CONDO_RECENT_SEARCHES_LABEL, JSON.stringify([currentSearchFinal, ...recentSearchesValid]));
      }

    } catch (error) {
      console.error(error);

      if (searchType === SearchTypeEnum.NewSearch) {
        dispatch(condosActions.setForceHideFilters(true));
      } else {
        dispatch(condosActions.setForceHideFilters(false));
      }

      if (!(error instanceof axios.Cancel)) {
        dispatch(condosActions.setIsFinishedWithError(true));
        dispatch(condosActions.setError(error.toString()));
        dispatch(condosActions.setLoading(false));
        dispatch(condosActions.setLoadingMore(false));
      }
    }
  };
};

export const resetCondosFull = (): AppThunk => {
  return async (dispatch) => {
    dispatch(condoFlexibleDatePickerActions.resetState());
    dispatch(condoStrictDatesActions.resetDates());
    dispatch(condosLocationPickerActions.resetLocations());
    dispatch(condoGuestsActions.resetGuest());
    dispatch(condoFiltersActions.resetFilters());    
    dispatch(condosActions.setIsSearch(false));
    dispatch(condosActions.setError(''));    
    dispatch(condosActions.setBounds(null));    
    dispatch(condosActions.setCounters(null));    
    dispatch(condosActions.setCondos(null));    
    dispatch(condosActions.setMapCondos(null));    
    dispatch(condosActions.setPrevCondos(null));    
  };
};
