import React from 'react';

export const EditSvg: React.FunctionComponent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.02638 10.7502C3.05763 10.7502 3.08888 10.7471 3.12013 10.7424L5.74825 10.2815C5.7795 10.2752 5.80919 10.2612 5.83107 10.2377L12.4545 3.61431C12.469 3.59985 12.4805 3.58268 12.4883 3.56378C12.4962 3.54488 12.5002 3.52461 12.5002 3.50415C12.5002 3.48369 12.4962 3.46342 12.4883 3.44452C12.4805 3.42562 12.469 3.40845 12.4545 3.39399L9.85763 0.795557C9.82794 0.765869 9.78888 0.750244 9.74669 0.750244C9.7045 0.750244 9.66544 0.765869 9.63576 0.795557L3.01232 7.41899C2.98888 7.44243 2.97482 7.47056 2.96857 7.50181L2.50763 10.1299C2.49243 10.2136 2.49786 10.2998 2.52345 10.3809C2.54905 10.4621 2.59403 10.5357 2.6545 10.5956C2.75763 10.6956 2.88732 10.7502 3.02638 10.7502ZM4.0795 8.02524L9.74669 2.35962L10.892 3.50493L5.22482 9.17056L3.83575 9.41587L4.0795 8.02524ZM12.7498 12.0627H1.24982C0.973254 12.0627 0.749817 12.2862 0.749817 12.5627V13.1252C0.749817 13.194 0.806067 13.2502 0.874817 13.2502H13.1248C13.1936 13.2502 13.2498 13.194 13.2498 13.1252V12.5627C13.2498 12.2862 13.0264 12.0627 12.7498 12.0627Z"
      fill="#0D99D6"
    />
  </svg>
);
