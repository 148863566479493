import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './style.scss';

interface IProps extends ButtonProps {}

export class RedButton extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <Button {...this.props} className="red-button">
        {this.props.children}
      </Button>
    );
  }
}
