import { BookingStatusEnum, HotelBookingStatusEnum } from '@common-types';

const condosHotelsStatusMap: { [key in BookingStatusEnum]: HotelBookingStatusEnum } = {
  [BookingStatusEnum.Confirmed]: HotelBookingStatusEnum.Confirmed,
  [BookingStatusEnum.Completed]: HotelBookingStatusEnum.Completed,
  [BookingStatusEnum.Cancelled]: HotelBookingStatusEnum.Cancelled,
  [BookingStatusEnum.Failed]: HotelBookingStatusEnum.Failed,
  [BookingStatusEnum.Pending]: HotelBookingStatusEnum.Pending,
  [BookingStatusEnum.Draft]: HotelBookingStatusEnum.InProgress,
  [BookingStatusEnum.CancellationInProgress]: HotelBookingStatusEnum.Cancelled,
  [BookingStatusEnum.CancellationFailed]: HotelBookingStatusEnum.Cancelled,
};

const hotelsCondosStatusMap: { [key in HotelBookingStatusEnum]: BookingStatusEnum } = {
  [HotelBookingStatusEnum.Confirmed]: BookingStatusEnum.Confirmed,
  [HotelBookingStatusEnum.Completed]: BookingStatusEnum.Completed,
  [HotelBookingStatusEnum.Cancelled]: BookingStatusEnum.Cancelled,
  [HotelBookingStatusEnum.Failed]: BookingStatusEnum.Failed,
  [HotelBookingStatusEnum.Pending]: BookingStatusEnum.Pending,
  [HotelBookingStatusEnum.InProgress]: BookingStatusEnum.Draft,
};

export const getHotelsStatusByCondos = (
  status: BookingStatusEnum | HotelBookingStatusEnum,
): HotelBookingStatusEnum => {
  return (condosHotelsStatusMap[status as BookingStatusEnum] || status) as HotelBookingStatusEnum;
};

export const getCondosStatusByHotels = (
  status: HotelBookingStatusEnum | BookingStatusEnum,
): BookingStatusEnum => {
  return (hotelsCondosStatusMap[status as HotelBookingStatusEnum] || status) as BookingStatusEnum;
};
