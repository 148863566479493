import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

const ONE_ROW = 1;
const TWO_ROW = 2;

export class UnitSkeleton extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="unit-skeleton">
        <Skeleton className="unit-skeleton__top-block" active paragraph={{ rows: TWO_ROW }} />
        <Skeleton
          className="unit-skeleton__middle-block"
          active
          title={false}
          paragraph={{ rows: TWO_ROW }}
        />
        <Skeleton
          className="unit-skeleton__bottom-block"
          active
          title={false}
          paragraph={{ rows: ONE_ROW }}
        />
      </div>
    );
  }
}
