import { CombinedState, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { DateSearchTypeEnum, ICondoGuests, ISessionKey } from '@share/common-types';
import { AppThunk, RootState, Toaster } from '@share/utils';
import { axiosInstance, getHeaders } from '@share/utils';
import { UrlUtils, getTimeout } from '@share/utils';
import { CONDO_UNITS_SEARCH_LABEL, Urls, CONDO_UNITS_SESSION_KEY_LABEL, MAX_SELECTED_MONTH_COUNT } from '@share/constants';
import { ICondoDetailsState, ICondoStrictDatesState } from '@share/store/slices';

import { getCondoUnitsRequest } from '@share/utils';
import { ICondoUnitsSearch, StayForEnum } from '@share/common-types';

export interface IUnitsSearchState {
  startDate: string;
  endDate: string;
  error: string;
  key: string;
  guests: ICondoGuests;
  isUnitsLoading: boolean;
  condoInfo: ICondoUnitsSearch;
  sessionKeyUnits?: ISessionKey;
  showUpdatedBanner: boolean;
  timer: number;
  isSearchUnits: boolean;
  searchType: DateSearchTypeEnum;
  stayFor: StayForEnum;
  selectedMonths: string[];
}


const initialState: IUnitsSearchState = {
  startDate: undefined,
  endDate: undefined,
  error: '',
  key: 'selection',
  guests: {
    adultsCount: 2,
    kidsCount: 0,
    bedroomsCount: 1,
    includeStudio: false,
  },
  isUnitsLoading: false,
  condoInfo: null,
  showUpdatedBanner: false,
  timer: null,
  isSearchUnits: false,
  searchType: DateSearchTypeEnum.Flexible,
  stayFor: StayForEnum.Week,
  selectedMonths: [],
};

const THREE_CHARACTERS = 3;

const unitsSearchSlice = createSlice({
  name: 'condo-unit-search',
  initialState,
  reducers: {
    setDates: (
      state: IUnitsSearchState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    resetDates: (state: IUnitsSearchState) => {
      state.startDate = undefined;
      state.endDate = undefined;
      state.key = 'selection';
      state.error = '';
    },
    resetFlexibleState: (state: IUnitsSearchState) => {
      state.stayFor = StayForEnum.Week;
      state.selectedMonths = [];
    },

    setStayFor: (state: IUnitsSearchState, { payload }: PayloadAction<StayForEnum>) => {
      state.stayFor = payload;
    },
    setMonths: (state: IUnitsSearchState, { payload }: PayloadAction<string[]>) => {
      state.selectedMonths = payload;
    },
    selectMonths: (state: IUnitsSearchState, { payload }: PayloadAction<string>) => {
      const payloadDateWithoutDay = payload.toString().substr(THREE_CHARACTERS);
      const datesStringWithoutDay = state.selectedMonths.map((date) =>
        date.toString().substr(THREE_CHARACTERS),
      );
      if (datesStringWithoutDay.includes(payloadDateWithoutDay)) {
        state.selectedMonths = state.selectedMonths.filter(
          (month) =>
            month.toString().substr(THREE_CHARACTERS) !==
            payload.toString().substr(THREE_CHARACTERS),
        );
      } else {
        if (state.selectedMonths.length < MAX_SELECTED_MONTH_COUNT) {
          state.selectedMonths.push(payload);
        }
      }
    },

    setStartDate: (state: IUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setEndDate: (state: IUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setError: (state: IUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setKey: (state: IUnitsSearchState, { payload }: PayloadAction<string>) => {
      state.key = payload;
    },
    updateAdultsCount: (state: IUnitsSearchState, { payload }: PayloadAction<number>) => {
      state.guests.adultsCount = payload;
    },
    updateKidsCount: (state: IUnitsSearchState, { payload }: PayloadAction<number>) => {
      state.guests.kidsCount = payload;
    },
    updateBedroomCount: (state: IUnitsSearchState, { payload }: PayloadAction<number>) => {
      state.guests.bedroomsCount = payload;
    },
    setGuestsCount: (state: IUnitsSearchState, { payload }: PayloadAction<ICondoGuests>) => {
      state.guests = payload;
    },
    setIsUnitsLoading: (state: IUnitsSearchState, { payload }: PayloadAction<boolean>) => {
      state.isUnitsLoading = payload;
    },
    setCondoInfo: (state: IUnitsSearchState, { payload }: PayloadAction<ICondoUnitsSearch>) => {
      state.condoInfo = payload;
    },
    setSessionKey: (state: IUnitsSearchState, { payload }: PayloadAction<ISessionKey>) => {
      state.sessionKeyUnits = payload;
    },
    setShowUpdatedBanner: (state: IUnitsSearchState, { payload }: PayloadAction<boolean>) => {
      state.showUpdatedBanner = payload;
    },
    setTimer: (state: IUnitsSearchState, { payload }: PayloadAction<number>) => {
      state.timer = payload;
    },
    setSearchUnits: (state: IUnitsSearchState, { payload }: PayloadAction<boolean>) => {
      state.isSearchUnits = payload;
    },
    setSearchType: (
      state: ICondoStrictDatesState, { payload }: PayloadAction<DateSearchTypeEnum>) => {
      state.searchType = payload;
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const unitsSearchActions = unitsSearchSlice.actions;

export const unitsSearchReducer = unitsSearchSlice.reducer;

let cancelRequest: Canceler;
let expirationTimeout: number;

export const updateUnits = (
  expireDate: string,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => CombinedState<{ condoDetailsStore: ICondoDetailsState }>,
): void => {
  // @ts-ignore
  expirationTimeout = setTimeout(() => {
    const { condoDetailsStore } = getState();

    dispatch(getUnitsDetails(condoDetailsStore.condo.condoDetails.id));
    dispatch(unitsSearchActions.setShowUpdatedBanner(true));
  }, getTimeout(expireDate));
  dispatch(unitsSearchActions.setTimer(expirationTimeout));
};

export const getUnitsDetails = (condoId: number): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(unitsSearchActions.setIsUnitsLoading(true));

    if (!!expirationTimeout) {
      clearTimeout(expirationTimeout);
    }

    if (cancelRequest) {
      cancelRequest();
    }

    const { unitsSearchStore } = getState();
    const { startDate, endDate, guests, searchType, stayFor, selectedMonths } = unitsSearchStore;

    try {
      UrlUtils.setUrl(CONDO_UNITS_SEARCH_LABEL, {
        startDate,
        endDate,
        guests,
        searchType,
        stayFor,
        selectedMonths,
      });

      const res = await axiosInstance.post(
        Urls.CondoUnits,
        {
          ...getCondoUnitsRequest(guests, startDate, endDate, searchType, selectedMonths),
          condoId,
        },
        {
          ...getHeaders(),
          cancelToken: new axios.CancelToken((canceler: Canceler) => {
            cancelRequest = canceler;
          }),
        },
      );

      dispatch(unitsSearchActions.setSearchUnits(true));
      dispatch(unitsSearchActions.setCondoInfo(res.data));
      dispatch(unitsSearchActions.setIsUnitsLoading(false));
      dispatch(unitsSearchActions.setSessionKey(res.data.sessionKey));

      UrlUtils.setUrl(CONDO_UNITS_SESSION_KEY_LABEL, res.data.sessionKey);
      // @ts-ignore
      //updateUnits(res.data.sessionKey?.expireDate, dispatch, getState);
      dispatch(unitsSearchActions.setError(''));
    } catch (error) {
      console.error(error);
      dispatch(unitsSearchActions.setError('No Units availables for the criteria selected. Change the criteria to continue.'));

      if (!(error instanceof axios.Cancel)) {
        dispatch(unitsSearchActions.setIsUnitsLoading(false));
      }

      Toaster.error('No Units availables for the criteria selected. Change the criteria to continue.');
    }
  };
};
