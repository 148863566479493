import React, { Component } from 'react';
// @ts-ignore
import JotformEmbed from 'react-jotform-embed';

import { IMenuState } from '@share/store/slices';
import { connect } from 'react-redux';

import { RootState } from '@share/utils';

import './style.scss';

const MLM_SUPPORT_FORM = 'https://form.jotform.com/232994817051260';
const MLM_CONCIERGE_FORM = 'https://form.jotform.com/232994740137260';
const MLM_CUSTOM_REQUEST_FORM = 'https://form.jotform.com/232994575391268';
const MLM_PHONE_CHATE_FORM = 'https://form.jotform.com/232994489229272';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
}

class ContactUsFormComponent extends Component<IProps> {
  render(): React.ReactNode {
    const { menuStore } = this.props;

    return (
      <div className="contact-us-form">
        {!menuStore?.items?.isMLM ? (
          <JotformEmbed {...{ src: 'https://form.jotform.com/220953995609167', frameBorder: '0' }} />) : (
          <>
            <div className="contact-us-form__support">
              <JotformEmbed {...{ src: `${MLM_SUPPORT_FORM}?memberid=${menuStore?.items?.rsiId}`, frameBorder: '0' }} />
            </div>
            <div className="contact-us-form__concierge">
              <JotformEmbed {...{ src: `${MLM_CONCIERGE_FORM}?memberid=${menuStore?.items?.rsiId}`, frameBorder: '0' }} />
            </div>
            <div className="contact-us-form__custom-request">
              <JotformEmbed {...{ src: `${MLM_CUSTOM_REQUEST_FORM}?memberid=${menuStore?.items?.rsiId}`, frameBorder: '0' }} />
            </div>
            <div className="contact-us-form__phone-chat">
              <JotformEmbed {...{ src: MLM_PHONE_CHATE_FORM, frameBorder: '0' }} />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore
  };
};

export const ContactUsForm = connect(mapStateToProps)(ContactUsFormComponent);
