import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WhiteButton } from '../white-button';
import { RedButton } from '../red-button';
import './style.scss';

interface IProps {
  isActiveRule: boolean;
  priseRuleId: number;
  isConfirmModal: boolean;
  handleCancelConfirm: () => void;
  onToggleActivatePriceRule: () => void;
  mask?: boolean;
  centerPosition?: boolean;
}

export class ConfirmModal extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const {
      isActiveRule,
      priseRuleId,
      isConfirmModal,
      handleCancelConfirm,
      onToggleActivatePriceRule,
      mask,
      centerPosition,
    } = this.props;

    return (
      <Modal
        title={<FormattedMessage id="rule.id" values={{ id: priseRuleId }} />}
        className={`confirm-modal ${centerPosition ? 'center' : ''}`}
        wrapClassName="confirm-modal__wrapper"
        mask={mask}
        visible={isConfirmModal}
        onCancel={handleCancelConfirm}
        footer={[
          <WhiteButton key={'cancel'} onClick={handleCancelConfirm}>
            <FormattedMessage id="cancel" />
          </WhiteButton>,
          <RedButton key={'confirm'} onClick={onToggleActivatePriceRule}>
            <FormattedMessage id="confirm" />
          </RedButton>,
        ]}
      >
        <p className="confirm-modal__text">
          <FormattedMessage id="rule.will.be" />
          <span className="confirm-modal__text-action">
            {isActiveRule ? (
              <FormattedMessage id="activate" />
            ) : (
              <FormattedMessage id="deactivate" />
            )}
            .
          </span>
        </p>
        <p className="confirm-modal__text">
          <FormattedMessage id="please.confirm" />
        </p>
      </Modal>
    );
  }
}
