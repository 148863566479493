import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { MatchedByEnum, IHotelAutocompleteResult } from '@common-types';
import {
  adminHotelBookingsActions,
  getAdminHotelBookings,
  getAdminHotelBookingsSearch,
  selectAdminHotelBookingsSearch,
  IAdminHotelBookingsState,
  selectAdminHotelBookingsAutocompleteResults,
} from '@store/slices';
import {
  AdminBookingsSearch,
  IAutocompleteGroupedItem,
  TAutocompleteGroupConfigs,
  mapAutocompleteGroups,
} from '../admin-booking-search';
import './style.scss';

const autocompleteGroupConfigs: TAutocompleteGroupConfigs[] = [
  {
    label: 'rsi.booking.order.ids',
    name: MatchedByEnum.OrderId,
  },
  {
    label: 'supplier.confirmation',
    name: MatchedByEnum.ConfirmationNumber,
  },
  {
    label: 'member.ID',
    name: MatchedByEnum.MemberId,
  },
  {
    label: 'member.name',
    name: MatchedByEnum.MemberName,
  },
  {
    label: 'guest.name',
    name: MatchedByEnum.GuestName,
  },
  {
    label: 'hotel.name',
    name: MatchedByEnum.HotelName,
  },
];

interface IMapStateToProps {
  search: IAdminHotelBookingsState['search'];
  autocompleteResults: IAdminHotelBookingsState['autocompleteResults'];
  isSearchLoading: IAdminHotelBookingsState['isSearchLoading'];
  selectedSearchItem: IAdminHotelBookingsState['selectedSearchItem'];
}

interface IMapDispatchToProps {
  setSearch: (search: string) => void;
  setAutocompleteResults: (results: IHotelAutocompleteResult[]) => void;
  setSelectedSearchItem: (result: IHotelAutocompleteResult) => void;
  getAdminHotelBookingsSearch: (search: string) => void;
  setIsSearchLoading: (loading: boolean) => void;
  getAdminHotelBookings: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class HotelBookingsSearchComponent extends React.Component<IProps> {
  getGroupedResult = (): IAutocompleteGroupedItem[] => {
    const { autocompleteResults } = this.props;

    return mapAutocompleteGroups(autocompleteResults, autocompleteGroupConfigs);
  };

  render(): React.ReactNode {
    const { search, isSearchLoading, autocompleteResults, selectedSearchItem } = this.props;

    return (
      <AdminBookingsSearch
        placeholderId="enter.supplier.confirmation"
        searchByPlaceholderId="search.by.placeholder.hotels"
        setSearch={this.props.setSearch}
        setAutocompleteResults={this.props.setAutocompleteResults}
        setSelectedSearchItem={this.props.setSelectedSearchItem}
        getBookingsSearch={this.props.getAdminHotelBookingsSearch}
        setIsSearchLoading={this.props.setIsSearchLoading}
        getAdminBookings={this.props.getAdminHotelBookings}
        autocompleteItems={autocompleteResults}
        selectedSearchItem={selectedSearchItem}
        search={search}
        isSearchLoading={isSearchLoading}
        groupedItems={this.getGroupedResult()}
      />
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  const searchState = selectAdminHotelBookingsSearch(state);
  const autocompleteResults = selectAdminHotelBookingsAutocompleteResults(state);

  return {
    autocompleteResults,
    search: searchState.search,
    isSearchLoading: searchState.isSearchLoading,
    selectedSearchItem: searchState.selectedSearchItem,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSearch: adminHotelBookingsActions.setSearch,
  setAutocompleteResults: adminHotelBookingsActions.setAutocompleteResults,
  setSelectedSearchItem: adminHotelBookingsActions.setSelectedSearchItem,
  setIsSearchLoading: adminHotelBookingsActions.setIsSearchLoading,
  getAdminHotelBookingsSearch,
  getAdminHotelBookings,
};

export const HotelBookingsSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HotelBookingsSearchComponent);
