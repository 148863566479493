import React, { useState } from 'react';
import { Carousel } from 'antd';
import img1 from '@assets/images/home/testimonial-2.jpg';
import img2 from '@assets/images/home/testimonial-1.jpg';
import Testimonial from './testimonial';

const Reviews = () => {
  const [showControls, setShowControls] = useState(true);

  return (
    <section className="pb-0 py-md-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <Carousel
              autoplay
              dots={showControls}
            >
              <div>
                <Testimonial
                  imgSrc={img1}
                  quote="A game-changer for business travel. Great savings, easy to use. Highly recommend TravBusiness!"
                />
              </div>
              <div>
                <Testimonial
                  imgSrc={img2}
                  quote="Impressive discounts, superior service. TravBusiness has been pivotal in meeting our travel budgets."
                />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Reviews };
