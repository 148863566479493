import React from 'react';
import img1 from '@assets/images/home/laptop-example.png';
import { registerData } from "../../../../_mock-api's/index";

const Register = () => {
  return (
    <section className="register-section pt-0 pt-lg-8">
      <div className="container position-relative laptop-bg">
        <div className="laptop-image position-absolute bottom-0 end-0 z-index-99 me-10 d-none d-lg-block">
          <img src={img1} className="mb-5" alt="Laptop" />
        </div>

        <div className="row">
          <div className="col-lg-7">
            <div className="register-content bg-light rounded-2 position-relative overflow-hidden p-4 p-sm-5">
              <h3 className="register-title mb-4">
                Register your free account now and start your 30-day trial
              </h3>
              <ul className="register-list list-inline position-relative mb-4">
                {registerData.map((item, index) => (
                  <li key={index} className="list-inline-item me-3">
                    <i className="bi bi-patch-check-fill text-success me-1"></i>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="register-btn">
                <a className="btn btn-primary btn-lg" href="register.php">
                  <i className="bi bi-patch-check"></i> Register for Free!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Register };
