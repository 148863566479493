import React, { useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { Spin } from 'antd';

interface LocationSelectorProps {
  onLocationSelect: (location: any) => void;
  onSearchChange: (input: string) => void;
  locationValid: boolean;
  searchTerm?: any;
  showLocationHistory: boolean;
}

const LocationSelector: React.FC<LocationSelectorProps> = ({
  onLocationSelect,
  onSearchChange,
  locationValid,
  searchTerm,
  showLocationHistory,
}) => {
  const [locations, setLocations] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');

  const fetchLocations = debounce(async (searchTerm: string) => {
    if (!searchTerm) return;
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://qa2-api.travcoding.com:4000/Locations?SearchString=${searchTerm}`,
      );
      setLocations(response.data.locations || []);
      setIsLoading(false);
      setIsDropdownOpen(true);
    } catch (error) {
      console.error('Failed to fetch locations:', error);
      setLocations([]);
    } finally {
      setIsLoading(false);
    }
  }, 900);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // Corrected method call
    if (event.target.value.length > 2) {
      fetchLocations(event.target.value);
    }
    setSelectedLocation(event.target.value);
    onSearchChange(event.target.value);
  };

  const handleFocus = () => {
     setTimeout(() => setIsDropdownOpen(true), 200);
  };

  const handleBlur = () => {
    setTimeout(() => setIsDropdownOpen(false), 200);
  };

  const handleOptionClick = (location: any) => {
    onLocationSelect(location);
    setSelectedLocation(location.name);
    setIsDropdownOpen(false);
  };


  return (
    <div
      className={`form-control-border form-control-bg-light form-fs-md d-flex ${
        locationValid ? 'form-control-bg-light' : 'form-control-bg-light-danger'
      }`}
    >
      <i className="bi bi-geo-alt fs-4 me-2 mt-3"></i>
      <div className="flex-grow-1 location-main-container">
        <label className={`${!locationValid ? 'form-label form-label-validate' : 'form-label'}`}>
          Location
        </label>
        <input
          type="text"
          className="form-guest-selector form-control"
          value={selectedLocation}
          onChange={handleSearchChange}
          placeholder="Search locations"
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {isLoading && <Spin size="small" style={{ position: 'absolute', right: 10, top: 30 }} />}
        {isDropdownOpen && locations.length > 2 && (
          <div className=" form-control-border form-control-bg-light custom-dropdown  absolute">
            {locations.map((location, index) => (
              <div
                key={index}
                className="dropdown-item p-2 cursor-pointer mt-1 dropdown-item-style"
                onClick={() => handleOptionClick(location)}
              >
                {location.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { LocationSelector };
