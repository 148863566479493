import React from 'react';

export const ListViewSvg: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 3.33301H5.00004C4.07957 3.33301 3.33337 4.0792 3.33337 4.99967V6.66634C3.33337 7.58682 4.07957 8.33301 5.00004 8.33301H15C15.9205 8.33301 16.6667 7.58682 16.6667 6.66634V4.99967C16.6667 4.0792 15.9205 3.33301 15 3.33301Z"
      fill="#0D99D6"
    />
    <path
      d="M15 11.667H5.00004C4.07957 11.667 3.33337 12.4132 3.33337 13.3337V15.0003C3.33337 15.9208 4.07957 16.667 5.00004 16.667H15C15.9205 16.667 16.6667 15.9208 16.6667 15.0003V13.3337C16.6667 12.4132 15.9205 11.667 15 11.667Z"
      fill="#0D99D6"
    />
  </svg>
);
