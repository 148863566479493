import React from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { Checkbox } from 'antd';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';

import './style.scss';

interface IOption {
  text: string | React.ReactNode;
  value: string;
}

interface IProps extends FilterDropdownProps {
  options: IOption[];
}

interface IState {
  selectedOptions: string[];
}

export class CheckboxTableFilter extends React.Component<IProps, IState> {
  state: IState = {
    selectedOptions: [],
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedOptions: (this.props.selectedKeys && this.props.selectedKeys.length)
        ? this.props.selectedKeys as string[]
        : [],
    };
  }

  onReset = (): void => {
    this.setState({ selectedOptions: [] });
    this.props.clearFilters();
  };

  onApply = (): void => {
    const { selectedOptions } = this.state;
    const { setSelectedKeys, confirm } = this.props;

    setSelectedKeys(selectedOptions);
    confirm();
  };

  toggleValue = (value: string): void => {
    const { selectedOptions } = this.state;

    if (selectedOptions.includes(value)) {
      this.setState((prevState: IState) => ({
        selectedOptions: prevState.selectedOptions.filter((item) => item !== value),
      }));
    } else {
      this.setState((prevState: IState) => ({
        selectedOptions: prevState.selectedOptions.concat(value),
      }));
    }
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!isEqual(prevProps.selectedKeys, this.props.selectedKeys)) {
      this.setState({
        selectedOptions: this.props.selectedKeys as string[],
      });
    }
  }

  render(): React.ReactNode {
    const { options } = this.props;

    return (
      <div className="checkbox-table-filter">
        <div className="checkbox-table-filter__actions">
          <div className="checkbox-table-filter__items">
            {options.map(({ text, value }, i: number) => {
              return (
                <div className="checkbox-table-filter__item" key={`${value}-${i}`}>
                  <Checkbox
                    onChange={() => this.toggleValue(value)}
                    checked={this.state.selectedOptions.includes(value)}
                  >
                    {text}
                  </Checkbox>
                </div>
              );
            })}
          </div>
          <div className="checkbox-table-filter__footer">
            <WhiteButton onClick={this.onReset}>
              <FormattedMessage id="reset" />
            </WhiteButton>
            <BlueButton onClick={this.onApply}>
              <FormattedMessage id="apply" />
            </BlueButton>
          </div>
        </div>
      </div>
    );
  }
}
