import React from "react";
import { Row, Col, Card, Skeleton } from "antd";

const DashboardHotelBookSkeleton = () => {
  return (
    <section className="py-4">
      <div className="container">
        <Card
          className="bg-light overflow-hidden px-sm-5 py-3"
          bordered={false}
        >
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={18}>
              <h1 className="m-0 h2">Review your Booking</h1>
            </Col>
            <Col xs={0} sm={6} className="skeleton-circle-avatar">
              <Skeleton.Avatar size="large" shape="circle" active />
            </Col>
          </Row>
        </Card>
      </div>
    </section>
  );
};

export default DashboardHotelBookSkeleton;
