import React, { useEffect, useRef } from "react";

const MapView = ({ center, zoom }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: center,
      zoom: zoom,
    });

    new window.google.maps.Marker({
      position: center,
      map: map,
    });
  }, [center, zoom]);

  return <div ref={mapRef} className="map-view" />;
};

export default MapView;
