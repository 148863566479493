import React from "react";
import CompanyTeamCard from "../../components/company-team/company-team-card/company-team-users";

const CompanyTeam = () => {
  return (
    <>
      <CompanyTeamCard />
    </>
  );
};

export default CompanyTeam;
