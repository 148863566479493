import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Form, Input, Modal, Select, AutoComplete } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import toNumber from 'lodash/toNumber';
import { FormInstance } from 'antd/lib/form';
import { INewPriceRule } from '@common-types';
import {
  DEFAULT_AMOUNT,
  MAX_LENGTH_DESCRIPTION,
  MarginTypeEnum,
  CURRENCY_TYPE,
  NEGATIVE,
  VALUE_ALL,
  MAX_LENGTH_SITE_ID,
  MIN_LENGTH_SITE_ID,
} from '@constants';
import {
  PERCENT_TYPE,
} from '@share/constants';

import { getSiteIds, getCurrentSuppliers } from '@utils';
import { checkInLatinLetters, receiptOfAmount } from '@share/utils';
import {
  changeSelectedRule,
  IAdminChangePriceRuleState,
  IAdminPriceRulesState,
  adminChangePriceRuleActions,
} from '@store/slices';
import { RootState } from '@share/utils';
import { RedButton, ConfirmModal } from '@components';
import { BlueButton } from '@share/components';
import './style.scss';

interface IMapStateToProps {
  adminPriceRules: IAdminPriceRulesState;
  adminPriceRule: IAdminChangePriceRuleState;
}

interface IMapDispatchToProps {
  changeSelectedRule: (data: INewPriceRule) => void;
  setChangePriceRules: (data: INewPriceRule) => void;
  setErrorText: (text: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isModalConfigPriceRule: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  product: string;
  priseRuleId: number;
  isHideActivateButton: boolean;
  isDisabled: boolean;
}

interface IState {
  siteIdValue: string;
  isSetValue: boolean;
  isConfirmModal: boolean;
}

const ZERO = 0;
const ONE = 1;

class ConfigPriceRuleComponent extends React.Component<IProps, IState> {
  state: IState = {
    siteIdValue: '',
    isSetValue: false,
    isConfirmModal: false,
  };

  formConfigRef = React.createRef<FormInstance>();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  updatePriceRule = (value: string | number | bigint | boolean, key: string) => {
    const updateRule = {
      ...this.props.adminPriceRule.changePriceRule,
      [key]: value,
    };

    this.props.setChangePriceRules(updateRule);
  };

  onChangeSupplier = (value: string): void => {
    this.updatePriceRule(value === VALUE_ALL ? '' : value, 'supplier');
  };

  onChangeSiteId = (value: string): void => {
    this.setState({ siteIdValue: value });
    this.updatePriceRule(toNumber(value) ? +value : NEGATIVE, 'siteId');
    this.formConfigRef.current.setFieldsValue({ siteId: toNumber(value) ? +value : NEGATIVE });
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.formConfigRef.current.setFieldsValue({ siteId: e.target.value });

    if (e.target.value !== VALUE_ALL && isNaN(Number(e.target.value))) {
      this.setState({ siteIdValue: '' });
    }
  };

  onChangeViewMarkup = (value: string): void => {
    this.updatePriceRule(DEFAULT_AMOUNT, 'margin');
    this.updatePriceRule(
      value === MarginTypeEnum.Percent ? PERCENT_TYPE : CURRENCY_TYPE,
      'marginType',
    );
  };

  onChangeAmount = (e: { target: HTMLInputElement }): void => {
    const { marginType } = this.props.adminPriceRule.changePriceRule;

    this.updatePriceRule(receiptOfAmount(Number(e.target.value), marginType), 'margin');
  };

  onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.updatePriceRule(e.target.value, 'name');
  };

  onCompleteChangePriceRule = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { changePriceRule, loading, error } = this.props.adminPriceRule;

    this.formConfigRef.current.setFieldsValue({ siteId: changePriceRule.siteId });
    this.props.changeSelectedRule(changePriceRule);

    if (!loading && error.length !== ZERO) {
      this.props.onCancel(e);
      this.props.setChangePriceRules(null);
      this.setState({ siteIdValue: '', isSetValue: false });
    }
  };

  onOpenConfirmModal = () => {
    this.setState({ isConfirmModal: true });
  };

  onToggleActivatePriceRule = () => {
    const { changePriceRule } = this.props.adminPriceRule;

    this.updatePriceRule(!changePriceRule.isActive, 'isActive');
    this.props.changeSelectedRule({ ...changePriceRule, isActive: !changePriceRule.isActive });
    this.setState({ isConfirmModal: false });
  };

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  afterClose = () => {
    this.setState({ isSetValue: false });
    this.props.setErrorText('');
  };

  handleCancelConfirm = () => {
    this.setState({ isConfirmModal: false });
  };

  componentDidUpdate() {
    const { isSetValue } = this.state;
    const { isModalConfigPriceRule, adminPriceRule } = this.props;
    const { changePriceRule } = adminPriceRule;

    if (changePriceRule && !isSetValue && isModalConfigPriceRule && this.formConfigRef.current) {
      this.formConfigRef.current.setFieldsValue({ siteId: changePriceRule.siteId });
      this.setState({
        siteIdValue:
          changePriceRule?.siteId !== NEGATIVE
            ? changePriceRule?.siteId === ZERO
              ? ''
              : String(changePriceRule?.siteId)
            : VALUE_ALL,
        isSetValue: true,
      });
    }
  }

  render(): React.ReactNode {
    const { siteIdValue, isConfirmModal } = this.state;
    const {
      intl,
      adminPriceRules,
      adminPriceRule,
      isModalConfigPriceRule,
      onCancel,
      priseRuleId,
      isHideActivateButton,
      isDisabled,
    } = this.props;
    const { changePriceRule, errorText, error } = adminPriceRule;
    const viewMarginType =
      changePriceRule?.marginType === PERCENT_TYPE
        ? MarginTypeEnum.Percent
        : MarginTypeEnum.Currency;

    return (
      changePriceRule && (
        <Modal
          className={`config-price-rule ${isConfirmModal ? 'transform-modal' : ''}`}
          wrapClassName="config-price-rule__wrapper"
          title={<FormattedMessage id="rule.id" values={{ id: priseRuleId }} />}
          visible={isModalConfigPriceRule}
          onCancel={onCancel}
          afterClose={this.afterClose}
          footer={false}
        >
          <Form
            className="config-price-rule_form"
            name="config-price-rule"
            ref={this.formConfigRef}
            initialValues={{
              product: changePriceRule?.productType,
              marginType: viewMarginType,
              margin: changePriceRule?.margin,
              name: changePriceRule?.name,
            }}
          >
            <div className="config-price-rule__block-info" ref={this.wrapperRef}>
              <Form.Item
                name="product"
                label={intl.formatMessage({ id: 'product' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.message.product' }),
                    pattern: checkInLatinLetters,
                  },
                ]}
              >
                <div>
                  <Select
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'product' })}
                    value={changePriceRule?.productType}
                    getPopupContainer={this.getPopupContainer}
                    disabled
                  />
                </div>
              </Form.Item>
              <Form.Item name="supplier" label={intl.formatMessage({ id: 'supplier' })}>
                <div>
                  <Select
                    disabled={isDisabled}
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'supplier' })}
                    value={changePriceRule?.supplier === '' ? VALUE_ALL : changePriceRule?.supplier}
                    onChange={this.onChangeSupplier}
                    getPopupContainer={this.getPopupContainer}
                  >
                    <Select.Option key={ZERO} value="All">
                      All
                    </Select.Option>
                    {changePriceRule &&
                      getCurrentSuppliers(
                        changePriceRule.productType,
                        adminPriceRules.adminSuppliers,
                      ).map((item, index) => {
                        return (
                          <Select.Option key={index + ONE} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item
                name="siteId"
                label={intl.formatMessage({ id: 'site.id' })}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.message.site.id' }),
                  },
                  () => ({
                    validator(_, value) {
                      if (value !== VALUE_ALL && isNaN(Number(value))) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'error.message.correct.site.id',
                            }),
                          ),
                        );
                      }
                      if (
                        (value.length < MIN_LENGTH_SITE_ID || value.length > MAX_LENGTH_SITE_ID) &&
                        value.length !== ZERO
                      ) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'error.message.min.max.site.id',
                            }),
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <div>
                  <AutoComplete
                    disabled={isDisabled}
                    // maxLength={MAX_LENGTH_SITE_ID}
                    placeholder={intl.formatMessage({ id: 'site.id' })}
                    options={getSiteIds(adminPriceRules.priceRules, changePriceRule)}
                    value={siteIdValue}
                    onChange={this.onChangeSiteId}
                    onSelect={this.onChangeSiteId}
                    onBlur={this.onBlur}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== NEGATIVE
                    }
                    getPopupContainer={this.getPopupContainer}
                  />
                </div>
              </Form.Item>
              <Form.Item name="markup" label={intl.formatMessage({ id: 'markup' })}>
                <Input.Group compact>
                  <Form.Item name="marginType" noStyle>
                    <Select
                      className="select-view-markup"
                      value={viewMarginType}
                      onChange={this.onChangeViewMarkup}
                      getPopupContainer={this.getPopupContainer}
                    >
                      {Object.values(MarginTypeEnum).map((value: string) => {
                        return (
                          <Select.Option key={value} value={value}>
                            {value}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="margin"
                    noStyle
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'error.message.site.markup' }),
                      },
                    ]}
                  >
                    <div className="config-price-rule__input-amount">
                      <Input
                        type="number"
                        className="input-amount"
                        placeholder={intl.formatMessage({ id: 'amount' })}
                        onChange={this.onChangeAmount}
                        defaultValue={34}
                        value={changePriceRule?.margin}
                      />
                    </div>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                name="name"
                label={intl.formatMessage({ id: 'description' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'error.message.site.description' }),
                  },
                  {
                    max: MAX_LENGTH_DESCRIPTION,
                    message: intl.formatMessage({ id: 'error.message.alphanumeric.chars' }),
                  },
                ]}
                className="config-price-rule__description"
              >
                <Input.TextArea value={changePriceRule?.name} onChange={this.onChangeDescription} />
                <p className="config-price-rule__description-length">
                  {changePriceRule?.name ? changePriceRule?.name.length : ZERO}/
                  {MAX_LENGTH_DESCRIPTION}
                </p>
              </Form.Item>
            </div>
            {error.length > ZERO && <p className="config-price-rule__error">{errorText}</p>}
            <div className="config-price-rule__btn-wrapper">
              {!isHideActivateButton ? (
                <div
                  onClick={this.onOpenConfirmModal}
                  className={changePriceRule?.isActive ? 'btn-deactivate' : 'btn-activate'}
                >
                  <RedButton>
                    <FormattedMessage id={changePriceRule?.isActive ? 'deactivate' : 'activate'} />
                  </RedButton>
                </div>
              ) : null}
              <BlueButton
                htmlType="submit"
                disabled={
                  !!this.formConfigRef.current
                    ?.getFieldsError()
                    .filter((item) => item.errors.length).length
                }
                onClick={this.onCompleteChangePriceRule}
              >
                <FormattedMessage id="save.changes" />
              </BlueButton>
            </div>
          </Form>
          <ConfirmModal
            isActiveRule={!changePriceRule?.isActive}
            priseRuleId={priseRuleId}
            isConfirmModal={isConfirmModal}
            handleCancelConfirm={this.handleCancelConfirm}
            onToggleActivatePriceRule={this.onToggleActivatePriceRule}
            mask={false}
          />
        </Modal>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPriceRules: state.adminPriceRulesStore,
    adminPriceRule: state.adminChangePriceRuleStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setChangePriceRules: (data: INewPriceRule) => {
    dispatch(adminChangePriceRuleActions.setChangePriceRules(data));
  },
  changeSelectedRule: (data: INewPriceRule) => dispatch(changeSelectedRule(data)),
  setErrorText: (test: string) => dispatch(adminChangePriceRuleActions.setErrorText(test)),
});

export const ConfigPriceRule = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ConfigPriceRuleComponent));
