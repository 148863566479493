import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Menu } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import { WhiteButton } from '@components';
import { AdminNavKeysEnum, MenuItemsEnum } from '@constants';
import { Routes } from '@share/constants';
import { BookingStatusEnum } from '@common-types';
import { ForkSvg, MenuUnfoldOutlinedSvg, CartSvg } from '@assets';
import './style.scss';

interface IProps extends RouteComponentProps, WrappedComponentProps {
  onBookingStatusSelected: (status: BookingStatusEnum) => void;
  onActiveMenuItem: (item: MenuItemsEnum) => void;
  activeItem: BookingStatusEnum | MenuItemsEnum;
}

interface IState {
  collapsed: boolean;
  selectedMenu: string;
}

const TWO = 2;

class NavigationMenuComponent extends React.Component<IProps, IState> {
  state: IState = {
    collapsed: false,
    selectedMenu: '1'
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onBookingStatusSelected = (status: BookingStatusEnum): void => {
    this.props.onBookingStatusSelected(status);
    this.setState({
      selectedMenu: status
    });
  };

  onAccountsSelected = (): void => {
    this.props.onActiveMenuItem(MenuItemsEnum.Accounts);
    this.setState({
      selectedMenu: 'AccountSearch'
    });
  };

  render(): React.ReactNode {
    const { SubMenu } = Menu;
    const { intl, activeItem } = this.props;
    const { collapsed } = this.state;

    return (
      <div className={`navigation-menu ${collapsed ? 'toggle-menu' : ''}`}>
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={
            location.pathname.split('/')[TWO] !== AdminNavKeysEnum.BusinessRules
              ? ['sub1']
              : ['sub2']
          }
          selectedKeys={[activeItem ? activeItem : '1']}
          mode="inline"
          inlineCollapsed={collapsed}
        >
          <SubMenu
            key="sub1"
            icon={
              <span role="img" aria-label="mail" className="anticon anticon-mail">
                <CartSvg />
              </span>
            }
            title={intl.formatMessage({ id: 'bookings' })}
          >
            <Menu.Item key="1">
              <Link to={`${Routes.AllBookings}`} onClick={() => this.onBookingStatusSelected(null)}>
                <FormattedMessage id="all.bookings" />
              </Link>
            </Menu.Item>
            <Menu.Item key={BookingStatusEnum.Failed}>
              <Link
                to={`${Routes.AllBookings}`}
                onClick={() => this.onBookingStatusSelected(BookingStatusEnum.Failed)}
              >
                <FormattedMessage id="failed" />
              </Link>
            </Menu.Item>
            <Menu.Item key={BookingStatusEnum.Draft}>
              <Link
                to={`${Routes.AllBookings}`}
                onClick={() => this.onBookingStatusSelected(BookingStatusEnum.Draft)}
              >
                <FormattedMessage id="draftInProgress" />
              </Link>
            </Menu.Item>
            <Menu.Item key={BookingStatusEnum.Confirmed}>
              <Link
                to={`${Routes.AllBookings}`}
                onClick={() => this.onBookingStatusSelected(BookingStatusEnum.Confirmed)}
              >
                <FormattedMessage id="confirmed" />
              </Link>
            </Menu.Item>
            <Menu.Item key={BookingStatusEnum.Completed}>
              <Link
                to={`${Routes.AllBookings}`}
                onClick={() => this.onBookingStatusSelected(BookingStatusEnum.Completed)}
              >
                <FormattedMessage id="completed" />
              </Link>
            </Menu.Item>
            <Menu.Item key={BookingStatusEnum.Cancelled}>
              <Link
                to={`${Routes.AllBookings}`}
                onClick={() => this.onBookingStatusSelected(BookingStatusEnum.Cancelled)}
              >
                <FormattedMessage id="cancelled" />
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub2"
            icon={
              <span role="img" aria-label="mail" className="anticon anticon-mail">
                <ForkSvg />
              </span>
            }
            title={intl.formatMessage({ id: 'business.rules' })}
          >
            <Menu.Item key={MenuItemsEnum.PriceRules}>
              <Link
                to={`${Routes.PriceRules}`}
                onClick={() => this.props.onActiveMenuItem(MenuItemsEnum.PriceRules)}
              >
                <FormattedMessage id="price.rules" />
              </Link>
            </Menu.Item>
            <Menu.Item key={MenuItemsEnum.PresentationRules}>
              <Link
                to={`${Routes.PresentationRules}`}
                onClick={() => this.props.onActiveMenuItem(MenuItemsEnum.PresentationRules)}
              >
                <FormattedMessage id="presentation.rules" />
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub3"
            icon={
              <span role="img" aria-label="mail" className="anticon anticon-mail">
                <ForkSvg />
              </span>
            }
            title={intl.formatMessage({ id: 'while.labels.accounts' })}
          >
            <Menu.Item key={MenuItemsEnum.Accounts}>
              <Link 
                to={`${Routes.Accounts}`}
                onClick={() => this.onAccountsSelected()}
              >
                <FormattedMessage id="accounts" />
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
        <div className={`navigation-menu__button ${collapsed ? 'toggle-menu' : ''}`}>
          <WhiteButton onClick={this.toggleCollapsed}>
            {collapsed ? <MenuUnfoldOutlinedSvg /> : <MenuUnfoldOutlinedSvg />}
          </WhiteButton>
        </div>
      </div>
    );
  }
}

export const NavigationMenu = withRouter(injectIntl(NavigationMenuComponent));
