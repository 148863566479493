import React from "react";
import { Skeleton } from "antd";

const HotelCardSkeleton = () => {
  return (
    <div>
      <div className="card shadow p-2 hotel-card-skeleton">
        <div className="row g-0">
          <div className="col-md-5 col-12 mt-2">
            <Skeleton.Image className="image-skeleton" />
          </div>

          <div className="col-md-7 col-12">
            <div className="card-body py-md-2 d-flex flex-column h-100 position-relative">
              <div className="d-flex justify-content-between align-items-center">
                <Skeleton
                  active
                  title={false}
                  paragraph={{ rows: 1, className: "paragraph-skeleton-small" }}
                />
                {/* <Skeleton.Button shape="circle" className="button-skeleton" active /> */}
              </div>

              <Skeleton.Input className="input-skeleton" active size="small" />
              <Skeleton.Input
                className="input-skeleton-half"
                active
                size="small"
              />

              <div className="mt-3 mb-2">
                <Skeleton
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    className: "paragraph-skeleton-medium",
                  }}
                />
              </div>

              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, className: "paragraph-skeleton-small" }}
              />

              <div className="d-sm-flex justify-content-sm-between align-items-center mt-3 mt-md-auto">
                <Skeleton
                  active
                  title={false}
                  paragraph={{ rows: 1, className: "paragraph-skeleton-large" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCardSkeleton;
