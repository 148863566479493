import React from 'react';

import { InputFilterType, NO_AVAILABLE_VALUE, ReservationStatusEnum } from '@share/common-types';
import { ReservationStatus, ReservationInformation, CategoryEnum, ReservationType, ReservationPrice } from '@components';
import { ComponentFunction, orderSort, IColumnMenu } from '@constants';
import { get } from 'lodash';

const getReservationStatus: ComponentFunction = (text: string) => <ReservationStatus status={text} />;

const getReservationPrice: ComponentFunction = (text: any, record: any) => <ReservationPrice record={record} />;

const getReservationMobileData: ComponentFunction = (text: string, record: any) => <ReservationInformation reservation={record} />;

const getReservationMobileDataAll: ComponentFunction = (text: string, record: any) => <ReservationInformation reservation={record} displayStatus />;

const getReservationType: ComponentFunction = (text: string) => <ReservationType type={text} />;

const STATUS = 'bookingStatusEnum';
const CATEGORY = 'bookingType';
const PROPERTY_NAME = 'propertyName';
const PROPERTY_CITY = 'propertyCity';
const CHECK_IN = 'checkIn';
const CHECK_OUT = 'checkOut';
const LEAD_NAME = 'leadName';

const COLUMNS_WIDTH = {
  [PROPERTY_NAME]: { normal: 265, all: 255 },
  [LEAD_NAME]: { normal: 175, all: 165 },
  [PROPERTY_CITY]: { normal: 155, all: 145 },
  [CHECK_IN]: { normal: 135, all: 127 },
  [CHECK_OUT]: { normal: 135, all: 127 },
  [CATEGORY]: { normal: 130, all: 120 },
  [STATUS]: { normal: 150, all: 120 },
}

export const BookingColumnMenu: IColumnMenu[] = [

  { description: 'Property Name', key: PROPERTY_NAME, width: 265, display: true, order: 2, type: InputFilterType.Text, displayConditions: false },
  { description: 'Property City', key: PROPERTY_CITY, width: 155, display: true, order: 3, type: InputFilterType.Text, displayConditions: false },

  { description: 'CheckIn', key: CHECK_IN, width: 135, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 4, type: InputFilterType.Date, displayConditions: true },
  { description: 'CheckOut', key: CHECK_OUT, width: 135, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 5, type: InputFilterType.Date, displayConditions: true },

  { description: 'Lead Name', key: LEAD_NAME, width: 175, display: true, order: 6, type: InputFilterType.Text, displayConditions: false },

  {
    description: 'Category',
    key: CATEGORY,
    width: 130,
    type: InputFilterType.Dropdown,
    filterFieldName: 'bookingType',
    dropdowmOptions: Object.values(CategoryEnum).map(v => get(v, '[0]', '').toUpperCase() + v?.slice(1)),
    display: true,
    order: 8,
    displayConditions: false,
    component: getReservationType
  },

  { 
    description: 'Status',
    key: STATUS, 
    width: 150, 
    type: InputFilterType.Dropdown,
    dropdowmOptions: Object.values(ReservationStatusEnum).filter(status => status !== NO_AVAILABLE_VALUE),
    display: false,
    order: 9,
    displayConditions: false,
    component: getReservationStatus
  },

  {
    description: 'Price',
    key: 'totalAmount',
    width: 150,
    type: InputFilterType.Custom,
    display: true,
    order: 10,
    component: getReservationPrice
  },
];

export const BookingMobileColumnMenu: IColumnMenu[] = [
  { 
    description: 'Information',
    key: 'information',
    width: 150,
    display: true,
    order: 1,
    type: InputFilterType.Custom,
    component: getReservationMobileData
  },
];


export const GetAllReservationsColumns = (searchType: string): IColumnMenu[] => {
  return BookingColumnMenu.map(c => {
    const column = { ...c };

    const columnData = get(COLUMNS_WIDTH, c.key);
    const columnWidth = ['past', 'all'].includes(searchType) ? columnData?.all : columnData?.normal;

    if (['past', 'all'].includes(searchType)) {
      if(c.description === 'Status') {
        column.display = true;
      }
    } else {
      if(c.description === 'Status') {
        column.display = false;
      }
    }

    if (columnData) {
      column.width = columnWidth;
    }

    return column;
  }).sort(orderSort);
};

export const GetAllReservationsMobileColumns = (searchType: string): IColumnMenu[] => {
  return BookingMobileColumnMenu.map(c => {
    const column = { ...c };

    if (['past', 'all'].includes(searchType)) {
      column.component = getReservationMobileDataAll;
    } else {
      column.component = getReservationMobileData;
    }

    return column;
  }).sort(orderSort);
};
