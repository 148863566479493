
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes } from '@share/constants';
import { RootState } from '@share/utils';
import { connect } from 'react-redux';

import { ILoginState } from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  children: any;
  path: string;
}

class PrivateAdminRouteComponent extends React.Component<IProps, null> {
  
  isAuthenticated = () => {
    return !!this.props.loginStore.user;
  }

  render(): React.ReactNode {
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={
          ({ location }) => (
            this.isAuthenticated() ?
              (children) :
              (<Redirect
                  to={{
                    pathname: `${Routes.AdminPage}/signin`,
                    state: { from: location }
                  }}
                />
              ))
        }
      />
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const PrivateAdminRoute = connect(mapStateToProps)(withRouter(PrivateAdminRouteComponent));
