import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Base64 } from "js-base64";
import useApi from "../../../api-hook/component";
import {
  validateAndSubmit,
  handleInputChange,
  handleBlur,
  submitInformation,
} from "../../../utils/checkout";

import DashboardHotelBookHero from "../../components/dashboard-hotel-book/hero/component";
import InformationCard from "../../components/dashboard-hotel-book/information-card/component";
import GuestCard from "../../components/dashboard-hotel-book/guest-card/component";
import PaymentSummaryCard from "../../components/dashboard-hotel-book/payment-summary/component";
import BookingDiscount from "../../components/dashboard-hotel-book/booking-discount-aside/component";
import DashboardHotelBookSkeleton from "../../components/dashboard-hotel-book/dashboard-hotel-book-skeleton/component";
import InformationCardSkeleton from "../../components/dashboard-hotel-book/information-card-skeleton/component";
import BookingSummarySkeleton from "../../components/dashboard-hotel-book/booking-discount-aside-skeleton/component";
import DashboardHotelConfirm from "../dashboard-hotel-confirm/component";
import { QUERY_PARAMS } from "../../../constants/index";
import { formatMoneyCurrency } from "../../../utils/currency";

interface RouteParams {
  id: string;
  packageId: string;
}

const DashboardHotelBook = () => {
  const location = useLocation();
  const { fetchData } = useApi();
  const { id, packageId } = useParams<RouteParams>();

  const queryParams = new URLSearchParams(location.search);
  const parsedHotelId = id ? JSON.parse(id) : null;
  const sessionKey = JSON.parse(
    Base64.decode(queryParams.get(QUERY_PARAMS.SESSION_KEY) ?? "null")
  );

  const [searchHotels, setSearchHotels] = useState<any>();
  const [countries, setCountries] = useState<any>();
  const [states, setStates] = useState();
  const [loading, setLoading] = useState(true);
  const [postOrderLoading, setPostOrderLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainGuest, setMainGuest] = useState({ roomIndex: 0 });
  const [orderResponse, setOrderResponse] = useState({});
  const dynamicRefs = useRef({});

  const inputRefs = {
    nameOnCard: useRef(null),
    cardNumber: useRef(null),
    cardExpirationMonth: useRef(null),
    cardExpirationYear: useRef(null),
    cardSecurityCode: useRef(null),
    billingAddress: useRef(null),
    billingCity: useRef(null),
    billingCountry: useRef(null),
    billingState: useRef(null),
    postalCode: useRef(null),
    dynamic: dynamicRefs.current,
  };

  const initialBookingInfo = {
    guests: [],
    hotelId: 362318,
    nameOnCard: "",
    cardNumber: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    cardSecurityCode: "",
    billingAddress: "",
    billingCity: "",
    billingCountry: "",
    billingState: "",
    postalCode: "",
    mobileNumber: "",
  };

  const [bookingInfo, setBookingInfo] = useState<any>(initialBookingInfo);
  const [showBookCard, setShowBookCard] = useState(false);
  const [cardType, setCardType] = useState("");

  const fetchHotels = async (payload) => {
    try {
      const result = await fetchData(
        "/hotels/hotel/booking-summary",
        payload,
        "post"
      );
      setSearchHotels(result || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const result = await fetchData("/condos/countries", {}, "post");
      setCountries(result || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    try {
      const result = await fetchData(
        "/condos/locations/alldestinations",
        {},
        "get"
      );
      setStates(result || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchPackageWithCard = async (payload) => {
    try {
      setPostOrderLoading(true);
      const response = await fetchData(
        "/hotels/book-package-with-card",
        payload,
        "post"
      );
      if (response.ok) {
        const data = await response.json();
        setOrderResponse(data);
        setShowBookCard(true);
      }
      setPostOrderLoading(false);
    } catch (error) {
      setPostOrderLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchStates();
  }, []);

  useEffect(() => {
    const payload = {
      hotelId: parsedHotelId,
      packageId,
      sessionKey,
      marginator: {
        percentage: 0,
        commission: 0,
      },
      currency: "USD",
      lifeType: "none",
    };
    fetchHotels(payload);
  }, [location.search]);

  useEffect(() => {
    if (searchHotels?.bookingCard?.package?.rooms) {
      const initialGuests = searchHotels?.bookingCard?.package?.rooms?.flatMap(
        (room) =>
          Array.from(
            { length: room.adultsCount + room.kidsAges.length },
            (_, index) => ({
              givenName: "",
              namePrefix: "Mr",
              surname: "",
              phone: "",
              email: "",
              ...(index >= room.adultsCount && {
                age: room.kidsAges[index - room.adultsCount],
              }),
            })
          )
      );
      setBookingInfo((prev) => ({
        ...prev,
        guests: initialGuests,
      }));
    }
  }, [searchHotels]);

  const handleMainGuestChange = (roomIndex) => {
    setBookingInfo((prev) => {
      const newGuests = [...prev.guests];
      const mainGuestIndex = searchHotels?.bookingCard?.package?.rooms
        .slice(0, roomIndex)
        .reduce(
          (acc, curr) => acc + curr.adultsCount + curr.kidsAges.length,
          0
        );
      newGuests[mainGuestIndex] = {
        ...newGuests[mainGuestIndex],
        email: "",
        phone: "",
      };
      return {
        ...prev,
        guests: newGuests,
      };
    });
    setMainGuest({ roomIndex });
  };

  // In your validate and submit form call
  const validateAndSubmitForm = () => {
    validateAndSubmit(
      bookingInfo,
      setErrors,
      searchHotels,
      mainGuest,
      inputRefs,
      () =>
        submitInformation(
          bookingInfo,
          sessionKey,
          parsedHotelId,
          packageId,
          searchHotels,
          fetchPackageWithCard,
          mainGuest,
          cardType
        )
    );
  };

  const formatter = formatMoneyCurrency(
    searchHotels?.bookingCard?.package?.currency
  );

  useEffect(() => {
    const selectedCountry = countries?.rciCountries?.find(
      (country) => country.isoCode === bookingInfo?.billingCountry
    );
    setStates(selectedCountry?.states || []);
  }, [bookingInfo?.billingCountry, countries]);

  return (
    <div>
      {loading ? (
        <>
          <DashboardHotelBookSkeleton />
          <section>
            <div className="container">
              <div className="row g-4 g-lg-5 checkout-page-row">
                <div className="col-xl-8">
                  <div className="vstack gap-5">
                    <InformationCardSkeleton />
                  </div>
                </div>
                <aside className="col-xl-4">
                  <BookingSummarySkeleton />
                </aside>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <DashboardHotelBookHero />
          <section>
            <div className="container">
              <div className="row g-4 g-lg-5 checkout-page-row">
                <div className="col-xl-8">
                  <div className="vstack gap-5">
                    {showBookCard ? (
                      <DashboardHotelConfirm
                        searchHotels={searchHotels}
                        orderResponse={orderResponse}
                      />
                    ) : (
                      <>
                        <InformationCard searchHotels={searchHotels} />
                        <GuestCard
                          searchHotels={searchHotels}
                          bookingInfo={bookingInfo}
                          handleInputChange={(
                            event,
                            overallGuestIndex,
                            roomIndex
                          ) =>
                            handleInputChange(
                              event,
                              overallGuestIndex,
                              roomIndex,
                              setBookingInfo,
                              setErrors,
                              searchHotels
                            )
                          }
                          handleBlur={(event, overallGuestIndex, roomIndex) =>
                            handleBlur(
                              event,
                              overallGuestIndex,
                              roomIndex,
                              setErrors,
                              searchHotels
                            )
                          }
                          inputRefs={dynamicRefs}
                          errors={errors}
                          hotels={searchHotels}
                          mainGuest={mainGuest}
                          updateMainGuest={handleMainGuestChange}
                        />
                        <PaymentSummaryCard
                          cardType={cardType}
                          balance={searchHotels?.bookingCard?.balance}
                          bookingInfo={bookingInfo}
                          handleInputChange={(event) =>
                            handleInputChange(
                              event,
                              undefined,
                              undefined,
                              setBookingInfo,
                              setErrors,
                              searchHotels,
                              setCardType
                            )
                          }
                          validateAndSubmit={validateAndSubmitForm}
                          countries={countries}
                          states={states}
                          handleBlur={(event) =>
                            handleBlur(
                              event,
                              undefined,
                              undefined,
                              setErrors,
                              searchHotels
                            )
                          }
                          inputRefs={inputRefs}
                          errors={errors}
                          loading={postOrderLoading}
                          disabled={
                            bookingInfo.noModificationsAllowed &&
                            bookingInfo.policyAccepted &&
                            bookingInfo.termsAccepted &&
                            bookingInfo.chargeAcknowledged
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
                <aside className="col-xl-4">
                  <BookingDiscount
                    formatter={formatter}
                    balance={searchHotels?.bookingCard?.balance}
                    totalFarePrice={
                      searchHotels?.bookingCard?.bookingPrice?.totalFarePrice
                    }
                    nightsCount={searchHotels?.bookingCard?.nightsCount}
                    roomsCount={searchHotels?.bookingCard?.roomsCount}
                    savings={searchHotels?.bookingCard?.bookingPrice?.savings}
                    taxes={searchHotels?.bookingCard?.bookingPrice?.totalTaxes}
                  />
                </aside>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default DashboardHotelBook;
