import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes } from '@share/constants';
import { ErrorAlertSvg } from '@assets';
import { BlueButton } from '@share/components';
import './style.scss';
import { connect } from 'react-redux';
import { VACATIONS_PAGE, getAccountUsernameFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {
  title: string;
  description: string;
  buttonTitle: string;
  onClick: () => void;
  isPriceChangedError?: boolean;
  isVacationRentals?: boolean;
}

class BookingFailedComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { title, description, buttonTitle, onClick, isPriceChangedError, isVacationRentals } = this.props;
    const accountName = getAccountUsernameFromPath(this.props.history);

    return (
      <div className="booking-failed">
        <ErrorAlertSvg />
        <p className="booking-failed__title">{title}</p>
        <p className="booking-failed__description">{description}</p>
        <div className="booking-failed__btn-wrapper">
          {isPriceChangedError && <BlueButton onClick={onClick}>{buttonTitle}</BlueButton>}
          <Link to={isVacationRentals ? `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}` : `/${accountName}${Routes.Search}${location.search}`}>
            <BlueButton>
              <FormattedMessage id="start.searching" />
            </BlueButton>
          </Link>
        </div>
      </div>
    );
  }
}

export const BookingFailed = connect()(withRouter(BookingFailedComponent));
