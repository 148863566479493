import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPromotionDetails, ITravelLeader } from '@common-types';
import { Urls } from '@share/constants';
import { axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';

export interface ITravelLeaderState {
  travelLeader: ITravelLeader[];
  loading: boolean;
  error: string;
  promotionDetails: IPromotionDetails;
  loadingDetails: boolean;
  errorDetails: string;
}

const initialState: ITravelLeaderState = {
  travelLeader: null,
  loading: false,
  error: '',
  promotionDetails: null,
  loadingDetails: false,
  errorDetails: '',
};

const travelLeaderSlice = createSlice({
  name: 'travelLeader',
  initialState,
  reducers: {
    setLoading: (state: ITravelLeaderState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ITravelLeaderState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setTravelLeader: (state: ITravelLeaderState, { payload }: PayloadAction<ITravelLeader[]>) => {
      state.travelLeader = payload;
    },
    setLoadingDetails: (state: ITravelLeaderState, { payload }: PayloadAction<boolean>) => {
      state.loadingDetails = payload;
    },
    setErrorDetails: (state: ITravelLeaderState, { payload }: PayloadAction<string>) => {
      state.errorDetails = payload;
    },
    setPromotionDetails: (
      state: ITravelLeaderState,
      { payload }: PayloadAction<IPromotionDetails>,
    ) => {
      state.promotionDetails = payload;
    },
  },
});

export const travelLeaderActions = travelLeaderSlice.actions;

export const travelLeaderReducer = travelLeaderSlice.reducer;

export const getTravelLeader = (): AppThunk => {
  return async (dispatch) => {
    dispatch(travelLeaderActions.setLoading(true));

    try {
      const { data } = await axiosInstance.get(Urls.Promotions);

      dispatch(travelLeaderActions.setLoading(false));
      dispatch(travelLeaderActions.setTravelLeader(data));
    } catch (error) {
      console.error(error);
      dispatch(travelLeaderActions.setError(error.toString()));
      dispatch(travelLeaderActions.setLoading(false));
    }
  };
};

export const getPromotionDetails = (promotionId: number): AppThunk => {
  return async (dispatch) => {
    dispatch(travelLeaderActions.setLoadingDetails(true));

    try {
      const { data } = await axiosInstance.get(`${Urls.Promotions}/${promotionId}`);

      dispatch(travelLeaderActions.setLoadingDetails(false));
      dispatch(travelLeaderActions.setPromotionDetails(data));
    } catch (error) {
      console.error(error);
      dispatch(travelLeaderActions.setErrorDetails(error.toString()));
      dispatch(travelLeaderActions.setLoadingDetails(false));
    }
  };
};
