import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Form, Input, notification, Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FormInstance } from 'antd/lib/form';
import {
  countriesCodeWithStates,
  SECOND_CHARACTER_POSITION,
  TENTH_CHARACTER_POSITION,
} from '@constants';
import {
  getNavigationMenu,
} from '@share/store/slices';

import {
  getPersonalDetails,
  IPersonalDetailsState,
  personalDetailsActions,
  updatePersonalDetails,
} from '@store/slices';
import {
  checkSpecialCharacters,
  mailFormat,
  scrollTop
} from '@share/utils';
import {
  setNumberPhone,
  updatePersonalInfo,
} from '@utils';
import {
  hideBrowserAutoFill
} from '@utils';
import { IPersonalDetails } from '@common-types';
import { ErrorSmallSvg, SuccessSmallSvg } from '@assets';
import { SkeletonPersonalInfo } from '../skeleton-personal-info';
import './style.scss';
import { AppThunk, RootState } from '@share/utils';
import { BlueButton } from '@share/components';

interface IMapStateToProps {
  personalDetailsStore: IPersonalDetailsState;
}

interface IMapDispatchToProps {
  getPersonalDetails: () => void;
  setPersonalDetails: (data: IPersonalDetails) => void;
  updatePersonalDetails: (data: IPersonalDetails) => void;
  getNavigationMenu: () => AppThunk;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps { }

const ZERO = 0;
const MAX_LENGTH_NAME = 100;
const MAX_LENGTH_LAST_NAME = 255;
const MAX_LENGTH_NUMBER = 50;
const MAX_LENGTH_EMAIL = 50;

class PersonalDetailsComponent extends React.Component<IProps, null> {
  formRef = React.createRef<FormInstance>();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  onChangeFirstName = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { firstName: e.target.value }) as IPersonalDetails,
    );
  };

  onChangeLastName = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { lastName: e.target.value }) as IPersonalDetails,
    );
  };

  validateEmail1 = (e: { target: HTMLInputElement }) => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { email1: e.target.value }) as IPersonalDetails,
    );
  };

  validateEmail2 = (e: { target: HTMLInputElement }) => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { email2: e.target.value }) as IPersonalDetails,
    );
  };

  onFocus1 = (e: { target: HTMLInputElement }) => {
    this.formRef.current.setFieldsValue({ email1: e.target.value });
  };

  onFocus2 = (e: { target: HTMLInputElement }) => {
    this.formRef.current.setFieldsValue({ email2: e.target.value });
  };

  onChangePhone = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, {
        phone1: setNumberPhone(e.target.value),
      }) as IPersonalDetails,
    );
  };

  onChangePhone2 = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, {
        phone2: setNumberPhone(e.target.value),
      }) as IPersonalDetails,
    );
  };

  onChangePhone3 = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, {
        phone3: setNumberPhone(e.target.value),
      }) as IPersonalDetails,
    );
  };

  onChangeAddressLine1 = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { address1: e.target.value }) as IPersonalDetails,
    );
  };

  onChangeAddressLine2 = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { address2: e.target.value }) as IPersonalDetails,
    );
  };

  onChangePrimaryCity = (e: { target: HTMLInputElement }): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { city: e.target.value }) as IPersonalDetails,
    );
  };

  onChangePrimaryCountry = (value: string): void => {
    const { personalDetails } = this.props.personalDetailsStore;

    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, {
        country: value,
        state: '',
      }) as IPersonalDetails,
    );
    this.formRef.current.setFieldsValue({ country: value });
  };

  onChangePrimaryState = (value: SelectValue): void => {
    const { personalDetails } = this.props.personalDetailsStore;
    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { state: value as string }) as IPersonalDetails,
    );
    this.formRef.current.setFieldsValue({ state: value });
  };

  onChangePrimaryPostalCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const { personalDetails } = this.props.personalDetailsStore;

    this.props.setPersonalDetails(
      updatePersonalInfo(personalDetails, { postalCode: e.target.value }) as IPersonalDetails,
    );
  };

  onFocusPhone = (): void => {
    const { personalDetails } = this.props.personalDetailsStore;

    this.formRef.current.setFieldsValue({
      phone: personalDetails.phone1,
    });
  };

  onFocusPhone2 = (): void => {
    const { personalDetails } = this.props.personalDetailsStore;

    this.formRef.current.setFieldsValue({
      phone2: personalDetails.phone1,
    });
  };

  onFocusPhone3 = (): void => {
    const { personalDetails } = this.props.personalDetailsStore;

    this.formRef.current.setFieldsValue({
      phone3: personalDetails.phone1,
    });
  };

  onSaveChanges = () => {
    const { personalDetails } = this.props.personalDetailsStore;
    const {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      postalCode,
      country,
      phone1,
      phone2,
      phone3,
      email1,
      email2,
    } = personalDetails;

    const data = {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      postalCode,
      country,
      phone1,
      phone2,
      phone3,
      email1,
      email2,
    };

    this.formRef.current.setFieldsValue({ firstName: firstName });
    this.formRef.current.setFieldsValue({ lastName: lastName });
    this.formRef.current.setFieldsValue({ email1: email1 });
    this.formRef.current.setFieldsValue({ phone1: phone1 });
    this.formRef.current.setFieldsValue({ address1: address1 });
    this.formRef.current.setFieldsValue({ city: city });
    this.formRef.current.setFieldsValue({ state: state });
    this.formRef.current.setFieldsValue({ postalCode: postalCode });
    this.formRef.current.setFieldsValue({ country: country });

    const formPersonalDetails = this.formRef.current.validateFields();

    Promise.all([formPersonalDetails]).then(() => {
      this.props.updatePersonalDetails(data);
      scrollTop();
    });
  };

  componentDidMount() {
    this.props.getPersonalDetails();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { personalDetailsStore, intl } = this.props;
    const { updateSuccess, updateDetailsLoading } = personalDetailsStore;

    if (prevProps.personalDetailsStore.updateSuccess !== updateSuccess && !updateDetailsLoading) {
      if (updateSuccess.isSuccess) {
        notification['success']({
          message: intl.formatMessage({ id: 'your.details.have.been.updated' }),
          icon: <SuccessSmallSvg />,
          className: 'all-bookings__notification success',
        });
        this.props.getNavigationMenu();
      } else {
        notification['error']({
          message: updateSuccess.message,
          icon: <ErrorSmallSvg />,
          className: 'all-bookings__notification error',
        });
      }
    }
  }

  render(): React.ReactNode {
    const { Option } = Select;
    const { personalDetailsStore, intl } = this.props;
    const { personalDetails, loading, updateDetailsLoading } = personalDetailsStore;
    const selectedCountry = personalDetails ? countriesCodeWithStates.find((item) => item.code3 === personalDetails.country) : null;

    return (
      <>
        <Spin spinning={updateDetailsLoading}>
          {personalDetails && !loading ? (
            <div className="personal-details" ref={this.wrapperRef}>
              <div className="personal-details__wrapper">
                <p className="personal-details__title">
                  <FormattedMessage id="personal.details" />
                </p>
                <div className="personal-details__form-wrapper">
                  <Form name="personal-details" ref={this.formRef}>
                    <div className="personal-details__block-wrapper">
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'error.message.required' }),
                          },
                          {
                            pattern: checkSpecialCharacters,
                            message: intl.formatMessage({ id: 'error.message.special.characters' }),
                          },
                          {
                            max: MAX_LENGTH_NAME,
                            message: intl.formatMessage(
                              { id: 'error.message.max' },
                              { max: MAX_LENGTH_NAME },
                            ),
                          },
                        ]}
                      >
                        <div className="personal-details__name-input-wrapper">
                          <span className="personal-details__input-label">
                            <FormattedMessage id="first.name" />
                          </span>
                          <div className="personal-details__input">
                            <Input
                              placeholder={intl.formatMessage({ id: 'first.name' })}
                              value={personalDetails.firstName}
                              maxLength={MAX_LENGTH_NAME}
                              onChange={(e) => {
                                this.onChangeFirstName(e);
                              }}
                            />
                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'error.message.required' }),
                          },
                          {
                            pattern: checkSpecialCharacters,
                            message: intl.formatMessage({ id: 'error.message.special.characters' }),
                          },
                          {
                            max: MAX_LENGTH_NAME,
                            message: intl.formatMessage(
                              { id: 'error.message.max' },
                              { max: MAX_LENGTH_NAME },
                            ),
                          },
                        ]}
                      >
                        <div className="personal-details__name-input-wrapper">
                          <span className="personal-details__input-label">
                            <FormattedMessage id="last.name" />
                          </span>
                          <div className="personal-details__input">
                            <Input
                              placeholder={intl.formatMessage({ id: 'last.name' })}
                              value={personalDetails.lastName}
                              maxLength={MAX_LENGTH_NAME}
                              onChange={(e) => {
                                this.onChangeLastName(e);
                              }}
                            />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                    <p className="personal-details__block-title">
                      <FormattedMessage id="contact.info" />
                    </p>
                    <Form.Item
                      name="email1"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'error.message.required' }),
                        },
                        {
                          pattern: new RegExp(mailFormat),
                          message: intl.formatMessage({ id: 'error.message.email.validation' }),
                        },
                        {
                          max: MAX_LENGTH_EMAIL,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_EMAIL },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="email" />
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'email.address' })}
                            value={personalDetails.email1}
                            maxLength={MAX_LENGTH_EMAIL}
                            onChange={this.validateEmail1}
                            onFocus={this.onFocus1}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="additional-email"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          pattern: new RegExp(mailFormat),
                          message: intl.formatMessage({ id: 'error.message.email.validation' }),
                        },
                        {
                          max: MAX_LENGTH_EMAIL,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_EMAIL },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="additional.email" />
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'email.address' })}
                            value={personalDetails.email2}
                            maxLength={MAX_LENGTH_EMAIL}
                            onChange={this.validateEmail2}
                            onFocus={this.onFocus2}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="phone1"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'error.message.required' }),
                        },
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_NUMBER,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_NUMBER },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper postal-code-input">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="mobile.phone" /> 1
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'phone.number' })}
                            onChange={(e) => {
                              this.onChangePhone(e);
                            }}
                            onFocus={this.onFocusPhone}
                            value={
                              isUndefined(personalDetails.phone1)
                                ? ''
                                : setNumberPhone(personalDetails.phone1)
                            }
                            maxLength={MAX_LENGTH_NUMBER}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="phone2"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_NUMBER,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_NUMBER },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper postal-code-input">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="mobile.phone" /> 2
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'phone.number' })}
                            onChange={(e) => {
                              this.onChangePhone2(e);
                            }}
                            onFocus={this.onFocusPhone2}
                            value={
                              isUndefined(personalDetails.phone2)
                                ? ''
                                : setNumberPhone(personalDetails.phone2)
                            }
                            maxLength={MAX_LENGTH_NUMBER}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <p className="personal-details__block-title">
                      <FormattedMessage id="primary.address" />
                    </p>
                    <Form.Item
                      name="address1"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'error.message.required' }),
                        },
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_LAST_NAME,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_LAST_NAME },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="address.line" values={{ number: 1 }} />
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'street.and.number' })}
                            value={personalDetails.address1}
                            maxLength={MAX_LENGTH_LAST_NAME}
                            onChange={(e) => {
                              this.onChangeAddressLine1(e);
                            }}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="primaryAddressLine2"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_LAST_NAME,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_LAST_NAME },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper">
                        <span className="personal-details__input-label">
                          <FormattedMessage
                            id="address.line"
                            values={{ number: 2, max: MAX_LENGTH_LAST_NAME }}
                          />
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'address.optional' })}
                            value={personalDetails.address2}
                            maxLength={MAX_LENGTH_LAST_NAME}
                            onChange={(e) => {
                              this.onChangeAddressLine2(e);
                            }}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'error.message.required' }),
                        },
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_NAME,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_NAME },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="city" />
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'city' })}
                            maxLength={MAX_LENGTH_NAME}
                            value={personalDetails.city}
                            onChange={this.onChangePrimaryCity}
                          />
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'error.message.required' }),
                        },
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_NAME,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_NAME },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="country" />
                        </span>
                        <div className="personal-details__input">
                          <Select
                            showSearch
                            placeholder={hideBrowserAutoFill(
                              intl.formatMessage({ id: 'country' }),
                              SECOND_CHARACTER_POSITION,
                            )}
                            value={personalDetails?.country}
                            onChange={this.onChangePrimaryCountry}
                          >
                            {countriesCodeWithStates.map((item, index) => (
                              <Option key={index} value={item.code3}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                    {selectedCountry?.states.length > ZERO ? (
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'error.message.required' }),
                          },
                          {
                            pattern: checkSpecialCharacters,
                            message: intl.formatMessage({ id: 'error.message.special.characters' }),
                          },
                          {
                            max: MAX_LENGTH_NAME,
                            message: intl.formatMessage(
                              { id: 'error.message.max' },
                              { max: MAX_LENGTH_NAME },
                            ),
                          },
                        ]}
                      >
                        <div className="personal-details__name-input-wrapper">
                          <span className="personal-details__input-label">
                            <FormattedMessage id="state" />
                          </span>
                          <div className="personal-details__input">
                            <Select
                              showSearch
                              filterOption={(input, option:any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={hideBrowserAutoFill(
                                intl.formatMessage({ id: 'select.state' }),
                                TENTH_CHARACTER_POSITION,
                              )}
                              value={personalDetails?.state}
                              onChange={this.onChangePrimaryState}
                            >
                              {selectedCountry.states.map((item, index) => {
                                return (
                                  <Select.Option key={index} value={item.code}>
                                    {item.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                      </Form.Item>)
                      : null}
                    <Form.Item
                      name="postalCode"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'error.message.required' }),
                        },
                        {
                          pattern: checkSpecialCharacters,
                          message: intl.formatMessage({ id: 'error.message.special.characters' }),
                        },
                        {
                          max: MAX_LENGTH_NUMBER,
                          message: intl.formatMessage(
                            { id: 'error.message.max' },
                            { max: MAX_LENGTH_NUMBER },
                          ),
                        },
                      ]}
                    >
                      <div className="personal-details__name-input-wrapper postal-code-input">
                        <span className="personal-details__input-label">
                          <FormattedMessage id="postal-code" />
                        </span>
                        <div className="personal-details__input">
                          <Input
                            placeholder={intl.formatMessage({ id: 'postal.code.label' })}
                            onChange={this.onChangePrimaryPostalCode}
                            value={personalDetails.postalCode}
                            max={MAX_LENGTH_NUMBER}
                          />
                        </div>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
                <div className="personal-details__btn-wrapper">
                  <BlueButton onClick={this.onSaveChanges}>
                    <FormattedMessage id="save.changes" />
                  </BlueButton>
                </div>
              </div>
            </div>
          ) : null}
          {loading ? <SkeletonPersonalInfo /> : null}
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    personalDetailsStore: state.personalDetailsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getPersonalDetails,
  updatePersonalDetails,
  setPersonalDetails: personalDetailsActions.setPersonalDetails,
  getNavigationMenu,
};

export const PersonalDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PersonalDetailsComponent));

