import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminPresentationRules, INewPresentationRule, INewPresentationRuleRequest, IPresentationRule, ISavingNewPriseRule } from '@common-types';
import { AppThunk, axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';

export interface IAdminPresentationRulesState {
  presentationRules: IAdminPresentationRules[];
  loading: boolean;
  error: string;
  newPresentationRule: INewPresentationRule;
  loadingNewPresentationRule: boolean;
  savingNewPresentationRule: ISavingNewPriseRule;
  errorNewPresentationRule: string;
  presentationRuleExists: boolean;
  duplicatePresentationId: number;
}

const initialState: IAdminPresentationRulesState = {
  presentationRules: null,
  loading: false,
  error: '',
  newPresentationRule: null,
  loadingNewPresentationRule: false,
  savingNewPresentationRule: null,
  errorNewPresentationRule: '',
  presentationRuleExists: false,
  duplicatePresentationId: null,
};

const adminPresentationRulesSlice = createSlice({
  name: 'searchRules',
  initialState,
  reducers: {
    setLoadingPresentationRules: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = payload;
    },
    setErrorPresentationRules: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = payload;
    },
    setPresentationRules: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<IAdminPresentationRules[]>,
    ) => {
      state.presentationRules = payload;
    },
    setNewPresentationRule: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<INewPresentationRule>,
    ) => {
      state.newPresentationRule = payload;
    },
    setLoadingNewPresentationRule: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loadingNewPresentationRule = payload;
    },
    setSavingNewPresentationRule: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<ISavingNewPriseRule>,
    ) => {
      state.savingNewPresentationRule = payload;
    },
    setErrorNewPresentationRule: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<string>,
    ) => {
      state.errorNewPresentationRule = payload;
    },
    setPresentationRuleExists: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.presentationRuleExists = payload;
    },
    setDuplicatePresentationId: (
      state: IAdminPresentationRulesState,
      { payload }: PayloadAction<number>,
    ) => {
      state.duplicatePresentationId = payload;
    },
  },
});

export const {
  setNewPresentationRule,
  setLoadingNewPresentationRule,
  setSavingNewPresentationRule,
  setErrorNewPresentationRule,
  setPresentationRuleExists,
  setLoadingPresentationRules,
  setPresentationRules,
  setErrorPresentationRules,
  setDuplicatePresentationId,
} = adminPresentationRulesSlice.actions;

export const adminPresentationRulesReducer = adminPresentationRulesSlice.reducer;

export const getPresentationRules = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingPresentationRules(true));

    try {
      const res = await axiosInstance.post(Urls.CondoPresentationRules, {
        ...getHeaders(),
      });

      const data = res.data.rules.map((rule: IPresentationRule) => {
        return {
          id: rule.ruleId,
          name: rule.description,
          siteId: rule.siteId,
          supplier: rule.supplierPriorities[0].supplierName,
          isActive: rule.status === 'Active' ? true : false,
          updateDate: rule.lastUpdated,
        };
      });

      dispatch(setLoadingPresentationRules(false));
      dispatch(setPresentationRules(data));
    } catch (error) {
      console.error(error);
      dispatch(setErrorPresentationRules(error.toString()));
      dispatch(setLoadingPresentationRules(false));
    }
  };
};

export const addNewPresentationRule = (
  newPresentationRule: INewPresentationRuleRequest,
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingNewPresentationRule(true));

    try {
      const res = await axiosInstance.post(
        `${Urls.CondoPresentationRules}/new`,
        newPresentationRule,
        {
          ...getHeaders(),
        },
      );

      if (res.data.isDuplicate) {
        dispatch(setDuplicatePresentationId(res.data.existingRuleId));
        dispatch(setPresentationRuleExists(true));
        dispatch(setLoadingNewPresentationRule(false));
        return;
      }

      dispatch(setSavingNewPresentationRule(res.data));
      dispatch(setLoadingNewPresentationRule(false));
    } catch (error) {
      console.error(error);
      dispatch(setErrorNewPresentationRule(error.toString()));
      dispatch(setPresentationRuleExists(true));
      dispatch(setLoadingNewPresentationRule(false));
    }
  };
};

