import React from 'react';

interface ReviewsProps {
  reviews: any;
  starRating: any;
  reviewsCount: any;
  guestRatings: any;
  comfort?: any;
  cleanliness?: any;
}

const Reviews: React.FC<ReviewsProps> = ({ reviews, starRating, reviewsCount, guestRatings }) => {
  const renderStars = (rating) => {
    const fullStars = parseInt(rating);
    const totalStars = 5;
    return Array.from({ length: totalStars }, (_, i) =>
      i < fullStars ? 'bi bi-star-fill text-warning' : 'bi bi-star text-warning',
    );
  };

  const renderProgress = (label:any, rating:any) => (
    <div className="row gx-0 g-2 align-items-center" key={label}>
      <div className="col-3 col-sm-3">
        <span className="h6">
          <small>{label}</small>
        </span>
      </div>
      <div className="col-6 col-sm-6">
        <div className="progress-new progress-sm bg-warning bg-opacity-15">
          <div
            className="progress-bar-new bg-warning"
            role="progressbar"
            style={{ width: `${(parseFloat(rating) / 5) * 100}%` }}
            aria-valuenow={rating}
            aria-valuemin={0}
            aria-valuemax={5}
          ></div>
        </div>
      </div>
      <div className="col-3 col-sm-3 text-end">
        <span className="h6 fw-light mb-0">{rating} / 5</span>
      </div>
    </div>
  );

  const ratingLabels = {
    comfort: 'Comfort',
    amenities: 'Amenities',
    cleanliness: 'Cleanliness',
    service: 'Service',
    condition: 'Condition',
    neighborhood: 'Neighborhood',
    overall: 'Overall',
  };

  return (
    <div className="mt-6">
      <div className="card bg-transparent">
        <div className="card-header border-bottom bg-transparent px-0 pt-0">
          <h3 className="mb-0">Guest reviews</h3>
        </div>

        <div className="card-body pt-2 p-0">
          <div className="card bg-light p-4 mb-4">
            <div className="row g-4 align-items-center">
              <div className="col-md-4">
                <div className="text-center">
                  <h2 className="mb-0">{reviews}</h2>
                  <p className="mb-2">Based on {reviewsCount} Reviews</p>
                  <ul className="list-inline mb-0">
                    {renderStars(reviews).map((starClass, index) => (
                      <li key={index} className="list-inline-item me-0">
                        <i className={starClass}></i>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-md-8">
                <div className="card-body p-0 ">
                  {Object.keys(ratingLabels).map((key) =>
                    guestRatings[key] ? renderProgress(ratingLabels[key], guestRatings[key]) : null,
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Reviews };
