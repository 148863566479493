import { scrollTop } from '@share/utils';
import React, { Component } from 'react';
import { ContactUsForm } from '../contact-us-form';
import { ContactUsHeading } from '../contact-us-heading';

import './style.scss';

export class ContactUsWrapper extends Component {
  componentDidMount(): void {
    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <>
        <ContactUsHeading />
        <ContactUsForm />
      </>
    );
  }
}
