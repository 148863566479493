import React from 'react';

export const SearchingSvg: React.FunctionComponent = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="url(#paint0_linear_66_535)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.571 9.60938C26.6151 9.60938 31.5326 14.5269 31.5326 20.571C31.5326 26.6151 26.6151 31.5326 20.571 31.5326C14.5269 31.5326 9.60938 26.6151 9.60938 20.571C9.60938 14.5269 14.5269 9.60938 20.571 9.60938ZM20.5312 26.7188C20.5312 26.7188 24.75 21.83 24.75 19.5C24.75 17.17 22.8612 15.2812 20.5312 15.2812C18.2013 15.2812 16.3125 17.17 16.3125 19.5C16.3125 21.83 20.5312 26.7188 20.5312 26.7188Z"
      fill="url(#paint1_linear_66_535)"
    />
    <circle cx="20.5" cy="19.5" r="2.5" fill="url(#paint2_linear_66_535)" />
    <path
      d="M31.1161 27.5392C30.1754 28.9579 28.9578 30.1755 27.5391 31.1162L34.5666 38.1437C34.8959 38.473 35.4295 38.473 35.7589 38.1437L38.1436 35.759C38.4729 35.4296 38.4729 34.896 38.1436 34.5667L31.1161 27.5392Z"
      fill="url(#paint3_linear_66_535)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_66_535"
        x1="24"
        y1="48"
        x2="24"
        y2="-21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5F4DC" />
        <stop offset="0.9973" stopColor="#F0FFF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_66_535"
        x1="20.571"
        y1="45.5096"
        x2="20.571"
        y2="8.80856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83F3A9" />
        <stop offset="1" stopColor="#00B59C" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_66_535"
        x1="20.5"
        y1="25.1877"
        x2="20.5"
        y2="16.8174"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83F3A9" />
        <stop offset="1" stopColor="#00B59C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_66_535"
        x1="32.9648"
        y1="45.5096"
        x2="32.9648"
        y2="8.80862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83F3A9" />
        <stop offset="1" stopColor="#00B59C" />
      </linearGradient>
    </defs>
  </svg>
);
