import React from 'react';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import moment from 'moment';
import { enUS } from 'date-fns/esm/locale'

import { LabeledValue } from 'antd/lib/select';
import { FormattedMessage } from 'react-intl';

import { MONTHS, SESSION_EXPIRATION_DELAY, DATE_FORMAT_ISO, DATE_FORMAT_UNIT_ROW, DATE_FORMAT_BASE } from '@share/constants';

import { creatingArrayWithNumber } from './common';
import { get } from 'lodash';

const MAX_YEARS = 20;
const ZERO = 0;
const ONE = 1;
const TEN = 10;
const isoFormat = 'yyyy-MM-dd';
const startTime = 'T00:00:01.000Z';

export const getMonths = (): LabeledValue[] => {
  return [
    { value: 0, label: <FormattedMessage id="january" /> },
    { value: 1, label: <FormattedMessage id="february" /> },
    { value: 2, label: <FormattedMessage id="march" /> },
    { value: 3, label: <FormattedMessage id="april" /> },
    { value: 4, label: <FormattedMessage id="may" /> },
    { value: 5, label: <FormattedMessage id="june" /> },
    { value: 6, label: <FormattedMessage id="july" /> },
    { value: 7, label: <FormattedMessage id="august" /> },
    { value: 8, label: <FormattedMessage id="september" /> },
    { value: 9, label: <FormattedMessage id="october" /> },
    { value: 10, label: <FormattedMessage id="november" /> },
    { value: 11, label: <FormattedMessage id="december" /> },
  ];
};

export const getTimeout = (expireDate: string): number => {
  const date = moment(expireDate, 'YYYY-MM-DDThh:mm:ssZ').toDate();
  const now = moment().toDate();
  return differenceInMilliseconds(date, now) - SESSION_EXPIRATION_DELAY;
};

export const getNights = (dateFrom: string, dateTo: string): number => {
  const toDate = moment(dateTo, 'YYYY-MM-DDThh:mm:ssZ').toDate();
  const fromDate = moment(dateFrom, 'YYYY-MM-DDThh:mm:ssZ').toDate();
  return differenceInDays(toDate, fromDate);
};

export const getOptionsYear = (): number[] => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return creatingArrayWithNumber(MAX_YEARS, currentYear);
};

export const getOptionsMonths = (year: string): any[] => {
  const date = moment();
  const currentMonth = date.month();
  const currentYear = date.year();

  if (currentYear === parseInt(year)) {
    return MONTHS.filter(month => parseInt(month.number) - 1 >= currentMonth)
  }

  return MONTHS;
};

export const isValidMonth = (month: string, year: string): boolean => {
  const date = moment();
  const currentMonth = date.month();
  const currentYear = date.year();

  if (currentYear === parseInt(year)) {
    return parseInt(month) >= currentMonth;
  }

  return true;
};

export const formatDateCheckInOut = (date: string, dateFormat: string, localeSelected?: Locale): string => {
  const locale = localeSelected ? localeSelected : enUS;
  return format(getDateCheckInOut(date), dateFormat, { locale });
};

export const formatDateCheckInOutWithLabel = (date: string/*, localeSelected?: Locale*/): string => {
  //const locale = localeSelected ? localeSelected : enUS;
  //const formattedDate = format(getDateCheckInOut(date), DATE_FORMAT_UNIT_ROW, { locale });
  const momentDate = moment(date, DATE_FORMAT_BASE);
  const formattedDate = momentDate.format(DATE_FORMAT_UNIT_ROW);
  const formattedDateSplitted = formattedDate.split(', ');
  return `<label className="grey">${get(formattedDateSplitted,'[0]')}</label>, ${get(formattedDateSplitted,'[1]')}, ${get(formattedDateSplitted,'[2]')}`
};

export const getDateCheckInOut = (date: string): Date => {
  if (!(date?.length >= 10)) {
    return undefined;
  }

  return moment(date, DATE_FORMAT_ISO).toDate();
};

export const getDateCheckInOutISOString = (date: Date): string => {
  return date === undefined || date === null
    ? undefined
    : format(date, isoFormat) + startTime;
};

export const IsMinDate = (date: Date): boolean => {
  const minDate = moment("0001-01-01T00:00:00");
  const receiveDate = moment(date);
  return !(moment(receiveDate).isAfter(minDate));
};