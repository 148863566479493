import React, { useEffect, useState } from 'react';
import TopNav from '../../components/dashboard2/top-nav/component';
import { DashboardHero } from '../../components/dashboard2/dashboard-hero';
import DashboardCards from '../../components/dashboard2/dashboard-cards/component';
import Amenities from '../../components/dashboard2/amenities/component';
import { Reviews } from '../../components/dashboard2/reviews';
import HotelInformation from '../../components/dashboard2/hotel-inforamation/component';
import { Base64 } from 'js-base64';
import useApi from '../../../api-hook/component';
import { useLocation } from 'react-router-dom';
import { DashboardHeroSkeleton } from '../../components/dashboard2/dashboard-hero-skeleton';
import FlexRoomSkeleton from '../../components/dashboard2/flex-room-skeleton/component';
import DashboardCardsSkeleton from '../../components/dashboard2/dashboard-cards-skeleton/component';
import ErrorMessage from '../../components/common/error-message/component';
import { useParams } from 'react-router-dom';
import { QUERY_PARAMS } from '../../../constants/index';


const DashboardHotel = () => {
  const location = useLocation();
  const [hotelData, setHotelData] = useState<any>(null);
  const [sessionKey, setSessionKey] = useState();
  const [loading, setLoading] = useState(true);

  const { fetchData } = useApi();

  async function fetchHotels(payload) {
    try {
      const result = await fetchData('/hotels/hotel', payload, 'post');
      setHotelData(result || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const { hotelDetails, roomsContent, adjustedPackageGroups } = hotelData || {};
  const { id } = useParams<any>();

  const {
    title: hotelName,
    images,
    address: hotelAddress,
    city,
    state,
    zipCode,
    amenities,
    starRating,
    reviews,
    reviewsCount,
    comfort,
    cleanliness,
    phone,
    checkInInformation,
    checkOutInformation,
    pets,
    guestRatings,
  } = hotelDetails || {};

  const parseQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const rooms = JSON.parse(Base64.decode(queryParams.get(QUERY_PARAMS.ROOMS) ?? 'null'));
    const locationData = JSON.parse(
      Base64.decode(queryParams.get(QUERY_PARAMS.LOCATION) ?? 'null'),
    );
    const dateObject = JSON.parse(Base64.decode(queryParams.get(QUERY_PARAMS.DATE) ?? 'null'));
    const sessionKey = JSON.parse(
      Base64.decode(queryParams.get(QUERY_PARAMS.SESSION_KEY) ?? 'null'),
    );

    return { id, rooms, locationData, dateObject, sessionKey };
  };

  const {
    locationData: parsedLocationData,
    dateObject: parsedDateObject,
    rooms,
  } = parseQueryParams();

  useEffect(() => {
    const { id, rooms, dateObject, sessionKey } = parseQueryParams();
    setSessionKey(sessionKey);

    if (id) {
      const payload = {
        marginator: {
          percentage: 0,
        },
        sessionKey: {
          ...sessionKey,
        },
        hotelRequest: {
          residency: 'US',
          location: '657822',
          checkIn: dateObject.checkIn,
          checkOut: dateObject.checkOut,
          rooms,
          hotelId: id,
        },
        currency: 'USD',
        lifeStyle: null,
      };
      fetchHotels(payload);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <TopNav
        location={parsedLocationData}
        checkInDate={parsedDateObject.checkIn}
        checkOutDate={parsedDateObject.checkOut}
        rooms={rooms}
      />
      {loading ? (
        <>
          <DashboardHeroSkeleton />
          <FlexRoomSkeleton />
          <section className="pt-0">
            <a id="rooms"></a>
            <div className="container" data-sticky-container>
              <div className="row g-4 g-xl-5">
                <div className="col-xl-12 order-1">
                  <div className="vstack gap-4">
                    <DashboardCardsSkeleton />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {hotelDetails ? (
            <>
              <DashboardHero
                hotelName={hotelName}
                hotelAddress={hotelAddress}
                city={city}
                state={state}
                zipCode={zipCode}
                images={images}
                hotelData={hotelData}
              />
              <section className="pt-0">
                <div className="container" data-sticky-container>
                  <h6 className="fw-bold mx-2">
                    Room availability: {adjustedPackageGroups?.length}
                  </h6>
                  <div className="order-1">
                    <div className="gap-4">
                      <DashboardCards
                        adjustedPackageGroups={adjustedPackageGroups}
                        location={parsedLocationData}
                        dateObject={parsedDateObject}
                        rooms={rooms}
                        roomsContent={roomsContent}
                        sessionKey={sessionKey}
                      />
                      {amenities?.length > 1 && <Amenities categories={amenities} />}
                      <Reviews
                        guestRatings={guestRatings}
                        reviews={reviews}
                        starRating={starRating}
                        reviewsCount={reviewsCount}
                        comfort={comfort}
                        cleanliness={cleanliness}
                      />
                      <HotelInformation
                        checkOutInformation={checkOutInformation[0]}
                        checkInInformation={checkInInformation[1]}
                        phone={phone}
                        pets={pets}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <div className="mb-6">
              <ErrorMessage
                heading="The room you selected is no longer available"
                subHeading="This hotel has confirmed that the room with the properties you selected is no longer available at this time"
                text="Don't worry! Try again but changing your search criteria"
                button="search again"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { DashboardHotel };
