import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';

import {
  hotelsReducer,
  locationsReducer,
  roomsReducer,
  datesReducer,
  loginReducer,
  filtersReducer,
  condoFiltersReducer,
  navigationMenuReducer,
  benefitsReducer,
  marginatorReducer,
  currencyReducer,
  reservationsReducer,
  passwordReducer,
  travelHistoryReducer,
  myProfileReducer,
  trustYouReviewReducer,
  condoGuestsReducer,
  condosReducer,
  condosLocationPickerReducer,
  condoStrictDatesReducer,
  condoFlexibleDatePickerReducer,
  condoDetailsReducer,
  quotesReducer,
  reservationCancellationReducer,
  condosStaticReducer,
} from '@share/store/slices';

import {
  getawaysReducer,
  getawaysDetailsReducer,
  hotelDetailsReducer,
  reviewBookReducer,
  roomsSearchReducer,
  redeemCodeReducer,
  unitsSearchReducer,
  condoReviewBookReducer,
  condoRedeemCodeReducer,
  adminPriceRulesReducer,
  adminChangePriceRuleReducer,
  adminActivityUsersReducer,
  accountsReducer,
  adminBookingsReducer,
  adminBookingDetailsReducer,
  accountSaveReducer,
  accountUsersReducer,
  accountUserSaveReducer,
  adminPresentationRulesReducer,
  adminChangePresentationRulesReducer,
  adminReminderReducer,
  supportPhoneReducer,
  magazineReducer,
  travelLeaderReducer,
  condoPopularDestinationsReducer,
  popularDestinationsReducer,
  popularDestinationsOffersReducer,
  notificationBarReducer,
  getawayBookingReducer,
  currentUserReducer,
  getawayDatesReducer,
  personalDetailsReducer,
  webinarsReducer,
  insuranceReducer
} from '../slices';
import * as slices from '../slices';

export const rootReducer = combineReducers({
  hotelsStore: hotelsReducer,
  locationsStore: locationsReducer,
  roomsStore: roomsReducer,
  datesStore: datesReducer,
  getawaysStore: getawaysReducer,
  getawaysDetailsStore: getawaysDetailsReducer,
  navigationMenuStore: navigationMenuReducer,
  filtersStore: filtersReducer,
  hotelDetailsStore: hotelDetailsReducer,
  reviewBookStore: reviewBookReducer,
  roomsSearchStore: roomsSearchReducer,
  trustYouReviewStore: trustYouReviewReducer,
  redeemCodeStore: redeemCodeReducer,
  condoGuestsStore: condoGuestsReducer,
  condosStore: condosReducer,
  condosStaticStore: condosStaticReducer,
  condosLocationPickerStore: condosLocationPickerReducer,
  condoStrictDatesStore: condoStrictDatesReducer,
  condoFlexibleDatePickerStore: condoFlexibleDatePickerReducer,
  condoDetailsStore: condoDetailsReducer,
  unitsSearchStore: unitsSearchReducer,
  condoReviewBookStore: condoReviewBookReducer,
  condoRedeemCodeStore: condoRedeemCodeReducer,
  adminPriceRulesStore: adminPriceRulesReducer,
  adminChangePriceRuleStore: adminChangePriceRuleReducer,
  adminActivityUsersStore: adminActivityUsersReducer,
  accountsStore: accountsReducer,
  accountSaveStore: accountSaveReducer,
  accountUsersStore: accountUsersReducer,
  condoFiltersStore: condoFiltersReducer,
  adminBookingsStore: adminBookingsReducer,
  adminHotelBookingsStore: slices.adminHotelBookingsReducer,
  adminBookingDetailsStore: adminBookingDetailsReducer,
  loginStore: loginReducer,
  accountUserSaveStore: accountUserSaveReducer,
  adminPresentationRulesStore: adminPresentationRulesReducer,
  adminChangePresentationRulesStore: adminChangePresentationRulesReducer,
  adminReminderStore: adminReminderReducer,
  routing: routerReducer,
  reservationCancellationStore: reservationCancellationReducer,
  supportPhoneStore: supportPhoneReducer,
  benefitsStore: benefitsReducer,
  magazineStore: magazineReducer,
  travelLeaderStore: travelLeaderReducer,
  condoPopularDestinationsStore: condoPopularDestinationsReducer,
  popularDestinationsStore: popularDestinationsReducer,
  popularDestinationsOffersStore: popularDestinationsOffersReducer,
  notificationBarStore: notificationBarReducer,
  getawayBookingStore: getawayBookingReducer,
  currentUserStore: currentUserReducer,
  getawayDatesStore: getawayDatesReducer,
  memberStory: slices.memberStoriesReducer,
  memberVideos: slices.memberVideosReducer,
  personalDetailsStore: personalDetailsReducer,
  adminAllBookingsStore: slices.adminAllBookingsReducer,
  adminHotelDetailsStore: slices.adminHotelDetailsReducer,
  marginatorStore: marginatorReducer,
  webinarsStore: webinarsReducer,
  insuranceStore: insuranceReducer,
  currencyStore: currencyReducer,
  reservationsStore: reservationsReducer,
  passwordStore: passwordReducer,
  travelHistoryStore: travelHistoryReducer,
  myProfileStore: myProfileReducer,
  quoteStore: quotesReducer
});
