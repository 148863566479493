import React, { useState } from "react";
import SideNav from "../../components/dashboard/side-nav/component";
import HotelCard from "./hotel-card";
import img2 from "@assets/images/sample-hotels/sheraton/328044214.jpg";

const initialHotelsData = [
  {
    imgSrc: img2,
    name: "Cambria Hotel Orlando Universal Blvd",
    address: "6801 Visitors Circle, Orlando, FL 32819",
    price: 108,
  },
  {
    imgSrc: img2,
    name: "Sheraton Orlando North",
    address: "600 N Lake Destiny Dr, Maitland, FL 32751",
    price: 116,
  },
];

const AccountSaved = () => {
  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleRemoveAll = (e) => {
    e.preventDefault();
    // Implement your remove all logic here
    // For example:
    // setHotelsData([]);
  };

  return (
    <section className="pt-3">
      <div className="container">
        <div className="row">
          <SideNav />
          <div className="col-lg-8 col-xl-9">
            <div className="card shadow-sm border">
              <div className="card-header bg-transparent border-bottom">
                <h4 className="card-header-title">
                  <i className="bi bi-bookmark-check mx-2"></i>Saved Properties
                </h4>
              </div>
              <div className="card-body vstack gap-4">
                <div className="card shadow p-2">
                  <form className="d-flex justify-content-between">
                    <div className="col-6 col-xl-3">
                      <select
                        className="form-select form-select-sm js-choice border-custom"
                        value={filter}
                        onChange={handleFilterChange}
                      >
                        <option value="">Sort by</option>
                        <option>Recently search</option>
                        <option>Most popular</option>
                        <option>Top rated</option>
                      </select>
                    </div>
                    <button
                      className="btn btn-danger-soft mb-0"
                      onClick={handleRemoveAll}
                    >
                      <i className="fas fa-trash me-2"></i>Remove all
                    </button>
                  </form>
                </div>
                {initialHotelsData?.map((hotel, index) => (
                  <HotelCard key={index} {...hotel} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountSaved;
