import React from 'react';
import { Spin } from 'antd';
import { GetCreditCardImage } from '@utils';

const PaymentSummaryCard = ({
  bookingInfo,
  handleInputChange,
  balance,
  validateAndSubmit,
  countries,
  states,
  handleBlur,
  inputRefs,
  errors,
  disabled,
  loading,
  cardType,
}) => {
  const { rciCountries } = countries || {};

  return (
    <div className="card shadow">
      <div className="card-header border-bottom p-4">
        <h4 className="card-title mb-0">
          <i className="bi bi-wallet me-2"></i>Payment Options
        </h4>
      </div>

      <div className="card-body p-4 pb-0">
        <div className="accordion accordion-icon accordion-bg-light" id="accordioncircle">
          <div className="accordion-item mb-3">
            <h6 className="accordion-header" id="heading-1">
              <button
                className="accordion-button rounded collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-1"
                aria-expanded="true"
                aria-controls="collapse-1"
              >
                <i className="bi bi-credit-card text-primary me-2"></i>
                <span className="me-5">Credit or Debit Card</span>
              </button>
            </h6>
            <div
              id="collapse-1"
              className="accordion-collapse collapse show"
              aria-labelledby="heading-1"
              data-bs-parent="#accordioncircle"
            >
              <div className="accordion-body">
                <div className="row g-3 pt-3">
                  <div className="col-12">
                    <label className="form-label">
                      <span className="h6 fw-normal">Name on Card *</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control form-control-lg ${
                        bookingInfo.nameOnCardError || errors.nameOnCard ? 'danger-border' : ''
                      }`}
                      placeholder="Name on Card"
                      name="nameOnCard"
                      value={bookingInfo.nameOnCard}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      ref={inputRefs.nameOnCard}
                    />
                    {bookingInfo.nameOnCardError && (
                      <div className="text-danger">{bookingInfo.nameOnCardError}</div>
                    )}
                    {errors.nameOnCard && <div className="text-danger">{errors.nameOnCard}</div>}
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      <span className="h6 fw-normal">Card Number *</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text" // Use "text" to handle input more flexibly
                        className={`form-control form-control-lg  ${
                          errors.cardNumber ? 'danger-border' : ''
                        }`}
                        placeholder="Card Number"
                        name="cardNumber"
                        value={bookingInfo.cardNumber}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        ref={inputRefs.cardNumber}
                        style={{
                          paddingRight: '40px',
                          minHeight: '40px !important',
                        }}
                      />
                      <div className="card-icon position-absolute translate-middle-y">
                        {GetCreditCardImage(cardType, true)}
                      </div>
                      {bookingInfo.cardNumberError && (
                        <div className="text-danger">{bookingInfo.cardNumberError}</div>
                      )}
                      {errors.cardNumber && <div className="text-danger">{errors.cardNumber}</div>}
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom pb-4">
                    <label className="form-label">
                      <span className="h6 fw-normal">Expiration date *</span>
                    </label>
                    <div className="input-group ">
                      <select
                        className={`form-control form-control-lg ${
                          errors.cardExpirationMonth ? 'danger-border' : ''
                        }`}
                        name="cardExpirationMonth"
                        value={bookingInfo.cardExpirationMonth}
                        onChange={handleInputChange}
                        ref={inputRefs.cardExpirationMonth}
                      >
                        <option value="">Month</option>
                        {Array.from({ length: 12 }, (_, index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                      <select
                        className={`form-control form-control-lg ${
                          errors.cardExpirationYear ? 'danger-border' : ''
                        }`}
                        name="cardExpirationYear"
                        value={bookingInfo.cardExpirationYear}
                        onChange={handleInputChange}
                        ref={inputRefs.cardExpirationYear}
                      >
                        <option value="">Year</option>
                        {Array.from({ length: 10 }, (_, index) => (
                          <option key={index} value={new Date().getFullYear() + index}>
                            {new Date().getFullYear() + index}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex jutify-content-between">
                      <div className="text-danger w-50">
                        {errors.cardExpirationMonth && errors.cardExpirationMonth}
                      </div>
                      <div className="text-danger w-50">
                        {errors.cardExpirationYear && errors.cardExpirationYear}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 border-bottom pb-4">
                    <label className="form-label">
                      <span className="h6 fw-normal">Security code *</span>
                    </label>
                    <input
                      type="number"
                      className={`form-control form-control-lg ${
                        bookingInfo.cardSecurityCodeError || errors.cardSecurityCode
                          ? 'danger-border'
                          : ''
                      }`}
                      placeholder="Security code"
                      name="cardSecurityCode"
                      value={bookingInfo.cardSecurityCode}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      ref={inputRefs.cardSecurityCode}
                    />
                    {bookingInfo.cardSecurityCodeError && (
                      <div className="text-danger">{bookingInfo.cardSecurityCodeError}</div>
                    )}
                    {errors.cardSecurityCode && (
                      <div className="text-danger">{errors.cardSecurityCode}</div>
                    )}
                  </div>

                  <label className="form-label pt-3 ">
                    <span className="h6 fw-normal bold-heading">Billing Address</span>
                  </label>
                  <div className="col-12">
                    <label className="form-label">
                      <span className="h6 fw-normal">Address *</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control form-control-lg ${
                          bookingInfo.billingAddressError || errors.billingAddress
                            ? 'danger-border'
                            : ''
                        }`}
                        placeholder="Address"
                        name="billingAddress"
                        value={bookingInfo.billingAddress}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        ref={inputRefs.billingAddress}
                      />
                      {bookingInfo.billingAddressError && (
                        <div className="text-danger">{bookingInfo.billingAddressError}</div>
                      )}
                      {errors.billingAddress && (
                        <div className="text-danger">{errors.billingAddress}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      <span className="h6 fw-normal">City *</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control form-control-lg ${
                          bookingInfo.billingCityError || errors.billingCity ? 'danger-border' : ''
                        }`}
                        placeholder="City"
                        name="billingCity"
                        onBlur={handleBlur}
                        value={bookingInfo.billingCity}
                        onChange={handleInputChange}
                        ref={inputRefs.billingCity}
                      />
                      {bookingInfo.billingCityError && (
                        <div className="text-danger">{bookingInfo.billingCityError}</div>
                      )}
                      {errors.billingCity && (
                        <div className="text-danger">{errors.billingCity}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      <span className="h6 fw-normal">Country</span>
                    </label>
                    <div className="position-relative">
                      <select
                        className={`form-control form-control-lg ${
                          errors.billingCountry ? 'danger-border' : ''
                        }`}
                        name="billingCountry"
                        onChange={handleInputChange}
                        ref={inputRefs.billingCountry}
                        defaultValue={'Select country'} // Default to empty string if no country at 3rd index
                      >
                        <option>Select country</option>
                        {rciCountries?.map((country, i) => (
                          <option key={i} value={country?.isoCode}>
                            {country?.name}
                          </option>
                        ))}
                      </select>
                      {errors.billingCountry && (
                        <div className="text-danger">{errors.billingCountry}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    {states.length > 1 && bookingInfo.billingCountry && (
                      <>
                        <label className="form-label">
                          <span className="h6 fw-normal">State *</span>
                        </label>

                        <div className="position-relative">
                          <select
                            className={`form-control form-control-lg ${
                              errors.billingState ? 'danger-border' : ''
                            }`}
                            name="billingState"
                            value={bookingInfo.billingState}
                            onChange={handleInputChange}
                            ref={inputRefs.billingState}
                          >
                            {states?.map((state, i) => (
                              <option key={i} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          {errors.billingState && (
                            <div className="text-danger">{errors.billingState}</div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="border-bottom">
                    <div className="col-md-6  pb-4">
                      <label className="form-label">
                        <span className="h6 fw-normal">Postal code *</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className={`form-control form-control-lg ${
                            bookingInfo.postalCodeError || errors.postalCode ? 'danger-border' : ''
                          } ${inputRefs.postalCode === null ? 'danger-border' : ''}`}
                          placeholder="Postal code"
                          name="postalCode"
                          value={bookingInfo.postalCode}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          ref={inputRefs.postalCode}
                        />
                      </div>
                      {bookingInfo.postalCodeError && (
                        <div className="text-danger">{bookingInfo.postalCodeError}</div>
                      )}
                      {errors.postalCode && <div className="text-danger">{errors.postalCode}</div>}
                    </div>
                  </div>
                  <label className="form-label pt-3">
                    <span className="h6 fw-normal bold-heading">Cancellation Policy:</span>
                    {'  '}Refundabale red
                  </label>
                  <div className="col-12 ">
                    <label className="form-label">
                      <span className="h6 fw-normal">
                        In the event of no-show, your booking will not qualify for a refund. There
                        are no cancellation or modifications allowed for this reservation.
                        <br />
                        <br /> You are booking a nonrefundable reservation. Our concierge staff
                        cannot assist with any changes or modifications once your reservation is
                        confirmed. We understand that unforeseen circumstances sometimes happen
                        after booking, which may impact your plans. Please be sure to ask about our
                        travel insurance, which may help cover you should any of the covered issues
                        arise.
                      </span>
                    </label>
                  </div>
                  <div className="form-check border-bottom pb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="policyAccepted"
                      checked={bookingInfo?.termsAndConditions?.policyAccepted}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">
                      By checking this box you acknowledge that you have read and accepted the terms
                      stated in this policy.
                    </label>
                  </div>
                  <div className="form-check pb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="chargeAcknowledged"
                      checked={bookingInfo?.termsAndConditions?.chargeAcknowledged}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">
                      You understand and accept you will be charged 7,918.86 USD immediately to make
                      this reservation. This charge will be made through Stripe and appear as Travel
                      Reservation in USD on your credit card statement.
                    </label>
                  </div>
                  <div className="form-check pb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="noModificationsAllowed"
                      checked={bookingInfo?.termsAndConditions?.noModificationsAllowed}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">
                      You acknowledge and agree that no alterations or modifications to the
                      reservation shall be permitted following its finalization.
                    </label>
                  </div>
                  <div className="form-check pb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="termsAccepted"
                      checked={bookingInfo?.termsAndConditions?.termsAccepted}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">
                      By checking this box you acknowledge that you have read and accepted the terms
                      stated in 'Important Information', 'Terms of Use' and 'Privacy Policy';
                      including information about mandatory fees that, if stated, must be paid at
                      property.
                    </label>
                  </div>

                  <div className="col-12 ">
                    <div className="d-sm-flex justify-content-sm-between align-items-center">
                      <h4 className="text-success">
                        ${balance?.toLocaleString()} <span className="small fs-6">Due now</span>
                      </h4>

                      <button
                        disabled={!disabled || loading}
                        onClick={validateAndSubmit}
                        className={`btn btn-primary mb-0 button-width ${
                          loading ? 'btn-disabled' : ''
                        }`}
                      >
                        {loading ? <Spin size="small" /> : `Pay Now`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer p-4 pt-0">
        <p className="mb-0">
          By processing, You accept the TravBusiness <a href="#">Terms of Services</a>
        </p>
      </div>
    </div>
  );
};

export default PaymentSummaryCard;
