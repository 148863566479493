import { ILocation } from "@share/common-types";

export enum GetawaysCondoSubCategoryEnum {
  PremierEscapes = 'premierescapes',
  ValueGetaways = 'valuegetaways',
}

export enum GetawaysLocationEnum {
  Fantasy = 'fantasy',
  Cruise = 'cruise',
  Condo = 'condo',
  Condos = 'condos',
  Staycation = 'staycation',
}

export type GetGetawaysArgs = (
  getaway: string,
  subCategory?: string,
  isLoadMore?: boolean,
  isSearch?: boolean,
) => void;

export interface ICustomGetawaysList {
  title: string;
  img?: string;
  text?: string;
  link?: string;
  subtitle?: string;
}

export interface ICustomGetawaysListHeaderInfo {
  headerImg: string;
  headerTitle: string;
  headerSubtitle: string;
}

export interface IGetawaySpecialType {
  specialTypeName: string;
  imageFolderName: string;
  creditName: string;
  vipspecialTypeId: string;
  costDisclaimerTemplate: string;
  importantInfoTemplate: string;
  bookingInstructions: string;
}

export interface IGetaway {
  title: string;
  description: string;
  checkinDate: string;
  checkoutDate: string;
  numberAvailable: number;
  numberOfNights: number;
  multipleDatesAvailable: boolean;
  youPayPrice: number;
  priceBasedOn: string;
  portFeesAndTaxes: string;
  city: string;
  country: string;
  state: string;
  promoId: string;
  accommodationType: string;
  lengthOfStay: string;
  unitSize: string;
  photoPath: string;
  subCategory: string;
  externalImageUrl: string;
  rsiresortId: number;
  portsOfCall: string[];
  specialType: IGetawaySpecialType;
  imageUrl: string;
  name: string;
  publicPrice?: number;
  saving?: number;
  savingPercentage?: number;
  location: ILocation;
  dealId?: number;
  dealUrl: string;
  supplierType: string;
}

export type GetawayIncludedItem = {
  name: string;
};

export type GetawayAmenity = {
  name: string;
  amenityType: string;
};

export interface IGetawayDetails {
  videoUrl: string;
  costDisclaimer: string;
  importantInformation: string;
  internalBookingInstructions: string;
  amenities: GetawayAmenity[];
  includedItems: GetawayIncludedItem[];
  title: string;
  description: string;
  checkinDate: string;
  numberAvailable: number;
  multipleDatesAvailable: boolean;
  youPayPrice: number;
  priceBasedOn: string;
  portFeesAndTaxes: string;
  city: string;
  country: string;
  state: string;
  promoId: string;
  accommodationType: string;
  lengthOfStay: string;
  unitSize: string;
  photoPath: string;
  subCategory: string;
  externalImageUrl: string;
  rsiresortId: number;
  specialType: IGetawaySpecialType;
  imageUrl: string;
  portsOfCall: string[];
  numberOfNights: number;
}

export interface IGetawaysLocations {
  city: string;
  state?: string;
  country?: string;
}

export const defaultLocationValue = 'All locations';

export const GetawaysDefaultLocation = {
  city: defaultLocationValue,
  country: '',
  state: '',
};

export const GetawaysInfoList = [
  {
    title: 'getaway.staycations.title',
    text: 'getaway.staycations.text',
  },
  {
    title: 'getaway.fantasy.title',
    text: 'getaway.fantasy.text',
  },
  {
    title: 'getaway.condo.title',
    text: 'getaway.condo.text',
  },
  {
    title: 'getaway.cruise.title',
    text: 'getaway.cruise.text',
  },
];

export interface IGetawayBookInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneCountryCode: string;
  preferredContact: string;
  preferredTime: string;
  comment: string;
}
export interface IGetawayInfo {
  promoId: string;
  accommodationType: string;
  title: string;
  city: string;
  state: string;
  specialType: ISpecialType;
  imageUrl: string;
  youPayPrice: number;
}

interface ISpecialType {
  importantInfoTemplate: string;
  specialTypeName: string;
}

export interface IGetawaysSubmitRequest {
  location: string;
  action: string;
  sid: string;
  rsi_id: number;
  memberNumber: number;
  getaway_type: string;
  getaway_name: string;
  org_name: string;
  departureLocation: string;
  departureState: string;
  member_website: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  preferredContact: string;
  preferredTime: string;
  numAdults: number;
  numChildrenThirteenToSeventeen: number;
  numChildrenThreeToTwelve: number;
  numInfants: number;
  firstDate: string;
  secondDate: string;
}

export const ContactMe = ['Phone', 'Email', 'Both (email and phone)'];

export const BestTime = ['Any time', 'Morning', 'Afternoon', 'Evening'];
