import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewPriceRule } from '@common-types';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';
import { ERROR_COUPON_STATUS } from '@constants';
import { Urls } from '@share/constants';

export interface IAdminChangePriceRuleState {
  changePriceRule: INewPriceRule;
  loading: boolean;
  error: string;
  isChangePriceRule: boolean;
  errorText: string;
}

const initialState: IAdminChangePriceRuleState = {
  changePriceRule: null,
  loading: false,
  error: '',
  isChangePriceRule: false,
  errorText: '',
};

const zeroItem = 0;

const adminChangePriceRuleSlice = createSlice({
  name: 'changePriceRules',
  initialState,
  reducers: {
    setLoading: (state: IAdminChangePriceRuleState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IAdminChangePriceRuleState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setChangePriceRules: (
      state: IAdminChangePriceRuleState,
      { payload }: PayloadAction<INewPriceRule>,
    ) => {
      state.changePriceRule = payload;
    },
    setIsChangePriceRules: (
      state: IAdminChangePriceRuleState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isChangePriceRule = payload;
    },
    setErrorText: (state: IAdminChangePriceRuleState, { payload }: PayloadAction<string>) => {
      state.errorText = payload;
    },
  },
});

export const adminChangePriceRuleActions = adminChangePriceRuleSlice.actions;

export const adminChangePriceRuleReducer = adminChangePriceRuleSlice.reducer;

export const changeSelectedRule = (priceRule: INewPriceRule): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(adminChangePriceRuleActions.setLoading(true));

    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.put(`${Urls.HotelPriceRules}/${priceRule.id}`, priceRule, {
        ...getHeaders(),
      });

      dispatch(adminChangePriceRuleActions.setIsChangePriceRules(res.data.success));
      dispatch(adminChangePriceRuleActions.setLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminChangePriceRuleActions.setError(error.toString()));
      dispatch(adminChangePriceRuleActions.setLoading(false));

      if (error?.response?.status === ERROR_COUPON_STATUS && error?.response?.data) {
        dispatch(
          adminChangePriceRuleActions.setErrorText(error?.response?.data[zeroItem].errorMessage),
        );
      }
    }
  };
};
