import React from 'react';
import { NavLink } from 'react-router-dom';
import logoDark from '@assets/images/logo/LOGO-4-DARK.svg';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

const Navbar = () => {
  const login = useSelector((state: IMapStateToProps) => state.loginStore);

  return (
    <header className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container">
        <NavLink to="/" className="navbar-brand">
          <img src={logoDark} alt="Company Logo" className="navbar-brand-item" />
        </NavLink>
        <div className="d-flex">
          <NavLink to={`/${login.account.name}/log-in`} className="btn btn-outline-secondary me-2">
            Log In
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
