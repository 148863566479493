import React from 'react';

export const EnjoySvg: React.FunctionComponent = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="url(#paint0_linear_66_526)"
    />
    <path
      d="M21.7013 23.1673H11.3135L10.7636 26.3226C10.705 26.6503 10.6758 26.987 10.6758 27.331C10.6758 30.2596 12.8557 32.6674 15.6742 33.0759V36.4909H13.175C12.7145 36.4909 12.3419 36.8634 12.3419 37.3236C12.3419 37.7839 12.7145 38.1563 13.175 38.1563H19.8395C20.3 38.1563 20.6725 37.7838 20.6725 37.3236C20.6725 36.8633 20.3 36.4909 19.8395 36.4909H17.3403V33.0759C20.1588 32.6674 22.3387 30.2596 22.3387 27.331C22.3387 26.9877 22.3093 26.6511 22.2517 26.3242L21.7013 23.1673ZM30.5627 10.2683C30.3642 9.91531 29.9387 9.76081 29.5588 9.89093L23.2743 12.1085C22.8927 12.2435 22.6633 12.633 22.7301 13.0323L23.3151 16.5055H34.0684L30.5627 10.2683ZM16.5072 13.1746H17.3403V14.0073C17.3403 14.4676 17.7128 14.84 18.1733 14.84C18.6338 14.84 19.0064 14.4675 19.0064 14.0073V13.1746H19.8395C20.3 13.1746 20.6725 12.8022 20.6725 12.3419C20.6725 11.8816 20.3 11.5092 19.8395 11.5092H19.0064V10.6766C19.0064 10.2162 18.6338 9.84387 18.1733 9.84387C17.7128 9.84387 17.3403 10.2163 17.3403 10.6766V11.5092H16.5072C16.0467 11.5092 15.6742 11.8817 15.6742 12.3419C15.6742 12.8022 16.0467 13.1746 16.5072 13.1746ZM20.6603 17.1951C20.5904 16.7967 20.2447 16.5055 19.8395 16.5055H13.175C12.7698 16.5055 12.424 16.7966 12.3541 17.1951L11.6036 21.5018H21.411L20.6603 17.1951ZM37.2768 29.2452C37.123 28.8125 36.6488 28.5889 36.2135 28.7369L33.8574 29.5679L32.7215 26.3507C35.3799 24.9621 36.4618 21.9333 35.5203 19.2697C35.3093 18.6689 35.0909 18.338 35.0042 18.171H23.5956L24.2692 22.1704C24.3245 22.4973 24.4074 22.8251 24.5222 23.1487C25.4755 25.8491 28.2606 27.4933 31.1533 26.9155L32.2857 30.1226L29.9289 30.9538C29.4953 31.1075 29.2675 31.5824 29.4204 32.0167C29.5714 32.4417 30.0382 32.6787 30.4837 32.5249C30.6799 32.4557 36.4045 30.4364 36.7684 30.3081C37.202 30.1543 37.4298 29.6794 37.2768 29.2452ZM36.7781 30.3046C36.7753 30.3056 36.7727 30.3065 36.7684 30.308C36.7684 30.308 36.7684 30.308 36.7683 30.308C36.7728 30.3065 36.7753 30.3056 36.7781 30.3046Z"
      fill="url(#paint1_linear_66_526)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_66_526"
        x1="24"
        y1="48"
        x2="24"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC8D1" />
        <stop offset="1" stopColor="#FFF6F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_66_526"
        x1="24.0001"
        y1="38.1563"
        x2="24.0001"
        y2="9.84378"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA68D" />
        <stop offset="1" stopColor="#FD3A84" />
      </linearGradient>
    </defs>
  </svg>
);
