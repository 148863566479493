import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, Spin } from 'antd';
import { toast } from 'react-toastify';

const { Option } = Select;

const AddTeamMember = (props) => {
  const { isOpen, setIsOpen, dptRoles, userData, onSaveSuccess } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    values.accountId = userData?.accountId;
    values.userId = userData?.userId;
    setLoading(true);
    try {
      await onSaveSuccess();
      userData
        ? toast.success('Team member updated successfully!', {
            theme: 'colored',
          })
        : toast.success('Team member added successfully!', {
            theme: 'colored',
          });
      setIsOpen(false);
    } catch (error) {
      console.error('Error parsing hotels data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData) {
      form.setFieldsValue(userData);
    }
  }, [userData]);
  return (
    <Modal
      visible={isOpen}
      title="Add New Team Member"
      onCancel={() => setIsOpen(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={form.submit} loading={loading}>
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="flex-container">
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please enter first name' }]}
            >
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please enter last name' }]}
            >
              <Input placeholder="Last name" />
            </Form.Item>
          </div>
          <Form.Item
            label="Company Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter company email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item label="State" name="state">
            <Select defaultValue="Active">
              <Option value="Active">Active</Option>
              <Option value="Locked">Locked</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Department Role"
            name="role"
            rules={[{ required: true, message: 'Please select department role' }]}
          >
            <Select placeholder="Select department role">
              {dptRoles?.items?.map((role: any, index: number) => (
                <>
                  <Option value={role.id}>{role?.name}</Option>
                </>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { AddTeamMember };
