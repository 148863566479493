import { SorterResult } from 'antd/lib/table/interface';
import { IAdminHotelBookings, HotelBookingsOrderEnum } from '@common-types';

type TSortMap = {
  [key: string]: {
    ascend: HotelBookingsOrderEnum;
    descend: HotelBookingsOrderEnum;
  };
};

const sortMap: TSortMap = {
  orderId: {
    ascend: HotelBookingsOrderEnum.OrderIdAsc,
    descend: HotelBookingsOrderEnum.OrderIdDesc,
  },
  'member.siteId': {
    ascend: HotelBookingsOrderEnum.MemberSiteIdAsc,
    descend: HotelBookingsOrderEnum.MemberSiteIdDesc,
  },
  'member.id': {
    ascend: HotelBookingsOrderEnum.MemberIdAsc,
    descend: HotelBookingsOrderEnum.MemberIdDesc,
  },
  'member.name': {
    ascend: HotelBookingsOrderEnum.MemberNameAsc,
    descend: HotelBookingsOrderEnum.MemberNameDesc,
  },
  status: { ascend: HotelBookingsOrderEnum.StatusAsc, descend: HotelBookingsOrderEnum.StatusDesc },
  checkIn: {
    ascend: HotelBookingsOrderEnum.CheckinAsc,
    descend: HotelBookingsOrderEnum.CheckinDesc,
  },
  checkOut: {
    ascend: HotelBookingsOrderEnum.CheckoutAsc,
    descend: HotelBookingsOrderEnum.CheckoutDesc,
  },
  'guest.name': {
    ascend: HotelBookingsOrderEnum.GuestNameAsc,
    descend: HotelBookingsOrderEnum.GuestNameDesc,
  },
  bookedOn: {
    ascend: HotelBookingsOrderEnum.BookedOnAsc,
    descend: HotelBookingsOrderEnum.BookedOnDesc,
  },
  supplier: {
    ascend: HotelBookingsOrderEnum.SupplierAsc,
    descend: HotelBookingsOrderEnum.SupplierDesc,
  },
  hotelName: {
    ascend: HotelBookingsOrderEnum.HotelNameAsc,
    descend: HotelBookingsOrderEnum.HotelNameDesc,
  },
  roomsCount: {
    ascend: HotelBookingsOrderEnum.RoomCountAsc,
    descend: HotelBookingsOrderEnum.RoomCountDesc,
  },
  'room.basis': {
    ascend: HotelBookingsOrderEnum.RoomBasisAsc,
    descend: HotelBookingsOrderEnum.RoomBasisDesc,
  },
  'room.name': {
    ascend: HotelBookingsOrderEnum.RoomNameAsc,
    descend: HotelBookingsOrderEnum.RoomNameDesc,
  },
  'propertyAddress.address': {
    ascend: HotelBookingsOrderEnum.PropertyAddressAsc,
    descend: HotelBookingsOrderEnum.PropertyAddressDesc,
  },
  'propertyAddress.cityName': {
    ascend: HotelBookingsOrderEnum.PropertyCityAsc,
    descend: HotelBookingsOrderEnum.PropertyCityDesc,
  },
  'propertyAddress.countryName': {
    ascend: HotelBookingsOrderEnum.PropertyCountryAsc,
    descend: HotelBookingsOrderEnum.PropertyCountryDesc,
  },
  'bookingPrice.supplier': {
    ascend: HotelBookingsOrderEnum.SupplierPriceAsc,
    descend: HotelBookingsOrderEnum.SupplierPriceDesc,
  },
  'bookingPrice.markup': {
    ascend: HotelBookingsOrderEnum.MarkupAsc,
    descend: HotelBookingsOrderEnum.MarkupDesc,
  },
  'bookingPrice.savings': {
    ascend: HotelBookingsOrderEnum.SavingsAsc,
    descend: HotelBookingsOrderEnum.SavingsDesc,
  },
  'bookingPrice.couponAmount': {
    ascend: HotelBookingsOrderEnum.DiscountAsc,
    descend: HotelBookingsOrderEnum.DiscountDesc,
  },
  'bookingPrice.profit': {
    ascend: HotelBookingsOrderEnum.ProfitAsc,
    descend: HotelBookingsOrderEnum.ProfitDesc,
  },
  'bookingPrice.total': {
    ascend: HotelBookingsOrderEnum.TotalPaidAsc,
    descend: HotelBookingsOrderEnum.TotalPaidDesc,
  },
  'bookingPrice.publicPrice': {
    ascend: HotelBookingsOrderEnum.PublicPriceAsc,
    descend: HotelBookingsOrderEnum.PublicPriceDesc,
  },
  'bookingPrice.totalProfitAmount': {
    ascend: HotelBookingsOrderEnum.ProfitAsc,
    descend: HotelBookingsOrderEnum.ProfitDesc,
  },
  'bookingPrice.totalSupplierPrice': {
    ascend: HotelBookingsOrderEnum.SupplierPriceAsc,
    descend: HotelBookingsOrderEnum.SupplierPriceDesc,
  },
};

export const getSortOrder = (sort: SorterResult<IAdminHotelBookings>): HotelBookingsOrderEnum => {
  if (sort && sort.columnKey && sort.order) {
    if (sortMap[sort.columnKey]) {
      return sortMap[sort.columnKey][sort.order];
    }
  }

  return null;
};
