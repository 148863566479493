import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER, NUMBER, NUMBER, NUMBER];

export class SkeletonBenefits extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-benefits">
        <div className="skeleton-benefits__title-wrapper">
          <Skeleton
            className="skeleton-benefits__title"
            active
            title={false}
            paragraph={{ rows: 1 }}
          />
        </div>
        <div className="skeleton-benefits__wrapper">
          <div className="skeleton-benefits__left-block">
            {NUMBER_OF_BLOCKS.map((item, index) => (
              <div key={`${index}-${item}`} className="skeleton-benefits__card">
                <Skeleton
                  className="skeleton-benefits__img"
                  active
                  title={false}
                  paragraph={{ rows: 1 }}
                />
                <Skeleton
                  className="skeleton-benefits__info"
                  active
                  title
                  paragraph={{ rows: 2 }}
                />
              </div>
            ))}
          </div>
          <div className="skeleton-benefits__right-block">
            <Skeleton
              className="skeleton-benefits__right-block-top"
              active
              title={false}
              paragraph={{ rows: 1 }}
            />
            <Skeleton
              className="skeleton-benefits__right-block-bottom"
              active
              title
              paragraph={{ rows: 2 }}
            />
          </div>
        </div>
      </div>
    );
  }
}
