import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  ResultWrapper,
  SkeletonResult,
  PopularDestinationOffers,
  Contact,
  CompanyLogos,
  SearchBenefits,
  AboutUs,
  RecentSearchesWrapper,
  Banner,
  CustomGetaways,
  GetawayList
} from '@components';
import { SearchWrapper } from '@share/components';
import { IBenefitsState, ILoginState, IMenuState, LoginType } from '@share/store/slices';
import { IHotelsState } from '@share/store/slices';
import { CanImpersonate, getPreferenceFromAccount, IsImpersonating, RootState } from '@share/utils';
import { Responsive } from '@share/utils';
import { HotelsCompanyLogos } from '@common-types';
import { DISPLAY_CONTACT_SECTION_FIELD_NAME, DISPLAY_TRUSTED_BRANDS_SECTION_FIELD_NAME, GetawaysLocationEnum, IBenefitsItems, PRIMARY_BANNER_TYPE, SECONDARY_BANNER_TYPE } from '@share/common-types';

import { FeaturedDestinations } from '../featured-destinations';
import { LifeStyleMessage } from '../lifestyle-message';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  menuStore: IMenuState;
  loginStore: ILoginState;
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {
  isVacationRentals: boolean;
}

class SearchTravcodingComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { hotelsStore, loginStore, isVacationRentals, benefitsStore } = this.props;
    const { hotels, loading, searchedLocation, error } = hotelsStore;
    const { account } = loginStore;
    
    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const hideThingsMatterSection = account?.hideThingsMatterSection;
    const hasDisplayContactSection = getPreferenceFromAccount(account, DISPLAY_CONTACT_SECTION_FIELD_NAME);
    const hasDisplayTrustedBrandsSection = getPreferenceFromAccount(account, DISPLAY_TRUSTED_BRANDS_SECTION_FIELD_NAME);
    const hasPublicValidToken = account.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

    const showImpersonate = CanImpersonate(account) && !IsImpersonating() && !account?.disableImpersonate && account?.forceImpersonate;

    return (
      <div className="search-travcoding">
        <SearchWrapper isVacationRentals={isVacationRentals} />
        {!hotels && (
          <>
            {(hasPublicValidToken && !showImpersonate) && (
              <RecentSearchesWrapper isVacationRentals={isVacationRentals} />)}

            {hasPublicValidToken && (
              <PopularDestinationOffers />)}

            {account?.displayGetawaysHomeHotels ?
              account?.displayGetawaysHomeHotelsSlider ? (
                <GetawayList
                  getaway={GetawaysLocationEnum.Staycation}
                  condoSubcategory={null}
                
                  isFromProperty
                  isInternal
                  isHome
                  isSlider
                />) : (
                <CustomGetaways benefits={benefitsStore?.benefits} noPaddingTop isFromHome />) : null}

            <Banner type={PRIMARY_BANNER_TYPE} />
            
            <LifeStyleMessage />

            <FeaturedDestinations />

            {(isEmpty(hasDisplayContactSection) || hasDisplayContactSection === 'true') ? (
              <Contact />) : null}

            {!hideThingsMatterSection ? (
              <SearchBenefits />) : null}

            {(isEmpty(hasDisplayTrustedBrandsSection) || hasDisplayTrustedBrandsSection === 'true') ? (
              <CompanyLogos logos={HotelsCompanyLogos} />) : null}

            <AboutUs />

            <Banner type={SECONDARY_BANNER_TYPE} />
          </>
        )}
        {hotels && loading && isEmpty(error) ? <SkeletonResult label={searchedLocation} /> : null}
        {(hotels || !isEmpty(error)) && !loading ? (
          <Responsive>
            <ResultWrapper label={searchedLocation} isVacationRentals={isVacationRentals} />
          </Responsive>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    benefitsStore: state.benefitsStore
  };
};

export const SearchTravcoding = connect(mapStateToProps)(SearchTravcodingComponent);
