import React from 'react';

import { FormattedMessage } from 'react-intl';

import { ICondoGuests } from '@share/common-types';

import { AdultSvg, ChildSvg, BedRoomSvg } from '@share/assets';

import { Counts } from '../counts';

import './style.scss';
import { Checkbox } from 'antd';

interface IProps {
  condoGuests: ICondoGuests;
  updateAdultsCount: (count: number) => void;
  updateKidsCount: (count: number) => void;
  updateBedroomsCount: (count: number) => void;
  updateIncludeStudio: (include: boolean) => void;
}

const ONE = 1;

export class Guests extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { condoGuests, updateAdultsCount, updateKidsCount, updateBedroomsCount, updateIncludeStudio } = this.props;
    const { kidsCount, adultsCount, bedroomsCount, includeStudio } = condoGuests;

    return (
      <div className="guests">
        <div className="guests__wrapper">
          <div className="guests__field">
            <div className="guests__user-icon">
              <AdultSvg />
            </div>
            <div className="guests__description">
              <div className="guests__user-label">
                <FormattedMessage values={{ count: 2 }} id="adult" />
              </div>
              <div className="guests__user-description">
                <FormattedMessage id="over.years" values={{ years: '12' }} />
              </div>
            </div>
            <div className="guests__counts">
              <Counts
                count={adultsCount}
                add={() => updateAdultsCount(adultsCount + ONE)}
                minus={() => updateAdultsCount(adultsCount - ONE)}
                min={1}
                max={12}
              />
            </div>
          </div>
        </div>
        <div className="guests__wrapper">
          <div className="guests__field">
            <div className="guests__user-icon">
              <ChildSvg />
            </div>
            <div className="guests__description">
              <div className="guests__user-label">
                <FormattedMessage values={{ count: 2 }} id="child" />
              </div>
              <div className="guests__user-description">
                <FormattedMessage id="less.years" values={{ years: '12' }} />
              </div>
            </div>
            <div className="guests__counts">
              <Counts
                count={kidsCount}
                add={() => updateKidsCount(kidsCount + ONE)}
                minus={() => updateKidsCount(kidsCount - ONE)}
                disabled={!adultsCount}
                min={0}
                max={6}
              />
            </div>
          </div>
        </div>
        <div className="guests__wrapper">
          <div className="guests__field">
            <div className="guests__user-icon">
              <BedRoomSvg />
            </div>
            <div className="guests__description">
              <div className="guests__user-label">
                <FormattedMessage values={{ count: 2 }} id="bedroom" />
              </div>
              <div className="guests__user-description">
                <FormattedMessage id="less.bedrooms" />
              </div>
            </div>
            <div className="guests__counts">
              <Counts
                count={bedroomsCount}
                add={() => updateBedroomsCount(bedroomsCount + ONE)}
                minus={() => updateBedroomsCount(bedroomsCount - ONE)}
                min={1}
                max={10}
                disabled={includeStudio}
              />
            </div>
          </div>
        </div>
        <div className="guests__wrapper">
          <div className="guests__field">
            <div className="guests__user-icon">
              <BedRoomSvg />
            </div>
            <div className="guests__description">
              <div className="guests__user-label">
                <FormattedMessage id="studio" />
              </div>
              <div className="guests__user-description">
                <FormattedMessage id="studio.less.bedrooms" />
              </div>
            </div>
            <div className="guests__counts">
              <Checkbox
                checked={includeStudio}
                onChange={() => updateIncludeStudio(!includeStudio)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
