import React from "react";
import img1 from "@assets/images/element/17.svg";

const DashboardHotelBookHero = () => {
  return (
    <section className="py-0">
      <div className="container">
        <div className="card bg-light overflow-hidden px-sm-5">
          <div className="row align-items-center g-4">
            <div className="col-sm-9">
              <div className="card-body">
                <h1 className="m-0 h2 card-title">Review your Booking</h1>
              </div>
            </div>

            <div className="col-sm-3 text-end d-none d-sm-block">
              <img src={img1} className="mb-n4" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardHotelBookHero;
