import React from 'react';
import { Magazine } from '@components';
import './style.scss';

interface IProps {}

export class AmericanLifestylePage extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return <Magazine />;
  }
}
