
import React from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { RSI_MASTER_CLASS_REGISTRATION_URL_FIELD_KEY } from '@share/common-types';
import { GetPreferenceValue } from '@utils';

import MasterClassesImg from '@assets/images/master-classes.jpg';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

class MasterClassesComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { loginStore, menuStore } = this.props;
    const { account } = loginStore;
    const { items } = menuStore;

    if (items?.isMLM) {
      return null;
    }

    const MasterClassesRegistrationPref = GetPreferenceValue(account, RSI_MASTER_CLASS_REGISTRATION_URL_FIELD_KEY);

    return (
      <div className="master-classes">
        <div className="master-classes__wrapper">
          <div className="master-classes__info-wrapper">
            <p className="master-classes__title">
              <FormattedMessage id="master.classes" />
            </p>
            <p className="master-classes__description">
              <FormattedMessage id="we.are.proud.to.offer.our.members" />
            </p>
            <p className="master-classes__description">
              <FormattedMessage id="be.included.in.our.future.classes" />
            </p>
            <div className="master-classes__btn-info">
              <p className="master-classes__btn-info-text">
                <FormattedMessage id="we.look.forward.to.seeing" />
              </p>
              {!isEmpty(MasterClassesRegistrationPref) ? (
                <a href={MasterClassesRegistrationPref} target="_blank" rel="noreferrer" className="master-classes__btn-link">
                  <BlueButton>
                    <FormattedMessage id="register" />
                  </BlueButton>
                </a>) : null}
              {isEmpty(MasterClassesRegistrationPref) ? (
                <Link to={`/${account?.name}${Routes.MasterClassesRegister}`} className="master-classes__btn-link">
                  <BlueButton>
                    <FormattedMessage id="register" />
                  </BlueButton>
                </Link>) : null}
            </div>
          </div>
          <div className="master-classes__img-wrapper">
            <img src={MasterClassesImg} alt="watch.videos" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const MasterClasses = connect(mapStateToProps)(MasterClassesComponent);
