import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Skeleton } from 'antd';
import './style.scss';

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER];

interface IProps {
  label: string;
  isTitle?: boolean;
}

export class SkeletonContent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { isTitle = true } = this.props;

    return (
      <div className="skeleton-content">
        {isTitle ? (
          <p className="skeleton-content__text">
            <FormattedMessage id="searching.skeleton" />
          </p>
        ) : null}
        {NUMBER_OF_BLOCKS.map((item, index) => (
          <div key={`${index}-${item}`} className="skeleton-content__card">
            <Skeleton
              className="skeleton-content__img-hotel"
              active
              title={false}
              paragraph={{ rows: 1 }}
            />
            <Skeleton
              className="skeleton-content__info-hotel"
              active
              title
              paragraph={{ rows: 3 }}
            />
            <div className="skeleton-content__price-hotel">
              <Skeleton active title={false} paragraph={{ rows: 2 }} />
              <Skeleton active title paragraph={false} />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
