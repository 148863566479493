import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Tabs } from 'antd';
import { TabsProps } from 'rc-tabs/lib/Tabs';
import format from 'date-fns/format';
import { IMagazineState, getMagazine } from '@store/slices';
import { RootState } from '@share/utils';
import { DATE_FORMAT_MONTH_YEAR } from '@constants';
import { ArrowLeftSvg, ArrowRightSvg } from '@share/assets';
import { removeVariables, getStringrWithoutTags } from '@share/utils';
import { BlueButton } from '@share/components';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import { getAccountDateFnsLocale } from '@i18n';

interface IMapStateToProps {
  magazineStore: IMagazineState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getMagazine: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

interface IState {
  tab: string;
}

const ZERO = 0;
const ONE = 1;
const TOP = 'top';

class MagazineComponent extends React.Component<IProps, IState> {
  state: IState = {
    tab: '0',
  };

  componentDidMount() {
    this.props.getMagazine();
  }

  next = () => {
    const { magazineStore } = this.props;
    this.setState({
      tab: Math.min(magazineStore.magazine.length - ONE, parseInt(this.state.tab) + ONE).toString(),
    });
  };

  prev = () => {
    this.setState({ tab: Math.max(ZERO, parseInt(this.state.tab) - ONE).toString() });
  };

  onChangeTab = (key: string) => {
    this.setState({ tab: key });
  };

  renderTabBar = (props: TabsProps, DefaultTabBar: React.ComponentType) => {
    const { magazineStore } = this.props;
    const { tab } = this.state;
    const isLastTab = tab === (magazineStore.magazine.length - ONE).toString();

    return (
      <div className={`magazine-wrapper__tabs-panel-wrapper ${isLastTab ? 'hide-shadow' : ''}`}>
        <div
          className={`magazine-wrapper__tab-prev ${tab === ZERO.toString() ? 'disabled' : ''}`}
          onClick={this.prev}
        >
          <ArrowLeftSvg />
        </div>
        <DefaultTabBar {...props} />
        <div
          className={`magazine-wrapper__tab-next ${isLastTab ? 'disabled' : ''}`}
          onClick={this.next}
        >
          <ArrowRightSvg />
        </div>
      </div>
    );
  };

  render() {
    const { magazineStore, loginStore } = this.props;
    const { magazine, loading } = magazineStore;
    const { account } = loginStore;
    const { TabPane } = Tabs;
    const locale = getAccountDateFnsLocale(account);

    return magazine && !loading ? (
      <div className="magazine-wrapper">
        <div className="magazine-wrapper__wrapper">
          <p className="magazine-wrapper__title">
            <FormattedMessage id="monthly.magazine" />
          </p>
          <div className="magazine-wrapper__tabs">
            <Tabs
              activeKey={this.state.tab}
              tabPosition={TOP}
              renderTabBar={this.renderTabBar}
              onChange={this.onChangeTab}
            >
              {magazine.map((item, index) => (
                <TabPane tab={format(new Date(item.date), DATE_FORMAT_MONTH_YEAR, { locale })} key={index}>
                  <div className="magazine-wrapper__content">
                    <div className="magazine-wrapper__content-wrapper">
                      <p className="magazine-wrapper__content-info-date">
                        {format(new Date(item.date), DATE_FORMAT_MONTH_YEAR, { locale })}
                      </p>
                      <p className="magazine-wrapper__content-info-title">{item.title.rendered}</p>
                      <div className="magazine-wrapper__content-info-link">
                        <a
                          href={getStringrWithoutTags(removeVariables(item.excerpt.rendered))}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BlueButton>
                            <FormattedMessage id="read.magazine" />
                          </BlueButton>
                        </a>
                      </div>
                    </div>
                    <div className="magazine-wrapper__content-img">
                      <img
                        src={getStringrWithoutTags(removeVariables(item.content.rendered))}
                        alt={item.title.rendered}
                      />
                    </div>
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    magazineStore: state.magazineStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getMagazine };

export const Magazine = connect(mapStateToProps, mapDispatchToProps)(MagazineComponent);
