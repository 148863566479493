import React from 'react';

export const TrustYouSVG: React.FunctionComponent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 0V20L6.75782 15.8551H17.8438V0H2ZM4.2634 2.61678H9.34225V3.99831H7.60699V8.78039H5.99902V3.99409H4.2634V2.61678ZM7.84715 12.4798H6.09712L5.34746 13.1331V9.98013H7.84715V12.4798ZM10.9703 12.4798H9.22059L8.46987 13.1331V9.98013H10.9696V12.4798H10.9703ZM14.0934 12.4798H12.3437L11.5937 13.1331V9.98013H14.0934V12.4798ZM13.445 6.48394V8.78039H11.837V6.48394L9.59226 2.61221H11.4172L12.6433 5.11612L13.8694 2.61221H15.6898L13.445 6.48394Z"
      fill="#292929"
    />
  </svg>
);
