import React from 'react';
import times from 'lodash/times';
import { StarSvg } from '@assets';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  stars: number;
}

export class HotelStars extends React.Component<IProps, null> {
  getStars = (): React.ReactNode[] => {
    const starRating: React.ReactNode[] = [];

    const stars = Math.abs(this.props.stars);
    const decimals = stars - Math.floor(stars);
    const startFull = Math.floor(stars)
    const startHalf = decimals > 0 ? 1 : 0;

    times(startFull, (i: number) => {
      starRating.push(
        <span key={i} className="hotel-info__star">
          <FontAwesomeIcon icon={faStar} />
        </span>,
      );
    });
    
    times(startHalf, (i: number) => {
      starRating.push(
        <span key={i + startFull + 1} className="hotel-info__star">
          <FontAwesomeIcon icon={faStarHalf} />
        </span>,
      );
    });

    return starRating;
  };

  render(): React.ReactNode {
    const stars = this.getStars();

    return (
      <span className="hotel-info__stars-wrapper">
        {stars.map((component: React.ReactNode) => {
          return component;
        })}
      </span>
    );
  }
}
