import React, { useState } from 'react';
import ImageGallery from '../../dashboard/image-gallery/component';
import Map from '../../common/map-view/component';

const DashboardHero = ({ hotelName, hotelAddress, city, state, zipCode, images, hotelData }) => {
  const zoomLevel = 12;
  const [modalShow, setModalShow] = useState(false); // Initially do not show the modal

  const location = {
    lat: hotelData?.hotelDetails?.location?.latitude,
    lng: hotelData?.hotelDetails?.location?.longitude,
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <section className="py-0 pt-0">
        <div className="container position-relative">
          <div className="row mb-3">
            <div className="col-12 row-gutter">
              <div className="d-lg-flex justify-content-lg-between mb-1">
                <div className="mb-2 mb-lg-0">
                  <h1 className="fs-2">{hotelName}</h1>
                  <p className="fw-bold mb-0">
                    {hotelAddress ? (
                      <>
                        <i className="bi bi-geo-alt me-2"></i>
                        {hotelAddress}, {city}, {state} {zipCode}
                        <span
                          className="ms-2 text-decoration-underline view-map"
                          onClick={toggleModal} // Use onClick to handle modal toggle
                        >
                          <i className="bi bi-map me-2"></i>View On Map
                        </span>
                      </>
                    ) : null}
                  </p>
                </div>
                <ul className="list-inline text-end">
                  <li className="list-inline-item">
                    <a href="#" className="btn btn-sm btn-light px-2">
                      <i className="bi bi-bookmark-fill"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {images && <ImageGallery images={images} />}

      {hotelData?.hotelDetails?.descriptions && (
        <section className="mt-0 pt-0">
          <div className="container">
            <div className="row">
              {hotelData.hotelDetails.descriptions.map((item, index) => (
                <span key={index} className="my-2 mx-2">
                  <i className="bi bi-patch-check-fill text-success me-2"></i>
                  {item}
                </span>
              ))}
            </div>
          </div>
        </section>
      )}

      {modalShow && (
        <>
          <div
            className="modal fade show modal-container"
            id="mapmodal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="mapModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="mapModalLabel">
                    Map View
                  </h5>
                  <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Map center={location} zoom={zoomLevel} />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" onClick={toggleModal} />
        </>
      )}
    </>
  );
};

export { DashboardHero };
