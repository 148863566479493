import React, { useRef, useState, useCallback } from 'react';
import { Modal, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InsuranceConfirmed, MapWrapper, ReservationRefundability, WhiteButton } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarMinus, faPaperPlane, faPlaneLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { IReservationsState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { Map } from '@utils';
import { IReservation, IReservationGuest, IStatic, ReservationStatusEnum } from '@share/common-types';
import parse from 'html-react-parser';
import MapMarker from '@assets/images/map-bed-marker.svg';
import { CloseBlueSvg } from '@assets';

import './style.scss';
import { get } from 'lodash';

interface IMapStateToProps {
  reservationsStore: IReservationsState;
}

interface IProps extends IMapStateToProps {
  visible: boolean;
  reservation: IReservation;
  staticData: IStatic;
  bookingStatusEnum: ReservationStatusEnum;
  showCancel: boolean;
  onCancel: () => void;
  onVoucher: () => void;
  onProtect: () => void;
  onClose: () => void;
}

const modalWidth = 600;

function DetailModalComponent(props: IProps) {
  const { visible, reservation, staticData, bookingStatusEnum, showCancel, onVoucher, onCancel, onProtect, onClose } = props;

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [mapVisibleKey, setMapVisibleKey] = useState(new Date().getTime());

  var smallMap = useRef();
  var smallMapInstance: google.maps.Map;

  const customRef = useCallback((ref) => {
    if (reservation && ref) {
      smallMap.current = ref;
      const { location, bookingId } = reservation;
      smallMapInstance = Map.initializeMap(
        smallMap.current,
        Map.getGoogleLocation(location),
        { disableDefaultUI: true },
      );
      Map.addMarkers(smallMapInstance, [{ location, id: Number(bookingId) }], MapMarker);
    }
  }, [visible]);

  const showMap = () => {
    setIsMapModalOpen(true);
    setMapVisibleKey(new Date().getTime());
    document.body.style.overflow = 'hidden';
  };

  const closeMap = () => {
    setIsMapModalOpen(false);
    document.body.style.overflow = 'initial';
  };

  const mainGuest: IReservationGuest = get(reservation?.rooms?.map(r => r.guests).flat().filter(g => g?.isMainGuest === true), '[0]');

  return (
    <div className="detail-modal">
      {
        reservation ?
          <Modal
            className="detail-modal-wrapper"
            wrapClassName="detail-modal-wrapper__wrap"
            visible={visible}
            footer={null}
            onCancel={onClose}
            closable={true}
            destroyOnClose={true}
            closeIcon={<FontAwesomeIcon icon={faTimes} />}
            width={modalWidth}
            title={<FormattedMessage id="itinerary" />}
          >
            <div className="detail-modal-wrapper__main">
              <div className="detail-modal-wrapper__area">

                <header>
                  <section className="header-section">
                    <figure className="section-figure">
                      <div className="property-image">
                        {staticData?.imageUrl
                          ? <img src={staticData?.imageUrl} alt={reservation.propertyName} height="100%" width="100%" style={{ objectFit: "cover" }} />
                          : <div className="custom-carousel-image__spinner"><Spin /></div>
                        }
                      </div>
                    </figure>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <h4 className="property-name">{reservation.propertyName}</h4>
                      <p className="property-address">{reservation.titleAddress} {reservation.subtitleAddress}</p>
                      <div style={{ display: 'flex', paddingTop: '12px' }}><ReservationRefundability refundability={reservation.refundable?.toString()} /></div>
                    </div>
                  </section>
                </header>

                <div className="container-section">

                  {bookingStatusEnum !== ReservationStatusEnum.Confirmed ? null :
                    <div className="action-container">
                      <div className="ant-list buderf ant-list-split ant-list-bordered">
                        <div className="ant-spin-nested-loading">
                          <div className="ant-spin-container">
                            {showCancel
                              ? <li className="ant-list-item" onClick={onCancel}>
                                <div><FontAwesomeIcon icon={faCalendarMinus} />Cancel reservation</div>
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"></path></svg>
                              </li>
                              : null}
                            <li className="ant-list-item" onClick={onVoucher}>
                              <div><FontAwesomeIcon icon={faPaperPlane} />Resend voucher</div>
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"></path></svg>
                            </li>
                            {((!reservation?.insurance?.policyDetail || reservation?.insurance?.policyDetail?.policyStatus === 'Error') && mainGuest?.countryCode === 'US') ? (
                              <li className="ant-list-item" onClick={onProtect}>
                                <div><FontAwesomeIcon icon={faPlaneLock} />Protect reservation</div>
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"></path></svg>
                              </li>) : null}
                          </div>
                        </div>
                      </div>
                    </div>}

                  <div className="policy-container">
                    <h3>Refund Policy</h3>
                    <div className="policy-text">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" className="svg-inline--fa fa-circle-info " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="#000000"><path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path></svg>
                      <p>{`${reservation.cancellationPolicesText}`}</p>
                    </div>
                  </div>

                  <div className="trip-container">
                    <header className="trip-header"><span>Trip Details</span></header>
                    <div className="data-items">
                      <li className="data-item"><span>Hotel Confirmation #:</span><span>{reservation.supplierConfirmation}</span></li>
                      <li className="data-item"><span>Check-In:</span><span>{reservation.checkIn}</span></li>
                      <li className="data-item"><span>Checkout:</span><span>{reservation.checkOut}</span></li>
                      <li className="data-item"><span>Created at:</span><span>{reservation.createdAt}</span></li>
                      <li className="data-item"><span>Nights:</span><span>{reservation.numberOfNights}</span></li>
                      <li className="data-item"><span>Guests:</span><span>{reservation.numberGuest}</span></li>
                      <li className="data-item"><span>Rooms:</span><span>{reservation.rooms[0].count}</span></li>
                      <li className="data-item"><span>Room Name:</span><span>{reservation.rooms[0].basis}</span></li>
                      <li className="data-item"><span>Room Details:</span><span>{reservation.rooms[0].name}</span></li>
                    </div>
                  </div>

                  <div className="trip-container">
                    <header className="trip-header"><span>Passenger Details</span></header>
                    <div className="data-items">
                      <li className="data-item"><span>Lead Name:</span><span>{reservation.leadName}</span></li>
                      <li className="data-item"><span>Phone:</span><span>{reservation.phone}</span></li>
                      <li className="data-item"><span>Email:</span><span>{reservation.email}</span></li>
                    </div>
                  </div>

                  <div className="trip-container">
                    <header className="trip-header"><span>Summary of Charges</span></header>
                    <div className="data-items">
                      <li className="data-item"><span>Average nightly rate:</span><span>{reservation.priceDetail.averageNightPrice}</span></li>
                      <li className="data-item"><span>{reservation.priceDetail.priceTitle}:</span><span>{reservation.priceDetail.price}</span></li>
                      {reservation.priceDetail.memberSavings ?
                        <li className="data-item" style={{ color: '#4e877f', fontWeight: 600 }}><span>Member savings:</span><span>{reservation.priceDetail.memberSavings}</span></li> : null}
                    </div>
                    <div className="total-paid">
                      <span>Total paid:</span><span>{reservation.priceDetail.totalPaid}</span>
                    </div>

                    {reservation.priceDetail.payAtPropertyFees.length ?
                      <div className="trip-container" style={{ marginTop: '8px', borderTop: '1px solid rgb(231, 232, 236)' }}>
                        <header className="trip-header"><span>Pay at property:</span></header>
                        <div className="data-items">
                          {reservation.priceDetail.payAtPropertyFees.map((item, i) => { return <li key={i} className="data-item"><span>{item.title}:</span><span>{item.formattedValue}</span></li> })}
                        </div>
                      </div> : null}

                    {staticData?.mandatory ?
                      <div className="trip-container">
                        <header className="trip-header"><span>Mandatory fees message:</span></header>
                        <div className="data-items">
                          <li className="data-item"><span style={{ width: '100%', whiteSpace: 'break-spaces' }}>{parse(staticData.mandatory)}</span></li>
                        </div>
                      </div> : null}

                    {staticData?.optionalFeesMessage ?
                      <div className="trip-container">
                        <header className="trip-header"><span>Optional fees message:</span></header>
                        <div className="data-items data-item-fee">
                          <span style={{ width: '100%', whiteSpace: 'break-spaces' }}>{parse(staticData.optionalFeesMessage)}</span>
                        </div>
                      </div> : null}
                  </div>

                  <div className='container-map'>
                    <div className='map-wrapper google-map-wrapper'>
                      <div className='map-ref'><div className={`location__map`} ref={customRef} /></div>
                    </div>
                    {(reservation?.location?.latitude || reservation?.location?.longitude) ? (
                      <div role="button" className="map-action" onClick={showMap}>
                        <div className="map-titles">
                          <div className="map-title">{reservation.titleAddress}</div>
                          <div className="map-title">{reservation.subtitleAddress}</div>
                        </div>
                        <div className="map-image">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="diamond-turn-right" className="svg-inline--fa fa-diamond-turn-right fa-2x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z"></path></svg>
                        </div>
                      </div>) : null}
                  </div>

                </div>
              </div>
            </div>

            <InsuranceConfirmed insurance={reservation?.insurance} />

            <div className="detail-modal-wrapper__footer">
              <WhiteButton onClick={onClose}>
                <FormattedMessage id="close.itinerary" />
              </WhiteButton>
            </div>
          </Modal> : null}

      {reservation ?
        <div className="hotel-details-wrapper__big-map-wrapper" style={{ display: isMapModalOpen ? 'block' : 'none', zIndex: isMapModalOpen ? 99999 : 0 }}>
          <div className="hotel-details-wrapper__big-map-top-section">
            <span className="hotel-details-wrapper__big-map-close" onClick={closeMap}><CloseBlueSvg /></span>{reservation.propertyName}
          </div>
          <MapWrapper
            key={`${reservation.bookingId}_${mapVisibleKey}`}
            locations={[{ location: reservation.location, id: Number(reservation.bookingId) }]}
            center={Map.getGoogleLocation(reservation.location)}
            icon={MapMarker}
            mapOptions={{ zoom: 15, disableDefaultUI: true }}
          />
        </div> : null}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reservationsStore: state.reservationsStore
  };
};

export const DetailModal = connect(mapStateToProps)(DetailModalComponent);