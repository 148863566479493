import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { sanitize } from 'dompurify';
import { get } from 'lodash';

import { RootState } from '@share/utils';
import { IReservationCancellationState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  reservationCancellationStore: IReservationCancellationState;
}

interface IProps extends IMapStateToProps {}

class ReservationCancellationPoliciesComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { reservationCancellationDetails } = this.props.reservationCancellationStore;
    const { reservationDetails } = reservationCancellationDetails;

    const bookingRemarks = get(reservationDetails, 'bookingRemarks');
    const cancellationPoliciesText = get(reservationDetails, 'cancellationPoliciesText');
    const refundabilityText = get(reservationDetails, 'refundabilityText');

    return (
      <div className="reservation-cancellation-policies">
        <div className="reservation-cancellation-policies__header">
          <h4 className="reservation-cancellation-policies__title">
            <FormattedMessage id="cancellation.policy" />:
          </h4>
        </div>
        <div className="reservation-cancellation-policies__info">
          <p className="reservation-cancellation-policies__info-description">
            {cancellationPoliciesText}
          </p>
        </div>
        {refundabilityText && (
          <div className="reservation-cancellation-policies__info">
            <p className="reservation-cancellation-policies__info-title">
              <FormattedMessage id="refundability" />:
            </p>
            <p className="reservation-cancellation-policies__info-description">
              {refundabilityText}
            </p>
          </div>
        )}
        {bookingRemarks && (
          <div className="reservation-cancellation-policies__info">
            <div className="reservation-cancellation-policies__header">
              <h4 className="reservation-cancellation-policies__title">
                <FormattedMessage id="booking.remarks" />:
              </h4>
            </div>
            <p
              className="reservation-cancellation-policies__info-description"
              dangerouslySetInnerHTML={{ __html: sanitize(bookingRemarks) }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reservationCancellationStore: state.reservationCancellationStore,
  };
};

export const ReservationCancellationPolicies = connect(
  mapStateToProps,
  null,
)(ReservationCancellationPoliciesComponent);
