import React from 'react';
import './style.scss';

interface IProps {}

export class SkeletonMap extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-map">
        <div className="skeleton-map__wrapper">
          <div className="skeleton-map__loader" />
        </div>
      </div>
    );
  }
}
