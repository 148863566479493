import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { INavigationItem } from '@share/common-types';

import PlaceHolder from '@assets/images/inclusives/all-inclusives-submit-request-card-img.jpg';

import './style.scss';

interface IProps {
  items: INavigationItem[];
  isMLM: boolean;
}

const ONE = 1;

export class DailyBenefitsItems extends Component<IProps> {
  render(): React.ReactNode {
    const { items, isMLM } = this.props;

    return (
      <div className="items-wrapper">
        <div className="items-wrapper__wrapper">
          <h2 className="items-wrapper__heading">
            <FormattedMessage id="daily.benefits.items.heading" />
          </h2>
          <div className="items-wrapper__items">
            {items
              ? items.filter(({ title }) => !isMLM || !title.includes('Active')).map(({ imageUrl, title, description, url }) => (
                  <a
                    key={title}
                    className={`items-wrapper__item hover ${items.length === ONE ? 'span' : ''}`}
                    href={url}
                    target="__blank"
                    rel="noreferrer"
                  >
                    <img
                      className={`items-wrapper__item-image ${items.length === ONE ? 'span' : ''}`}
                      src={!imageUrl ? PlaceHolder : imageUrl}
                    />
                    <div className="items-wrapper__item-text-wrapper">
                      <h2 className="items-wrapper__item-heading"><FormattedMessage id={title} /></h2>
                      <p className="items-wrapper__item-paragraph"><FormattedMessage id={description} /></p>
                    </div>
                  </a>
                ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}
