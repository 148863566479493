import React from 'react';

import { Homes } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

class HomesPageComponent extends React.Component<IProps, null> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="homes-page">
        <Homes />
      </div>
    );
  }
}

export const HomesPage = HomesPageComponent;
