import React from "react";
interface AmenityCategory {
  title: string;
  icon: string;
  amenities: AmenityItem[];
}

interface AmenityItem {
  name: string;
  icon: string;
}

interface AmenitiesProps {
  categories: AmenityCategory[];
}

const Amenities: React.FC<AmenitiesProps> = ({ categories }) => {
  return (
    <div>
      <div className="card bg-transparent">
        <div className="card-header border-bottom bg-transparent px-0 pt-0">
          <h3 className="fw-light mb-0">{categories && "Amenities"}</h3>
        </div>
        <div className="card-body pt-4 p-0">
          <h6>
            <i className="bi bi-building me-2"></i> Hotel amenities
          </h6>
          <div className="row g-1">
            {categories?.map((category:any, index) => (
              <div className="col-sm-6" key={index}>
                <ul className="list-group list-group-borderless mb-0">
                  <li className="list-group-item pb-0">
                    <i className="bi bi-patch-check-fill text-success me-2"></i>
                    {category.name}
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Amenities;
