import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { UNAUTHORIZED_STATUS } from '@share/constants';
import { loginActions } from '@share/store/slices';
import { useDispatch } from 'react-redux';

function useApi(baseUrl = process.env.REACT_APP_API_B2B_URL, contentType = 'application/json') {
  axios.defaults.baseURL = baseUrl || process.env.REACT_APP_API_B2B_URL;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const showErrorModal = (message) => {
    toast.error(message, {
      theme: 'colored',
    });
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        config.headers['Content-Type'] = contentType;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [contentType]);
  const fetchData = async (
    endpoint: string,
    payload: any,
    method: 'get' | 'post' | 'put' | 'delete' = 'post',
  ) => {
    if (new Date().getTime() > parseInt(Cookies.get('tokenExpiry'))) {
      const message = 'Token expired';
      setError(message);
      showErrorModal(message);
      return null;
    }

    setLoading(true);
    const url = endpoint;

    try {
      setLoading(true);
      const response = await axios({
        url,
        method,
        data: payload,
      });
      setData(response.data);
      return response.data;
    } catch (e) {
      let error = 'An unknown error occurred';
      // handleRequestError(e);
      if (e?.response?.data) {
        try {
          const responseData =
            typeof e.response.data === 'string' ? JSON.parse(e.response.data) : e.response.data;
          error =
            responseData?.message ||
            responseData?.reason ||
            (responseData.errors && responseData.errors[0]?.message) ||
            'An error occurred';
        } catch (parseError) {
          error = 'Failed to parse error response';
        }
      }
      showErrorModal(error);
      setError(error);
      return e.response;
    } finally {
      setLoading(false);
    }
  };

  return { fetchData, loading, error, data };
}

const handleRequestError = (error: any) => {
  const dispatch = useDispatch();
  if (error?.response?.status === UNAUTHORIZED_STATUS) {
    dispatch(loginActions.setIsTokenExpired(true));
    error.code = 'SESSIONEXPIRED';
  }

  return Promise.reject(error);
};

export default useApi;
