import React from "react";
import img1 from "@assets/images/home/getty-images-5dpagkuxf64-unsplash.jpg";
import Feature from "./feature";
import { companyFeatures } from "../../../../_mock-api's/index";

const CompanyTravels = () => {
  return (
    <section className="pb-0 pb-xl-5 mb-5">
      <div className="container">
        <div className="row g-2 justify-content-between align-items-top">
          <div className="col-lg-5 position-relative">
            <img
              src={img1}
              className="rounded-3 position-relative"
              alt="Company Travels"
            />
          </div>

          <div className="col-lg-6">
            <h2 className="mb-3 mb-lg-5">
              Manage and control how and where your company travels
            </h2>

            <div className="row g-4">
              {companyFeatures.map((feature, index) => (
                <Feature
                  key={index}
                  iconClass={feature.iconClass}
                  bgClass={feature.bgClass}
                  title={feature.title}
                >
                  {feature.description}
                </Feature>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { CompanyTravels };
