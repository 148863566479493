import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home } from '../../pages/new-home';
import Login from '../../pages/log-in/login';
import Register from '../../pages/register/register-company';
import AccountSettings from '../../pages/account-settings/account-settings';
import { PaymentDetails } from '../payment-details';
import Bookings from '../../pages/bookings/component';
import AccountCredit from '../../pages/account-credit/account-credit';
import AccountSaved from '../../pages/account-saved/account-saved';
import { Dashboard } from '../dashboard';
import CompanySettings from '../../pages/company-settings/component';
import CompanyTeam from '../../pages/company-team/component';
import TwoFactorAuthentication from '../../pages/two-factor-authentication/component';
import { PrivateRoute } from '../../components/common/private-route';
import { CustomErrorPage } from '@share/components';
import { Base64 } from 'js-base64';

const NewApp = () => {
  const loggedUser = localStorage.getItem('LOGGED_USER_LABEL');
  const userDataStorage = loggedUser ? JSON.parse(Base64.decode(loggedUser)) : null;
  const userStorage = userDataStorage?.user;

  const publicRoutes = [
    { path: '/:accountUsername/log-in', component: Login },
    { path: '/:accountUsername/two-factor-authentication', component: TwoFactorAuthentication },
    { path: '/:accountUsername/register', component: Register },
    { path: '/:accountUsername/', component: Home },
  ];

  const privateRoutes = [
    { path: '/:accountUsername/dashboard', component: Dashboard },
    { path: '/:accountUsername/account-settings', component: AccountSettings },
    { path: '/:accountUsername/account-payment', component: PaymentDetails },
    { path: '/:accountUsername/bookings', component: Bookings },
    { path: '/:accountUsername/account-credit', component: AccountCredit },
    { path: '/:accountUsername/account-saved', component: AccountSaved },
    // { path: '/:accountUsername/hotel-book/:hotelId/:uuid', component: DashboardHotelBook },
    { path: '/:accountUsername/company-settings', component: CompanySettings },
    { path: '/:accountUsername/company-team', component: CompanyTeam },
  ];

  return (
      <Switch>
        <Route path="/:accountUsername/log-in" exact>
          <Login />
        </Route>
        <Route path="/:accountUsername/two-factor-authentication" exact>
          <TwoFactorAuthentication />
        </Route>
        <Route path="/:accountUsername/register" exact>
          <Register />
        </Route>

        {publicRoutes.map(({ path, component }) => (
          <Route key={path} path={path} exact component={component} />
        ))}
        {privateRoutes.map(({ path, component }) => (
          <PrivateRoute key={path} path={path} exact component={component} userData={userStorage} />
        ))}

        <Route path="*">
          <CustomErrorPage />
        </Route>
      </Switch>
  );
};

export { NewApp };
