import React, { ReactNode } from 'react';

interface FeatureProps {
  iconClass: string;
  bgClass: string;
  title: string;
  children: ReactNode;
}

const Feature: React.FC<FeatureProps> = (props) => {
  const { iconClass, bgClass, title, children } = props;

  return (
    <div className="col-sm-6">
      <div className={`icon-lg ${bgClass} bg-opacity-10 text-success rounded-circle`}>
        <i className={iconClass}></i>
      </div>
      <h5 className="mt-2">{title}</h5>
      <p className="mb-0">{children}</p>
    </div>
  );
};

export default Feature;
