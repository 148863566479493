import React from 'react';

export const CheckCircleSvg: React.FunctionComponent = () => (
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.5 5C21.1719 5 5.5 20.6719 5.5 40C5.5 59.3281 21.1719 75 40.5 75C59.8281 75 75.5 59.3281 75.5 40C75.5 20.6719 59.8281 5 40.5 5ZM55.6172 28.5703L39.1641 51.3828C38.9341 51.7038 38.631 51.9653 38.2797 52.1457C37.9285 52.3261 37.5394 52.4203 37.1445 52.4203C36.7497 52.4203 36.3605 52.3261 36.0093 52.1457C35.6581 51.9653 35.355 51.7038 35.125 51.3828L25.3828 37.8828C25.0859 37.4688 25.3828 36.8906 25.8906 36.8906H29.5547C30.3516 36.8906 31.1094 37.2734 31.5781 37.9297L37.1406 45.6484L49.4219 28.6172C49.8906 27.9687 50.6406 27.5781 51.4453 27.5781H55.1094C55.6172 27.5781 55.9141 28.1562 55.6172 28.5703Z"
      fill="#52C41A"
    />
  </svg>
);
