import { ILoginState } from '@share/store/slices';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

interface IMapStateToProps {
  loginStore: ILoginState;
}

const PrivateRoute = ({ component: Component, userData, ...rest }) => {
  const login = useSelector((state: IMapStateToProps) => state.loginStore);
  return (
    <Route
      {...rest}
      render={(props) =>
        userData?.accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/${login.account.name}/log-in`} />
        )
      }
    />
  );
};

export { PrivateRoute };
