
import { ICondoLocation, IGeoLocation } from "@share/common-types";

export enum LocationsTypes {
  Unspecified = 'unspecified',
  Destination = 'destination',
  Hotel = 'hotel',
  Airport = 'airport',
}

export enum PointsLocationsEnum {
  Condo = 'condo',
  CondoBooking = 'unit-book',
  Search = 'search',
  Hotel = 'hotel',
  HotelBooking = 'book',
}

export enum CondoLocationsEnum {
  Unspecified = 'Unspecified',
  GenericRegion = 'GenericRegion',
  GenericCondo = 'GenericCondo',
}

export interface ILocation {
  id: string;
  code: string;
  name: string;
  geoLocation: IGeoLocation;
  locationType: LocationsTypes;
  city?: string;
  country?: string;
}


export interface ICondoPopularDestinations {
  popularDestinations: IPopularDestinations[];
}

export interface IPopularDestinations {
  count: number;
  imageUrl: string;
  name: string;
  location: ICondoLocation;
}

export interface IHotelPopularDestinations {
  popularDestinations: IDestinations[];
}

export interface IDestinations {
  count: number;
  imageUrl: string;
  name: string;
  location: ILocation;
}
