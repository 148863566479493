import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import trimEnd from 'lodash/trimEnd';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import { Table, Space, Tabs } from 'antd';
import {
  NEGATIVE,
  VALUE_ALL,
  ProductsEnum,
  DATE_FORMAT_FOR_ADMIN_TABLE,
} from '@constants';
import { Routes, PERCENT_TYPE } from '@share/constants';
import { ConfirmModal, WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { InfoIconSvg, RefreshSvg } from '@assets';
import { PlusSvg } from '@share/assets';
import { IAdminPriceRules, INewPriceRule } from '@common-types';
import { RootState } from '@share/utils';
import {
  getPriceRules,
  IAdminChangePriceRuleState,
  IAdminPriceRulesState,
  setProduct,
  changeSelectedRule,
  adminChangePriceRuleActions,
  getAdminSuppliers,
  setRuleExists,
} from '@store/slices';
import { ConfigPriceRule } from '../config-price-rule';
import './style.scss';

interface IMapStateToProps {
  adminPriceRules: IAdminPriceRulesState;
  adminPriceRule: IAdminChangePriceRuleState;
}

interface IMapDispatchToProps {
  getPriceRules: () => void;
  getAdminSuppliers: () => void;
  setProduct: (value: string) => void;
  setChangePriceRules: (data: INewPriceRule) => void;
  setIsChangePriceRules: (data: boolean) => void;
  changeSelectedRule: (data: INewPriceRule) => void;
  setRuleExists: (data: boolean) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {}

interface IState {
  isModalConfigPriceRule: boolean;
  priseRuleId: number;
  activeKey: string;
  isHideActivateButton: boolean;
  isDisabled: boolean;
  isConfirmModal: boolean;
  currentRule: IAdminPriceRules;
}

const ZERO = 0;
const ONE = 1;
const TWO = 2;
const FIVE_DAYS = 5;
const DAY_IN_HOURS = 24;
const LAST_LETTER = 's';
const ASCEND = 'ascend';

class PriceRulesComponent extends React.Component<IProps, IState> {
  state: IState = {
    isModalConfigPriceRule: false,
    priseRuleId: null,
    activeKey: '',
    isHideActivateButton: false,
    isDisabled: false,
    isConfirmModal: false,
    currentRule: null,
  };

  sorterColumn = (valueAscend: string, valueDescend: string) => {
    return valueAscend.localeCompare(valueDescend);
  };

  onChangeProduct = (key: string) => {
    this.props.setProduct(trimEnd(key, LAST_LETTER));
    this.setState({ activeKey: key });
  };

  showConfigPriceRule = (priceRule: IAdminPriceRules) => {
    this.props.setChangePriceRules({
      id: priceRule.id,
      productType: priceRule.productType,
      supplier: priceRule.supplier,
      siteId: priceRule?.siteId,
      marginType: priceRule.marginType,
      margin: priceRule.margin,
      isActive: priceRule.isActive,
      name: priceRule.name,
    });

    this.setState({
      isModalConfigPriceRule: true,
      priseRuleId: priceRule.id,
      isHideActivateButton: false,
      isDisabled: false,
    });

    if (priceRule.supplier === '' && priceRule.siteId === NEGATIVE) {
      this.setState({ isHideActivateButton: true, isDisabled: true });
    }
  };

  handleCancelConfirm = () => {
    this.setState({ isConfirmModal: false });
  };

  onOpenConfirmModal = (priceRule: IAdminPriceRules) => {
    this.setState({ isConfirmModal: true, currentRule: priceRule });
  };

  onToggleActivatePriceRule = () => {
    const { currentRule } = this.state;

    this.props.setChangePriceRules({
      id: currentRule.id,
      productType: currentRule.productType,
      supplier: currentRule.supplier,
      siteId: currentRule?.siteId,
      marginType: currentRule.marginType,
      margin: currentRule.margin,
      isActive: !currentRule.isActive,
      name: currentRule.name,
    });

    this.props.changeSelectedRule({
      id: currentRule.id,
      productType: currentRule.productType,
      supplier: currentRule.supplier,
      siteId: currentRule?.siteId,
      marginType: currentRule.marginType,
      margin: currentRule.margin,
      isActive: !currentRule.isActive,
      name: currentRule.name,
    });

    this.setState({ isConfirmModal: false });
  };

  onCancelModalConfigPriceRule = () => {
    this.setState({ isModalConfigPriceRule: false });
  };

  componentDidMount() {
    const { intl, adminPriceRules } = this.props;

    this.props.getPriceRules();
    this.props.getAdminSuppliers();
    this.props.setProduct(ProductsEnum.Condo);
    this.setState({
      activeKey:
        adminPriceRules.product === ProductsEnum.Condo
          ? intl.formatMessage({ id: 'condos.title' })
          : intl.formatMessage({ id: 'hotels.title' }),
    });

    if (
      !isEmpty(adminPriceRules.newPriceRule) &&
      adminPriceRules.newPriceRule?.siteId !== '' &&
      adminPriceRules.ruleExists
    ) {
      const newSiteId =
        adminPriceRules.newPriceRule.siteId === VALUE_ALL
          ? NEGATIVE
          : adminPriceRules.newPriceRule.siteId;
      const newSupplier =
        adminPriceRules.newPriceRule.supplier === VALUE_ALL
          ? ''
          : adminPriceRules.newPriceRule.supplier;
      const getDuplicateRule = adminPriceRules.priceRules.find(
        (item) =>
          (item.supplier === newSupplier && item.siteId === newSiteId) ||
          item.name === adminPriceRules.newPriceRule.name,
      );
      this.showConfigPriceRule(getDuplicateRule);
    }
  }

  componentDidUpdate() {
    if (this.props.adminPriceRule.isChangePriceRule) {
      this.props.setIsChangePriceRules(!this.props.adminPriceRule.isChangePriceRule);
      this.props.getPriceRules();
    }
  }

  componentWillUnmount() {
    this.props.setRuleExists(false);
  }

  getPriceRulesForType = (productType: string) => {
    const { adminPriceRules } = this.props;

    const priceRules = adminPriceRules.priceRules
      .filter((item) => item.productType === productType)
      .sort((a, b) => {
        return new Date(a.updateDate).getTime() - new Date(b.updateDate).getTime();
      })
      .reverse();

    const indexDefaultPriceRule = priceRules.findIndex((item) => {
      return item.supplier === '' && item.siteId === NEGATIVE;
    });
    const defaultPriceRule = priceRules.splice(indexDefaultPriceRule, ONE);

    return [...defaultPriceRule, ...priceRules];
  };

  getDefaultPriceRule = (record: INewPriceRule, index: number): string => {
    return index === ZERO && record.siteId === NEGATIVE && record.supplier === ''
      ? 'price-rules__main-price-rule'
      : '';
  };

  getLastUpdate = (record: IAdminPriceRules) => {
    const timeDistance = formatDistance(new Date(record.updateDate), new Date(), {
      addSuffix: true,
    });
    const differenceDays = differenceInDays(new Date(), new Date(record.updateDate));
    const differenceHours = differenceInHours(new Date(), new Date(record.updateDate));

    if (differenceDays === ONE) {
      return (
        <p className="price-rules__text-info">
          <FormattedMessage id="yesterday" />
        </p>
      );
    }
    if (differenceDays > ONE) {
      return (
        <p className="price-rules__text-info">
          {format(new Date(record.updateDate), DATE_FORMAT_FOR_ADMIN_TABLE)}
        </p>
      );
    }
    if (differenceHours > FIVE_DAYS && differenceHours < DAY_IN_HOURS) {
      return (
        <p className="price-rules__text-info">
          <FormattedMessage id="today" />
        </p>
      );
    } else {
      return <p className="price-rules__text-info">{timeDistance}</p>;
    }
  };

  refreshPage() {
    window.location.reload();
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({ id: 'rule.id.column' }),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules) => a.id - b.id,
        render: (id: React.ReactNode, record: IAdminPriceRules) => (
          <p className="price-rules__btn_action" onClick={() => this.showConfigPriceRule(record)}>
            {id}
          </p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'description' }),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules) => this.sorterColumn(a.name, b.name),
        render: (data: IAdminPriceRules, record: IAdminPriceRules) => {
          if (record.supplier === '' && record.siteId === NEGATIVE) {
            return (
              <p className="price-rules__text-info bold-text main-rule">
                <InfoIconSvg />
                <FormattedMessage id="default.rule" />
              </p>
            );
          } else {
            return <p className="price-rules__text-info">{record.name}</p>;
          }
        },
      },
      {
        title: intl.formatMessage({ id: 'supplier' }),
        dataIndex: 'supplier',
        key: 'supplier',
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules) =>
          this.sorterColumn(a.supplier, b.supplier),
        render: (supplier: string) => (
          <p className="price-rules__text-info">{supplier !== '' ? supplier : VALUE_ALL}</p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'site.id' }),
        dataIndex: 'siteId',
        key: 'siteId',
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules) => a.siteId - b.siteId,
        render: (siteId: number) => (
          <p className="price-rules__text-info">{siteId !== NEGATIVE ? siteId : VALUE_ALL}</p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'markup' }),
        dataIndex: 'margin',
        key: 'margin',
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules, sortOrder: string) =>
          a.marginType === b.marginType
            ? sortOrder === ASCEND
              ? a.margin - b.margin
              : b.margin - a.margin
            : a.marginType.localeCompare(b.marginType),
        render: (data: IAdminPriceRules, record: IAdminPriceRules) => (
          <p className="price-rules__text-info">
            {record.marginType === PERCENT_TYPE
              ? `${record.margin.toFixed(TWO)}%`
              : `$${record.margin}`}
          </p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'status' }),
        dataIndex: 'isActive',
        key: 'isActive',
        render: (isActive: string) => (
          <p className={`price-rules__status ${isActive ? 'active' : 'inactive'}`}>
            {isActive ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
          </p>
        ),
        // @ts-ignore
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules) => a.isActive - b.isActive,
      },
      {
        title: intl.formatMessage({ id: 'last.updated' }),
        dataIndex: 'updateDate',
        key: 'updateDate',
        sorter: (a: IAdminPriceRules, b: IAdminPriceRules) =>
          this.sorterColumn(a.updateDate, b.updateDate),
        render: (data: IAdminPriceRules, record: IAdminPriceRules) => this.getLastUpdate(record),
      },
      {
        title: intl.formatMessage({ id: 'action' }),
        dataIndex: 'siteId',
        key: 'action',
        render: (data: IAdminPriceRules, record: IAdminPriceRules, index: number) => {
          return (
            <Space size="middle">
              <p
                className="price-rules__btn_action"
                onClick={() => this.showConfigPriceRule(record)}
              >
                <FormattedMessage id="config" />
              </p>
              {!this.getDefaultPriceRule(record, index) && (
                <p
                  className="price-rules__btn_action"
                  onClick={() => this.onOpenConfirmModal(record)}
                >
                  {record.isActive ? (
                    <FormattedMessage id="deactivate" />
                  ) : (
                    <FormattedMessage id="activate" />
                  )}
                </p>
              )}
            </Space>
          );
        },
      },
    ];
  };

  render(): React.ReactNode {
    const {
      isModalConfigPriceRule,
      priseRuleId,
      activeKey,
      isHideActivateButton,
      isDisabled,
      isConfirmModal,
      currentRule,
    } = this.state;
    const { intl, adminPriceRules } = this.props;
    const { TabPane } = Tabs;

    return (
      adminPriceRules.priceRules && (
        <div className="price-rules">
          <div className="price-rules__btn-wrapper">
            <Link to={`${Routes.NewRule}`}>
              <BlueButton>
                <PlusSvg />
                <FormattedMessage id="add.new" />
              </BlueButton>
            </Link>
            <WhiteButton onClick={this.refreshPage}>
              <RefreshSvg />
            </WhiteButton>
          </div>
          <Tabs activeKey={activeKey} onChange={this.onChangeProduct}>
            <TabPane
              tab={intl.formatMessage({ id: 'condos.title' })}
              key={intl.formatMessage({ id: 'condos.title' })}
            >
              <Table
                rowClassName={this.getDefaultPriceRule}
                columns={this.getColumns()}
                dataSource={this.getPriceRulesForType(ProductsEnum.Condo)}
                scroll={{
                  y: 'calc(100vh - 420px)',
                }}
                rowKey={(row) => row.id}
              />
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'hotels.title' })}
              key={intl.formatMessage({ id: 'hotels.title' })}
            >
              <Table
                rowClassName={this.getDefaultPriceRule}
                columns={this.getColumns()}
                dataSource={this.getPriceRulesForType(ProductsEnum.Hotel)}
                scroll={{
                  y: 'calc(100vh - 420px)',
                }}
                rowKey={(row) => row.id}
              />
            </TabPane>
          </Tabs>
          <ConfigPriceRule
            isModalConfigPriceRule={isModalConfigPriceRule}
            onCancel={this.onCancelModalConfigPriceRule}
            product={adminPriceRules.product}
            priseRuleId={priseRuleId}
            isHideActivateButton={isHideActivateButton}
            isDisabled={isDisabled}
          />
          {currentRule ? (
            <ConfirmModal
              isActiveRule={!currentRule?.isActive}
              priseRuleId={currentRule?.id}
              isConfirmModal={isConfirmModal}
              handleCancelConfirm={this.handleCancelConfirm}
              onToggleActivatePriceRule={this.onToggleActivatePriceRule}
              mask={isConfirmModal}
              centerPosition={true}
            />
          ) : null}
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPriceRules: state.adminPriceRulesStore,
    adminPriceRule: state.adminChangePriceRuleStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getPriceRules: () => dispatch(getPriceRules()),
  setProduct: (value: string) => dispatch(setProduct(value)),
  changeSelectedRule: (data: INewPriceRule) => dispatch(changeSelectedRule(data)),
  setChangePriceRules: (data: INewPriceRule) => {
    dispatch(adminChangePriceRuleActions.setChangePriceRules(data));
  },
  setIsChangePriceRules: (data: boolean) => {
    dispatch(adminChangePriceRuleActions.setIsChangePriceRules(data));
  },
  getAdminSuppliers: () => dispatch(getAdminSuppliers()),
  setRuleExists: (data: boolean) => dispatch(setRuleExists(data)),
});

export const PriceRules = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(PriceRulesComponent)));
