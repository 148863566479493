import React from 'react';

import { RouteComponentProps, withRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Routes } from '@share/constants';
import { RootState } from '@share/utils';
import { ILoginState, LoginType, setLoginAccount, setLoginUser } from '@share/store/slices';
import { properties } from '../../admin-panel/accounts';
import { IUser, TRAVCODING_V2 } from '@share/common-types';
import { IAccount } from '@share/common-types';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setLoginAccount: (redirect: IAccount) => void;
  setLoginUser: (redirect: IUser) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  children: any;
  path: string;
  exact?: boolean;
  isSignIn?: boolean;
}

interface IState {
  category: string;
  action: string;
  label: string;
}

class PrivateHotelRouteComponent extends React.Component<IProps, IState> {
  state: IState = {
    category: '',
    action: '',
    label: '',
  };

  componentDidMount() {
    const { account } = this.props.loginStore;
    if (account) {
      const validProperties = [properties[0].value, properties[2].value];
      if (!validProperties.includes(account.property)) {
        this.props.setLoginAccount(null);
        this.props.setLoginUser(null);
        this.props.history.push({
          pathname: Routes.CustomError,
          state: {
            data: 'UNAUTHORIZED',
          },
        });
      }
    }
  }

  isAuthenticated = () => {
    if (this.props.isSignIn) {
      const { account } = this.props.loginStore;
      return !!account && account.type === LoginType.PrivateWithLogin;
    } else {
      const { account, user, loading } = this.props.loginStore;
      return (
        account?.type === LoginType.Public ||
        !!user ||
        (account.type === LoginType.PrivateWithToken && loading)
      );
    }
  };

  render(): React.ReactNode {
    const { account, loading } = this.props.loginStore;
    const isTravCoding_V2 = account?.homePageTemplate === TRAVCODING_V2;

    if (!account) {
      return null;
    }

    const pathname = this.props.isSignIn
      ? Routes.NotFound
      : isTravCoding_V2
      ? `/${account.name}/log-in`
      : `/${account.name}${Routes.Login}`;
    const { children, exact, ...rest } = this.props;
    const exactProp = exact ? exact : false;

    if (loading) {
      return null;
    }

    return (
      <Route
        {...rest}
        exact={exactProp}
        render={({ location }) =>
          this.isAuthenticated() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setLoginAccount,
  setLoginUser,
};

export const PrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PrivateHotelRouteComponent));
