import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { RootState } from '@share/utils';
import { formatMoney } from '@share/utils';
import { IAdminHotelBookingDetails } from '@common-types';
import { selectAdminHotelBookingsDetails } from '@store/slices';

import './style.scss';

interface IMapStateToProps {
  hotelDetails: IAdminHotelBookingDetails;
}

interface IProps extends IMapStateToProps {}

class BookingPaymentDetailsComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { hotelDetails } = this.props;

    if (!hotelDetails) {
      return null;
    }

    const { segments } = hotelDetails;

    return (
      <div className="booking-payment-details">
        <div className="booking-payment-details__card">
          <div className="booking-payment-details__header-wrapper">
            <div className="booking-payment-details__header">
              <FormattedMessage id="payment.information" />
            </div>
          </div>
          <div className="booking-payment-details__card-content unit-card">
            {segments?.map(({ transactionBase, refundTransaction, segmentId }, i) => {
              return (
                <div key={i}>
                  <div className="booking-payment-details__payment-section grey-section">
                    <div className="booking-payment-details__payment-section_segment">
                      <FormattedMessage id="segment" /> <span> </span> # {i + 1}
                      <strong>
                        {' '}
                        <FormattedMessage id="id" />: <span> </span>
                        {segmentId}
                      </strong>
                    </div>
                  </div>
                  <div className="booking-payment-details__payment-info">
                    <h4>
                      <FormattedMessage id="payment.info" />
                    </h4>
                    {transactionBase?.map(({ amount, transactionId, authCode }) => (
                      <div key={transactionId}>
                        <div className="booking-payment-details__payment-info_row">
                          <span>
                            <FormattedMessage id="amount" />:
                          </span>{' '}
                          <strong>{formatMoney.format(amount)}</strong>
                        </div>
                        <div className="booking-payment-details__payment-info_row">
                          <span>
                            <FormattedMessage id="transaction.id" />:
                          </span>{' '}
                          <span>{transactionId}</span>
                        </div>
                        <div className="booking-payment-details__payment-info_row">
                          <span>
                            {' '}
                            <FormattedMessage id="authorization.code" />:
                          </span>{' '}
                          <span>{authCode}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {!!refundTransaction && (
                    <div className="booking-payment-details__payment-info">
                      <h4>
                        <FormattedMessage id="refunded.info" />
                      </h4>
                      <div>
                        <div className="booking-payment-details__payment-info_row">
                          <span>
                            <FormattedMessage id="amount" />:{' '}
                          </span>
                          <strong>{formatMoney.format(refundTransaction.amount)}</strong>
                        </div>
                        <div className="booking-payment-details__payment-info_row">
                          <span>
                            <FormattedMessage id="transaction.id" />:
                          </span>{' '}
                          <span>{refundTransaction.transactionId}</span>
                        </div>
                        {refundTransaction.authCode && (
                          <div className="booking-payment-details__payment-info_row">
                            <span>
                              <FormattedMessage id="authorization.code" />:
                            </span>{' '}
                            <span>{refundTransaction.authCode}</span>
                          </div>
                        )}
                        <div className="booking-payment-details__payment-info_row">
                          <span>
                            <FormattedMessage id="refunded.transaction.id" />:
                          </span>
                          <span>{refundTransaction.referenceTransactionId}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelDetails: selectAdminHotelBookingsDetails(state),
  };
};

export const BookingPaymentDetails = connect(mapStateToProps)(BookingPaymentDetailsComponent);

