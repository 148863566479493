import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBenefits } from '@share/common-types';
import { Urls } from '@share/constants';
import { axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';

export interface IBenefitsState {
  benefits: IBenefits;
  loading: boolean;
  error: string;
}

const initialState: IBenefitsState = {
  benefits: null,
  loading: false,
  error: '',
};

const benefitsSlice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {
    setLoading: (state: IBenefitsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IBenefitsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setBenefits: (state: IBenefitsState, { payload }: PayloadAction<IBenefits>) => {
      state.benefits = payload;
    },
  },
});

export const benefitsActions = benefitsSlice.actions;

export const benefitsReducer = benefitsSlice.reducer;

export const getBenefits = (keyId: number): AppThunk => {
  return async (dispatch) => {
    dispatch(benefitsActions.setLoading(true));

    try {
      const res = await axiosInstance.get(`${Urls.Benefits}/${keyId}`);

      dispatch(benefitsActions.setLoading(false));
      dispatch(benefitsActions.setBenefits(res.data));
    } catch (error) {
      console.error(error);
      dispatch(benefitsActions.setError(error.toString()));
      dispatch(benefitsActions.setLoading(false));
    }
  };
};
