import React from "react";

const PaymentCard = ({ brand, number, validThru, cvv }) => {
  return (
    <div className="card">
      <div className={`bg-${brand === "Visa" ? "primary" : "danger"} p-4 rounded-3`}>
        <div className="d-flex justify-content-between align-items-start">
          <img
            className="w-40px"
            src={`assets/images/element/${brand.toLowerCase()}.svg`}
            alt=""
          />
          <div className="dropdown">
            <a
              className="text-white"
              href="#"
              id={`creditcardDropdown-${brand}`}
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <svg width="24" height="24" fill="none">
                <circle
                  fill="currentColor"
                  cx="12.5"
                  cy="3.5"
                  r="2.5"
                ></circle>
                <circle
                  fill="currentColor"
                  opacity="0.5"
                  cx="12.5"
                  cy="11.5"
                  r="2.5"
                ></circle>
                <circle
                  fill="currentColor"
                  opacity="0.3"
                  cx="12.5"
                  cy="19.5"
                  r="2.5"
                ></circle>
              </svg>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby={`creditcardDropdown-${brand}`}
            >
              <li>
                <a className="dropdown-item" href="#">
                  <i className="bi bi-pencil-square me-2 fw-icon"></i>
                  Edit card
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item text-danger"
                  href="#"
                >
                  <i className="bi bi-trash me-2 fw-icon"></i>
                  Remove card
                </a>
              </li>
            </ul>
          </div>
        </div>
        <h4 className="text-white mt-4">{number}</h4>
        <div className="d-flex justify-content-between text-white">
          <span>Valid thru: {validThru}</span>
          <span>CVV: {cvv}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
