import React from 'react';
import { connect } from 'react-redux';
import { notification, Tabs } from 'antd';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import format from 'date-fns/format';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { RootState } from '@share/utils';
import { BookingDetailsActionsEnum, BookingDetailsTabsEnum, BookingStatusEnum, BookingSubStatusEnum, BookingTabsEnum, CondoBookingType } from '@common-types';
import { BlueButton, CustomSelect } from '@share/components';
import {
  getAdminBooking,
  getLastReminder,
  IAdminReminderState,
  retryVoidCondoBooking,
  adminAllBookingsActions,
  retryCapturedCondoBooking,
  IAdminBookingDetailsState,
  adminBookingDetailsActions,
} from '@store/slices';
import { getMarkAsCancelled, IReservationCancellationState } from '@share/store/slices';
import { ErrorSmallSvg, SuccessSmallSvg, InfoDarkSvg } from '@assets';
import { DATE_FORMAT_FULL_DATA, DATE_FORMAT_CANCELED } from '@constants';
import { BookingsStatus } from '../booking-status';
import { BookingOrderDetails } from '../order-details';
import { BookingPaymentDetail } from '../payment-details';
import { BookingCondoUnit } from '../condo-unit';
import { ReminderModal } from '../reminder-modal';
import { CancelModal } from '../cancel-modal';
import './style.scss';
import { Routes } from '@share/constants';

interface IMapStateToProps {
  adminBookingDetailsStore: IAdminBookingDetailsState;
  adminReminderStore: IAdminReminderState;
  reservationCancellationStore: IReservationCancellationState;
}

interface IMapDispatchToProps {
  setShowReminderModal: (value: boolean) => void;
  setShowMarkAsCanceledModal: (value: boolean) => void;
  getAdminBooking: (type: CondoBookingType, id: string) => void;
  getLastReminder: (orderId: number) => void;
  retryVoidCondoBooking: (id: number) => void;
  retryCapturedCondoBooking: (id: number) => void;
  getMarkAsCancelled: (orderId: number, reason: string) => void;
  setTab: (tab: BookingTabsEnum) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    WrappedComponentProps,
    RouteComponentProps {
  type: CondoBookingType;
}

interface IState {
  tab: BookingDetailsTabsEnum;
}

const options: LabeledValue[] = [
  { label: <FormattedMessage id="all.actions" />, value: BookingDetailsActionsEnum.AllActions },
  {
    label: <FormattedMessage id="mark.as.cancelled" />,
    value: BookingDetailsActionsEnum.MarkAsCancelled,
  },
  { label: <FormattedMessage id="send.reminder" />, value: BookingDetailsActionsEnum.SendReminder },
];

const ZERO = 0;

class BookingDetailsComponent extends React.Component<IProps, IState> {
  state: IState = {
    tab: BookingDetailsTabsEnum.OrderDetails,
  };

  changeTab = (tab: string) => {
    this.setState({ tab: tab as BookingDetailsTabsEnum });
  };

  getComponentByTab = (): React.ReactNode => {
    const map: { [key: string]: React.ReactNode } = {
      [BookingDetailsTabsEnum.OrderDetails]: <BookingOrderDetails />,
      [BookingDetailsTabsEnum.PaymentDetails]: <BookingPaymentDetail />,
    };

    return map[this.state.tab];
  };

  onActionSelect = (value: SelectValue) => {
    if (value === BookingDetailsActionsEnum.MarkAsCancelled) {
      this.openCancelModal();
    } else if (value === BookingDetailsActionsEnum.SendReminder) {
      this.openRemindModal();
    }
  };

  openRemindModal = () => {
    const { orderId } = this.props.adminBookingDetailsStore.details;

    this.props.setShowReminderModal(true);
    this.props.getLastReminder(orderId);
  };

  openCancelModal = () => {
    this.props.setShowMarkAsCanceledModal(true);
  };

  closeRemindModal = () => {
    this.props.setShowReminderModal(false);
  };

  closeCancelModal = () => {
    this.props.setShowMarkAsCanceledModal(false);
  };

  retryVoidCondoBooking = (): void => {
    const { adminBookingDetailsStore } = this.props;
    const { details } = adminBookingDetailsStore;
    this.props.retryVoidCondoBooking(details.orderId);
  };

  retryCapturedCondoBooking = (): void => {
    const { adminBookingDetailsStore } = this.props;
    const { details } = adminBookingDetailsStore;
    this.props.retryCapturedCondoBooking(details.orderId);
  };

  componentDidMount() {
    this.props.setTab(BookingTabsEnum.Condos);
    this.props.getAdminBooking(this.props.type, (this.props.match.params as { id: string }).id);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      intl,
      type,
      match,
      setTab,
      history,
      getAdminBooking,
      adminReminderStore,
      adminBookingDetailsStore,
      reservationCancellationStore,
    } = this.props;
    const { loading, error } = adminReminderStore;
    const { loadingMarkAsCancelled, markAsCancelled } = reservationCancellationStore;
    const { isVoided, isCaptured, retryVoidLoading, retryCapturedLoading } =
      adminBookingDetailsStore;

    if (
      prevProps.adminBookingDetailsStore.retryCapturedLoading !== retryCapturedLoading &&
      !retryCapturedLoading
    ) {
      if (isCaptured) {
        notification['success']({
          message: intl.formatMessage({ id: 'retry.captured.successful' }),
          icon: <SuccessSmallSvg />,
          className: 'all-bookings__notification success',
        });
        setTab(BookingTabsEnum.Condos);
        getAdminBooking(type, (match.params as { id: string }).id);
      } else {
        notification['error']({
          message: intl.formatMessage({ id: 'retry.captured.failed' }),
          icon: <ErrorSmallSvg />,
          className: 'all-bookings__notification error',
        });
      }
    }

    if (
      prevProps.adminBookingDetailsStore.retryVoidLoading !== retryVoidLoading &&
      !retryVoidLoading
    ) {
      if (isVoided) {
        notification['success']({
          message: intl.formatMessage({ id: 'retry.void.successful' }),
          icon: <SuccessSmallSvg />,
          className: 'all-bookings__notification success',
        });
        history.push(`${Routes.AllBookings}`);
      } else {
        notification['error']({
          message: intl.formatMessage({ id: 'retry.void.failed' }),
          icon: <ErrorSmallSvg />,
          className: 'all-bookings__notification error',
        });
      }
    }

    if (prevProps.adminReminderStore.loading !== loading && !loading) {
      notification['success']({
        message: intl.formatMessage({ id: 'reminder.is.successful' }),
        icon: <SuccessSmallSvg />,
        className: 'all-bookings__notification success',
      });
    }

    if (prevProps.adminReminderStore.error.length !== error.length && error.length !== ZERO) {
      notification['error']({
        message: intl.formatMessage({ id: 'reminder.is.failed' }),
        icon: <ErrorSmallSvg />,
        className: 'all-bookings__notification error',
      });
    }

    if (
      prevProps.reservationCancellationStore.loadingMarkAsCancelled !== loadingMarkAsCancelled &&
      !loadingMarkAsCancelled
    ) {
      if (markAsCancelled?.isCompletedSuccessfully) {
        notification['success']({
          message: intl.formatMessage({ id: 'cancel.booking.successful' }),
          icon: <SuccessSmallSvg />,
          className: 'all-bookings__notification success',
        });
        getAdminBooking(this.props.type, (this.props.match.params as { id: string }).id);
      } else {
        notification['error']({
          message: intl.formatMessage({ id: 'cancel.booking.failed' }),
          icon: <ErrorSmallSvg />,
          className: 'all-bookings__notification error',
        });
      }
    }
  }

  render(): React.ReactNode {
    const { adminBookingDetailsStore, adminReminderStore } = this.props;
    const { details, retryVoidLoading, retryCapturedLoading } = adminBookingDetailsStore;
    const { loadingLastReminder, lastReminder } = adminReminderStore;
    const isDraft = details && details.status === BookingStatusEnum.Draft;
    const isFailed = details && details.status === BookingStatusEnum.Failed;
    const isCancelled = details && details.status === BookingStatusEnum.Cancelled;
    const isSubStatusCaptureUnknown =
      details &&
      details.subStatuses.some((subStatus) => subStatus.status === BookingSubStatusEnum.Unknown);
    const isSubStatusAuthorizeFailed =
      details &&
      details.subStatuses.some(
        (subStatus) => subStatus.stage === BookingSubStatusEnum.AuthorizeFailed,
      );
    const isCancellationReason =
      details && details.status === BookingStatusEnum.Cancelled && details.cancellationReason;

    return (
      details && (
        <div className="booking-details">
          <div className="booking-details__top-section">
            <div className="booking-details__header">
              <FormattedMessage id="condo.order" /> #{details.orderId}
            </div>
            {details.bookedOn ? (
              <div className="booking-details__description">
                <FormattedMessage id="booked.on" />{' '}
                {format(new Date(details.bookedOn), DATE_FORMAT_FULL_DATA)}
              </div>
            ) : null}
            <div className="booking-details__actions">
              <div
                className={`booking-details__actions-left ${
                  isCancellationReason ? 'booking-details__actions-left_reason' : ''
                }`}
              >
                <div className="booking-details__actions-status">
                  <BookingsStatus status={details.status} subStatus={details.subStatuses} />
                  {isCancelled && details.cancellationDate && (
                    <div className="booking-details__actions-cancelled-date">
                      <FormattedMessage id="on" />{' '}
                      {format(new Date(details.cancellationDate), DATE_FORMAT_CANCELED)}
                    </div>
                  )}
                </div>
                {isCancellationReason && (
                  <div className="booking-details__actions-reason">
                    <span>
                      <InfoDarkSvg />
                      <FormattedMessage id="reason" />:
                    </span>
                    {details.cancellationReason}
                  </div>
                )}
              </div>
              <div className="booking-details__actions-right">
                {isDraft && !isSubStatusAuthorizeFailed ? (
                  <BlueButton onClick={this.retryVoidCondoBooking} loading={retryVoidLoading}>
                    <FormattedMessage id="void" />
                  </BlueButton>
                ) : null}
                {isFailed && !isSubStatusCaptureUnknown ? (
                  <BlueButton
                    onClick={this.retryCapturedCondoBooking}
                    loading={retryCapturedLoading}
                  >
                    <FormattedMessage id="retry" />
                  </BlueButton>
                ) : null}
                {details.status === BookingStatusEnum.Confirmed ? (
                  <>
                    <BlueButton onClick={this.openCancelModal}>
                      <FormattedMessage id="mark.as.cancelled" />
                    </BlueButton>
                    <CustomSelect
                      options={options}
                      value={BookingDetailsActionsEnum.AllActions}
                      onChange={this.onActionSelect}
                      disabled={false}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <div className="booking-details__tabs">
              <Tabs activeKey={this.state.tab} onChange={this.changeTab}>
                <Tabs.TabPane
                  tab={<FormattedMessage id="order.details" />}
                  key={BookingDetailsTabsEnum.OrderDetails}
                />
                <Tabs.TabPane
                  tab={<FormattedMessage id="payment.details" />}
                  key={BookingDetailsTabsEnum.PaymentDetails}
                />
              </Tabs>
            </div>
          </div>
          <div className="booking-details__mid">
            <div className="booking-details__mid-left">{this.getComponentByTab()}</div>
            <div className="booking-details__mid-right">
              <BookingCondoUnit details={details} />
            </div>
          </div>
          {adminBookingDetailsStore.showReminderModal && lastReminder && !loadingLastReminder ? (
            <ReminderModal
              visible={adminBookingDetailsStore.showReminderModal}
              onCancel={this.closeRemindModal}
              orderId={details.orderId}
            />
          ) : null}
          {adminBookingDetailsStore.showMarkAsCanceledModal ? (
            <CancelModal
              visible={adminBookingDetailsStore.showMarkAsCanceledModal}
              orderId={details.orderId}
              onCancel={this.closeCancelModal}
              getMarkAsCancelled={this.props.getMarkAsCancelled}
            />
          ) : null}
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingDetailsStore: state.adminBookingDetailsStore,
    adminReminderStore: state.adminReminderStore,
    reservationCancellationStore: state.reservationCancellationStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setShowReminderModal: (value: boolean) => {
    dispatch(adminBookingDetailsActions.setShowReminderModal(value));
  },
  setShowMarkAsCanceledModal: (value: boolean) => {
    dispatch(adminBookingDetailsActions.setShowMarkAsCanceledModal(value));
  },
  getAdminBooking: (type: CondoBookingType, id: string) => {
    dispatch(getAdminBooking(type, id));
  },
  getLastReminder: (orderId: number) => {
    dispatch(getLastReminder(orderId, BookingTabsEnum.Condos));
  },
  retryVoidCondoBooking: (id: number) => {
    dispatch(retryVoidCondoBooking(id));
  },
  retryCapturedCondoBooking: (id: number) => {
    dispatch(retryCapturedCondoBooking(id));
  },
  getMarkAsCancelled: (orderId: number, reason: string) => {
    dispatch(getMarkAsCancelled(orderId, reason));
  },
  setTab: (tab: BookingTabsEnum) => {
    dispatch(adminAllBookingsActions.setTab(tab));
  },
});

export const BookingDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(BookingDetailsComponent)));

