import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';

import { ILocation, LocationsTypes } from '@share/common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';

export interface ILocationsState {
  locations: ILocation[];
  loading: boolean;
  error: string;
  selectedLocationLabel: string;
  selectedLocation: ILocation;
  locationLabel: string;
  location: ILocation;
}

const initialState: ILocationsState = {
  locations: [],
  loading: false,
  error: '',
  selectedLocationLabel: undefined,
  selectedLocation: undefined,
  locationLabel: undefined,
  location: undefined,
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLoading: (state: ILocationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ILocationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLocations: (state: ILocationsState, { payload }: PayloadAction<ILocation[]>) => {
      state.locations = payload;
    },
    setSelectLocation: (state: ILocationsState, { payload }: PayloadAction<ILocation>) => {
      state.selectedLocation = payload;
    },
    setSelectLocationLabel: (state: ILocationsState, { payload }: PayloadAction<string>) => {
      state.selectedLocationLabel = payload;
    },
    applyLocation: (state: ILocationsState) => {
      state.locationLabel = state.selectedLocationLabel;
      state.location = state.selectedLocation;
    },
    resetLocation: (state: ILocationsState) => {
      state.locations = [];
      state.loading = false;
      state.error = '';
      state.selectedLocationLabel = '';
      state.selectedLocation = undefined;
      state.locationLabel = '';
      state.location = undefined;
    },
    resetSelectedLocation: (state: ILocationsState) => {
      state.selectedLocationLabel = state.locationLabel;
      state.selectedLocation = state.location;
    },
  },
});

export const locationActions = locationsSlice.actions;

export const locationsReducer = locationsSlice.reducer;

let cancelRequest: Canceler;

export const getLocations = (search: string, selectedLocation: ILocation | undefined, onlyCities: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(locationActions.setLoading(true));

    try {
      if (cancelRequest) {
        cancelRequest();
      }

      const { hotelsStore } = getState();
      const { isWidget } = hotelsStore;
      const res = await axiosInstance.get(Urls.Locations, {
        ...getHeaders(isWidget),
        params: {
          SearchString: search.trim(),
        },
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelRequest = canceler;
        }),
      });
      let locations: ILocation[] = [...res.data.locations];

      if (selectedLocation && search === selectedLocation.name) {
        locations = locations.filter(({ code }) => code !== selectedLocation.code);
        locations.unshift(selectedLocation);
      }

      if (onlyCities) {
        locations = locations.filter(({ type }) => type === LocationsTypes.Destination);
      }

      dispatch(locationActions.setLoading(false));
      dispatch(locationActions.setLocations(locations));
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        console.error(error);
        dispatch(locationActions.setLoading(false));
      }
    }
  };
};
