import React, { useState } from "react";
import { Form, Input, Select, Button, Spin } from "antd";
import img from "@assets/images/element/signin.svg";
import useApi from "../../../api-hook/component";
import createAccountPayload from "./payload.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { API_END_POINT, BASE_URL } from "../../../constants/api-enums";
import { registerFormFields } from "../../../_mock-api's";

const Register = () => {
  const history = useHistory();
  const { fetchData } = useApi(BASE_URL.API_BASE_URL, "multipart/form-data");
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    createAccountPayload.email = values.businessEmail;
    createAccountPayload.name = values.companyName;
    const payloadDataStringify = JSON.stringify(createAccountPayload);

    const formData = new FormData();
    formData.append("query", payloadDataStringify);

    try {
      const result = await fetchData(
        API_END_POINT.REGISTER_COMPANY,
        formData,
        "post"
      );

      if (result?.name) {
        toast.success("Company Registered successfully", {
          theme: "colored",
        });
        history.push("/dashboard");
      }
    } catch (error) {
      console.error("Error parsing hotels data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="">
      <div className="container h-100 d-flex px-0 px-sm-4 mb-5">
        <div className="row justify-content-center align-items-center m-auto">
          <div className="col-12">
            <div className="bg-mode shadow rounded-2">
              <div className="row g-0">
                <div className="col-lg-6 d-flex align-items-top">
                  <div className="p-3 p-lg-5">
                    <img src={img} alt="" />
                  </div>
                  <div className="vr opacity-1 d-none d-lg-block"></div>
                </div>
                <div className="col-lg-6 order-1">
                  <div className="p-4 p-sm-7">
                    <h1 className="mb-2 h3">Register your company</h1>
                    <p className="mb-3">
                      Get exclusive corporate access to over 500,000 condos,
                      resorts and hotels worldwide
                    </p>

                    <Form
                      onFinish={onFinish}
                      className="mt-4 text-start"
                      layout="vertical"
                    >
                      {registerFormFields?.map((field, i) => (
                        <Form.Item
                          key={i}
                          label={field.label}
                          name={field.name}
                          rules={field.rules}
                        >
                          {field.name === "annualTravelSpend" ? (
                            <Select
                              placeholder="Select"
                              className="form-control"
                            >
                              <Select.Option value="0-25k">
                                $0 - $25,000
                              </Select.Option>
                              <Select.Option value="25k-50k">
                                $25,000 - $50,000
                              </Select.Option>
                              <Select.Option value="50k-100k">
                                $50,000 - $100,000
                              </Select.Option>
                              <Select.Option value="100k-250k">
                                $100,000 - $250,000
                              </Select.Option>
                              <Select.Option value="250k+">
                                $250,000+
                              </Select.Option>
                            </Select>
                          ) : (
                            <Input
                              className="form-control"
                              placeholder={field?.placeholder}
                            />
                          )}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                          {loading ? (
                            <div>
                              <Spin size="small" />
                            </div>
                          ) : (
                            "Register my Free Account"
                          )}
                        </Button>
                      </Form.Item>
                    </Form>
                    <div className="my-3">
                      <p>
                        Already registered?<a href="login.php"> Log in.</a>
                      </p>
                    </div>
                    <div className="my-3">
                      <p>
                        If your company is already registered:
                        <a href="request-access.php"> Request access.</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
