import React from 'react';

export const LocationSvg: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.414 20.9L17.657 16.657C18.7758 15.5382 19.5377 14.1127 19.8463 12.5609C20.155 11.009 19.9965 9.40051 19.391 7.93873C18.7855 6.47694 17.7601 5.22754 16.4445 4.3485C15.1289 3.46947 13.5822 3.00029 12 3.00029C10.4178 3.00029 8.87107 3.46947 7.55548 4.3485C6.2399 5.22754 5.21452 6.47694 4.60901 7.93873C4.00349 9.40051 3.84504 11.009 4.15369 12.5609C4.46234 14.1127 5.22422 15.5382 6.343 16.657L10.587 20.9C10.962 21.2746 11.4704 21.4851 12.0005 21.4851C12.5306 21.4851 13.039 21.2746 13.414 20.9ZM15 11C15 12.6568 13.6569 14 12 14C10.3431 14 9 12.6568 9 11C9 9.34314 10.3431 8 12 8C13.6569 8 15 9.34314 15 11Z"
      fill="#0D99D6"
    />
  </svg>
);
