export enum LastReminderOrderIdKeyEnum {
  CondoOrderId = 'condoOrderId',
  HotelOrderId = 'hotelOrderId',
}
export interface IAdminLastReminder {
  additionalEmail: string;
  email: string;
  lastSentDate: string;
}

export interface IAdminReminder {
  [key: number]: string | number;
  email: string;
  additionalEmail: string;
}

