// config.js
const createAccountPayload = {
    preferences: [
      {"key":"default_currency","value":"USD"},
      {"key":"default_languega","value":"en"},
      {"key":"header_menu","value":"[{\"Title\":\"testing\",\"Url\":\"http://www.google.com\"}]"},
      {"key":"footer_menu","value":"[{\"Title\":\"testing\",\"Url\":\"http://www.facebook.com\"}]"},
      {"key":"support_assistants","value":"{\"type\":\"EMAIL\",\"value\":\"test@test.cpm\"}"},
      {"key":"sales_assistants","value":"{\"type\":\"EMAIL\",\"value\":\"sales@test.com\"}"},
      {"key":"condos_search_title","value":"lkkljljk"},
      {"key":"condos_search_message","value":"nbvvnbvnbvvbvbn"},
      {"key":"search_title","value":"gjhhjhjgjhgjhg"},
      {"key":"displayContactSection","value":"false"},
      {"key":"default_language","value":"en"},
      {"key":"urlwebhook","value":"https://travnow.com/booking_service.php"},
      {"key":"headwebhook","value":"TravcResponse"},
      {"key":"chargecurrency","value":"TravcChargeCurrency"},
      {"key":"buttons_color","value":"#d0021b"},
      {"key":"header_background_color","value":"#7ed321"},
      {"key":"footer_background_color","value":"#9013fe"},
      {"key":"header_fonts_color","value":"#ffffff"},
      {"key":"footer_fonts_color","value":"#ffffff"},
      {"key":"search_message","value":"gjgjgjhgjhgjhg"},
      {"key":"savings_percentaje_min","value":"10"},
      {"key":"typesendinvoice","value":"Manual"},
      {"key":"quick","value":"false"},
      {"key":"hasDynamicMargin","value":"false"},
      {"key":"enableSupplierMerchantCredentials","value":"false"},
      {"key":"enable_credit_line","value":"false"},
      {"key":"forceMember","value":"true"},
      {"key":"enableWallet","value":"false"},
      {"key":"walletIsExternal","value":"true"},
      {"key":"walletExternalProfile","value":"TranzactCard"},
      {"key":"walletPercentage","value":"11"},
      {"key":"disableNewTabOpen","value":"true"},
      {"key":"cars_display_trusted_brands","value":"true"},
      {"key":"hideThingsMatterSection","value":"false"},
      {"key":"search_icons_color","value":"#4a90e2"},
      {"key":"search_types","value":"[\"Hotels\",\"Vacations\",\"Condos\",\"Cars\"]"},
      {"key":"logo","value":"{\"Key\":\"cktest/Condo_World_Logo.jpeg\",\"Value\":\"https://travcoding-api-resources.s3.amazonaws.com/cktest/Condo_World_Logo.jpeg\"}"},
      {"key":"master_class_registration_url","value":"https://register.gotowebinar.com/register/498870846326700384"},
      {"key":"lead_time","value":"15"},
      {"key":"enable_insurance","value":"false"},
      {"key":"home_page_template","value":"rsi"},
      {"key":"refundable_rule_everyone","value":"false"}
    ],
    hasWebhook: true,
    hasSendInvoice: false,
    isTravelAgentPro: false,
    siteId: -1,
    parentAccountId: "a9d1e3bb-1de9-491e-8292-c988aa5bb0e4",
    suppliers: ["CV","CR","CIR","CWH","DID","EXPP","EXPS","GAR","GUESTY","HDO","HBD","HSI","INT","PRLN","PRLPC","RCI","RESTEL","TBO","CARN"],
    showProviderVoucher: false,
    property: "Hotel",
    baseAddressSite: "https://qa2-bookingflow.travcoding.com/",
    name: "onemoretesttt",
    description: "testTes Test",
    email: "inftesto@test.com",
    type: "privateWithLogin",
    countryCode: "US"
  };
  
export default createAccountPayload
  