import React from 'react';

export const InfoDarkSvg: React.FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875ZM9 2.46094C12.6105 2.46094 15.5391 5.38945 15.5391 9C15.5391 12.6105 12.6105 15.5391 9 15.5391C5.38945 15.5391 2.46094 12.6105 2.46094 9C2.46094 5.38945 5.38945 2.46094 9 2.46094Z"
      fill="black"
      fillOpacity="0.85"
    />
    <path
      d="M9.84403 12.0937C9.84403 11.87 9.75513 11.6554 9.5969 11.4971C9.43866 11.3389 9.22405 11.25 9.00028 11.25C8.7765 11.25 8.56189 11.3389 8.40365 11.4971C8.24542 11.6554 8.15653 11.87 8.15653 12.0937C8.15653 12.3175 8.24542 12.5321 8.40365 12.6904C8.56189 12.8486 8.7765 12.9375 9.00028 12.9375C9.22405 12.9375 9.43866 12.8486 9.5969 12.6904C9.75513 12.5321 9.84403 12.3175 9.84403 12.0937ZM8.5784 10.125L9.42215 10.125C9.49949 10.125 9.56278 10.0617 9.56278 9.98437L9.56278 5.20312C9.56278 5.12578 9.49949 5.0625 9.42215 5.0625L8.5784 5.0625C8.50106 5.0625 8.43778 5.12578 8.43778 5.20312L8.43778 9.98437C8.43778 10.0617 8.50106 10.125 8.5784 10.125Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

