import React from 'react';
import img1 from '@assets/images/client/01.svg';
import img2 from '@assets/images/client/02.svg';
import img3 from '@assets/images/client/03.svg';
import img4 from '@assets/images/client/04.svg';
import img5 from '@assets/images/client/05.svg';
import img6 from '@assets/images/client/06.svg';

const Brands = () => {
  const brandImages = [img1, img6, img2, img5, img3, img4];
  return (
    <section className="py-0 py-md-5">
      <div className="container">
        <h4 className="mb-3 mb-lg-5 text-center">
          Brands you know and love trust us for their travel!
        </h4>
      </div>
      <div className="container">
        <div className="row g-4 g-lg-7 justify-content-center align-items-center">
          {brandImages?.map((imgSrc, index) => (
            <div key={index} className="col-5 col-sm-3 col-xl-2">
              <img src={imgSrc} className="grayscale" alt={`Brand ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Brands };
