import React, { useState } from 'react';

const DashoardAside: React.FC<any> = ({
  payload,
  fetchHotels,
  setSearchHotels,
  setLoading,
  setPageNumber,
  setPayload,
}) => {
  const [sortBy, setSortBy] = useState('BiggestSavingsPercentage');
  const [accommodationTypes, setAccommodationTypes] = useState<string[]>([]);
  const [priceRanges, setPriceRanges] = useState([]);
  const [amenities, setAmenities] = useState<string[]>([]);
  const [customerRating, setCustomerRating] = useState([]);

  const handleAccommodationTypeChange = (type: string, isChecked: boolean) => {
    if (isChecked) {
      setAccommodationTypes([type]);
      applyFilter('accommodationType', [type]);
    } else {
      setAccommodationTypes([]);
      applyFilter('accommodationType', []);
    }
  };
  const handlePriceChange = (range, isChecked) => {
    if (isChecked) {
      setPriceRanges([range]);
      applyFilter('budgetRanges', [range]);
    } else {
      setPriceRanges([]);
      applyFilter('budgetRanges', []);
    }
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
    setLoading(true);
    setPayload((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        sortBy: e.target.value,
      };
    });
    fetchHotels(
      {
        ...payload,
        pageNumber: 1,
        filter: {
          ...payload.filter,
        },
        sortBy: e.target.value,
      },
      () => {
        setPageNumber(1);
      },
    );
  };

  const handleAmenitiesChange = (type: string, isChecked: boolean) => {
    if (isChecked) {
      setAmenities((prevTypes) => [...prevTypes, type]);
      applyFilter('amenities', [...amenities, type]);
    } else {
      const filteredItems = amenities.filter((item) => item !== type);
      setAmenities(filteredItems);
      applyFilter('amenities', filteredItems);
    }
  };

  const handleStarRatingChange = (type: any, isChecked: boolean) => {
    if (isChecked) {
      setCustomerRating((prevTypes) => [...prevTypes, type]);
      applyFilter('starRatings', [...customerRating, type]);
    } else {
      const filteredItems = customerRating.filter((item) => item !== type);
      setCustomerRating(filteredItems);
      applyFilter('starRatings', filteredItems);
    }
  };

  const applyFilter = (type, value) => {
    setLoading(true);
    setSearchHotels([]);
    setPayload((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        filter: {
          ...payload.filter,
          [type]: value,
        },
        sortBy,
      };
    });
    fetchHotels(
      {
        ...payload,
        pageNumber: 1,
        filter: {
          ...payload.filter,
          [type]: value,
        },
        sortBy,
      },
      () => {
        setPageNumber(1);
      },
    );
  };

  const resetFilters = (event) => {
    event.preventDefault();
    setLoading(true);
    setSearchHotels([]);
    setPayload((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        filter: {
          distanceInMiles: 10,
          exactSearch: true,
        },
        sortBy,
      };
    });
    fetchHotels(
      {
        ...payload,
        pageNumber: 1,
        filter: {
          distanceInMiles: 10,
          exactSearch: true,
        },
        sortBy,
      },
      () => {
        setPageNumber(1);
      },
    );
    setAccommodationTypes([]);
    setPriceRanges([]);
    setAmenities([]);
    setCustomerRating([]);
  };

  return (
    <aside className="col-xl-4 col-xxl-3">
      <div>
        <div
          className="offcanvas-xl offcanvas-end"
          id="offcanvasSidebar"
          aria-labelledby="offcanvasSidebarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasSidebarLabel">
              Advance Filters
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              data-bs-target="#offcanvasSidebar"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body flex-column p-3 p-xl-0">
            <div className="rounded-3 shadow">
              <div className="mt-4 d-flex justify-content-end">
                <button className="btn btn-link p-0 mb-0 clear-filters" onClick={resetFilters}>
                  Clear filters
                </button>
              </div>
              <div className="card card-body rounded-0 rounded-top p-4">
                <h6 className="mb-2">Sort by</h6>
                <select
                  className="form-select js-choice"
                  value={sortBy}
                  onChange={handleSortChange}
                >
                  <option value="BiggestSavings">Best value</option>
                  <option value="DistanceAsc">Distance</option>
                  <option value="PriceAsc">Price - Low to High</option>
                  <option value="PriceDesc">Price - High to Low</option>
                  <option value="GuestRating">Guest Rating - Highest</option>
                </select>
              </div>

              <hr className="my-0" />

              <div className="card card-body rounded-0 p-4">
                <h6 className="mb-2">Accommodation Type</h6>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={accommodationTypes.includes('Hotel')}
                      onChange={(e) => handleAccommodationTypeChange('Hotel', e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">Hotel</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={accommodationTypes.includes('Resort')}
                      onChange={(e) => handleAccommodationTypeChange('Resort', e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">Resort</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={accommodationTypes.includes('Villa')}
                      onChange={(e) => handleAccommodationTypeChange('Villa', e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">Villa</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={accommodationTypes.includes('Lodge')}
                      onChange={(e) => handleAccommodationTypeChange('Lodge', e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">Lodge</label>
                  </div>

                  <div className="multi-collapse collapse" id="hotelType">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Hotel"
                        checked={accommodationTypes.includes('Guest House')}
                        onChange={(e) =>
                          handleAccommodationTypeChange('Guest House', e.target.checked)
                        }
                        id="hotelType2"
                      />
                      <label className="form-check-label">Guest House</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Hotel"
                        checked={accommodationTypes.includes('Cottage')}
                        onChange={(e) => handleAccommodationTypeChange('Cottage', e.target.checked)}
                        id="hotelType2"
                      />
                      <label className="form-check-label">Cottage</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Hotel"
                        checked={accommodationTypes.includes('Beach Hut')}
                        onChange={(e) =>
                          handleAccommodationTypeChange('Beach Hut', e.target.checked)
                        }
                        id="hotelType2"
                      />
                      <label className="form-check-label">Beach Hut</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Hotel"
                        checked={accommodationTypes.includes('Farm house')}
                        onChange={(e) =>
                          handleAccommodationTypeChange('Farm house', e.target.checked)
                        }
                        id="hotelType2"
                      />
                      <label className="form-check-label">Farm house</label>
                    </div>
                  </div>
                  <a
                    className="p-0 mb-0 mt-2 btn-more d-flex align-items-center collapsed"
                    data-bs-toggle="collapse"
                    href="#hotelType"
                    role="button"
                    aria-expanded="false"
                    aria-controls="hotelType"
                  >
                    See <span className="see-more ms-1">more</span>
                    <span className="see-less ms-1">less</span>
                    <i className="fa-solid fa-angle-down ms-2"></i>
                  </a>
                </div>
              </div>

              <hr className="my-0" />

              <div className="card card-body rounded-0 p-4">
                <h6 className="mb-2">Property name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter by name"
                  onChange={(e) => {
                    applyFilter('propertyName', e.target.value);
                  }}
                />
              </div>

              <hr className="my-0" />

              <div className="card card-body rounded-0 p-4">
                <h6 className="mb-2">Price range</h6>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={priceRanges.some((range) => range.from === 75 && range.to === 250)}
                      onChange={(e) => handlePriceChange({ from: 75, to: 250 }, e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">Up to $250</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={priceRanges.some((range) => range.from === 250 && range.to === 500)}
                      onChange={(e) => handlePriceChange({ from: 250, to: 500 }, e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">$250 to $500</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={priceRanges.some((range) => range.from === 500 && range.to === 1000)}
                      onChange={(e) => handlePriceChange({ from: 500, to: 1000 }, e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">$500 - $1000</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={priceRanges.some(
                        (range) => range.from === 1000 && range.to === 1500,
                      )}
                      onChange={(e) =>
                        handlePriceChange({ from: 1000, to: 1500 }, e.target.checked)
                      }
                      id="hotelType2"
                    />
                    <label className="form-check-label">$1000 - $1500</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={priceRanges.some(
                        (range) => range.from === 1500 && range.to === 2000,
                      )}
                      onChange={(e) =>
                        handlePriceChange({ from: 1500, to: 2000 }, e.target.checked)
                      }
                      id="hotelType2"
                    />
                    <label className="form-check-label">$1500 - $2000</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Hotel"
                      checked={priceRanges.some((range) => range.from === 2500)}
                      onChange={(e) => handlePriceChange({ from: 2500 }, e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">$2000+</label>
                  </div>
                </div>
              </div>

              <hr className="my-0" />

              <div className="card card-body rounded-0 p-4">
                <h6 className="mb-2">Amenities</h6>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Continental breakfast"
                      checked={amenities.includes('Continental breakfast')}
                      onChange={(e) =>
                        handleAmenitiesChange('Continental breakfast', e.target.checked)
                      }
                      id="hotelType2"
                    />
                    <label className="form-check-label">Continental breakfast</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Restaurant on-site"
                      checked={amenities.includes('Restaurant on-site')}
                      onChange={(e) =>
                        handleAmenitiesChange('Restaurant on-site', e.target.checked)
                      }
                      id="hotelType2"
                    />
                    <label className="form-check-label">Restaurant on-site</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Gym/Spa"
                      checked={amenities.includes('Gym/Spa')}
                      onChange={(e) => handleAmenitiesChange('Gym/Spa', e.target.checked)}
                      id="hotelType2"
                    />
                    <label className="form-check-label">Gym/Spa</label>
                  </div>
                  <div className="multi-collapse collapse" id="amenities">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Business center"
                        checked={amenities.includes('Business center')}
                        onChange={(e) => handleAmenitiesChange('Business center', e.target.checked)}
                        id="hotelType2"
                      />
                      <label className="form-check-label">Business center</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Full kitchen"
                        checked={amenities.includes('Full kitchen')}
                        onChange={(e) => handleAmenitiesChange('Full kitchen', e.target.checked)}
                        id="hotelType2"
                      />
                      <label className="form-check-label">Full kitchen</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="EV charging"
                        checked={amenities.includes('EV charging')}
                        onChange={(e) => handleAmenitiesChange('EV charging', e.target.checked)}
                        id="hotelType2"
                      />
                      <label className="form-check-label">EV charging</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Pull-out sofa"
                        checked={amenities.includes('Pull-out sofa')}
                        onChange={(e) => handleAmenitiesChange('Pull-out sofa', e.target.checked)}
                        id="hotelType2"
                      />
                      <label className="form-check-label">Pull-out sofa</label>
                    </div>
                  </div>
                  <a
                    className="p-0 mb-0 mt-2 btn-more d-flex align-items-center collapsed"
                    data-bs-toggle="collapse"
                    href="#amenities"
                    role="button"
                    aria-expanded="false"
                    aria-controls="amenities"
                  >
                    See <span className="see-more ms-1">more</span>
                    <span className="see-less ms-1">less</span>
                    <i className="fa-solid fa-angle-down ms-2"></i>
                  </a>
                </div>
              </div>

              <hr className="my-0" />

              <div className="card card-body rounded-0 p-4">
                <h6 className="mb-2">Star Rating</h6>
                <ul className="list-inline mb-0 g-3">
                  <li className="list-inline-item mb-0">
                    <input
                      className="btn-check border border-black"
                      type="checkbox"
                      checked={customerRating.includes(3)}
                      id="rating-3"
                      onChange={(e) => handleStarRatingChange(3, e.target.checked)}
                    />

                    <label
                      htmlFor="rating-3"
                      className="btn btn-sm btn-light btn-primary-soft-check"
                    >
                      3+ <i className="bi bi-heart-fill"></i>
                    </label>
                  </li>

                  <li className="list-inline-item mb-0">
                    <input
                      className="btn-check border border-black"
                      type="checkbox"
                      checked={customerRating.includes(4)}
                      id="rating-4"
                      onChange={(e) => handleStarRatingChange(4, e.target.checked)}
                    />

                    <label
                      htmlFor="rating-4"
                      className="btn btn-sm btn-light btn-primary-soft-check"
                    >
                      4+ <i className="bi bi-heart-fill"></i>
                    </label>
                  </li>
                  <li className="list-inline-item mb-0">
                    <input
                      id="rating-5"
                      className="btn-check border border-black"
                      type="checkbox"
                      checked={customerRating.includes(5)}
                      onChange={(e) => handleStarRatingChange(5, e.target.checked)}
                    />

                    <label
                      htmlFor="rating-5"
                      className="btn btn-sm btn-light btn-primary-soft-check"
                    >
                      5.0 <i className="bi bi-heart-fill"></i>
                    </label>
                  </li>
                </ul>
              </div>

              <hr className="my-0" />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default DashoardAside;
