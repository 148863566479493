import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import {
  ICondosLocationPickerState,
  ICondoFlexibleDatePickerState,
  ICondoStrictDatesState,
  ICondoGuestsState,
} from '@share/store/slices';
import { DATE_FORMAT } from '@share/constants';
import { formatDateCheckInOut, RootState } from '@share/utils';
import { getSelectedMonthText, getStayForText } from '@share/utils';
import { DateSearchTypeEnum } from '@share/common-types';

import { SearchSvg } from '@share/assets';

import './style.scss';

interface IMapStateToProps {
  condoStrictDatesStore: ICondoStrictDatesState;
  condoFlexibleDatePickerStore: ICondoFlexibleDatePickerState;
  condoGuestsStore: ICondoGuestsState;
  condosLocationPickerStore: ICondosLocationPickerState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  onToggle: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

class CondoSearchInfoComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const {
      condoGuestsStore,
      condoFlexibleDatePickerStore,
      condoStrictDatesStore,
      condosLocationPickerStore,
    } = this.props;
    const { selectedLocationLabel } = condosLocationPickerStore;
    const { adultsCount, kidsCount } = condoGuestsStore;
    const { searchType, startDate, endDate } = condoStrictDatesStore;
    const { selectedMonths } = condoFlexibleDatePickerStore;
    const isStrict = searchType === DateSearchTypeEnum.Strict;

    return (
      <div className="search-info" onClick={this.props.onToggle}>
        <SearchSvg />
        <div className="search-info__info">
          <p className="search-info__locations">{selectedLocationLabel}</p>
          <p className="search-info__description">
            {isStrict ? (
              <>
                {startDate && endDate ? (
                  <>
                    {formatDateCheckInOut(startDate, DATE_FORMAT)} -{' '}
                    {formatDateCheckInOut(endDate, DATE_FORMAT)},
                  </>
                ) : null}
              </>
            ) : (
              <>
                {getStayForText()}{' '}
                {selectedMonths.length ? (
                  <span className="condo-strict-date-picker__bold ttn">
                    <FormattedMessage id="in" />{' '}
                    {getSelectedMonthText(selectedMonths, this.props.intl)},
                  </span>
                ) : (
                  <span className="bold ttl">
                    <FormattedMessage id="anytime" />,
                  </span>
                )}
              </>
            )}{' '}
            {adultsCount + kidsCount} <FormattedMessage id="guests" />
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoStrictDatesStore: state.condoStrictDatesStore,
    condoFlexibleDatePickerStore: state.condoFlexibleDatePickerStore,
    condoGuestsStore: state.condoGuestsStore,
    condosLocationPickerStore: state.condosLocationPickerStore,
  };
};

export const CondoSearchInfo = connect(mapStateToProps, null)(injectIntl(CondoSearchInfoComponent));
