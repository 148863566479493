import React from 'react';
import ReactGA from 'react-ga4';
import Favicon from 'react-favicon';
import Cookies from 'js-cookie';
import axios from 'axios';

import { IntlProvider } from 'react-intl';
import { get, isEmpty, toNumber } from 'lodash';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider, withCookies, ReactCookieProps } from 'react-cookie';
import { Helmet } from 'react-helmet';

import { Locales, getAntLocale, getLocale, getMessages } from '@i18n';
import {
  Header,
  Footer,
  AdminHeader,
  SignIn,
  PrivateAdminRoute,
  ErrorPanel,
  PrivateRoute,
  ErrorPage,
  Markdown,
  IframeBackoffice,
  GenericNotFoundPage,
  ExpiredSessionModal,
  CondoWorldMap,
  ResetPassword,
  VerifyUser,
  ForgotPassword,
  SendVerification,
  ChangePassword,
  SignUp,
} from '@components';
import TravFooter from '../../components/new-home/footer/component';
import { Loading, LoadingSise } from '@share/components';
import {
  DecodeTokenData,
  getCancelIdCancellationFromPath,
  getNormalizedRooms,
  getPreferenceFileUrlFromAccount,
  getPreferenceFromAccount,
  getTempKeyCancellationFromPath,
  isAnyReservationCancelFromPath,
  UrlUtils,
  isVacationRentalsFromPath,
  VACATIONS_PAGE,
  isCondoWorldFromPath,
  isThreeDSFromPath,
  isCondoEngineFromPath,
} from '@share/utils';
import {
  ACCESS_TOKEN_LABEL,
  ADULTS_LABEL,
  CHECKIN_LABEL,
  CHECKOUT_LABEL,
  CHILDS_LABEL,
  COOKIE1_TOKEN_NAME,
  COOKIE_TOKEN_NAME,
  DATES_LABEL,
  LATITUDE_LABEL,
  LOCATION_LABEL,
  LOCATION_OBJECT_LABEL,
  LONGITUDE_LABEL,
  REFERENCE_NUMBER_LABEL,
  ROOMS_LABEL,
  Routes,
  SESSION_KEY_LABEL,
  USER_BASE_URL,
  AGENCY_NUMBER_LABEL,
  FILTERS_LABEL,
  CONDO_FILTERS_LABEL,
  CLIENT_CASH_LABEL,
  CLIENT_CASH_CONDO_LABEL,
  LIFE_STYLE_NAME_PARAM,
} from '@share/constants';
import { Responsive } from '@share/utils';
import {
  AppThunk,
  getAccountUsernameFromPath,
  isAdminFromPath,
  isErrorFromPath,
  isSignInFromPath,
  isSignOutFromPath,
  isWidgetFromPath,
  RootState,
} from '@share/utils';
import {
  ILoginState,
  logout,
  getLoginUserAccount,
  setLoginRedirect,
  locationActions,
  datesActions,
  roomsActions,
  hotelsActions,
  loginActions,
  LoginType,
  IMenuState,
  IFiltersState,
  ICondoFiltersState,
  filtersActions,
  condoFiltersActions,
  condosActions,
} from '@share/store/slices';
import { getAllCondoDestinations, getCountries } from '@share/store/slices';
import { REQUEST_CANCEL_RESERVATION, RESERVATION_CANCELLATION } from '@constants';

import { HotelDetailPage } from '../hotel-details';
import { GetawayPage } from '../getaway';
import { GetawayListPage } from '../getaway-list';
import { CondoDetailPage } from '../condo-details';
import { SubmitRequestPage } from '../all-inclusives/submit-request';
import { GetawayDetailsPage } from '../getaway-details';
import { CondoReviewBookPage } from '../condo-review-book';
import { ReservationCancellation } from '../reservation-cancellation';
import { NewApp } from '@pages';
import { HomePage } from '../home-page';
import { HomesPage } from '../homes';
import { Condominiums } from '../../components/condo/condominiums';
import { CondoSpecialsPage } from '../../components/condo/condo-specials';
import { GetawayBookingPage } from '../getaway-booking';
import { AmericanLifestylePage } from '../american-lifestyle';
import { ConciergeTravelTeamPage } from '../concierge';
import { ResourcesPage } from '../resources';
import { CruisesPage } from '../cruises';
import { DailyBenefitsPage } from '../daily-benefits';
import { LuxuryCruisesPage } from '../luxury-cruises';
import { ContactUsPage } from '../contact-us';
import { MasterClassesRegisterPage } from '../master-classes-register';
import { ScrollToTop } from '../../components/common/scroll-to-top';
import {
  FAVICON_FIELD_KEY,
  IClientCash,
  IGuest,
  ILocation,
  IRoom,
  ISessionKey,
  IUrlRoom,
  LOGOUT_FIELD_KEY,
  PAGE_TITLE_FIELD_KEY,
  TRAVCODING_V2,
} from '@share/common-types';
import { getLocationByGeo } from '@share/services';
import { MarkDownEnum, UserAccountEnum } from '@common-types';
import {
  setSelectedCondoReviewClientCash,
  setSelectedCondoReviewClientCashStr,
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,
} from '@store/slices';

import GetawayCondoPage from '../getaway-condo/component';
import AllInclusivesPage from '../all-inclusives/component';
import ReviewBookPage from '../review-book/component';
import AdminPage from '../admin-page/component';

import { SearchPage } from '../search';
import { MemberVideosPage } from '../member-videos';
import { MemberStoryPage } from '../member-story';
import { CondoPage } from '../condo';
import { PersonalInfoPage } from '../personal-info';
import { PromotionDetailsPage } from '../promotion-details';
import { BrioRciHowToPage } from '../brio-rci-how-to';
import { EliteRciHowToPage } from '../elite-rci-how-to';
import { WebinarsPage } from '../webinars';
import { ReservationsPage } from '../reservations';
import { AccountPage } from '../account';
import { NewMembersPage } from '../new-members';
import { ExperiencesPage } from '../experiences';
import { SidekickPage } from '../sidekicks';
import { SidekickTermsPage } from '../sidekicks-terms';
import { SidekickPrivacyPage } from '../sidekicks-privacy';
import { ThreeDS } from '../three-ds';
import { CarsPage } from '../cars';

import FaviconImage from '@assets/images/favicon.png';

import './style.scss';
import 'react-toastify/dist/ReactToastify.css';
import LogInNav from '../../../components/components/common/log-in-nav/component';
import Navbar from '../../../components/components/common/nav-bar/navbar';
import { Base64 } from 'js-base64';

const ORG_ID_TOEXCLUDE_CHAT = [644];

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  getLoginUserAccount: (
    accountName: string,
    isWidget: boolean,
    isCondo: boolean,
    isCancelRoute: boolean,
    cancelId?: string,
    tempKey?: string,
  ) => AppThunk;
  setLoginRedirect: (redirect: string) => void;
  logout: () => AppThunk;

  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  applyDates: () => void;

  setRooms: (rooms: IRoom[]) => void;
  applyRooms: () => void;

  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (locationLabel: string) => void;
  applyLocation: () => void;

  setSessionKey: (session: ISessionKey) => void;
  setReferenceNumber: (referenceNumber: string) => void;
  setAccessToken: (accessToken: string) => void;
  setAgencyNumber: (agencyNumber: string) => void;

  setLifeStyle: (lifeStyle: string) => void;

  setFilters: (filters: IFiltersState) => void;
  setCondoFilters: (filters: ICondoFiltersState) => void;

  setSelectedHotelSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedHotelReviewClientCash: (selectedClientCash: number) => void;
  setSelectedHotelReviewClientCashStr: (selectedClientCash: string) => void;

  setSelectedCondoSearchClientCash: (selectedClientCahs: IClientCash) => void;
  setSelectedCondoReviewClientCash: (selectedClientCash: number) => void;
  setSelectedCondoReviewClientCashStr: (selectedClientCash: string) => void;

  getAllCondoDestinations: () => void;
  getCountries: () => void;
}

export interface IAccountParams {
  accountUsername: string;
  id: string;
}

interface IProps
  extends ReactCookieProps,
    IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps<IAccountParams> {
  isWidget?: boolean;
}

const ONE = 1;

class ApplicationComponent extends React.Component<IProps, null> {
  async componentDidMount() {
    const { history } = this.props;
    const { loginStore } = this.props;
    const { account } = loginStore;
    const isTravCoding_V2 = account?.homePageTemplate === TRAVCODING_V2;
    const loggedUser = localStorage.getItem('LOGGED_USER_LABEL');
    const userDataStorage = loggedUser ? JSON.parse(Base64.decode(loggedUser)) : null;
    const userStorage = userDataStorage?.user;
    if (userStorage?.accessToken) {
      axios.defaults.headers.common.Authorization = `bearer ${userStorage?.accessToken}`;
    }

    const isWidgetPage = isWidgetFromPath(history);
    const pagesToExcludeAccountCheck = [
      isWidgetPage,
      isCondoWorldFromPath(history),
      isAdminFromPath(history),
      isErrorFromPath(history),
      isThreeDSFromPath(history),
    ];

    const values = UrlUtils.getValues();

    if (!pagesToExcludeAccountCheck.includes(true)) {
      const pathAccountName = getAccountUsernameFromPath(history);
      if (!isSignInFromPath(history) && !isSignOutFromPath(history)) {
        const { pathname, search } = history.location;
        this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);
      }

      this.props.getLoginUserAccount(
        pathAccountName,
        isWidgetPage,
        isCondoEngineFromPath(this.props.history),
        isAnyReservationCancelFromPath(history),
        getCancelIdCancellationFromPath(history),
        getTempKeyCancellationFromPath(history),
      );
    }

    const hasLatitudeLongitude =
      !isEmpty(values[LATITUDE_LABEL]) && !isEmpty(values[LONGITUDE_LABEL]);

    if (!hasLatitudeLongitude && values[LOCATION_LABEL]) {
      this.props.setSelectLocationLabel(values[LOCATION_LABEL] as string);
      this.props.applyLocation();
    }

    if (values[REFERENCE_NUMBER_LABEL]) {
      this.props.setReferenceNumber(values[REFERENCE_NUMBER_LABEL] as string);
    }

    if (values[CLIENT_CASH_LABEL]) {
      const clientCash = values[CLIENT_CASH_LABEL] as IClientCash;
      await this.props.setSelectedHotelSearchClientCash(clientCash);
      await this.props.setSelectedHotelReviewClientCash(
        clientCash?.selectedPropertyReviewClientCash,
      );
      await this.props.setSelectedHotelReviewClientCashStr(
        clientCash?.selectedPropertyReviewClientCash
          ? clientCash?.selectedPropertyReviewClientCash.toString()
          : '',
      );
    }

    if (values[CLIENT_CASH_CONDO_LABEL]) {
      const clientCash = values[CLIENT_CASH_CONDO_LABEL] as IClientCash;
      await this.props.setSelectedCondoSearchClientCash(clientCash);
      await this.props.setSelectedCondoReviewClientCash(
        clientCash?.selectedPropertyReviewClientCash,
      );
      await this.props.setSelectedCondoReviewClientCashStr(
        clientCash?.selectedPropertyReviewClientCash
          ? clientCash?.selectedPropertyReviewClientCash.toString()
          : '',
      );
    }

    if (values[AGENCY_NUMBER_LABEL]) {
      this.props.setAgencyNumber(values[AGENCY_NUMBER_LABEL] as string);
    }

    if (values[LIFE_STYLE_NAME_PARAM]) {
      await this.props.setLifeStyle(values[LIFE_STYLE_NAME_PARAM] as string);
    }

    if (values[ACCESS_TOKEN_LABEL]) {
      this.props.setAccessToken(values[ACCESS_TOKEN_LABEL] as string);
      localStorage.setItem(ACCESS_TOKEN_LABEL, values[ACCESS_TOKEN_LABEL] as string);
      UrlUtils.removeFromUrl(ACCESS_TOKEN_LABEL);
    } else {
      const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN_LABEL);
      if (!isEmpty(accessTokenStorage)) {
        this.props.setAccessToken(accessTokenStorage);
      }
    }

    if (values[LOCATION_OBJECT_LABEL]) {
      this.props.setSelectLocation(values[LOCATION_OBJECT_LABEL] as ILocation);
      this.props.applyLocation();
    } else if (hasLatitudeLongitude) {
      const latitude = toNumber(values[LATITUDE_LABEL] as string);
      const longitude = toNumber(values[LONGITUDE_LABEL] as string);
      try {
        const location = await getLocationByGeo(latitude, longitude, true);

        this.props.setSelectLocationLabel(location?.data?.name);
        this.props.setSelectLocation(location?.data);
        this.props.applyLocation();

        UrlUtils.removeFromUrl(LATITUDE_LABEL);
        UrlUtils.removeFromUrl(LONGITUDE_LABEL);
      } catch (e) {
        console.error(e);
      }
    }

    if (values[ROOMS_LABEL]) {
      this.props.setRooms(getNormalizedRooms(values[ROOMS_LABEL] as Array<IUrlRoom>));
      this.props.applyRooms();
    } else if (!isEmpty(values[ADULTS_LABEL]) || !isEmpty(values[CHILDS_LABEL])) {
      this.props.setRooms([
        {
          adultsCount: values[ADULTS_LABEL] ? toNumber(values[ADULTS_LABEL] as string) : 2,
          kids: Array(values[CHILDS_LABEL] ? toNumber(values[CHILDS_LABEL] as string) : 0).fill(
            0,
          ) as IGuest[],
        },
      ]);
      this.props.applyRooms();

      UrlUtils.removeFromUrl(ADULTS_LABEL);
      UrlUtils.removeFromUrl(CHILDS_LABEL);
    }

    if (values[SESSION_KEY_LABEL]) {
      this.props.setSessionKey(values[SESSION_KEY_LABEL] as ISessionKey);
    }

    if (values[FILTERS_LABEL]) {
      this.props.setFilters(values[FILTERS_LABEL] as IFiltersState);
    }

    if (values[CONDO_FILTERS_LABEL]) {
      this.props.setCondoFilters(values[CONDO_FILTERS_LABEL] as ICondoFiltersState);
    }

    if (!isTravCoding_V2) {
      if (values[DATES_LABEL]) {
        this.props.setDatesSelected(values[DATES_LABEL] as { startDate: string; endDate: string });
        this.props.applyDates();
      } else if (!isEmpty(values[CHECKIN_LABEL]) || !isEmpty(values[CHECKOUT_LABEL])) {
        this.props.setDatesSelected({
          startDate: !isEmpty(values[CHECKIN_LABEL]) ? (values[CHECKIN_LABEL] as string) : null,
          endDate: !isEmpty(values[CHECKOUT_LABEL]) ? (values[CHECKOUT_LABEL] as string) : null,
        });

        if (!isEmpty(values[CHECKIN_LABEL]) && !isEmpty(values[CHECKOUT_LABEL])) {
          this.props.applyDates();
        }

        UrlUtils.removeFromUrl(CHECKIN_LABEL);
        UrlUtils.removeFromUrl(CHECKOUT_LABEL);
      }
    }
  }

  renderLoading() {
    return (
      <div className="loading-container">
        <Loading size={LoadingSise.Medium} />
      </div>
    );
  }

  renderHeader = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    const isAdminPage = isAdminFromPath(this.props.history);
    const isThreeDS = isThreeDSFromPath(this.props.history);
    const isWidgetPage = isWidgetFromPath(this.props.history);
    const isErrorPage = isErrorFromPath(this.props.history);
    const isVacationRentals = isVacationRentalsFromPath(this.props.history);
    const isCondoWorldMap = isCondoWorldFromPath(this.props.history);
    const isTravCoding_V2 = account?.homePageTemplate === TRAVCODING_V2;
    const loggedUser = localStorage.getItem('LOGGED_USER_LABEL');
    const userDataStorage = loggedUser ? JSON.parse(Base64.decode(loggedUser)) : null;
    const userStorage = userDataStorage?.user;

    if (isTravCoding_V2) {
      if (userStorage?.accessToken) {
        return <LogInNav />;
      } else {
        return <Navbar />;
      }
    }

    if (isWidgetPage || isCondoWorldMap || isThreeDS) {
      return null;
    }

    if (isAdminPage) {
      return <AdminHeader />;
    }

    return (
      <Responsive>
        <Header isError={isErrorPage} isVacationRentals={isVacationRentals} />
      </Responsive>
    );
  };

  renderFooter = () => {
    const isAdminPage = isAdminFromPath(this.props.history);
    const isThreeDS = isThreeDSFromPath(this.props.history);
    const isErrorPage = isErrorFromPath(this.props.history);
    const isWidgetPage = isWidgetFromPath(this.props.history);
    const isCondoWorldMap = isCondoWorldFromPath(this.props.history);
    const { loginStore } = this.props;
    const { account } = loginStore;
    const isTravCoding_V2 = account?.homePageTemplate === TRAVCODING_V2;

    if (isTravCoding_V2) {
      return <TravFooter />;
    } else if (!isAdminPage && !isErrorPage && !isWidgetPage && !isCondoWorldMap && !isThreeDS) {
      return <Footer />;
    }

    return null;
  };

  handleExpireRedirect = () => {
    const { loginStore, menuStore } = this.props;
    const { account } = loginStore;

    const isPublicAccount = account?.type === LoginType.Public;
    const isPrivateWithLoginAccount = account?.type === LoginType.PrivateWithLogin;
    const isPrivateWithTokenAccount = account?.type === LoginType.PrivateWithToken;

    if (isPublicAccount) {
      window.location.reload();
    } else if (isPrivateWithLoginAccount) {
      this.props.logout();
      this.props.history.push(`/${account.name}${Routes.Login}`);
    } else if (isPrivateWithTokenAccount) {
      const logoutUrlMenu = menuStore?.items?.logoutUrl;
      const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
      const logoutUrlToken = get(DecodeTokenData(), 'logoutUrl', null);
      const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
      const logoutUrl = !isEmpty(logoutUrlStorage)
        ? logoutUrlStorage
        : !isEmpty(logoutUrlMenu)
        ? logoutUrlMenu
        : !isEmpty(logoutUrlToken)
        ? logoutUrlToken
        : logoutPref;

      Cookies.remove(COOKIE_TOKEN_NAME);
      Cookies.remove(COOKIE1_TOKEN_NAME);

      if (!isEmpty(logoutUrl)) {
        window.location.replace(logoutUrl);
      }
    }
  };

  renderContent = () => {
    const pathSplit = location.pathname.split('/');

    const isReservationCancellation =
      pathSplit[ONE] === REQUEST_CANCEL_RESERVATION || pathSplit[ONE] === RESERVATION_CANCELLATION;

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

    const { loginStore, menuStore } = this.props;
    const { account, user, userLanguage, isTokenExpired } = loginStore;
    const items = menuStore?.items;

    const faviconPref = getPreferenceFileUrlFromAccount(account, FAVICON_FIELD_KEY);
    const pageTitlePref = getPreferenceFromAccount(account, PAGE_TITLE_FIELD_KEY);

    const displayMarketingScriptFlag = process.env.REACT_APP_DISPLAY_MARKETING_SCRIP;

    const isCondo = isCondoEngineFromPath(this.props.history);
    const isSignIn = isSignInFromPath(this.props.history);
    const favicon = !isEmpty(faviconPref) ? faviconPref : FaviconImage;
    const isPublicAccount = account?.type === LoginType.Public;
    const locale = isCondo ? Locales.English : getLocale(userLanguage);
    const isTravCoding_V2 = account?.homePageTemplate === TRAVCODING_V2;

    const isRSITemplate = account?.isRSITemplate;
    const displayChat =
      isRSITemplate && items?.rsiId && !ORG_ID_TOEXCLUDE_CHAT.includes(items?.organizationId);

    return (
      <CookiesProvider>
        <ConfigProvider locale={getAntLocale(locale)}>
          <IntlProvider messages={getMessages(locale)} locale={locale} defaultLocale={locale}>
            <div className={`app ${isReservationCancellation ? 'cancellation' : ''}`}>
              <Favicon url={favicon} />
              <Helmet>
                {!isEmpty(pageTitlePref) ? <title>{pageTitlePref}</title> : null}
                {isRSITemplate && displayMarketingScriptFlag === 'true' ? (
                  <script type="text/javascript">
                    {`(function() {
                        window.sib = {
                          equeue: [],
                          client_key: "jyhjbffcot7rzilng6zj030p"
                        };

                        /* OPTIONAL: email for identify request*/
                        // window.sib.email_id = 'example@domain.com';
                        window.sendinblue = {};
                        for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
                          (function(k) {
                            window.sendinblue[k] = function() {
                              var arg = Array.prototype.slice.call(arguments);
                              (window.sib[k] || function() {
                                var t = {};
                                t[k] = arg;
                                window.sib.equeue.push(t);
                              })(arg[0], arg[1], arg[2], arg[3]);
                            };
                          })(j[i]);
                        }

                        var n = document.createElement("script"),
                            i = document.getElementsByTagName("script")[0];
                        n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
                      })();`}
                  </script>
                ) : null}

                {displayChat ? (
                  <script type="text/javascript">
                    {`var url_string = window.location.href;
                      var url = new URL(url_string);
                      var CA_DID = url.searchParams.get("ca_did");
                      var CA_APIKEY = url.searchParams.get("apiKey");
                      var CA_TITLE = url.searchParams.get("title");
                      var CA_THEME_COLOR = url.searchParams.get("themeColor");`}
                  </script>
                ) : null}
                {displayChat ? (
                  <script type="text/javascript">
                    {`var chatConfig={
                        "widgetConfig": {
                            "title": CA_TITLE,
                            "subtitle": "Chat",
                            "headerLogo": "https://ozonetel.com/wp-content/uploads/2022/03/O-Image.png.webp",
                            "showHeaderLogo": true,
                            "showAgentIcon": false,
                            "showAgentAvatar": false
                        },
                        "CA_SCRIPT": "https://crexendocx.com/ChatWidget/dist/caChatAPI.js",
                        "clientInfo": {
                            "DID": "8284453857",
                            "API_KEY": "KK7edafced1c078adc0856ff36a7111116"
                        },
                        "custDetails": {
                            "custName": "${user?.firstName} ${user?.lastName}",
                            "custMail": "${user?.email}",
                            "custPhone": "${items?.phone}",
                            "custId": "${user?.keyid}"
                        },
                        "theme": {
                            "primary": "#"+CA_THEME_COLOR,
                            "appHeader": {
                                "backgroundColor": "#FFFFFF",
                                "textColor": "#2F324A"
                            },
                            "conversation": {
                                "body": "#F1F2F9",
                                "messagePreview": "#ffff",
                                "text": "#2F324A",
                                "bot": {
                                    "backgroundColor": "#FFFFFF",
                                    "textColor": "#2F324A"
                                },
                                "user": {
                                    "backgroundColor": "rgba(59, 141, 247, 1)",
                                    "textColor": "#FFFFFF"
                                },
                                "interactiveButton": {
                                    "backgroundColor": "rgba(59, 141, 247, 1)",
                                    "textColor": "#FFFFFF"
                                }
                            }
                        },
                        "launcher": {
                            "type": "text",
                            "text": "Need Help? Chat with Us"
                        },
                        "userForm": [
                            {
                                "name": "Name",
                                "required": true,
                                "hide": false
                            },
                            {
                                "name": "Email",
                                "required": true,
                                "hide": false
                            },
                            {
                                "name": "CountryCode",
                                "required": false,
                                "hide": true
                            },
                            {
                                "name": "Phone",
                                "required": false,
                                "hide": false
                            },
                            {
                                "name": "Address",
                                "required": false,
                                "hide": true
                            }
                        ],
                    }`}
                  </script>
                ) : null}
                {displayChat ? (
                  <script
                    type="text/javascript"
                    src="https://crexendocx.com/ChatWidget/dist/chatWidget.js"
                  ></script>
                ) : null}
              </Helmet>

              {this.renderHeader()}
              <ScrollToTop />

              <ToastContainer
                position="top-right"
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              <Switch>
                <Route path={`/:accountUsername${Routes.Home}`} exact={true}>
                  <HomePage />
                </Route>

                <Route path={`/:accountUsername${Routes.Homes}`}>
                  <HomesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Experiences}`}>
                  <ExperiencesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Cruises}`} exact={true}>
                  <CruisesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Cars}`} exact={true}>
                  <CarsPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Cruises}/luxury`}>
                  <LuxuryCruisesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.AdminCondo}/:id`}>
                  <CondoDetailPage isFromAdminPage={true} />
                </Route>
                <Route path={`/:accountUsername${Routes.Getaway}`} exact={true}>
                  <GetawayPage />
                </Route>
                <Route path={`/:accountUsername${Routes.GetawayDetails}/:id`}>
                  <GetawayDetailsPage />
                </Route>
                <Route path={`/:accountUsername${Routes.GetawayCondo}`} exact={true}>
                  <GetawayCondoPage isInternal={false} />
                </Route>
                <Route path={`/:accountUsername${Routes.GetawayCondo}/:id`}>
                  <GetawayListPage isInternal={false} />
                </Route>

                <Route path={`/:accountUsername${Routes.GetawayCondos}`} exact={true}>
                  <GetawayCondoPage isInternal={true} />
                </Route>
                <Route path={`/:accountUsername${Routes.GetawayCondos}/:id`}>
                  <GetawayListPage isInternal={true} />
                </Route>

                <Route path={`/:accountUsername${Routes.Getaway}/:id`}>
                  <GetawayListPage isInternal={false} />
                </Route>
                <Route path={`/:accountUsername${Routes.GetawayBookingPage}`}>
                  <GetawayBookingPage />
                </Route>
                <Route path={`/:accountUsername${Routes.AllInclusive}`} exact>
                  <AllInclusivesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.AllInclusive}`} exact>
                  <AllInclusivesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.AllInclusive}/submit-request`}>
                  <SubmitRequestPage />
                </Route>
                <Route path={`/:accountUsername${Routes.AdminPage}`}>
                  <AdminPage />
                </Route>
                <Route path={`/:accountUsername${Routes.ReservationCancellation}`}>
                  <ReservationCancellation />
                </Route>
                <Route path={`/:accountUsername${Routes.RequestCancelReservation}`}>
                  <ReservationCancellation />
                </Route>
                <Route path={`/:accountUsername${Routes.AmericanLifestyle}`}>
                  <AmericanLifestylePage />
                </Route>
                <Route path={`/:accountUsername${Routes.ConciergeTeam}`}>
                  <ConciergeTravelTeamPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Resources}`}>
                  <ResourcesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Resources}`}>
                  <ResourcesPage />
                </Route>
                <Route path={`/:accountUsername${Routes.DailyBenefits}`}>
                  <DailyBenefitsPage />
                </Route>
                <Route path={`/:accountUsername${Routes.ContactUs}`}>
                  <ContactUsPage />
                </Route>
                <Route path={`/:accountUsername${Routes.MasterClassesRegister}`}>
                  <MasterClassesRegisterPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Error}`} exact={true}>
                  <ErrorPage />
                </Route>
                <Route path={`/:accountUsername${Routes.MemberStories}/:id`} exact={true}>
                  <MemberStoryPage />
                </Route>
                <Route path={`/:accountUsername${Routes.MemberVideos}`} exact={true}>
                  <MemberVideosPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Condo}`} exact={true}>
                  <Condominiums />
                </Route>
                <Route path={`/:accountUsername${Routes.Condo}/specials`}>
                  <CondoSpecialsPage />
                </Route>
                <Route path={`/:accountUsername${Routes.BrioRciHowTo}`}>
                  <BrioRciHowToPage />
                </Route>
                <Route path={`/:accountUsername${Routes.NewMembers}`}>
                  <NewMembersPage />
                </Route>
                <Route path={`/:accountUsername${Routes.EliteRciHowTo}`}>
                  <EliteRciHowToPage />
                </Route>
                <Route path={`/:accountUsername${Routes.Webinars}`}>
                  <WebinarsPage />
                </Route>

                <Route path={`/:accountUsername${Routes.PromotionDetails}`}>
                  <PromotionDetailsPage />
                </Route>
                <Route path={`/:accountUsername${Routes.PersonalInfo}`} exact={true}>
                  <PersonalInfoPage activeKey={UserAccountEnum.PersonalInfo} />
                </Route>
                <Route path={`/:accountUsername${Routes.Security}`} exact={true}>
                  <PersonalInfoPage activeKey={UserAccountEnum.Security} />
                </Route>
                <Route path={`/:accountUsername${Routes.PrivacyPolicy}`} exact={true}>
                  <Markdown messageId={MarkDownEnum.PrivacyPolicy} />
                </Route>
                <Route path={`/:accountUsername${Routes.PriceMatch}`}>
                  <Markdown messageId={MarkDownEnum.PriceMatch} />
                </Route>
                <Route path={`/:accountUsername${Routes.Faq}`}>
                  <Markdown messageId={MarkDownEnum.Faq} />
                </Route>

                <PrivateRoute path={`/:accountUsername${Routes.Reservations}`} exact={true}>
                  <ReservationsPage />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.RsiAccount}`}>
                  <AccountPage key="account" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.AccountReservations}`}>
                  <AccountPage key="account_reservation" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.RsiTestDriveManager}`}>
                  <AccountPage key="account_test_drive" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.RsiQuotes}/:id`}>
                  <AccountPage key="account_my_qoutes" />
                </PrivateRoute>

                <Route path={`/:accountUsername${Routes.Backoffice}`} exact={true}>
                  <IframeBackoffice />
                </Route>

                <Route path={`${Routes.AdminHotel}/:id`}>
                  <HotelDetailPage isFromAdminPage />
                </Route>

                <PrivateRoute path={`/:accountUsername${Routes.CondoSearch}`}>
                  <CondoPage />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.Condo}/:id`}>
                  <CondoDetailPage isFromAdminPage={false} />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.AdminCondo}/:id`}>
                  <CondoDetailPage isFromAdminPage={true} />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.UnitBook}/:id`}>
                  <CondoReviewBookPage />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.CondoSearch}/submit-request`}>
                  <SubmitRequestPage isCondoPage />
                </PrivateRoute>

                <PrivateRoute path={`/:accountUsername${Routes.Search}`} exact={true}>
                  <SearchPage key="hotels" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.Hotel}/:id`} exact={true}>
                  <HotelDetailPage key="hotels" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.Book}/:id/:packageId`} exact={true}>
                  <ReviewBookPage key="hotels" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.RequestCancelReservation}/:id`}>
                  <ReservationCancellation />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.ReservationCancellation}/:id`}>
                  <ReservationCancellation />
                </PrivateRoute>

                <PrivateRoute
                  path={`/:accountUsername${Routes.Search}/${VACATIONS_PAGE}`}
                  exact={true}
                >
                  <SearchPage isVacationRentals={true} key="vacations" />
                </PrivateRoute>
                <PrivateRoute
                  path={`/:accountUsername${Routes.Hotel}/:id/${VACATIONS_PAGE}`}
                  exact={true}
                >
                  <HotelDetailPage isVacationRentals={true} key="vacations" />
                </PrivateRoute>
                <PrivateRoute
                  path={`/:accountUsername${Routes.Book}/:id/:packageId/${VACATIONS_PAGE}`}
                  exact={true}
                >
                  <ReviewBookPage isVacationRentals={true} key="vacations" />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.SidekickRewards}`} exact={true}>
                  <SidekickPage />
                </PrivateRoute>
                <PrivateRoute path={`/:accountUsername${Routes.SidekickRewardsTerms}`} exact={true}>
                  <SidekickTermsPage />
                </PrivateRoute>
                <PrivateRoute
                  path={`/:accountUsername${Routes.SidekickRewardsPrivacy}`}
                  exact={true}
                >
                  <SidekickPrivacyPage />
                </PrivateRoute>

                <Route path={`/:accountUsername${Routes.ThreeDS}`} exact={true}>
                  <ThreeDS />
                </Route>

                <Route path={`/:accountUsername${Routes.Login}`} exact={true}>
                  <SignIn />
                </Route>
                <Route path={`/:accountUsername${Routes.Login}/:bookingID`} exact={true}>
                  <SignIn />
                </Route>
                <Route path={`/:accountUsername${Routes.SignUp}`} exact={true}>
                  <SignUp />
                </Route>
                <Route path={`/:accountUsername${Routes.SignUp}/:bookingID`} exact={true}>
                  <SignUp />
                </Route>

                <Route path={`/:accountUsername${Routes.VerifyUser}/:token`} exact={true}>
                  <VerifyUser />
                </Route>

                <Route path={`/:accountUsername${Routes.SendVerifyEmail}`} exact={true}>
                  <SendVerification />
                </Route>

                <Route path={`/:accountUsername${Routes.ForgotPassword}`} exact={true}>
                  <ForgotPassword />
                </Route>

                <Route path={`/:accountUsername${Routes.ResetPassword}/:token`} exact={true}>
                  <ResetPassword />
                </Route>

                <Route path={`/:accountUsername${Routes.ChangePassword}`} exact={true}>
                  <ChangePassword />
                </Route>

                <Route path={`${Routes.CondosMap}`} exact={true}>
                  <CondoWorldMap />
                </Route>

                <Route path={`${Routes.AdminLogin}`} exact={true}>
                  <SignIn />
                </Route>

                <PrivateAdminRoute path={`${Routes.AdminPage}`}>
                  <AdminPage />
                </PrivateAdminRoute>

                <Route path={Routes.Error}>
                  <ErrorPanel />
                </Route>
                <Route path="/:accountUsername">{isTravCoding_V2 && <NewApp />}</Route>
                <Route path="*">
                  <GenericNotFoundPage />
                </Route>
              </Switch>

              {this.renderFooter()}

              {!isSignIn && isTokenExpired ? (
                <ExpiredSessionModal
                  visible={true}
                  hasRedirect={!isPublicAccount}
                  onHandleRedirect={this.handleExpireRedirect}
                />
              ) : null}
            </div>
          </IntlProvider>
        </ConfigProvider>
      </CookiesProvider>
    );
  };

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    if (this.props.loginStore?.loading && account?.type !== LoginType.PrivateWithToken) {
      return this.renderLoading();
    } else {
      return this.renderContent();
    }
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectLocation: locationActions.setSelectLocation,
  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setDatesSelected: datesActions.setDatesSelected,
  applyDates: datesActions.applyDates,
  applyRooms: roomsActions.applyRooms,
  applyLocation: locationActions.applyLocation,
  setRooms: roomsActions.setRooms,
  setSessionKey: hotelsActions.setSessionKey,
  setReferenceNumber: hotelsActions.setReferenceNumber,
  setAgencyNumber: hotelsActions.setAgencyNumber,
  setCondoFilters: condoFiltersActions.setFilters,
  setFilters: filtersActions.setFilters,
  setSelectedHotelSearchClientCash: hotelsActions.setSelectedHotelSearchClientCash,
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,

  setAccessToken: loginActions.setAccessToken,
  setLifeStyle: loginActions.setLifeStyle,

  setSelectedCondoSearchClientCash: condosActions.setSelectedCondoSearchClientCash,
  setSelectedCondoReviewClientCash,
  setSelectedCondoReviewClientCashStr,

  logout,
  getLoginUserAccount,
  setLoginRedirect,
  getCountries,
  getAllCondoDestinations,
};

const ApplicationComponentWithRouter = withRouter(ApplicationComponent);
const ApplicationComponentCookies = withCookies(ApplicationComponentWithRouter);

export const App = connect(mapStateToProps, mapDispatchToProps)(ApplicationComponentCookies);
