import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingTabsEnum } from '@common-types';

export interface IAdminAllBookingsState {
  currentTab: BookingTabsEnum;
}

const initialState: IAdminAllBookingsState = {
  currentTab: BookingTabsEnum.Hotels,
};

const adminAllBookingsSlice = createSlice({
  name: 'admin-all-bookings',
  initialState,
  reducers: {
    setTab: (state: IAdminAllBookingsState, { payload }: PayloadAction<BookingTabsEnum>) => {
      state.currentTab = payload;
    },
  },
});

export const adminAllBookingsActions = adminAllBookingsSlice.actions;

export const adminAllBookingsReducer = adminAllBookingsSlice.reducer;
