import React from 'react';
import { connect } from 'react-redux';

import { IBenefitsState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { fetchMemberStories } from '@store/slices';
import { Responsive } from '@share/utils';
import { CompanyLogos, CustomGetaways, GetawayCondo, GetawayList, SkeletonGetaways } from '@components';

import { LandingCompanyLogos, TreatYourselfLogos } from '@common-types';
import { TravelLeader } from '../travel-leader';
import { ListClickableCards } from '../list-clickable-cards';
import { TutorialVideos } from '../tutorial-videos';
import { TreatYourself } from '../../../common';
import { MasterClasses } from '../master-classes';
import { Benefits } from '../benefits';
import { ConciergeServices } from '../concierge-services';

import './style.scss';
import { GetawaysLocationEnum, ISideKickStatus } from '@share/common-types';

interface IMapStateToProps {
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
}

interface IMapDispatchToProps {
  getMemberStories: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class HomeRsiComponent extends React.Component<IProps, null> {
  componentDidMount() {
    this.props.getMemberStories();
  }

  render(): React.ReactNode {
    const { menuStore, benefitsStore } = this.props;
    const { items } = menuStore;
    const { benefits } = benefitsStore;

    return (
      <div className="home-rsi-wrapper">
        <Responsive>
          <TravelLeader />
        </Responsive>

        {(benefits?.items && items?.isMLM && items?.sideKickStatus === ISideKickStatus.PARENT) ? (
          <GetawayList
            getaway={GetawaysLocationEnum.Staycation}
            condoSubcategory={null}
          
            isFromProperty
            isInternal
            isHome
          />) :
          <div style={{ width: '100%', padding: '20px' }}></div>}

        {!items?.isMLM ? (<Benefits />) : null}

        <ConciergeServices />

        <ListClickableCards />

        {!items?.isMLM ? (<TutorialVideos />) : null}

        <CompanyLogos logos={LandingCompanyLogos} />

        <TreatYourself
          customerServiceNumber={items?.customerServiceNumber}
          logos={TreatYourselfLogos}
        />

        <MasterClasses />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getMemberStories: fetchMemberStories };

export const HomeRsi = connect(mapStateToProps, mapDispatchToProps)(HomeRsiComponent);
