import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getBenefitInfo, getLocalURI, isExternalUrl, RootState } from '@share/utils';
import { BenefitsEnum, IBenefits } from '@share/common-types';
import './style.scss';
import { connect } from 'react-redux';
import { ILoginState } from '@share/store/slices';
import { get } from 'lodash';
import { SkeletonGetaways } from '../skeleton-getaways';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  benefits: IBenefits;

  noPaddingTop?: boolean;
  displayInternalOnly?: boolean;
  isFromHome?: boolean;
}

const ZERO = 0;

class CustomGetawaysComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { benefits, displayInternalOnly, noPaddingTop, loginStore, isFromHome } = this.props;
    const { account, loading } = loginStore;

    const benefitsInfo = getBenefitInfo(benefits, BenefitsEnum.Getaways);
    const childItemsInt: any[] = get(benefitsInfo, `[${ZERO}].childItems`);
    const childItemsIntMapped: any[] = childItemsInt?.map((c: any) => ({ ...c , isExternal: isExternalUrl(c?.url)}));
    const childItems: any[] = displayInternalOnly ? childItemsIntMapped?.filter((c: any) => !c?.isExternal) : childItemsIntMapped;
    
    if (loading && !benefits?.items) {
      return (<SkeletonGetaways />);
    }

    return (
      <div className={`custom__getaway ${noPaddingTop ? 'no-padding' : ''}`}>
        <div className="getaway__container">
          <h2 className="custom__getaway_title">
            <FormattedMessage id="custom.getaway.title" values={{ count: childItems?.length }} />
          </h2>
          <div className="custom__getaway_list">
            {childItems?.map(
              ({ title, description, imageUrl, url, isExternal }, index) => (
                isExternal ? (
                  <a key={`${index}-${title}`} href={url} target="_blank" rel="noreferrer" className={`custom__getaway_item ${isFromHome ? 'from-home' : ''}`}>
                    <img className={`custom__getaway_item-img ${isFromHome ? 'from-home' : ''}`} src={imageUrl} alt={title} />
                    <div className="custom__getaway_item-info">
                      <p className="custom__getaway_item-info_title"><FormattedMessage id={title} /></p>
                      <p className="custom__getaway_item-info_subtitle"><FormattedMessage id={description} /></p>
                    </div>
                  </a>) : (
                  <Link key={`${index}-${title}`} to={`/${account?.name}${getLocalURI(url)}`} className={`custom__getaway_item ${isFromHome ? 'from-home' : ''}`}>
                    <img className={`custom__getaway_item-img ${isFromHome ? 'from-home' : ''}`} src={imageUrl} alt={title} />
                    <div className="custom__getaway_item-info">
                      <p className="custom__getaway_item-info_title"><FormattedMessage id={title} /></p>
                      <p className="custom__getaway_item-info_subtitle"><FormattedMessage id={description} /></p>
                    </div>
                  </Link>)
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const CustomGetaways = connect(mapStateToProps)(CustomGetawaysComponent);
