import React from 'react'
import PaymentCard from './payment-card';
import { savedPaymentCardsData } from "../../../_mock-api's/index";

const SavedCards = () => (
    <div className="row g-4">
      {savedPaymentCardsData?.map((card, index) => (
        <div key={index} className="col-md-6">
          <PaymentCard
            brand={card.brand}
            number={card.number}
            validThru={card.validThru}
            cvv={card.cvv}
          />
        </div>
      ))}
    </div>
  );

export default SavedCards