import { detectCardType } from './booking';
import { v4 } from 'uuid';

// Email validation function
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Phone number validation function
export const validatePhoneNumber = (phoneNumber) => {
  const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  return phoneRegex.test(phoneNumber);
};

// Name validation function (only characters and not less than 2)
export const validateName = (name) => {
  const nameRegex = /^[A-Za-z]{2,13}$/;
  return nameRegex.test(name);
};

// Name on card validation function (only characters and not more than 14)
export const validateNameOnCard = (name) => {
  const nameRegex = /^[A-Za-z\s\-\.]{1,26}$/;
  return nameRegex.test(name);
};

// Debit card number validation function
function validateCardNumber(number) {
  // Trim spaces and non-digit characters from the input
  const sanitizedNumber = number.replace(/\D/g, '');

  // Ensure the number is within the typical credit card length range
  if (sanitizedNumber.length < 13 || sanitizedNumber.length > 16) {
    return false; // The card number is too short or too long
  }

  let sum = 0;
  let shouldDouble = false;

  // Use the Luhn Algorithm to validate the card number
  for (let i = sanitizedNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(sanitizedNumber[i], 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
}

// Security code validation function (only numbers and not more than 3)
export const validateSecurityCode = (code) => {
  const securityCodeRegex = /^[0-9]{3}$/;
  return securityCodeRegex.test(code);
};

// Address validation function (cannot be empty)
export const validateAddress = (address) => {
  return address.trim().length > 0;
};

// City validation function (only characters and not more than 30)
export const validateCity = (city) => {
  const cityRegex = /^[A-Za-z\s]{1,30}$/;
  return cityRegex.test(city);
};

export const validatePostalCode = (code) => {
  const postalCodeRegex = /^[0-9]{5}$/;
  return postalCodeRegex.test(code);
};
export const validateField = (fieldName, value) => {
  let isValid = true;
  let errorMessage = '';

  switch (fieldName) {
    case 'email':
      isValid = validateEmail(value);
      errorMessage = isValid ? '' : 'Please enter a valid email';
      break;
    case 'phone':
      isValid = validatePhoneNumber(value);
      errorMessage = isValid ? '' : 'Please enter mobile phone number';
      break;
    case 'givenName':
    case 'surname':
      isValid = validateName(value);
      errorMessage = isValid ? '' : 'Enter contact name, only Latin letters';
      break;
    case 'nameOnCard':
      isValid = validateNameOnCard(value);
      errorMessage = isValid
        ? ''
        : "Please enter the cardholder's name exactly as it appears on the card";
      break;
    case 'cardNumber':
      isValid = validateCardNumber(value);
      errorMessage = isValid
        ? ''
        : 'The credit card number must be at least 13 or 19 characters long, Please enter a valid card number';
      break;
    case 'cardSecurityCode':
      isValid = validateSecurityCode(value);
      errorMessage = isValid ? '' : 'Please enter a valid security code';
      break;
    case 'postalCode':
      isValid = validatePostalCode(value);
      errorMessage = isValid ? '' : 'Please enter a valid postal code';
      break;
    case 'billingAddress':
      isValid = validateAddress(value);
      errorMessage = isValid ? '' : 'Please enter address';
      break;
    case 'billingCity':
      isValid = validateCity(value);
      errorMessage = isValid ? '' : 'Please enter city';
      break;
    default:
      break;
  }

  return { isValid, errorMessage };
};

export const handleInputChange = (
  event: any,
  overallGuestIndex?: any,
  roomIndex?: number,
  setBookingInfo?: (info: any) => void,
  setErrors?: (errors: any) => void,
  searchHotels?: any,
  setCardType?: (type: string) => void
) => {
  const { name, value, type, checked } = event.target;
  const fieldValue = type === 'checkbox' ? checked : value;

  const formatCardNumber = (value) => {
    let sanitized = value.replace(/\D/g, '');
    let formatted = sanitized.match(/.{1,4}/g)?.join('-');
    if (formatted && formatted.length > 19) {
      formatted = formatted.substring(0, 19);
    }
    return formatted || '';
  };

  const formatPhoneNumber = (value) => {
    let sanitized = value.replace(/\D/g, '');
    let formatted = sanitized.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
    return formatted || '';
  };

  if (name === 'cardNumber') {
    const cardType = detectCardType(value);
    const formattedValue = formatCardNumber(value);
    setCardType(cardType);
    setBookingInfo((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  } else if (name === 'postalCode') {
    if (/^[0-9]{0,5}$/.test(value)) {
      setBookingInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  } else if (name === 'cardSecurityCode') {
    if (/^[0-9]{0,4}$/.test(value)) {
      setBookingInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  } else if (name === 'phone') {
    const formattedValue = formatPhoneNumber(value);
    setBookingInfo((prev) => {
      const newGuests = [...prev.guests];
      if (overallGuestIndex !== undefined) {
        newGuests[overallGuestIndex] = {
          ...newGuests[overallGuestIndex],
          [name]: formattedValue,
        };
        return {
          ...prev,
          guests: newGuests,
        };
      } else {
        return {
          ...prev,
          [name]: formattedValue,
        };
      }
    });
  } else {
    setBookingInfo((prev) => {
      const newGuests = [...prev.guests];
      if (overallGuestIndex !== undefined) {
        newGuests[overallGuestIndex] = {
          ...newGuests[overallGuestIndex],
          [name]: fieldValue,
        };
        return {
          ...prev,
          guests: newGuests,
        };
      } else {
        return {
          ...prev,
          [name]: fieldValue,
        };
      }
    });
  }

  const isFirstGuest =
    overallGuestIndex ===
    searchHotels.bookingCard.package.rooms
      .slice(0, roomIndex)
      .reduce((acc, room) => acc + room.adultsCount + room.kidsAges.length, 0);

  if (isFirstGuest || overallGuestIndex === undefined) {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (
        isFirstGuest &&
        overallGuestIndex !== undefined &&
        newErrors[roomIndex] &&
        newErrors[roomIndex][0]
      ) {
        newErrors[roomIndex][0][name] = undefined;
      } else {
        newErrors[name] = undefined;
      }
      return newErrors;
    });
  }
};
export const handleBlur = (event, overallGuestIndex, roomIndex, setErrors, searchHotels) => {
  const { name, value } = event.target;

  const isFirstGuest =
    overallGuestIndex ===
    searchHotels.bookingCard.package.rooms
      .slice(0, roomIndex)
      .reduce((acc, room) => acc + room.adultsCount + room.kidsAges.length, 0);

  if (!isFirstGuest && overallGuestIndex !== undefined) {
    return;
  }

  const { isValid, errorMessage } = validateField(name, value);

  setErrors((prevErrors) => {
    const newErrors = { ...prevErrors };
    if (isFirstGuest && overallGuestIndex !== undefined) {
      if (!newErrors[roomIndex]) {
        newErrors[roomIndex] = {};
      }
      if (!newErrors[roomIndex][0]) {
        newErrors[roomIndex][0] = {};
      }
      newErrors[roomIndex][0][name] = !isValid ? errorMessage : undefined;
    } else {
      newErrors[name] = !isValid ? errorMessage : undefined;
    }
    return newErrors;
  });
};

// Validate and submit form
export const validateAndSubmit = (
  bookingInfo,
  setErrors,
  searchHotels,
  mainGuest,
  inputRefs,
  submitInformation,
) => {
  const validationErrors: any = {};
  let firstInvalidFieldKey: any = null;

  if (!searchHotels?.bookingCard?.package?.rooms) {
    return;
  }

  searchHotels.bookingCard.package.rooms.forEach((room, roomIndex) => {
    const firstGuestIndex = searchHotels.bookingCard.package.rooms
      .slice(0, roomIndex)
      .reduce((acc, curr) => acc + curr.adultsCount + curr.kidsAges.length, 0);
    const guest = bookingInfo.guests[firstGuestIndex];

    if (guest) {
      if (!validateName(guest.givenName)) {
        validationErrors[roomIndex] = validationErrors[roomIndex] || {};
        validationErrors[roomIndex][0] = validationErrors[roomIndex][0] || {};
        validationErrors[roomIndex][0].givenName = 'Enter contact name, only Latin letters';
        if (!firstInvalidFieldKey) {
          firstInvalidFieldKey = {
            roomIndex,
            guestIndex: 0,
            field: 'givenName',
          };
        }
      }
      if (!validateName(guest.surname)) {
        validationErrors[roomIndex] = validationErrors[roomIndex] || {};
        validationErrors[roomIndex][0] = validationErrors[roomIndex][0] || {};
        validationErrors[roomIndex][0].surname = 'Enter contact name, only Latin letters';
        if (!firstInvalidFieldKey) {
          firstInvalidFieldKey = { roomIndex, guestIndex: 0, field: 'surname' };
        }
      }
      if (mainGuest.roomIndex === roomIndex && !validateEmail(guest.email)) {
        validationErrors[roomIndex] = validationErrors[roomIndex] || {};
        validationErrors[roomIndex][0] = validationErrors[roomIndex][0] || {};
        validationErrors[roomIndex][0].email = 'Please enter a valid email';
        if (!firstInvalidFieldKey) {
          firstInvalidFieldKey = { roomIndex, guestIndex: 0, field: 'email' };
        }
      }
      if (mainGuest.roomIndex === roomIndex && !validatePhoneNumber(guest.phone)) {
        validationErrors[roomIndex] = validationErrors[roomIndex] || {};
        validationErrors[roomIndex][0] = validationErrors[roomIndex][0] || {};
        validationErrors[roomIndex][0].phone = 'Please enter mobile phone number';
        if (!firstInvalidFieldKey) {
          firstInvalidFieldKey = { roomIndex, guestIndex: 0, field: 'phone' };
        }
      }
    } else {
      console.error(`Guest data is missing for room ${roomIndex}, guest ${firstGuestIndex}`);
    }
  });

  if (!validateNameOnCard(bookingInfo.nameOnCard)) {
    validationErrors.nameOnCard =
      "Please enter the cardholder's name exactly as it appears on the card";
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'nameOnCard' };
    }
  }
  if (!validateCardNumber(bookingInfo.cardNumber)) {
    validationErrors.cardNumber =
      'The credit card number must be between 13 and 16 digits long. Please enter a valid card number.';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'cardNumber' };
    }
  }

  if (!validateSecurityCode(bookingInfo.cardSecurityCode)) {
    validationErrors.cardSecurityCode = 'Please enter a valid security code';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'cardSecurityCode' };
    }
  }
  if (!validateAddress(bookingInfo.billingAddress)) {
    validationErrors.billingAddress = 'Please enter address';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'billingAddress' };
    }
  }
  if (!validateCity(bookingInfo.billingCity)) {
    validationErrors.billingCity = 'Please enter city';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'billingCity' };
    }
  }
  if (!validatePostalCode(bookingInfo.postalCode)) {
    validationErrors.postalCode = 'Please enter a valid postal code';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'postalCode' };
    }
  }
  if (!bookingInfo.billingCountry) {
    validationErrors.billingCountry = 'Please select a country';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'billingCountry' };
    }
  }

  if (!bookingInfo.cardExpirationYear) {
    validationErrors.cardExpirationYear = 'Please select year';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'cardExpirationYear' };
    }
  }

  if (!bookingInfo.cardExpirationMonth) {
    validationErrors.cardExpirationMonth = 'Please select month';
    if (!firstInvalidFieldKey) {
      firstInvalidFieldKey = { field: 'cardExpirationMonth' };
    }
  }

  setErrors(validationErrors);
  if (Object.keys(validationErrors).length === 0) {
    submitInformation();
  } else {
    if (firstInvalidFieldKey) {
      const { roomIndex, guestIndex, field } = firstInvalidFieldKey;
      const isGuest = roomIndex !== undefined && guestIndex !== undefined;
      if (isGuest) {
        let ref = inputRefs.dynamic?.[roomIndex]?.[guestIndex]?.[field];
        ref?.scrollIntoView({ behavior: 'smooth' });
      } else {
        const firstInvalidField = Object.keys(validationErrors).find((key) => inputRefs[key]);
        if (firstInvalidField) {
          inputRefs[firstInvalidField]?.current?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    }
  }
};

// Submit information
export const submitInformation = (
  bookingInfo,
  parsedSessionKey,
  parsedHotelId,
  parsedPackageId,
  searchHotels,
  fetchPackageWithCard,
  mainGuest,
  cardType
) => {
  const guestsWithAllocation = [];
  let guestIndex = 0;

  searchHotels.bookingCard.package.rooms.forEach((room, roomIndex) => {
    const roomGuests: any = Array.from({ length: room.adultsCount }).map(() => {
      const guest = {
        ...bookingInfo.guests[guestIndex],
        id: v4(),
        isMainGuest: mainGuest.roomIndex === roomIndex && guestIndex % room.adultsCount === 0,
        allocation: room.id,
        type: 'adult',
        givenName: bookingInfo.guests[guestIndex].givenName || 'TBV',
        surname: bookingInfo.guests[guestIndex].surname || 'TBV',
      };
      guestIndex++;
      return guest;
    });
    guestsWithAllocation.push(...roomGuests);
  });

  const payload = {
    sessionKey: {
      ...parsedSessionKey,
    },
    bookingPrice: searchHotels?.bookingCard?.package?.price,
    hotelId: parsedHotelId,
    leadPaxId: v4(),
    leadPaxRoomId: v4(),
    packageId: parsedPackageId,
    couponCode: '',
    marginator: {
      percentage: 0,
      commission: 0,
      impersonator: {
        memberId: 0,
      },
    },
    guests: guestsWithAllocation,
    referenceNumber: null,
    agencyNumber: null,
    currency: 'USD',
    card: {
      addressLine: bookingInfo.billingAddress,
      city: bookingInfo.billingCity,
      country: bookingInfo.billingCountry,
      state: bookingInfo.billingState,
      phone: bookingInfo.mobileNumber,
      zipCode: bookingInfo.postalCode,
      addPaymentMethod: false,
      id: null,
      holderName: bookingInfo.nameOnCard,
      cardNumber: bookingInfo.cardNumber,
      cardType,
      expireDate: `${bookingInfo.cardExpirationMonth}${bookingInfo.cardExpirationYear.slice(-2)}`,
      cvv: bookingInfo.cardSecurityCode,
    },
  };

  fetchPackageWithCard(payload);
};
