import React from 'react';
import parse from 'html-react-parser';

import { FormattedMessage } from 'react-intl';
import { IHotelDetails } from '@common-types';

import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

class PoliciesComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { hotel, refAnchor } = this.props;
    const { checkOutInformation = [], checkInInformation = [], pets = [], isVacationRental } = hotel;
    const isCheckIn = !!checkInInformation.length;
    const isCheckOut = !!checkOutInformation.length;
    const isPets = !!pets.length;

    return (
      (isCheckOut || isCheckIn || isPets) && (
        <div className="policies" ref={refAnchor}>
          {
            isVacationRental
              ?
                <div className="rooms-search__list-text vacation">
                  <div className="rooms-search__header" style={{ fontSize: '24px' }}><FormattedMessage id="tab.policies" /></div>
                </div>
              : <h4 className="policies__title"><FormattedMessage id="tab.policies" /></h4>
          }
      
          {isCheckIn && (
            <div className="policies__info">
              <p className="policies__point">
                <FormattedMessage id="check.in" />:
              </p>
              <div className="policies__info-wrapper">
                {checkInInformation.map((checkIn, index) => (
                  <div key={index} className="policies__info-text">
                    {parse(checkIn)}
                  </div>

                ))}
              </div>
            </div>
          )}
          {isCheckOut && (
            <div className="policies__info">
              <p className="policies__point">
                <FormattedMessage id="check.out" />:
              </p>
              <div className="policies__info-wrapper">
                {checkOutInformation.map((checkOut, index) => (
                  <div key={index} className="policies__info-text">
                    {parse(checkOut)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {isPets && (
            <div className="policies__info">
              <p className="policies__point">
                <FormattedMessage id="pets" />:
              </p>
              <div className="policies__info-wrapper">
                {pets.map((pet, index) => (
                  <div key={index} className="policies__info-text">
                    {parse(pet)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )
    );
  }
}

export const Policies = PoliciesComponent;
