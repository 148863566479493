import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@share/utils';
import { getHeaders, axiosInstance, getSelectedCurrency } from '@share/utils';
import { Urls } from '@share/constants';
import { IHotelPopularDestinationsOffers } from '@common-types';
import { LocationsTypes } from '@share/common-types';

export interface IPopularDestinationsOffersState {
  popularDestinationsOffers: IHotelPopularDestinationsOffers[];
  loading: boolean;
  error: string;
}

const initialState: IPopularDestinationsOffersState = {
  popularDestinationsOffers: null,
  loading: false,
  error: '',
};

const popularDestinationsOffersSlice = createSlice({
  name: 'popularDestinationsOffers',
  initialState,
  reducers: {
    setLoading: (state: IPopularDestinationsOffersState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IPopularDestinationsOffersState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPopularDestinationsOffers: (
      state: IPopularDestinationsOffersState,
      { payload }: PayloadAction<IHotelPopularDestinationsOffers[]>,
    ) => {
      state.popularDestinationsOffers = payload;
    },
  },
});

export const popularDestinationsOffersActions = popularDestinationsOffersSlice.actions;
export const popularDestinationsOffersReducer = popularDestinationsOffersSlice.reducer;

export const getHotelPopularDestinationsOffers = (account: any): AppThunk => {
  return async (dispatch) => {
    dispatch(popularDestinationsOffersActions.setLoading(true));

    try {   
      const res = await axiosInstance.get(`${Urls.HotelTopDeals}/${getSelectedCurrency(account)}`, { ...getHeaders(), });
      dispatch(popularDestinationsOffersActions.setPopularDestinationsOffers(res.data.topDeals));    
      dispatch(popularDestinationsOffersActions.setLoading(false));
    } catch (error) {
      dispatch(popularDestinationsOffersActions.setError(error.toString()));
      dispatch(popularDestinationsOffersActions.setLoading(false));
    }
  };
};
