import React from 'react';

import { LoadingSise } from '@common-types';

import LoadingImage from '@assets/images/loading.gif';

import './style.scss';

interface IProps {
  size: LoadingSise;
  className?: string;
}

export class Loading extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const className = `loading-image-container ${this.props.className ? this.props.className : ''} ${this.props.size ? this.props.size : LoadingSise.Normal}`;
    return (
      <img src={LoadingImage} className={className} alt="loading..." />
    );
  }
}
