import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { IAutocompleteResult, MatchedByEnum } from '@common-types';
import {
  IAdminBookingsState,
  adminBookingsActions,
  getAdminBookingsSearch,
  getAdminBookings,
} from '@store/slices';
import {
  AdminBookingsSearch,
  IAutocompleteGroupedItem,
  TAutocompleteGroupConfigs,
  mapAutocompleteGroups,
} from '../admin-booking-search';
import './style.scss';

const autocompleteGroupConfig: TAutocompleteGroupConfigs[] = [
  {
    label: 'rsi.booking.order.ids',
    name: MatchedByEnum.OrderId,
  },
  {
    label: 'supplier.booking',
    name: MatchedByEnum.ConfirmationNumber,
  },
  {
    label: 'member.ID',
    name: MatchedByEnum.MemberId,
  },
  {
    label: 'card.info.column',
    name: MatchedByEnum.CardInfo,
  },
  {
    label: 'member.name',
    name: MatchedByEnum.MemberInfo,
  },
  {
    label: 'guest.name',
    name: MatchedByEnum.GuestInfo,
  },
  {
    label: 'condo.name.column',
    name: MatchedByEnum.CondoName,
  },
];

interface IMapStateToProps {
  adminBookingsStore: IAdminBookingsState;
}

interface IMapDispatchToProps {
  setSearch: (search: string) => void;
  setAutocompleteResults: (results: IAutocompleteResult[]) => void;
  setSelectedSearchItem: (result: IAutocompleteResult) => void;
  getAdminBookingsSearch: (search: string) => void;
  setIsSearchLoading: (loading: boolean) => void;
  getAdminBookings: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class CondoBookingsSearchComponent extends React.Component<IProps> {
  getGroupedResult = (): IAutocompleteGroupedItem[] => {
    const { adminBookingsStore } = this.props;
    const { autocompleteResults } = adminBookingsStore;

    return mapAutocompleteGroups(autocompleteResults, autocompleteGroupConfig);
  };

  render(): React.ReactNode {
    const { adminBookingsStore } = this.props;

    return (
      <AdminBookingsSearch
        placeholderId="enter.supplier.booking"
        searchByPlaceholderId="search.by.placeholder.condos"
        setSearch={this.props.setSearch}
        setAutocompleteResults={this.props.setAutocompleteResults}
        setSelectedSearchItem={this.props.setSelectedSearchItem}
        getBookingsSearch={this.props.getAdminBookingsSearch}
        setIsSearchLoading={this.props.setIsSearchLoading}
        getAdminBookings={this.props.getAdminBookings}
        autocompleteItems={adminBookingsStore.autocompleteResults}
        selectedSearchItem={adminBookingsStore.selectedSearchItem}
        search={adminBookingsStore.search}
        isSearchLoading={adminBookingsStore.isSearchLoading}
        groupedItems={this.getGroupedResult()}
      />
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingsStore: state.adminBookingsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSearch: adminBookingsActions.setSearch,
  setAutocompleteResults: adminBookingsActions.setAutocompleteResults,
  setSelectedSearchItem: adminBookingsActions.setSelectedSearchItem,
  setIsSearchLoading: adminBookingsActions.setIsSearchLoading,
  getAdminBookingsSearch,
  getAdminBookings,
};

export const CondoBookingsSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondoBookingsSearchComponent);
