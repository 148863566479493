import React from 'react';
import { RefSelectProps, SelectValue } from 'antd/lib/select';
import Highlighter from 'react-highlight-words';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { AutoComplete, Spin, Select } from 'antd';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import find from 'lodash/fp/find';
import { ENTER_KEY } from '@share/constants';
import { MatchedByEnum } from '@common-types';
import { ClearSvg, SearchSvg } from '@share/assets';
import { ISearchAutocomplete, IAutocompleteGroupedItem } from './interfaces';

import './style.scss';

interface IProps extends WrappedComponentProps {
  setSearch: (search: string) => void;
  setAutocompleteResults: (results: ISearchAutocomplete[]) => void;
  setSelectedSearchItem: (result: ISearchAutocomplete) => void;
  getBookingsSearch: (search: string) => void;
  setIsSearchLoading: (loading: boolean) => void;
  getAdminBookings: () => void;
  autocompleteItems: ISearchAutocomplete[];
  selectedSearchItem: ISearchAutocomplete;
  search: string;
  isSearchLoading: boolean;
  groupedItems: IAutocompleteGroupedItem[];
  placeholderId: string;
  searchByPlaceholderId: string;
}

interface IState {
  isFocused: boolean;
  isDropdownOpen: boolean;
}

const MIN_SEARCH_LENGTH = 3;
const LIST_HEIGHT = 220;
const SEARCH_DEBOUNCE = 200;
const { Option, OptGroup } = Select;

class AdminBookingsSearchComponent extends React.Component<IProps, IState> {
  selectRef: React.RefObject<RefSelectProps> = React.createRef();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  lastSearch = '';
  lastSelectedResult: ISearchAutocomplete = null;
  state: IState = {
    isFocused: false,
    isDropdownOpen: false,
  };

  onFocus = () => {
    const { isFocused } = this.state;

    if (!isFocused) {
      this.onSearch(this.props.search || '');
    }

    this.setState({ isFocused: true, isDropdownOpen: true });
  };

  onSearch = (search: string) => {
    this.lastSearch = search;
    this.setState({ isDropdownOpen: true });

    if (search.length >= MIN_SEARCH_LENGTH) {
      this.debouncedSearch();
      this.props.setIsSearchLoading(true);
    } else {
      this.props.setAutocompleteResults([]);
    }

    this.props.setSearch(search);
  };

  onChange = (value: SelectValue) => {
    const {
      autocompleteItems,
      setSearch,
      setAutocompleteResults,
      getAdminBookings,
      setSelectedSearchItem,
    } = this.props;
    const result = autocompleteItems.find(({ id }) => id === value);

    if (!value) {
      this.setState({ isDropdownOpen: true, isFocused: true });
    } else {
      this.setState({ isDropdownOpen: false, isFocused: true });
    }

    setSelectedSearchItem(result);
    setAutocompleteResults([]);
    this.lastSelectedResult = result;

    if (result) {
      setSearch(`${result[result.matchedBy as keyof ISearchAutocomplete] as string}`);
      getAdminBookings();
    }

    if (!value) {
      setSearch('');
      getAdminBookings();
    }
  };

  debouncedSearch = debounce(() => {
    this.props.getBookingsSearch(this.lastSearch);
  }, SEARCH_DEBOUNCE);

  focusSelect = () => {
    if (this.selectRef && this.selectRef.current) {
      this.selectRef.current.focus();
    }
  };

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target as Node) &&
      !(event.target as HTMLElement).closest('.ant-select-clear')
    ) {
      this.setState({ isFocused: false, isDropdownOpen: false });
      this.props.setAutocompleteResults([]);
      this.lastSearch = '';
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getPopupContainer = () => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  getNotFoundContent = (): React.ReactNode => {
    const { search, isSearchLoading, searchByPlaceholderId } = this.props;
    let message: React.ReactNode;

    if (search?.length < MIN_SEARCH_LENGTH) {
      message = <FormattedMessage id={searchByPlaceholderId} />;
    } else if (isSearchLoading) {
      message = <FormattedMessage id="searching" />;
    } else {
      message = <FormattedMessage id="no.results.found.try.another.keyword" />;
    }

    return <div className="all-bookings-search__nothing-found">{message}</div>;
  };

  getDropdown = (menu: React.ReactElement): React.ReactElement => {
    return <div className="all-bookings-search__dropdown">{menu}</div>;
  };

  getOptionGroup = (
    array: ISearchAutocomplete[],
    label: string,
    name: string,
  ): React.ReactNode | null => {
    const nonDuplicateArray = uniqBy(array, name);

    return array.length ? (
      <OptGroup key={name} label={<FormattedMessage id={label} />}>
        {nonDuplicateArray.map((result: ISearchAutocomplete) => {
          return (
            <Option key={result.id} value={result.id} className="all-bookings-search__option">
              <span className="all-bookings-search__location-name">
                <Highlighter
                  highlightClassName="blue-text"
                  searchWords={[this.lastSearch.trim()]}
                  autoEscape={true}
                  textToHighlight={`${
                    result[result.matchedBy as keyof ISearchAutocomplete] as string
                  }`}
                />
              </span>
            </Option>
          );
        })}
      </OptGroup>
    ) : null;
  };

  onClick = (event: React.MouseEvent<HTMLElement>) => {
    const { selectedSearchItem, setAutocompleteResults } = this.props;
    const { isFocused, isDropdownOpen } = this.state;

    if (
      selectedSearchItem &&
      isFocused &&
      !isDropdownOpen &&
      event.target &&
      (event.target as HTMLElement).classList.contains('ant-select-selection-search-input')
    ) {
      this.onSearch(
        `${selectedSearchItem[selectedSearchItem.matchedBy as keyof ISearchAutocomplete]}`,
      );
    } else if (
      (event.target as HTMLElement).classList.contains('ant-select-item-option-selected') ||
      (event.target as HTMLElement).closest('.ant-select-item-option-selected')
    ) {
      if (this.lastSelectedResult) {
        this.setState({ isDropdownOpen: false, isFocused: true });
        setAutocompleteResults([]);
      }
    }
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    const { isDropdownOpen, isFocused } = this.state;
    const { autocompleteItems, setSelectedSearchItem, setAutocompleteResults, setSearch } =
      this.props;

    if (
      e.code === ENTER_KEY &&
      isDropdownOpen &&
      isFocused &&
      autocompleteItems?.length &&
      !this.lastSelectedResult
    ) {
      const item =
        getItemWithOrderId(autocompleteItems) || getItemWithConfirmation(autocompleteItems);

      this.setState({ isDropdownOpen: false, isFocused: true });
      setSearch(`${item[item.matchedBy as keyof ISearchAutocomplete] as string}`);
      setSelectedSearchItem(item);
      setAutocompleteResults([]);
      this.lastSelectedResult = item;
    }
  };

  render(): React.ReactNode {
    const { isDropdownOpen, isFocused } = this.state;
    const { intl, isSearchLoading, search, groupedItems, placeholderId } = this.props;

    return (
      <div className={`all-bookings-search ${isFocused ? 'selected' : ''}`} ref={this.wrapperRef}>
        <AutoComplete
          onKeyDown={this.onKeyDown}
          virtual={false}
          showSearch={true}
          placeholder={intl.formatMessage({ id: placeholderId })}
          onChange={this.onChange}
          onSearch={this.onSearch}
          onFocus={this.onFocus}
          showArrow={false}
          ref={this.selectRef}
          open={isDropdownOpen}
          defaultActiveFirstOption={false}
          filterOption={() => true}
          autoClearSearchValue={false}
          dropdownRender={this.getDropdown}
          getPopupContainer={this.getPopupContainer}
          listHeight={LIST_HEIGHT}
          allowClear={true}
          value={search}
          notFoundContent={this.getNotFoundContent()}
          onClick={this.onClick}
          clearIcon={
            <span className="all-bookings-search__clear">
              {isSearchLoading && isDropdownOpen ? <Spin /> : <ClearSvg />}
            </span>
          }
        >
          {groupedItems.map(({ autocompleteItems, label, name }) =>
            this.getOptionGroup(autocompleteItems, label, name),
          )}
        </AutoComplete>
        <div className="all-bookings-search__search-icon" onClick={() => this.focusSelect()}>
          <SearchSvg />
        </div>
      </div>
    );
  }
}

const getItemWithOrderId = find<ISearchAutocomplete>({ matchedBy: MatchedByEnum.OrderId });
const getItemWithConfirmation = find<ISearchAutocomplete>({
  matchedBy: MatchedByEnum.ConfirmationNumber,
});

export const AdminBookingsSearch = injectIntl(AdminBookingsSearchComponent);
