import React from "react";
import { Skeleton } from "antd";

const BookingSummarySkeleton = () => {
  return (
    <div className="row g-4">
      <div className="col-md-6 col-xl-12">
        <div className="card shadow rounded-2">
          <div className="card-header border-bottom">
            <Skeleton active={true} paragraph={false} />
          </div>
          <div className="card-body">
            <ul className="list-group list-group-borderless">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div className="skeleton-bar-width">
                  <Skeleton active={true} paragraph={false} />
                </div>
                <div className="skeleton-bar-width">
                  <Skeleton active={true} paragraph={false} />
                </div>
              </li>

              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div className="skeleton-bar-width">
                  <Skeleton active={true} paragraph={false} />
                </div>
                <div className="skeleton-bar-width">
                  <Skeleton active={true} paragraph={false} />
                </div>
              </li>
              <hr className="m-0 my-1 mb-2" />
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div className="skeleton-bar-width">
                  <Skeleton active={true} paragraph={false} />
                </div>
                <div className="skeleton-bar-width">
                  <Skeleton active={true} paragraph={false} />
                </div>
              </li>
            </ul>
          </div>
          <div className="card-footer border-top">
            <div className="d-flex justify-content-between align-items-center">
              <div className="skeleton-bar-width">
                <Skeleton active={true} paragraph={false} />
              </div>
              <div className="skeleton-bar-width">
                <Skeleton active={true} paragraph={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSummarySkeleton;
