import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FilterCheckbox, FilterRadio } from '@components';
import { BlueButton } from '@share/components';
import { IFilterOption } from '@common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


import './style.scss';
import { get } from 'lodash';

export enum Aligment {
  Right,
  Left
};

interface IProps {
  display: boolean;
  title: string;
  dropdownDisable: boolean;
  dropdownTitle: string;

  values: string[];
  counters: any[];

  aligment?: Aligment;

  singleValue?: boolean;
  displayZero?: boolean;

  counterConverter: (counter: any) => IFilterOption;
  onChange: (value: string[]) => void;
  onDoneClick: () => void;
}

interface IState {
  isFilterOpen: boolean;
  valuesOriginal: string[];
}

class CondoResultFilterComponent extends React.Component<IProps, IState> {
  state: IState = { isFilterOpen: false, valuesOriginal: null };
  filterWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleFilter = () => {
    this.setState((prevState: IState) => ({ isFilterOpen: !prevState.isFilterOpen, valuesOriginal: !prevState.isFilterOpen ? [...this.props.values] : null }));
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      this.state.isFilterOpen &&
      this.filterWrapperRef &&
      !this.filterWrapperRef.current.contains(event.target as Node)
    ) {
      const refresh = this.state.isFilterOpen ? JSON.stringify(this.props.values) !== JSON.stringify(this.state.valuesOriginal) : false;
      const valuesOriginal = refresh ? [...this.state.valuesOriginal] : null;
      this.toggleFilter();
      if (refresh) {
        this.props.onChange(valuesOriginal);
      }
    }
  };

  render(): React.ReactNode {
    const { isFilterOpen } = this.state;
    const { singleValue, aligment, display, title, dropdownTitle, dropdownDisable, values, counters, displayZero, onDoneClick } =this.props;

    if (!display) {
      return null;
    }
    
    return (
      <div className={`condo-result-filter__fast-filter-wrapper ${aligment === Aligment.Right ? 'right-aligment' : ''}`}>
        <div
          className={`condo-result-filter__fast-filter-top ${isFilterOpen ? 'open' : ''} ${values?.length ? 'selected' : ''}`}
          onClick={this.toggleFilter}
        >
          <div className={`condo-result-filter__fast-filter-top-tittle ${values?.length ? 'selected' : ''}`}>
            <FormattedMessage id={title} />
          </div>
          {isFilterOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </div>
        <div
          ref={this.filterWrapperRef}
          className={`condo-result-filter__fast-filter-dropdown ${isFilterOpen ? 'open' : ''}`}
        >
          <div className="condo-result-filter__fast-filter-dropdown-top">
            {!singleValue ? (
              <FilterCheckbox
                disabled={dropdownDisable}
                onChange={this.props.onChange}
                values={values}
                options={counters?.map((counter: any) => this.props.counterConverter(counter))}
                title={dropdownTitle}
                displayZero={displayZero}
              />) : (
              <FilterRadio
                disabled={dropdownDisable}
                onChange={this.props.onChange}
                value={get(values, '[0]')}
                options={counters?.map((counter: any) => this.props.counterConverter(counter))}
                title={dropdownTitle}
                displayZero={displayZero}
              />)}
          </div>

          {onDoneClick ? (
            <div className="condo-result-filter__fast-filter-dropdown-bottom">
              <BlueButton
                onClick={() => {
                  this.toggleFilter();
                  onDoneClick();
                }}
              >
                <FormattedMessage id="done" />
              </BlueButton>
            </div>) : null}
        </div>
      </div>
    );
  }
}

export const CondoResultFilter = connect()(CondoResultFilterComponent);
