import React from 'react';

import { BlueButton } from '@share/components';

import { PlusSvg, MinusSvg } from '@share/assets';

import './style.scss';

interface IProps {
  add: () => void;
  minus: () => void;
  count: number;
  disabled?: boolean;
  min: number;
  max: number;
}

export class Counts extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { count, add, minus, min, max, disabled = false } = this.props;

    return (
      <div className="guest-count">
        <div className="guest-count__button">
          <BlueButton onClick={() => minus()} disabled={count <= min || disabled}>
            <MinusSvg />
          </BlueButton>
        </div>
        <div className="guest-count__count">{count}</div>
        <div className="guest-count__button">
          <BlueButton onClick={() => add()} disabled={count >= max || disabled}>
            <PlusSvg />
          </BlueButton>
        </div>
      </div>
    );
  }
}
