import React from 'react';
import parse from 'html-react-parser';

import { FormattedMessage } from 'react-intl';
import { IHotelDetails } from '@common-types';

import './style.scss';
import { isEmpty } from 'lodash';
import { Progress } from 'antd';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

export class Reviews extends React.Component<IProps, null> {

  renderRatingMessage = (value: number) => {
    if (value === 5) {
      return <FormattedMessage id="reviews.excellent" />
    }
    if (value < 5 && value >= 4) {
      return <FormattedMessage id="reviews.very.good" />
    }
    if (value < 4 && value >= 3) {
      return <FormattedMessage id="reviews.good" />
    }
    if (value < 3 && value >= 2) {
      return <FormattedMessage id="reviews.ok" />
    }
    if (value < 2 && value >= 1) {
      return <FormattedMessage id="reviews.poor" />
    }
    if (value < 1) {
      return <FormattedMessage id="reviews.terrible" />
    }

    return null;
  }

  renderItem = (value: string, title: string) => {
    if (!isEmpty(value)) {
      const valueNumber = parseFloat(value);
      const percent = valueNumber / 5 * 100;
      return (
        <div className="col-md-4 guest-ratings__reviews-rating-item">
          <label className="rating">{value}/5 <label>{this.renderRatingMessage(valueNumber)}</label></label>
          <label className="title"><FormattedMessage id={title} /></label>
          <Progress percent={percent} showInfo={false} />
        </div>
      );
    }
    return null;
  }

  render(): React.ReactNode {
    const { hotel, refAnchor } = this.props;

    return !!hotel.guestRatings ? (
      <div ref={refAnchor} className="guest-ratings">
        <h4 className="guest-ratings__title">
          <FormattedMessage id="reviews.guest.ratings.title" />
        </h4>
        <div className="guest-ratings__reviews-wrapper">
          <div className="guest-ratings__reviews-rating">{hotel.guestRatings.overall}/5 {this.renderRatingMessage(parseFloat(hotel.guestRatings.overall))}</div>
          <div className="guest-ratings__reviews-overall"><FormattedMessage id="overall" /></div>
          <div className="container-fluid">
            <div className="row">
              {this.renderItem(hotel.guestRatings.amenities, 'reviews.amenities')}
              {this.renderItem(hotel.guestRatings.cleanliness, 'reviews.cleanliness')}
              {this.renderItem(hotel.guestRatings.comfort, 'reviews.comfort')}
              {this.renderItem(hotel.guestRatings.condition, 'reviews.condition')}
              {this.renderItem(hotel.guestRatings.neighborhood, 'reviews.neighborhood')}
              {this.renderItem(hotel.guestRatings.service, 'reviews.service')}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
