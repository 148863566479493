import React from 'react';
import { AdminPanel } from '@components';
import './style.scss';

interface IProps {}

export default class AdminPage extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="admin-page">
        <AdminPanel />
      </div>
    );
  }
}
