import React from 'react';

import { PersonalInfoWrapper } from '@components';

import './style.scss';

interface IProps {
  activeKey: string;
}

export class PersonalInfoPage extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return <PersonalInfoWrapper activeKey={this.props.activeKey} />;
  }
}
