export enum Routes {
  Search = '/search',
  Backoffice = '/backoffice',
  Home = '/home',
  Homes = '/homes',
  Experiences = '/experiences',
  // TODO: Change route search to route hotel on the entire site
  ThreeDS = '/three-ds',
  Error = '/error',
  BasicError = '/error/generic',
  CustomError = '/error/message',
  NotAuthorized = '/error/not-authorized',
  NotFound = '/error/404',
  Cruises = '/cruise',
  Cars = '/car',
  Getaway = '/getaway',
  GetawayCondo = '/getaway/condo',
  GetawayCondos = '/getaway/condos',
  GetawayList = '/getaway/list',
  GetawayDetails = '/getaway/details',
  GetawayBookingPage = '/getaway-booking',
  SearchResult = '/result',
  Hotel = '/hotel',
  AdminHotel = '/bookings-admin-hotel',
  AllInclusive = '/all-inclusives',
  AllInclusivesSubmitRequest = '/all-inclusives/submit-request',
  Book = '/book',
  Condo = '/condo',
  CondosMap = '/condos/world-map',
  Widget = '/widget',
  SearchHotelWidget = '/widget/search/hotel',
  CondoSearch = '/condo/deals',
  AdminCondo = '/bookings-admin-condo',
  UnitBook = '/unit-book',
  AdminPage = '/admin-page',
  AdminLogin = '/admin-page/signin',
  Login = '/signin',
  Logout = '/signout',
  SignUp = '/signup',
  VerifyUser = '/verify',
  SendVerifyEmail = '/send-verify',
  ForgotPassword = '/password/forgot',
  ResetPassword = '/password/reset',
  ChangePassword = '/password/change',
  Accounts = '/admin-page/accounts',
  Account = '/admin-page/account',
  PriceRules = '/admin-page/business-rules/price-rules',
  PresentationRules = '/admin-page/business-rules/presentation-rules',
  AllBookings = '/admin-page/all-bookings',
  BookingDetails = '/admin-page/all-bookings/booking',
  OrderDetails = '/admin-page/all-bookings/order',
  BusinessRules = '/business-rules',
  NewRule = '/admin-page/business-rules/price-rules/new-rule',
  NewPresentationRule = '/admin-page/business-rules/presentation-rules/new-rule',
  ActivityUsers = '/admin-page/business-rules/price-rules/activity',
  AdminHotelBookingDetails = '/admin-page/all-bookings/hotel-booking',
  RequestCancelReservation = '/request-cancel-reservation',
  ReservationCancellation = '/reservation-cancellation',  
  AmericanLifestyle = '/magazine',
  ConciergeTeam = '/concierge-travel-team',
  MemberStories = '/member/stories',
  Resources = '/travel-resources',
  DailyBenefits = '/daily-benefits',
  MemberVideos = '/member/videos',
  ContactUs = '/contact-us',
  MasterClassesRegister = '/master-classes-register',
  PersonalInfo = '/personal-info',
  Security = '/security',
  PromotionDetails = '/promotion',
  PrivacyPolicy = '/privacy-policy',
  PriceMatch = '/price-match',
  BrioRci = '/brio-rci',
  BrioRciHowTo = '/brio-rci/how-to',
  NewMembers = '/newmembers',
  EliteRciHowTo = '/elite-rci/how-to',
  Webinars = '/webinars',
  Faq = '/faq',
  Reservations = '/reservations',
  RsiAccount = '/account',
  AccountReservations = '/account/reservations',
  RsiTestDriveManager = '/account/test-drive-manage',
  RsiQuotes = '/account/quotes',
  SidekickRewards = '/sidekick-rewards',
  SidekickRewardsTerms = '/sidekick-rewards/terms',
  SidekickRewardsPrivacy = '/sidekick-rewards/privacy',
}

export enum Urls {
  Users = '/users',
  UsersCards = '/users/paymentmethod',
  SSO = '/sso',
  Accounts = '/account',
  Hotels = '/Hotels/search/page',
  Locations = '/Locations',
  NavigationItems = '/account/menu',
  Suppliers = '/suppliers',
  HotelDetails = '/hotels/hotel',
  BookingSummary = '/hotels/hotel/booking-summary',
  BookPackageWithCard = '/hotels/book-package-with-card',
  BookPackageWithCardResume = '/hotels/book-resume',
  HotelsMap = '/hotels/search/area',
  HotelRedeemCode = '/hotels/redeem-code',
  HotelRemoveCode = '/hotels/cancel-redeem-code',
  HotelAutocomplete = '/hotels/hotel/autocomplete',
  reservationCancellationDetails = '/bookings/reservation-details',
  cancelReservation = '/bookings/cancel-reservation',
  requestCancelReservation = '/bookings/request-cancel',
  HotelsRecentSearches = '/hotels/recent-searches',
  HotelTopDeals = '/hotels/top-deals',
  HotelRooms = '/hotels/search/id',

  WalletUser = '/wallet/byuserId',

  Quotes = '/deals/quote',

  getReservations = '/bookings/browsebookingbyuser',
  getContentByPropertyId = '/bookings/content',
  reservationsSearchText = '/bookings/bookingsearch',
  reservationsCancel = '/bookings/cancel',
  reservationsSendVoucher = '/bookings/voucher',
  QuotesHontelsDownload = '/bookings/quotes/download',
  QuotesHotelsShare = '/bookings/quotes/share',

  QuotesCondosDownload = '/bookings/quotes/condo/download',
  QuotesCondosShare = '/bookings/quotes/condo/share',

  getTravelHistory = '/sso/browsetravelhistorybyuser',
  ssoMember = '/sso/member',

  GetHotels = '/api/hotels',
  
  AdminHotelDetails = '/api/admin/condo/condobookings/hotel',
  TrustYou = 'https://api.trustyou.com/hotels',
  Deals = '/deals',
  Getaways = '/deals',
  CurrentUser = '/sso/currentuser',
  Benefits = '/sso/benefits',
  
  Currencies = '/currencies',


  CondosRecommendedLocations = '/condos/locations/recommended',
  CondosRecentSearches = '/condos/recent-searches',
  CondosLocationsPopularDestinations = '/condos/locations/popular',
  CondosLocations = '/condos/locations',
  CondosSearchStrict = '/condos/search/strict',
  CondosSearchStrictPagination = '/condos/search/strict/page',
  CondosSearchFlexible = '/condos/search/flexible',
  CondosSearchFlexiblePagination = '/condos/search/flexible/page',
  CondosSearchAnytime = '/condos/search/anytime',
  CondosSearchAnytimePagination = '/condos/search/anytime/page',
  CondoDetails = '/condos/details',
  CondoBook = '/condos/book',
  CondoBookResume = '/condos/book-resume',
  CheckinStatus = '/condos/checkin-status',
  CondoUnits = '/condos/search/units',
  CondoCountries = '/condos/countries',
  CondoBookingSummary = '/condos/booking-summary',
  CondoAllDestinations = '/condos/locations/alldestinations',

  CondoRedeemCode = '/api/condos/redeem-code',
  CondoRemoveCode = '/api/condos/cancel-redeem-code',

  AdminCondoDetails = '/api/admin/condo/condobookings/condo',
  HotelPriceRules = '/api/admin/hotel/pricerules',
  CondoPresentationRules = '/api/admin/condo/presentationrules',
  PresentationRules = '/api/admin/search-rules',
  AdminBooking = '/api/admin/condo/condobookings',
  AdminBookingSearch = '/api/admin/condo/condobookings/search',
  AdminBookingRetryVoid = '/api/admin/condo/condobookings/retry-void',
  AdminBookingRetryCapture = '/api/admin/condo/condobookings/retry-capture',
  CondoBookingsSubStatuses = '/api/admin/condo/condobookings/substatuses',
  CondoBookingsExportCsv = '/api/admin/condo/condobookings/export',
  AdminCondoNotification = '/api/admin/condo/notification',
  supportPhone = '/api/support/phone',
  AdminHotelNotification = 'api/admin/hotel/notification',
  MarkAsCancelled = '/api/admin/condo/condobookings/mark-as-cancelled',
  AdminSuppliers = '/api/admin/hotel/suppliers',
  HotelBookingsExportCsv = '/api/admin/hotel/hotelbookings/export',
  HotelBookings = 'api/admin/hotel/hotelbookings',
  SearchHotelBookings = 'api/admin/hotel/hotelbookings/search',
  ExportHotelBookings = 'api/admin/hotel/hotelbookings/export',
  HotelBookingsOrderDetails = 'api/admin/hotel/hotelbookings/order-details',
  HotelBookingsOrderDetailsCancel = 'api/admin/hotel/hotelbookings/cancel',

  InsuranceGetQuote = '/insurance/quote',
  InsuranceCheckout = '/insurance/checkout',

  Magazine = '/external/magazine',
  NotificationBar = '/external/notification-bar',
  MemberStories = '/external/member-stories',
  
  Slides = '/external/slides',
  Promotions = '/promotions',
  MemberVideos = 'https://rsistaticdata.s3.amazonaws.com/membervideos',
  PersonalDetails = '/api/sso/personal-details',
}

