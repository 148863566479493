import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatRate, renderNumber } from '@share/utils';
import { ISummary } from '@share/common-types';
import { TrustYouScoreSVG } from '@assets';
import './style.scss';

interface IProps {
  summary: ISummary;
  reviewsCount: number;
}

export class TrustYouScore extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { summary, reviewsCount } = this.props;

    if (!summary) {
      return null;
    }

    return (
      <div className="trust-you-score">
        <div className="trust-you-score__number">{formatRate(summary.score)}</div>
        <div className="trust-you-score__summary-wrapper">
          <div className="trust-you-score__summary">
            <p className="trust-you-score__summary-text">{summary.score_description}</p>
            <TrustYouScoreSVG />
          </div>
          <p className="trust-you-score__reviews">
            <FormattedMessage id="based.on" /> {renderNumber(reviewsCount)}{' '}
            <FormattedMessage id="reviews" />
          </p>
        </div>
      </div>
    );
  }
}
