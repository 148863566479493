import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { formatDateCheckInOut, renderPhone, renderPhoneWithCode, RootState } from '@share/utils';
import { ManDarkSvg } from '@assets';
import { DATE_FORMAT_FOR_BOOKINGS } from '@constants';
import { IAdminHotelBookingDetails, HotelBookingRefundabilityEnum } from '@common-types';
import { selectAdminHotelBookingsDetails } from '@store/slices';
import { BookingsStatus } from '../../../booking-status';

import './style.scss';

interface IMapStateToProps {
  hotelDetails: IAdminHotelBookingDetails;
}

interface IProps extends IMapStateToProps {}

class BookingOrderDetailsComponent extends React.Component<IProps> {
  getRefundableStatus = () => {
    const { refundability } = this.props.hotelDetails;

    const refundabilityMap: { [key in HotelBookingRefundabilityEnum]: React.ReactNode } = {
      [HotelBookingRefundabilityEnum.Refundable]: <FormattedMessage id="refundable" />,
      [HotelBookingRefundabilityEnum.Nonrefundable]: <FormattedMessage id="non.refundable" />,
      [HotelBookingRefundabilityEnum.Unknown]: <FormattedMessage id="unknown" />,
    };

    return refundabilityMap[refundability];
  };

  render(): React.ReactNode {
    const { hotelDetails } = this.props;

    if (!hotelDetails) {
      return null;
    }

    const {
      supplierName,
      confirmationCode,
      mainGuest,
      providerOrderId,
      checkIn,
      checkOut,
      segments,
      refundabilityText,
      cancellationPoliciesText,
      bookingRemarks,
    } = hotelDetails;

    return (
      <div className="booking-order-details">
        <div className="booking-order-details__card">
          <div className="booking-order-details__header-wrapper">
            <div className="booking-order-details__header">
              <FormattedMessage id="room.details" />
            </div>
            <div className="booking-order-details__details">
              <div className="booking-order-details__details-data">
                <FormattedMessage id="supplier" />:{' '}
                <span className="bold-text" style={{ textTransform: 'uppercase' }}>
                  {supplierName}
                </span>
              </div>
            </div>
          </div>
          <div className="booking-order-details__card-content unit-card">
            <div className="booking-order-details__unit-code-section">
              <div className="booking-order-details__unit-content">
                <div className="booking-order-details__unit-name">
                  <span className="booking-order-details__unit-title">
                    <FormattedMessage id="provider.order.ID" />:{' '}
                  </span>
                  <span className="bold-text">{providerOrderId}</span>
                </div>
              </div>
              <div className="booking-order-details__unit-content">
                <div className="booking-order-details__unit-name">
                  <span className="booking-order-details__unit-title">
                    <FormattedMessage id="confirmation.code" />:{' '}
                  </span>
                  <span className="bold-text">{confirmationCode ? confirmationCode : '-'}</span>
                </div>
              </div>
            </div>
            {segments?.map(({ rooms, segmentId }, i) => (
              <div key={i}>
                <div className="booking-order-details__unit-status-section grey-section">
                  <div className="booking-order-details__unit-status-section_segment">
                    <FormattedMessage id="segment" /> <span> </span> # {i + 1}
                    <strong>
                      {' '}
                      <FormattedMessage id="id" />: <span> </span>
                      {segmentId}
                    </strong>
                  </div>
                  <BookingsStatus status={hotelDetails.status} />
                </div>
                {rooms?.map(({ name, guests, type, basis }, roomIndex) => {
                  const guestWithoutMain = guests.filter(({ isMainGuest }) => !isMainGuest);
                  return (
                    <div key={roomIndex}>
                      <div className="booking-order-details__unit-top">
                        <div className="booking-order-details__unit-column">
                          <div className="booking-order-details__unit-header">
                            <FormattedMessage id="room.title" />
                          </div>
                          <div className="booking-order-details__unit-content">
                            <div className="booking-order-details__unit-name">
                              <strong>{name}</strong>
                            </div>

                            {guests.map((_, guestIndex) => (
                              <span key={guestIndex} className="rooms-search__icon-wrapper">
                                <ManDarkSvg />
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="booking-order-details__unit-column">
                          <div className="booking-order-details__unit-header">
                            <FormattedMessage id="guest.info" />
                          </div>
                          <div className="booking-order-details__unit-content">
                            <div className="booking-order-details__unit-name">
                              <strong>
                                {' '}
                                {mainGuest.name} {mainGuest.surname}
                              </strong>
                            </div>
                            {guestWithoutMain.map(({ name, surname }, i) => (
                              <div key={i} className="booking-order-details__unit-name">
                                {name} {surname}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="booking-order-details__unit-column">
                          <div className="booking-order-details__unit-header">
                            <FormattedMessage id="cancellation.policy" />
                          </div>
                          <div className="booking-order-details__unit-content">
                            <strong>{this.getRefundableStatus()}</strong>
                          </div>
                        </div>
                        <div className="booking-order-details__unit-column">
                          <div className="booking-order-details__unit-header">
                            <FormattedMessage id="travel.dates" />
                          </div>
                          <div className="booking-order-details__unit-content">
                            {formatDateCheckInOut(checkIn, DATE_FORMAT_FOR_BOOKINGS)} —{' '}
                            {formatDateCheckInOut(checkOut, DATE_FORMAT_FOR_BOOKINGS)}
                          </div>
                        </div>
                      </div>
                      <div className="booking-order-details__unit-type grey-section">
                        {type} | {basis}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
        <div className="booking-order-details__card">
          <div className="booking-order-details__header-wrapper">
            <div className="booking-order-details__header">
              <FormattedMessage id="important.information" />
            </div>
          </div>
          <div className="booking-order-details__card-wrapper">
            {cancellationPoliciesText && (
              <div className="booking-order-details__card-section">
                <h4>
                  <FormattedMessage id="cancellation.policy" />
                </h4>
                <p>{cancellationPoliciesText}</p>
              </div>
            )}
            {refundabilityText && (
              <div className="booking-order-details__card-section">
                <h4>
                  <FormattedMessage id="refundability" />
                </h4>
                <p>{refundabilityText}</p>
              </div>
            )}
            {bookingRemarks && (
              <div className="booking-order-details__card-section">
                <h4>
                  <FormattedMessage id="booking.remarks" />
                </h4>
                <p>{bookingRemarks}</p>
              </div>
            )}
          </div>
        </div>
        <div className="booking-order-details__card">
          <div className="booking-order-details__header-wrapper">
            <div className="booking-order-details__header">
              <FormattedMessage id="contact.information" />
            </div>
          </div>
          <div className="booking-order-details__card-content">
            <div className="booking-order-details__contact-wrapper">
              <div className="booking-order-details__contact-column">
                <div className="booking-order-details__contact-header">
                  <FormattedMessage id="contact.person" />
                </div>
                <div className="booking-order-details__contact-info">
                  <div className="booking-order-details__contact-header-wrapper">
                    <div className="booking-order-details__contact-name">
                      {mainGuest.name} {mainGuest.surname}
                    </div>
                    <div className="booking-order-details__contact-phone">{mainGuest.phone}</div>
                  </div>
                  <div className="booking-order-details__contact-row">
                    <div className="booking-order-details__contact-label">
                      <FormattedMessage id="email" />:
                    </div>
                    <div className="booking-order-details__contact-value">
                      <a href={`mailto:${mainGuest.email}`}>{mainGuest.email}</a>
                    </div>
                  </div>
                  <div className="booking-order-details__contact-row">
                    <div className="booking-order-details__contact-label">
                      <FormattedMessage id="phone.number" />:
                    </div>
                    <div className="booking-order-details__contact-value">
                      {renderPhone(mainGuest.phone)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="booking-order-details__contact-column hotel-contact-column">
                <div className="booking-order-details__contact-header">
                  <FormattedMessage id="property.info" />
                </div>
                <div className="booking-order-details__contact-info">
                  <div className="booking-order-details__contact-name">
                    {hotelDetails.hotelModel.name}
                  </div>
                  <div className="booking-order-details__contact-row">
                    <div className="booking-order-details__contact-label">
                      <FormattedMessage id="email" />:
                    </div>
                    <div className="booking-order-details__contact-value">
                      <a href={`mailto:${hotelDetails.hotelModel.email}`}>
                        {hotelDetails.hotelModel.email}
                      </a>
                    </div>
                  </div>
                  <div className="booking-order-details__contact-row">
                    <div className="booking-order-details__contact-label">
                      <FormattedMessage id="phone.number" />:
                    </div>
                    <div className="booking-order-details__contact-value">
                      {renderPhoneWithCode(hotelDetails.hotelModel.phone)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelDetails: selectAdminHotelBookingsDetails(state),
  };
};

export const BookingOrderDetails = connect(mapStateToProps)(BookingOrderDetailsComponent);

