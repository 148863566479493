import React from 'react';

import './style.scss';

interface IProps {
  logos: any[];
}

export class CarsLogos extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { logos } = this.props;

    return (
      <div className="cars-logos">
        <div className="cars-logos__wrapper">
          <p className="cars-logos__title">
            Preferred Car Rental Companies
          </p>
          <div className="cars-logos__logos-wrapper">
            {logos.map((item, index) => (
              <div key={`${index}-${item.name}`}>
                <div className="cars-logos__img-wrapper">
                  <img className="cars-logos__img" src={item.image} alt={item.name} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
