import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Modal, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';

import { ICondoUnit, IDetails } from '@share/common-types';
import { ICondoGuests } from '@share/common-types';
import { getNightsCount } from '@utils';
import { creatingArrayWithNumber } from '@share/utils';
import { UnitMainInfo } from '@components';
import { RootState } from '@share/utils';

import { KitchenSvg, ManDarkSvg, CheckMarkSvg, InfoSvg, ExclamationGreySvg } from '@assets';

import './style.scss';

interface IMapStateToProps {
  condoGuestsStore: ICondoGuests;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  condo: IDetails;
  unit: ICondoUnit;
  visible: boolean;

  walletPrices: any;

  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

const ZERO = 0;
const ONE = 1;
const NO = 'No';
const YES = 'Yes';

class ModalUnitDetailsComponent extends React.Component<IProps> {
  getBathRoomInfo = () => {
    const { bathroom, bathroomDescription, bedroomBathLocation } = this.props.unit;
    const bathRoomInfo = [];

    if (bathroom) {
      bathRoomInfo.push(bathroom);
    }
    if (bathroomDescription) {
      bathRoomInfo.push(bathroomDescription);
    }
    if (bedroomBathLocation) {
      bathRoomInfo.push(bedroomBathLocation);
    }

    return bathRoomInfo;
  };

  getBathRoomLabels = () => {
    const { intl, unit } = this.props;
    const { rollInShower, bathroomAccessible } = unit;
    const bathRoomLabel = [];

    if (rollInShower) {
      bathRoomLabel.push(intl.formatMessage({ id: 'roll.in.shower' }));
    }
    if (bathroomAccessible) {
      bathRoomLabel.push(intl.formatMessage({ id: 'bathroom.accessible' }));
    }

    return bathRoomLabel;
  };

  getKitchenEquipments = () => {
    const { kitchenEquipments } = this.props.unit;

    if (!isEmpty(kitchenEquipments) || !isEmpty(kitchenEquipments)) {
      return kitchenEquipments.filter((item) => item.details[ZERO] !== NO);
    } else {
      return [];
    }
  };

  getRoomAmenities = () => {
    const { roomAmenities } = this.props.unit;

    if (!isEmpty(roomAmenities) || !isEmpty(roomAmenities)) {
      return roomAmenities.filter((item) => item.details[ZERO] !== NO);
    } else {
      return [];
    }
  };

  getRoomFurnishing = () => {
    const { roomFurnishing } = this.props.unit;

    if (!isEmpty(roomFurnishing) || !isEmpty(roomFurnishing)) {
      return roomFurnishing.filter((item) => item.details[ZERO] !== NO);
    } else {
      return [];
    }
  };

  getRoomEntertainment = () => {
    const { intl, unit } = this.props;
    const { televisionServiceType, televisionType, numberOfTVs, televisionLocations } = unit;
    const roomEntertainment = [];

    if (televisionServiceType) {
      roomEntertainment.push(televisionServiceType);
    }

    if (televisionType) {
      roomEntertainment.concat(televisionType);
    }

    if (numberOfTVs) {
      roomEntertainment.push(`${intl.formatMessage({ id: 'number.of.tvs' })} ${numberOfTVs}`);
    }

    if (televisionLocations) {
      roomEntertainment.concat(televisionLocations);
    }

    return roomEntertainment;
  };

  getAccessibility = () => {
    const { accessibility } = this.props.unit;

    if (accessibility) {
      return [accessibility, ...this.getBathRoomLabels()];
    } else {
      return this.getBathRoomLabels();
    }
  };

  isShowAmenities = () => {
    const { coolingSystems } = this.props.unit;

    return (
      !isEmpty(this.getRoomAmenities()) ||
      !isEmpty(this.getAccessibility()) ||
      !isEmpty(coolingSystems) ||
      !isEmpty(this.getRoomFurnishing()) ||
      !isEmpty(this.getRoomEntertainment())
    );
  };

  render(): React.ReactNode {
    const { visible, onCancel, unit, condo, condoGuestsStore,walletPrices } = this.props;
    const { fullAddress, title, media, id, checkInCheckOutInfo } = condo;
    const {
      bedroomsCount,
      bathroomsCount,
      dateRange,
      price,
      unitId,
      bedRoomType,
      maxOccupancy,
      privacyOccupancy,
      kitchenType,
      babyFacilities,
      coolingSystems,
      nonSmokingRoomsByRequest,
      safebox,
      safetyEquipment,
      housekeeping,
      housekeepingFeeDescription,
      cancellationPolicyText,
      name,
    } = unit;
    const highlights = this.getBathRoomInfo();

    return (
      <Modal
        className="modal-unit-details"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-unit-details__wrapper"
      >
        <div className="modal-unit-details__block">
          <div className="modal-unit-details__left-block">
            <h4 className="modal-unit-details__info-title capitalize">{name}</h4>
            <div className="modal-unit-details__guests">
              {creatingArrayWithNumber(maxOccupancy, ONE).map((value, i) => {
                return (
                  <span className="modal-unit-details__icon-wrapper" key={i}>
                    <ManDarkSvg />
                  </span>
                );
              })}
            </div>
            <div className="modal-unit-details__capacity">
              <div className="modal-unit-details__capacity-text">
                <FormattedMessage id="max.sleeps" />: {maxOccupancy}
              </div>
              <div className="modal-unit-details__capacity-text">
                <FormattedMessage id="private" />: {privacyOccupancy}
              </div>
            </div>
            {!isEmpty(highlights) && (
              <h4 className="modal-unit-details__info-title mt15">
                <FormattedMessage id="bathroom" />
              </h4>
            )}
            {highlights && highlights.length ? (
              <div className="modal-unit-details__highlights">
                {highlights.map((item, index) => (
                  <p key={index} className="modal-unit-details__highlight">
                    {item}
                  </p>
                ))}
              </div>
            ) : null}
            <div className="modal-unit-details__default-banner">
              <div className="modal-unit-details__default-banner-icon">
                <ExclamationGreySvg />
              </div>
              <div className="modal-unit-details__default-banner-text">
                <div className="modal-unit-details__default-banner-header">
                  <FormattedMessage id="please.note.that.resort.will.assign" />
                </div>
                <div className="modal-unit-details__default-banner-description">
                  <FormattedMessage id="selected.unit.type.may.have.amenities" />
                </div>
              </div>
            </div>
            <div className="modal-unit-details__info">
              {!isEmpty(this.getBathRoomLabels()) && (
                <div className="modal-unit-details__highlights pt0">
                  {this.getBathRoomLabels().map((item, index) => (
                    <p key={index} className="modal-unit-details__highlight">
                      <CheckMarkSvg />
                      {item}
                    </p>
                  ))}
                </div>
              )}
            </div>
            {!isEmpty(this.getKitchenEquipments()) ? (
              <div className="modal-unit-details__info">
                <h4 className="modal-unit-details__info-title">
                  <FormattedMessage id="kitchen" />
                </h4>
                {kitchenType ? (
                  <div className="modal-unit-details__block-info kitchen-icon-block">
                    <KitchenSvg />
                    <p className="modal-unit-details__block-info-text capitalize">{kitchenType}</p>
                  </div>) : null}
                {this.getKitchenEquipments().map((equipment, index) => (
                  <div key={index}>
                    <p className="modal-unit-details__description-info-text">{equipment.name}:</p>
                    {equipment.details.map((item, i) => (
                      <span className="modal-unit-details__description-info-text" key={i}>
                        {(i ? ', ' : '') + item}
                      </span>
                    ))}
                  </div>
                ))}
              </div>) : null}
            {babyFacilities && !isEmpty(babyFacilities) ? (
              <div className="modal-unit-details__info">
                <h4 className="modal-unit-details__info-title">
                  <FormattedMessage id="baby.facilities" />
                </h4>
                {babyFacilities.map((item, index) => (
                  <p className="modal-unit-details__description-info-text" key={index}>
                    {(index ? ', ' : '') + item}
                  </p>
                ))}
              </div>) : null}
            {this.isShowAmenities() ? (
              <div className="modal-unit-details__info amenities-wrapper">
                <h4 className="modal-unit-details__info-title">
                  <FormattedMessage id="tab.amenities" />
                </h4>
                {!isEmpty(this.getRoomAmenities()) ? (
                  <>
                    <p className="modal-unit-details__info-list-title">
                      <FormattedMessage id="general" />
                    </p>
                    <div className="modal-unit-details__info-items">
                      {this.getRoomAmenities().map((amenity, index) => (
                        <p key={index} className="modal-unit-details__info-list-item">
                          <CheckMarkSvg />
                          {amenity.name}
                          {amenity.details.map(
                            (item, i) =>
                              item !== YES && (
                                <span
                                  className="modal-unit-details__info-list-item-description"
                                  key={i}
                                >
                                  {(i ? ', ' : '') + item}
                                </span>
                              ),
                          )}
                        </p>
                      ))}
                    </div>
                  </>) : null}
                <div className="modal-unit-details__info-list-wrapper">
                  <div className="modal-unit-details__info-list">
                    {(!isEmpty(this.getRoomFurnishing()) || !isEmpty(this.getRoomEntertainment())) ? (
                      <p className="modal-unit-details__info-list-title">
                        <FormattedMessage id="entertainment" />
                      </p>) : null}
                    <div className="modal-unit-details__info-items">
                      {!isEmpty(this.getRoomFurnishing()) ?
                        this.getRoomFurnishing().map((furnishing, index) => (
                          <p key={index} className="modal-unit-details__info-list-item one-colonm">
                            <CheckMarkSvg />
                            {furnishing.name}
                            {furnishing?.details.map(
                              (item, i) =>
                                item !== YES && (
                                  <span
                                    className="modal-unit-details__info-list-item-description"
                                    key={i}
                                  >
                                    {(i ? ', ' : '') + item}
                                  </span>
                                ),
                            )}
                          </p>)) : null}
                      {!isEmpty(this.getRoomEntertainment()) ?
                        this.getRoomEntertainment().map((item, index) => (
                          <p key={index} className="modal-unit-details__info-list-item one-colonm">
                            <CheckMarkSvg />
                            {item}
                          </p>)) : null}
                    </div>
                  </div>
                  {coolingSystems?.length && (
                    <div className="modal-unit-details__info-list">
                      <p className="modal-unit-details__info-list-title">
                        <FormattedMessage id="heating.venting.cooling" />
                      </p>
                      {coolingSystems.map((item, index) => (
                        <p key={index} className="modal-unit-details__info-list-item one-colonm">
                          <CheckMarkSvg />
                          {item}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                {!isEmpty(this.getAccessibility()) ? (
                  <div>
                    <p className="modal-unit-details__info-list-title">
                      <FormattedMessage id="accessibility" />
                    </p>
                    {this.getAccessibility().map((item, index) => (
                      <p key={index} className="modal-unit-details__info-list-item one-colonm">
                        <CheckMarkSvg />
                        {item}
                      </p>
                    ))}
                  </div>) : null}
              </div>) : null}
            <div className="modal-unit-details__info-bottom">
              {nonSmokingRoomsByRequest ? (
                <p className="modal-unit-details__description-info-text">
                  <FormattedMessage id="non.smoking.rooms" />
                  {':'}
                  <span className="modal-unit-details__property-info-text">
                    {nonSmokingRoomsByRequest ? YES : NO}
                  </span>
                </p>) : null}
              {safebox ? (
                <p className="modal-unit-details__description-info-text">
                  <FormattedMessage id="safebox" />
                  {':'}
                  <span className="modal-unit-details__property-info-text">
                    {safebox ? YES : NO}
                  </span>
                </p>) : null}
              {safetyEquipment ? (
                <p className="modal-unit-details__description-info-text">
                  <FormattedMessage id="safety.equipment" />
                  {':'}
                  <span className="modal-unit-details__property-info-text">
                    {safetyEquipment ? YES : NO}
                  </span>
                </p>
              ) : null}
              {housekeeping ? (
                <p className="modal-unit-details__description-info-text">
                  <FormattedMessage id="housekeeping" />
                  {':'}
                  <span className="modal-unit-details__property-info-text">{housekeeping}</span>
                  <Tooltip placement="bottom" title={housekeepingFeeDescription}>
                    <span className="modal-unit-details__price-info-icon">
                      <InfoSvg />
                    </span>
                  </Tooltip>
                </p>) : null}
            </div>
            {!isEmpty(cancellationPolicyText) ? (
              <div className="modal-unit-details__cancellation-policy-wrapper">
                <h4 className="modal-unit-details__info-title">
                  <FormattedMessage id="cancellation.policy" />
                </h4>
                <p className="modal-unit-details__cancellation-policy-text">
                  {cancellationPolicyText}
                </p>
              </div>
            ) : null}
          </div>
          <div className="modal-unit-details__right-block">
            <UnitMainInfo
              imageUrl={media[ZERO]?.url}
              data={{
                title: title,
                address: fullAddress,
                unitName: bedRoomType,
                maxOccupancy: maxOccupancy,
                adultsCount: condoGuestsStore?.adultsCount,
                childrenCount: condoGuestsStore?.kidsCount,
                bedroomsCount: bedroomsCount,
                bathroomsCount: bathroomsCount,
                checkIn: dateRange.from,
                checkOut: dateRange.to,
                nightsCount: getNightsCount(unit.dateRange),
                condoId: id,
                unitId: unitId,
                price: price,
                checkInTime: checkInCheckOutInfo?.checkInTime,
                checkOutTime: checkInCheckOutInfo?.checkOutTime,
                isModalInfo: true,
                walletPrices
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoGuestsStore: state.condoGuestsStore,
  };
};

export const ModalUnitDetails = connect(
  mapStateToProps,
  null,
)(injectIntl(ModalUnitDetailsComponent));

