import React from 'react';
import { connect } from 'react-redux';

import './style.scss';

interface IProps {}

class HomeTravcodingComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="home-travcoding-wrapper">
        <div className="container-fluid">
          <div className="row">
          </div>
        </div>
      </div>
    );
  }
}

export const HomeTravcoding = connect()(HomeTravcodingComponent);
