
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';

import { INavigation, ISideKickStatus, IUserSidekick } from '@share/common-types';
import { Urls, USER_BASE_URL } from '@share/constants';
import { AppThunk, axiosInstanceExternal, DecodeTokenData, getToken } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { forceSetLoginAccount, getUserWallet, reservationCancellationAction } from '@share/store/slices';

export interface IMenuState {
  items: INavigation;
  userSidekick: IUserSidekick;
  loading: boolean;
  error: string;
  visible: boolean;
  isUpdateMenu: boolean;
}

const initialState: IMenuState = {
  items: null,
  userSidekick: null,
  loading: false,
  error: '',
  visible: false,
  isUpdateMenu: false,
};

const navigationMenuSlice = createSlice({
  name: 'navigationMenu',
  initialState,
  reducers: {
    setLoading: (state: IMenuState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IMenuState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setMenu: (state: IMenuState, { payload }: PayloadAction<INavigation>) => {
      state.items = payload;
    },
    setVisible: (state: IMenuState, { payload }: PayloadAction<boolean>) => {
      state.visible = payload;
    },
    setIsUpdateMenu: (state: IMenuState, { payload }: PayloadAction<boolean>) => {
      state.isUpdateMenu = payload;
    },
    setUserSidekick: (state: IMenuState, { payload }: PayloadAction<IUserSidekick>) => {
      state.userSidekick = payload;
    }
  },
});

export const { setLoading, setError, setMenu, setVisible, setIsUpdateMenu, setUserSidekick } =
  navigationMenuSlice.actions;

export const navigationMenuReducer = navigationMenuSlice.reducer;

export const navigationMenuSelector = (state: { navigationMenuStore: IMenuState }): IMenuState => {
  return state.navigationMenuStore;
};

export const getNavigationMenu = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
      const { loginStore } = getState();
      const { user, account } = loginStore;

      const data = getToken(account);
      const res = await axiosInstance.post(`${Urls.NavigationItems}/${account.name}/${user.userId}`, data? data : {}, {
        ...getHeaders(),
      });

      const items = { ...res.data };

      const dataDecoded = DecodeTokenData();
      if (dataDecoded) {
        items.renewalAgency = dataDecoded.renewalAgency;
        items.sideKickStatus = dataDecoded.sideKickStatus;
      }
 
      dispatch(setMenu({ ...items }));

      if (items?.sideKickStatus === ISideKickStatus.PARENT) {
        dispatch(getUserSidekick());
      }

      if (items?.isMLM) {
        dispatch(getUserWallet(user));

        dispatch(forceSetLoginAccount({
          ...account,
          walletUseSlider: true,
          hasClientCash: true,
          walletIsExternal: true,
          walletNoDecimals: false,
          // TODO delete this
          //walletNoPassEarnings: true,
          //walletWalletSavings: true,
          isMLM: true,
          walletClientCashName: 'Points'
        }));

      }

      dispatch(setLoading(false));

      if (!isEmpty(items?.logoutUrl)) {
        localStorage.setItem(USER_BASE_URL, items.logoutUrl);
      }

    } catch (error) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setLoading(false));
    }
  };
};

export const getNavigationMenutCancelDetail = (tempKey: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(reservationCancellationAction.setLoading(true));

    try {
      const params = { tempKey };
      const res = await axiosInstance.post(Urls.reservationCancellationDetails, null, {
        params,
        ...getHeaders(),
      });

      dispatch(setMenu({
        logoUrl: get(res.data, 'reservationDetails.logoUrl', null)
      } as INavigation));
      dispatch(reservationCancellationAction.setReservationCancellationDetails(res.data));

      dispatch(setLoading(false));
      dispatch(reservationCancellationAction.setLoading(false));

    } catch (error) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setLoading(false));
    }
  };
};

export const getUserSidekick = (): AppThunk => {
  return async (dispatch, getState) => {
    const { loginStore } = getState();
    const { user } = loginStore;

    dispatch(setLoading(true));
    dispatch(setLoading(true));

    try {
      const responseAuth = await axiosInstance.post(`${Urls.SSO}/token-sidekick`, null, {
        ...getHeaders(),
      });

      const accessToken = responseAuth.data.accessToken;
      const response = await axiosInstanceExternal.get(`https://svc.accessrsi.com/api/sidekick/${user?.keyid}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }      
      });
      
      const items = response.data.items;
      const pending = items?.length ? items.map((i: any) => i.pendingRewards).reduce((p: number, n: number) => p + n) : 0;
      const available = items?.length ? items.map((i: any) => i.activeRewards).reduce((p: number, n: number) => p + n) : 0;
      const sidekicks = items.map((i: any) => ({
        key: i.rsiId,
        rsiId: i.rsiId,
        name: i.fullName,
        email: i.email,
        pendingTravel: i.pendingRewards,
        availableRewards: i.activeRewards,
        dateAdded: i.activationDate,
        status: i.isActive ? 'Active' : 'Pending'
      }));

      const sidekick = {
        pending,
        available,
        sidekicks
      };
      dispatch(setUserSidekick(sidekick));
      dispatch(setLoading(false));

    } catch (error) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setLoading(false));
    }
  };
};

