
export const ADMIN_PAGE = 'admin-page';
export const ORDER_DETAILS_PAGE = 'order';
export const BOOKING_DETAILS_PAGE = 'booking';
export const DEFAULT_AMOUNT = 10;
export const CURRENCY_TYPE = 'USD';
export const MAX_LENGTH_DESCRIPTION = 60;
export const NEGATIVE = -1;
export const VALUE_ALL = 'All';
export const MIN_LENGTH_SITE_ID = 1;
export const MAX_LENGTH_SITE_ID = 10;

export const PRODUCTS = [{ name: 'Condo' }, { name: 'Hotel' }];

export enum MarginTypeEnum {
  Currency = '$',
  Percent = '%',
}

export enum ProductsEnum {
  Condo = 'Condo',
  Hotel = 'Hotel',
}

export enum SuppliersEnum {
  Rci = 1,
  Intervals = 2,
}

export const DEFAULT_NEW_PRICE_RULE = {
  name: '',
  marginType: CURRENCY_TYPE,
  margin: DEFAULT_AMOUNT,
  isActive: true,
};

export const DEFAULT_NEW_PRESENTATION_RULE = {
  name: '',
  isActive: true,
};

export const ADMIN_PAGE_URL_INFO = {
  'price-rules': '',
  'presentation-rules': '',
  'new-rule': 'new.price.rules.description',
  'activity': 'activity.description',
  'accounts': '',
  'account': '',
};

export enum MenuItemsEnum {
  PriceRules = 'Price Rules',
  PresentationRules = 'Presentation Rules',
  Accounts = 'Account Management',
}

export enum AdminNavKeysEnum {
  Bookings = 'all-bookings',
  BusinessRules = 'business-rules',
}

export enum OrderBy {
  ascend = 'Asc',
  descend = 'Desc',
}
