import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { ILoginState } from '@share/store/slices';
import { getPreferenceFileUrlFromAccount, RootState } from '@share/utils';
import { LOGO_FIELD_KEY } from '@share/common-types';

import Logo from '@share/assets/images/logo_black.png';
import TravLogo from '@share/assets/images/trav_logo.png';
import Priceline from '@share/assets/images/Priceline_Logo.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {
  visible: boolean;
  redirect: string;

  onRedirect: () => void;
}

const modalWidth = 600;

class RedirectModalComponent extends React.Component<IProps, null> {

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if(!prevProps.visible && this.props.visible) {
      setTimeout(() => {
        if (!isEmpty(this.props.redirect) && this.props.visible) {
          const disableNewTabOpen = this.props.loginStore.account?.disableNewTabOpen;

          window.open(this.props.redirect, disableNewTabOpen ? '_self' : '_blank').focus();
          this.props.onRedirect();
        }
      }, 4000);  
    }
  }

  render(): React.ReactNode {
    const { loginStore, visible } = this.props;
    const { account } = loginStore;


    const logoDB = getPreferenceFileUrlFromAccount(account, LOGO_FIELD_KEY);
    const logo = !isEmpty(logoDB)? logoDB : Logo;

    return (
      <div className="redirect-modal">
        <Modal
          className="redirect-modal-wrapper"
          wrapClassName="redirect-modal-wrapper__wrap"
          visible={visible}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={modalWidth}
        >
          <div className="redirect-modal-wrapper__wrapper">
            <span className="redirect-modal-wrapper__title"><FormattedMessage id={'redirect.modal.title'} /></span>
            <span className="redirect-modal-wrapper__message"><FormattedMessage id={'redirect.modal.message'} /></span>
            
            <div className="loop-image-container">
              <div className="image-container">
                <div className="travcoding-logo">
                  <img src={TravLogo} width="100px" height="100px" />
                </div>
              </div>
            </div>

            <div className="redirect-modal-wrapper__redirect">
              <img className="logo-icon" src={logo} alt={'logo'} />
              <img className="logo-icon" src={Priceline} alt={'logo'} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const RedirectModal = connect(mapStateToProps)(injectIntl(withRouter(RedirectModalComponent)));
