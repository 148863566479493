import React from 'react';
import parse from 'html-react-parser';

import { FormattedMessage } from 'react-intl';
import { IHotelDetails } from '@common-types';

import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

export class OtherImportantInformation extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { hotel, refAnchor } = this.props;

    return !!hotel.otherImportantInformation.length ? (
      <>
        <div ref={refAnchor} className="other-important-information pt-0">
          {hotel.isVacationRental
            ?
              <div className="rooms-search__list-text vacation mb-0">
                <div className="rooms-search__header" style={{ fontSize: '24px' }}><FormattedMessage id="other.important.information" /></div>
              </div>
            : <h4 className="other-important-information__title"><FormattedMessage id="other.important.information" /></h4>}
          <div className="other-important-information__text-wrapper">
            {hotel.otherImportantInformation.map((item, index) => (
              <div className="other-important-information__text" key={index}>
                {parse(item)}
              </div>
            ))}
          </div>
        </div>
      </>
    ) : null;
  }
}
