import React from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  creatingArrayWithNumber,
  renderPhone,
  renderPhoneWithCode,
  getTextWithBrakes,
  formatDateCheckInOut,
} from '@share/utils';
import { InfoSvg, ManDarkSvg } from '@assets';
import { RootState } from '@share/utils';
import { DATE_FORMAT_FOR_BOOKINGS } from '@constants';
import { RefundableEnum } from '@common-types';
import { IAdminBookingDetailsState } from '@store/slices';
import './style.scss';

interface IMapStateToProps {
  adminBookingDetailsStore: IAdminBookingDetailsState;
}

interface IProps extends IMapStateToProps {}

interface IState {
  isKnowBeforeYouGoShown: boolean;
}

const zero = 0;
const one = 1;

class BookingOrderDetailsComponent extends React.Component<IProps, IState> {
  state: IState = {
    isKnowBeforeYouGoShown: true,
  };

  toggleKnowBeforeYouGo = () => {
    this.setState(({ isKnowBeforeYouGoShown }) => ({
      isKnowBeforeYouGoShown: !isKnowBeforeYouGoShown,
    }));
  };

  render(): React.ReactNode {
    const { adminBookingDetailsStore } = this.props;
    const { isKnowBeforeYouGoShown } = this.state;
    const { details } = adminBookingDetailsStore;

    if (!details) {
      return null;
    }

    const {
      supplierType,
      confirmationNumber,
      unit,
      guest,
      refundability,
      checkIn,
      checkOut,
      cancellationPolicyText,
      bookingBeforeYouGo,
      condo,
      userId,
    } = details;

    return (
      <div className="booking-order-details">
        <div className="booking-order-details__card">
          <div className="booking-order-details__header-wrapper">
            <div className="booking-order-details__header">
              <FormattedMessage id="unit.details" />
            </div>
            <div className="booking-order-details__details">
              <div className="booking-order-details__details-data">
                <FormattedMessage id="supplier" />:{' '}
                <span className="bold-text" style={{ textTransform: 'uppercase' }}>
                  {supplierType}
                </span>
              </div>
              <div className="booking-order-details__details-data">
                <FormattedMessage id="confirmation.code" />:{' '}
                <span className="bold-text">{confirmationNumber ? confirmationNumber : '-'}</span>
              </div>
            </div>
          </div>
          <div className="booking-order-details__card-content unit-card">
            <div className="booking-order-details__unit-top">
              <div className="booking-order-details__unit-column">
                <div className="booking-order-details__unit-header">
                  <FormattedMessage id="unit" />
                </div>
                <div className="booking-order-details__unit-content">
                  {unit.name}
                  <div className="booking-order-details__unit-capacity">
                    {creatingArrayWithNumber(unit.maxOccupancy, one).map((value, i) => {
                      return (
                        <span className="booking-order-details__icon-wrapper" key={i}>
                          <ManDarkSvg />
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="booking-order-details__unit-column">
                <div className="booking-order-details__unit-header">
                  <FormattedMessage id="main.guest.info" />
                </div>
                <div className="booking-order-details__unit-content">
                  <div className="booking-order-details__unit-name">
                    {guest.namePrefix} {guest.givenName} {guest.surname}
                  </div>
                  <div className="booking-order-details__unit-email">
                    <a href={`mailto:${guest.email}`}>{guest.email}</a>
                  </div>
                </div>
              </div>
              <div className="booking-order-details__unit-column">
                <div className="booking-order-details__unit-header">
                  <FormattedMessage id="cancellation.policy" />
                </div>
                <div className="booking-order-details__unit-content">
                  {refundability === RefundableEnum.Refundable && (
                    <FormattedMessage id="refundable" />
                  )}
                  {refundability === RefundableEnum.Nonrefundable && (
                    <FormattedMessage id="non.refundable" />
                  )}
                </div>
              </div>
              <div className="booking-order-details__unit-column">
                <div className="booking-order-details__unit-header">
                  <FormattedMessage id="travel.dates" />
                </div>
                <div className="booking-order-details__unit-content">
                  {formatDateCheckInOut(checkIn, DATE_FORMAT_FOR_BOOKINGS)} —{' '}
                  {formatDateCheckInOut(checkOut, DATE_FORMAT_FOR_BOOKINGS)}
                </div>
              </div>
            </div>
            <div className="booking-order-details__unit-bottom">
              <FormattedMessage id="max.sleeps" />: {unit.maxOccupancy},{' '}
              <FormattedMessage id="private" />: {unit.privacyOccupancy} |{' '}
              <span style={{ textTransform: 'capitalize' }}>{unit.kitchenType}</span>{' '}
              <FormattedMessage id="kitchen" /> {unit.bathroom && `| ${unit.bathroom}`}
            </div>
          </div>
        </div>
        <div className="booking-order-details__card">
          <div className="booking-order-details__header-wrapper">
            <div className="booking-order-details__header">
              <FormattedMessage id="important.information" />
            </div>
          </div>
          <div className="booking-order-details__card-content policy-wrapper">
            <div
              className={`booking-order-details__policy-wrapper ${
                !isKnowBeforeYouGoShown ? 'closed' : ''
              }`}
            >
              <div className="booking-order-details__policy-header">
                <InfoSvg />
                <FormattedMessage id="know.before.you.go" />:
              </div>
              <div
                className="booking-order-details__policy-toggle"
                onClick={this.toggleKnowBeforeYouGo}
              >
                {isKnowBeforeYouGoShown ? <UpOutlined /> : <DownOutlined />}
              </div>
              <div className="booking-order-details__policy-text">
                {!isEmpty(bookingBeforeYouGo?.generalUrgentInfoMessages) && (
                  <div className="booking-order-details__know-text-wrapper">
                    {bookingBeforeYouGo?.generalUrgentInfoMessages.map((item, index) => (
                      <p key={index} className="booking-order-details__know--text">
                        {item}
                      </p>
                    ))}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo?.generalUrgentInfoForNonMembersMessages) && (
                  <div className="booking-order-details__know-text-wrapper">
                    {bookingBeforeYouGo?.generalUrgentInfoForNonMembersMessages.map(
                      (item, index) => (
                        <p key={index} className="booking-order-details__know--text">
                          {item}
                        </p>
                      ),
                    )}
                  </div>
                )}
                {bookingBeforeYouGo?.specialNotesPrecautions && (
                  <div className="booking-order-details__know-text-wrapper">
                    <p className="booking-order-details__know--text">
                      {bookingBeforeYouGo?.specialNotesPrecautions}
                    </p>
                  </div>
                )}
              </div>
              <div className="booking-order-details__policy-header">
                <FormattedMessage id="possible.applicable.fees" />:
              </div>
              <div className="booking-order-details__policy-text">
                {!isEmpty(bookingBeforeYouGo?.mandatoryFees) && (
                  <div className="booking-order-details__know-text-wrapper">
                    {bookingBeforeYouGo?.mandatoryFees.map((item, index) => (
                      <div key={index} className="booking-order-details__know-text-block">
                        {item && <p className="booking-order-details__know--text">{item}</p>}
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo?.fees) && (
                  <div className="booking-order-details__know-text-wrapper">
                    {bookingBeforeYouGo?.fees.map((item, index) => (
                      <div key={index} className="booking-order-details__know-text-block">
                        {item && <p className="booking-order-details__know--text">{item}</p>}
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo?.housekeepingFees) && (
                  <div className="booking-order-details__know-text-wrapper">
                    {bookingBeforeYouGo?.housekeepingFees.map((item, index) => (
                      <div key={index} className="booking-order-details__know-text-block">
                        {item && <p className="booking-order-details__know--text">{item}</p>}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {bookingBeforeYouGo?.occupancyRestrictions && (
                <>
                  <div className="booking-order-details__policy-header">
                    <FormattedMessage id="resort.contractions.restrictions" />:
                  </div>
                  <div className="booking-order-details__policy-text">
                    <div className="booking-order-details__know-text-wrapper">
                      <p className="booking-order-details__know--text">
                        {bookingBeforeYouGo?.occupancyRestrictions}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="booking-order-details__policy-header">
              <FormattedMessage id="cancellation.policy" />:
            </div>
            <div className="booking-order-details__policy-text">
              {getTextWithBrakes(cancellationPolicyText)}
            </div>
          </div>
        </div>
        <div className="booking-order-details__card">
          <div className="booking-order-details__header-wrapper">
            <div className="booking-order-details__header">
              <FormattedMessage id="contact.information" />
            </div>
          </div>
          <div className="booking-order-details__card-content">
            <div className="booking-order-details__contact-wrapper">
              <div className="booking-order-details__contact-column">
                <div className="booking-order-details__contact-header">
                  <FormattedMessage id="contact.person" />
                </div>
                <div className="booking-order-details__contact-info">
                  <div className="booking-order-details__contact-header-wrapper">
                    <div className="booking-order-details__contact-name">
                      {guest.givenName} {guest.surname}
                    </div>
                    <div className="booking-order-details__contact-phone">{userId}</div>
                  </div>
                  <div className="booking-order-details__contact-row">
                    <div className="booking-order-details__contact-label">
                      <FormattedMessage id="email" />:
                    </div>
                    <div className="booking-order-details__contact-value">
                      <a href={`mailto:${guest.email}`}>{guest.email}</a>
                    </div>
                  </div>
                  <div className="booking-order-details__contact-row">
                    <div className="booking-order-details__contact-label">
                      <FormattedMessage id="phone.number" />:
                    </div>
                    <div className="booking-order-details__contact-value">
                      {renderPhone(guest.phone)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="booking-order-details__contact-column condo-contact-column">
                <div className="booking-order-details__contact-header">
                  <FormattedMessage id="property.info" />
                </div>
                <div className="booking-order-details__contact-info">
                  <div className="booking-order-details__contact-name">{condo.displayName}</div>
                  {condo.emails?.length ? (
                    <div className="booking-order-details__contact-row">
                      <div className="booking-order-details__contact-label">
                        <FormattedMessage id="email" />:
                      </div>
                      <div className="booking-order-details__contact-value">
                        <a href={`mailto:${condo.emails[zero]}`}>{condo.emails[zero]}</a>
                      </div>
                    </div>
                  ) : null}
                  {condo.phones?.length ? (
                    <div className="booking-order-details__contact-row">
                      <div className="booking-order-details__contact-label">
                        <FormattedMessage id="phone.number" />:
                      </div>
                      <div className="booking-order-details__contact-value">
                        {renderPhoneWithCode(condo.phones[zero])}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingDetailsStore: state.adminBookingDetailsStore,
  };
};

export const BookingOrderDetails = connect(mapStateToProps)(BookingOrderDetailsComponent);
