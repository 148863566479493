
import React, { useEffect, useState } from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Tabs, Spin } from 'antd';

import { Routes } from '@share/constants';
import { getMyProfile, IMyProfileState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { ILoginState, LoginType } from '@share/store/slices';
import { Loading } from '@components';
import { LoadingSise } from '@common-types';
import { RENEWAL_AGENCY_ASHEVILLE } from '@share/constants';

import { AddPayment } from './add-payment';
import { AuthorizedUsers } from './authorized-users';
import { UpdateProfile } from './update-profile';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  myProfileStore: IMyProfileState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  get: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function MyProfileComponent(props: IProps) {

  const [ loadingDelete, setLoadingDelete ] = useState(false);

  const { myProfileStore, history, loginStore, menuStore } = props;
  const { loading, result, loadingCards } = myProfileStore;
  const { user, account } = loginStore;

  const isRenewalMember = menuStore?.items?.renewalAgency === RENEWAL_AGENCY_ASHEVILLE;

  useEffect(() => {
    if (!loginStore.loading) {
      props.get();
    }
  }, [loginStore.loading])

  if (!account || account.type !== LoginType.PrivateWithToken) {
    history.push(Routes.NotAuthorized);
  }

  return (
    <div className="my-profile-wrapper">

      <div className={`my-profile-wrapper__header ${isRenewalMember ? '' : 'isno-renewal-member'}`}>
        <div className="container-fluid" style={{ display: 'grid', alignSelf: 'baseline' }}>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 buttons" style={{ paddingRight: '0', position: 'relative' }}>
              <div className="my-profile-wrapper__title">
                <h1><FormattedMessage id="my.profile" /></h1>
                <span style={{ marginTop: '6px' }}>MEMBER SINCE: {result?.profile?.memberSince}</span>
              </div>
            </div>
            {!menuStore?.items?.isMLM ? (
              <div className="my-profile-wrapper__pay-div">
                <span className={`renew-due-by-mobile`}>RENEWAL DUE BY: {result?.profile?.expirationDate}</span>
                {isRenewalMember ? (<Button disabled={loading} size='large' type='primary' target='blank' href={`https://www.myvcaccount.com/Account/tokenpass/${user?.keyid}?token=r3iwH708P0d6VCMwvheoDheq0ZoRD0V8`} className='btn-pay-color'>PAY YOUR RENEWAL</Button>) : null}
                <span className={`renew-due-by ${isRenewalMember ? '' : 'isno-renewal-member'}`}>RENEWAL DUE BY: {result?.profile?.expirationDate}</span>
              </div>) : null}
          </div>
        </div>
      </div>

      <Spin spinning={loading || loadingDelete || loadingCards} indicator={<Loading size={LoadingSise.Small} />} >
        <>
          <Tabs type="line" defaultActiveKey="1" id='tab-internal'>
            <Tabs.TabPane tab={<FormattedMessage id="primary" />} key="1">
              <UpdateProfile />
            </Tabs.TabPane>

            <Tabs.TabPane tab={<FormattedMessage id={!menuStore?.items?.isMLM ? 'authorized.users' : 'partner.spouse'} />} key="2">
              <AuthorizedUsers onLoadingDelete={setLoadingDelete} />
            </Tabs.TabPane>

            <Tabs.TabPane tab={<FormattedMessage id="add.payment.method" />} key="3">
              <AddPayment />
            </Tabs.TabPane>
          </Tabs>
        </>
      </Spin>

    </div >
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    myProfileStore: state.myProfileStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  get: () => {
    dispatch(getMyProfile());
  }
});

const ComponentIntl = injectIntl(MyProfileComponent);

export const MyProfile = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
