import React from 'react';
import { Modal, Tabs } from 'antd';
import uniq from 'lodash/uniq';
import ImageGallery from 'react-image-gallery';
import { isVideo } from '@utils';
import { IMedia, IImagesForCarousel } from '@share/common-types';

import './style.scss';

interface IProps {
  media: IMedia[];
  visible: boolean;
  title: string;
  startIndex: number;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
  isVideoError: boolean;
}

const ZERO = 0;
const ONE = 1;

export class CondoImagesSlider extends React.Component<IProps, null> {
  getImagesByTags = (): IImagesForCarousel[][] => {
    const { media } = this.props;
    const imageTags = uniq(media.reduce((r, e) => (r.push(...e.tags), r), []));

    return imageTags.map((item) => {
      return media
        .map((image) => {
          if (image.tags.some((tags) => tags === item)) {
            return {
              original: isVideo(image.url) ? media[ZERO].url : image.url,
              thumbnail: isVideo(image.url) ? media[ZERO].url : image.url,
              embedUrl: isVideo(image.url) ? image.url : '',
              tag: item,
            };
          }
        })
        .filter((item) => item !== undefined);
    });
  };

  getAllImages = (): IImagesForCarousel[] => {
    const { media } = this.props;

    return media.map((image) => {
      return {
        original: isVideo(image.url) ? media[ZERO].url : image.url,
        thumbnail: isVideo(image.url) ? media[ZERO].url : image.url,
        embedUrl: isVideo(image.url) ? image.url : '',
      };
    });
  };

  renderVideo = (item: { embedUrl: string; original: string }): React.ReactNode => {
    const { isVideoError } = this.props;

    return !isVideoError ? (
      <div className="image-gallery-image">
        <video width="100%" height="100%" controls loop>
          <source src={item.embedUrl} />
        </video>
      </div>
    ) : (
      <div>
        <img className="image-gallery-image" src={item.original} alt={item.original} />
      </div>
    );
  };

  componentWillUnmount(): void {
    this.getImagesByTags();
  }

  render(): React.ReactNode {
    const { TabPane } = Tabs;
    const { visible, title, startIndex, onCancel } = this.props;

    return (
      <Modal
        className="condo-images-slider"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="condo-images-slider-wrapper"
      >
        <div className="condo-images-slider__header">
          <h4 className="condo-images-slider__title">{title}</h4>
        </div>
        <Tabs defaultActiveKey={`${ZERO}`}>
          <TabPane tab="All photos" key={ZERO}>
            <ImageGallery
              startIndex={startIndex}
              showIndex
              items={this.getAllImages()}
              showPlayButton
              renderItem={this.renderVideo}
            />
          </TabPane>
          {this.getImagesByTags().map((image, index) => {
            return (
              <TabPane tab={image[ZERO].tag} key={index + ONE}>
                <ImageGallery
                  showIndex
                  items={image}
                  showPlayButton
                  renderItem={this.renderVideo}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </Modal>
    );
  }
}

