import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import AdminLogo from '@assets/images/Admin-logo.png';
import { AppThunk, RootState } from '@share/utils';
import { ILoginState, logout } from '@share/store/slices';
import { BlueButton } from '@share/components';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  logout: () => AppThunk;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class AdminHeaderComponent extends React.Component<IProps> {

  renderLogout = () => {
    if (this.props.loginStore.user?.userId) {
      return (
        <div className="col-2 logout-button">
          <BlueButton onClick={this.props.logout}>
            <FormattedMessage id="admin.logout" />
          </BlueButton>
        </div>
      );
    }
    return null;
  }

  render(): React.ReactNode {
    return (
      <div className="admin-header">
        <div className="admin-header__content">
          <div className="admin-header__logo">
            <div className="row">
              <div className="col-10">
                <img className="logo-icon" src={AdminLogo} alt={'logo'} />
              </div>
              {this.renderLogout()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  logout
};

export const AdminHeader = connect(mapStateToProps, mapDispatchToProps)(AdminHeaderComponent);

