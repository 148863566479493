
import IframeResizer from 'iframe-resizer-react'
import React from 'react'

import { COOKIE1_TOKEN_NAME, COOKIE_TOKEN_NAME } from '@share/constants';
import { ILoginState } from '@share/store/slices';
import { getTokenData, RootState } from '@share/utils';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import './style.scss';
import { FormattedMessage } from 'react-intl';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

class IframeBackofficeComponent extends React.Component<IProps, null> {

  iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();
  divContainer: React.RefObject<HTMLDivElement> = React.createRef();

  render() {
    const { loginStore } = this.props;
    const tokenObj = getTokenData();
    let token = null;
    if (tokenObj) {
      token = tokenObj[COOKIE_TOKEN_NAME.toLocaleLowerCase()];
      const token1 = tokenObj[COOKIE1_TOKEN_NAME.toLocaleLowerCase()]
      if (!isEmpty(token1)) {
        token = token.concat(token1);
      }
    }

    if (!isEmpty(token)) {
      return (
        <div style={{ flex: '1', display: 'flex' }} ref={this.divContainer}>
          <IframeResizer
            forwardRef={this.iframeRef}
            heightCalculationMethod="lowestElement"
            id="backoffice_iframe"
            src={`${process.env.REACT_APP_API_BACKOFFICE_URL}/signin/${loginStore.account?.name}/?accessToken=${token}`}
            style={{ width: '1px', minWidth: '100%'}}
            sandbox="allow-scripts allow-forms allow-same-origin allow-downloads allow-popups"
            key={new Date().getTime()}
          />
        </div>
      );
    }

    return (<div><FormattedMessage id="you.are.not.authorized" /></div>);
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const IframeBackoffice = connect(mapStateToProps)(IframeBackofficeComponent);
