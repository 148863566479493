import React from 'react';
import { Skeleton } from 'antd';

import './style.scss';

interface IProps {
  singleBlock?: boolean;
}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER];
const SINGLE_BLOCK = [NUMBER];

export class SkeletonItems extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { singleBlock } = this.props;
    const blocks = singleBlock ? SINGLE_BLOCK : NUMBER_OF_BLOCKS;

    return (
      <div className="skeleton-items">
        {blocks.map((item, index) => (
          <div key={`${index}-${item}-0`} className={`skeleton-items__wrapper ${singleBlock ? 'single' : ''}`}>
            <Skeleton
              className="skeleton-items__img-skeleton"
              active
              title={false}
              paragraph={{ rows: 1 }}
            />
            <div className="skeleton-items__text-wrapper">
              <Skeleton
                key={`${index}-${item}-1`}
                className="skeleton-items__text-skeleton"
                active
                title={false}
                paragraph={{ rows: 1 }}
              />
              <Skeleton
                key={`${index}-${item}-2`}
                className="skeleton-items__text-skeleton"
                active
                title={false}
                paragraph={{ rows: 3 }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
