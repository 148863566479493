import { Base64 } from 'js-base64';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import {
  DATES_LABEL,
  DEFAULT_SEPARATOR,
  LOCATION_LABEL,
  LOCATION_OBJECT_LABEL,
  ROOMS_LABEL,
  DEFAULT_KEY_VALUE_SEPARATOR,
  FILTERS_LABEL,
  SESSION_KEY_LABEL,
  ROOMS_SEARCH_LABEL,
  SESSION_KEY_LABEL_ROOMS,
  CONDO_DATES_FLEXIBLE_LABEL,
  CONDO_DATES_LABEL,
  CONDO_IS_FLEXIBLE_LABEL,
  CONDO_LOCATIONS_LABEL,
  CONDO_LOCATIONS_OBJECT_LABEL,
  CONDO_GUESTS_LABEL,
  CONDO_UNITS_SEARCH_LABEL,
  CONDO_SESSION_KEY,
  CONDO_UNITS_SESSION_KEY_LABEL,
  CONDO_SEARCH_BOUNDS,
  CONDO_FILTERS_LABEL,
  BOOKING_STATUS_LABEL,
  BOOKING_TABLE_PAGINATION,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_TABLE_SEARCH,
  BOOKING_SUB_STATUSES_LABEL,
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_CURRENT_TAB,
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  BOOKING_TABLE_MATCHED_BY,
  GETAWAY_LOCATION,
  GETAWAY_SORT_ORDER,
  SortTypes,
  HomesTypes,
  CHILDS_LABEL,
  ADULTS_LABEL,
  LONGITUDE_LABEL,
  LATITUDE_LABEL,
  CHECKOUT_LABEL,
  CHECKIN_LABEL,
  REFERENCE_NUMBER_LABEL,
  ACCESS_TOKEN_LABEL,
  AGENCY_NUMBER_LABEL,
  DEALID_LABEL,
  QUOTE_LABEL,
  LANGUAGE_LABEL,
  CLIENT_CASH_LABEL,
  CLIENT_CASH_CONDO_LABEL,
  LIFE_STYLE_NAME_PARAM,
  DEAL_HOME_LABEL,
} from '@share/constants';
import { IDateRange, IGetawaysLocations, IDates, IUrlRoom, ICondoLocation, IClientCash } from '@share/common-types';
import { ICondoFiltersState } from '@share/store/slices';
import { IFiltersState } from '@share/store/slices';
import { ILocation, ISessionKey } from '@share/common-types';
import { get } from 'lodash';

const zero = 0;
const oneParam = 1;
const emptySearch = -1;

const keysToNotDecode: string[] = [
  CHECKIN_LABEL,
  CHECKOUT_LABEL,
  LATITUDE_LABEL,
  LONGITUDE_LABEL,
  ADULTS_LABEL,
  CHILDS_LABEL,
  REFERENCE_NUMBER_LABEL,
  ACCESS_TOKEN_LABEL,
  AGENCY_NUMBER_LABEL,
  LIFE_STYLE_NAME_PARAM,
  DEALID_LABEL,
  DEAL_HOME_LABEL,
  QUOTE_LABEL,
  LANGUAGE_LABEL
];

const keysToDecode: string[] = [
  CONDO_LOCATIONS_LABEL,
  CONDO_IS_FLEXIBLE_LABEL,
  LOCATION_LABEL,
  BOOKING_TABLE_SEARCH,
];

const keysToDecodeAndParse: string[] = [
  BOOKING_SUB_STATUSES_LABEL,
  BOOKING_STATUS_LABEL,
  BOOKING_SUPPLIER_TYPES_LABEL,
  BOOKING_TABLE_SORT_ORDER,
  BOOKING_TABLE_DATE_CHECK_IN_RANGE,
  BOOKING_TABLE_DATE_CHECK_OUT_RANGE,
  BOOKING_TABLE_DATE_BOOKED_ON_RANGE,
  CONDO_LOCATIONS_OBJECT_LABEL,
  CONDO_SESSION_KEY,
  CONDO_DATES_LABEL,
  CONDO_DATES_FLEXIBLE_LABEL,
  CONDO_GUESTS_LABEL,
  DATES_LABEL,
  LOCATION_OBJECT_LABEL,
  ROOMS_LABEL,
  FILTERS_LABEL,
  SESSION_KEY_LABEL,
  ROOMS_SEARCH_LABEL,
  SESSION_KEY_LABEL_ROOMS,
  CONDO_UNITS_SEARCH_LABEL,
  CONDO_UNITS_SESSION_KEY_LABEL,
  CONDO_SEARCH_BOUNDS,
  CONDO_FILTERS_LABEL,
  BOOKING_TABLE_PAGINATION,
  BOOKING_TABLE_CURRENT_TAB,
  BOOKING_TABLE_MATCHED_BY,
  GETAWAY_LOCATION,
  GETAWAY_SORT_ORDER,
  CLIENT_CASH_LABEL,
  CLIENT_CASH_CONDO_LABEL,
];

export class UrlUtils {
  static setUrl(
    paramName: string,
    data:
      | string[]
      | Record<string, unknown>
      | { adultsCount: number; kids: number[] }[]
      | IFiltersState
      | ICondoFiltersState
      | number
      | string
      | ILocation
      | ISessionKey
      | IGetawaysLocations
      | SortTypes
      | IDateRange
      | HomesTypes
      | IClientCash
  ): void {
    const res = this.getUrl(paramName, data);
    history.replaceState(null, null, `${location.pathname}?${res}`);
  }

  static getUrl(
    paramName: string,
    data:
      | string[]
      | Record<string, unknown>
      | { adultsCount: number; kids: number[] }[]
      | IFiltersState
      | ICondoFiltersState
      | number
      | string
      | ILocation
      | ICondoLocation
      | ISessionKey
      | IDateRange
      | IGetawaysLocations
      | SortTypes
      | IDateRange
      | IDates
      | IUrlRoom[]
      | IClientCash
  ): string {
    let res = '';
    try {
      const { search } = location;

      const param =
        !isNull(data) && !isUndefined(data)
          ? `${paramName}=${Base64.encode(
              paramName === LOCATION_LABEL ||
                paramName === CONDO_LOCATIONS_LABEL ||
                paramName === BOOKING_TABLE_SEARCH ||
                paramName === CONDO_IS_FLEXIBLE_LABEL
                ? data.toString()
                : JSON.stringify(data),
            )}`
          : '';

      if (!search) {
        res += param;
      } else {
        const values = search
          .slice(oneParam)
          .split(DEFAULT_SEPARATOR)
          .filter((val) => !!val);
        const valueIndexToUpdate = values.findIndex((value: string) => value.includes(paramName));

        if (valueIndexToUpdate > emptySearch) {
          values.splice(valueIndexToUpdate, oneParam, param);
          res = values.join(DEFAULT_SEPARATOR);
        } else {
          res = values.concat(param).join(DEFAULT_SEPARATOR);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return res;
  }

  static removeFromUrl(paramName: string): void {
    try {
      const { search } = location;

      if (search) {
        const values = search
          .slice(oneParam)
          .split(DEFAULT_SEPARATOR)
          .filter((val) => !val.includes(paramName));
        const res = values.join(DEFAULT_SEPARATOR);

        history.replaceState(null, null, `${location.pathname}?${res}`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  static getSearchParams(search: string): string[] {
    return search
      .slice(oneParam)
      .split(DEFAULT_SEPARATOR)
      .filter((val) => !!val);
  }

  static getParsedParams(search: string): string[][] {
    const params: string[] = UrlUtils.getSearchParams(search);

    return params.map((param: string) => {
      return param.split(DEFAULT_KEY_VALUE_SEPARATOR);
    });
  }

  static getSearchParam(paramName: string): string {
    const { search } = location;
    try {
      if (search && search.length > oneParam) {
        const split = UrlUtils.getSearchParams(search);
        const param = split
                        .map(val => {
                          const separatorIndex = val.indexOf(DEFAULT_KEY_VALUE_SEPARATOR);
                          const key: string = val.slice(zero, separatorIndex);
                          const value: string = val.slice(separatorIndex + oneParam);
                          return { key, value };
                        })
                        .filter(({ key }) => {
                          return paramName === key;
                        });
        const value = get(param, '[0].value', null);
        if (value) {
          return value;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  static decodeValue = (value: string): string => {
    return Base64.decode(value);
  };

  static decodeAndParseValue = (value: string): { [key: string]: unknown } => {
    return JSON.parse(UrlUtils.decodeValue(value));
  };

  static getValues(): { [key: string]: unknown } {
    const { search } = location;
    const res: { [key: string]: unknown } = {};

    try {
      if (search && search.length > oneParam) {
        const split = UrlUtils.getSearchParams(search);

        split.forEach((value: string) => {
          const separatorIndex = value.indexOf(DEFAULT_KEY_VALUE_SEPARATOR);
          const key: string = value.slice(zero, separatorIndex);
          const val: string = value.slice(separatorIndex + oneParam);

          if (keysToNotDecode.includes(key)) {
            res[key] = val;
          } else if (keysToDecode.includes(key)) {
            res[key] = UrlUtils.decodeValue(val);
          } else if (keysToDecodeAndParse.includes(key)) {
            res[key] = UrlUtils.decodeAndParseValue(val);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    return res;
  }
}
