import { 
  IRating, ISessionKey,
  IConstructionDetails,
  IDaysOfCheckIn,
  IFees,
  IFrontDeskInfo,
  IHousekeepingFees,
  IUrgentInfo,
} from '@share/common-types';
import { IInsuranceComplete } from './insurance';

export interface ICondoBookingSummary {
  mainGuest: ICondoMainGuest;
  bookingCard: ICondoBookingCard;
  bookingPolicies: IBookingPolicies;
  bookingBeforeYouGo: IBookingBeforeYouGo;
  refundability: string;
  refundabilityText: string;
  cancellationPolicyText: string;
  rating: IRating;
  supplierType: string;
  supplierIdentifier?: string;
  customProvider?: boolean;
}

export interface ICondoMainGuest {
  id: string;
  givenName: string;
  namePrefix: string;
  surname: string;
  phone: string;
  email: string;
}

export interface ICondoBookingCard {
  condoId: string;
  name: string;
  address: string;
  city: string;
  state?: string;
  postalCode?: string;
  postalCodeExtension?: string;
  country?: string;
  fullAddress: string;
  unitName: string;
  nightsCount: number;
  unitDescription: string;
  totalGuests: number;
  totalChildren: number;
  totalBedrooms: number;
  totalBathrooms?: number;
  titleImage: string;
  checkIn: string;
  checkOut: string;
  balance: number;
  couponCode: string;
  bookingPrice: ICondoBookingPrice;
  maxOccupancy: number;
  bedRoomType: string;
  currency?: string;
}

export interface ICondoBookingPrice {
  publicPrice: number;
  maxWalletClientCash: number;
  savings: number;
  couponAmount: number;
  payNowPrice: number;
  totalTaxes: number;
}

export interface ICondoGuestBooking {
  id: string;
  givenName: string;
  namePrefix: string;
  surname: string;
  countryCode: string;
  phoneCountryCode: string;
  phone: string;
  email: string;
  additionalEmail: string;
  isBookingForSomeoneElse: boolean;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  stateCode: string;
  zipCode: string;
}

export interface ICondoBookPackageWithCard {
  sessionKey: ISessionKey;
  bookingPrice: number;
  condoId: number;
  availabilityId: string;
  card: ICondoCardBooking;
  couponCode: string;
  guests: ICondoGuestBooking[];
  quote?: string;
  insuranceQuoteId?: string;
}

export interface ICountriesCode {
  rciCountries?: IRciCountriesCodes[];
  intervalCountries?: IIntervalCountriesCodes[];
}

export interface IRciCountriesCodes {
  isZipRequired: boolean;
  name: string;
  isoCode: string;
  states: ICondoStates[];
  zipRuleType: string;
}

export interface IIntervalCountriesCodes {
  isStateRequired: boolean;
  code: string;
  isoCode: string;
  name: string;
  states: ICondoStates[];
}

export interface ICondoStates {
  code: string;
  name: string;
  zipRule?: string;
}

export interface ICondoCardBooking {
  id?: string;
  addressLine1: string;
  addressLine2?: string;
  cvv: string;
  cardNumber: string;
  cardType: string;
  city: string;
  country: string;
  stateCode: string;
  expireDate: string;
  holderName: string;
  phone: string;
  zipCode: string;
  addPaymentMethod?: boolean;
}

export interface IBookingBeforeYouGo {
  urgentInfo: IUrgentInfo;
  specialNotesPrecautions: string;
  mandatoryFees: IFees[];
  fees: IFees[];
  housekeepingFees: IHousekeepingFees[];
  constructionDetails: IConstructionDetails[];
  amenityClosures: IConstructionDetails[];
}

export interface IBookingCheckInCheckOutInfo {
  description: string;
  afterHoursInstructions: string;
  arrivalCheckInSpecialInstructions: string;
  checkInTime: string;
  checkOutTime: string;
  expressCheckInCheckOut: boolean;
}

export interface IBookingPolicies {
  checkInCheckOutInfo: IBookingCheckInCheckOutInfo;
  ageRestriction: string;
  daysOfCheckIn: IDaysOfCheckIn;
  minimumNights: number;
  frontDeskInfo: IFrontDeskInfo;
  pets: string;
  parking: string[];
  smoking: string[];
}

export interface ICondoBookingComplete {
  memberId: string;
  bookingGuid: string;
  status: string;
  confirmationNumber: string;
  customerServiceNumber: string;
  currency: string;
  totalPrice: number;
  totalPay: number;
  clientCash: number;
  resort: {
    phones: string[];
  };
  errors: [
    {
      errorMessage: string;
      errorType: string;
    },
  ];
  insurance: IInsuranceComplete;
}

export interface IFormErrorValues {
  prefixName: string;
  givenName: string;
  surname: string;
  phone: string;
  primaryAddressLine1: string;
  primaryAddressLine2: string;
  primaryCity: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  cardNumber?: string;
  code?: string;
  holderName?: string;
  months?: string[];
  years?: string[];
  primaryCountry?: string;
}

export interface IFormErrorField {
  errors: string[];
  name: string[];
}

export interface IFormErrors {
  errorFields: IFormErrorField[];
  outOfDate: boolean;
  values: IFormErrorValues;
}

export enum ScrollBehaviorEnum {
  Auto = 'auto',
  Smooth = 'smooth',
}

