import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {
  active: boolean;
}

const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER, NUMBER, NUMBER, NUMBER];

export class SkeletonHeader extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="skeleton-header">
        <div className="skeleton-header__wrapper">
          <div className="skeleton-header__top-block">
            <div className="skeleton-header__top-left-block">
              {[NUMBER, NUMBER].map((item, index) => (
                <Skeleton
                  key={`${index}-${item}`}
                  className="skeleton-header__top-left-skeleton"
                  active={this.props.active}
                  title={false}
                  paragraph={{ rows: 1 }}
                />
              ))}
            </div>
            <div className="skeleton-header__top-right-block">
              {[NUMBER, NUMBER, NUMBER].map((item, index) => (
                <Skeleton
                  key={`${index}-${item}`}
                  className="skeleton-header__top-right-skeleton"
                  active={this.props.active}
                  title={false}
                  paragraph={{ rows: 1 }}
                />
              ))}
            </div>
          </div>
          <div className="skeleton-header__bottom-block">
            <div className="skeleton-header__bottom-left-block">
              <Skeleton
                className="skeleton-header__img-skeleton"
                active={this.props.active}
                title={false}
                paragraph={{ rows: 1 }}
              />
              {NUMBER_OF_BLOCKS.map((item, index) => (
                <Skeleton
                  key={`${index}-${item}`}
                  className="skeleton-header__bottom-left-skeleton"
                  active={this.props.active}
                  title={false}
                  paragraph={{ rows: 1 }}
                />
              ))}
            </div>
            <div className="skeleton-header__bottom-right-block">
              {[NUMBER, NUMBER].map((item, index) => (
                <Skeleton
                  key={`${index}-${item}`}
                  className="skeleton-header__bottom-right-skeleton"
                  active={this.props.active}
                  title={false}
                  paragraph={{ rows: 1 }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
