import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { InfoSvg, LocationMapSvg } from '@assets';
import { IAdminBookingDetails } from '@common-types';
import { DATE_FORMAT_CHECK_IN_OUT } from '@constants';
import { Routes } from '@share/constants';
import { formatDateCheckInOut, formatMoney } from '@share/utils';
import './style.scss';

interface IProps {
  details: IAdminBookingDetails;
}

const one = 1;
const zero = 0;

export class BookingCondoUnit extends React.Component<IProps> {
  showInMap = (): void => {
    const { location } = this.props.details;
    window.open(`https://maps.google.com?q=${location.latitude},${location.longitude}`);
  };

  render(): React.ReactNode {
    const {
      condo,
      unit,
      nightsCount,
      checkIn,
      checkOut,
      bookingPolicies,
      bookingPrice,
      markup,
      couponCode,
    } = this.props.details;

    return (
      <div className="booking-condo-unit">
        <div className="booking-condo-unit__image">
          <img src={condo.heroImageUrl} className="booking-condo-unit__img" />
        </div>
        <div className="booking-condo-unit__main-wrapper">
          <div className="booking-condo-unit__header">{condo.displayName}</div>
          <div className="booking-condo-unit__address" onClick={this.showInMap}>
            <LocationMapSvg /> {condo.fullAddress}
          </div>
          <div className="booking-condo-unit__bedrooms-count">{unit.name}</div>
          <div className="booking-condo-unit__guest-description">{unit.description}</div>
          <div className="booking-condo-unit__dates">
            <div className="booking-condo-unit__dates-value">
              <div className="booking-condo-unit__dates-left">
                <FormattedMessage id="check.in" />:
              </div>
              <div className="booking-condo-unit__dates-right">
                {formatDateCheckInOut(checkIn, DATE_FORMAT_CHECK_IN_OUT)}
                {bookingPolicies?.checkInTime ? (
                  <>
                    {', '}
                    {bookingPolicies?.checkInTime}
                  </>
                ) : null}
              </div>
            </div>
            <div className="booking-condo-unit__dates-value">
              <div className="booking-condo-unit__dates-left">
                <FormattedMessage id="check.out" />:
              </div>
              <div className="booking-condo-unit__dates-right">
                {formatDateCheckInOut(checkOut, DATE_FORMAT_CHECK_IN_OUT)}
                {bookingPolicies?.checkOutTime ? (
                  <>
                    {', '}
                    <FormattedMessage id="till" /> {bookingPolicies?.checkOutTime}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="booking-condo-unit__price-details">
            <div className="booking-condo-unit__label">
              <FormattedMessage id="price.details" />
            </div>
            <div className="booking-condo-unit__detail">
              <div className="booking-condo-unit__detail-left">
                <div className="booking-condo-unit__detail-label">
                  <FormattedMessage id="public.price.details" />{' '}
                  <FormattedMessage id="unit.count" values={{ count: one }} /> x{' '}
                  <FormattedMessage id="nights" values={{ count: nightsCount }} />
                </div>
              </div>
              <div className="booking-condo-unit__detail-right">
                {bookingPrice ? formatMoney.format(bookingPrice.publicPrice) : ''}
              </div>
            </div>
            <div className="booking-condo-unit__detail normal-weight">
              <div className="booking-condo-unit__detail-left">
                <div className="booking-condo-unit__detail-label tradewind-dark-color">
                  <FormattedMessage id="member.savings" />:
                </div>
              </div>
              <div className="booking-condo-unit__detail-right tradewind-dark-color">
                {bookingPrice && (
                  <>
                    {bookingPrice.savings === zero ? (
                      <FormattedMessage id="not.applied" />
                    ) : (
                      `- ${formatMoney.format(bookingPrice.savings)}`
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="booking-condo-unit__detail normal-weight">
              <div className="booking-condo-unit__detail-left">
                <div className="booking-condo-unit__detail-label tradewind-dark-color">
                  <FormattedMessage id="coupon.discount" />:
                </div>
                <div className="booking-condo-unit__total-number-description">
                  {couponCode && (
                    <>
                      <FormattedMessage id="coupone.code" /> {`“${couponCode}”`}
                    </>
                  )}
                </div>
              </div>
              <div className="booking-condo-unit__detail-right">
                <div className="booking-condo-unit__detail-coupon-status tradewind-dark-color">
                  {bookingPrice && (
                    <>
                      {bookingPrice.couponAmount === zero ? (
                        <FormattedMessage id="not.applied" />
                      ) : (
                        <>- {formatMoney.format(bookingPrice.couponAmount)}</>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="booking-condo-unit__total">
              <div className="booking-condo-unit__total-text">
                <FormattedMessage id="total.paid" />:
                <div className="booking-condo-unit__total-number-description">
                  <div>
                    {bookingPrice.totalTaxes !== zero ? (
                      <>
                        <FormattedMessage id="including.taxes.fees" />:
                        {formatMoney.format(bookingPrice.totalTaxes)}
                      </>
                    ) : (
                      <FormattedMessage id="zero.charge.for.taxes.fees" />
                    )}
                  </div>
                  <div>
                    {markup && (
                      <>
                        <FormattedMessage id="including.markup" />:<span> </span>
                        {formatMoney.format(markup)}
                      </>
                    )}
                  </div>
                  <div>
                    {bookingPrice.commissionFeeAmount === zero ? (
                      <FormattedMessage id="markup.payment.zero.charge.transaction" />
                    ) : (
                      <>
                        <FormattedMessage id="markup.including.payment.transaction.fee" />:{' '}
                        {formatMoney.format(bookingPrice.commissionFeeAmount)}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="booking-condo-unit__total-number-wrapper">
                <div className="booking-condo-unit__total-number">
                  {bookingPrice ? formatMoney.format(bookingPrice.payNowPrice) : ''}
                </div>
              </div>
            </div>
            <div className="booking-condo-unit__warning">
              <InfoSvg />{' '}
              <div>
                <strong>
                  <FormattedMessage id="additional.charges.may.apply.upon" />
                </strong>
                <div>
                  <FormattedMessage id="please.check.before" />
                </div>
              </div>
            </div>
            <div className="booking-condo-unit__view-condo">
              <Link to={`${Routes.AdminCondo}/${condo.condoId}`} target="_blank">
                <FormattedMessage id="view.condo.details" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
