import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IActivityUsers } from '@share/common-types';
import { AppThunk } from '@share/utils';

export interface IAdminActivityUsersState {
  activityUsers: IActivityUsers[];
  loading: boolean;
  error: string;
}

const initialState: IAdminActivityUsersState = {
  activityUsers: null,
  loading: false,
  error: '',
};

const adminActivityUsersSlice = createSlice({
  name: 'changePriceRules',
  initialState,
  reducers: {
    setLoadingActivityUsers: (
      state: IAdminActivityUsersState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = payload;
    },
    setErrorActivityUsers: (
      state: IAdminActivityUsersState,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = payload;
    },
    setActivityUsers: (
      state: IAdminActivityUsersState,
      { payload }: PayloadAction<IActivityUsers[]>,
    ) => {
      state.activityUsers = payload;
    },
  },
});

export const { setLoadingActivityUsers, setErrorActivityUsers, setActivityUsers } =
  adminActivityUsersSlice.actions;

export const adminActivityUsersReducer = adminActivityUsersSlice.reducer;

export const getActivityUsers = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingActivityUsers(true));

    try {
      const data = [
        {
          date: '2021-07-14T00:00:01.000Z',
          productType: 'Condos',
          ruleId: '3434343',
          action: 'Rule has been created.',
          user: 'john@mail.com',
        },
        {
          date: '2021-07-14T00:00:01.000Z',
          productType: 'Hotels',
          ruleId: '34345',
          action: 'Supplier has been changed.',
          user: 'john@mail.com',
        },
        {
          date: '2021-07-14T00:00:01.000Z',
          productType: 'Condos',
          ruleId: '9999',
          action: 'Rule has been created.',
          user: 'john@mail.com',
        },
        {
          date: '2021-07-14T00:00:01.000Z',
          productType: 'Condos',
          ruleId: '45454',
          action: 'Supplier has been changed.',
          user: 'john@mail.com',
        },
      ];

      dispatch(setActivityUsers(data));
      dispatch(setLoadingActivityUsers(false));
    } catch (error) {
      console.error(error);
      dispatch(setErrorActivityUsers(error.toString()));
      dispatch(setLoadingActivityUsers(false));
    }
  };
};
