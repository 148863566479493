import React from "react";
import TwoFactorAuth from "../../components/two-factor-auth/component";

const TwoFactorAuthentication = () => {
  return (
    <>
      <TwoFactorAuth />
    </>
  );
};

export default TwoFactorAuthentication;
