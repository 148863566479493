import React from "react";

interface StatCardProps {
  iconClass: string;
  value: string | number;
  label: string;
}

const StatCard: React.FC<StatCardProps> = (props) => {
  const { iconClass, value, label } = props;
  return (
    <div className="col-sm-6 col-xl-3">
      <div className="d-flex align-items-center">
        <span className="display-6 text-primary">
          <i className={iconClass}></i>
        </span>
        <div className="ms-3">
          <h4 className="mb-0">{value}</h4>
          <span>{label}</span>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
