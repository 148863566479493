import React, { ChangeEvent } from 'react';
import { Modal, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ErrorAlertSvg, InfoSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';

import './style.scss';

interface IProps extends WrappedComponentProps {
  visible: boolean;
  orderId: number;
  onCancel: () => void;
  getMarkAsCancelled: (orderId: number, reason: string) => void;
}

interface IState {
  note: string;
  error: string;
  enteredChars: number;
}

const rows = 5;
const oneHundred = 100;
const modalWidth = 400;
const { TextArea } = Input;
const minTextLength = 5;

class CancelModalComponent extends React.Component<IProps, IState> {
  state: IState = {
    note: '',
    error: '',
    enteredChars: 0,
  };

  validateField = (text: string) => {
    if (!text) {
      this.setState({ error: 'error.message.reason.empty' });
      return false;
    }
    if (text.length < minTextLength) {
      this.setState({
        error: 'error.message.reason.min.length',
        enteredChars: this.state.note.length,
      });
      return false;
    }
    return true;
  };

  onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      note: e.target.value,
    });
  };

  onMarkAsCanceled = () => {
    const { orderId, getMarkAsCancelled, onCancel } = this.props;

    if (!this.validateField(this.state.note)) {
      return;
    }

    getMarkAsCancelled(orderId, this.state.note);
    onCancel();
  };

  render(): React.ReactNode {
    const { visible, onCancel, intl } = this.props;
    const { error, enteredChars } = this.state;

    return (
      <div className="cancel-modal">
        <Modal
          className="cancel-modal-wrapper"
          wrapClassName="cancel-modal-wrapper__wrap"
          visible={visible}
          footer={null}
          onCancel={onCancel}
          closable={true}
          destroyOnClose={true}
          closeIcon={<CloseSvg />}
          width={modalWidth}
          title={<FormattedMessage id="mark.as.cancelled" />}
        >
          <div className="cancel-modal-wrapper__error">
            <InfoSvg /> <FormattedMessage id="this.action.cannot.be.undone" />
          </div>
          <div className="cancel-modal-wrapper__main">
            <div className="cancel-modal-wrapper__label">
              <FormattedMessage id="add.note" />
            </div>
            <div className="cancel-modal-wrapper__area">
              <TextArea
                showCount={true}
                maxLength={oneHundred}
                value={this.state.note}
                onChange={this.onNoteChange}
                placeholder={intl.formatMessage(
                  { id: 'enter.text.here.min' },
                  { minLength: minTextLength },
                )}
                rows={rows}
              />
              {error ? (
                <div className="cancel-modal-wrapper__error">
                  <ErrorAlertSvg />
                  <FormattedMessage id={error} values={{ charLength: enteredChars }} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="cancel-modal-wrapper__footer">
            <WhiteButton onClick={onCancel}>
              <FormattedMessage id="cancel" />
            </WhiteButton>
            <BlueButton onClick={this.onMarkAsCanceled}>
              <FormattedMessage id="confirm" />
            </BlueButton>
          </div>
        </Modal>
      </div>
    );
  }
}

export const CancelModal = injectIntl(CancelModalComponent);
