import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BlueButton } from '@share/components';

import './style.scss';

interface IProps {
  onClick: () => void;
}

export class TableHeader extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <div className="table-header__no-result">
        <div className="table-header__no-result-main">
          <div className="table-header__no-result-header">
            <FormattedMessage id="selected.filters.produced.no.results" />
          </div>
          <div className="table-header__no-result-text">
            <FormattedMessage id="try.adjust.or.cleaning.your.filters.to.display.better.results" />
          </div>
          <BlueButton onClick={this.props.onClick}>
            <FormattedMessage id="reset.all.filters" />
          </BlueButton>
        </div>
      </div>
    );
  }
}
