import React from 'react';
import moment from 'moment';

import { DatePicker, Input, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { IFilterField, InputCommonFilterOption, InputFilterOption, InputFilterOptions, InputFilterType } from '@share/common-types';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { DATE_FORMAT_TABLE_FILTER } from '@share/constants';

import './style.scss';

interface IProps {
  type: InputFilterType;
  field: IFilterField<any>;
  displayConditions: boolean;
  dropdownOptions?: string[];
  placeholder?: string;

  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  confirm?: () => void;
}

const TEXT_TYPE = 1;
const TEXT2_TYPE = 2;

interface IState {
  condition: InputFilterOption;
  text: string;
  text2: string;
}

export class InputTableFilter extends React.Component<IProps, IState> {

  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);
    const { type, field } = props;

    const options = Object.values(InputFilterOptions[type]);
    const conditionValue = field?.condition ? field?.condition : options[0];

    this.state = { 
      text: !isEmpty(field?.value)? field?.value : '',
      text2: !isEmpty(field?.value2)? field?.value2 : '',
      condition: !isEmpty(conditionValue)? conditionValue : null
    };
  }

  getPopupContainer = (): HTMLElement => {
    if (this.wrapperRef?.current) {
      return this.wrapperRef.current;
    }
    return document.body;
  };

  onReset = (): void => {
    const { setSelectedKeys, confirm } = this.props;

    this.setState({ text: null, text2: null, condition: null });

    setSelectedKeys([null]);
    confirm();
  };

  onApply = (): void => {
    const { text, text2, condition } = this.state;
    const { setSelectedKeys, confirm } = this.props;

    if (!isEmpty(text)) {
      if (!isEmpty(text2)) {
        setSelectedKeys([`${text}&${text2}~${condition}`]);
      } else {
        setSelectedKeys([`${text}~${condition}`]);
      }
      confirm();
    }
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>, textType: number) => {
    const { type } = this.props;
    if (type === InputFilterType.IntegerNumber ||
        type === InputFilterType.DecimalNumber ||
        type === InputFilterType.Currency ||
        type === InputFilterType.Percentage) {
      const regexNumber = type === InputFilterType.IntegerNumber? /^[0-9\b]+$/ : /^(\d+(?:[\.](\d{1,2})?)?)$/;
      if (event.target.value === '' || regexNumber.test(event.target.value)) {
        if (textType === TEXT_TYPE) {
          this.setState({ text: event.target.value });
        } else {
          this.setState({ text2: event.target.value });
        }
      }
    } else {
      if (textType === 1) {
        this.setState({ text: event.target.value });
      } else {
        this.setState({ text2: event.target.value });
      }
    }
  }

  onKeyPress = (event: any) => {
    if(event.key === 'Enter'){
      this.onApply();
    }
  }

  renderInput = (text: string | boolean, textType: number) => {
    const { type, dropdownOptions } = this.props;

    if (type === InputFilterType.Dropdown || type === InputFilterType.Boolean) {
      return (
        <Select
          value={text as string}
          placeholder={this.props.placeholder}
          onChange={e => {
            if (textType === TEXT_TYPE) {
              this.setState({ text: e });
            } else {
              this.setState({ text2: e });
            }
          }}
          dropdownClassName={`input-table-filter__select-drop full`}
          getPopupContainer={this.getPopupContainer}
        >
          {dropdownOptions.map((value, index) => <Select.Option value={value} key={index}>{value}</Select.Option>)}
        </Select>
      );
    }

    if (type === InputFilterType.DateTime || type === InputFilterType.Date) {
      return (
        <DatePicker
          placeholder={this.props.placeholder}
          value={!isEmpty(text) ? moment(text as string, DATE_FORMAT_TABLE_FILTER) : null}
          onChange={(e) => {
            if (textType === TEXT_TYPE) {
              this.setState({ text: e ? e.format(DATE_FORMAT_TABLE_FILTER) : '' });
            } else {
              this.setState({ text2: e ? e.format(DATE_FORMAT_TABLE_FILTER) : '' });
            }
          }}
          format={DATE_FORMAT_TABLE_FILTER} />
      );
    }

    return (
      <Input
        placeholder={this.props.placeholder}
        value={text as string}
        onChange={(e) => this.onChange(e, textType)}
        onKeyPress={this.onKeyPress}
      />
    );
  }

  render(): React.ReactNode {
    const { condition, text, text2 } = this.state;
    const { type, displayConditions } = this.props;

    const options = Object.values(InputFilterOptions[type]);
    const conditionValue = condition ? condition : options[0];
    
    return (
      <div className="input-table-filter">
        <div className="input-table-filter__actions" ref={this.wrapperRef}>
          {displayConditions && (<div className="input-table-filter__select">
            <Select
              value={conditionValue}
              onChange={e => this.setState({ condition: e })}
              dropdownClassName={`input-table-filter__select-drop full`}
              getPopupContainer={this.getPopupContainer}
            >
              {options.map(value => <Select.Option value={value} key={value}><FormattedMessage id={`input.filter.condition.${value}`} /></Select.Option>)}
            </Select>
          </div>)}

          <div className="input-table-filter__input">
            {this.renderInput(text, TEXT_TYPE)}
          </div>

          {conditionValue === InputCommonFilterOption.Between ? (
            <div className="input-table-filter__input">
              {this.renderInput(text2, TEXT2_TYPE)}
            </div>) : null}

          <div className="input-table-filter__footer border-input-top">
            <WhiteButton onClick={this.onReset}>
              <FormattedMessage id="reset" />
            </WhiteButton>
            <BlueButton onClick={this.onApply} style={{ height: '30px !important' }}>
              <FormattedMessage id="apply" />
            </BlueButton>
          </div>
        </div>
      </div>
    );
  }
}
