import React from 'react'
import CreditRow from './credit-row';

const CreditSection = ({ headerTitle, rows }: any) => (
    <div className="card shadow-sm border mb-5">
      <div className="card-header bg-transparent border-bottom">
        <h5 className="card-header-title">
          <i className="bi bi-clipboard-data me-2"></i>
          {headerTitle}
        </h5>
      </div>
      <div className="card-body">
        {rows.map((row, index) => (
          <CreditRow key={index} title={row.title} linkText={row.linkText} />
        ))}
      </div>
    </div>
  );
  
export default CreditSection