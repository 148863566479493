import React from 'react'

const DestinationCard = ({ name, imgSrc, properties }) => (
    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
      <div className="card bg-transparent text-center p-1 h-100">
        <img src={imgSrc} className="rounded-circle" alt={`ImageCard of ${name}`} />
        <div className="card-body p-0 pt-3">
          <h5 className="card-title">
            <a href="#" className="stretched-link">
              {name}
            </a>
          </h5>
          <span>{properties} Properties</span>
        </div>
      </div>
    </div>
  );
export default DestinationCard