import React from 'react';
import { CruisesWrapper } from '../../components/cruises';
import './style.scss';

interface IProps {}

export class CruisesPage extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return <CruisesWrapper />;
  }
}
