import React from 'react';
const TableRow: React.FC<any> = (props) => {
  const { date, description, type, amount, balance } = props;

  return (
    <tr>
      <td>{date}</td>
      <td>
        <a href="#" className="text-dark">
          {description}
        </a>
      </td>
      <td>{type}</td>
      <td className={`text-${amount < 0 ? "danger" : "success"} text-end`}>
        {amount}
      </td>
      <td className="fw-bolder text-end">{balance}</td>
    </tr>
  );
};

export default TableRow;
