import React from 'react';
import { connect } from 'react-redux';
import { notification, Tabs } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';
import format from 'date-fns/format';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  IAdminHotelBookingDetails,
  IAdminLastReminder,
  BookingHotelDetailsActionsEnum,
  HotelBookingStatusEnum,
  BookingTabsEnum,
} from '@common-types';
import {
  selectAdminHotelBookingsDetails,
  getAdminHotelDetails,
  selectAdminHotelBookingsDetailsShowReminderModal,
  adminHotelDetailsActions,
  selectAdminHotelBookingsDetailsShowCanceledModal,
  getLastReminder,
  cancelAdminHotelDetails,
  IHotelAdminDetailsState,
  IAdminReminderState,
} from '@store/slices';
import { ErrorSmallSvg, SuccessSmallSvg, InfoDarkSvg } from '@assets';
import { DATE_FORMAT_FULL_DATA, DATE_FORMAT_CANCELED } from '@constants';
import { ICancelData } from '@api';
import { BookingHotelUnit } from '../hotel-unit';
import { BookingsStatus } from '../booking-status';
import { ReminderModal } from '../reminder-modal';
import { CancelHotelBookingModal } from '../cancel-hotel-booking-modal';
import { BookingOrderDetails, BookingPaymentDetails } from './components';
import './style.scss';
import { BlueButton, CustomSelect } from '@share/components';
import { RootState } from '@share/utils';

enum TabsEnum {
  OrderDetails = 'OrderDetails',
  PaymentDetails = 'Payment Details',
}

interface IMapStateToProps {
  hotelDetails: IAdminHotelBookingDetails;
  lastReminder: IAdminLastReminder;
  loadingLastReminder: boolean;
  showReminderModal: boolean;
  showCanceledModal: boolean;
  adminHotelDetailsStore: IHotelAdminDetailsState;
  adminReminderStore: IAdminReminderState;
}

interface IMapDispatchToProps {
  getAdminHotelDetails: (id: number) => void;
  setShowReminderModal: (show: boolean) => void;
  setShowCanceledModal: (show: boolean) => void;
  cancelAdminHotelDetails: (data: ICancelData) => void;
  getLastReminder: (orderId: number, bookingTab: BookingTabsEnum) => void;
}

interface IParams {
  id: string;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    WrappedComponentProps,
    RouteComponentProps<IParams> {}

interface IState {
  tab: TabsEnum;
}

const options: LabeledValue[] = [
  {
    label: <FormattedMessage id="all.actions" />,
    value: BookingHotelDetailsActionsEnum.AllActions,
  },
  {
    label: <FormattedMessage id="send.reminder" />,
    value: BookingHotelDetailsActionsEnum.SendReminder,
  },
  {
    label: <FormattedMessage id="cancel" />,
    value: BookingHotelDetailsActionsEnum.Cancel,
  },
];

const ZERO = 0;

class BookingHotelDetailsComponent extends React.Component<IProps, IState> {
  state: IState = {
    tab: TabsEnum.OrderDetails,
  };

  changeTab = (tab: string) => {
    this.setState({ tab: tab as TabsEnum });
  };

  getComponentByTab = (): React.ReactNode => {
    const map: { [key: string]: React.ReactNode } = {
      [TabsEnum.OrderDetails]: <BookingOrderDetails />,
      [TabsEnum.PaymentDetails]: <BookingPaymentDetails />,
    };

    return map[this.state.tab];
  };

  openRemindModal = () => {
    const { orderId } = this.props.hotelDetails;

    this.props.setShowReminderModal(true);
    this.props.getLastReminder(orderId, BookingTabsEnum.Hotels);
  };

  closeRemindModal = () => {
    this.props.setShowReminderModal(false);
  };

  closeCancelModal = () => {
    this.props.setShowCanceledModal(false);
  };

  openCancelModal = () => {
    this.props.setShowCanceledModal(true);
  };

  onActionSelect = (value: BookingHotelDetailsActionsEnum) => {
    const actionMenuMap: { [key: string]: () => void } = {
      [BookingHotelDetailsActionsEnum.Cancel]: this.openCancelModal,
      [BookingHotelDetailsActionsEnum.SendReminder]: this.openRemindModal,
    };

    actionMenuMap[value]?.();
  };

  cancelBooking = (reason: string) => {
    const { hotelDetails, cancelAdminHotelDetails } = this.props;
    cancelAdminHotelDetails({ orderId: hotelDetails.orderId, reason });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAdminHotelDetails(Number(id));
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { intl, adminHotelDetailsStore, adminReminderStore } = this.props;
    const { loading, error } = adminReminderStore;
    const { loadingCancelBooking, cancelBooking } = adminHotelDetailsStore;

    if (
      prevProps.adminHotelDetailsStore.loadingCancelBooking !== loadingCancelBooking &&
      !loadingCancelBooking
    ) {
      if (cancelBooking?.isCompletedSuccessfully) {
        notification['success']({
          message: intl.formatMessage({ id: 'cancel.booking.successful' }),
          icon: <SuccessSmallSvg />,
          className: 'all-bookings__notification success',
        });
      } else {
        notification['error']({
          message: intl.formatMessage({ id: 'cancel.booking.failed' }),
          icon: <ErrorSmallSvg />,
          className: 'all-bookings__notification error',
        });
      }
    }

    if (prevProps.adminReminderStore.loading !== loading && !loading) {
      notification['success']({
        message: intl.formatMessage({ id: 'reminder.is.successful' }),
        icon: <SuccessSmallSvg />,
        className: 'all-bookings__notification success',
      });
    }

    if (prevProps.adminReminderStore.error.length !== error.length && error.length !== ZERO) {
      notification['error']({
        message: intl.formatMessage({ id: 'reminder.is.failed' }),
        icon: <ErrorSmallSvg />,
        className: 'all-bookings__notification error',
      });
    }
  }

  render(): React.ReactNode {
    const {
      hotelDetails,
      showReminderModal,
      showCanceledModal,
      lastReminder,
      loadingLastReminder,
      adminHotelDetailsStore,
    } = this.props;

    if (!hotelDetails) {
      return null;
    }

    return (
      <div className="booking-details">
        <div className="booking-details__top-section">
          <div className="booking-details__header">
            <FormattedMessage id="hotel.order" /> #{hotelDetails.orderId}
          </div>
          {hotelDetails.bookedOn && (
            <div className="booking-details__description">
              <FormattedMessage id="booked.on" />{' '}
              {format(new Date(hotelDetails.bookedOn), DATE_FORMAT_FULL_DATA)}
            </div>
          )}
          <div className="booking-details__actions">
            <div className="booking-details__actions-left">
              <div className="booking-details__actions-status-section">
                <BookingsStatus status={hotelDetails.status} subStatus={hotelDetails.subStatus} />
                {hotelDetails.status === HotelBookingStatusEnum.Cancelled &&
                  hotelDetails.cancellationDate && (
                    <div className="booking-details__actions-cancelled-date">
                      <FormattedMessage id="on" />{' '}
                      {format(new Date(hotelDetails.cancellationDate), DATE_FORMAT_CANCELED)}
                    </div>
                  )}
                {hotelDetails.failureReason?.length > 0 && (
                  <div className="booking-details__actions-cancelled-date">
                    {hotelDetails.failureReason}
                  </div>
                )}
              </div>
              {hotelDetails.status === HotelBookingStatusEnum.Cancelled &&
                hotelDetails.cancellationReason && (
                  <div className="booking-details__actions-reason">
                    <span>
                      <InfoDarkSvg />
                      <FormattedMessage id="reason" />:
                    </span>
                    {hotelDetails.cancellationReason}
                  </div>
                )}
            </div>
            <div className="booking-details__actions-right">
              {hotelDetails.status === HotelBookingStatusEnum.Confirmed ? (
                <>
                  <BlueButton
                    onClick={this.openCancelModal}
                    loading={adminHotelDetailsStore.loadingCancelBooking}
                  >
                    <FormattedMessage id="cancel.booking" />
                  </BlueButton>
                  <CustomSelect
                    options={options}
                    value={BookingHotelDetailsActionsEnum.AllActions}
                    onChange={this.onActionSelect}
                    disabled={false}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="booking-details__tabs">
            <Tabs activeKey={this.state.tab} onChange={this.changeTab}>
              <Tabs.TabPane
                tab={<FormattedMessage id="order.details" />}
                key={TabsEnum.OrderDetails}
              />
              <Tabs.TabPane
                tab={<FormattedMessage id="payment.details" />}
                key={TabsEnum.PaymentDetails}
              />
            </Tabs>
          </div>
        </div>
        <div className="booking-details__mid">
          <div className="booking-details__mid-left">{this.getComponentByTab()}</div>
          <div className="booking-details__mid-right">
            <BookingHotelUnit details={hotelDetails} />
          </div>
        </div>
        {showReminderModal && lastReminder && !loadingLastReminder && (
          <ReminderModal
            visible={showReminderModal}
            onCancel={this.closeRemindModal}
            orderId={hotelDetails.orderId}
          />
        )}
        {showCanceledModal && (
          <CancelHotelBookingModal
            visible={showCanceledModal}
            onCancel={this.closeCancelModal}
            cancelBooking={this.cancelBooking}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelDetails: selectAdminHotelBookingsDetails(state),
    showReminderModal: selectAdminHotelBookingsDetailsShowReminderModal(state),
    showCanceledModal: selectAdminHotelBookingsDetailsShowCanceledModal(state),
    lastReminder: state.adminReminderStore.lastReminder,
    loadingLastReminder: state.adminReminderStore.loadingLastReminder,
    adminHotelDetailsStore: state.adminHotelDetailsStore,
    adminReminderStore: state.adminReminderStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getAdminHotelDetails,
  cancelAdminHotelDetails,
  getLastReminder,
  setShowReminderModal: adminHotelDetailsActions.setShowReminderModal,
  setShowCanceledModal: adminHotelDetailsActions.setShowCanceledModal,
};

export const BookingHotelDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(BookingHotelDetailsComponent)));
