import React from 'react';

const FilterableHeader = (props) => {
  const { activeBookings, canceledBookings, errorBookings, unconfirmedBookings } = props || {};
  return (
    <div className="d-sm-flex justify-content-sm-between">
      <ul
        className="nav nav-pills nav-pills-primary-soft mb-3 mr-auto"
        id="tour-pills-tab"
        role="tablist"
      >
        <li className="nav-item me-2">
          <a className="nav-link active" data-bs-toggle="tab" href="#tab-active">
            Active ({activeBookings?.length || '0'})
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" href="#tab-canceled">
            Canceled ({canceledBookings?.length || '0'})
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" href="#tab-error">
            Error ({errorBookings?.length || '0'})
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" href="#tab-unconfirmed">
            Unconfirmed ({unconfirmedBookings?.length || '0'})
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FilterableHeader;
