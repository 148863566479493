import React, { useEffect, useState } from 'react';
import img from '@assets/images/heros/homepage.jpg';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from '../date-range-component';
import { LocationSelector } from '../location-component';
import { GuestsRoomsSelector } from '../guest-room-component';
import moment from 'moment';
import arrow from '@assets/images/gallery/right-arrow.svg';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface Room {
  adultsCount: number;
  kidsAges: number[];
}
interface IMapStateToProps {
  loginStore: ILoginState;
}

const DashboardHero: React.FC = () => {
  const login = useSelector((state: IMapStateToProps) => state.loginStore);
  const [checkIn, setCheckIn] = useState<string>('');
  const [checkOut, setCheckOut] = useState<string>('');
  const [rooms, setRooms] = useState<Room[]>([{ adultsCount: 2, kidsAges: [] }]);
  const [location, setLocation] = useState<string>('');
  const history = useHistory();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [locationValid, setLocationValid] = useState<boolean>(true);
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const [showHistoryCard, setShowHistoryCard] = useState<boolean>(false);
  const [uniqueHistoryArray, setUniqueHistoryArray] = useState<any[]>([]);
  const [disable, setDisable] = useState<boolean>(true);

  const currency = 'USD';
  const residency = 'US';
  const STORAGE_KEY: string = 'HOTEL_RECENT_SEARCHES_V2';

  const handleSubmit = async () => {
    const isFormValid = checkIn && checkOut && location;
    if (isFormValid) {
      const dates = { checkIn, checkOut };
      const payload = {
        currency,
        filter: { exactSearch: true },
        hotelsRequest: {
          residency,
          checkIn,
          checkOut,
          location,
          rooms: rooms.map((room) => ({
            adultsCount: room.adultsCount,
            kidsAges: room.kidsAges,
          })),
        },
        pageNumber: 1,
        pageSize: 10,
        quick: false,
        searchHomes: 'All',
        sortBy: 'BiggestSavingsPercentage',
      };

      history.push(
        `/${login.account.name}/search?dates=${btoa(JSON.stringify(dates))}&location=${btoa(
          JSON.stringify(location),
        )}&rooms=${btoa(JSON.stringify(payload.hotelsRequest.rooms))}`,
      );

      const recentSearchesData: any = { location, checkIn, checkOut, rooms };

      const storage: string | null = localStorage.getItem(STORAGE_KEY);
      const existingData: Array<{
        location: {
          code: string;
          name: string;
          type: string;
          geoLocation: {
            latitude: number;
            longitude: number;
          };
        };
        checkIn: string;
        checkOut: string;
        rooms: Array<{
          adultsCount: number;
          kidsAges: number[];
        }>;
      }> = storage ? JSON.parse(storage) : [];
  
      const dataExists = existingData.some(
        (search: any) =>
          search.location === recentSearchesData.location &&
          search.checkIn === recentSearchesData.checkIn &&
          search.checkOut === recentSearchesData.checkOut &&
          search.rooms === recentSearchesData.rooms,
      );
  
      if (!dataExists) {
        existingData.push(recentSearchesData);
      }
  
      localStorage.setItem(STORAGE_KEY, JSON.stringify(existingData));
      
    } else {
      setIsFormValid(false);
      setLocationValid(!!location);
      setIsDateValid(!!(checkIn && checkOut));
    }
  };

  const handleDateChange = (newCheckIn: string, newCheckOut: string) => {
    setCheckIn(newCheckIn);
    setCheckOut(newCheckOut);
  };

  const handleLocationSelect = (selectedLocation: string) => {
    setLocation(selectedLocation);
    setLocationValid(true);
  };

  const handleSearchChange = (searchInput: string) => {
    setLocation(searchInput);
    setLocationValid(true);
  };

  const recentSearchHandleSubmit = (location, rooms, checkIn, checkOut) => {
    const dates = { checkIn, checkOut };

    history.push(
      `/${login.account.name}/search?dates=${btoa(JSON.stringify(dates))}&location=${btoa(
        JSON.stringify(location),
      )}&rooms=${btoa(JSON.stringify(rooms))}`,
    );
  };

  const cards = document.querySelectorAll('.history-card-text');

  cards.forEach((card: any) => {
    card.addEventListener('mouseenter', () => {
      card.style.transition = 'transform 0.3s ease-in, box-shadow 0.3s ease-in';
    });

    card.addEventListener('mouseleave', () => {
      card.style.transition = 'transform 0.9s ease-out, box-shadow 0.9s ease-out';
    });
  });

  function truncateString(str, num) {
    if (str?.length <= num) {
      return str; // Return the original string if it is shorter than the maximum length
    }
    return str?.slice(0, num) + '...'; // Truncate the string and append ellipsis
  }

  useEffect(() => {
    const storageData = localStorage.getItem('HOTEL_RECENT_SEARCHES_V2');
    if (storageData) {
      const historyData = JSON.parse(storageData);
      if (historyData.length > 0) {
        const recentSearches = historyData.slice(-2);
        setUniqueHistoryArray(recentSearches.reverse());
        setShowHistoryCard(recentSearches.length > 0);
      }
    }
  }, []);

  const totalKids = (search) =>
    search?.rooms?.reduce((total, room) => {
      return total + (room?.kidsAges?.length || 0);
    }, 0);

  return (
    <section className="pt-0">
      <div
        className="py-6 container-fluid dark-overlay"
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: 'center left',
          backgroundSize: 'cover',
        }}
      >
        <div className="row">
          <div className="col-md-6 mx-auto text-center pt-7 pb-5 pb-sm-6 pb-lg-9">
            <h1 className="text-white">Begin your search</h1>
            <p className="lead text-white mb-0">
              Effortlessly search through discounted corporate lodging options, worldwide!
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-n7">
        <div className="row">
          <div className="col-12">
            <div className="card shadow rounded-3 p-4">
              <div className="row g-4 align-items-center">
                <div className="col-xl-10">
                  <div className="row g-4">
                    <div className={`col-lg-4 `}>
                      <LocationSelector
                        onLocationSelect={handleLocationSelect}
                        searchTerm={location}
                        onSearchChange={handleSearchChange}
                        locationValid={locationValid}
                        showLocationHistory={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <DateRangePicker
                        checkIn={checkIn}
                        checkOut={checkOut}
                        onDateChange={handleDateChange}
                        validate={isFormValid}
                        isDateValid={isDateValid}
                      />
                    </div>
                    <div className="col-lg-4">
                      <GuestsRoomsSelector
                        setDisable={setDisable}
                        rooms={rooms}
                        setRooms={setRooms}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="d-grid">
                    <button onClick={handleSubmit} className="btn btn-lg btn-primary mb-0 mt-2">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showHistoryCard && (
            <section className="pt-4">
              <h5 className="mx-4">Your recent searches</h5>
              <div className="row">
                {uniqueHistoryArray?.map((search, index) => {
                  const checkIn = moment(search.checkIn).format('MMM-DD');
                  const checkOut = moment(search.checkOut).format('MMM-DD YYYY');
                  return (
                    <div className="col-lg-6 col-md-12" key={search.location.name}>
                      <div className="card card-body history-card-text  border-1 shadow-sm p-2">
                        <div className="">
                          <div className="mx-3 ">
                            <div className="">
                              <div>
                                <h6 className="mb-1 mt-1">
                                  <i className="bi bi-geo-alt fs-8 me-2 mt-3 location-icon"></i>
                                  <span title={search.location.name}>
                                    {truncateString(search.location.name, 50)}
                                  </span>
                                </h6>

                                <div className="d-flex justify-content-between align-items-end flex-wrap">
                                  <div className="mt-2 mx-2">
                                    <span className="mb-1 mt-1 history-card-text-transform">
                                      {checkIn}
                                    </span>{' '}
                                    -{' '}
                                    <span className=" mt-1 history-card-text-transform">
                                      {checkOut}
                                    </span>
                                    <p className="history-card-text-transform">
                                      Adult Count:{' '}
                                      {search?.rooms.length < 0
                                        ? 1
                                        : search?.rooms.reduce(
                                            (total, room) => total + room.adultsCount,
                                            0,
                                          )}
                                      {', '}
                                      {search?.rooms.length <= 1 ? 'Room' : 'Rooms'}:{' '}
                                      {search?.rooms?.length}
                                      {totalKids(search) > 0 ? ', ' : ''}
                                      {totalKids(search) > 0 && `Kids: ${totalKids(search)}`}
                                    </p>
                                  </div>
                                  <button
                                    className="recent-history-search pb-2"
                                    onClick={() =>
                                      recentSearchHandleSubmit(
                                        search.location,
                                        search.rooms,
                                        search.checkIn,
                                        search.checkOut,
                                      )
                                    }
                                  >
                                    Search Again
                                    <img src={arrow} width={25} className="mx-2" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          )}
        </div>
      </div>
    </section>
  );
};

export { DashboardHero };
