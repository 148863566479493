import React from 'react';
import { RootState } from '@share/utils';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { NEGATIVE, VALUE_ALL } from '@constants';
import {Routes } from '@share/constants';
import { CloseCircleSvg, SmallCloseCircleSvg } from '@assets';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import {
  IAdminPresentationRulesState,
  IAdminPriceRulesState,
  setRuleExists,
  setPresentationRuleExists,
  setDuplicatePresentationId,
} from '@store/slices';
import { INewPresentationRule, INewPriceRule } from '@common-types';
import './style.scss';

interface IMapStateToProps {
  adminPriceRules: IAdminPriceRulesState;
  adminPresentationRules: IAdminPresentationRulesState;
}

interface IMapDispatchToProps {
  setRuleExists: (value: boolean) => void;
  setPresentationRuleExists: (value: boolean) => void;
  setDuplicatePresentationId: (value: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isPresentationRule?: boolean;
}

interface IState {
  duplicateRule: INewPriceRule;
  duplicatePresentationRule: INewPresentationRule;
}

class RuleAlreadyExistsComponent extends React.Component<IProps, IState> {
  state: IState = {
    duplicateRule: null,
    duplicatePresentationRule: null,
  };

  getRule = () => {
    if (this.props.isPresentationRule) {
      const { presentationRules, newPresentationRule } = this.props.adminPresentationRules;
      const newSiteId =
        newPresentationRule.siteId === VALUE_ALL ? NEGATIVE : newPresentationRule.siteId;

      const duplicatePresentationRule = presentationRules.find(
        (item) => item.siteId === newSiteId || item.name === newPresentationRule.name,
      );

      this.props.setDuplicatePresentationId(duplicatePresentationRule.id);

      return this.setState({ duplicatePresentationRule });
    } else {
      const { priceRules, newPriceRule } = this.props.adminPriceRules;
      const newSiteId = newPriceRule.siteId === VALUE_ALL ? NEGATIVE : newPriceRule.siteId;
      const newSupplier = newPriceRule.supplier === VALUE_ALL ? '' : newPriceRule.supplier;
      const getDuplicateRule = priceRules.find(
        (item) =>
          (item.supplier === newSupplier && item.siteId === newSiteId) ||
          item.name === newPriceRule.name,
      );

      return this.setState({ duplicateRule: getDuplicateRule });
    }
  };

  changeConfigurations = () => {
    this.props.setRuleExists(false);
    this.props.setPresentationRuleExists(false);
  };

  redirectToRuleExists = (): void => {
    if (this.props.isPresentationRule) {
      this.props.history.push(`${Routes.PresentationRules}`);
    } else {
      this.props.history.push(`${Routes.PriceRules}`);
    }
  };

  componentDidMount() {
    this.getRule();
  }

  render(): React.ReactNode {
    const { duplicateRule, duplicatePresentationRule } = this.state;
    const { isPresentationRule } = this.props;

    return (
      <div className="rule-exists">
        <CloseCircleSvg />
        <p className="rule-exists__title">
          <FormattedMessage id="rule.already.exists" />
        </p>
        <p className="rule-exists__description">
          <FormattedMessage id="rule.can’t.be.activated" />
        </p>
        <div className="rule-exists__block">
          <p className="rule-exists__block-title">
            <FormattedMessage id="rule.you.submitted" />
          </p>
          <div className="rule-exists__block-info">
            <p className="rule-exists__block-info-rule">
              <SmallCloseCircleSvg /> <FormattedMessage id="rule" />{' '}
              {duplicatePresentationRule?.id || duplicateRule?.id}
            </p>
            <p className="rule-exists__block-info-link" onClick={this.redirectToRuleExists}>
              <FormattedMessage id="view" />
            </p>
          </div>
        </div>
        <div className="rule-exists__btn-wrapper">
          <BlueButton onClick={this.changeConfigurations}>
            <FormattedMessage id="change.configurations" />
          </BlueButton>
          <Link
            to={isPresentationRule ? Routes.PresentationRules : Routes.PriceRules}
            onClick={this.changeConfigurations}
          >
            <WhiteButton>
              <FormattedMessage id="cancel" />
            </WhiteButton>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPriceRules: state.adminPriceRulesStore,
    adminPresentationRules: state.adminPresentationRulesStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setRuleExists,
  setPresentationRuleExists,
  setDuplicatePresentationId,
};

export const RuleAlreadyExists = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RuleAlreadyExistsComponent));

