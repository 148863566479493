
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAdminHotelBookingDetails } from '@common-types';
import { ICancelHotelBooking } from '@share/common-types';
import { hotelBookingsAdminAPI, ICancelData } from '@api';
import { AppThunk } from '@share/utils';

export interface IHotelAdminDetailsState {
  hotelDetails: IAdminHotelBookingDetails;
  cancelBooking: ICancelHotelBooking;
  loading: boolean;
  showReminderModal: boolean;
  showCanceledModal: boolean;
  loadingCancelBooking: boolean;
  errorCancelBooking: string;
  error: string;
}

const initialState: IHotelAdminDetailsState = {
  hotelDetails: null,
  loading: true,
  showReminderModal: false,
  showCanceledModal: false,
  loadingCancelBooking: false,
  errorCancelBooking: '',
  cancelBooking: {
    isCompletedSuccessfully: false,
    isCancellable: false,
    isTryAgainLater: false,
    isPending: false,
  },
  error: '',
};

const adminHotelDetailsSlice = createSlice({
  name: 'admin-hotel-details',
  initialState,
  reducers: {
    setLoading: (state: IHotelAdminDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IHotelAdminDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setDetails: (
      state: IHotelAdminDetailsState,
      { payload }: PayloadAction<IAdminHotelBookingDetails>,
    ) => {
      state.hotelDetails = payload;
    },
    setShowReminderModal: (state: IHotelAdminDetailsState, { payload }: PayloadAction<boolean>) => {
      state.showReminderModal = payload;
    },
    setShowCanceledModal: (state: IHotelAdminDetailsState, { payload }: PayloadAction<boolean>) => {
      state.showCanceledModal = payload;
    },
    setCancelBooking: (
      state: IHotelAdminDetailsState,
      { payload }: PayloadAction<ICancelHotelBooking>,
    ) => {
      state.cancelBooking = {
        ...state.cancelBooking,
        ...payload,
      };
    },
    setLoadingCancelBooking: (
      state: IHotelAdminDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loadingCancelBooking = payload;
    },
    setErrorCancelBooking: (state: IHotelAdminDetailsState, { payload }: PayloadAction<string>) => {
      state.errorCancelBooking = payload;
    },
  },
});

export const adminHotelDetailsActions = adminHotelDetailsSlice.actions;
export const adminHotelDetailsReducer = adminHotelDetailsSlice.reducer;

export const getAdminHotelDetails = (orderId: number): AppThunk => {
  return async (dispatch) => {
    dispatch(adminHotelDetailsActions.setLoading(true));

    try {
      const response = await hotelBookingsAdminAPI.getHotelBookingsDetails(orderId);
      dispatch(adminHotelDetailsActions.setLoading(false));
      dispatch(adminHotelDetailsActions.setDetails(response.data.hotelOrderDetails));
    } catch (error) {
      console.error(error);
      dispatch(adminHotelDetailsActions.setError(error.toString()));
      dispatch(adminHotelDetailsActions.setLoading(false));
    }
  };
};

export const cancelAdminHotelDetails = (data: ICancelData): AppThunk => {
  return async (dispatch, getState) => {
    const { hotelDetails } = getState().adminHotelDetailsStore;

    dispatch(adminHotelDetailsActions.setLoadingCancelBooking(true));

    try {
      const response = await hotelBookingsAdminAPI.cancel(data);
      dispatch(adminHotelDetailsActions.setCancelBooking(response.data));
      dispatch(adminHotelDetailsActions.setLoadingCancelBooking(false));

      if (response.data.isCompletedSuccessfully) {
        dispatch(getAdminHotelDetails(hotelDetails.orderId));
      }
    } catch (error) {
      console.error(error);
      dispatch(adminHotelDetailsActions.setErrorCancelBooking(error.toString()));
      dispatch(adminHotelDetailsActions.setLoadingCancelBooking(false));
    }
  };
};

