import React from 'react';

interface TabContentProps {
  title: string;
  content: string;
  imgSrc: string;
}

const TabContent: React.FC<TabContentProps> = (props) => {
  const { title, content, imgSrc } = props;

  return (
    <div className="card shadow p-4">
      <div className="row">
        <div className="col-lg-3 col-md-12">
          <div className="card-header bg-transparent border-bottom p-0 pb-3">
            <h3 className="mb-0">{title}</h3>
          </div>
          <div className="card-body p-0 pt-3">
            <p className="mb-4 lead">{content}</p>
          </div>
        </div>
        <div className="col-lg-9 col-md-12 position-relative">
          <img src={imgSrc} className="rounded-2 position-relative shadow" alt={title} />
        </div>
      </div>
    </div>
  );
};

export default TabContent;
