import React from 'react';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover } from 'antd';
import { isEmpty } from 'lodash';

import { getBookRoutePath, getToken, renderNumber, RootState } from '@share/utils';
import { IPackage, IPossibleStay, RefundableEnum } from '@common-types';
import { Currency, ModalCancellationPolicies } from '@components';
import { BlueButton } from '@share/components';
import { InfoSvg } from '@assets';
import { hotelsActions, IHotelsState, ILoginState, IMarginatorState, LoginType } from '@share/store/slices';
import { D_RESERVE_BUTTON } from '@share/constants';
import { getAccountLocale } from '@i18n';
import { IRoomsSearchState, setSelectedHotelReviewClientCash, setSelectedHotelReviewClientCashStr } from '@store/slices';

import './style.scss';
import { IClientCash } from '@share/common-types';

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
  roomsSearchStore: IRoomsSearchState;
  hotelsStore: IHotelsState;
}

interface IMapDispatchToProps {
  setSelectedHotelReviewClientCash: (selectedClientCash: number) => void;
  setSelectedHotelReviewClientCashStr: (selectedClientCash: string) => void;
  setSelectedHotelSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isVacationRentals?: boolean;
  possibleStays: IPossibleStay[];
  groupedPackages: IPackage;
  hotelId: number;
}

interface IState {
  isModalCancellationPoliciesVisible: boolean;
  cancellationPoliciesText: string;
  refundabilityText: string;
  isChangedCancellationPolicy: boolean;
  isSessionExpired: boolean;
}

class RoomsSearchPossibleStaysComponent extends React.Component<IProps, IState> {
  state: IState = {
    isModalCancellationPoliciesVisible: false,
    cancellationPoliciesText: '',
    refundabilityText: '',
    isChangedCancellationPolicy: false,
    isSessionExpired: false
  };

  showCancellationPolicies = (packageInfo: any): void => {
    this.setState({
      isModalCancellationPoliciesVisible: true,
      cancellationPoliciesText: packageInfo.cancellationPolicyText,
      refundabilityText: packageInfo.refundabilityText,
    });
  };

  getReserveRoomUrl = (selectedPackageId: string): string => {
    const { hotelId, history, isVacationRentals } = this.props;
    return getBookRoutePath(hotelId, selectedPackageId, history, isVacationRentals);
  };

  handleReserve = (selectedPackageId: string) => {
    const { history, loginStore } = this.props;
    const { account } = loginStore;

    const token = getToken(account);
    if (account?.type === LoginType.PrivateWithToken && !token) {
      this.setState({ isSessionExpired: true }); 
    } else {

      const { hotelsStore } = this.props;
      const { selectedHotelSearchClientCash } = hotelsStore;
  
      const selectedPropertyClientCash = selectedHotelSearchClientCash?.selectedPropertyClientCash;
      this.props.setSelectedHotelReviewClientCash(selectedPropertyClientCash);
      this.props.setSelectedHotelReviewClientCashStr(selectedPropertyClientCash ? selectedPropertyClientCash.toString() : '');
      this.props.setSelectedHotelSearchClientCash({ ...selectedHotelSearchClientCash, selectedPropertyReviewClientCash: selectedPropertyClientCash });

      history.push(this.getReserveRoomUrl(selectedPackageId));

      ReactGA.event({
        category: account.name,
        action: `${D_RESERVE_BUTTON}_${account.name.toUpperCase()}`,
        label: `User clicked reserve button on detail`,
        nonInteraction: false,
      });
    }
  }

  render(): React.ReactNode {
    const { possibleStays, groupedPackages, marginatorStore, loginStore, roomsSearchStore } = this.props;

    if (!possibleStays || possibleStays?.length <= 1) {
      return null;
    }

    const { error } = roomsSearchStore;

    return (
      <div className="rooms-search__possible-stays-list-wrapper">
        <label className="rooms-search__possible-stays-title"><FormattedMessage id="room.search.alternate_dates" /></label>
        <label className="rooms-search__possible-stays-message"><FormattedMessage id="room.search.options" /></label>

        {possibleStays.slice(1).map((possibleStay: IPossibleStay, index: number) => {
          const marginatorPrice = possibleStay.price / (1 - (marginatorStore.value / 100));
          const displayRefundable = groupedPackages.refundability === RefundableEnum.Refundable || groupedPackages.refundability === RefundableEnum.Nonrefundable;
          const { account } = loginStore;

          const checkIn = moment(possibleStay.checkIn, 'yyyy-MM-DD');
          const checkOut = moment(possibleStay.checkOut, 'yyyy-MM-DD');
          const checkInFormat = checkIn.year() === checkOut.year() ? 'dddd, DD MMMM' : 'dddd, DD MMMM, yyyy';
          const locale = getAccountLocale(account);

          return (
            <div key={index} className="rooms-search__possible-stays-item">
              <div className="rooms-search__possible-stays-item-dates">
                <label>
                  {checkIn.locale(locale).format(checkInFormat)} - {checkOut.locale(locale).format('dddd, DD MMMM, yyyy')}
                </label>
              </div>
              <div className="rooms-search__possible-stays-item-rest">
                <div className="rooms-search__possible-stays-item-price">
                  <div className={`${displayRefundable ? 'col-8' : 'col-12'} rooms-search__possible-stays-item-price-details`}>
                    <p className="rooms-search__possible-stays-item-price-value">
                      <Currency currency={groupedPackages.currency} />{renderNumber(marginatorPrice, 2)}
                    </p>
                    <div className="rooms-search__possible-stays-item-price-message">
                      <span>
                        <FormattedMessage id="for" />{' '}
                        <FormattedMessage id="nights" values={{ count: possibleStay.days }} />{' '}
                      </span>
                      <span style={{ marginRight: '8px' }}>
                        <FormattedMessage id={account?.breakdownTaxes ? 'abbreviated.price.without.taxes.fees' : 'abbreviated.price.includes.taxes.fees'} />
                      </span>
                    </div>
                  </div>
                  {displayRefundable ? (<div className="col-4 rooms-search__possible-stays-item-price-refundable">
                    {groupedPackages.refundability === RefundableEnum.Refundable && (
                      <div className="rooms-search__refundable-ref" onClick={() => this.showCancellationPolicies(groupedPackages)}>
                        <Popover
                          overlayClassName="overlay-popover"
                          content={<FormattedMessage id="changed.cancellation.policy" />}
                          visible={this.state.isChangedCancellationPolicy}
                          placement="bottomLeft"
                        >
                          <FormattedMessage id="refundable" />
                          <InfoSvg />
                        </Popover>

                      </div>
                    )}

                    {groupedPackages.refundability === RefundableEnum.Nonrefundable && (
                      <div className="rooms-search__refundable-nonref"onClick={() => this.showCancellationPolicies(groupedPackages)}>
                        <Popover
                          overlayClassName="overlay-popover"
                          content={<FormattedMessage id="changed.cancellation.policy" />}
                          visible={this.state.isChangedCancellationPolicy}
                          placement="bottomLeft"
                        >
                          <FormattedMessage id="non.refundable" />
                          <InfoSvg />
                        </Popover>
                      </div>
                    )}

                  </div>) : null}
                </div>
                <div className="rooms-search__possible-stays-item-button">
                  <div className={`rooms-search__bottom rooms-search__action`}>
                    <BlueButton disabled={!isEmpty(error)} onClick={() => this.handleReserve(possibleStay.packageId)} className="btn btn-block w-100">
                      <FormattedMessage id="reserve" />
                    </BlueButton>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <ModalCancellationPolicies
          cancellationPoliciesText={this.state.cancellationPoliciesText}
          refundabilityText={this.state.refundabilityText}
          visible={this.state.isModalCancellationPoliciesVisible}
          onCancel={() => this.setState({ isModalCancellationPoliciesVisible: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,    
    roomsSearchStore: state.roomsSearchStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedHotelReviewClientCash,
  setSelectedHotelReviewClientCashStr,
  setSelectedHotelSearchClientCash: hotelsActions.setSelectedHotelSearchClientCash,
};

export const RoomsSearchPossibleStays = connect(mapStateToProps, mapDispatchToProps)(withRouter(RoomsSearchPossibleStaysComponent));

