import React from 'react';
import { connect } from 'react-redux';

import { SearchRsi, SearchTravcoding } from '@components';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { TRAVCODING_HOME_TEMPLATE, TRAVCODING_V2 } from '@share/common-types';
import DashboardSearch from '../../pages/dashboard-search/component';
import LogInNav from '../../components/common/log-in-nav/component';
import Footer from '../../components/new-home/footer/component';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  isVacationRentals?: boolean;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class SearchPageComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { loginStore, isVacationRentals } = this.props;
    const { account } = loginStore;
    const homePageTemplate = account?.homePageTemplate;
    const isRSITemplate = account?.isRSITemplate;

    return (
      <div className="search-page">
        {(!homePageTemplate || homePageTemplate === TRAVCODING_HOME_TEMPLATE) && (
          <SearchTravcoding isVacationRentals={isVacationRentals} />
        )}
        {account?.homePageTemplate !== TRAVCODING_V2 && isRSITemplate && (
          <SearchRsi isVacationRentals={isVacationRentals} />
        )}
        {!isRSITemplate && homePageTemplate === TRAVCODING_V2 && (
            <DashboardSearch />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const SearchPage = connect(mapStateToProps)(SearchPageComponent);
