
import React from 'react';

import { getCurrencyText } from '@share/utils';

import './style.scss';

interface IProps {
  currency: string;
}

export class Currency extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { currency } = this.props;
    return getCurrencyText(currency);
  }
}
