import React from 'react';

import isUndefined from 'lodash/isUndefined';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { add, format, getMonth } from 'date-fns';
import { connect } from 'react-redux';

import { BlueButton } from '@share/components';
import { InfoSvg, CheckMarkSvg } from '@assets';
import { DATE_FORMAT_MONTH, DATE_FORMAT_MONTH_END, DATE_FORMAT_MONTH_END_DIFFERENT, DATE_FORMAT_MONTH_START, DATE_FORMAT_MONTH_START_DIFFERENT, TRAVEL_GUARD_LINK } from '@constants';
import { Routes } from '@share/constants';
import { renderPriceBasedOn, renderGetawaysDetailsTitle, renderGetawaysCardDate } from '@utils';
import {
  IGetawayDetails,
  GetawayAmenity,
  GetawayIncludedItem,
  GetawaysLocationEnum,
} from '@share/common-types';
import { formatMoneyCurrency, getDateCheckInOut, getSelectedCurrency, RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';
import { getAccountDateFnsLocale } from '@i18n';

import GetawayBackground from '@assets/images/getaways/getaways-list-bg.png';

import './style.scss';

const ZERO = 0;
const ONE = 1;
const SIX = 6;
const MAX_DESCRIPTION_LENGTH = 1250;

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  getaway: IGetawayDetails;
}

interface IState {
  isEllipsis: boolean;
  isImageError: boolean;
}

class GetawayDetailsInfoComponent extends React.Component<IProps, IState> {
  state: IState = {
    isEllipsis: false,
    isImageError: false,
  };

  groupAmenities = (): GetawayAmenity[][] => {
    const { getaway } = this.props;

    const amenities = getaway.amenities?.reduce((acc, curr) => {
      const currentValue = acc[curr.amenityType] ? [...acc[curr.amenityType], curr] : [curr];

      return { ...acc, [curr.amenityType]: currentValue };
    }, {} as { [field: string]: GetawayAmenity[] });

    return Object.values(amenities);
  };

  onImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    if (event) {
      this.setState({ isImageError: !this.state.isImageError });
    }
  };

  showMoreToggler = (): void => {
    this.setState({ isEllipsis: !this.state.isEllipsis });
  };

  render(): React.ReactNode {
    const { isEllipsis, isImageError } = this.state;
    const { getaway, loginStore } = this.props;
    const {
      title,
      promoId,
      videoUrl,
      imageUrl,
      amenities,
      specialType,
      youPayPrice,
      description,
      includedItems,
      costDisclaimer,
      importantInformation,
      portsOfCall,
      priceBasedOn,
      city,
      state,
      country,
      unitSize,
      numberOfNights,
      lengthOfStay,
      checkinDate,
    } = getaway;
    const { specialTypeName } = specialType;
    const activeGetaway = specialTypeName.toLowerCase();
    const activeGetawaySubcategory = location.pathname.split('/')[SIX];
    const isTypeNameStaycation = activeGetaway === GetawaysLocationEnum.Staycation;
    const isMore = description.length > MAX_DESCRIPTION_LENGTH;

    const isTypeNameCondo = activeGetaway === GetawaysLocationEnum.Condo;
    const isTypeNameCruise = activeGetaway === GetawaysLocationEnum.Cruise;

    const { account } = loginStore;
    const locale = getAccountDateFnsLocale(account);
    const currency = getSelectedCurrency(account);

    const checkInDate = getDateCheckInOut(checkinDate);
    const formattedDate = checkinDate && format(checkInDate, DATE_FORMAT_MONTH, { locale });

    const checkInDateWithNights = add(checkInDate, {
      days: numberOfNights > ONE ? numberOfNights - ONE : numberOfNights,
    });

    const checkInEndDate = new Date(checkInDateWithNights);
    const startMonth = getMonth(checkInDate);
    const endMonth = getMonth(checkInEndDate);
    const isDifferentMonths = startMonth !== endMonth;

    const formattedStartDate = checkinDate && format(checkInDate, DATE_FORMAT_MONTH_START, { locale });
    const formattedStartDateDifferent =
      checkinDate && format(checkInDate, DATE_FORMAT_MONTH_START_DIFFERENT, { locale });
    
    const formattedEndDate = checkinDate && format(checkInDateWithNights, DATE_FORMAT_MONTH_END, { locale });
    const formattedEndDateDifferent =
      checkinDate && format(checkInDateWithNights, DATE_FORMAT_MONTH_END_DIFFERENT, { locale });

    const staycationDate = checkInDate && (isDifferentMonths
      ? `${formattedStartDate}-${formattedEndDate}`
      : `${formattedStartDateDifferent} - ${formattedEndDateDifferent}`);

    return (
      <div className="getaway__container">
        <Link
          className="getaway__details-back"
          to={`/${account?.name}${Routes.Getaway}/${activeGetaway}/${activeGetawaySubcategory}`}
        >
          <FormattedMessage id="back.to.list" />
        </Link>
        <div className="getaway__details-wrapper">
          <div className="getaway__details-header">
            <div>
              <h1 className="getaway__details-header_title">{title}</h1>
              <p className="getaway__details-header_promo">
                <FormattedMessage id="promo.id" />: {promoId}
              </p>

              {isTypeNameCruise && (
                <>
                  {portsOfCall.length ? (
                    portsOfCall.map((port: string, index: number) => (
                      <p className="getaway__list-item_info_location_cruise" key={`${port}-${index}`}>
                        {port}
                      </p>
                    ))
                  ) : (
                    <p className="getaway__list-item_info_location_cruise">
                      <FormattedMessage id="no.data.available" />
                    </p>
                  )}

                  <p className="getaway__list-item_info_location_cruise">
                    <FormattedMessage id="ship" /> {name}
                  </p>
                </>)}
              {isTypeNameCondo && (
                <p className="getaway__list-item_info_location">
                  {city} {state} {country} | {unitSize}
                </p>)}

                <p
                  className={`getaway__list-item_info_date_text ${
                    !!isTypeNameCruise ? 'getaway__list-item_info_date_text_cruise' : ''
                  }`}
                >
                  {renderGetawaysCardDate(
                    activeGetaway,
                    formattedDate,
                    staycationDate,
                    lengthOfStay,
                    city,
                    numberOfNights,
                    state,
                  )}
                </p>

            </div>
            <div>
              <p
                className={`getaway__details-header_price ${
                  isTypeNameStaycation ? 'getaway__details-header_price_staycation' : ''
                }`}
              >
                {formatMoneyCurrency(currency).format(youPayPrice)}{' '}
                <span>{renderPriceBasedOn(activeGetaway)}</span>
                <Tooltip
                  title={priceBasedOn}
                  overlayClassName="getaway__list-item_info_tooltip"
                  placement="bottom"
                >
                  <div className="bookings-title-wrapper">
                    <InfoSvg />
                  </div>
                </Tooltip>
              </p>
              <Link
                to={`/${account?.name}${Routes.GetawayBookingPage}/${promoId}/${activeGetaway}/${
                  !isUndefined(activeGetawaySubcategory) ? activeGetawaySubcategory : ''
                }`}
                className="getaway__details-header_book"
              >
                <BlueButton>
                  <FormattedMessage id="book.getaway" />
                </BlueButton>
              </Link>
            </div>
          </div>
          <div className="getaway__details-media">
            <img
              onError={(event) => this.onImageError(event)}
              className={`getaway__details-media_img ${
                videoUrl ? 'getaway__details-media_item' : ''
              }`}
              src={isImageError || !imageUrl ? GetawayBackground : imageUrl}
              alt={title}
            />
            {videoUrl && (
              <div className="getaway__details-media_item">
                <iframe className="getaway__details-media_item_youtube" src={`${videoUrl}`} />
              </div>
            )}
          </div>
          <div className="getaway__details-content">
            <div className="getaway__details-info">
              <h2 className="getaway__details-info_title">
                {renderGetawaysDetailsTitle(activeGetaway)}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: description.slice(
                    0,
                    isMore
                      ? !isEllipsis
                        ? MAX_DESCRIPTION_LENGTH
                        : description.length
                      : description.length,
                  ),
                }}
                className="getaway__details-info_text"
              />
              {isMore && (
                <div className="getaway__details-info_more" onClick={this.showMoreToggler}>
                  {isEllipsis ? (
                    <FormattedMessage id="show.less" />
                  ) : (
                    <FormattedMessage id="show.more" />
                  )}
                </div>
              )}
              <h2 className="getaway__details-info_title">
                <FormattedMessage id="important.information" />
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: importantInformation,
                }}
                className="getaway__details-info_text"
              />
              <div className="getaway__details-info_warning">
                <InfoSvg />
                <p className="getaway__details-info_warning_text">{costDisclaimer}</p>
              </div>
            </div>
            <div className="getaway__details-sidebar">
              <div>
                <h2 className="getaway__details-sidebar_title">
                  <FormattedMessage id="whats.included" />
                </h2>
                {!includedItems.length && !amenities.length ? (
                  <p className="getaway__details-sidebar_item_header_text">
                    <FormattedMessage id="no.items.available" />
                  </p>
                ) : (
                  <div>
                    {this.groupAmenities().map((item: GetawayAmenity[], index: number) => (
                      <div
                        key={`${item[ZERO].amenityType}-${index}`}
                        className="getaway__details-sidebar_item"
                      >
                        <div className="getaway__details-sidebar_item_header">
                          <CheckMarkSvg />
                          <p className="getaway__details-sidebar_item_header_text">
                            {item[ZERO].amenityType}
                          </p>
                        </div>
                        {item.map((amenity: GetawayAmenity) => (
                          <p key={amenity.name} className="getaway__details-sidebar_item_text">
                            {amenity.name}
                          </p>
                        ))}
                      </div>
                    ))}
                    {includedItems.map((item: GetawayIncludedItem, index: number) => (
                      <div key={`${item.name}-${index}`} className="getaway__details-sidebar_item">
                        <div className="getaway__details-sidebar_item_header">
                          <CheckMarkSvg />
                          <p className="getaway__details-sidebar_item_header_text">{item.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <div className="getaway__details-sidebar_divider" />
                <p className="getaway__details-info_link">
                  <FormattedMessage id="why.buy.travel" />{' '}
                  <a href={TRAVEL_GUARD_LINK} target="_blank" rel="noreferrer">
                    <FormattedMessage id="click.here" />
                  </a>{' '}
                  <FormattedMessage id="to.learn.why.we.recommend" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const GetawayDetailsInfo = connect(mapStateToProps)(GetawayDetailsInfoComponent);
