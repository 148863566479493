import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { v4 } from 'uuid';
import bedImage from '@assets/images/gallery/iconsbed.svg';
import { formatMoneyCurrency } from '../../../../utils/currency';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

const DashboardCards = ({
  adjustedPackageGroups,
  location,
  dateObject,
  roomsContent,
  rooms,
  sessionKey,
}) => {
  const history = useHistory();
  const uuid = v4();
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const login = useSelector((state: IMapStateToProps) => state.loginStore);

  const toggleDropdown = (roomKey) => {
    const room = roomsContent.find((room) => {
      return room.roomKey === roomKey;
    });

    setShowDropdown((prevKey) => (prevKey === roomKey ? null : roomKey));
  };

  const findAmenities = (roomKey) => {
    const room = roomsContent.find((room) => {
      return room.roomKey === roomKey.targetRoomKey;
    });
    return room;
  };

  const findBedType = (roomKey) => {
    const room = roomsContent.find((room) => {
      return room.roomKey === roomKey.targetRoomKey;
    });
    return room;
  };
  const findRoomImage = (roomKey) => {
    const room = roomsContent.find((room) => {
      return room.roomKey === roomKey;
    });
    return room
      ? room.images[0]
      : 'https://travcoding-hotel-resources.s3.amazonaws.com/images/306854/room/7wauu6ww9w.jpg';
  };

  const handleSubmit = (packageId, hotelId) => {
    history.push(
      `/${login.account.name}/book/${hotelId}/${packageId}?dates=${Base64.encode(
        JSON.stringify(dateObject),
      )}&&location=${Base64.encode(JSON.stringify(location))}&&rooms=${Base64.encode(
        JSON.stringify(rooms),
      )}&&sessionKey=${Base64.encode(
        JSON.stringify(sessionKey),
      )}`,
    );
  };

  const findLowestPricePackage = (packages) => {
    if (!packages || packages.length === 0) return null;

    return packages.reduce((lowest, item) => {
      return item.price < lowest.price ? item : lowest;
    }, packages[0]);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };

  useEffect(() => {
    if (showDropdown !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className="card-body">
      <div className="room-card gap-2">
        {adjustedPackageGroups?.map((hotel, i) => {
          const { groupedPackages } = hotel;
          const { rooms } = groupedPackages[0];
          const lowestPricePackage = findLowestPricePackage(groupedPackages);

          const formatter = formatMoneyCurrency(lowestPricePackage.currency);

          if (!lowestPricePackage) {
            return null;
          }

          return (
            <div key={i} className="card border bg-transparent p-3">
              <div className="row g-3 g-md-4">
                <div className="col-md-4">
                  <div className="position-relative">
                    <img
                      src={findRoomImage(lowestPricePackage?.rooms[0].targetRoomKey)}
                      className="card-img custom-dashboard-card-image"
                      alt="Room"
                    />
                    <div className="position-absolute top-0 start-0 z-index-1 m-2">
                      <div className="badge text-bg-success">
                        Save {Math.floor(lowestPricePackage.savingPercent)}%
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="card-body d-flex flex-column p-0 h-100 justify-content-between">
                    <h5 className="card-title mb-0 line-clamp">
                      {lowestPricePackage.rooms.roomName}
                    </h5>
                    <div className="pt-1">
                      <span>
                        <span>
                          {findBedType(rooms[0])?.bedTypes.length > 0 ? (
                            <ul>
                              {findBedType(rooms[0]).bedTypes.map((bedType, index) => (
                                <li key={index}>{bedType}</li>
                              ))}
                            </ul>
                          ) : (
                            <>
                              <img src={bedImage} width={17} className="pb-1" />
                              <span className="pl-1"> No data available</span>
                            </>
                          )}
                        </span>
                        <div>
                          <p>
                            {findAmenities(rooms[0])
                              ?.amenities.slice(0, 4)
                              .map((amenity, index, arr) => {
                                return (
                                  <span key={index}>
                                    {amenity}
                                    {index < arr.length - 1 ? ', ' : ''}
                                  </span>
                                );
                              })}

                            {findAmenities(rooms[0])?.amenities.length > 7 && (
                              <>
                                <button
                                  className="btn btn-link p-0 mb-0 pt-3 more"
                                  onClick={() => toggleDropdown(findAmenities(rooms[0])?.roomKey)}
                                >
                                  More...
                                </button>

                                {showDropdown === findAmenities(rooms[0])?.roomKey && (
                                  <div className=" dropdown-more" ref={dropdownRef}>
                                    {findAmenities(rooms[0])
                                      ?.amenities.slice(4)
                                      .map((amenity, index) => {
                                        return (
                                          <div key={index} className="amenities-dropdown">
                                            {amenity}
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </span>
                    </div>
                    {lowestPricePackage.refundability === 'nonrefundable' && (
                      <ul className="list-group list-group-borderless small mb-0 mt-2">
                        <li className="list-group-item d-flex text-danger p-0">
                          <i className="bi bi-patch-check-fill me-2"></i>Non Refundable
                        </li>
                      </ul>
                    )}
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div className="align-self-end">
                        <span className="me-2 small-text-price">Public</span>
                        <span className="fw-light text-decoration-line-through small-text-price">
                          {formatter.format(
                            findLowestPricePackage(lowestPricePackage.publicPrices).price,
                          )}
                        </span>
                        <h6 className="mb-0 text-success fw-medium pe-2">
                          {formatter.format(lowestPricePackage.pricePerNight)}
                          <span className="mb-0 me-2 small-text-price">/night</span>
                        </h6>
                      </div>
                      <div className="d-flex flex-column justify-content-end">
                        <div className="small max-width">
                          <span className="fw-bold fs-6 text-black">
                            {formatter.format(lowestPricePackage.price)}
                          </span>
                          <p>
                            {' '}
                            for{' '}
                            {Math.round(
                              lowestPricePackage.price / lowestPricePackage.pricePerNight,
                            )}{' '}
                            {Math.round(
                              lowestPricePackage.price / lowestPricePackage.pricePerNight,
                            ) > 1
                              ? 'nights'
                              : 'night'}{' '}
                            night incl. taxes only{' '}
                          </p>
                        </div>
                        <button
                          className="btn btn-sm btn-primary mb-0"
                          onClick={() =>
                            handleSubmit(lowestPricePackage.packageId, lowestPricePackage.hotelId)
                          }
                        >
                          Select room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardCards;
