import React from 'react';
import { Skeleton } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Routes } from '@share/constants';

import './style.scss';
import { VACATIONS_PAGE, getAccountUsernameFromPath } from '@share/utils';
import { connect } from 'react-redux';

export interface IProps extends RouteComponentProps {
  isVacationRentals?: boolean;
}

class SkeletonView extends React.Component<IProps, null> {
  render(): React.ReactNode {

    const accountName = getAccountUsernameFromPath(this.props.history);
    return (
      <div className="skeleton-hotel">
        <div className="skeleton-hotel__wrapper">
          <div className="skeleton-hotel__back-link-skeleton">
            <Link to={this.props.isVacationRentals ? `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}` : `/${accountName}${Routes.Search}${location.search}`}>
              <FormattedMessage id="see.all.properties" />
            </Link>
          </div>
          <div className="skeleton-hotel__top-content">
            <Skeleton
              className="skeleton-hotel__top-content-left"
              active
              title
              paragraph={{ rows: 2 }}
            />
          </div>
          <div className="skeleton-hotel__tabs-content desktop">
            <Skeleton active title paragraph={false} />
            <Skeleton active title paragraph={false} />
            <Skeleton active title paragraph={false} />
            <Skeleton active title paragraph={false} />
          </div>
          <Skeleton
            className="skeleton-hotel__img-hotel"
            active
            title={false}
            paragraph={{ rows: 1 }}
          />
          <div className="skeleton-hotel__tabs-content mobile">
            <Skeleton active title paragraph={false} />
            <Skeleton active title paragraph={false} />
            <Skeleton active title paragraph={false} />
            <Skeleton active title paragraph={false} />
          </div>
          <div className="skeleton-hotel__bottom-content">
            <Skeleton
              className="skeleton-hotel__bottom-content-left"
              active
              title={false}
              paragraph={{ rows: 4 }}
            />
            <Skeleton
              className="skeleton-hotel__bottom-content-right"
              active
              title={false}
              paragraph={{ rows: 3 }}
            />
          </div>
          <div className="skeleton-hotel__bottom-content-mobile">
            <Skeleton active title paragraph={{ rows: 4 }} />
          </div>
        </div>
      </div>
    );
  }
}

export const SkeletonComponent = connect()(withRouter(SkeletonView));
