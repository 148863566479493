import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import img from '@assets/images/avatar/avatar01.png';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface NavLinkProps {
  isActive: boolean;
  label: string;
  icon: string;
  to: string;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

const CustomNavLink: React.FC<NavLinkProps> = ({ isActive, label, icon, to }) => (
  <li className="nav-item">
    <NavLink to={to} className={`nav-link ${isActive ? 'active' : ''}`}>
      <i className={`${icon} fa-fw me-2`}></i>
      {label}
    </NavLink>
  </li>
);

const SideNav: React.FC = () => {
  const [userData, setUserData] = useState(null);
  const firstName = userData?.claims?.AccountName;
  const login = useSelector((state: IMapStateToProps) => state.loginStore);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUserData() {
      const user = localStorage.getItem('user');
      if (user) {
        try {
          const parsedUserData = JSON.parse(user);
          setUserData(parsedUserData);
        } catch (error) {
          console.error('Error parsing user data:', error);
        }
      }
      setLoading(false);
    }
    fetchUserData();
  }, []);

  // Define state or props that determine which nav item is active
  const activePage: any = location.pathname.split('/')[2] || 'account-settings'; // This value should ideally come from router or parent component state

  if (loading) {
    return (
      <div className="centered-spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="col-lg-4 col-xl-3">
      <div className="offcanvas-lg offcanvas-end" tabIndex={-1} id="offcanvasSidebar">
        <div className="offcanvas-header justify-content-end pb-2">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-3 p-lg-0">
          <div className="card bg-light w-100">
            <div className="position-absolute top-0 end-0 p-3">
              <NavLink to={`/${login.account.name}/account-settings`} className="text-primary-hover" title="Edit profile">
                <i className="bi bi-pencil-square"></i>
              </NavLink>
            </div>
            <div className="card-body p-3">
              <div className="text-center mb-3">
                <div className="avatar avatar-xl mb-2">
                  <img
                    className="avatar-img rounded-circle border border-2 border-white"
                    src={img}
                    alt="avatar"
                  />
                </div>
                <h6 className="mb-0">{firstName}</h6>
                <NavLink
                  to={`/${login.account.name}/company-settings`}
                  className="text-reset text-primary-hover small"
                >
                  Aspen Credit, LLC
                </NavLink>
                <hr />
              </div>
              <ul className="nav nav-pills-primary-soft flex-column">
                <CustomNavLink
                  isActive={activePage === 'account-settings'}
                  label="Account Settings"
                  icon="bi bi-gear"
                  to={`/${login.account.name}/account-settings`}
                />
                <CustomNavLink
                  isActive={activePage === 'account-payment'}
                  label="Payment Details"
                  icon="bi bi-credit-card"
                  to={`/${login.account.name}/account-payment`}
                />
                <CustomNavLink
                  isActive={activePage === 'booking'}
                  label="My Bookings"
                  icon="bi bi-suitcase-lg"
                  to={`/${login.account.name}/bookings`}
                />
                <CustomNavLink
                  isActive={activePage === 'account-credit'}
                  label="My Trip Credits"
                  icon="bi bi-coin"
                  to={`/${login.account.name}/account-credit`}
                />
                <CustomNavLink
                  isActive={activePage === 'account-saved'}
                  label="Saved Properties"
                  icon="bi bi-bookmark-check"
                  to={`/${login.account.name}/account-saved`}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
