import React from "react";
import SideNav from "../../components/company-settings/side-nav/component";
import CompanyCard from "../../components/company-settings/company-card/component";

const CompanySettings = () => {
  return (
    <section className="pt-3">
      <div className="container">
        <div className="row">
          <SideNav />
          <div className="col-lg-8 col-xl-9">
            <CompanyCard />
            {/* <DomainCard /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanySettings;
