import React from "react";
import SideNav from "../../components/dashboard/side-nav/component";
import { creditSections, creditTransactions } from "../../../_mock-api's/index";
import TableRow from "./table-row";
import CreditSection from "./credit-sections";

const AccountCredit = () => {
  return (
    <section className="pt-3">
      <div className="container">
        <div className="row">
          <SideNav />
          <div className="col-lg-8 col-xl-9">
            <div className="card shadow-sm border mb-4">
              <div className="card-header bg-transparent border-bottom">
                <h4 className="card-header-title">
                  <i className="bi bi-coin me-2"></i>Trip Credits
                </h4>
              </div>

              <div className="card-body vstack gap-4">
                <div className="row g-3 justify-content-between align-items-top border-bottom mb-1 pb-3">
                  <div className="col-6">
                    <div className="h3 fw-medium text-emerald mb-0 mt-0">
                      $70.15
                    </div>
                    <div className="h6 fw-light text-primary small mt-0">
                      Credit Balance
                    </div>
                  </div>
                </div>
                <div className="row">
                  <table
                    id="credits"
                    className="fw-medium display responsive nowrap"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th className="text-end">Amount</th>
                        <th className="text-end">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditTransactions?.map((transaction, index) => (
                        <TableRow key={index} {...transaction} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {creditSections?.map((section, index) => (
              <CreditSection
                key={index}
                headerTitle={section.headerTitle}
                rows={section.rows}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountCredit;
