import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RootState } from '@share/utils';
import { IReviewBookState } from '@store/slices';

import { ILoginState } from '@share/store/slices';
import { get } from 'lodash';
import { NON_REFUNDABLE_TYPE, REFUNDABLE_TYPE } from '@constants';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalCancellationPolicies } from '../../common';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {}

interface IState {
  isModalCancellationPoliciesVisible: boolean;
  isChangedCancellationPolicy: false;
}

class RefundabilityComponent extends React.Component<IProps, IState> {
  
  state: IState = { isChangedCancellationPolicy: false, isModalCancellationPoliciesVisible: false };

  showCancellationPolicies = (): void => {
    this.setState({ isModalCancellationPoliciesVisible: true });
  };

  render(): React.ReactNode {
    const { isModalCancellationPoliciesVisible, isChangedCancellationPolicy } = this.state;
    const { reviewBookStore, loginStore } = this.props;
    const { account, userWallet } = loginStore;
    const { bookingSummary, selectedHotelReviewClientCash, bookingComplete, booking } = reviewBookStore;
    const { cancellationPolicyText, refundabilityText } = bookingSummary;
    const { refundability } = bookingSummary.bookingCard?.package;

    const hasClientCash = account?.hasClientCash;
    const walletIsExternal = account?.walletIsExternal;

    const isLogged = !!userWallet;

    const usedClientCash = (bookingComplete && booking) ? get(booking?.hotelSegments, '[0].clientCash', 0) : 0;
    
    const refundabilityFinal = (!walletIsExternal && hasClientCash && isLogged && (selectedHotelReviewClientCash > 0 || usedClientCash > 0)) ? NON_REFUNDABLE_TYPE : refundability;
    const refundabilityClassName = `room-info__refundability ${NON_REFUNDABLE_TYPE === refundabilityFinal ? 'non-refundable' : 'refundable'}`;

    return (
      <>
        <div className={refundabilityClassName} onClick={this.showCancellationPolicies}>
          {NON_REFUNDABLE_TYPE === refundabilityFinal && (<p className="room-info__room-info-title"><FormattedMessage id={'non.refundable'} /></p>)}
          {REFUNDABLE_TYPE === refundabilityFinal && (<p className="room-info__room-info-title"><FormattedMessage id={'refundable'} /></p>)}
          <Popover
            overlayClassName="overlay-popover"
            content={<FormattedMessage id="changed.cancellation.policy" />}
            visible={isChangedCancellationPolicy}
            placement="bottomLeft"
          >
            <FontAwesomeIcon icon={faInfoCircle} size="1x" />
          </Popover>
        </div>

        <ModalCancellationPolicies
          cancellationPoliciesText={cancellationPolicyText}
          refundabilityText={refundabilityText}
          visible={isModalCancellationPoliciesVisible}
          onCancel={() => this.setState({ isModalCancellationPoliciesVisible: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    loginStore: state.loginStore
  };
};

export const Refundability = connect(mapStateToProps, null)(injectIntl(RefundabilityComponent));
