import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import { ITravelLeaderState, getTravelLeader } from '@store/slices';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { BlueButton } from '@share/components';
import { TRAVEL_LEADERS_DOMAIN, PROMO_DETAILS_PATCH } from '@constants';
import { ITravelLeader } from '@common-types';
import { Routes } from '@share/constants';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface IMapStateToProps {
  travelLeaderStore: ITravelLeaderState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getTravelLeader: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  matches?: boolean;
}

const ZERO = 0;
const TWO = 2;
const DOUBLE_SLASH = '//';
const SPEED = 1000;
const SLIDES_TO_SHOW = 1;
const SLIDES_TO_SCROLL = 1;
const BACKGROUND_COLOR = '#20274d';

class TravelLeaderComponent extends React.Component<IProps, null> {
  getLinkViewDetails = (baseUrl: string, promotionId: number) => {
    const getCorrectDomain =
      baseUrl.substr(ZERO, TWO) === DOUBLE_SLASH ? baseUrl : `${DOUBLE_SLASH}${baseUrl}`;

    return `${getCorrectDomain}${PROMO_DETAILS_PATCH}${promotionId}`;
  };

  componentDidMount() {
    this.props.getTravelLeader();
  }

  render() {
    const propsCarousel = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: SPEED,
      slidesToShow: SLIDES_TO_SHOW,
      slidesToScroll: SLIDES_TO_SCROLL,
      autoplay: true,
    };
    const { travelLeaderStore, loginStore, intl } = this.props;
    const { travelLeader, loading } = travelLeaderStore;
    const { account } = loginStore;
    const isDataAvailable = travelLeader && travelLeader && travelLeader.length > 0;

    return isDataAvailable && !loading ? (
      <div className="travel-leader">
        <Carousel {...propsCarousel} lazyLoad="ondemand">
          {travelLeader.map((item: ITravelLeader, i: number) => {
            return (
              <div className="travel-leader__carousel-wrapper" key={i + item.promotionId}>
                <div className="travel-leader__image-wrapper" style={{ backgroundImage: `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)),url(${TRAVEL_LEADERS_DOMAIN}${item.fileName})`}}></div>
                <div
                  className="travel-leader__info-wrapper"
                  style={{ background: 'transparent', width: `${window.innerWidth}px` }}
                >
                  <div className="travel-leader__info-text">
                    <div
                      className="travel-leader__info-title"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: item.headline }),
                      }}
                    />
                  </div>
                  <Link
                    to={`/${account?.name}${Routes.PromotionDetails}/${item.promotionId}`}
                    className="travel-leader__info-link"
                  >
                    <BlueButton>
                      <FormattedMessage id="view.details" />
                      <FontAwesomeIcon icon={faArrowRight} />
                    </BlueButton>
                  </Link>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    travelLeaderStore: state.travelLeaderStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getTravelLeader };

export const TravelLeader = connect(mapStateToProps, mapDispatchToProps)(injectIntl(TravelLeaderComponent));

