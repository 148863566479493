import React from 'react';
import sanFranciscoImg from '@assets/images/home/sanfrancisco.jpg';
import losAngelesImg from '@assets/images/home/losangeles.jpg';
import miamiImg from '@assets/images/home/miami.jpg';
import atlantaImg from '@assets/images/home/atlanta.jpg';
import newYorkImg from '@assets/images/home/newyork.jpg';
import dallasImg from '@assets/images/home/dallas.jpg';
import DestinationCard from './destination-card';

const destinationsData = [
  { name: 'San Francisco', imgSrc: sanFranciscoImg, properties: 104 },
  { name: 'Los Angeles', imgSrc: losAngelesImg, properties: 217 },
  { name: 'Miami', imgSrc: miamiImg, properties: 92 },
  { name: 'Atlanta', imgSrc: atlantaImg, properties: 148 },
  { name: 'New York', imgSrc: newYorkImg, properties: 61 },
  { name: 'Dallas', imgSrc: dallasImg, properties: 78 },
];

const PopularDestinations = () => {
  return (
    <section>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 text-center">
            <h2 className="mb-0">Popular Destinations</h2>
          </div>
        </div>
        <div className="row g-4 g-md-5">
          {destinationsData?.map((destination, index) => (
            <DestinationCard key={index} {...destination} />
          ))}
        </div>
      </div>
    </section>
  );
};

export { PopularDestinations };
