import React from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { IHotelDetailsState, IReviewBookState } from '@store/slices';
import { getAccountUsernameFromPath, RootState, VACATIONS_PAGE } from '@share/utils';
import { Routes } from '@share/constants';
import { IPackageRoom } from '@common-types';
import { BookingErrorsEnum } from '@share/common-types';

import Image from '@assets/images/availability-status.png';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  hotelDetailsStore: IHotelDetailsState;
}

interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {
  isVacationRentals?: boolean;
}

interface IState {
  visible: boolean;
}

const modalWidth = 400;

class AvailabilityStatusModalComponent extends React.Component<IProps, IState> {

  state: IState = { visible: true };

  handleGoToDetails = () => {
    const { hotelDetailsStore, isVacationRentals } = this.props;
    const { hotel } = hotelDetailsStore;

    const accountName = getAccountUsernameFromPath(this.props.history);
    this.props.history.push(isVacationRentals ? 
        `/${accountName}${Routes.Hotel}/${hotel?.hotelDetails?.id}/${VACATIONS_PAGE}${location.search}` :
        `/${accountName}${Routes.Hotel}/${hotel?.hotelDetails?.id}${location.search}`);
  }

  handleGoToSearch = () => {
    const accountName = getAccountUsernameFromPath(this.props.history);
    this.props.history.push(this.props.isVacationRentals ? `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}` : `/${accountName}${Routes.Search}${location.search}`);
  }

  render(): React.ReactNode {
    const { visible } = this.state;

    const { reviewBookStore, hotelDetailsStore } = this.props;
    const { bookingSummary } = reviewBookStore;
    const { error } = hotelDetailsStore;

    const rooms = get(bookingSummary, 'bookingCard.package.rooms', []);
    const hasSoldout = rooms.filter((r: IPackageRoom) => r.availability === BookingErrorsEnum.SoldOut).length > 0;
    const hasUnavailable = BookingErrorsEnum.RoomsUnavailable === error;

    return (
      <div className="price-change-modal">
        <Modal
          className="price-change-modal-wrapper"
          wrapClassName="price-change-modal-wrapper__wrap"
          visible={visible && (hasSoldout || hasUnavailable)}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={modalWidth}
        >
          <div className="price-change-modal-wrapper__wrapper">
            <img src={Image} alt="" />
            <span className="price-change-modal-wrapper__title"><FormattedMessage id={'availability.status.modal.title'} /></span>
            <span className="price-change-modal-wrapper__message">
              <FormattedMessage id={'availability.status.modal.message'} />
              <Tooltip placement="rightBottom" title={this.props.intl.formatMessage({ id: 'availability.status.modal.tooltip' })}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
            <div className={`price-change-modal-wrapper__buttons`}>
              <div className="accept-button" onClick={this.handleGoToSearch}><FormattedMessage id={'availability.status.modal.search_again'} /></div>
              {error ? null : <div className="go-to-search-button" onClick={this.handleGoToDetails}><FormattedMessage id={'availability.status.modal.back_detail'} /></div>}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    hotelDetailsStore: state.hotelDetailsStore
  };
};

export const AvailabilityStatusModal = connect(mapStateToProps)(injectIntl(withRouter(AvailabilityStatusModalComponent)));
