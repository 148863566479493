import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';

import { ITravelLeaderState, getPromotionDetails } from '@store/slices';
import { ImageTypeEnum, TRAVEL_LEADERS_LINK } from '@constants';

import { SkeletonComponent } from '../hotel-details/skeleton';

import './style.scss';

interface IMapStateToProps {
  travelLeaderStore: ITravelLeaderState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  getPromotionDetails: (id: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

const THIRD_ELEMENT = 3;

class PromotionDetailsComponent extends React.Component<IProps, null> {
  getMainImage = () => {
    const { promotionDetails } = this.props.travelLeaderStore;

    const imageDetails = promotionDetails.promotionImages.find(
      (img) => img.imageType === ImageTypeEnum.LargeHeaderNat,
    );

    return `${TRAVEL_LEADERS_LINK}${imageDetails.fileName}`;
  };

  componentDidMount() {
    const promotionId = Number(window.location.pathname.split('/')[THIRD_ELEMENT]);

    this.props.getPromotionDetails(promotionId);
  }

  render(): React.ReactNode {
    const { travelLeaderStore, menuStore } = this.props;
    const { promotionDetails, loadingDetails } = travelLeaderStore;

    return promotionDetails && !loadingDetails ? (
      <div className="promotion-details">
        <div className="promotion-details__header">
          <div className="promotion-details__container">
            <p
              className="promotion-details__header_title"
              dangerouslySetInnerHTML={{
                __html: promotionDetails.title,
              }}
            />
          </div>
        </div>
        <div className="promotion-details__main-image">
          <img src={this.getMainImage()} alt={promotionDetails.title} />
        </div>
        <div className="promotion-details__container">
          <p className="promotion-details__travel-period">
            <FormattedMessage id="travel.period" />: {promotionDetails.travelPeriod}
          </p>
          <div
            className="promotion-details__body-info"
            dangerouslySetInnerHTML={{
              __html: promotionDetails.promotionBody,
            }}
          />
        </div>
        <div className="promotion-details__call-wrapper">
          <div className="promotion-details__container">
            <span className="promotion-details__call-text">
              <FormattedMessage id="call" />
            </span>{' '}
            <a href={`tel:${menuStore?.items?.phone}`}>{menuStore?.items?.phone}</a>{' '}
            <span className="promotion-details__call-text">
              <FormattedMessage id="to.take.advantage.this.offer" />
            </span>
          </div>
        </div>
      </div>
    ) : (
      <SkeletonComponent />
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    travelLeaderStore: state.travelLeaderStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getPromotionDetails,
};

export const PromotionDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionDetailsComponent);
