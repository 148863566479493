import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getBenefitInfo, getLocalURI, isExternalUrl } from '@share/utils';
import { BenefitsEnum, IBenefitsItems } from '@share/common-types';
import { SkeletonItems } from '@components';
import { RootState } from '@share/utils';
import { IBenefitsState, ILoginState } from '@share/store/slices';

import './style.scss';
import { get, isEmpty } from 'lodash';

interface IMapStateToProps {
  loginStore: ILoginState;
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {
  forceMessages?: boolean;
  desc3Style?: any;
}

const ZERO = 0;

export class CruisesProgramListComponent extends React.Component<IProps, null> {

  renderContent = (item: any, benefitsCount: number) => {
    const account = this.props.loginStore?.account;
    const backgroundColorPreference: any = account? account?.buttonColor : null;
    const backgroundColor = !isEmpty(backgroundColorPreference)? backgroundColorPreference : 'rgb(46, 45, 235)';  

    return (
      <>
        <div className={`cruises-program-list__image-wrapper ${benefitsCount === 1 ? 'single' : ''}`}>
          <img src={item.imageUrl} alt={item.title} />
        </div>
        <div className="cruises-program-list__info">
          <p className="cruises-program-list__info-title">{item.title}</p>
          <p className="cruises-program-list__info-content">
            <FormattedMessage id={item.description} />
          </p>

          <p className="cruises-program-list__info-content">
            <FormattedMessage id={item.description2} />
          </p>

          {item.description3 ? (
            <p className="cruises-program-list__info-content" style={this.props.desc3Style}>
              <FormattedMessage id={item.description3} />
            </p>) : null}

          {item.description4 ? (
            <p className="cruises-program-list__info-content">
              <FormattedMessage id={item.description4} />
            </p>) : null}

          {item.description5 ? (
            <p className="cruises-program-list__info-content">
              <FormattedMessage id={item.description5} />
            </p>) : null}

          {item.errorMessage ? (
            <p className="cruises-program-list__info-content centered error">
              <FormattedMessage id={item.errorMessage} />
            </p>) : null}

          <div className="cruises-program-list__info-button-container">
            <div className="cruises-program-list__info-button" style={{ backgroundColor }}>
              {item?.buttonMessage ? item.buttonMessage : (<FormattedMessage id="check.out.sailings" />)}
            </div>
          </div>
        </div>
      </>
    );
  }

  render(): React.ReactNode {
    const { forceMessages, benefitsStore, loginStore } = this.props;
    const { account } = loginStore;
    
    const cruises: any[] = benefitsStore.benefits?.items ? get(getBenefitInfo(benefitsStore.benefits, BenefitsEnum.Cruises), `[${ZERO}].childItems`, []) : [];
    const cruisesFiltered = cruises.filter(cruise => cruise.title === 'Luxury Cruises');
    const { url, imageUrl, titleForce, buttonMessage, description, description2, description3, description4, description5, errorMessage } = get(cruisesFiltered, '[0]', {} as IBenefitsItems);
    const luxuryCruises = {
      url: forceMessages ? url : url ? url : 'https://membergetaways.com/odtest1.php?id=1620216',
      imageUrl: imageUrl ? imageUrl : 'https://rsistaticdata.s3.amazonaws.com/benefits/cruises-luxury-cruises.jpg',
      title: forceMessages ? titleForce : 'Luxury Ocean & River Cruises',
      description: forceMessages ? description : 'luxury.cruises.message',
      description2: forceMessages ? description2 : 'luxury.cruises.message2',
      description3,
      description4,
      description5,
      buttonMessage,
      errorMessage
    };
    const cruisesToDisplay = [luxuryCruises];
    const linkClassNames = `cruises-program-list__item ${cruisesToDisplay.length === 1 ? 'single' : ''}`;

    return (
      <div className="cruises-program-list">
        <h1 className="cruises-program-list__header">
          <FormattedMessage id="members.have.custom.getaway.programs" />
        </h1>
        {benefitsStore.benefits?.items ? (
          <div className="cruises-program-list__items-wrapper">
            {cruisesToDisplay?.map(
              (item, index) =>
                isExternalUrl(item.url) ? (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    key={`${index}${item.title}`}
                    className={linkClassNames}
                  >
                    {this.renderContent(item, cruisesToDisplay.length)}
                  </a>
                ) : (
                  <Link
                    to={`/${account?.name}${getLocalURI(item.url)}`}
                    key={`${index}${item.title}`}
                    className={linkClassNames}
                  >
                    {this.renderContent(item, cruisesToDisplay.length)}
                  </Link>
                ),
            )}
          </div>
        ) : (
          <SkeletonItems singleBlock={true} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    benefitsStore: state.benefitsStore,
  };
};

export const CruisesProgramList = connect(mapStateToProps, null)(CruisesProgramListComponent);
