import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Table, Tabs, Space } from 'antd';
import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import { ConfirmModal, WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { InfoIconSvg, RefreshSvg } from '@assets';
import { PlusSvg } from '@share/assets';
import { IAdminPresentationRules, INewPresentationRule, INewPriceRule } from '@common-types';
import {
  setChangePresentationRule,
  setIsChangePresentationRule,
  getAdminSuppliers,
  getPresentationRules,
  IAdminChangePresentationRuleState,
  IAdminPresentationRulesState,
  IAdminPriceRulesState,
  changeSelectedPresentationRule,
  setPresentationRuleExists,
} from '@store/slices';
import { RootState } from '@share/utils';
import { NEGATIVE, VALUE_ALL, DATE_FORMAT_FOR_ADMIN_TABLE, ProductsEnum } from '@constants';
import { Routes } from '@share/constants';
import { ConfigPresentationRules } from '../config-presentation-rule';
import { isEmpty } from 'lodash';

import './style.scss';

interface IMapStateToProps {
  adminPresentationRules: IAdminPresentationRulesState;
  adminPresentationRule: IAdminChangePresentationRuleState;
  adminPriceRules: IAdminPriceRulesState;
}

interface IMapDispatchToProps {
  changeSelectedPresentationRule: (data: INewPresentationRule) => void;
  getPresentationRules: () => void;
  setChangePresentationRule: (data: INewPresentationRule) => void;
  setIsChangePresentationRule: (data: boolean) => void;
  getAdminSuppliers: () => void;
  setPresentationRuleExists: (data: boolean) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {}

interface IState {
  currentRule: IAdminPresentationRules;
  presentationRuleId: number;
  isConfirmModal: boolean;
  isModalConfigPresentationRule: boolean;
  isHideActivateButton: boolean;
  isDisabled: boolean;
}

const ZERO = 0;
const ONE = 1;
const FIVE_DAYS = 5;
const DAY_IN_HOURS = 24;

class PresentationRulesComponent extends React.Component<IProps, IState> {
  state: IState = {
    currentRule: null,
    presentationRuleId: null,
    isConfirmModal: false,
    isModalConfigPresentationRule: false,
    isHideActivateButton: false,
    isDisabled: false,
  };

  sorterColumn = (valueAscend: string, valueDescend: string) => {
    return valueAscend.localeCompare(valueDescend);
  };

  showConfigPresentationRule = (
    presentationRule: IAdminPresentationRules | INewPresentationRule,
  ) => {
    this.props.setChangePresentationRule({
      id: presentationRule.id,
      supplier: presentationRule.supplier,
      siteId: presentationRule?.siteId,
      isActive: presentationRule.isActive,
      name: presentationRule.name,
      productType: ProductsEnum.Condo,
    });

    this.setState({
      isModalConfigPresentationRule: true,
      presentationRuleId: presentationRule.id,
      isHideActivateButton: false,
      isDisabled: false,
    });
  };

  refreshPage = () => {
    this.props.getPresentationRules();
    window.location.reload();
  };

  getLastUpdate = (record: IAdminPresentationRules) => {
    const timeDistance = formatDistance(new Date(record.updateDate), new Date(), {
      addSuffix: true,
    });
    const differenceDays = differenceInDays(new Date(), new Date(record.updateDate));
    const differenceHours = differenceInHours(new Date(), new Date(record.updateDate));

    if (differenceDays === ONE) {
      return (
        <p className="search-rules__text-info">
          <FormattedMessage id="yesterday" />
        </p>
      );
    }
    if (differenceDays > ONE) {
      return (
        <p className="search-rules__text-info">
          {format(new Date(record.updateDate), DATE_FORMAT_FOR_ADMIN_TABLE)}
        </p>
      );
    }
    if (differenceHours > FIVE_DAYS && differenceHours < DAY_IN_HOURS) {
      return (
        <p className="search-rules__text-info">
          <FormattedMessage id="today" />
        </p>
      );
    } else {
      return <p className="search-rules__text-info">{timeDistance}</p>;
    }
  };

  updatePriceRule = (value: string | number | bigint | boolean, key: string) => {
    const updateRule = {
      ...this.props.adminPresentationRule.changePresentationRule,
      [key]: value,
    };

    this.props.setChangePresentationRule(updateRule);
  };

  getDefaultPresentationRule = (record: INewPresentationRule, index: number): string => {
    return index === ZERO && record.siteId === NEGATIVE && record.supplier === ''
      ? 'price-rules__main-price-rule'
      : '';
  };

  onOpenConfirmModal = (presentationRule: IAdminPresentationRules) => {
    this.setState({ isConfirmModal: true, currentRule: presentationRule });
  };

  onCancelModalConfigPresentationRule = () => {
    this.setState({ isModalConfigPresentationRule: false });
  };

  handleCancelConfirm = () => {
    this.setState({ isConfirmModal: false });
  };

  onToggleActivatePresentationRule = () => {
    const { currentRule } = this.state;

    this.updatePriceRule(!currentRule.isActive, 'isActive');
    this.props.changeSelectedPresentationRule({
      ...currentRule,
      isActive: !currentRule.isActive,
    });

    this.setState({ isConfirmModal: false });
  };

  componentDidMount() {
    const { getPresentationRules, getAdminSuppliers, adminPresentationRules } = this.props;

    getPresentationRules();
    getAdminSuppliers();

    if (
      !isEmpty(adminPresentationRules.newPresentationRule) &&
      adminPresentationRules.newPresentationRule?.siteId !== '' &&
      adminPresentationRules.presentationRuleExists
    ) {
      const { presentationRules, duplicatePresentationId } = adminPresentationRules;

      const duplicatePresentationRule = presentationRules.find(
        (item) => item.id === duplicatePresentationId,
      );
      this.showConfigPresentationRule(duplicatePresentationRule);
    }
  }

  componentDidUpdate() {
    if (this.props.adminPresentationRule.isChangePresentationRule) {
      this.props.setIsChangePresentationRule(
        !this.props.adminPresentationRule.isChangePresentationRule,
      );
      this.props.getPresentationRules();
    }
  }

  componentWillUnmount() {
    this.props.setPresentationRuleExists(false);
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({ id: 'rule.id.column' }),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: IAdminPresentationRules, b: IAdminPresentationRules) => a.id - b.id,
        render: (id: React.ReactNode, record: IAdminPresentationRules) => (
          <p
            className="search-rules__btn_action"
            onClick={() => this.showConfigPresentationRule(record)}
          >
            {id}
          </p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'description' }),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: IAdminPresentationRules, b: IAdminPresentationRules) =>
          this.sorterColumn(a.name, b.name),
        render: (data: IAdminPresentationRules, record: IAdminPresentationRules) => {
          if (record.supplier === '' && record.siteId === NEGATIVE) {
            return (
              <p className="search-rules__text-info bold-text main-rule">
                <InfoIconSvg />
                <FormattedMessage id="default.rule" />
              </p>
            );
          } else {
            return <p className="search-rules__text-info">{record.name}</p>;
          }
        },
      },
      {
        title: intl.formatMessage({ id: 'site.id' }),
        dataIndex: 'siteId',
        key: 'siteId',
        sorter: (a: IAdminPresentationRules, b: IAdminPresentationRules) => a.siteId - b.siteId,
        render: (siteId: number) => (
          <p className="search-rules__text-info">{siteId !== NEGATIVE ? siteId : VALUE_ALL}</p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'supplier' }),
        dataIndex: 'supplier',
        key: 'supplier',
        sorter: (a: IAdminPresentationRules, b: IAdminPresentationRules) =>
          this.sorterColumn(a.supplier, b.supplier),
        render: (supplier: string) => (
          <p className="search-rules__text-info">{supplier !== '' ? supplier : VALUE_ALL}</p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'status' }),
        dataIndex: 'isActive',
        key: 'isActive',
        render: (isActive: string) => (
          <p className={`search-rules__status ${isActive ? 'active' : 'inactive'}`}>
            {isActive ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
          </p>
        ),
        // @ts-ignore
        sorter: (a: IAdminPresentationRules, b: IAdminPresentationRules) => a.isActive - b.isActive,
      },
      {
        title: intl.formatMessage({ id: 'last.updated' }),
        dataIndex: 'updateDate',
        key: 'updateDate',
        sorter: (a: IAdminPresentationRules, b: IAdminPresentationRules) =>
          this.sorterColumn(a.updateDate, b.updateDate),
        render: (data: IAdminPresentationRules, record: IAdminPresentationRules) =>
          this.getLastUpdate(record),
      },
      {
        title: intl.formatMessage({ id: 'action' }),
        dataIndex: 'siteId',
        key: 'action',
        render: (data: IAdminPresentationRules, record: IAdminPresentationRules, index: number) => {
          return (
            <Space size="middle">
              <p
                className="price-rules__btn_action"
                onClick={() => this.showConfigPresentationRule(record)}
              >
                <FormattedMessage id="config" />
              </p>
              {!this.getDefaultPresentationRule(record, index) && (
                <p
                  className="price-rules__btn_action"
                  onClick={() => this.onOpenConfirmModal(record)}
                >
                  {record.isActive ? (
                    <FormattedMessage id="deactivate" />
                  ) : (
                    <FormattedMessage id="activate" />
                  )}
                </p>
              )}
            </Space>
          );
        },
      },
    ];
  };

  render(): React.ReactNode {
    const {
      currentRule,
      isConfirmModal,
      isModalConfigPresentationRule,
      isHideActivateButton,
      isDisabled,
      presentationRuleId,
    } = this.state;
    const { intl, adminPresentationRules } = this.props;
    const { TabPane } = Tabs;

    return (
      adminPresentationRules.presentationRules && (
        <div className="search-rules">
          <div className="search-rules__btn-wrapper">
            <Link to={`${Routes.NewPresentationRule}`}>
              <BlueButton>
                <PlusSvg />
                <FormattedMessage id="add.new" />
              </BlueButton>
            </Link>
            <WhiteButton onClick={this.refreshPage}>
              <RefreshSvg />
            </WhiteButton>
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={intl.formatMessage({ id: 'condos.title' })}
              key={intl.formatMessage({ id: 'condos.title' })}
            >
              <Table
                columns={this.getColumns()}
                dataSource={adminPresentationRules.presentationRules}
                pagination={false}
                rowKey={(row) => row.id}
                scroll={{
                  x: true,
                }}
              />
            </TabPane>
          </Tabs>
          <ConfigPresentationRules
            isModalConfigPriceRule={isModalConfigPresentationRule}
            onCancel={this.onCancelModalConfigPresentationRule}
            product={ProductsEnum.Condo}
            presentationRuleId={presentationRuleId}
            isHideActivateButton={isHideActivateButton}
            isDisabled={isDisabled}
          />
          {currentRule ? (
            <ConfirmModal
              isActiveRule={!currentRule?.isActive}
              priseRuleId={currentRule?.id}
              isConfirmModal={isConfirmModal}
              handleCancelConfirm={this.handleCancelConfirm}
              onToggleActivatePriceRule={this.onToggleActivatePresentationRule}
              mask={isConfirmModal}
              centerPosition={true}
            />
          ) : null}
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPresentationRules: state.adminPresentationRulesStore,
    adminPresentationRule: state.adminChangePresentationRulesStore,
    adminPriceRules: state.adminPriceRulesStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getPresentationRules: () => dispatch(getPresentationRules()),
  setChangePresentationRule: (data: INewPriceRule) => {
    dispatch(setChangePresentationRule(data));
  },
  getAdminSuppliers: () => dispatch(getAdminSuppliers()),
  setIsChangePresentationRule: (data: boolean) => dispatch(setIsChangePresentationRule(data)),
  setPresentationRuleExists: (data: boolean) => dispatch(setPresentationRuleExists(data)),
  changeSelectedPresentationRule: (data: INewPresentationRule) =>
    dispatch(changeSelectedPresentationRule(data)),
});

export const PresentationRules = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(PresentationRulesComponent)));

