import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HomesTypes } from '@share/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney, faHotel } from '@fortawesome/free-solid-svg-icons';

export const getHomesOptions = (init: boolean) => {
  const options = [
    { value: HomesTypes.All, label: <FormattedMessage id="all" />, icon: "" },
    { value: HomesTypes.VacationRentalOnly, label: <FormattedMessage id={init ? "vacation.rental.only" : "houses"} />, icon: <FontAwesomeIcon icon={faHouseChimney} /> },
    { value: HomesTypes.HotelOnly, label: <FormattedMessage id={init ? "hotel.only" : "hotels"} />, icon: <FontAwesomeIcon icon={faHotel} /> }
  ];

  return options;
};
