
import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Form } from 'antd';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '@share/utils';
import { IMyProfileState, addUserCard } from '@share/store/slices';
import { CreditCardsList, PaymentMethodBase } from '@components';
import { IUserCard } from '@share/common-types';

import './style.scss';

const EMPTY_CARD = {
  addressLine: '',
  cvv: '',
  cardNumber: '',
  cardType: null,
  city: '',
  country: 'US',
  state: '',
  expireDate: '',
  holderName: '',
  phone: '',
  zipCode: '',
} as IUserCard;

interface IMapStateToProps {
  myProfileStore: IMyProfileState;
}

interface IMapDispatchToProps {
  addUserCard: (userCard: IUserCard, sucessCallBack: () => void) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function AddPaymentComponent(props: IProps) {

  const { myProfileStore, addUserCard } = props;
  const { result, loadingCards } = myProfileStore;
  const { userCards } = result;

  const [ card, setCard ] = useState({ ...EMPTY_CARD });
  const [ key, setKet ] = useState(new Date().getTime());

  const [ formPayment ] = Form.useForm();

  const isFormPaymentValid = () => {
    return formPayment?.getFieldsError()?.some((field) => field.errors.length > 0);
  }

  const handleSaveCreditCard = () => {
    formPayment.validateFields()
      .then(() => {
        const isFormInValid = isFormPaymentValid();
        if (!isFormInValid) {
          addUserCard(card, () => {
            setCard({ ...EMPTY_CARD });
            formPayment.resetFields();
            setKet(new Date().getTime());
          });
        }
      })
      .catch();
  }

  return (
    <div className="form-profile" style={{ display: 'flex', flexDirection: 'column' }}>
      <CreditCardsList />

      {(!userCards?.length || userCards?.length < 5) ? (
        <>
          <PaymentMethodBase
            form={formPayment}
            key={key}
            card={card}
            loading={loadingCards}
            setCard={setCard}
            errorsField={null}
            formRef={null}
            display
            isRequired
            useFormLabel
            roundBorders
            fullWidthForm
            includePhone
            skipStorage
            disableInsurance
          />
          <div className="save-credit-card">
            <Button block size="large" type="primary" onClick={handleSaveCreditCard}>
              <FormattedMessage id="add.credit.card" />
            </Button>
          </div>
        </>) : 
        <div><FormattedMessage id="available.cards.limit" /></div>}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    myProfileStore: state.myProfileStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  addUserCard: (userCard: IUserCard, sucessCallBack: () => void) => {
    dispatch(addUserCard(userCard, sucessCallBack));
  },
});

const ComponentIntl = injectIntl(AddPaymentComponent);

export const AddPayment = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
