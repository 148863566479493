import React from "react";

const DashboardHotelConfirm: React.FC<any> = ({
  orderResponse,
  searchHotels,
}) => {
  return (
    <div>
      <div className="row">
        <div>
          <div className="card shadow">
            <img
              src={searchHotels?.bookingCard?.titleImage}
              className="rounded-top object-fit-cover hotel-book-image"
              alt=""
            />

            <div className="card-header border-bottom text-center p-4 pb-0">
              <h1 className="card-title text-success fs-3">Congratulations!</h1>
              <p className="lead mb-3">Your trip has been booked at the</p>

              <h5 className="text-primary mb-4">
                {searchHotels?.bookingCard?.name}
              </h5>
            </div>
            <div className="card-body text-center p-4 pb-0">
              <div className="row justify-content-between text-start mb-4">
                <div className="col-lg-12">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <i className="bi bi-vr fa-fw me-2"></i>Confirmation:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {orderResponse?.confirmationCode}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <i className="bi bi-currency-dollar fa-fw me-2"></i>
                        Total Price:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        $
                        {orderResponse?.hotelSegments[0].totalPay.toLocaleString()}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <i className="bi bi-currency-dollar fa-fw me-2"></i>
                        Contact Property:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {orderResponse?.resortAddress.phone}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <i className="bi bi-currency-dollar fa-fw me-2"></i>
                        Customer Service Number:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {orderResponse?.customerServiceNumber}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHotelConfirm;
