import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, getSelectedCurrency } from '@share/utils';
import { AppThunk } from '@share/utils';
import { IGetawayBookInfo, IGetawayInfo, IGetawaysSubmitRequest } from '@share/common-types';
import { USD_CURRENCY } from '@constants';
import { isEmpty } from 'lodash';

export interface IGetawayBookingState {
  getawayInfo: IGetawayInfo;
  loading: boolean;
  error: string;
  getawayBookInfo: IGetawayBookInfo;
  adultsCount: number;
  youngerChildrenCount: number;
  middleChildrenCount: number;
  olderChildrenCount: number;
  successSubmitGetaway: boolean;
  loadingSubmitGetaway: boolean;
  errorSubmitGetaway: string;
}

const initialState: IGetawayBookingState = {
  getawayInfo: null,
  loading: false,
  error: '',
  getawayBookInfo: null,
  adultsCount: 2,
  youngerChildrenCount: 0,
  middleChildrenCount: 0,
  olderChildrenCount: 0,
  successSubmitGetaway: false,
  loadingSubmitGetaway: false,
  errorSubmitGetaway: '',
};

const getawayBookingSlice = createSlice({
  name: 'getawayBooking',
  initialState,
  reducers: {
    setLoading: (state: IGetawayBookingState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IGetawayBookingState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setGetawayInfo: (state: IGetawayBookingState, { payload }: PayloadAction<IGetawayInfo>) => {
      state.getawayInfo = payload;
    },
    setGetawayBookInfo: (
      state: IGetawayBookingState,
      { payload }: PayloadAction<IGetawayBookInfo>,
    ) => {
      state.getawayBookInfo = payload;
    },
    updateAdultsCount: (state: IGetawayBookingState, { payload }: PayloadAction<number>) => {
      state.adultsCount = payload;
    },
    updateYoungerChildrenCount: (
      state: IGetawayBookingState,
      { payload }: PayloadAction<number>,
    ) => {
      state.youngerChildrenCount = payload;
    },
    updateMiddleChildrenCount: (
      state: IGetawayBookingState,
      { payload }: PayloadAction<number>,
    ) => {
      state.middleChildrenCount = payload;
    },
    updateOlderChildrenCount: (state: IGetawayBookingState, { payload }: PayloadAction<number>) => {
      state.olderChildrenCount = payload;
    },
    setSuccessSubmitGetaway: (state: IGetawayBookingState, { payload }: PayloadAction<boolean>) => {
      state.successSubmitGetaway = payload;
    },
    setLoadingSubmitGetaway: (state: IGetawayBookingState, { payload }: PayloadAction<boolean>) => {
      state.loadingSubmitGetaway = payload;
    },
    setErrorSubmitGetaway: (state: IGetawayBookingState, { payload }: PayloadAction<string>) => {
      state.errorSubmitGetaway = payload;
    },
  },
});

export const getawayBookingActions = getawayBookingSlice.actions;

export const getawayBookingReducer = getawayBookingSlice.reducer;

export const getGetawayInfo = (promoId: string, getaway: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getawayBookingActions.setLoading(true));

    try {
      const { loginStore } = getState();

      const currency = getSelectedCurrency(loginStore.account);

      const res = await axiosInstance.get(`${Urls.Deals}/${getaway}/details/${!isEmpty(currency) ? currency : USD_CURRENCY}/${promoId}`, {
        ...getHeaders(),
      });

      dispatch(getawayBookingActions.setLoading(false));
      dispatch(getawayBookingActions.setGetawayInfo(res.data));
    } catch (error) {
      console.error(error);
      dispatch(getawayBookingActions.setError(error.toString()));
      dispatch(getawayBookingActions.setLoading(false));
    }
  };
};

export const getSubmitRequest = (data: IGetawaysSubmitRequest): AppThunk => {
  return async (dispatch) => {
    dispatch(getawayBookingActions.setLoadingSubmitGetaway(true));

    try {
      await axiosInstance.post(
        `https://olm.ownerservices.us/travelrequests/getawayrequestTC`,
        data,
        {},
      );

      dispatch(getawayBookingActions.setLoadingSubmitGetaway(false));
      dispatch(getawayBookingActions.setSuccessSubmitGetaway(true));
    } catch (error) {
      console.error(error);
      dispatch(getawayBookingActions.setErrorSubmitGetaway(error.toString()));
      dispatch(getawayBookingActions.setLoadingSubmitGetaway(false));
    }
  };
};
