import React from "react";
import { Table } from "antd";

const BookingsTable = ({ bookings }: any) => {
  const columns = [
    {
      title: "Guest",
      dataIndex: "leadName",
      key: "leadName",
    },
    {
      title: "In",
      dataIndex: "checkIn",
      key: "checkIn",
    },
    {
      title: "Out",
      dataIndex: "checkOut",
      key: "checkOut",
    },
    {
      title: "Hotel",
      dataIndex: "propertyName",
      key: "propertyName",
      render: (text, record) => (
        <a className="text-primary fw-medium" href="#">
          {text}
        </a>
      ),
    },
    {
      title: "Location",
      key: "location",
      render: (text, record) => (
        <span>
          {record.propertyCity}, {record.propertyCountry}
        </span>
      ),
    },
    {
      title: "Cost",
      dataIndex: "totalPaid",
      key: "totalPaid",
    },
  ];

  return <Table columns={columns} dataSource={bookings} pagination={false}/>;
};

export default BookingsTable;
