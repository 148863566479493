import React from 'react';
import upperFirst from 'lodash/upperFirst';

import { Tabs, Select } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { RootState } from '@share/utils';
import { ITrustYouReviewState } from '@share/store/slices';
import { ChevronSvg } from '@assets';

import { TrustYouSentenceCategory } from '../sentence-category';
import { TrustYouCategory } from '../category';
import { TrustYouScore } from '../trust-you-score';

import './style.scss';

interface IMapStateToProps {
  trustYouReviewStore: ITrustYouReviewState;
}

interface IMapDispatchToProps {}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  refAnchor: React.RefObject<HTMLDivElement>;
}
interface IState {
  selectedCategory: string;
}

const ONE = 1;

class TrustYouReviewComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    selectedCategory: 'all-reviews',
  };

  customTitle = (title: string, scope: number) => (
    <div>
      <p className="review__tab-title">{upperFirst(title)}</p>
      <p className="review__tab-percent">{scope}%</p>
    </div>
  );

  handleChangeCategory = (value: string) => {
    this.setState({ selectedCategory: value });
  };

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { TabPane } = Tabs;
    const { Option } = Select;
    const { refAnchor, trustYouReviewStore } = this.props;
    const { selectedCategory } = this.state;
    const { reviewModule } = trustYouReviewStore;

    return reviewModule && reviewModule.summary ? (
      <div ref={refAnchor} className="review">
        <h2 className="review__title">
          <FormattedMessage id="reviews.title" />
        </h2>
        <TrustYouScore summary={reviewModule.summary} reviewsCount={reviewModule.reviews_count} />
        <div className="review__select-wrapper" ref={this.wrapperRef}>
          <p className="review__select-label">
            <FormattedMessage id="type.of.guests" />:
          </p>
          <Select
            suffixIcon={ChevronSvg}
            defaultValue="all-reviews"
            onChange={this.handleChangeCategory}
            getPopupContainer={this.getPopupContainer}
          >
            <Option value="all-reviews">All reviews</Option>
            {reviewModule.trip_type_meta_review_list?.map((item, index) => (
              <Option key={index} value={index.toString()}>
                {upperFirst(item.filter.trip_type)} {item.reviews_percent}%
              </Option>
            ))}
          </Select>
        </div>
        <div className="review__tab-content mobile">
          {this.state.selectedCategory === 'all-reviews' ? (
            <>
              <TrustYouSentenceCategory categoryList={reviewModule.category_list} />
              <TrustYouCategory goodToKnowList={reviewModule.good_to_know_list} />
            </>
          ) : (
            <>
              <TrustYouSentenceCategory
                categoryList={
                  reviewModule.trip_type_meta_review_list[+selectedCategory].category_list
                }
              />
              <TrustYouCategory
                goodToKnowList={
                  reviewModule.trip_type_meta_review_list[+selectedCategory].good_to_know_list
                }
              />
            </>
          )}
        </div>
        <Tabs defaultActiveKey="0">
          <TabPane tab="All reviews" key="0">
            <div className="review__tab-content">
              <TrustYouSentenceCategory categoryList={reviewModule.category_list} />
              <TrustYouCategory goodToKnowList={reviewModule.good_to_know_list} />
            </div>
          </TabPane>
          {reviewModule.trip_type_meta_review_list?.map((item, index) => {
            return (
              <TabPane
                tab={this.customTitle(item.filter.trip_type, item.reviews_percent)}
                key={index + ONE}
              >
                <div className="review__tab-content">
                  <TrustYouSentenceCategory categoryList={item.category_list} />
                  <TrustYouCategory goodToKnowList={item.good_to_know_list} />
                </div>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    trustYouReviewStore: state.trustYouReviewStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {};

export const TrustYouReview = connect(mapStateToProps, mapDispatchToProps)(TrustYouReviewComponent);

