import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { IBenefitsState, ILoginState, IMenuState } from '@share/store/slices';
import { Membership, TreatYourself } from '@components';
import { TreatYourselfLogos } from '@common-types';
import { GetawaysInfoList } from '@share/common-types';
import { GetawayInfo } from '../getaway-info';
import { GetawayHeader } from '../getaway-header';
import { CustomGetaways } from '../custom-getaways';
import { GetawayUniqueCollection } from '../getaway-unique-collection';
import { SkeletonGetaways } from '../skeleton-getaways';
import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

class GetawayWrapperComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { menuStore, benefitsStore, loginStore } = this.props;

    return (
      <>
        <GetawayHeader />

        {(benefitsStore.benefits?.items && !loginStore?.loading) ? (
          <CustomGetaways benefits={benefitsStore.benefits} />) : (
          <SkeletonGetaways />)}

        <GetawayInfo getawaysInfoList={GetawaysInfoList} />
        <GetawayUniqueCollection />
        <TreatYourself
          customerServiceNumber={menuStore.items?.customerServiceNumber}
          logos={TreatYourselfLogos}
        />
        <Membership />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
    loginStore: state.loginStore
  };
};

export const GetawayWrapper = connect(mapStateToProps, null)(GetawayWrapperComponent);
