import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import './style.scss';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { connect } from 'react-redux';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

class ConciergeContactComponent extends Component<IProps, null> {
  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    return (
      <div className="contact-wrapper">
        <div className="contact-wrapper__wrapper">
          <p className="contact-wrapper__paragraph">
            <FormattedMessage id="concierge.contact.paragraph" />
          </p>
          <Link to={`/${account?.name}${Routes.ContactUs}`}>
            <BlueButton>
              <FormattedMessage id="concierge.contact.button" />
            </BlueButton>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const ConciergeContact = connect(mapStateToProps)(ConciergeContactComponent);
