import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Input } from 'antd';
import { isEmpty } from 'lodash';

import { RootState } from '@share/utils';
import { SearchSvg } from '@share/assets';
import { getUsers, setAccountUsersKeyword, IUsersState } from '@store/slices';
import { WhiteButton } from '@components';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

interface IMapStateToProps {
  accountUsersStore: IUsersState;
}

interface IMapDispatchToProps {
  getUsers: (accountId: number) => void;
  setAccountUsersKeyword: (keyword: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  accountId: number;
}

interface IState {}

class UsersSearchComponent extends React.Component<IProps, IState> {

  state = { keyword: '' };

  handleSearch = () => {
    if (!isEmpty(this.state.keyword)) {
      this.props.setAccountUsersKeyword(this.state.keyword);
      this.props.getUsers(this.props.accountId);
    }
  }

  renderSearchButton = () => {
    return (
      <div className="accounts-search-button" onClick={this.handleSearch}>
        <SearchSvg />
      </div>
    );
  }

  clearKeywordFilter = (): void => {
    this.props.setAccountUsersKeyword(null);
    this.props.getUsers(this.props.accountId);
    this.setState({ keyword: '' });
  }

  handleOnKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  }

  renderClearButton = ():any => {
    if (!isEmpty(this.props.accountUsersStore.keyword)) {
      return (
        <WhiteButton onClick={this.clearKeywordFilter}>
          <FontAwesomeIcon icon={faMinus} />
        </WhiteButton>
      );
    }
    return null;
  }

  render(): React.ReactNode {
    const { intl } = this.props;

    return (
      <div className="accounts-search">
        <Input
          placeholder={intl.formatMessage({ id: 'search.accounts.by.keyword' })}
          suffix={this.renderSearchButton()}
          value={this.state.keyword}
          onChange={(e) => this.setState({ keyword: e.target.value })}
          onKeyPress={this.handleOnKeyPress}
        />
        {this.renderClearButton()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    accountUsersStore: state.accountUsersStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getUsers,
  setAccountUsersKeyword
};

export const UsersSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(UsersSearchComponent));
