import React from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isBefore from 'date-fns/isBefore';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { get } from 'lodash';

import {
  getCondoDetails,
  getTrustYouReview,
  ICondoDetailsState,
  condoDetailsActions,
  ICondoFlexibleDatePickerState,
  resetCondosFull,
} from '@share/store/slices';
import {
  unitsSearchActions,
  IGetawaysState
} from '@store/slices';
import { ILoginState} from '@share/store/slices';
import { GetHomeParams, RootState } from '@share/utils';
import { Routes, CONDO_SESSION_KEY, CONDO_UNITS_SESSION_KEY_LABEL, CONDO_IS_FLEXIBLE_LABEL, CONDO_UNITS_SEARCH_LABEL, CONDO_DATES_FLEXIBLE_LABEL, CONDO_GUESTS_LABEL, CONDO_DATES_LABEL, CONDO_LOCATIONS_OBJECT_LABEL, C_D_VIEW_MAP_TEXT, DEALID_LABEL, QUOTE_LABEL, DEAL_HOME_LABEL } from '@share/constants';
import { DateSearchTypeEnum, ISessionKey, GetawaysLocationEnum, BookingErrorsEnum } from '@share/common-types';
import { Map } from '@utils';
import { UrlUtils, Responsive } from '@share/utils';
import { TrustYouReview, MobileSlider, CondoAvailabilityStatusModal } from '@components';
import { CloseBlueSvg } from '@assets';

import { CondoImagesDescription } from '../condo-images-description';
import { SkeletonComponent } from '../../../hotel-details/skeleton';
import { CondoInfo } from '../condo-info';
import { UnitsSearch } from '../condo-units';
import { CondoAmenities } from '../condo-amenities';
import { CondoActivities } from '../condo-activities';
import { CondoLocation } from '../condo-location';
import { CondoPolicies } from '../condo-policies';
import { MapWrapper } from '../../../map';

import MapMarker from '@assets/images/map-bed-marker.svg';

import './style.scss';
import { CondoDetailsServerError } from '../condo-details-server-error';
import { CustomErrorPage } from '@share/components';

interface IMapStateToProps {
  condoDetailsStore: ICondoDetailsState;
  loginStore: ILoginState;
  getawaysStore: IGetawaysState;
}

interface IMapDispatchToProps {
  getCondoDetails: (
    numberId: number,
    sessionKey: ISessionKey | undefined,
    isMobile?: boolean,
    condoRequest?: any,
    dealId?: number,
    quote?: string
  ) => void;
  getTrustYouReview: (trustYouId: string) => void;
  resetCondoDetails: () => void;
  resetCondoUnits: () => void;
  resetCondosFull: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches?: boolean;
  isFromAdminPage: boolean;
}

interface IState {
  active: number;
  isMapModalOpen: boolean;
  isTrustYouLoading: boolean;
  disableScroll: boolean;
  quote: string;
  dealId: number;
  backHomeType: string;
}

interface IAnchor {
  id: string;
  ref: React.RefObject<HTMLDivElement>;
}

const ZERO = 0;
const ONE = 1;
const THIRD_ELEMENT = 3;
const DEFAULT_ANCHOR = 0;
const TAB_POLICIES = 'tab.policies';
const TAB_AMENITIES = 'tab.amenities.facilities';
const TAB_ACTIVITIES = 'tab.activities';
const TAB_REVIEWS = 'tab.reviews';
const BIG_MAP_ZOOM = 15;
const DEBOUNCE_TIME = 100;

class CondoDetailsWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    active: DEFAULT_ANCHOR,
    isMapModalOpen: false,
    isTrustYouLoading: false,
    disableScroll: false,
    quote: null,
    dealId: null,
    backHomeType: null,
  };

  overviewRef: React.RefObject<HTMLDivElement> = React.createRef();
  unitsRef: React.RefObject<HTMLDivElement> = React.createRef();
  amenitiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  activitiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  locationRef: React.RefObject<HTMLDivElement> = React.createRef();
  policiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  reviewsRef: React.RefObject<HTMLDivElement> = React.createRef();

  ITEMS_ANCHOR: IAnchor[] = [
    { id: 'tab.overview', ref: this.overviewRef },
    { id: 'tab.units.availability', ref: this.unitsRef },
    { id: 'tab.amenities.facilities', ref: this.amenitiesRef },
    { id: 'tab.activities', ref: this.activitiesRef },
    { id: 'tab.location', ref: this.locationRef },
    { id: 'tab.policies', ref: this.policiesRef },
    { id: 'tab.reviews', ref: this.reviewsRef },
  ];

  scrollView = (e: React.MouseEvent<HTMLParagraphElement>, ref: React.RefObject<HTMLElement>) => {
    this.setState({ active: +e.currentTarget.dataset.index, disableScroll: true });
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  getAnchors = (): IAnchor[] => {
    const { condo } = this.props.condoDetailsStore;
    const allDataPolicies = [
      ...(condo.condoDetails.checkInInformation || []),
      ...(condo.condoDetails.checkOutInformation || []),
      ...(condo.condoDetails.pets || []),
    ];

    return this.ITEMS_ANCHOR.filter((item) => {
      if (
        (item.id === TAB_POLICIES && isEmpty(allDataPolicies)) ||
        (item.id === TAB_AMENITIES &&
          isEmpty(condo.condoDetails.amenities) &&
          isEmpty(condo.condoDetails.facilities)) ||
        (item.id === TAB_ACTIVITIES && isEmpty(condo.condoDetails.activities)) ||
        (item.id === TAB_REVIEWS && isEmpty(condo.condoDetails.rating.trustYouId))
      ) {
        return;
      }
      return item;
    });
  };

  filtersAnchor = () => {
    return this.getAnchors().map((itemAnchor, i) => (
      <p
        key={i}
        className={`condo-details-wrapper__tab-anchors ${i === this.state.active ? 'active' : ''}`}
        onClick={(e) => this.scrollView(e, itemAnchor.ref)}
        data-index={i}
      >
        <FormattedMessage id={itemAnchor.id} />
      </p>
    ));
  };

  onClickScrollToUnits = (e: React.MouseEvent<HTMLDivElement>) => {
    this.scrollView(e, this.unitsRef);
  };

  onClickScrollToReviews = (e: React.MouseEvent<HTMLDivElement>) => {
    this.scrollView(e, this.reviewsRef);
  };

  componentDidMount() {
    const { matches, getCondoDetails } = this.props;
    const footer: HTMLDivElement = document.querySelector('.footer');

    if (!!this.props.loginStore.user) {
      const condoId = Number(this.props.history.location.pathname.split('/')[THIRD_ELEMENT]);
      const values = UrlUtils.getValues();
      const sessionKey = values[CONDO_UNITS_SESSION_KEY_LABEL]
        ? values[CONDO_UNITS_SESSION_KEY_LABEL]
        : values[CONDO_SESSION_KEY];
      let dealId;
      if (values[DEALID_LABEL]) {
        dealId = parseInt(values[DEALID_LABEL] as string);
        this.setState({ dealId });
      }

      if (values[DEAL_HOME_LABEL]) {
        this.setState({ backHomeType: values[DEAL_HOME_LABEL] as string });
      }
  
      let quote;
      if (values[QUOTE_LABEL]) {
        quote = (values[QUOTE_LABEL] as string);
        this.setState({ quote });
      }
      getCondoDetails(condoId, sessionKey as ISessionKey, !matches, this.getCondoRequestDetail(), Number(dealId), quote);
    }

    document.body.style.overflow = 'auto';

    if (footer) {
      footer.style.display = 'block';
    }

    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    this.props.resetCondoDetails();
    this.props.resetCondoUnits();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { isTrustYouLoading } = this.state;
    const { condo, loading } = this.props.condoDetailsStore;

    if (condo && !loading && condo.condoDetails.rating.trustYouId && !isTrustYouLoading) {
      this.props.getTrustYouReview(condo?.condoDetails.rating.trustYouId);
      this.setState({ isTrustYouLoading: !isTrustYouLoading });
    }

    if (!prevProps.loginStore.user && !!this.props.loginStore.user) {
      const { matches, getCondoDetails } = this.props;

      const condoId = Number(this.props.history.location.pathname.split('/')[THIRD_ELEMENT]);
      const values = UrlUtils.getValues();
      const sessionKey = values[CONDO_UNITS_SESSION_KEY_LABEL]
        ? values[CONDO_UNITS_SESSION_KEY_LABEL]
        : values[CONDO_SESSION_KEY];
      let dealId;
      if (values[DEALID_LABEL]) {
        dealId = parseInt(values[DEALID_LABEL] as string);
        this.setState({ dealId });
      }

      if (values[DEAL_HOME_LABEL]) {
        this.setState({ backHomeType: values[DEAL_HOME_LABEL] as string });
      }
  
      let quote;
      if (values[QUOTE_LABEL]) {
        quote = (values[QUOTE_LABEL] as string);
        this.setState({ quote });
      }
      getCondoDetails(condoId, sessionKey as ISessionKey, !matches, this.getCondoRequestDetail(), Number(dealId), quote);
    }
  }

  getCondoRequestDetail = () => {
    const values = UrlUtils.getValues();
    
    const unitSearchValues = values[CONDO_UNITS_SEARCH_LABEL];
    const condoGuestValues = values[CONDO_GUESTS_LABEL];
    const condoDatesValues = values[CONDO_DATES_LABEL];
    const adultsCount = get(condoGuestValues, 'adultsCount', get(unitSearchValues, 'guests.adultsCount', 2));
    const bedroomsCount = get(condoGuestValues, 'bedroomsCount', get(unitSearchValues, 'guests.bedroomsCount', 1));
    const childrenCount = get(condoGuestValues, 'kidsCount', get(unitSearchValues, 'guests.kidsCount', 0));
    const includeStudio = get(condoGuestValues, 'includeStudio', get(unitSearchValues, 'guests.includeStudio', false));
    const checkIn = get(condoDatesValues, 'startDate');
    const checkOut = get(condoDatesValues, 'endDate');
    let months;
    let stayFor;

    if (values[CONDO_DATES_FLEXIBLE_LABEL]) {
      const selectedData: ICondoFlexibleDatePickerState = values[CONDO_DATES_FLEXIBLE_LABEL] as ICondoFlexibleDatePickerState;
      const today = new Date();
      today.setHours(ZERO, ZERO, ZERO, ZERO);
      today.setDate(ONE);
      const mths = selectedData?.selectedMonths.filter((dateString: string) => {
        const date = new Date(dateString);
        date.setHours(ZERO, ZERO, ZERO, ZERO);
        date.setDate(ONE);

        return !isBefore(date, today);
      });

      stayFor = selectedData?.stayFor;
      months = mths?.length ? mths : [];
    }

    return {
      location: values[CONDO_LOCATIONS_OBJECT_LABEL],
      adultsCount,
      bedroomsCount,
      childrenCount,
      requestType: values[CONDO_IS_FLEXIBLE_LABEL] === '1' ? DateSearchTypeEnum.Flexible : DateSearchTypeEnum.Strict,
      checkIn,
      checkOut,
      months,
      stayFor,
      includeStudio
    };
  }

  getActiveAnchor = (): IAnchor => {
    const anchors: IAnchor[] = this.getAnchors();
    const { pageYOffset } = window;
    const additionalHeight = 300;
    let activeRef: IAnchor = anchors[ZERO];

    anchors.forEach((anchor: IAnchor) => {
      const { ref } = anchor;

      if (ref && ref.current) {
        const { current } = ref;
        const height = current.getBoundingClientRect()?.height;
        const offsetTop = current.offsetTop - additionalHeight;
        const offsetBottom = offsetTop + height;

        if (height && offsetTop && pageYOffset >= offsetTop && pageYOffset <= offsetBottom) {
          activeRef = anchor;
        }
      }
    });

    return activeRef;
  };

  onScroll = debounce(() => {
    const { condo, loading } = this.props.condoDetailsStore;
    const { disableScroll } = this.state;

    if (condo && !loading && !disableScroll) {
      const anchor = this.getActiveAnchor();

      if (anchor && anchor.id) {
        const index = this.getAnchors().findIndex(({ id }) => id === anchor.id);

        if (index >= ZERO && this.state.active !== index) {
          this.setState({ active: index });
        }
      }
    }

    if (disableScroll) {
      this.setState({ disableScroll: false });
    }
  }, DEBOUNCE_TIME);

  showMap = () => {
    this.setState({ isMapModalOpen: true });
    document.body.style.overflow = 'hidden';

    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account.name,
      action: `${C_D_VIEW_MAP_TEXT}_${account.name.toUpperCase()}`,
      label: `User clicked view map on Condo details`,
      nonInteraction: false,
    });
  };

  closeMap = () => {
    this.setState({ isMapModalOpen: false });
    document.body.style.overflow = 'initial';
  };

  render(): React.ReactNode {
    const { isMapModalOpen, dealId, backHomeType } = this.state;
    const { isFromAdminPage, condoDetailsStore, loginStore, getawaysStore } = this.props;
    const { condo, loading, isServerError, error } = condoDetailsStore;
    const { account } = loginStore;

    return (
      <div className="condo-details-wrapper">
        {isServerError ? (
          <CondoDetailsServerError />) : (
          <>
            <div className="condo-details-wrapper__back-link desktop">
              {!isFromAdminPage && (
                !dealId
                  ? (<Link to={`/${account?.name}${Routes.CondoSearch}${location.search}`}><FormattedMessage id="back.to.list" /></Link>) :
                  !isEmpty(backHomeType) ? (
                    <Link to={null} onClick={() => {
                      const homeParams = GetHomeParams(account);
                      this.props.resetCondosFull();
                      this.props.history.push(`/${account?.name}${Routes.CondoSearch}${homeParams}`);
                    }}>
                      <FormattedMessage id="confirmation.go.back_home" />
                    </Link>) : (
                    <Link to={`/${account?.name}${Routes.Getaway}/${GetawaysLocationEnum.Condo}/${getawaysStore.activeGetawaySubcategory}`}>
                      <FormattedMessage id="back.to.staycations" />
                    </Link>)

                  //: (<Link to={`/${account?.name}${Routes.Getaway}/${GetawaysLocationEnum.Condo}/${getawaysStore.activeGetawaySubcategory}`}><FormattedMessage id="back.to.list" /></Link>)
              )}
            </div>
            {loading ? <SkeletonComponent /> : null}
            {condo && !loading && isEmpty(error) ? (
              <div className="condo-details-wrapper__wrapper">
                <div ref={this.overviewRef} />
                <div className="condo-details-wrapper__back-link mobile">
                  {condo.condoDetails.isAllInclusive ? (
                    <div className="condo-details-wrapper__label-all-inclusive">
                      <p className="condo-details-wrapper__label-title">
                        <FormattedMessage id="all.inclusive" />
                      </p>
                    </div>
                  ) : null}
                  {!isFromAdminPage && (
                    !dealId
                      ? (<Link to={`/${account?.name}${Routes.CondoSearch}${location.search}`}><FormattedMessage id="back.to.list" /></Link>) :
                      !isEmpty(backHomeType) ? (
                        <Link to={null} onClick={() => {
                          const homeParams = GetHomeParams(account);
                          this.props.resetCondosFull();
                          this.props.history.push(`/${account?.name}${Routes.CondoSearch}${homeParams}`);
                        }}>
                          <FormattedMessage id="confirmation.go.back_home" />
                        </Link>) : (
                        <Link to={`/${account?.name}${Routes.Getaway}/${GetawaysLocationEnum.Condo}/${getawaysStore.activeGetawaySubcategory}`}>
                          <FormattedMessage id="back.to.staycations" />
                        </Link>)
  
                      //: (<Link to={`/${account?.name}${Routes.Getaway}/${GetawaysLocationEnum.Condo}/${getawaysStore.activeGetawaySubcategory}`}><FormattedMessage id="back.to.list" /></Link>)
                  )}
                </div>
                <div
                  className={`condo-details-wrapper__desktop-hotel-info ${
                    condo.condoDetails.isAllInclusive ? 'all-inclusive-wrapper' : ''
                  }`}
                >
                  {condo.condoDetails.isAllInclusive ? (
                    <div className="condo-details-wrapper__label-all-inclusive">
                      <p className="condo-details-wrapper__label-title">
                        <FormattedMessage id="all.inclusive" />
                      </p>
                    </div>
                  ) : null}
                  <CondoInfo condo={condo} onClickScrollToReviews={this.onClickScrollToReviews} />
                </div>
                <MobileSlider images={condo.condoDetails.media || []} />
                <div className="condo-details-wrapper__details-anchor">
                  {condo && this.filtersAnchor()}
                </div>
                <div className="condo-details-wrapper__mobile-hotel-info">
                  <CondoInfo condo={condo} onClickScrollToReviews={this.onClickScrollToReviews} />
                </div>
                <CondoImagesDescription
                  account={account}
                  media={condo.condoDetails.media || []}
                  condoName={condo.condoDetails.title}
                  condoDescription={condo.condoDetails.descriptions || []}
                  onClickScrollToUnits={this.onClickScrollToUnits}
                  highlights={condo.condoDetails.highlights}
                  isAllInclusive={condo.condoDetails.isAllInclusive}
                />
                <Responsive>
                  <UnitsSearch
                    condoDetails={condo.condoDetails}
                    refAnchor={this.unitsRef}
                    isShortStaysSupported={
                      condo.condoDetails?.daysOfCheckIn?.pointsResortCheckInDays?.length > ZERO
                    }
                  />
                </Responsive>
                {(!isEmpty(condo.condoDetails.amenities) ||
                  !isEmpty(condo.condoDetails.facilities)) && (
                  <CondoAmenities condoDetails={condo.condoDetails} refAnchor={this.amenitiesRef} />
                )}
                {!isEmpty(condo.condoDetails.accessibilities) && (
                  <div className="condo-details-wrapper__accessibilities-items">
                    {condo.condoDetails.accessibilities.map((item, index) => (
                      <div className="condo-details-wrapper__accessibilities-item" key={index}>
                        <span className="condo-details-wrapper__accessibilities-text">{item.name}</span>
                        <p className="condo-details-wrapper__accessibilities-category">
                          {item.category}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(condo.condoDetails.activities) && (
                  <CondoActivities condoDetails={condo.condoDetails} refAnchor={this.activitiesRef} />
                )}
                <CondoLocation
                  condoDetails={condo.condoDetails}
                  refAnchor={this.locationRef}
                  showBigMap={this.showMap}
                />
                <CondoPolicies condoDetails={condo.condoDetails} refAnchor={this.policiesRef} />
                <TrustYouReview refAnchor={this.reviewsRef} />
                <div
                  className="condo-details-wrapper__big-map-wrapper"
                  style={{ display: isMapModalOpen ? 'block' : 'none' }}
                >
                  <div className="condo-details-wrapper__big-map-top-section">
                    <span className="condo-details-wrapper__big-map-close" onClick={this.closeMap}>
                      <CloseBlueSvg />
                    </span>
                    {condo.condoDetails.title}
                  </div>
                  <MapWrapper
                    locations={[{ location: condo.condoDetails.location, id: condo.condoDetails.id }]}
                    center={Map.getGoogleLocation(condo.condoDetails.location)}
                    icon={MapMarker}
                    mapOptions={{ zoom: BIG_MAP_ZOOM, disableDefaultUI: true }}
                  />
                </div>
              </div>) : null}
            {(!loading && !isEmpty(error)) ? 
              ((BookingErrorsEnum.RoomsUnavailable === error) || (BookingErrorsEnum.SoldOut === error) ? 
                <CondoAvailabilityStatusModal /> : 
                <CustomErrorPage message={error} />) : 
              null}
          </>)}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoDetailsStore: state.condoDetailsStore,
    loginStore: state.loginStore,
    getawaysStore: state.getawaysStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getCondoDetails,
  getTrustYouReview,
  resetCondoDetails: condoDetailsActions.resetState,
  resetCondoUnits: unitsSearchActions.resetState,
  resetCondosFull
};

export const CondoDetailsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CondoDetailsWrapperComponent));
