import React from 'react';
import { Tabs } from 'antd';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserAccountEnum } from '@common-types';
import { PersonalDetails } from '../personal-details/component';
import { SecurityManage } from '../security-manage/component';
import { ShortUserInfoWrapper } from '../short-user-info';
import './style.scss';
import { Routes } from '@share/constants';
import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { connect } from 'react-redux';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends WrappedComponentProps, RouteComponentProps, IMapStateToProps {
  activeKey: string;
}

export class PersonalInfoWrapperComponent extends React.Component<IProps, null> {
  onChangeItem = (activeKey: string): void => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    switch (activeKey) {
      case UserAccountEnum.PersonalInfo: {
        return this.props.history.push(`/${account?.name}${Routes.PersonalInfo}`);
      }
      case UserAccountEnum.Security: {
        return this.props.history.push(`/${account?.name}${Routes.Security}`);
      }
      default:
        break;
    }
  };

  render(): React.ReactNode {
    const { TabPane } = Tabs;
    const { intl } = this.props;

    return (
      <div className="personal-info">
        <ShortUserInfoWrapper />
        <Tabs activeKey={this.props.activeKey} onChange={this.onChangeItem}>
          <TabPane
            tab={intl.formatMessage({ id: 'personal.info' })}
            key={intl.formatMessage({ id: 'personal.info' })}
          >
            <PersonalDetails />
          </TabPane>
          <TabPane
            tab={intl.formatMessage({ id: 'security' })}
            key={intl.formatMessage({ id: 'security' })}
          >
            <SecurityManage />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}


const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const PersonalInfoWrapper = connect(mapStateToProps)(injectIntl(withRouter(PersonalInfoWrapperComponent)));
