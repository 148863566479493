import React from "react";

const HotelInformation = ({
  checkInInformation,
  checkOutInformation,
  phone,
  pets,
}) => {
  return (
    <div className="">
      <div className="card-header border-bottom bg-transparent px-0 pt-0">
        <h3 className="mb-0">Hotel Information</h3>
      </div>
      <div className="card-body pt-4 p-0">
        {/* <div className="row">
          <div className="col-12">
            <ul className="list-group list-group-borderless mb-2">
              <li className="list-group-item d-flex">
                <i className="bi bi-info-circle-fill text-primary me-2"></i>
                Minimum age 21 and over required to check in. Free parking.
                There is a mandatory one-time 100.00 USD security deposit
                applied at check in by credit card, deposit amount will be
                released on departure, and it may take up to 5 business days to
                release.
              </li>
            </ul>
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <ul className="list-group list-group-borderless mb-2">
              <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-calendar2-check text-primary me-2"></i>
                <b className="me-2">Check-in:</b> {checkInInformation.slice(12)}
              </li>
              <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-calendar2-x text-primary me-2"></i>
                <b className="me-2">Check-out:</b> {checkOutInformation}
              </li>
              <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-telephone text-primary me-2"></i>
                <b className="me-2">Lobby phone:</b>{" "}
                <a href="tel:4078159500">{phone}</a>
              </li>
              {/* <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-printer text-primary me-2"></i>
                <b className="me-2">Fax:</b>{" "}
                <a href="tel:4078151999">(407) 815-1999</a>
              </li> */}
            </ul>
          </div>
          <div className="col-6">
            <ul className="list-group list-group-borderless mb-2">
              {/* <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-clock text-primary me-2"></i>
                <b className="me-2">Lobby hours:</b> 24/7
              </li> */}
              {/* <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-sun text-primary me-2"></i>
                <b className="me-2">Breakfast:</b> 6:00 am - 10:00 am
              </li> */}
              {/* <li className="list-group-item h6 fw-light d-flex mb-0">
                <i className="bi bi-x-circle-fill text-danger me-2"></i>
                <b className="me-2">Smoking:</b> Smoke-free
              </li> */}
              {pets.length > 0 && (
                <li className="list-group-item h6 fw-light d-flex mb-0">
                  <i className="bi bi-x-circle-fill text-danger me-2"></i>
                  <b className="me-2">Pets:</b> {pets.map((pet) => pet.name)}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelInformation;
