import React from 'react';
import StatCard from './stat-card';
import { statsData, timeframeOptions } from "../../../../_mock-api's/index";

const RecentActivity = () => {
  return (
    <section className="py-0 py-lg-7 mt-6">
      <div className="container vstack gap-4">
        <div className="row">
          <div className="col-12">
            <h1 className="fs-4 mb-0">
              <i className="bi bi-graph-up-arrow fa-fw me-1"></i>Recent activity
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card border h-100">
              <div className="card-header border-bottom">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-md-12 col-xl-6 mb-3 mb-md-0">
                    <h5 className="card-header-title">Aspen Credit, LLC activity stats</h5>
                  </div>
                  <div className="col-md-12 col-xl-2">
                    <form action="" className="form-control-bg-light">
                      <select className="form-select form-select-sm js-choice border-0">
                        <option disabled>Timeframe</option>
                        {timeframeOptions?.map((option, index) => (
                          <option key={index}>{option}</option>
                        ))}
                      </select>
                    </form>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row g-4">
                  {statsData?.map((stat, index) => (
                    <StatCard key={index} {...stat} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { RecentActivity };
