import React from 'react';
import { SkeletonFilters } from '../skeleton-filters';
import { SkeletonContent } from '../skeleton-content';
import './style.scss';

export interface IProps {
  label: string;
}

export class SkeletonResult extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { label } = this.props;

    return (
      <div className="skeleton-wrapper">
        <div className="skeleton-wrapper__wrapper">
          <div className="skeleton-wrapper__block-filters">
            <SkeletonFilters />
          </div>
          <div className="skeleton-wrapper__block-content">
            <SkeletonContent label={label} />
          </div>
        </div>
      </div>
    );
  }
}
