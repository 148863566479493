import React from 'react'

const PaymentForm = () => {
  return (
    <div className="card border mt-4">
    <div className="card-header border-bottom">
      <h5 className="card-header-title">Add New Card</h5>
    </div>

    <div className="card-body">
      <form className="row text-start g-4">
        <div className="col-12">
          <label className="form-label">
            Name on Card<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            aria-label="name of card holder"
            placeholder="Enter name"
          />
        </div>
        <div className="col-12">
          <label className="form-label">
            Card Number<span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <input
              type="number"
              className="form-control"
              typeof="numeric"
              placeholder="xxxx xxxx xxxx xxxx"
            />
            <img
              src="assets/images/element/visa.svg"
              className="w-40px position-absolute top-50 end-0 translate-middle-y me-2 d-none d-sm-block"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="form-label">
            Expiration Date<span className="text-danger">*</span>
          </label>
          <div className="input-group">
            <input
              type="number"
              typeof="numeric"
              className="form-control"
              placeholder="Month"
            />
            <input
              type="number"
              typeof="numeric"
              className="form-control"
              placeholder="Year"
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="form-label">
            CVV / CVC<span className="text-danger">*</span>
          </label>
          <input
            type="number"
            typeof="numeric"
            className="form-control"
            placeholder="xxx"
          />
        </div>

        <div className="col-12">
          <button className="btn btn-primary mb-0">
            Add card
          </button>
        </div>
      </form>
    </div>
  </div>
  )
}

export default PaymentForm