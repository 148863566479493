import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

interface IProps {}

const ONE_ROW = 1;
const TWO_ROW = 2;
const NUMBER = 1;
const NUMBER_OF_BLOCKS = [NUMBER, NUMBER, NUMBER];

export class AlternativeDatesSkeleton extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="alternative-dates-skeleton">
        <Skeleton
          className="alternative-dates-skeleton__title"
          active
          title={false}
          paragraph={{ rows: ONE_ROW }}
        />
        <div className="alternative-dates-skeleton__items">
          {NUMBER_OF_BLOCKS.map((value, index) => (
            <div key={index} className="alternative-dates-skeleton__row">
              <Skeleton
                className="alternative-dates-skeleton__left-block"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
              <div className="alternative-dates-skeleton__wrapper-block">
                <Skeleton
                  className="alternative-dates-skeleton__center-block"
                  active
                  title={false}
                  paragraph={{ rows: TWO_ROW }}
                />
                <Skeleton
                  className="alternative-dates-skeleton__right-block"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
