import React from 'react';

import { Modal, Tooltip, Typography, } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IQuote } from '@share/common-types';
import { RefundableEnum } from '@common-types';
import { IQuoteState } from '@share/store/slices';
import { RootState, formatDateCheckInOut, renderNumber } from '@share/utils';
import { getAmenityImageByName } from '@utils';
import { Currency, HotelStars } from '@components';
import { DATE_FORMAT_CHECK_IN_OUT } from '@constants';

import './style.scss';
import moment from 'moment';
import format from 'date-fns/format';

const modalWidth = 900;
const ONE = 1;
const DATE_FORMAT = 'd MMM';

interface IMapStateToProps {
  quoteStore: IQuoteState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  visible: boolean;
  quoteBase: string;

  isHistorical: boolean;

  onCancel: () => void;
}

class QuoteCondosModalCompareComponent extends React.Component<IProps> {

  handleView = (quote: IQuote) => {
    window.open(`${quote.link}&quote=${quote.userWhoShared}`, '_blank');
  }

  render(): React.ReactNode {
    const { visible, quoteBase, isHistorical, onCancel } = this.props;
    const { result } = this.props.quoteStore;
    const { quotesCondos, quotesHistorical } = result;
    let myQuotes: any = isHistorical ? quotesHistorical : quotesCondos;

    if (quoteBase) {
      myQuotes = myQuotes.filter((x: any) => x.quoteBaseId === quoteBase);
    }

    const adultsCount = myQuotes[0]?.adultsCount;
    const kidsCount = myQuotes[0]?.kidsCount;
    const bedroomsCount = myQuotes[0]?.bedroomsCount;
    const months: string[] = [];//myQuotes[0]?.months;
    const endDate = myQuotes[0]?.endDate;
    const startDate = myQuotes[0]?.startDate;
    const isFlexible = true;//myQuotes[0]?.isFlexible;

    return (
      <Modal
        className="modal-condo-compare"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-condo-compare__wrapper"
        width={modalWidth}
      >
        <div>
          <h4 className="condo-modal-compare__title">
            <FormattedMessage id="results.condos.card.compare.modal.title" />
          </h4>

          <div className="modal-condo-compare__condos">
            {myQuotes.map((quote: any, index: number) => {
              return (
                <div key={index} className={`modal-condo-compare__condo ${index === 0 ? 'display-border' : ''} condo_${myQuotes.length}`}>
                  <div className="condo-images-container">
                    <div className="condo-image" style={{ backgroundImage: `url(${quote.image})` }}>
                    </div>
                  </div>

                  <div className="condo-text-container" style={{ height: '86px' }}>
                    <p
                      style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          lineClamp: 3,
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                      }}>{quote?.displayName}</p>
                  </div>
                  
                  <div className="condo-text-container">
                    <p
                      style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          lineClamp: 4,
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                      }}>{quote?.fullAddress}</p>
                  </div>

                  <div className="condo-text-container" style={{ height: '40px' }}>
                    {quote?.availableDates?.length ? (
                      <div className="condo-modal-compare__date-label">
                        {quote?.availableDates.length >= ONE && (
                          <div className="condo-modal-compare__date-more">
                            <p className="condo-modal-compare__date-more-text">
                              <Tooltip className="condo-compare-tooltip" overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" title={
                                <>
                                  {quote?.availableDates.map((d: any, i: number) => <Typography key={i} style={{ color: '#FFFFFF' }}>{format(new Date(d.from), DATE_FORMAT)} - {format(new Date(d.to), DATE_FORMAT)}</Typography>)}
                                </>
                              }>
                                {quote?.availableDates.length} <FormattedMessage id="available.dates" />
                              </Tooltip>
                            </p>
                          </div>)}
                      </div>) : null}
                  </div>

                  {quote.refundability && quote.refundability !== RefundableEnum.Unknown ? (
                    <div className="condo-text-container" style={{ height: 'auto ' }}>
                      <div className="hotel-card-wrapper__refundable">
                        {quote.refundability === RefundableEnum.Refundable && (
                          <div className="hotel-card-wrapper__refundable-ref">
                            <FormattedMessage id="refundable" />
                          </div>)}
                        {quote.refundability === RefundableEnum.Nonrefundable && (
                          <div className="hotel-card-wrapper__refundable-nonref">
                            <FormattedMessage id="non.refundable" />
                          </div>)}
                      </div>
                    </div>) : null}

                  <div className="condo-amenities-container" style={{ height: '50px' }}>
                    {(quote.rating?.value || quote.rating?.reviewsCount) ? (
                      <label>{quote.rating?.value ? (<><strong>{quote.rating?.value.toFixed(1)}</strong> / 5</>) : null} {quote.rating?.reviewsCount ? (<>({quote.rating?.reviewsCount} <FormattedMessage id="reviews" />)</>) : null}</label>) : (
                      <label><FormattedMessage id="reviews.guest.no.ratings" /></label> )}
                  </div>

                  <div className="condo-text-container">
                    {quote.publicPrice ? (<p style={{ textDecoration: 'line-through' }}><Currency currency={quote.currency} />{renderNumber(quote.publicPrice, 2)}</p>) : null}
                    <p><strong style={{ fontSize: '20px', fontWeight: 'bold' }}><Currency currency={quote.currency} />{renderNumber(quote.price, 2)}</strong></p>
                    {quote.discount > 0 ? (<p>{quote.discount} % <FormattedMessage id="results.hotel.card.compare.modal.off" /></p>) : null}
                  </div>

                  {!isHistorical ? (
                    <div className="condo-view-container">
                      <div className="view-button" onClick={() => this.handleView(quote)}>
                        <FormattedMessage id="results.hotel.card.compare.modal.view" />
                      </div>
                    </div>) : null}
                </div>)
            })}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    quoteStore: state.quoteStore,
  };
};

const ModalCompareComponentRouter = withRouter(QuoteCondosModalCompareComponent);

export const QuoteCondosModalCompare = connect(mapStateToProps)(ModalCompareComponentRouter);