import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';

import './style.scss';

interface IProps {
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

export class ModalPrices extends React.Component<IProps, null> {

  render(): React.ReactNode {
    const { visible, onCancel } = this.props;

    return (
      <Modal
        className="modal-prices"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-prices__wrapper"
      >
        <div>
          <h4 className="modal-prices__title">
            <FormattedMessage id="price.and.taxes" />
          </h4>
          <div className="modal-prices__price">
            <p className="modal-prices__price-description">
              <FormattedMessage id="price.and.taxes.description" />
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
