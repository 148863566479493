import React from 'react';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { RootState, getLocalCurrency } from '@share/utils';
import { BasePriceChangeModal } from '@components';
import { IHotelDetailsState } from '@store/slices';
import { IHotelsState, IMarginatorState } from '@share/store/slices';
import { getHotelDetailsPriceDiffDetails } from '@utils';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  hotelDetailsStore: IHotelDetailsState;
  marginatorStore: IMarginatorState;
}


interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {
  isDeal: boolean;
  isVacationRentals?: boolean;
}

class HotelDetailsPriceChangeModalComponent extends React.Component<IProps, null> {
 
  render(): React.ReactNode {
    const { hotelsStore, hotelDetailsStore, marginatorStore, isDeal, isVacationRentals } = this.props;
    const { selectedHotel } = hotelsStore;
    const { hotel } = hotelDetailsStore;

    const currency = get(selectedHotel, 'currency', getLocalCurrency() || 'USD');
    const { newPrice, isIncrease, diff, newSavings, diffSavings } = getHotelDetailsPriceDiffDetails(selectedHotel, hotel, marginatorStore.percentage, isDeal);

    return <BasePriceChangeModal currency={currency} newPrice={newPrice} diff={diff} isIncrease={isIncrease} newSavings={newSavings} diffSavings={diffSavings} isDeal={isDeal} isVacationRentals={isVacationRentals} />
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    hotelDetailsStore: state.hotelDetailsStore,
    marginatorStore: state.marginatorStore
  };
};

export const HotelDetailsPriceChangeModal = connect(mapStateToProps)(injectIntl(withRouter(HotelDetailsPriceChangeModalComponent)));
