import React from 'react';

import { FormattedMessage } from 'react-intl';
import { NoResults } from '@assets';

import './style.scss';

interface IProps {
  resetFilters: () => void;
  altOptionsCount: number;
}

const ZERO = 0;

export class NoAvailableCondosFilterNotification extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { altOptionsCount } = this.props;

    return (
      <div className="no-available-condos-filter">
        <NoResults />
        <div className="no-available-condos-filter__wrapper">
          <p className="no-available-condos-filter__title">
            <FormattedMessage
              id={
                altOptionsCount === ZERO
                  ? 'we.couldnt.find.any.condos'
                  : 'resorts.are.available.alternative.dates'
              }
              values={{ count: altOptionsCount }}
            />
          </p>
          <p className="no-available-condos-filter__description">
            <FormattedMessage id="looks.like.you.recently.applied.some.filters" />{' '}
            <span className="no-available-condos-filter__reset" onClick={this.props.resetFilters}>
              <FormattedMessage id="here" />
            </span>{' '}
            <FormattedMessage id="to.reset.them" />
          </p>
        </div>
      </div>
    );
  }
}

