import React from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getAccountUsernameFromPath, RootState, VACATIONS_PAGE } from '@share/utils';
import { C_ACCEPT_NEW_PRICE, C_DECLINE_NEW_PRICE, D_ACCEPT_NEW_PRICE, D_DECLINE_NEW_PRICE, Routes } from '@share/constants';
import { Currency } from '@components';

import Decrease from '@assets/images/price_decrease.png';
import Increase from '@assets/images/price_increase.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import ReactGA from 'react-ga4';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  isIncrease: boolean;
  isBookPage?: boolean;
  diff: number;
  newPrice: number;
  currency: string;

  diffSavings?: number;
  newSavings?: number;

  isDeal?: boolean;
  isVacationRentals?: boolean;
}

interface IState {
  visible: boolean;
}

const MODAL_WIDTH = 400;
const MIN_DIFF_CHECK = 1;

class BasePriceChangeModalComponent extends React.Component<IProps, IState> {

  state: IState = { visible: true };

  handleAccept = () => {
    const { loginStore, isBookPage } = this.props;
    const { account } = loginStore;

    const key = isBookPage ? C_ACCEPT_NEW_PRICE : D_ACCEPT_NEW_PRICE;

    ReactGA.event({
      category: account.name,
      action: `${key}_${account.name.toUpperCase()}`,
      label: `User accepted price change`,
      nonInteraction: false,
    });

    this.setState({ visible: false });
  }

  handleGoToSearch = () => {
    const { loginStore, isBookPage } = this.props;
    const { account } = loginStore;

    const key = isBookPage ? C_DECLINE_NEW_PRICE : D_DECLINE_NEW_PRICE;

    ReactGA.event({
      category: account.name,
      action: `${key}_${account.name.toUpperCase()}`,
      label: `User declined price change`,
      nonInteraction: false,
    });

    const accountName = getAccountUsernameFromPath(this.props.history);

    if (this.props.isVacationRentals) {
      this.props.history.push(`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}`);
    } else {
      this.props.history.push(`/${accountName}${Routes.Search}${location.search}`);
    }
  }

  render(): React.ReactNode {
    const { isIncrease, diff, newPrice, currency, diffSavings, newSavings, isDeal } = this.props;
    const { visible } = this.state;

    const image = isIncrease ? Increase : Decrease;

    const isPriceChange = diff > MIN_DIFF_CHECK;
    const isSavingsChange = diffSavings > MIN_DIFF_CHECK;
    
    return (
      <div className="price-change-modal">
        <Modal
          className="price-change-modal-wrapper"
          wrapClassName="price-change-modal-wrapper__wrap"
          visible={visible && (isPriceChange || isSavingsChange)}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={MODAL_WIDTH}
        >
          <div className="price-change-modal-wrapper__wrapper">
            <img src={image} alt="" />

            {isPriceChange && (
              <span className="price-change-modal-wrapper__message"><FormattedMessage id="new.price.message" /></span>)}

            {isPriceChange && (
              <span className={`price-change-modal-wrapper__price-change ${isIncrease ? 'increased' : 'decreased'}`}>{isIncrease ? this.props.intl.formatMessage({ id: 'price.changed.increased' }) : this.props.intl.formatMessage({ id: 'price.changed.decreased' })} <Currency currency={currency} /> {diff.toFixed(2)}</span>)}

            {isSavingsChange && (
              <span style={{ marginBottom: '25px' }} className="price-change-modal-wrapper__message"><FormattedMessage id="updated.savings.message" /></span>)}

            {isPriceChange && (
              <div className="price-change-modal-wrapper__price">
                <span className="price-type"><FormattedMessage id="new.price" /></span>
                <span className="price-value"><Currency currency={currency} /> {newPrice.toFixed(2)}</span>
              </div>)}

            {isSavingsChange && (
              <div className="price-change-modal-wrapper__price">
                <span className="price-type"><FormattedMessage id="updated.savings" /></span>
                <span className="price-value">{newSavings.toFixed(0)} %</span>
              </div>)}

            <div className={`price-change-modal-wrapper__buttons ${!isIncrease ? 'decrease' : ''}`}>
              {!isDeal && isIncrease && (<div className="go-to-search-button" onClick={this.handleGoToSearch}><FormattedMessage id="availability.status.modal.search_again" /></div>)}
              <div className="accept-button" onClick={this.handleAccept}><FormattedMessage id="accept" /></div>
            </div>

            <span className="price-change-modal-wrapper__explanation">
              <FormattedMessage id="new.price.message.by_clicking" />
              <Tooltip placement="rightBottom" title={this.props.intl.formatMessage({ id: 'new.price.message.by_clicking.tooltip' })}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const BasePriceChangeModal = connect(mapStateToProps)(injectIntl(withRouter(BasePriceChangeModalComponent)));
