import React from 'react';

import { connect } from 'react-redux';

import LoadingImage from '@share/assets/images/loading.gif';

import './style.scss';

export enum LoadingSise {
  XSmall = 'xsmall',
  Small = 'small',
  Normal = 'normal',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge'
}

interface IProps {
  size: LoadingSise;
}

class LoadingComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const className = `loading-image-container ${this.props.size !== LoadingSise.Normal ? this.props.size : ''}`;
    return (
      <img src={LoadingImage} className={className} alt={'loading...'} />
    );
  }
}

export const Loading = connect()(LoadingComponent);

