import React from "react";

const HotelCard = ({ imgSrc, name, address, price }) => (
  <div className="card shadow p-2">
    <div className="row g-0">
      <div className="col-md-3">
        <img src={imgSrc} className="card-img rounded-2" alt="CardImage" />
      </div>

      <div className="col-md-9">
        <div className="card-body py-md-2 d-flex flex-column h-100">
          <h5 className="card-title mb-1">
            <a href="#">{name}</a>
          </h5>
          <small>
            <i className="bi bi-geo-alt me-2"></i>
            {address}
          </small>

          {/* Price and view button */}
          <div className="d-sm-flex justify-content-sm-between align-items-center mt-3 mt-md-auto">
            <div className="d-flex align-items-center">
              <h5 className="fw-bold mb-0 me-1">${price}</h5>
              <span className="mb-0 me-2">/night</span>
            </div>
            <div className="mt-3 mt-sm-0">
              <a href="#" className="btn btn-sm btn-dark w-100 mb-0">
                View hotel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HotelCard;
