import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, Input, Alert, Spin } from 'antd';
import { RootState, getAccountUsernameFromPath } from '@share/utils';
import { IPasswordState, change } from '@share/store/slices';
import { Loading } from '@components';
import { LoadingSise } from '@common-types';

import './style.scss';

interface IMapStateToProps {
  passwordStore: IPasswordState;
}

interface IMapDispatchToProps {
  change: (current: string, password: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function ChangePasswordComponent(props: IProps) {
  const [accountName, setAccountName] = useState(null);
  const [form] = Form.useForm();
  const { isSuccess, message, loading } = props.passwordStore;

  useEffect(() => {
    const accountName = getAccountUsernameFromPath(props.history);
    setAccountName(accountName);
  }, [])

  const onFinish = (values: any) => {
    props.change(values.current, values.password);
    form.resetFields();
  };

  return (
    <div className="password-page">

      <div className="password-page__header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1 buttons" style={{ paddingRight: '0', position: 'relative' }}>
              <div className="password-page__title">
                <h1><FormattedMessage id="change.password" /></h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Spin spinning={loading} indicator={<Loading size={LoadingSise.Small} />} >
        <div className="row form-profile">
          <div className='col-sm-6'>
            <Form
              name="normal_password"
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Item
                      name="current"
                      label={<FormattedMessage id="current.password" />}
                      rules={[{ required: true, message: <FormattedMessage id="enter.your.password" /> }]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Item
                      name="password"
                      label={<FormattedMessage id="new.password" />}
                      rules={[
                        { required: true, message: <FormattedMessage id="enter.new.password" /> },
                        { min: 5, message: <FormattedMessage id="password.must.be.minimum.characters" /> }
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Item
                      name="confirm"
                      label={<FormattedMessage id="confirm.new.password" />}
                      dependencies={['password']}
                      hasFeedback
                      rules={[{ required: true, message: <FormattedMessage id="please.confirm.your.password" /> },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(props.intl.formatMessage({ id: 'the.two.passwords.that.you.entered.do.not.match' })));
                        },
                      }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Item>
                      <Button block size='large' type="primary" htmlType="submit">
                        <FormattedMessage id="save.changes" />
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>

      {message && !loading ?
        <div className="row col-sm-6 pt-4" style={{ margin: 'auto' }}>
          {isSuccess
            ? <Alert message="" description={message} type="success" showIcon />
            : <Alert message="" description={message} type="error" showIcon />
          }
        </div>
        : null
      }
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    passwordStore: state.passwordStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  change: (current: string, password: string) => {
    dispatch(change(current, password));
  }
});

const ChangePasswordComponentRouter = withRouter(ChangePasswordComponent);

export const ChangePassword = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePasswordComponentRouter));
