import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IHotelDetails } from '@common-types';
import { getFullAddress, Map } from '@utils';

import MapMarker from '@assets/images/map-bed-marker.svg';

import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
  showBigMap: () => void;
  isVacation: boolean;
}

export class Location extends React.Component<IProps, null> {
  smallMap: React.RefObject<HTMLDivElement> = React.createRef();
  smallMapInstance: google.maps.Map;

  componentDidMount(): void {
    const { hotel } = this.props;
    const { location, id } = hotel;

    if (this.smallMap && this.smallMap.current) {
      this.smallMapInstance = Map.initializeMap(
        this.smallMap.current,
        Map.getGoogleLocation(location),
        { disableDefaultUI: true },
      );
      Map.addMarkers(this.smallMapInstance, [{ location, id }], MapMarker);
    }
  }

  render(): React.ReactNode {
    const { hotel, refAnchor, showBigMap, isVacation } = this.props;
    const { address, city, countryCode, state, zipCode } = hotel;
    const fullAddress = getFullAddress(address, city, state, zipCode, countryCode);
    const addressStr = fullAddress.join(', ');

    return (
      <div className="location" ref={refAnchor}>
        {
          isVacation
            ?
            <div className="pb-2">
              <div className="rooms-search__list-text vacation mb-0">
                <div className="rooms-search__header" style={{ fontSize: '24px' }}><FormattedMessage id="tab.map" /></div>
              </div>
            </div>
            : <h4 className="location__tittle"><FormattedMessage id="location" /></h4>
        }

        <div className={`location__wrapper ${isVacation ? 'row' : ''}`}>
          <div className={`location__description-wrapper ${isVacation ? 'col-12' : ''}`}>
            <div className="location__description">
              {hotel.locationDescriptions?.length ? (
                <div>
                  <p className="location__title-text">
                    <FormattedMessage id="surrounding.whatsNearby.title" />
                  </p>
                  {hotel.locationDescriptions.map((description, index) => (
                    <p key={index} className="location__text">
                      {description}
                    </p>
                  ))}
                </div>
              )
              : null
              }
            </div>
            <p className="location__address">
              {fullAddress.length ? addressStr : hotel.address}
            </p>
            {hotel.phone && (
              <p className="location__phone">
                <FormattedMessage id="phone" />: {hotel.phone}
              </p>
            )}
          </div>

          <div className={`location__map-wrapper ${isVacation ? 'vacation col-12 pt-4' : ''}`}>
            <div className={`location__map ${isVacation ? 'vacation' : ''}`} ref={this.smallMap} />
            <div className={`location__map-button ${isVacation ? 'vacation' : ''}`} onClick={showBigMap}>
              <FormattedMessage id="view.on.map" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
