import React from 'react';
import isBefore from 'date-fns/isBefore';
import moment from 'moment';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { Button } from 'antd';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 } from 'uuid';
import { Base64 } from 'js-base64';

import {
  condoFlexibleDatePickerActions,
  CondoGuestInitialState,
  condoGuestsActions,
  condosActions,
  condosLocationPickerActions,
  condoStrictDatesActions,
  getCondos,
  ICondoFlexibleDatePickerState,
  ICondoGuestsState,
  ICondosLocationPickerState,
  ICondosState,
  ICondoStrictDatesState,
  IHotelsState,
} from '@share/store/slices';
import { getDateCheckInOut, getPreferenceFileUrlFromAccount, getPreferenceFromAccount, isCondoDataValid, RootState } from '@share/utils';
import { AccessToken, BlueButton, SearchSelection } from '@share/components';
import { disableAnytimeSearchWarning } from '@share/utils';
import { getTimeout, UrlUtils } from '@share/utils';
import {
  CONDO_DATES_FLEXIBLE_LABEL,
  CONDO_DATES_LABEL,
  CONDO_GUESTS_LABEL,
  CONDO_IS_FLEXIBLE_LABEL,
  CONDO_LOCATIONS_LABEL,
  CONDO_LOCATIONS_OBJECT_LABEL,
  CONDO_SEARCH_BOUNDS,
  CONDO_FILTERS_LABEL,
  CONDO_SESSION_KEY,
  DEFAULT_PAGE_NUMBER,
  REFERENCE_NUMBER_LABEL,
  AGENCY_NUMBER_LABEL,
  Routes,
  ACCESS_TOKEN_LABEL,
} from '@share/constants';
import {
  BACKGROUND_COLOR_FIELD_KEY,
  CondoLocationsEnum,
  CONDOS_BACKGROUND_PHOTO_FIELD_KEY,
  CONDOS_SEARCH_MESSAGE_FIELD_KEY,
  CONDOS_SEARCH_TITLE_FIELD_KEY,
  DateSearchTypeEnum,
  IBounds,
  ICondoLocation,
  ISessionKey,
  PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME,
} from '@share/common-types';
import { SearchTypeEnum, StayForEnum } from '@share/common-types';
import { condoFiltersActions, ICondoFiltersState, ILoginState, LoginType } from '@share/store/slices';
import { Responsive } from '@share/utils';

import { CondoGuestsPicker } from '../condo-guests-picker';
import { CondoStrictDatePicker } from '../condo-strict-date-picker';
import { CondoSearchInfo } from '../condo-search-info';
import { CondoLocationPicker } from '../condo-location-picker';

import { SearchSvg, UpdateSvg } from '@share/assets';

import HotelBackground from '@share/assets/images/hotel/bg-hotels.png';
import CondoBackground from '@share/assets/images/condo/bg-condo.jpg';

import './style.scss';

interface IMapStateToProps {
  condosStore: ICondosState;
  condosLocationPickerStore: ICondosLocationPickerState;
  condoStrictDatesStore: ICondoStrictDatesState;
  condoGuestsStore: ICondoGuestsState;
  condoFlexibleDatePickerStore: ICondoFlexibleDatePickerState;
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  validateLocation: (error: string) => void;
  validateDates: (error: string) => void;
  setIsWidget: (isWidget: boolean) => void;
  setIsSearch: () => void;
  setPageNumber: () => void;
  setIsAnytimeSearchWarningVisible: (visible: boolean) => void;
  getCondos: (isReuse: boolean) => void;
  setShouldFocus: () => void;
  selectLocationLabel: (label: string) => void;
  selectLocation: (location: ICondoLocation) => void;
  setGuestsState: (state: ICondoGuestsState) => void;
  setFlexibleState: (state: ICondoFlexibleDatePickerState) => void;
  setSearchType: (type: DateSearchTypeEnum) => void;
  setDates: (dates: { startDate: string; endDate: string }) => void;
  setSessionKey: (sessionKey: ISessionKey) => void;
  setBounds: (bounds: IBounds) => void;
  setFilters: (filters: ICondoFiltersState) => void;
  resetFilters: () => void;
  resetDates: () => void;
  resetFlexibleState: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  isWidget?: boolean;
  isExperiences?: boolean;
  forceVertical?: boolean;

  title?: string;
  message?: string;
  hideTitle?: boolean;
  hideMessage?: boolean;
  hideSearchSelection?: boolean;
  widgetBackgroundColor?: string;
  widgetOriginalType?: string;
  borderRadius?: string;
  
  excludeCondosSelection?: boolean;
  excludeHotelsSelection?: boolean;
  excludeVacationsSelection?: boolean;
  disableLocationSearch?: boolean;

  onExperience?: () => void;

  onWidgetSelectionChange?: (value: string) => void;
}

interface IState {
  isCollapse: boolean;
}

const zero = 0;
const one = 1;

class CondoSearchComponent extends React.Component<IProps, IState> {
  state: IState = {
    isCollapse: false,
  };

  componentDidMount() {
    const {
      isWidget,
      condosStore,
      setDates,
      selectLocation,
      selectLocationLabel,
      setSearchType,
      setGuestsState,
      setFlexibleState,
      setSessionKey,
      setBounds,
      setFilters,
      setIsWidget,
    } = this.props;

    if (isWidget) {
      setIsWidget(isWidget);
    }

    const values = UrlUtils.getValues();
    let isReuse = false;

    if (values[CONDO_FILTERS_LABEL]) {
      setFilters(values[CONDO_FILTERS_LABEL] as ICondoFiltersState);
    }

    if (values[CONDO_SEARCH_BOUNDS]) {
      setBounds(values[CONDO_SEARCH_BOUNDS] as IBounds);
    }

    if (values[CONDO_LOCATIONS_OBJECT_LABEL]) {
      selectLocation(values[CONDO_LOCATIONS_OBJECT_LABEL] as ICondoLocation);
    }

    if (values[CONDO_LOCATIONS_LABEL]) {
      selectLocationLabel(values[CONDO_LOCATIONS_LABEL] as string);
    }

    if (values[CONDO_DATES_LABEL]) {
      const dates: { startDate: string; endDate: string } = values[CONDO_DATES_LABEL] as {
        startDate: string;
        endDate: string;
      };
      const today = new Date();
      today.setHours(zero, zero, zero, zero);

      setDates(dates);

      if (dates?.startDate && isBefore(getDateCheckInOut(dates?.startDate), today)) {
        setDates({ endDate: undefined, startDate: undefined });
        setSearchType(DateSearchTypeEnum.Strict);
      }
    }

    if (values[CONDO_IS_FLEXIBLE_LABEL]) {
      const value = values[CONDO_IS_FLEXIBLE_LABEL];
      setSearchType(
        value.toString() === `${one}` ? DateSearchTypeEnum.Flexible : DateSearchTypeEnum.Strict,
      );
    }
    if (values[CONDO_DATES_FLEXIBLE_LABEL]) {
      const selectedData: ICondoFlexibleDatePickerState = values[
        CONDO_DATES_FLEXIBLE_LABEL
      ] as ICondoFlexibleDatePickerState;
      const today = new Date();
      today.setHours(zero, zero, zero, zero);
      today.setDate(one);
      const months = selectedData?.selectedMonths.filter((dateString: string) => {
        const date = new Date(dateString);
        date.setHours(zero, zero, zero, zero);
        date.setDate(one);

        return !isBefore(date, today);
      });

      setFlexibleState({
        stayFor: selectedData?.stayFor,
        selectedMonths: months?.length ? months : [],
      });
    }

    if (!values[CONDO_DATES_FLEXIBLE_LABEL]) {
      setFlexibleState({
        stayFor: StayForEnum.Week,
        selectedMonths: [],
      });
    }

    if (values[CONDO_GUESTS_LABEL]) {
      setGuestsState(values[CONDO_GUESTS_LABEL] as ICondoGuestsState);
    }

    const session: ISessionKey = values[CONDO_SESSION_KEY] as ISessionKey;

    if (session && session.expireDate && getTimeout(session.expireDate) > zero) {
      setSessionKey(session);
      isReuse = true;
    }

    setTimeout(() => {
      if (!condosStore.condos && session) {
        this.onSearch({ isReuse, isLoad: true });
      } else {
        const { history, loginStore } = this.props;
        
        const isDisableHomes = loginStore?.account?.isDisableHomes;
        const disableHomesRedirect = loginStore?.account?.disableHomesRedirect;
    
        if (isDisableHomes && !isWidget) {
          if (isEmpty(disableHomesRedirect)) {
            history.push(Routes.NotAuthorized);
          } else {
            window.location.replace(disableHomesRedirect);
          }
        }

      }
    }, 200);
  }

  onAnytimeSearchWarningClose = (e: React.MouseEvent<HTMLElement, MouseEvent>, dontShowAgain: boolean) => {
    if (dontShowAgain) {
      disableAnytimeSearchWarning();
    }
    this.props.setIsAnytimeSearchWarningVisible(false);
  }

  onSearch = ({ isReuse = false, isInit = true, isLoad = false } = {}) => {
    const {
      isWidget,
      isExperiences,
      condoStrictDatesStore,
      condosLocationPickerStore,
      condoGuestsStore,
      condoFlexibleDatePickerStore,
      hotelsStore,
      loginStore,

      intl,

      setIsSearch,
      getCondos,
      setShouldFocus,
      setPageNumber,
      resetFilters,
      setSearchType,
      resetFlexibleState,
      resetDates,
      onExperience,
      validateLocation,
      validateDates,
    } = this.props;

    const { isDatesValid, isLocationValid, isValid, isDatesMaxError } = isCondoDataValid(
      condosLocationPickerStore,
      condoStrictDatesStore,
    );

    if (!isLocationValid) {
      validateLocation(intl.formatMessage({ id: 'condo.location.error' }));
    }

    if (!isDatesValid) {
      validateDates(
        intl.formatMessage({ id: isDatesMaxError ? 'maximum.date.range.search' : 'dates.error' }),
      );
    }

    if (isValid) {

      if (isWidget) {

        const { stayFor, selectedMonths } = condoFlexibleDatePickerStore;
        const { searchType, startDate, endDate } = condoStrictDatesStore;
        const { selectedLocation, selectedLocationLabel } = condosLocationPickerStore;
        const { adultsCount, kidsCount, bedroomsCount, includeStudio } = condoGuestsStore;
        
        const isFlexible = searchType === DateSearchTypeEnum.Flexible;
        const guestParams = UrlUtils.getUrl(CONDO_GUESTS_LABEL, { adultsCount, kidsCount, bedroomsCount, includeStudio });
        const locationParams = `${CONDO_LOCATIONS_OBJECT_LABEL}=${Base64.encode(JSON.stringify(selectedLocation))}`;
        const locationLabelParams = UrlUtils.getUrl(CONDO_LOCATIONS_LABEL, selectedLocationLabel);
        const isFlexibleParams = UrlUtils.getUrl(CONDO_IS_FLEXIBLE_LABEL, isFlexible ? '1' : '0');
        const sessionParams = UrlUtils.getUrl(CONDO_SESSION_KEY, {
          value: v4(),
          isInvalid: true,
          expireDate: moment().format('yyyy-MM-DDTHH:mm:ss.SSSZ')
        } as ISessionKey);
        
        let datesParams;
        if (isFlexible) {
          datesParams = UrlUtils.getUrl(CONDO_DATES_FLEXIBLE_LABEL, { selectedMonths, stayFor });
        } else {
          datesParams = UrlUtils.getUrl(CONDO_DATES_LABEL, { startDate, endDate });
        }
    
        const accessToken = getPreferenceFromAccount(loginStore.account, PUBLIC_WITH_FIXED_TOKEN_FIELD_NAME);
        const hasPublicAccessToken = loginStore.account?.hasPublicAccessToken;
        const hasPublicValidToken = loginStore.account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(accessToken);

        const baseAddressSite = loginStore.account?.baseAddressSite;
        const accountName = loginStore.account?.name;
        const disableNewTabOpen = this.props.loginStore.account?.disableNewTabOpen;

        const path = !isEmpty(baseAddressSite) ? baseAddressSite : process.env.REACT_APP_WIDGET_REDIRECT;
        const referenceNumberParam = !isEmpty(hotelsStore.referenceNumber) ? `${REFERENCE_NUMBER_LABEL}=${hotelsStore.referenceNumber}` : '';
        const agencyNumberParam = !isEmpty(hotelsStore.agencyNumber) ? `${AGENCY_NUMBER_LABEL}=${hotelsStore.agencyNumber}` : '';
        const tokenParam = hasPublicValidToken ? `${ACCESS_TOKEN_LABEL}=${accessToken}` : '';

        const isCondoLocation = selectedLocation.locationType === CondoLocationsEnum.GenericCondo;

        let url;
        if (isCondoLocation) {
          url = `${path}${accountName}${Routes.Condo}/${selectedLocation.id}?${guestParams}&${tokenParam}&${locationParams}&${isFlexibleParams}&${locationLabelParams}&${datesParams}&${sessionParams}&${referenceNumberParam}&${agencyNumberParam}`;
        } else {
          url = `${path}${accountName}${Routes.CondoSearch}?${guestParams}&${tokenParam}&${locationParams}&${isFlexibleParams}&${locationLabelParams}&${datesParams}&${sessionParams}&${referenceNumberParam}&${agencyNumberParam}`;
        }

        if (disableNewTabOpen) {
          window.location.replace(url);
        } else {
          window.open(url, '_blank');
        }

      } else {
        const { shouldFocus, selectedLocation } = condosLocationPickerStore;

        if (!isExperiences && !isLoad && condoStrictDatesStore.searchType === DateSearchTypeEnum.Flexible) {
          setSearchType(DateSearchTypeEnum.Flexible);
        }
    
        if (!shouldFocus && selectedLocation) {
          setPageNumber();
          setIsSearch();
          this.setState({ ...this.state, isCollapse: !this.state.isCollapse });
    
          if (!isInit) {
            resetFilters();
            UrlUtils.setUrl(CONDO_FILTERS_LABEL, null);
          }
    
          if (isWidget) {
    
          } else if (isExperiences) {
            onExperience();
          } else {
            getCondos(isReuse);
    
            if (condoStrictDatesStore.searchType === DateSearchTypeEnum.Strict) {
              resetFlexibleState();
            } else {
              resetDates();
            }
          
            const contentWrapper = document.querySelector('.condo-result-wrapper__content');
    
            if (contentWrapper) {
              contentWrapper.scroll(zero, zero);
              window.scrollTo(zero, zero);
            }
          }
        } else {
          if (!isInit) {
            setShouldFocus();
          }
        }  
      }
    }

  };

  onToggle = () => {
    this.setState({ ...this.state, isCollapse: !this.state.isCollapse });
  };

  render(): React.ReactNode {
    const { 
      isWidget,
      isExperiences,
      condosStore,
      loginStore,
      hideTitle,
      title,
      hideMessage,
      message,
      hideSearchSelection,
      widgetBackgroundColor,
      excludeCondosSelection,
      excludeHotelsSelection,
      excludeVacationsSelection,
      widgetOriginalType,
      forceVertical,
      borderRadius,
      disableLocationSearch,
    } = this.props;
    const { isSearch, condos } = condosStore;
    const { isCollapse } = this.state;

    const { account } = loginStore;

    const searchTitle = getPreferenceFromAccount(account, CONDOS_SEARCH_TITLE_FIELD_KEY);
    const searchMessage = getPreferenceFromAccount(account, CONDOS_SEARCH_MESSAGE_FIELD_KEY);
    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const backgroundImage = getPreferenceFileUrlFromAccount(account, CONDOS_BACKGROUND_PHOTO_FIELD_KEY);
    const isRSITemplate = account?.isRSITemplate;

    const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

    let withImage = false;
    const styleAccount: any = {};   
    const styleMain: any = {};   
    if (!isExperiences) {  
      if (isWidget && widgetBackgroundColor) {
        styleAccount.background = widgetBackgroundColor;
        styleMain.background = 'none';
      } else {
        const background = getPreferenceFromAccount(account, BACKGROUND_COLOR_FIELD_KEY);
        const logoImage = backgroundImage ? 
                            backgroundImage : 
                            account?.name === 'rsi'? 
                                HotelBackground : 
                                background ? 
                                    null : 
                                    isWidget ? null : CondoBackground;

        if (background && !logoImage) {
          styleAccount.background = background;
          styleMain.background = 'none';
        } 
  
        if (logoImage) {
          if (!isSearch) {
            withImage = true;
            styleMain.background = 'none';
          }
  
          styleAccount.backgroundImage = isRSITemplate || condos ?
            !isSearch && `url(${logoImage})` :
            isWidget ?
              !isSearch && `url(${logoImage})` :
              !isSearch && `linear-gradient(rgba(255,255,255,0) 80%, rgba(255,255,255,1)), url(${logoImage})`;
        }  
      }
    } 

    const values = UrlUtils.getValues();

    const searchTitleFinal = isWidget && !isEmpty(title) ? title : searchTitle;
    const searchMessageFinal = isWidget && !isEmpty(message) ? message : searchMessage;

    const mainStyle = {} as any;
    if (!isEmpty(borderRadius)) {
      try {
        const border = parseInt(borderRadius);
        mainStyle.borderRadius = `${border}px`;
        styleAccount.borderRadius = `${border}px`;
      } catch(e) {
        console.error(e);
      }
    }

    return (
      <div
        className={`condo-search ${(isWidget || isExperiences) ? 'widget' : ''}  ${(!isRSITemplate && !condos) ? 'travcoding-template-main' : ''} ${(isSearch && !(isWidget || isExperiences)) ? 'after-search' : ''} ${isCollapse ? 'toggle-condo-search-info' : ''}`}
        style={styleMain}
      >
        <div className={`search-wrapper__image ${withImage ? 'with-image' : ''} ${isWidget ? 'widget' : ''}`} style={styleAccount}></div>

        <div className={`condo-search__wrapper ${backgroundImage? 'background-image' : ''} ${(!isRSITemplate && !condos) ? 'travcoding-template' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`}>
          {!isExperiences ? (
            <>
              {(!isExperiences && (!isWidget || (isWidget && !hideTitle))) ? (
                <h1 className={`condo-search__header  ${isWidget ? 'widget' : ''} ${(isWidget && hideMessage)? 'widget-hide' : ''}`}>
                  {isEmpty(searchTitleFinal) ? (<FormattedMessage id="condo.header" />) : searchTitleFinal}
                </h1>) : null}

              {(!isExperiences && (!isWidget || (isWidget && !hideMessage))) ? (
                <div className={`condo-search__header-small ${(!isRSITemplate && !condos) ? 'travcoding-template' : ''} ${isWidget ? 'widget' : ''} ${(isWidget && hideTitle) ? 'widget-hide' : ''}`}>
                  {isEmpty(searchMessageFinal) ? (<FormattedMessage id="condo.header.small" />) : searchMessageFinal}
                </div>) : null}
            </>) : null}

          <div className={`condo-search__search-section ${(isWidget || isExperiences) ? 'widget' : ''}`}>
            {(hasPublicValidToken || isWidget) && !isExperiences && (!isCollapse || isEmpty(values)) && !condos && !hideSearchSelection && (
              <SearchSelection 
                type={'Condos'}
                isWidget={isWidget}
                widgetOriginalType={widgetOriginalType}
                onWidgetSelectionChange={this.props.onWidgetSelectionChange}
                excludeCondosSelection={excludeCondosSelection}
                excludeHotelsSelection={excludeHotelsSelection}
                excludeVacationsSelection={excludeVacationsSelection}
              />)}

            {(hasPublicValidToken || isWidget) && (
              <div className={`condo-search__search-top ${forceVertical? 'vertical' : ''} ${(isWidget || isExperiences) ? 'widget' : ''}`}>
                <CondoLocationPicker
                  key="location-picker"
                  isExperiences={isExperiences}
                  openOnClick={true}
                  disable={disableLocationSearch}
                  onFireSearch={() => {
                    this.props.setSearchType(DateSearchTypeEnum.Flexible);
                    this.props.setDates({ endDate: undefined, startDate: undefined });
                    this.props.setGuestsState({ ...CondoGuestInitialState });
                    this.props.setFlexibleState({ stayFor: StayForEnum.Week, selectedMonths: [] });
                    if (!isWidget) {
                      setTimeout(() => this.onSearch({ isInit: false }), 200);
                    }
                  }}
                />
                <Responsive>
                  <CondoStrictDatePicker isExperiences={isExperiences} />
                </Responsive>

                <CondoGuestsPicker isExperiences={isExperiences} />

                <BlueButton onClick={() => this.onSearch({ isInit: false })} style={{ height: '60px' }}>
                  {isSearch ? (
                    <>
                      <UpdateSvg />
                      <FormattedMessage id="update.search.your.hotel" />
                    </>
                  ) : (
                    <>
                      <SearchSvg />
                      <FormattedMessage id="find.your.condo" />
                    </>
                  )}
                </BlueButton>
              </div>)}
            {(!hasPublicValidToken && !isWidget) && (<AccessToken />)}          
          </div>
          {isCollapse && <CondoSearchInfo onToggle={this.onToggle} />}
          {isSearch && !isCollapse && !isWidget && (
            <Button
              className="condo-search__toggle-condo-search-info"
              onClick={this.onToggle}
              type="text"
            >
              <FormattedMessage id="cancel" />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    condosLocationPickerStore: state.condosLocationPickerStore,
    condoStrictDatesStore: state.condoStrictDatesStore,
    condoGuestsStore: state.condoGuestsStore,
    condoFlexibleDatePickerStore: state.condoFlexibleDatePickerStore,
    hotelsStore: state.hotelsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  validateLocation: (error: string) => {
    dispatch(condosLocationPickerActions.setError(error));
  },
  validateDates: (error: string) => {
    dispatch(condoStrictDatesActions.setError(error));
  },
  setIsSearch: () => {
    dispatch(condosActions.setIsSearch(true));
  },
  setSessionKey: (sessionKey: ISessionKey) => {
    dispatch(condosActions.setSessionKey(sessionKey));
  },
  setPageNumber: () => {
    dispatch(condosActions.setPageNumber(DEFAULT_PAGE_NUMBER));
  },
  setIsAnytimeSearchWarningVisible: (visible: boolean) => {
    dispatch(condosActions.setIsAnytimeSearchWarningVisible(visible));
  },
  selectLocationLabel: (label: string) => {
    dispatch(condosLocationPickerActions.selectLocationLabel(label));
  },
  selectLocation: (location: ICondoLocation) => {
    dispatch(condosLocationPickerActions.selectLocation(location));
  },
  setGuestsState: (state: ICondoGuestsState) => {
    dispatch(condoGuestsActions.setState(state));
  },
  setFlexibleState: (state: ICondoFlexibleDatePickerState) => {
    dispatch(condoFlexibleDatePickerActions.setState(state));
  },
  setSearchType: (type: DateSearchTypeEnum) => {
    dispatch(condoStrictDatesActions.setSearchType(type));
  },
  setDates: (dates: { startDate: string; endDate: string }) => {
    dispatch(condoStrictDatesActions.setDates(dates));
  },
  setBounds: (bounds: IBounds) => {
    dispatch(condosActions.setBounds(bounds));
  },
  getCondos: (isReuse: boolean) => {
    dispatch(getCondos(SearchTypeEnum.NewSearch, isReuse));
  },
  setShouldFocus: () => {
    dispatch(condosLocationPickerActions.setShouldFocus(true));
  },
  setFilters: (filters: ICondoFiltersState) => {
    dispatch(condoFiltersActions.setFilters(filters));
  },
  resetFilters: () => {
    dispatch(condoFiltersActions.resetFilters());
  },
  setIsWidget: (isWidget: boolean) => {
    dispatch(condosActions.setIsWidget(isWidget));
  },
  resetDates: (value?: boolean) => {
    dispatch(condoStrictDatesActions.resetDates(value));
  },
  resetFlexibleState: () => {
    dispatch(condoFlexibleDatePickerActions.resetState());
  },

});

export const CondoSearch = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(CondoSearchComponent)));
