import React from 'react';
import { Skeleton } from 'antd';

const DashboardHeroSkeleton = () => {
  return (
    <>
      <section className="py-0 pt-0">
        <div className="container position-relative">
          <div className="row mb-3">
            <div className="col-12">
              <Skeleton paragraph={{ rows: 0 }} active />
              <Skeleton paragraph={{ rows: 0 }} active />
            </div>
          </div>
        </div>
      </section>

      <section className="card-grid pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 mb-3 mb-lg-0">
              <Skeleton.Image
                className="skeleton-image"
                style={{
                  minHeight: 300,
                  minWidth: 550,
                  height: 'auto',
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row g-4">
                <div className="col-12 mb-3 mb-md-0">
                  <Skeleton.Image
                    className="skeleton-image"
                    style={{
                      minHeight: 130,
                      minWidth: 300,
                      height: 'auto',
                    }}
                  />
                </div>
                <div className="col-12">
                  <Skeleton.Image
                    className="skeleton-image"
                    style={{
                      minHeight: 145,
                      minWidth: 300,
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { DashboardHeroSkeleton };
