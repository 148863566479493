import React, { useState } from 'react';
import moment from 'moment';
import { LocationSelector } from '../../dashboard/location-component';
import { DateRangePicker } from '../../dashboard/date-range-component';
import { GuestsRoomsSelector } from '../../dashboard/guest-room-component';
import { useHistory } from 'react-router-dom';
import cross from '@assets/images/gallery/cross.svg';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface Room {
  adultCount: number;
  kidsAges: number[];
}

interface HotelImage {
  src: string;
  alt: string;
}
interface Hotel {
  hotelId: number;
  displayName: string;
  starRating: number;
  countryCode: string;
  zipCode: string;
  originalPrice?: number;
  discountedPrice?: number;
  totalPrice?: number;
  refundable?: boolean;
  images?: HotelImage[];
  currency: string;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

const TopNav = ({ location, checkInDate, checkOutDate, rooms, setPageNumber }: any) => {
  const login = useSelector((state: IMapStateToProps) => state.loginStore);
  const checkIn = checkInDate || '';
  const checkOut = checkOutDate || '';
  const countryName = location?.name || '';
  const room = rooms || [];
  const monthMap = {
    Jan: 'Jan ',
    Feb: 'Feb ',
    Mar: 'Mar ',
    Apr: 'Apr ',
    May: 'May ',
    Jun: 'Jun ',
    Jul: 'Jul ',
    Aug: 'Aug ',
    Sep: 'Sept ',
    Oct: 'Oct ',
    Nov: 'Nov ',
    Dec: 'Dec ',
  };
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [updatelocation, setUpdateLocation] = useState<string>(location);
  const [isLocationSelectorVisible, setIsLocationSelectorVisible] = useState<boolean>(false);
  const [updateCheckIn, setUpdateCheckIn] = useState<string>(checkIn);
  const [updateCheckOut, setUpdateSetCheckOut] = useState<string>(checkOut);
  const [isDateVisible, setIsDateVisible] = useState<boolean>(false);
  const [updateRooms, setupdateRooms] = useState<Room[]>(room);
  const [isRoomsVisible, setIsRoomsVisible] = useState<boolean>(false);

  const payload = {
    hotelsRequest: {
      residency: 'US',
      locationCode: '657822',
      location: updatelocation || [],
      checkIn: updateCheckIn || [],
      checkOut: updateCheckOut || [],
      rooms: updateRooms || [],
    },
    filter: {
      distanceInMiles: 10,
      exactSearch: true,
    },
    sortBy: 'BiggestSavingsPercentage',
    pageNumber: 1,
    pageSize: 10,
    quick: false,
    searchHomes: 'All',
    currency: 'USD',
  };

  const handleSubmit = () => {
    if (setPageNumber) {
      setPageNumber(1);
    }
    setIsLoading(true);
    const dates = {
      checkIn: payload.hotelsRequest.checkIn,
      checkOut: payload.hotelsRequest.checkOut,
    };

    try {
      history.replace(
        `/${login.account.name}/search?dates=${btoa(JSON.stringify(dates))}&location=${btoa(
          JSON.stringify(payload.hotelsRequest.location),
        )}&rooms=${btoa(JSON.stringify(payload.hotelsRequest.rooms))}`,
      );
    } catch (error) {
      console.error('Navigation failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomMonthAbbreviation = (month: any) => monthMap[month];

  const handleLocationSelect = (selectedLocation: string) => {
    setDisable(false);
    setUpdateLocation(selectedLocation);
  };

  const handleSearchChange = (searchInput: string) => {
    setDisable(false);
    setUpdateLocation(searchInput);
  };

  const toggleLocationSelector = () => {
    setIsLocationSelectorVisible(!isLocationSelectorVisible);
  };
  const toggleDateSelector = () => {
    setIsDateVisible(!isDateVisible);
  };
  const toggleRoomSelector = () => {
    setIsRoomsVisible(!isRoomsVisible);
  };

  const handleDateChange = (newCheckIn: string, newCheckOut: string) => {
    setDisable(false);
    setUpdateCheckIn(newCheckIn);
    setUpdateSetCheckOut(newCheckOut);
  };

  return (
    <section className="pt-4">
      <div className="container">
        <div className="row g-3">
          <div className="col-lg-4 col-lg-4 h-full position-relative row-gutter">
            <div
              className="card card-body border-1 shadow-sm  p-2"
              style={{
                height: '100%',
                justifyContent: 'center',
              }}
            >
              {isLocationSelectorVisible && (
                <div className="end-0 pe-3 pt-1 top-0 position-absolute">
                  <img
                    src={cross}
                    width={10}
                    onClick={toggleLocationSelector}
                    className="cursor-pointer"
                    alt="Close"
                  />
                </div>
              )}
              <div
                className={`${
                  isLocationSelectorVisible
                    ? ''
                    : 'd-flex justify-content-start  align-items-center btn-bi-color'
                }`}
              >
                {isLocationSelectorVisible ? (
                  ''
                ) : (
                  <div className="icon-lg rounded-circle flex-shrink-0 bg-primary bg-opacity-10 text-primary mb-0">
                    <i className="bi bi-geo-alt fa-fw"></i>
                  </div>
                )}
                <div className="mx-3 ">
                  <span>{isLocationSelectorVisible ? '' : 'Destination'}</span>
                  {isLocationSelectorVisible ? (
                    <div className="mt-1">
                      <LocationSelector
                        onLocationSelect={handleLocationSelect}
                        searchTerm={updatelocation}
                        onSearchChange={handleSearchChange}
                        locationValid={true}
                        showLocationHistory={false}
                      />
                    </div>
                  ) : (
                    <h6 className="mb-1 mt-1" onClick={toggleLocationSelector}>
                      {countryName}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 h-full position-relative row-gutter">
            <div
              className="card card-body  border-1 shadow-sm h-full p-2"
              style={{
                height: '100%',
                justifyContent: 'center',
              }}
            >
              {isDateVisible && (
                <div className="end-0 pe-3 pt-1 top-0 position-absolute">
                  <img
                    src={cross}
                    width={10}
                    onClick={toggleDateSelector}
                    className="cursor-pointer"
                    alt="Close"
                  />
                </div>
              )}
              <div
                className={`${
                  isDateVisible ? '' : 'd-flex justify-content-start align-items-center btn-bi-color'
                }`}
              >
                {isDateVisible ? (
                  ''
                ) : (
                  <div className="icon-lg rounded-circle flex-shrink-0 bg-primary bg-opacity-10 text-primary mb-0">
                    <i className="bi bi-calendar-week fa-fw"></i>
                  </div>
                )}
                <div className="mx-3">
                  {isDateVisible ? '' : 'Dates'}
                  {isDateVisible ? (
                    <DateRangePicker
                      checkIn={updateCheckIn}
                      checkOut={updateCheckOut}
                      onDateChange={handleDateChange}
                      validate={true}
                      isDateValid={true}
                    />
                  ) : (
                    <h6 className="mb-1 mt-1" onClick={toggleDateSelector}>
                      {getCustomMonthAbbreviation(moment(checkIn).format('MMM'))}
                      {moment(checkIn).format('DD')} -{' '}
                      {getCustomMonthAbbreviation(moment(checkOut).format('MMM'))}
                      {moment(checkOut).format('DD')}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 row-gutter">
            <div
              className="card card-body border-1 shadow-sm p-2"
              style={{
                height: '100%',
                justifyContent: 'center',
              }}
            >
              {isRoomsVisible && (
                <div className="end-0 pe-3 pt-1 top-0 position-absolute">
                  <img
                    src={cross}
                    width={10}
                    onClick={toggleRoomSelector}
                    className="cursor-pointer"
                    alt="Close"
                  />
                </div>
              )}
              <div
                className={`${
                  isRoomsVisible ? '' : 'd-flex justify-content-start align-items-center mt-1 btn-bi-color'
                }`}
              >
                {isRoomsVisible ? (
                  ''
                ) : (
                  <div className="icon-lg rounded-circle flex-shrink-0 bg-primary bg-opacity-10 text-primary mb-0">
                    <i className="bi bi-people fa-fw"></i>
                  </div>
                )}
                <div className="mx-3">
                  {isRoomsVisible ? '' : 'Travelers'}
                  {isRoomsVisible ? (
                    <GuestsRoomsSelector
                      rooms={updateRooms}
                      setRooms={setupdateRooms}
                      setDisable={setDisable}
                    />
                  ) : (
                    <h6 className="mb-1 mt-1" onClick={toggleRoomSelector}>
                      {`${rooms?.reduce((acc, room) => acc + room.adultsCount, 0)} Guests, ${
                        rooms?.length
                      } Rooms, ${rooms?.reduce(
                        (totalKids, room) => totalKids + room.kidsAges?.length,
                        0,
                      )} Kids`}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
          {(isDateVisible || isLocationSelectorVisible || isRoomsVisible) && (
            <div className="update-button-top-nav-container">
              <button
                onClick={handleSubmit}
                className="update-button-top-nav card card-body border-1 shadow-sm p-2"
                disabled={disable ? true : false}
              >
                Update Search
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopNav;
