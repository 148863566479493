import React from 'react';
import { Select } from 'antd';
import { IGetawaysLocations, GetawaysDefaultLocation } from '@share/common-types';
import './style.scss';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  options: IGetawaysLocations[];
}

export class FilterLocations extends React.Component<IProps, null> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { onChange, value, disabled = false, options } = this.props;
    const { Option } = Select;

    const optionsFiltered = options.reduce((arr, item) => {
      const removed = arr.filter(i => i.city !== item.city);
      return [...removed, item];
    }, []);

    optionsFiltered.sort((a: IGetawaysLocations, b: IGetawaysLocations) => {
      return a.city.localeCompare(b.city);
    });

    return (
      <div className="filter-locations" ref={this.wrapperRef}>
        <Select
          disabled={disabled}
          value={value || GetawaysDefaultLocation.city}
          onChange={onChange}
          getPopupContainer={this.getPopupContainer}
        >
          {optionsFiltered.map(({ state, city, country }) => (
            <Option key={`${city}-${state}`} value={city}>
              <div className="filter-locations__option">
                {city}
                <div>{country && state && `${country}, ${state}`}</div>
              </div>
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
