import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CondoBookingType, IAdminBookingDetails } from '@common-types';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';
import { Urls } from '@share/constants';

export interface IAdminBookingDetailsState {
  details: IAdminBookingDetails;
  loading: boolean;
  error: string;
  showReminderModal: boolean;
  showMarkAsCanceledModal: boolean;
  retryVoidLoading: boolean;
  isVoided: boolean;
  retryVoidError: string;
  retryCapturedLoading: boolean;
  isCaptured: boolean;
  retryCapturedError: string;
}

const initialState: IAdminBookingDetailsState = {
  details: null,
  loading: false,
  showReminderModal: false,
  showMarkAsCanceledModal: false,
  error: '',
  retryVoidLoading: false,
  isVoided: false,
  retryVoidError: '',
  retryCapturedLoading: false,
  isCaptured: false,
  retryCapturedError: '',
};

const adminBookingDetailsSlice = createSlice({
  name: 'adminBookingDetails',
  initialState,
  reducers: {
    setLoading: (state: IAdminBookingDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IAdminBookingDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setBooking: (
      state: IAdminBookingDetailsState,
      { payload }: PayloadAction<IAdminBookingDetails>,
    ) => {
      state.details = payload;
    },
    setShowReminderModal: (
      state: IAdminBookingDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showReminderModal = payload;
    },
    setShowMarkAsCanceledModal: (
      state: IAdminBookingDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showMarkAsCanceledModal = payload;
    },
    setRetryVoidLoading: (
      state: IAdminBookingDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.retryVoidLoading = payload;
    },
    setIsVoided: (state: IAdminBookingDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isVoided = payload;
    },
    setRetryVoidError: (state: IAdminBookingDetailsState, { payload }: PayloadAction<string>) => {
      state.retryVoidError = payload;
    },
    setRetryCapturedLoading: (
      state: IAdminBookingDetailsState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.retryCapturedLoading = payload;
    },
    setIsCaptured: (state: IAdminBookingDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isCaptured = payload;
    },
    setRetryCapturedError: (
      state: IAdminBookingDetailsState,
      { payload }: PayloadAction<string>,
    ) => {
      state.retryVoidError = payload;
    },
  },
});

export const adminBookingDetailsActions = adminBookingDetailsSlice.actions;

export const adminBookingDetailsReducer = adminBookingDetailsSlice.reducer;

export const getAdminBooking = (type: CondoBookingType, id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(adminBookingDetailsActions.setLoading(true));

    try {
      const { data } = await axiosInstance.get(`${Urls.AdminBooking}/${type}/${id}`, {
        ...getHeaders(),
      });
      dispatch(adminBookingDetailsActions.setBooking(data));
      dispatch(adminBookingDetailsActions.setLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminBookingDetailsActions.setError(error.toString()));
      dispatch(adminBookingDetailsActions.setLoading(false));
    }
  };
};

export const retryVoidCondoBooking = (id: number): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(adminBookingDetailsActions.setRetryVoidLoading(true));

      const { data } = await axiosInstance.post(
        `${Urls.AdminBookingRetryVoid}`,
        { orderId: id },
        {
          ...getHeaders(),
        },
      );

      if (data?.isVoided) {
        dispatch(adminBookingDetailsActions.setIsVoided(data?.isVoided));
      }
      dispatch(adminBookingDetailsActions.setRetryVoidLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminBookingDetailsActions.setRetryVoidError(error.toString()));
      dispatch(adminBookingDetailsActions.setRetryVoidLoading(false));
    }
  };
};

export const retryCapturedCondoBooking = (id: number): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(adminBookingDetailsActions.setRetryCapturedLoading(true));

      const { data } = await axiosInstance.post(
        `${Urls.AdminBookingRetryCapture}`,
        { orderId: id },
        {
          ...getHeaders(),
        },
      );

      if (data?.isCaptured) {
        dispatch(adminBookingDetailsActions.setIsCaptured(data?.isCaptured));
      }
      dispatch(adminBookingDetailsActions.setRetryCapturedLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(adminBookingDetailsActions.setRetryCapturedError(error.toString()));
      dispatch(adminBookingDetailsActions.setRetryCapturedLoading(false));
    }
  };
};
