import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import isNull from 'lodash/isNull';
import { getKeyCancellationFromPath, RootState } from '@share/utils';
import { getReservationCancellationDetails, IReservationCancellationState } from '@share/store/slices';
import { ILoginState, IMenuState, LoginType } from '@share/store/slices';
import { SkeletonReviewBook } from '../../common';
import { ReservationCancellation } from '../reservation-cancellation-info';
import { ReservationCancellationRoomInfo } from '../reservation-cancellation-room-info';
import {
  ErrorRequestCancel,
  CancelledBooking,
  CancellationFailed,
  CancellationNotAvailable,
  CancellationUnknown,
} from '../notification';

import './style.scss';

interface IMapStateProps {
  reservationCancellationStore: IReservationCancellationState;
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  getReservationCancellationDetails: (tempKey: string) => void;
}

interface IProps extends IMapStateProps, IMapDispatchToProps {
  isVacationRentals?: boolean;
}

const ZERO = 0;

export class ReservationCancellationWrapperComponent extends React.Component<IProps, null> {
  componentDidMount(): void {
    const { loginStore } = this.props;
    const { user } = loginStore;

    if (!!user) {
      const tempKey = getKeyCancellationFromPath()
      this.props.getReservationCancellationDetails(tempKey);
    }
  }

  render(): React.ReactNode {
    const { menuStore, loginStore, isVacationRentals } = this.props;
    const { account } = loginStore;
    const { reservationCancellationDetails, loading, error, cancelReservation } =
      this.props.reservationCancellationStore;

    const isPrivateWithToken = account?.type !== LoginType.PrivateWithToken;
    const privateTokenFromUrl = account?.privateTokenFromUrl;

    return (
      <div className="reservation-cancellation-wrapper">
        {loading ? <SkeletonReviewBook /> : null}
        {reservationCancellationDetails && !loading ? (
          <>
            {isNull(cancelReservation) && reservationCancellationDetails.isCancellable ? (
              <div className="reservation-cancellation-wrapper__header">
                <h4 className="reservation-cancellation-wrapper__title">
                  <FormattedMessage id="review.cancel" />
                </h4>
                <p className="reservation-cancellation-wrapper__description">
                  <FormattedMessage id="review.reservation.cancellation" />
                </p>
              </div>
            ) : null}
            <div
              className={`reservation-cancellation-wrapper__wrapper
              ${!reservationCancellationDetails?.reservationDetails ? 'flex-center' : ''}`}
            >
              {isNull(cancelReservation) ? (
                reservationCancellationDetails.isCancellable ? (
                  <ReservationCancellation />
                ) : reservationCancellationDetails.isExpired ? (
                  <CancellationNotAvailable
                    isExpired={reservationCancellationDetails.isExpired}
                    isMemberType={isPrivateWithToken && menuStore.error.length === ZERO}
                    isVacationRentals={isVacationRentals}
                    isExternal={isPrivateWithToken && !privateTokenFromUrl}
                    />
                ) : reservationCancellationDetails.isTryAgainLater ? (
                  <CancellationFailed
                    isTryAgainLater={reservationCancellationDetails.isTryAgainLater}
                    supportPhone={reservationCancellationDetails?.reservationDetails.supportPhone}
                  />
                ) : (
                  <CancellationNotAvailable
                    isMemberType={isPrivateWithToken && menuStore.error.length === ZERO}
                    isVacationRentals={isVacationRentals}
                    isExternal={isPrivateWithToken && !privateTokenFromUrl}
                  />
                )
              ) : (
                <>
                  {cancelReservation?.isCompletedSuccessfully ? (
                    <CancelledBooking
                      city={reservationCancellationDetails.reservationDetails.hotel.city}
                      name={reservationCancellationDetails.reservationDetails.hotel.name}
                      state={reservationCancellationDetails.reservationDetails.hotel.state}
                      isMemberType={isPrivateWithToken && menuStore.error.length === ZERO}
                      isExternal={isPrivateWithToken && !privateTokenFromUrl}
                    />
                  ) : cancelReservation?.isPending ? (
                    <CancellationUnknown
                      supportPhone={reservationCancellationDetails?.reservationDetails.supportPhone}
                    />
                  ) : cancelReservation?.isExpired ? (
                    <CancellationNotAvailable
                      isExpired={reservationCancellationDetails.isExpired}
                      isMemberType={isPrivateWithToken && menuStore.error.length === ZERO}
                      isVacationRentals={isVacationRentals}
                      isExternal={isPrivateWithToken && !privateTokenFromUrl}
                    />
                  ) : cancelReservation?.isTryAgainLater ? (
                    <CancellationFailed
                      isTryAgainLater={cancelReservation.isTryAgainLater}
                      supportPhone={reservationCancellationDetails?.reservationDetails.supportPhone}
                    />
                  ) : !cancelReservation?.isCompletedSuccessfully &&
                    !cancelReservation?.isPending ? (
                    <CancellationUnknown
                      supportPhone={reservationCancellationDetails?.reservationDetails.supportPhone}
                    />
                  ) : null}
                </>
              )}
              {reservationCancellationDetails?.reservationDetails && (
                <ReservationCancellationRoomInfo
                  hotelDetails={reservationCancellationDetails.reservationDetails}
                  isPromo={menuStore.items?.promo}
                />
              )}
            </div>
          </>
        ) : null}
        {error.length !== ZERO && !loading ? <ErrorRequestCancel /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateProps => {
  return {
    reservationCancellationStore: state.reservationCancellationStore,
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getReservationCancellationDetails,
};

export const ReservationCancellationWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationCancellationWrapperComponent);
