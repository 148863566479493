import React from 'react';
import img1 from '@assets/images/home/hotel-generic2.jpg';
import img2 from '@assets/images/home/hotel-generic1.jpg';
import img3 from '@assets/images/home/hotel-generic1.jpg';
import img4 from '@assets/images/home/hotel-generic4.jpg';
import HotelCard from './hotel-card';

const Features = () => {
  const hotels = [
    {
      imgSrc: img1,
      location: 'New York',
      hotelName: 'Hilton Downtown',
      price: 155,
      rating: 4.5,
    },
    {
      imgSrc: img2,
      location: 'Miami',
      hotelName: 'New Apollo Hotel',
      price: 236,
      rating: 4.8,
    },
    {
      imgSrc: img3,
      location: 'Los Angeles',
      hotelName: 'Wyndham L.A.',
      price: 274,
      rating: 4.6,
    },
    {
      imgSrc: img4,
      location: 'Chicago',
      hotelName: 'Windy City Boutique',
      price: 312,
      rating: 4.8,
    },
  ];

  return (
    <section>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 text-center">
            <h2 className="mb-0">Featured Hotels</h2>
          </div>
        </div>

        <div className="row g-4">
          {hotels?.map((hotel, index) => (
            <HotelCard key={index} {...hotel} />
          ))}
        </div>
      </div>
    </section>
  );
};

export { Features };
