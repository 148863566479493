import { SortTypes, Urls } from '@share/constants';
import { IGetaway, IGetawaysLocations, GetawaysDefaultLocation } from '@share/common-types';
import { getHeaders, axiosInstance, getSelectedCurrency } from '@share/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, USD_CURRENCY } from '@constants';
import { AppThunk } from '@share/utils';
import { isEmpty } from 'lodash';

const ZERO = 0;
const ONE = 1;

export interface IGetawaysState {
  getaways: IGetaway[];
  errorGetaways: string;
  loadingGetaways: boolean;
  loadingMoreGetaways: boolean;
  getawaysLocations: IGetawaysLocations[];
  errorGetawaysLocations: string;
  loadingGetawaysLocations: boolean;
  prevGetaways: IGetaway[];
  pageNumber: number;
  pageSize: number;
  getawaysTotalCount: number;
  sortType: SortTypes;
  filterType: IGetawaysLocations;
  promoId: string;
  isGetawaysListPage: boolean;
  activeGetawaySubcategory: string;
}

export const getawaysInitialState: IGetawaysState = {
  getaways: [],
  errorGetaways: '',
  loadingGetaways: false,
  loadingMoreGetaways: false,
  getawaysLocations: [],
  errorGetawaysLocations: '',
  loadingGetawaysLocations: false,
  prevGetaways: [],
  pageNumber: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
  getawaysTotalCount: ZERO,
  sortType: SortTypes.PriceAsc,
  filterType: undefined,
  promoId: '',
  isGetawaysListPage: false,
  activeGetawaySubcategory: ''
};

const getawaysSlice = createSlice({
  name: 'getaways',
  initialState: getawaysInitialState,
  reducers: {
    setGetaways: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ getaways: IGetaway[]; isLoadMore: boolean }>,
    ) => {
      state.prevGetaways = payload.getaways;

      if (payload.isLoadMore) {
        state.getaways = [...(state.getaways || []), ...payload.getaways];
      } else {
        state.getaways = payload.getaways;
      }
    },
    setGetawaysTotalCount: (state: IGetawaysState, { payload }: PayloadAction<number>) => {
      state.getawaysTotalCount = payload;
    },
    setLoadingGetaways: (state: IGetawaysState, { payload }: PayloadAction<boolean>) => {
      state.loadingGetaways = payload;
    },
    setLoadingMoreGetaways: (state: IGetawaysState, { payload }: PayloadAction<boolean>) => {
      state.loadingMoreGetaways = payload;
    },
    setErrorGetaways: (state: IGetawaysState, { payload }: PayloadAction<string>) => {
      state.errorGetaways = payload;
    },
    setPageNumber: (state: IGetawaysState, { payload }: PayloadAction<number | undefined>) => {
      state.pageNumber = payload ? payload : state.pageNumber + ONE;
    },
    setPageSize: (state: IGetawaysState, { payload }: PayloadAction<number | undefined>) => {
      state.pageSize = payload;
    },
    setGetawaysLocations: (
      state: IGetawaysState,
      { payload }: PayloadAction<IGetawaysLocations[]>,
    ) => {
      state.getawaysLocations = payload;
    },
    setLoadingGetawaysLocations: (state: IGetawaysState, { payload }: PayloadAction<boolean>) => {
      state.loadingGetawaysLocations = payload;
    },
    setErrorGetawaysLocations: (state: IGetawaysState, { payload }: PayloadAction<string>) => {
      state.errorGetawaysLocations = payload;
    },
    setSortType: (state: IGetawaysState, { payload }: PayloadAction<SortTypes>) => {
      state.sortType = payload;
    },
    setPromoId: (state: IGetawaysState, { payload }: PayloadAction<string>) => {
      state.promoId = payload;
    },
    setGetawaysListPage: (state: IGetawaysState, { payload }: PayloadAction<boolean>) => {
      state.isGetawaysListPage = payload;
    },
    setFiltrationType: (state: IGetawaysState, { payload }: PayloadAction<IGetawaysLocations>) => {
      state.filterType = payload;
    },
    resetFilters: (state: IGetawaysState) => {
      state.filterType = undefined;
      state.promoId = '';
    },
    setDefaultValues: (state: IGetawaysState) => {
      return {...getawaysInitialState, activeGetawaySubcategory: state.activeGetawaySubcategory};
    },
    setActiveGetawaySubcategory: (state: IGetawaysState, { payload }: PayloadAction<string>) => {
      state.activeGetawaySubcategory = payload;
    }
  },
});

export const getawaysActions = getawaysSlice.actions;

export const getawaysReducer = getawaysSlice.reducer;

export const getGetaways = (
  getaway: string,
  subCategory: string,
  isLoadMore = false,
  isSearch = false,
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      if (isLoadMore || isSearch) {
        dispatch(getawaysActions.setLoadingMoreGetaways(true));
      } else {
        dispatch(getawaysActions.setLoadingGetaways(true));
      }

      const { getawaysStore, loginStore } = getState();

      const currency = getSelectedCurrency(loginStore.account);

      const { data } = await axiosInstance.post(
        Urls.Getaways,
        {
          getaway: getaway,
          subCategory: subCategory,
          sortBy: getawaysStore.sortType,
          location: getawaysStore.filterType,
          promoId: getawaysStore.promoId,
          currency: isEmpty(currency) ? USD_CURRENCY : currency,
          page: {
            size: getawaysStore.pageSize,
            number: getawaysStore.pageNumber,
          },
        },
        {
          ...getHeaders(),
        },
      );

      dispatch(getawaysActions.setGetaways({ getaways: data.deals, isLoadMore }));
      if (!isLoadMore) {
        dispatch(getawaysActions.setGetawaysTotalCount(data.totalCount));
      }
      dispatch(getawaysActions.setLoadingGetaways(false));
      dispatch(getawaysActions.setLoadingMoreGetaways(false));
    } catch (error) {
      dispatch(getawaysActions.setErrorGetaways(error.toString()));
      dispatch(getawaysActions.setLoadingGetaways(false));
      dispatch(getawaysActions.setLoadingMoreGetaways(false));
    }
  };
};

export const getGetawaysLocations = (getaway: string, subCategory: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(getawaysActions.setLoadingGetawaysLocations(true));

      const { data } = await axiosInstance.get(`${Urls.Getaways}/${getaway}/locations`, {
        params: {
          subCategory,
        },
        ...getHeaders(),
      });

      if (data) {
        const filtrationList = [GetawaysDefaultLocation, ...data];
        dispatch(getawaysActions.setGetawaysLocations(filtrationList));
      }

      dispatch(getawaysActions.setLoadingGetawaysLocations(false));
    } catch (error) {
      dispatch(getawaysActions.setErrorGetawaysLocations(error.toString()));
      dispatch(getawaysActions.setLoadingGetawaysLocations(false));
    }
  };
};
