import React from 'react';

import { Modal, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';

import { Action } from '@reduxjs/toolkit';

import { RootState } from '@share/utils';
import { ICondoFlexibleDatePickerState, condoFlexibleDatePickerActions, condoStrictDatesActions, condoGuestsActions } from '@share/store/slices';
import { ICondosStaticState } from '@share/store/slices';
import { DateSearchTypeEnum, ICondoLocation } from '@share/common-types';
import { USA } from '@share/constants';
import { ICondoGuestsState } from '@share/store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { CondoLocationPicker } from '../condo-location-picker';

import './style.scss';

const MODAL_WIDTH = 800;

interface IMapStateToProps {
  condosStaticStore: ICondosStaticState;
}

interface IMapDispatchToProps {
  setGuestsState: (state: ICondoGuestsState) => void;
  setFlexibleState: (state: ICondoFlexibleDatePickerState) => void;
  setSearchType: (type: DateSearchTypeEnum) => void;
  setDates: (dates: { startDate: string; endDate: string }) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  visible: boolean;

  onSelect: (location: ICondoLocation) => void;
  onQuick: () => void;
  onCancel: () => void;
}

interface IState {
  countrySelected: string;
  stateSelected: string;
}

class ModalCondoLocationDestinationsComponent extends React.Component<IProps, IState> {

  state: IState = {  countrySelected: null, stateSelected: null };

  countriesWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  statesWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount(): void {
    this.setState({ countrySelected: null });
  }

  handleSelectCountryChange = (countrySelected: string) => {
    this.setState({ countrySelected, stateSelected: null });
  }
  handleSelectStateChange = (stateSelected: string) => {
    this.setState({ stateSelected });
  }

  handleQuick = (location: ICondoLocation) => {
    this.props.onSelect(location);
    this.props.onQuick();
    this.props.onCancel();
    this.setState({ countrySelected: null, stateSelected: null });
  }

  getPopupContainer = () => {
    return this.countriesWrapperRef ? this.countriesWrapperRef.current : document.body;
  };

  getPopupContainerStates = () => {
    return this.statesWrapperRef ? this.statesWrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { visible, condosStaticStore, onCancel } = this.props;
    const { countrySelected, stateSelected } = this.state;
    const { allDestinations, allDestinationCountries, allDestinationCountryStates } = condosStaticStore;

    let allDestinationsFiltered = [...allDestinations];

    if (!isEmpty(countrySelected)) {
      allDestinationsFiltered = allDestinationsFiltered.filter(d => d.country?.toLowerCase().includes(countrySelected.toLowerCase()));
    }

    if (!isEmpty(stateSelected)) {
      allDestinationsFiltered = allDestinationsFiltered.filter(d => d.state?.toLowerCase().includes(stateSelected.toLowerCase()));
    }

    allDestinationsFiltered.sort((a, b) => a.city.localeCompare(b.city));

    const displayState = [USA].includes(countrySelected);
    const displayList = !isEmpty(countrySelected) && (!displayState || !isEmpty(stateSelected));
    const displayListMessage = isEmpty(countrySelected) ? 'see.all.destinations.select_country' : (displayState && isEmpty(stateSelected)) ? 'see.all.destinations.select_state' : '';
    const states = displayState ? allDestinationCountryStates[countrySelected] : null;

    return (
      <Modal
        className="modal-condo-location-destinations"
        visible={visible}
        footer={null}
        onCancel={onCancel}
        width={MODAL_WIDTH}
        wrapClassName="modal-condo-location-destinations__wrapper"
      >
        <div>
        <h4 className="modal-condo-location-destinations__title">
            <FormattedMessage id="see.all.destinations.title" />
          </h4>
          <h4 className="modal-condo-location-destinations__sub-title">
            <FormattedMessage id="see.all.destinations.destination.by.country" />
          </h4>
          <div className="modal-condo-location-destinations__container">
            <div className="modal-condo-location-destinations__filters" ref={this.countriesWrapperRef}>
              <Select value={countrySelected} onChange={this.handleSelectCountryChange} placeholder={<FormattedMessage id="select.country" />} getPopupContainer={this.getPopupContainer}>
                {allDestinationCountries?.map((country, index) => <Select.Option key={`all_destinations_country_${index}`} value={country}>{country}</Select.Option>)}
              </Select>

              {isEmpty(countrySelected) ?
                  null : (
                  <FontAwesomeIcon icon={faTimesCircle} className="filters-search-icon filters-search-icon__clear" onClick={() => this.setState({ countrySelected: null, stateSelected: null })} />)}
            </div>

            {displayState && (
              <div className="modal-condo-location-destinations__filters" ref={this.statesWrapperRef}>
                <Select value={stateSelected} onChange={this.handleSelectStateChange} placeholder={<FormattedMessage id="select.by.state" />} getPopupContainer={this.getPopupContainerStates}>
                  {states?.map((state: string, index: number) => <Select.Option key={`all_destinations_state_${index}`} value={state}>{state}</Select.Option>)}
                </Select>

                {isEmpty(stateSelected) ?
                  null : (
                  <FontAwesomeIcon icon={faTimesCircle} className="filters-search-icon filters-search-icon__clear" onClick={() => this.setState({ stateSelected: null })} />)}
              </div>)}
          </div>

          <div className="modal-condo-location-destinations__destinations">
            <div className="container-fluid">
              <div className="row">
                {displayList ?
                  allDestinationsFiltered.map((dest, index) => (
                    <div key={`destinations-group_${index}`} className="col-lg-4">
                      <label style={{ display: 'flex', position: 'relative' }}>
                        <div>
                          <span className="modal-condo-location-destinations__destination-item" onClick={() => this.handleQuick(dest.location)}>{dest.city}</span> - {dest.totalAmount}
                        </div>
                      </label>
                    </div>)) : <FormattedMessage id={displayListMessage} />}
              </div>
            </div>
          </div>

          <h4 className="modal-condo-location-destinations__sub-title" style={{ marginTop: '30px' }}>
            <FormattedMessage id="see.all.destinations.destination.by.name" />
          </h4>

          <CondoLocationPicker key="location-picker-modal" isSmall={true} isOnlyPicker={true} onSelect={() => this.props.onCancel()} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStaticStore: state.condosStaticStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setGuestsState: (state: ICondoGuestsState) => {
    dispatch(condoGuestsActions.setState(state));
  },
  setFlexibleState: (state: ICondoFlexibleDatePickerState) => {
    dispatch(condoFlexibleDatePickerActions.setState(state));
  },
  setSearchType: (type: DateSearchTypeEnum) => {
    dispatch(condoStrictDatesActions.setSearchType(type));
  },
  setDates: (dates: { startDate: string; endDate: string }) => {
    dispatch(condoStrictDatesActions.setDates(dates));
  },
});

export const ModalCondoLocationDestinations = connect(mapStateToProps, mapDispatchToProps)(ModalCondoLocationDestinationsComponent);
