import { IGetawayDetails } from '@share/common-types';
import { getHeaders, axiosInstance, getSelectedCurrency } from '@share/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { AppThunk } from '@share/utils';
import { USD_CURRENCY } from '@constants';
import { isEmpty } from 'lodash';

export interface IGetawaysDetailsState {
  getaway: IGetawayDetails;
  error: string;
  isLoading: boolean;
}

export const getawaysDetailsInitialState: IGetawaysDetailsState = {
  getaway: null,
  error: '',
  isLoading: false,
};

const getawaysDetailsSlice = createSlice({
  name: 'getaways-details',
  initialState: getawaysDetailsInitialState,
  reducers: {
    setGetaway: (state: IGetawaysDetailsState, { payload }: PayloadAction<IGetawayDetails>) => {
      state.getaway = payload;
    },
    setError: (state: IGetawaysDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLoading: (state: IGetawaysDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setDefaultValues: () => {
      return getawaysDetailsInitialState;
    },
  },
});

export const getawaysDetailsActions = getawaysDetailsSlice.actions;

export const getawaysDetailsReducer = getawaysDetailsSlice.reducer;

export const getGetawaysDetails = (getaway: string, promoId: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(getawaysDetailsActions.setLoading(true));

      const { loginStore } = getState();
      const currency = getSelectedCurrency(loginStore.account);

      const { data } = await axiosInstance.get(`${Urls.Getaways}/${getaway}/details/${!isEmpty(currency) ? currency : USD_CURRENCY}/${promoId}`, {
        ...getHeaders(),
      });

      dispatch(getawaysDetailsActions.setGetaway(data));
      dispatch(getawaysDetailsActions.setLoading(false));
    } catch (error) {
      dispatch(getawaysDetailsActions.setLoading(false));
      dispatch(getawaysDetailsActions.setError(error.toString()));
    }
  };
};
