import React from "react";
import { Card, Row, Col, Skeleton } from "antd";

const DashboardCardsSkeleton = ({ count = 3 }) => {
  return (
    <div className="card-body p-0 pt-3">
      <div className="vstack gap-5">
        {Array.from({ length: count }).map((_, idx) => (
          <Card key={idx} bordered={false} className="bg-transparent p-3">
            <Row gutter={[16, 16]}>
              <Col md={5}>
                <Skeleton.Image active />
              </Col>
              <Col md={16}>
                <Skeleton active>
                  <Card.Meta
                    title={
                      <Skeleton.Input
                        style={{ width: 200 }}
                        active
                        size="small"
                      />
                    }
                    description={
                      <>
                        <Skeleton.Button
                          style={{ width: 100 }}
                          active
                          size="small"
                        />
                        <div style={{ marginTop: 10 }}>
                          <Skeleton.Input
                            style={{ width: 150 }}
                            active
                            size="small"
                          />
                          {/* <Skeleton.Input
                            style={{ width: 100 }}
                            active
                            size="small"
                          /> */}
                        </div>
                      </>
                    }
                  />
                  <div style={{ marginTop: 10 }}>
                    <Skeleton.Button style={{ width: 180 }} active />
                    <Skeleton.Button
                      style={{ width: 120,  marginTop: 10  }}
                      active
                    />
                  </div>
                </Skeleton>
              </Col>
            </Row>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default DashboardCardsSkeleton;
