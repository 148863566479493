import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Card, Spin } from 'antd';
import useApi from '../../../../api-hook/component';
import createAccountPayload from '../../../pages/register/payload.js';
import { companyInfoForm } from "../../../../_mock-api's/index";
import { API_END_POINT, BASE_URL } from '../../../../constants/api-enums';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

const { Option } = Select;

interface State {
  code: string;
  name: string;
}

interface Country {
  isZipRequired: boolean;
  isoCode: string;
  name: string;
  states: State[];
  zipRuleType: string;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

const CompanyCard: React.FC = () => {
  const [form] = Form.useForm();
  const login = useSelector((state: IMapStateToProps) => state.loginStore);
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<any>();
  // const { fetchData: fetchCountriesData } = useApi(
  //   "https://qa2-api.travcoding.com:4000"
  // );
  const { fetchData: fetchSaveData } = useApi(BASE_URL.API_BASE_URL);

  // const fetchCountries = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await fetchCountriesData(
  //       API_END_POINT.FETCH_COUNTRIES,
  //       {},
  //       "post"
  //     );
  //     if (result?.rciCountries) {
  //       const uniqueCountries = Array.from(
  //         new Set(
  //           result?.rciCountries?.map((country: Country) => country?.isoCode)
  //         )
  //       )?.map((isoCode) => {
  //         return result?.rciCountries?.find(
  //           (country: Country) => country.isoCode === isoCode
  //         );
  //       });
  //       setCountries(uniqueCountries);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error parsing countries data:", error);
  //     setLoading(false);
  //   }
  // };

  const handleCountryChange = (value: string) => {
    const selectedCountry = countries?.find((country) => country?.isoCode === value);
    setStates(selectedCountry?.states || []);
    form.setFieldsValue({ state: undefined });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const getAccountUser = await fetchSaveData(
          `${API_END_POINT.FETCH_USER_ACCOUNT}${login.account.id}`,
          {},
          'get',
        );
        setAccountData(getAccountUser);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    createAccountPayload.email = values.email;
    createAccountPayload.name = values.name;
    createAccountPayload.id = accountData?.id;
    createAccountPayload.parentAccountId = accountData?.parentAccountId;
    createAccountPayload.preferences = accountData?.preferences;
    createAccountPayload.property = values.property;

    const payloadDataStringify = JSON.stringify(createAccountPayload);
    const formData = new FormData();
    formData.append('query', payloadDataStringify);
    try {
      const result = await fetchSaveData(API_END_POINT.SAVE_USER_ACCOUNT, formData, 'post');
      if (!result?.status) {
        toast.success('Company Information updated successfully', {
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  useEffect(() => {
    if (accountData) {
      const initialValues = {
        name: accountData?.name,
        email: accountData?.email,
        id: accountData?.id,
        accountId: accountData?.id,
        parentAccountId: accountData?.parentAccountId,
        baseAddressSite: accountData?.baseAddressSite,
        preferences: accountData?.preferences,
        property: accountData?.property,
      };
      form.setFieldsValue(initialValues);
    }
  }, [accountData]);

  return (
    <>
      <Card className="shadow-sm mb-5">
        <div className="card-header border-bottom">
          <h4 className="card-header-title mb-3">
            <i className="bi bi-building mx-2"></i>Company Information
          </h4>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Card bordered={false} className="border-bottom mb-3">
            <Spin spinning={loading}>
              <div className="row">
                {companyInfoForm?.map((item, index) => (
                  <div key={index} className={`col-md-${item.col} col-12`}>
                    <Form.Item
                      name={item.name}
                      label={item.label}
                      rules={[
                        {
                          required: item.required,
                          message: `${item.label} is required`,
                        },
                      ]}
                    >
                      {item.inputType === 'select' ? (
                        <Select
                          placeholder={item.placeholder}
                          onChange={item.name === 'country' ? handleCountryChange : undefined}
                          disabled={loading}
                        >
                          {item.name === 'country'
                            ? countries?.map((country) => (
                                <Option key={country.isoCode} value={country.isoCode}>
                                  {country.name}
                                </Option>
                              ))
                            : states?.map((state) => (
                                <Option key={state.code} value={state.code}>
                                  {state.name}
                                </Option>
                              ))}
                        </Select>
                      ) : (
                        <Input
                          type={item.inputType}
                          disabled={item.disabled}
                          placeholder={item.placeholder}
                        />
                      )}
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Spin>
          </Card>
          <div className="text-end">
            <Button type="primary" htmlType="submit" disabled={loading}>
              Save Changes
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default CompanyCard;
