import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Table } from 'antd';
import format from 'date-fns/format';
import { DATE_FORMAT_FOR_TABLE } from '@constants';
import { AppThunk, RootState } from '@share/utils';
import { getActivityUsers, IAdminActivityUsersState } from '@store/slices';
import './style.scss';

interface IMapStateToProps {
  adminActivityUsers: IAdminActivityUsersState;
}

interface IMapDispatchToProps {
  getActivityUsers: () => AppThunk;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

//TODO  implement data activity users
class ActivityUsersComponent extends React.Component<IProps, null> {
  componentDidMount() {
    this.props.getActivityUsers();
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({ id: 'date.changed' }),
        dataIndex: 'date',
        key: 'date',
        render: (date: string) => (
          <p className="activity-users__date">{format(new Date(date), DATE_FORMAT_FOR_TABLE)}</p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'product.type' }),
        dataIndex: 'productType',
        key: 'productType',
        render: (productType: string) => (
          <p className={`activity-users__type ${productType === 'Condos' ? 'condos-type' : ''}`}>
            {productType}
          </p>
        ),
      },
      {
        title: intl.formatMessage({ id: 'rule.id.column' }),
        dataIndex: 'ruleId',
        key: 'ruleId',
      },
      {
        title: intl.formatMessage({ id: 'action' }),
        dataIndex: 'action',
        key: 'action',
      },
      {
        title: intl.formatMessage({ id: 'user' }),
        dataIndex: 'user',
        key: 'user',
      },
    ];
  };

  render(): React.ReactNode {
    const { adminActivityUsers } = this.props;

    return (
      <div className="activity-users">
        <Table columns={this.getColumns()} dataSource={adminActivityUsers.activityUsers} />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminActivityUsers: state.adminActivityUsersStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { getActivityUsers };

export const ActivityUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ActivityUsersComponent));
