import React from 'react';
import { ReviewBookWrapper } from '@components';
import { RootState, scrollTop } from '@share/utils';
import { connect } from 'react-redux';
import './style.scss';
import { TRAVCODING_V2 } from '@share/common-types';
import DashboardHotelBook from '../../pages/dashboard-hotel-book/component';
import { ILoginState } from '@share/store/slices';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  isVacationRentals?: boolean;
  loginStore?: ILoginState;
}

interface IMapStateToProps {
  loginStore: ILoginState;
}

class ReviewBook extends React.Component<IProps, null> {
  constructor(props: IProps) {
    super(props);
    scrollTop();
  }

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account } = loginStore;
    return (
      <>
        {account?.homePageTemplate === TRAVCODING_V2 ? (
          <DashboardHotelBook />
        ) : (
          <div className="review-book-page">
            <ReviewBookWrapper isVacationRentals={this.props.isVacationRentals} />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ReviewBookPage = connect(mapStateToProps)(withRouter(ReviewBook));

export default ReviewBookPage;
