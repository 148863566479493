
import { IGeoLocation } from '@share/common-types';

export enum CondoLocationsEnum {
  Unspecified = 'Unspecified',
  GenericRegion = 'GenericRegion',
  GenericCondo = 'GenericCondo',
  NoAvailability = 'NoAvailability'
}


export enum LocationsTypes {
  Unspecified = 'Unspecified',
  Destination = 'City',
  Hotel = 'Hotel',
  Airport = 'Airport',
  Port = 'Port',
  Recents = 'Recents',
}

export interface ILocation {
  code: number;
  name: string;
  type: LocationsTypes;
  realType?: LocationsTypes;
  
  geoLocation: IGeoLocation;
}

export interface ICondoLocation {
  id: string;
  name: string;
  region?: string;
  locationType: CondoLocationsEnum;
  keywords?: string[];

  geoLocation?: IGeoLocation;
}
