import React from 'react';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from '@share/utils';
import { BasePriceChangeModal } from '@components';
import { IHotelDetailsState, IReviewBookState } from '@store/slices';
import { ILoginState, IMarginatorState } from '@share/store/slices';
import { getPriceDiffDetails } from '@utils';


import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  hotelDetailsStore: IHotelDetailsState;
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
}

interface IProps extends WrappedComponentProps, IMapStateToProps, RouteComponentProps {
  isVacationRentals?: boolean;
}

class ReviewBookPriceChangeModalComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { loginStore, reviewBookStore, hotelDetailsStore, marginatorStore, isVacationRentals } = this.props;
    const { bookingSummary } = reviewBookStore;
    const { hotel } = hotelDetailsStore;
    const { account } = loginStore;

    const { newPrice, isIncrease, diff } = getPriceDiffDetails(bookingSummary, hotel, marginatorStore.percentage, account?.breakdownTaxes);
    const currency = bookingSummary?.bookingCard?.package?.currency;

    return <BasePriceChangeModal currency={currency} newPrice={newPrice} diff={diff} isIncrease={isIncrease} isBookPage={true} isVacationRentals={isVacationRentals} />
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    hotelDetailsStore: state.hotelDetailsStore,
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
  };
};

export const ReviewBookPriceChangeModal = connect(mapStateToProps)(injectIntl(withRouter(ReviewBookPriceChangeModalComponent)));
