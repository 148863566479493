import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorAlertSvg } from '@assets';
import './style.scss';

interface IProps {
  isTryAgainLater: boolean;
  supportPhone: string;
}

export class CancellationFailed extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { isTryAgainLater, supportPhone } = this.props;

    return (
      <div className="cancellation-failed">
        <ErrorAlertSvg />
        <p className="cancellation-failed__title">
          <FormattedMessage id="cancellation.failed" />
        </p>
        <p className="cancellation-failed__description">
          {isTryAgainLater ? (
            <FormattedMessage id="cancellation.has.failed.at.the.moment" />
          ) : (
            <FormattedMessage id="cancellation.has.failed" />
          )}
        </p>
        <div className="cancellation-failed__footer">
          <p className="cancellation-failed__footer-text">
            <FormattedMessage
              id="cancellation.failed.if.you.have.any.questions"
              values={{ phone: '' }}
            />
            <a href={`tel:${supportPhone}`} className="cancellation-failed__phone">
              {supportPhone}
            </a>
          </p>
        </div>
      </div>
    );
  }
}
