import React from "react";

type CardProps = {
  imgSrc: string;
  title: string;
  description: string;
  buttonText: string;
};

const Card: React.FC<CardProps> = ({
  imgSrc,
  title,
  description,
  buttonText,
}) => {
  return (
    <div className="card card-metro overflow-hidden rounded-3">
      <img src={imgSrc} alt={imgSrc} />
      <div className="card-img-overlay d-flex ">
        <div className="card-text mt-auto">
          <h4>
            <div className="text-white  stretched-link">
              {title}
            </div>
          </h4>
          <p className="text-white mb-3">{description}</p>
          <button className="btn btn-outline-white text-white p-2 mb-0">
            {buttonText} <i className="fa-solid fa-arrow-right-long fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
