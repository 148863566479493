import React from 'react';
import { CondoReviewBookWrapper } from '@components';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

export class CondoReviewBookPage extends React.Component<IProps, null> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="review-book-page">
        <CondoReviewBookWrapper />
      </div>
    );
  }
}
