import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';

interface DateRangePickerProps {
  placeholder?: string;
  checkIn: string;
  checkOut: string;
  onDateChange: (checkIn: string, checkOut: string) => void;
  isDateValid: boolean;
  leadTime?: string; // Add leadTime prop
  validate?: any;
}

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  const {
    placeholder = 'Select date',
    checkIn,
    checkOut,
    onDateChange,
    isDateValid,
    leadTime,
    validate,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const minDate = moment().add(Number(leadTime), 'days').toDate();

      const fp = flatpickr(inputRef.current, {
        mode: 'range',
        defaultDate: [checkIn, checkOut],
        minDate: minDate,
        onChange: (selectedDates) => {
          if (selectedDates.length === 2) {
            console.log(selectedDates)
            const [startDate, endDate] = selectedDates.map((date) =>
              moment(date).startOf('day').toISOString(true),
            );
            onDateChange(startDate || '', endDate || '');
          } else {
            onDateChange('', '');
          }
        },
      });

      return () => {
        fp.destroy();
      };
    }
  }, [leadTime]); // Add leadTime to the dependency array

  return (
    <div className="d-flex">
      <i className="bi bi-calendar-week fs-4 me-2 mt-3"></i>
      <div
        className={`form-control-border form-control-bg-light form-fs-md ${
          isDateValid ? 'form-control-bg-light' : 'form-control-bg-light-danger'
        }`}
      >
        <label className={`${!isDateValid ? 'form-label form-label-validate' : 'form-label'}`}>
          Check In - Out
        </label>
        <input
          type="text"
          className="form-control flatpickr"
          data-mode="range"
          placeholder={placeholder}
          ref={inputRef}
        />
        {/* {leadTime > 0 && (
          <div className="text-danger mt-2 lead-time-text">
            {leadTime} Days in Advance are Required to perform a search
          </div>
        )} */}
      </div>
    </div>
  );
};

export { DateRangePicker };
