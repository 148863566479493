import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Progress } from 'antd';
import { formatRate } from '@share/utils';
import { ICategoryList } from '@share/common-types';

import './style.scss';

interface IProps {
  categoryList: ICategoryList[];
}

const ZERO = 0;

export class TrustYouSentenceCategory extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { categoryList } = this.props;

    if (!categoryList) {
      return null;
    }

    return (
      <div className="comments">
        {categoryList.map((category, index) => (
          <div className="comments__wrapper" key={`${category.category_name} + ${index}`}>
            <div className="comments__rated-wrapper">
              <div className="comments__rated">
                <p className="comments__rated-item">{category.category_name}</p>
                <p className="comments__rate-item">{formatRate(category.score)}</p>
              </div>
              <div className="comments__rated mobile">
                <p className="comments__rated-item">
                  {category.category_name} - {formatRate(category.score)}
                </p>
                <p className="comments__rated-reviews">
                  {category.count} <FormattedMessage id="reviews" />
                </p>
              </div>
              <Progress percent={category.score} showInfo={false} />
              <p className="comments__rated-reviews mobile">
                {category.count} <FormattedMessage id="reviews" />
              </p>
            </div>
            <div className="comments__comment">
              {category.summary_sentence_list.map((item, index) =>
                index === ZERO ? (
                  <span className="comments__comment-text-main" key={index}>
                    &quot;{item.text}&quot;
                  </span>
                ) : (
                  <span className="comments__comment-text" key={index}>
                    {item.text}
                  </span>
                ),
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
