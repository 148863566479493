import React from "react";
import { Card, Skeleton } from "antd";

const InformationCardSkeleton = () => {
  return (
    <div className="card shadow">
      <div className="card-header p-4 border-bottom">
        <Skeleton active={true} paragraph={false} />
      </div>

      <div className="card-body p-4">
        <div className="card mb-4">
          <div className="row align-items-center">
            <div className="col-sm-6 col-md-3">
              <Skeleton.Image active />{" "}
            </div>

            <div className="col-sm-6 col-md-9">
              <div className="card-body pt-3 pt-sm-0 p-0">
                <Skeleton active={true} paragraph={false} />
                <Skeleton active={true} paragraph={false} />
                <Skeleton active={true} paragraph={false} />
              </div>
            </div>
          </div>
        </div>

        <div className="row g-2 card-skeleton-group-height">
          <div className="col-lg-4">
            <Card>
              <Skeleton active={true} paragraph={{ rows: 1 }} title={true} />
            </Card>
          </div>

          <div className="col-lg-4">
            <Card>
              <Skeleton active={true} paragraph={{ rows: 1 }} title={true} />
            </Card>
          </div>

          <div className="col-lg-4">
            <Card>
              <Skeleton active={true} paragraph={{ rows: 1 }} title={true} />
            </Card>
          </div>
        </div>

        <div className="card border mt-8">
          <div className="card-header border-bottom d-md-flex justify-content-md-between">
            <Skeleton active={true} paragraph={false} />
            <Skeleton active={true} paragraph={false} />
          </div>

          <div className="card-body">
            <Skeleton active={true} paragraph={false} />
            <Skeleton active={true} paragraph={false} />
            <Skeleton active={true} paragraph={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationCardSkeleton;
