import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewPresentationRule } from '@common-types';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';
import { ERROR_COUPON_STATUS, SuppliersEnum } from '@constants';
import { Urls } from '@share/constants';

export interface IAdminChangePresentationRuleState {
  changePresentationRule: INewPresentationRule;
  loading: boolean;
  error: string;
  isChangePresentationRule: boolean;
  errorText: string;
  isDuplicate: boolean;
  duplicatePresentationId: number;
}

const initialState: IAdminChangePresentationRuleState = {
  changePresentationRule: null,
  loading: false,
  error: '',
  isChangePresentationRule: false,
  errorText: '',
  isDuplicate: false,
  duplicatePresentationId: null,
};

const zeroItem = 0;
const RCI = 'Rci';
const PRIORITY = 200;

const adminChangePresentationRulesSlice = createSlice({
  name: 'changePresentationRules',
  initialState,
  reducers: {
    setLoadingChangePresentationRule: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = payload;
    },
    setErrorChangePresentationRule: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = payload;
    },
    setChangePresentationRule: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<INewPresentationRule>,
    ) => {
      state.changePresentationRule = payload;
    },
    setIsChangePresentationRule: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isChangePresentationRule = payload;
    },
    setErrorTextChangePresentationRule: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<string>,
    ) => {
      state.errorText = payload;
    },
    setIsPresentationRuleDuplicate: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isDuplicate = payload;
    },
    setDuplicateChangePresentationId: (
      state: IAdminChangePresentationRuleState,
      { payload }: PayloadAction<number>,
    ) => {
      state.duplicatePresentationId = payload;
    },
  },
});

export const {
  setLoadingChangePresentationRule,
  setIsChangePresentationRule,
  setErrorChangePresentationRule,
  setErrorTextChangePresentationRule,
  setChangePresentationRule,
  setIsPresentationRuleDuplicate,
  setDuplicateChangePresentationId,
} = adminChangePresentationRulesSlice.actions;

export const adminChangePresentationRulesReducer = adminChangePresentationRulesSlice.reducer;

export const changeSelectedPresentationRule = (
  presentationRule: INewPresentationRule,
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingChangePresentationRule(true));

    try {
      const parsedPresentationRule = {
        ruleId: presentationRule.id,
        siteId: presentationRule.siteId,
        description: presentationRule.name,
        supplierPriorities: [
          {
            supplierId:
              presentationRule.supplier === RCI ? SuppliersEnum.Rci : SuppliersEnum.Intervals,
            priority: PRIORITY,
          },
        ],
        activate: presentationRule.isActive,
      };

      const res = await axiosInstance.put(
        `${Urls.CondoPresentationRules}`,
        parsedPresentationRule,
        {
          ...getHeaders(),
        },
      );

      if (res.data.isDuplicate) {
        dispatch(setDuplicateChangePresentationId(res.data.existingRuleId));
        dispatch(setIsPresentationRuleDuplicate(true));
        dispatch(setLoadingChangePresentationRule(false));
        return;
      }
      dispatch(setIsChangePresentationRule(res.data.isSuccessful));
      dispatch(setLoadingChangePresentationRule(false));
    } catch (error) {
      console.error(error);
      dispatch(setErrorChangePresentationRule(error.toString()));
      dispatch(setLoadingChangePresentationRule(false));

      if (error?.response?.status === ERROR_COUPON_STATUS && error?.response?.data) {
        dispatch(setErrorTextChangePresentationRule(error?.response?.data[zeroItem].errorMessage));
      }
    }
  };
};

