import { FeatureData, NavItem } from "../types/types";

export const newsletterOptions = [
    { label: 'Daily', isChecked: true },
    { label: 'Twice a week', isChecked: false },
    { label: 'Weekly', isChecked: false },
    { label: 'Never', isChecked: false },
  ];

  export const personalDetailsFormInfo = [
    {
      name: "userId",
      label: "Account Id",
      placeholder: "Enter Account Id",
      isDisabled: true,
      rules: [{ required: true, message: "Account Id is required" }],
      
    },
    {
      name: "username",
      label: "Username",
      placeholder: "First name",
      isDisabled: false,
      rules: [{ required: true, message: "First name is required" }],
    },
    {
      name: "firstName",
      label: "First Name",
      placeholder: "First name",
      isDisabled: false,
      rules: [{ required: true, message: "First name is required" }],
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Last name",
      isDisabled: false,
      rules: [{ required: true, message: "Last name is required" }],
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Company email",
      isDisabled: false,
      rules: [
        {
          required: true,
          type: "email" as const,
          message: "Valid email is required",
        },
      ],
    },

  ];


  export const companyInfoForm = [
    { name: "accountId", label: "Account Id", required: true, inputType: "text",disabled: true,  placeholder: "Account Id", col: 6 },
    { name: "parentAccountId", label: "Parent Account Id", inputType: "text",disabled: true, placeholder: "Enter Parent Account Id", col: 6 },
    { name: "name", label: "Legal Business Name", required: true, inputType: "text", disabled: false, placeholder: "Legal name", col: 6 },
    { name: "email", label: "Email", required: true, inputType: "email", placeholder: "demo@gmail.com", col: 6 },
    // { name: "country", label: "Country", required: true, inputType: "select", placeholder: "Select Country", col: 4 },
    // { name: "state", label: "State", required: false, inputType: "select", placeholder: "Select State", col: 4 },
    { name: "baseAddressSite", label: "Base Address Site", required: true, inputType: "text", placeholder: "Share base address site url", col: 6 },
    { name: "property", label: "Property", required: true, inputType: "text", placeholder: "Enter Property", col: 6 }
  ];

  export const companyFeatures: FeatureData[] = [
    {
      iconClass: "bi bi-geo-alt",
      bgClass: "bg-success",
      title: "400,000+ Locations",
      description: "Our network of locations reaches destinations worldwide. If you need to be there, we can accommodate!"
    },
    {
      iconClass: "bi bi-gear-wide-connected",
      bgClass: "bg-primary",
      title: "Control Options",
      description: "Set rules for your team on where, when and how they can select hotels and destinations"
    },
    {
      iconClass: "bi bi-clipboard-data",
      bgClass: "bg-orange",
      title: "Stay Under Budget",
      description: "We know managing budgets can be challenging. Lean on our savings to make it easier!"
    },
    {
      iconClass: "bi bi-shield-check",
      bgClass: "bg-info",
      title: "Flexibility",
      description: "Plans change in the business world, get peace of mind by knowing we help you stay flexible."
    },
  ];


  export const registerData = [
    "24/7 customer support",
    "Flexible options",
    "Over 400,000 hotels"
  ]
  

  export const footerFeatures = [
    {
      icon: "headset",
      title: "24/7 Support",
      description: "Our support staff is standing by 24/7 to assist.",
    },
    {
      icon: "award",
      title: "Guarantee",
      description: "All refunds come with no questions asked guarantee.",
    },
  ];

  
  export const timeframeOptions = [
    "Year-to-date",
    "Month-to-date",
    "Last 365 days",
    "Last 90 days",
    "Last 30 days",
  ];

  export const statsData = [
    { iconClass: "bi bi-credit-card", value: "$1,534.25", label: "Amount Spent" },
    { iconClass: "bi bi-bank", value: "$872.48", label: "Savings" },
    { iconClass: "bi bi-calculator", value: "$95.88", label: "Average Nightly Rate" },
    { iconClass: "bi bi-calendar-check", value: "16", label: "Nights Booked" },
  ];

  // Define an array of saved cards
export const savedPaymentCardsData = [
  {
    brand: "Visa",
    number: "**** **** **** 1569",
    validThru: "12/26",
    cvv: "***"
  },
  {
    brand: "Mastercard",
    number: "**** **** **** 5620",
    validThru: "02/35",
    cvv: "***"
  }
];


export const creditSections = [
  {
    headerTitle: "Reports",
    rows: [
      { title: "Current Credit Total by Member", linkText: "" },
      { title: "Company Travel Credit History", linkText: "" },
      { title: "My Travel Credit History", linkText: "" },
    ],
  },
  {
    headerTitle: "More Info",
    rows: [
      { title: "Terms & Conditions", linkText: "" },
      { title: "How Credits Work", linkText: "" },
      { title: "Reallocating Travel Credits", linkText: "" },
    ],
  },
];


export const creditTransactions = [
  {
    date: "05-26-2022",
    description: "Booking: Hilton Garden Center, Orlando, FL",
    type: "Debit",
    amount: "$79.85",
    balance: "$70.15",
  },
  {
    date: "03-19-2022",
    description: "Promotion: Account sign-up bonus",
    type: "Credit",
    amount: "$150.00",
    balance: "$150.00",
  },
];

export const navItems = [
  { href: "company-settings", icon: "bi-building", label: "Company Info" },
  { href: "company-team", icon: "bi-people", label: "Team Members" }
];

export const menuItems = [
  { title: "Solutions", items: ["Travel Managers", "Operations Teams", "Finance Managers", "Corporate Travelers", "Corporate Groups"], id: "solutionsDropdown" },
  { title: "Features", items: ["Dashboard Control", "Reporting Tools", "Easy Invoicing", "Loyalty Rewards", "Trip Flex Pro"], id: "featuresDropdown" },
  { title: "Our Customers", items: ["Our Customers", "Testimonials", "24/7 Support", "Calculate Savings"], id: "customersDropdown" }
];


export const registerFormFields = [
  {
    label: "First name",
    name: "firstName",
    rules: [{ required: true, message: "Please input your first name!" }],
    placeholder: "Enter First Name",
  },
  {
    label: "Last name",
    name: "lastName",
    rules: [{ required: true, message: "Please input your last name!" }],
    placeholder: "Enter Last Name",
  },
  {
    label: "Company name",
    name: "companyName",
    rules: [{ required: true, message: "Please input your company name!" }],
    placeholder: "Enter Company Name",
  },
  {
    label: "Business email",
    name: "businessEmail",
    rules: [
      {
        required: true,
        message: "Please input your business email!",
        pattern: new RegExp("^\\S+@\\S+\\.\\S+$"),
      },
    ],
    placeholder: "Enter Business Email",
  },
  {
    label: "Business phone",
    name: "businessPhone",
    rules: [{ required: true, message: "Please input your business phone!" }],
    placeholder: "Enter Business Phone",
  },
  {
    label: "Estimated annual travel spend",
    name: "annualTravelSpend",
    rules: [
      {
        required: true,
        message: "Please select your estimated annual travel spend!",
      },
    ],
    placeholder: "Enter Annual Travel Spend",
  },
];


  export const contactInfo = [
    { label: "Sales:", phone: "(828) 785-4573" },
    { label: "Member Support:", phone: "(828) 785-4573" },
  ];

  export const businessSolutions = [
    "Travel Managers",
    "Operations Managers",
    "Finance Managers",
    "Travelers",
    "Groups",
    "Industries",
  ];

  export const platformFeatures = [
    "Dashboard Control",
    "Reporting Tools",
    "Easy Invoicing",
    "Loyalty Rewards",
    "Trip Flex",
  ];

  export const companyLinks = [
    "About Us",
    "Contact Us",
    "Testimonials",
    "Our Customers",
    "Travel Partners",
  ];

  export const helpResources = [
    "FAQ's",
    "Help Center",
    "Calculate Savings",
    "24/7 Support",
  ];

  export const footerSections = [
    { title: "Business Solutions", links: businessSolutions },
    { title: "Platform Features", links: platformFeatures },
    { title: "Our Company", links: companyLinks },
    { title: "Help & Resources", links: helpResources },
  ];

  export const socialIcons = [
  { icon: "facebook-f", color: "facebook", link: "#" },
  { icon: "instagram", color: "instagram", link: "#" },
  { icon: "twitter", color: "twitter", link: "#" },
  { icon: "linkedin-in", color: "linkedin", link: "#" },
];