import React from 'react';
import SideNav from '../../components/dashboard/side-nav/component';
import PaymentForm from './payment-form';
import SavedCards from './saved-payment-cards';

const PaymentDetails = () => (
  <section className="pt-3">
    <div className="container">
      <div className="row">
        <SideNav />
        <div className="col-lg-8 col-xl-9">
          <div className="card shadow-sm bg-transparent border">
            <div className="card-header bg-transparent border-bottom">
              <h4 className="card-header-title">
                <i className="bi bi-credit-card mx-2"></i>Payment Details
              </h4>
            </div>

            <div className="card-body p-2 p-sm-4">
              <h5>Saved Card(s)</h5>
              <SavedCards />
              <PaymentForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export { PaymentDetails };
