import React from 'react';

export const CloseCircleSvg: React.FunctionComponent = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40 5C20.6719 5 5 20.6719 5 40C5 59.3281 20.6719 75 40 75C59.3281 75 75 59.3281 75 40C75 20.6719 59.3281 5 40 5ZM52.9219 53.2969L47.7656 53.2734L40 44.0156L32.2422 53.2656L27.0781 53.2891C26.7344 53.2891 26.4531 53.0156 26.4531 52.6641C26.4531 52.5156 26.5078 52.375 26.6016 52.2578L36.7656 40.1484L26.6016 28.0469C26.5072 27.9324 26.4548 27.789 26.4531 27.6406C26.4531 27.2969 26.7344 27.0156 27.0781 27.0156L32.2422 27.0391L40 36.2969L47.7578 27.0469L52.9141 27.0234C53.2578 27.0234 53.5391 27.2969 53.5391 27.6484C53.5391 27.7969 53.4844 27.9375 53.3906 28.0547L43.2422 40.1562L53.3984 52.2656C53.4922 52.3828 53.5469 52.5234 53.5469 52.6719C53.5469 53.0156 53.2656 53.2969 52.9219 53.2969Z"
      fill="#FF4D4F"
    />
  </svg>
);
