import { isEmpty } from "lodash";

export const USERNAME_FIELD_NAME = 'username';
export const FIRSTNAME_FIELD_NAME = 'firstname';
export const LASTNAME_FIELD_NAME = 'lastname';
export const COUNTRY_FIELD_NAME = 'countryCode';
export const EMAIL_FIELD_NAME = 'email';

export const USERNAME_VALIDATION_FIELD_NAME = 'usernameValid';
export const EMAIL_VALIDATION_FIELD_NAME = 'emailValid';
export const COUNTRY_VALIDATION_FIELD_NAME = 'countrryValid';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const SPECIAL_CHARACTERS_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const WHITESPACE_REGEX = /\s/;

const validateEmpty = (value: string) => !isEmpty(value);
const validateWhiteSpaces = (value: string) => validateEmpty(value) && !WHITESPACE_REGEX.test(String(value));
const validateSpecialCharacters = (value: string) => validateEmpty(value) && !SPECIAL_CHARACTERS_REGEX.test(String(value));
const validateBasic = (value: string) => validateSpecialCharacters(value) && validateWhiteSpaces(value);
const validateEmail = (value: string) => validateWhiteSpaces(value) && EMAIL_REGEX.test(String(value));

export interface IValidation {
  valid: boolean;
  touched: boolean;
};

export interface IFormValidations {
  [USERNAME_VALIDATION_FIELD_NAME]: IValidation;
  [EMAIL_VALIDATION_FIELD_NAME]: IValidation;
  [COUNTRY_VALIDATION_FIELD_NAME]: IValidation;
};

export interface IFormValidation {
  field: string;
  validation?: (value: string) => boolean;
};

export interface IFormFieldsValidation {
  [USERNAME_FIELD_NAME]: IFormValidation;
  [EMAIL_FIELD_NAME]: IFormValidation;
  [COUNTRY_FIELD_NAME]: IFormValidation;
};

export const FORM_FIELDS_VALIDATIONS: IFormFieldsValidation = {
  [USERNAME_FIELD_NAME]: {
    field: USERNAME_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateBasic(value)
  },
  [EMAIL_FIELD_NAME]: {
    field: EMAIL_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateEmail(value)
  },
  [COUNTRY_FIELD_NAME]: {
    field: COUNTRY_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateBasic(value)
  }
};

export const IsFormValid = (form: IFormValidations) => {
  return form[USERNAME_VALIDATION_FIELD_NAME].valid &&
          form[EMAIL_VALIDATION_FIELD_NAME].valid &&
          form[COUNTRY_VALIDATION_FIELD_NAME].valid;
}

export const IsFormTouched = (form: IFormValidations) => {
  return form[USERNAME_VALIDATION_FIELD_NAME].touched &&
        form[EMAIL_VALIDATION_FIELD_NAME].touched &&
        form[COUNTRY_VALIDATION_FIELD_NAME].touched;
}

export const IsFormFieldValid = (form: IValidation) => {
  return !form.touched || form.valid;
}
