import React, { useState } from "react";

const GuestCard = ({
  searchHotels,
  bookingInfo,
  handleInputChange,
  handleBlur,
  inputRefs,
  errors,
  hotels,
  mainGuest,
  updateMainGuest,
}) => {
  const rooms = hotels?.bookingCard?.package?.rooms;
  const countries = [
    { code: "AO +244", name: "Angola" },
    { code: "AI +1242", name: "Anguilla" },
    { code: "AQ", name: "Antarctica" },
    { code: "AG +1268", name: "Antigua and Barbuda" },
    { code: "AM +374", name: "Armenia" },
    { code: "AW +297", name: "Aruba" },
    { code: "AU +61", name: "Australia" },
    { code: "AT +43", name: "Austria" },
    { code: "AZ +994", name: "Azerbaijan" },
    { code: "BS +1242", name: "Bahamas" },
    { code: "BH +973", name: "Bahrain" },
    { code: "BD +880", name: "Bangladesh" },
  ];

  const getOverallGuestIndex = (roomIndex, guestIndex) => {
    return (
      rooms
        .slice(0, roomIndex)
        .reduce(
          (acc, room) => acc + room.adultsCount + room.kidsAges.length,
          0
        ) + guestIndex
    );
  };

  const [collapsedState, setCollapsedState] = useState(
    rooms?.map(() => false) || []
  );

  const handleToggleCollapse = (index) => {
    setCollapsedState((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const setRef = (roomIndex, guestIndex, fieldName, ref) => {
    if (!inputRefs.current[roomIndex]) {
      inputRefs.current[roomIndex] = {};
    }
    if (!inputRefs.current[roomIndex][guestIndex]) {
      inputRefs.current[roomIndex][guestIndex] = {};
    }
    inputRefs.current[roomIndex][guestIndex][fieldName] = ref;
  };

  if (!rooms?.length) {
    return <></>; // Or any loading state
  }

  return (
    <div className="card shadow">
      <div className="card-header border-bottom p-4">
        <h4 className="card-title mb-0">
          <i className="bi bi-people me-2"></i>Guest Details
        </h4>
      </div>
      {rooms?.map((room, roomIndex) => (
        <div className="card-body p-4" key={roomIndex}>
          <form className="row g-4">
            <div className="">
              <p className="card-title">Room: {roomIndex + 1}</p>
              <p className="form-label">
                {room.adultsCount} Adults, {room.roomBasis}
              </p>
            </div>
            {Array.from({
              length: room.adultsCount + room.kidsAges.length,
            }).map((_, guestIndex) => {
              const overallGuestIndex = getOverallGuestIndex(
                roomIndex,
                guestIndex
              );
              const isKid = guestIndex >= room.adultsCount;
              const isFirstGuest = guestIndex === 0;

              return (
                <React.Fragment key={overallGuestIndex}>
                  {isFirstGuest && (
                    <div className="col-12">
                      <div className="bg-light rounded-2 px-4 py-3 d-flex">
                        <input
                          type="checkbox"
                          className="me-2"
                          checked={mainGuest.roomIndex === roomIndex}
                          onChange={() => updateMainGuest(roomIndex)}
                        />
                        <h6 className="mb-0">This is the Main Guest</h6>
                      </div>
                    </div>
                  )}
                  {isFirstGuest || collapsedState[roomIndex] ? (
                    <>
                      <div className="col-12">
                        <div className="bg-light rounded-2 px-4 py-3">
                          <h6 className="mb-0">{`Guest ${guestIndex + 1} ${
                            isFirstGuest ? "(Mandatory)" : "(Optional)"
                          } ${
                            isKid
                              ? `(Kid, Age: ${
                                  room.kidsAges[guestIndex - room.adultsCount]
                                })`
                              : ""
                          }`}</h6>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-size-lg">
                          <label className="form-label">Title</label>
                          <select
                            className="form-select js-choice"
                            name="namePrefix"
                            value={
                              bookingInfo.guests[overallGuestIndex]
                                ?.namePrefix || "Mr"
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                overallGuestIndex,
                                roomIndex,
                                guestIndex
                              )
                            }
                            onBlur={(e) =>
                              handleBlur(
                                e,
                                overallGuestIndex,
                                roomIndex,
                                guestIndex
                              )
                            }
                            ref={(el) =>
                              setRef(roomIndex, guestIndex, "namePrefix", el)
                            }
                          >
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          className={`form-control form-control-lg ${
                            errors[roomIndex]?.[0]?.givenName &&
                            guestIndex === 0
                              ? "danger-border"
                              : ""
                          }`}
                          placeholder="First Name"
                          name="givenName"
                          value={
                            bookingInfo.guests[overallGuestIndex]?.givenName ||
                            ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              overallGuestIndex,
                              roomIndex,
                              guestIndex
                            )
                          }
                          onBlur={(e) =>
                            handleBlur(
                              e,
                              overallGuestIndex,
                              roomIndex,
                              guestIndex
                            )
                          }
                          ref={(el) =>
                            setRef(roomIndex, guestIndex, "givenName", el)
                          }
                        />
                        {errors[roomIndex]?.[0]?.givenName &&
                          guestIndex === 0 && (
                            <div className="text-danger">
                              {errors[roomIndex][0].givenName}
                            </div>
                          )}
                      </div>
                      <div className="col-md-5">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className={`form-control form-control-lg ${
                            errors[roomIndex]?.[0]?.surname && guestIndex === 0
                              ? "danger-border"
                              : ""
                          }`}
                          placeholder="Last Name"
                          name="surname"
                          value={
                            bookingInfo.guests[overallGuestIndex]?.surname || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              overallGuestIndex,
                              roomIndex,
                              guestIndex
                            )
                          }
                          onBlur={(e) =>
                            handleBlur(
                              e,
                              overallGuestIndex,
                              roomIndex,
                              guestIndex
                            )
                          }
                          ref={(el) =>
                            setRef(roomIndex, guestIndex, "surname", el)
                          }
                        />
                        {errors[roomIndex]?.[0]?.surname &&
                          guestIndex === 0 && (
                            <div className="text-danger">
                              {errors[roomIndex][0].surname}
                            </div>
                          )}
                      </div>
                      {mainGuest.roomIndex === roomIndex &&
                        guestIndex === 0 && (
                          <>
                            <div className="col-md-6">
                              <label className="form-label">Email id</label>
                              <input
                                type="email"
                                className={`form-control form-control-lg ${
                                  errors[roomIndex]?.[0]?.email &&
                                  guestIndex === 0
                                    ? "danger-border"
                                    : ""
                                }`}
                                placeholder="Enter your email"
                                name="email"
                                value={
                                  bookingInfo.guests[overallGuestIndex]
                                    ?.email || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    overallGuestIndex,
                                    roomIndex,
                                    guestIndex
                                  )
                                }
                                onBlur={(e) =>
                                  handleBlur(
                                    e,
                                    overallGuestIndex,
                                    roomIndex,
                                    guestIndex
                                  )
                                }
                                ref={(el) =>
                                  setRef(roomIndex, guestIndex, "email", el)
                                }
                              />
                              <div id="emailHelp" className="form-text">
                                (Booking voucher will be sent to this email ID)
                              </div>
                              {errors[roomIndex]?.[0]?.email &&
                                guestIndex === 0 && (
                                  <div className="text-danger">
                                    {errors[roomIndex][0].email}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Mobile number
                              </label>
                              <div className="input-group">
                                <select
                                  className="js-choice country-select"
                                  name="countryCode"
                                  value={
                                    bookingInfo.guests[overallGuestIndex]
                                      ?.countryCode || ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      overallGuestIndex,
                                      roomIndex,
                                      guestIndex
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleBlur(
                                      e,
                                      overallGuestIndex,
                                      roomIndex,
                                      guestIndex
                                    )
                                  }
                                  ref={(el) =>
                                    setRef(
                                      roomIndex,
                                      guestIndex,
                                      "countryCode",
                                      el
                                    )
                                  }
                                  defaultValue=""
                                >
                                  {countries?.map((country, i) => (
                                    <option key={i} value={country.code}>
                                      {country.code}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="text"
                                  className={`form-control form-control-lg ${
                                    errors[roomIndex]?.[0]?.phone &&
                                    guestIndex === 0
                                      ? "danger-border"
                                      : ""
                                  }`}
                                  placeholder="Enter your mobile number"
                                  name="phone"
                                  maxLength={12}
                                  value={
                                    bookingInfo.guests[overallGuestIndex]
                                      ?.phone || ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      overallGuestIndex,
                                      roomIndex,
                                      guestIndex
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleBlur(
                                      e,
                                      overallGuestIndex,
                                      roomIndex,
                                      guestIndex
                                    )
                                  }
                                  ref={(el) =>
                                    setRef(roomIndex, guestIndex, "phone", el)
                                  }
                                />
                              </div>
                              {errors[roomIndex]?.[0]?.phone &&
                                guestIndex === 0 && (
                                  <div className="text-danger">
                                    {errors[roomIndex][0].phone}
                                  </div>
                                )}
                            </div>
                            <p className="text-danger fs-6">
                              The first and last name that you enter as the main
                              guest MUST be present upon check-in with a valid
                              photo ID that matches the name you've entered when
                              making the reservation. Name changes are strictly
                              prohibited anytime after your booking is
                              confirmed.
                            </p>
                          </>
                        )}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
            {room.adultsCount + room.kidsAges.length > 1 && (
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleToggleCollapse(roomIndex)}
                >
                  {!collapsedState[roomIndex]
                    ? "Add all guests for this room"
                    : "Only add main guest for this room"}
                </button>
              </div>
            )}
          </form>
        </div>
      ))}
    </div>
  );
};

export default GuestCard;
