import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { LUCKY_POINT_TRANSPARENT } from '@constants';
import BackgroundImage from '@assets/images/master-classes.jpg';
import './style.scss';

export class MasterClassesRegisterHeading extends Component {
  render(): React.ReactNode {
    return (
      <div
        className="master-classes-register-heading"
        style={{
          background: `url(${BackgroundImage}) 50% 26% / cover no-repeat ${LUCKY_POINT_TRANSPARENT}`,
        }}
      >
        <div className="master-classes-register-heading__wrapper">
          <h1 className="master-classes-register-heading__heading">
            <FormattedMessage id="master.classes" />
          </h1>
        </div>
      </div>
    );
  }
}
