import React, { useState, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { marginatorActions, IMarginatorState, ILoginState, Impersonate, ErrorType } from '@share/store/slices';
import { Input, Collapse, Select, Spin } from 'antd';
import { RootState } from '@share/utils';
import { BlueButton, Loading, LoadingSise } from '@share/components';
import { isEmpty } from 'lodash';
import './style.scss';

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setMemberId: (value: number) => void;
  setStoreType: (value: string) => void;
  login: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps { }

function TravelProMemberComponent(props: IProps) {
  const { Panel } = Collapse;
  const { error, account, loading, loadingImpersonate } = props.loginStore;

  const [show, setShow] = useState(account?.isTravelAgentPro || account?.forceImpersonate);
  const [member, setMember] = useState('');
  const [storeType, setStoreType] = useState('');

  useEffect(() => {
    setShow(account?.isTravelAgentPro || account?.forceImpersonate);
  }, [])

  useEffect(() => {
    setShow(account?.isTravelAgentPro || account?.forceImpersonate);
  }, [loading])

  const handleChange = (e: { target: HTMLInputElement }): void => {
    setMember(e.target.value);
  }

  const handleStoreChange = (value: string): void => {
    setStoreType(value);
  }

  const handleApply = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    props.setMemberId(Number(member));
    props.setStoreType(storeType);
    if (!isEmpty(member) && !isEmpty(storeType)) {
      props.login();
    }
  }

  return (
    <>
      {show && (
        <Collapse className="impersonate-container" defaultActiveKey={account?.forceImpersonate ? ['1'] : null} ghost expandIconPosition='right'>
          <Panel header="Impersonate Member" key="1">
            <div className="member-code__wrapper mt-2 member-code__form">
              {account?.isCapitalVacations ? (
                <Select style={{padding: '0 11px', width: '100%'}}
                  placeholder={'Enter store type'}
                  value={storeType}
                  onChange={(value) => handleStoreChange(value)}
                >
                  <Select.Option value={'value'}>Value</Select.Option>
                  <Select.Option value={'choice'}>Choice</Select.Option>
                  <Select.Option value={'unlimited'}>Unlimited</Select.Option>
                </Select>) : null}
              <Input
                className={`member-code__input`}
                placeholder="Enter member id"
                type={'number'}
                onChange={handleChange}
                value={member}
              />
              <BlueButton disabled={isEmpty(member) || isEmpty(storeType) || loadingImpersonate} onClick={(e) => { handleApply(e) }}>
                {loadingImpersonate ? (<Spin />) : (<FormattedMessage id="apply" />)}
              </BlueButton>
            </div>
            {error?.type === ErrorType.ImpersonatorInvalidMemberId && <p className='error'>{error.error}</p>}
          </Panel>
        </Collapse>
      )}
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setMemberId: (value: number) => {
    dispatch(marginatorActions.setMemberId(value));
  },
  setStoreType: (value: string) => {
    dispatch(marginatorActions.setStoreType(value));
  },
  login: () => {
    dispatch(Impersonate(false));
  },
});

export const Impersonator = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TravelProMemberComponent));
