export const BASE_URL = {
  API_BASE_URL_4000: "https://qa2-api.travcoding.com:4000",
  API_BASE_URL: "https://qa2-api.travcoding.com",
};

export enum API_END_POINT {
  FETCH_COUNTRIES = "/condos/countries",
  FETCH_USER_ACCOUNT = "/identity/account/",
  SAVE_USER_ACCOUNT = "/identity/account/save",
  GET_TEAM_MEMBERS = "/identity/users",
  GET_USER_ROLES = "/identity/roles",
  ADD_UPDATE_USER = "/identity/users/save",
  LOGIN_USER = "/identity/users/sign-in",
  REGISTER_COMPANY="/identity/account/save",
  HOTELS_SEARCH ="/Hotels/search/page"
}

