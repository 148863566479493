import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TutorialVideosImg from '@assets/images/tutorial-videos.png';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import './style.scss';
import { ILoginState } from '@share/store/slices';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends RouteComponentProps, IMapStateToProps {}

class TutorialVideosComponent extends React.Component<IProps, null> {
  goToVideos = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    this.props.history.push(`/${account?.name}${Routes.MemberVideos}`);
  };

  render(): React.ReactNode {
    return (
      <div className="tutorial-videos">
        <div className="tutorial-videos__wrapper">
          <div className="tutorial-videos__info-wrapper">
            <p className="tutorial-videos__title">
              <FormattedMessage id="member.tutorial.videos" />
            </p>
            <p className="tutorial-videos__description">
              <FormattedMessage id="get.the.most.out.of.your.membership" />
            </p>
            <div className="tutorial-videos__btn-wrapper">
              <BlueButton onClick={this.goToVideos}>
                <FormattedMessage id="watch.videos" />
              </BlueButton>
            </div>
          </div>
          <div className="tutorial-videos__img-wrapper">
            <img src={TutorialVideosImg} alt="watch.videos" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const TutorialVideosWithRouter = withRouter(TutorialVideosComponent);

export const TutorialVideos = connect(mapStateToProps)(TutorialVideosWithRouter);
