import React from 'react';
import { Action } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';

import { RootState } from '@share/utils';
import { IAdminBookingDetailsState, retryCapturedCondoBooking, retryVoidCondoBooking } from '@store/slices';
import { DATE_FORMAT_COMBINATION, STATUS_SUCCEEDED, DATE_FORMAT_FOR_PAYMENT_DETAILS, STATUS_FAILED } from '@constants';
import { getAuthorizeTransaction, getLastTransaction } from '@utils';
import { formatMoney } from '@share/utils';
import { BookingStatusEnum, BookingSubStatusEnum } from '@common-types';
import { BlueButton } from '@share/components';

import './style.scss';

interface IMapStateToProps {
  adminBookingDetailsStore: IAdminBookingDetailsState;
}

interface IMapDispatchToProps {
  retryVoidCondoBooking: (id: number) => void;
  retryCapturedCondoBooking: (id: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

const ONE = 1;

class BookingPaymentDetailComponent extends React.Component<IProps> {
  retryVoidCondoBooking = (): void => {
    const { details } = this.props.adminBookingDetailsStore;
    this.props.retryVoidCondoBooking(details.orderId);
  };

  retryCapturedCondoBooking = (): void => {
    const { details } = this.props.adminBookingDetailsStore;
    this.props.retryCapturedCondoBooking(details.orderId);
  };

  getHeader = (): React.ReactNode => {
    const { details } = this.props.adminBookingDetailsStore;
    const { transactionsLog } = details;

    return (
      <div className="booking-payment-detail__header">
        <div className="booking-payment-detail__header-text">
          <FormattedMessage id="authorization.code" />:{' '}
          <span className="bold-text">
            {getLastTransaction(getAuthorizeTransaction(details)).authCode}
          </span>
        </div>
        <div className="booking-payment-detail__header-date">
          {format(
            new Date(getLastTransaction(transactionsLog).transactionDate),
            DATE_FORMAT_FOR_PAYMENT_DETAILS,
          )}
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    const { Panel } = Collapse;
    const { details, retryVoidLoading, retryCapturedLoading } = this.props.adminBookingDetailsStore;
    const { cardDetails, bookingPrice, transactionsLog } = details;
    const isDraft = details && details.status === BookingStatusEnum.Draft;
    const isFailed = details && details.status === BookingStatusEnum.Failed;
    const isSubStatusCaptureUnknown =
      details &&
      details.subStatuses.some((subStatus) => subStatus.status === BookingSubStatusEnum.Unknown);
    const isSubStatusAuthorizeFailed =
      details &&
      details.subStatuses.some(
        (subStatus) => subStatus.stage === BookingSubStatusEnum.AuthorizeFailed,
      );

    return !isEmpty(details.transactionsLog) ? (
      <div className="booking-payment-detail">
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="booking-payment-detail__card"
        >
          <Panel header={this.getHeader()} key="1">
            <div className="booking-payment-detail__main">
              <div className="booking-payment-detail__column">
                <div className="booking-payment-detail__block">
                  <div className="booking-payment-detail__small-header">
                    <FormattedMessage id="payment.info" />
                  </div>
                  <div className="booking-payment-detail__log-column-wrapper">
                    {bookingPrice && (
                      <div className="booking-payment-detail__row">
                        <div className="booking-payment-detail__label">
                          <FormattedMessage id="amount" />:
                        </div>
                        <div className="booking-payment-detail__value">
                          {formatMoney.format(bookingPrice?.payNowPrice)}
                        </div>
                      </div>
                    )}
                    <div className="booking-payment-detail__row">
                      <div className="booking-payment-detail__label">
                        <FormattedMessage id="purchase.id" />:
                      </div>
                      <div className="booking-payment-detail__value">
                        {getLastTransaction(getAuthorizeTransaction(details)).pgId}
                      </div>
                    </div>
                    <div className="booking-payment-detail__gateway">
                      <FormattedMessage id="qualpay" />
                    </div>
                  </div>
                </div>
                <div className="booking-payment-detail__block">
                  <div className="booking-payment-detail__small-header">
                    <FormattedMessage id="card.details" />
                  </div>
                  <div className="booking-payment-detail__log-column-wrapper">
                    <div className="booking-payment-detail__row">
                      <div className="booking-payment-detail__label">
                        <FormattedMessage id="cardholder.name" />:
                      </div>
                      <div className="booking-payment-detail__value">
                        {cardDetails.cardHolderName}
                      </div>
                    </div>
                    <div className="booking-payment-detail__row">
                      <div className="booking-payment-detail__label">
                        <FormattedMessage id="card.number" />:
                      </div>
                      <div className="booking-payment-detail__value">{cardDetails.cardNumber}</div>
                    </div>
                    <div className="booking-payment-detail__gateway">{cardDetails.cardType}</div>
                  </div>
                </div>
              </div>
              <div className="booking-payment-detail__logs">
                <div className="booking-payment-detail__small-header">
                  <FormattedMessage id="transaction.logs" />
                </div>
                <div className="booking-payment-detail__logs-info">
                  <div className="booking-payment-detail__logs-column">
                    <div className="booking-payment-detail__logs-header">
                      <FormattedMessage id="date" />
                    </div>
                    {transactionsLog.map((transaction, index) => (
                      <div key={index} className="booking-payment-detail__logs-value mb">
                        {format(
                          new Date(transaction.transactionDate),
                          DATE_FORMAT_FOR_PAYMENT_DETAILS,
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="booking-payment-detail__logs-column">
                    <div className="booking-payment-detail__logs-header">
                      <FormattedMessage id="payment.gateway.id" />
                    </div>
                    {transactionsLog.map((transaction, index) => (
                      <div key={index} className="booking-payment-detail__logs-value mb">
                        {transaction.pgId}
                      </div>
                    ))}
                  </div>
                  <div className="booking-payment-detail__logs-column">
                    <div className="booking-payment-detail__logs-header">
                      <FormattedMessage id="status" />
                    </div>
                    {transactionsLog.map((transaction, index) => (
                      <div
                        key={index}
                        className={`booking-payment-detail__logs-value mb ${
                          transaction.completionStatus === STATUS_SUCCEEDED ? 'succeeded' : 'failed'
                        }`}
                      >
                        {transaction.status}
                        {isDraft &&
                          transaction.completionStatus === STATUS_FAILED &&
                          !isSubStatusAuthorizeFailed &&
                          index === transactionsLog.length - ONE && (
                            <BlueButton
                              onClick={this.retryVoidCondoBooking}
                              loading={retryVoidLoading}
                            >
                              <FormattedMessage id="void" />
                            </BlueButton>
                          )}
                        {isFailed &&
                          transaction.completionStatus === STATUS_FAILED &&
                          !isSubStatusCaptureUnknown &&
                          index === transactionsLog.length - ONE && (
                            <BlueButton
                              onClick={this.retryCapturedCondoBooking}
                              loading={retryCapturedLoading}
                            >
                              <FormattedMessage id="retry" />
                            </BlueButton>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    ) : (
      <div className="booking-payment-detail__not-transactions">
        <p className="booking-payment-detail__not-transactions-text">
          <FormattedMessage id="not.transactions" />
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminBookingDetailsStore: state.adminBookingDetailsStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  retryVoidCondoBooking: (id: number) => {
    dispatch(retryVoidCondoBooking(id));
  },
  retryCapturedCondoBooking: (id: number) => {
    dispatch(retryCapturedCondoBooking(id));
  },
});

export const BookingPaymentDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingPaymentDetailComponent);

