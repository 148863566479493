import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { RootState } from '@share/utils';
import { getUsers, getSelectedAccountDetails, IAccountsState } from '@store/slices';

import { AccountDetails, AllAccountUsers, UserDetails } from '..';
import { Tabs } from 'antd';
import { AccountInformationTabsEnum } from '@common-types';

import './style.scss';

interface IMapStateToProps {
  accountsStore: IAccountsState;
}

interface IMapDispatchToProps {
  getUsers: (accountId: number) => void;
  getSelectedAccountDetails: (accountId: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {}

interface IState {
  displaySearch: boolean;
  userId?: string;
}

class AccountInformationComponent extends React.Component<IProps, IState> {

  state: IState = { displaySearch: true };

  renderSearch = () => {
    if (this.state.displaySearch) {
      return <AllAccountUsers onUserSelected={this.handleDisplay} />
    }
    return null;
  }

  renderUserDetail = () => {
    if (!this.state.displaySearch) {
      const account = this.props.accountsStore.selectedAccount;
      return <UserDetails accountId={account.id} userId={this.state.userId} onClose={this.handleDisplay} />
    }
    return null;
  }

  handleDisplay = (userId?: string) => {
    this.setState({ displaySearch: !this.state.displaySearch, userId });
  }

  handleTabChange = (tab: string): void => {
    if (tab === AccountInformationTabsEnum.AccountUsers) {
      this.setState({ displaySearch: true });
    } else {
      this.setState({ displaySearch: false });
    }
  };

  render(): React.ReactNode {
    return (
      <div className="account-information">
        <div className="account-information__wrapper">
          <div className="account-information__header">
            <div className="account-information__tabs">
              <Tabs
                   onChange={this.handleTabChange}
                  defaultActiveKey={AccountInformationTabsEnum.AccountDetails}>
                <Tabs.TabPane
                  tab={<FormattedMessage id="account.details.title" />}
                  key={AccountInformationTabsEnum.AccountDetails}
                >
                  <AccountDetails />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={<FormattedMessage id="account.users.title" />}
                  key={AccountInformationTabsEnum.AccountUsers}
                >
                  {this.renderSearch()}
                  {this.renderUserDetail()}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    accountsStore: state.accountsStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getUsers,
  getSelectedAccountDetails
};

const AccountInformationComponentWithRouter = withRouter(injectIntl(AccountInformationComponent));

export const AccountInformation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountInformationComponentWithRouter);
