import React from 'react';
import ReactGA from 'react-ga4';
import debounce from 'lodash/debounce';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { Button, Radio } from 'antd';
import { get, isEmpty } from 'lodash';
import { HasPermission, ViewQuotesSharePermission } from '@utils';

import {
  EmptyFilterMessage,
  EmptySearchMessage,
  FilterCheckbox,
  FilterStars,
  SearchPropertyName,
  SkeletonContent,
  SkeletonFilters,
  WhiteButton,
  SkeletonMap,
  ExpiredSessionModal,
  Banner,
  Currency,
  WalletSearchSlider,
} from '@components';
import { BlueButton, CustomErrorPage, CustomSelect } from '@share/components';
import {
  filtersActions,
  resetHotels,
  resetHotelsFull,
  getHotels,
  getSimilarHotels,
  getMapHotels,
  hotelsActions,
  IDatesState,
  IFiltersState,
  IHotelsState,
  ILocationsState,
  IRoomsState,
  locationActions,
  ILoginState,
} from '@share/store/slices';
import { CloseBlueSvg, FilterSvg, MapSvg, MapSearchSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { getAccountUsernameFromPath, RootState, getSelectedCurrency, VACATIONS_PAGE, GetHomeParams } from '@share/utils';
import {
  IBounds,
  IClientCash,
  ICounterItem,
  IHotel,
  ILocation,
  IRange,
  IRoom,
  LocationsTypes,
  SEARCH_BANNER_TYPE,
} from '@share/common-types';
import MapImage from '@assets/images/map.png';
import MapMonoImage from '@assets/images/map_mono.png';
import {
  APP_SELECTOR,
  TOP_AMENITIES_ICONS,
} from '@constants';
import {
  DEFAULT_PAGE_SIZE,
  FILTERS_LABEL,
  getPropertyTypeCodeByValue,
  getRefundabilityCodeByValue,
  getSortByCodeByValue,
  HomesTypes,
  MAIN_BLUE_COLOR,
  Routes,
  R_FILTER_BUDGET,
  R_FILTER_CONDO,
  R_FILTER_NAME,
  R_FILTER_STARS,
  R_VIEW_MAP,
  SortTypes,
} from '@share/constants';
import {
  getAmenities,
  isFiltersChanged,
  isFiltersEmpty,
  Map,
  getSortOptions
} from '@utils';
import {
  Responsive,
  UrlUtils,
  isDataValid,
  getHomesOptions
} from '@share/utils';
import { HotelCardComponent } from '../hotel-card';
import { MapWrapper } from '../../map';
import { ModalCompare } from './modal-compare';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSliders } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  datesStore: IDatesState;
  locationsStore: ILocationsState;
  roomsStore: IRoomsState;
  filtersStore: IFiltersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setPageNumber: () => void;
  getHotels: (
    location: ILocation,
    isLoadMore: boolean,
    isFiltersChange: boolean,
  ) => void;
  getSimilarHotels: (isFiltersChange: boolean, isLoadMore: boolean) => void;
  resetHotels: () => void;
  resetHotelsFull: () => void;
  setBudget: (value: string[]) => void;
  setMapLoading: (isMapLoading: boolean) => void;
  setAmenities: (value: string[]) => void;
  setAccommodationType: (value: string[]) => void;
  setAccessibility: (value: string[]) => void;
  setPopularLocations: (value: string[]) => void;
  setBoardName: (value: string[]) => void;
  setRefundable: (value: string[]) => void;
  setFlexibleDate: (value: string[]) => void;
  setNeighbourhoods: (value: string[]) => void;
  setPlaces: (value: string[]) => void;
  setStarRating: (value: number[]) => void;
  setDistance: (value: number) => void;
  setSortBy: (value: string) => void;
  setSelectedCompareHotels: (selectedCompareHotels: IHotel[]) => void;
  resetFilters: () => void;
  getMapHotels: (
    location: ILocation,
    startDate: string,
    endDate: string,
    rooms: IRoom[],
    hotelId?: number,
  ) => void;
  setIsMapMessageShown: (shown: boolean) => void;
  validateLocation: (error: string) => void;
  setBounds: (bounds: IBounds) => void;
  setIsMapView: (isMapView: boolean) => void;
  setSearchHomes: (value: string) => void;
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
  label: string;
  isVacationRentals: boolean;
  matches?: boolean;
}

interface IState {
  isFullAmenities: boolean;
  isFullNeighbourhoods: boolean;
  isFullAccessibilities: boolean;
  isFullPopularLocations: boolean;
  isFullAccommodationTypes: boolean;
  isFullMealPlans: boolean;
  isMobileFilter: boolean;
  isMapView: boolean;
  showFilters: boolean;
  selectedHotel: IHotel;
  showCompareModal: boolean;
}

const debounceTime = 300;
const scrollPixels = 450;
const zero = 0;
const maxCompareHotels = 5;
const maxAmenitiesCount = 6;
const plainOptionsStar = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];

class ResultWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    isFullAmenities: false,
    isFullNeighbourhoods: false,
    isFullAccessibilities: false,
    isFullPopularLocations: false,
    isFullAccommodationTypes: false,
    isFullMealPlans: false,
    isMobileFilter: false,
    isMapView: false,
    showFilters: true,
    selectedHotel: null,
    showCompareModal: false
  };
  root: HTMLDivElement = document.querySelector(APP_SELECTOR);
  prevFilters: IFiltersState = this.props.filtersStore;
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  filterButtonWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  checkIsLocationValid = (): boolean => {
    const { datesStore, locationsStore, validateLocation, intl } = this.props;
    const { isLocationValid } = isDataValid(locationsStore, datesStore);

    if (!isLocationValid) {
      validateLocation(intl.formatMessage({ id: 'location.error' }));
      return false;
    }

    return true;
  };

  onScroll = debounce(() => {
    const {
      setPageNumber,
      getHotels,
      getSimilarHotels,
      locationsStore,
      hotelsStore,
    } = this.props;
    const { loadingMore, hotels, similarHotels, prevHotels, isSessionExpired, lastSearchedLocation } = hotelsStore;
    const { selectedLocation, location } = locationsStore;

    if (!lastSearchedLocation && !selectedLocation) {
      this.checkIsLocationValid();
    }

    const rootHeight = this.root.scrollHeight ? this.root.scrollHeight : this.root.offsetHeight;
    if (!this.state.isMapView &&
        !isSessionExpired &&
        !loadingMore &&
        this.root &&
        window.innerHeight + window.scrollY + scrollPixels >= rootHeight
    ) {
      setPageNumber();

      if (hotels &&
          hotels.length &&
          hotels.length % DEFAULT_PAGE_SIZE === zero &&
          prevHotels.length) {
        getHotels(
          lastSearchedLocation,
          true,
          false,
        );
      } else if (location?.type === 'Hotel' &&
                 similarHotels &&
                 similarHotels.length &&
                 similarHotels.length % DEFAULT_PAGE_SIZE === zero) {
        getSimilarHotels(false, true);
      }
    }
  }, debounceTime);

  componentDidMount() {
    window.history.pushState(null, null, window.location.pathname + window.location.search);
    window.addEventListener('popstate', this.onClickBackButton);
    window.addEventListener('scroll', this.onScroll); 

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('popstate', this.onClickBackButton);

    document.removeEventListener('mousedown', this.handleClickOutside);

    const footer: HTMLDivElement = document.querySelector('.footer');

    if (footer) {
      document.body.style.overflow = 'auto';
      footer.style.display = 'block';
    }
  }

  handleClickOutside = (event: any) => {
    if (this.state.isMapView && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) &&
        this.filterButtonWrapperRef.current && !this.filterButtonWrapperRef.current.contains(event.target)) {
      this.setState({ showFilters: false });
    }
  }

  onClickBackButton = (e: any) => {
    e.preventDefault();

    const accountName = getAccountUsernameFromPath(this.props.history);

    const { loginStore } = this.props;
    const { account } = loginStore;

    const homeParams = GetHomeParams(account);

    if (this.props.isVacationRentals) {
      this.props.history.push(`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
    } else {
      this.props.history.push(`/${accountName}${Routes.Search}${homeParams}`);
    }

    window.location.reload();
  }

  onBudgetChange = (checkedValues: string[]) => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    this.props.setBudget(checkedValues);

    const action = 
    ReactGA.event({
      category: account.name,
      action: `${R_FILTER_BUDGET}_${account.name.toUpperCase()}`,
      label: `User selected budget checkbox filter on result`,
      nonInteraction: false,
    });

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onAmenitiesChange = (checkedValues: string[]) => {
    this.props.setAmenities(checkedValues);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onAccommodationTypeChange = (checkedValues: string[]) => {
    this.props.setAccommodationType(checkedValues);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onAccessibilityChange = (checkedValues: string[]) => {
    this.props.setAccessibility(checkedValues);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onPopularLocationChange = (checkedValues: string[]) => {
    this.props.setPopularLocations(checkedValues);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onBoardNameChange = (checkedValues: string[]) => {
    this.props.setBoardName(checkedValues);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onRefundableChange = (checkedValues: string[]) => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    this.props.setRefundable(checkedValues);

    const action = getRefundabilityCodeByValue(checkedValues);
    ReactGA.event({
      category: account.name,
      action: `${action}_${account.name.toUpperCase()}`,
      label: `User selected ${action} checkbox filter on result`,
      nonInteraction: false,
    });

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onFlexibleDatesChange = (checkedValues: string[]) => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    this.props.setFlexibleDate(checkedValues);

    ReactGA.event({
      category: account.name,
      action: `${R_FILTER_CONDO}_${account.name.toUpperCase()}`,
      label: `User selected/unselected stay more save more checkbox filter on result`,
      nonInteraction: false,
    });

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onNeighborhoodsChange = (checkedValues: string[]) => {
    this.props.setNeighbourhoods(checkedValues);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  getBudgetLabel = (range: IRange, currency: string) => {
    const { from, to } = range;

    if (from && to) {
      return (
        <span>
          <Currency currency={currency} />{from} <FormattedMessage id="to" /> <Currency currency={currency} />{to}
        </span>
      );
    }

    if (!from && to) {
      return (
        <span>
          <FormattedMessage id="less.than" /> <Currency currency={currency} />{to}
        </span>
      );
    }

    if (from && !to) {
      return (
        <span>
          <FormattedMessage id="greater.than" /> <Currency currency={currency} />{from}
        </span>
      );
    }
  };

  toggleAmenities = () => {
    this.setState(({ isFullAmenities }) => ({
      isFullAmenities: !isFullAmenities,
    }));
  };

  toggleNeighbourhoods = () => {
    this.setState(({ isFullNeighbourhoods }) => ({
      isFullNeighbourhoods: !isFullNeighbourhoods,
    }));
  };

  getSortOptions = (): LabeledValue[] => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    const { hasQuick, isB2C, walletWalletSavings } = account;
    return getSortOptions(hasQuick, isB2C, walletWalletSavings);
  };

  onSearchSave = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account.name,
      action: `${R_FILTER_NAME}_${account.name.toUpperCase()}`,
      label: `User selected an hotel name filter on resuls`,
      nonInteraction: false,
    });

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onDistanceChange = (value: number) => {
    this.props.setDistance(value);
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onStarsChange = (value: number[]) => {
    this.props.setStarRating(value);

    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account.name,
      action: `${R_FILTER_STARS}_${account.name.toUpperCase()}`,
      label: `User selected stars filter on resuls`,
      nonInteraction: false,
    });

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onSortChange = (value: SelectValue) => {
    const valueStr = value as string;
    this.props.setSortBy(valueStr);

    const { loginStore } = this.props;
    const { account } = loginStore;

    const action = getSortByCodeByValue(valueStr);
    if (action) {
      ReactGA.event({
        category: account.name,
        action: `${action}_${account.name.toUpperCase()}`,
        label: `User selected ${action} sort on result`,
        nonInteraction: false,
      });
    }

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onResetFilters = (fromMap = false) => {
    UrlUtils.setUrl(FILTERS_LABEL, null);

    if (fromMap) {
      this.props.setMapLoading(fromMap);
    }

    this.props.resetFilters();
    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  onFiltersOrSortChange = debounce(() => {
    const {
      getHotels,
      locationsStore,
      filtersStore,
      hotelsStore,
      resetHotels,
    } = this.props;
    const { lastSearchedLocation } = hotelsStore;
    const { selectedLocation } = locationsStore;

    if (!lastSearchedLocation && !selectedLocation) {
      this.checkIsLocationValid();
    }

    if (isFiltersChanged(this.prevFilters, filtersStore)) {
      UrlUtils.setUrl(FILTERS_LABEL, filtersStore);
      this.prevFilters = filtersStore;
      resetHotels();
      getHotels(
        lastSearchedLocation,
        true,
        true,
      );
    }
  }, debounceTime);

  onMobileFilter = () => {
    if (!this.props.hotelsStore.isSessionExpired) {
      this.setState({ isMobileFilter: !this.state.isMobileFilter });
      return this.state.isMobileFilter
        ? (document.body.style.overflow = 'auto')
        : (document.body.style.overflow = 'hidden');
    }
  };

  onApply = () => {
    this.onMobileFilter();
    this.onFiltersOrSortChange();
  };

  showMap = (center: google.maps.LatLngLiteral, selectedHotel: IHotel = null): void => {
    const { hotelsStore, loginStore } = this.props;
    const { hotels, similarHotels } = hotelsStore;
    const { account } = loginStore;

    if (!this.props.hotelsStore.isSessionExpired && (hotels?.length || similarHotels?.length)) {
      this.setState({ showFilters: false });

      const footer: HTMLDivElement = document.querySelector('.footer');

      this.setState({ isMapView: true, selectedHotel });
      this.props.setIsMapView(true);
      this.onMapSearchUpdate(selectedHotel);

      if (footer) {
        window.scrollTo(zero, zero);
        document.body.style.overflow = 'hidden';
        footer.style.display = 'none';
      }
    }

    ReactGA.event({
      category: account.name,
      action: `${R_VIEW_MAP}_${account.name.toUpperCase()}`,
      label: `User clicked view map`,
      nonInteraction: false,
    });

  };

  showFiltersView = () => {
    this.setState({ showFilters: !this.state.showFilters });
  }

  showListView = (): void => {
    if (!this.props.hotelsStore.isSessionExpired) {
      this.setState({ showFilters: true });

      const footer: HTMLDivElement = document.querySelector('.footer');

      this.setState({ isMapView: false });
      this.props.setIsMapView(false);

      if (footer) {
        document.body.style.overflow = 'auto';
        footer.style.display = 'block';
      }
    }
  };

  onCloseMessage = () => {
    this.props.setIsMapMessageShown(false);
  };

  onMapSearchUpdate = (selectedHotel: IHotel = null): void => {
    if (!this.props.hotelsStore.isSessionExpired) {
      const { getMapHotels, datesStore, roomsStore, locationsStore } = this.props;
      const { location } = locationsStore;
      const { startDate, endDate } = datesStore;
      const { rooms } = roomsStore;

      getMapHotels(location, startDate, endDate, rooms, selectedHotel?.hotelId);
    }
  };

  getMapHotelList = (location: ILocation, mapHotels: IHotel[], similarHotels: IHotel[]): IHotel[] => {
    if (location.type === 'Hotel') {
      const list: IHotel[] = (mapHotels?.length || similarHotels?.length) ? [] : null;
    
      if (mapHotels?.length) {
        mapHotels.forEach(h => list.push(h));
      }
      if (similarHotels?.length) {
        similarHotels.forEach(h => list.push(h));
      }

      return list;
    }

    return null;
  }

  handleSearchHomes = (value: SelectValue) => {
    const valueStr = value as string;
    this.props.setSearchHomes(valueStr);

    const action = getPropertyTypeCodeByValue(valueStr);
    if (action) {

      const { loginStore } = this.props;
      const { account } = loginStore;

      ReactGA.event({
        category: account.name,
        action: `${action}_${account.name.toUpperCase()}`,
        label: `User clicked ${value === HomesTypes.HotelOnly ? 'Hotel' : 'Vacation rental'} button on result`,
        nonInteraction: false,
      });
    }

    return !this.state.isMobileFilter && this.onFiltersOrSortChange();
  };

  homesFilterComponent = () => {
    const { loginStore, filtersStore, isVacationRentals } = this.props;

    const account = loginStore?.account;
    const color: any = account?.buttonColor ? account?.buttonColor : MAIN_BLUE_COLOR;
    const styleColor = !isEmpty(color)? { background: color, borderRightColor: color, borderColor: color } : {};  

    if(isVacationRentals) {
      return null;
    }

    if (account?.forceDisableVacationRentals) {
      return null;
    }

    return (
      <div className="row row-cols-6 pb-3 result-wrapper__style-home-search-container">
        <div className="col-12">
          <Radio.Group value={filtersStore.searchHomes} optionType="button" buttonStyle="solid" size="large" onChange={(e) => this.handleSearchHomes(e.target.value)}>
            {getHomesOptions(false).map((item, index) => (
              <Radio.Button 
                key={`${index}-${item}`}
                value={item.value}
                className="result-wrapper__style-home-search"
                style={item.value === filtersStore.searchHomes ? styleColor : {}}
              >
                {item.icon} {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>
    )
  }

  handleCompare = (hotel: IHotel) => {
    const { hotelsStore } = this.props;
    const { selectedCompareHotels } = hotelsStore;
    const hotelIndex = selectedCompareHotels.findIndex(h => h.hotelId === hotel.hotelId);
    if (hotelIndex != -1) {
      const selectedCompareHotelsFiltered = [...selectedCompareHotels];
      selectedCompareHotelsFiltered.splice(hotelIndex, 1);
      this.props.setSelectedCompareHotels([...selectedCompareHotelsFiltered]);
    } else {
      this.props.setSelectedCompareHotels([...selectedCompareHotels, hotel]);
    }
  }

  handleOnSelectClientCash = (selectedSearchClientCash: number) => {
    const { hotelsStore } = this.props;
    const { selectedHotelSearchClientCash } = hotelsStore;

    const clientCash = { ...selectedHotelSearchClientCash, selectedSearchClientCash };
    this.props.setSelectedHotelSearchClientCash(clientCash);
  }

  render(): React.ReactNode {
    const { isFullAmenities, isMobileFilter, isMapView, showFilters, selectedHotel, isFullNeighbourhoods, isFullAccessibilities, isFullPopularLocations, isFullAccommodationTypes, isFullMealPlans, showCompareModal } =
      this.state;
    const { hotelsStore, loginStore, filtersStore, locationsStore, roomsStore, datesStore, matches, isVacationRentals, setBounds } = this.props;
    const {
      hotels,
      similarHotels,
      searchedLocation,
      lastSearchedLocation,
      loadingMore,
      loadingSimilar,
      counters,
      mapCounters,
      loadingFilters,
      loadingFilter,
      mapHotels,
      bounds,
      isSessionExpired,
      mapLoading,
      sessionKey,
      selectedCompareHotels,
      error,
      selectedHotelSearchClientCash,
    } = hotelsStore;
    const {
      amenities,
      accommodationType,
      accessibility,
      budget,
      popularLocations,
      neighbourhoods,
      distance,
      propertyName,
      sortBy,
      starRatings,
      refundable,
      boardNames,
      flexibleDates
    } = filtersStore;
    const { account, user } = loginStore;
    const { location } = locationsStore;
    const resultCounters = isMapView ? (mapCounters ? mapCounters : counters) : counters;
    const { startDate, endDate } = datesStore;
    const { rooms } = roomsStore;

    const accountName = getAccountUsernameFromPath(this.props.history);
    const isDisableHomes = account?.isDisableHomes;

    const isFiltersApplied = !isFiltersEmpty(filtersStore);
    const HasViewQuotesSharePermission = HasPermission(user, ViewQuotesSharePermission);
    const displayCompareLength = (HasViewQuotesSharePermission || isFiltersApplied) ? 0 : 1;

    const homeParams = GetHomeParams(account);

    if (!isEmpty(error)) {
      return (
        <div className={`result-wrapper ${isMapView ? 'map-view' : ''}`}>
          <div className="result-wrapper__back-link">
            {!isDisableHomes ? (
              <div className="back-home" onClick={() => {
                this.props.resetHotelsFull();
                if (isVacationRentals) {
                  this.props.history.push(`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
                } else {
                  this.props.history.push(`/${accountName}${Routes.Search}${homeParams}`);
                }
              }}>
                <FormattedMessage id="back.home" />
              </div>) : null}
          </div>

          <div className="result-wrapper__wrapper">
            <CustomErrorPage message={error} />
          </div>
        </div>
      );
    }

    let sortedAmenities;
    let amenitiesToShow: ICounterItem[];
    let neighbourhoodsToShow:ICounterItem[];
    let accessibilitiesToShow:ICounterItem[];
    let popularLocationsToShow:ICounterItem[];
    let accommodationTypesToShow:ICounterItem[];
    let boardNamesToShow:ICounterItem[];
    
    let isShowAmenities:boolean;
    let isShowNeighbourhoods;
    let isShowBudgets;
    let hasBudgets: boolean;
    let isShowAccommodationTypes;
    let isShowRefundable;
    let isShowAccessibility;
    let isShowMealPlans;
    let isShowPopularLocations;

    let isFlexibleDates; 

    if (!!resultCounters) {
      let accessibilities = resultCounters.accessibilities;
      if (accessibilities?.length && accessibility?.length) {
        const accessibilitiesToMoveTop = resultCounters.accessibilities.filter(a => accessibility.includes(a.key));
        const accessibilitiesToKeep = resultCounters.accessibilities.filter(a => !accessibility.includes(a.key));
        accessibilities = [...accessibilitiesToMoveTop, ...accessibilitiesToKeep];
      }

      let accommodationTypes = resultCounters.propertyTypes;
      if (accommodationTypes?.length && accommodationType?.length) {
        const accommodationTypesToMoveTop = resultCounters.propertyTypes.filter(a => accommodationType.includes(a.key));
        const accommodationTypesToKeep = resultCounters.propertyTypes.filter(a => !accommodationType.includes(a.key));
        accommodationTypes = [...accommodationTypesToMoveTop, ...accommodationTypesToKeep];

        if (account?.forceDisableVacationRentals) {
          const ACCOMMODATIONS_TO_EXCLUDE = ['Apartment', 'Condominium', 'Villas', 'Private vacation home'];
          accommodationTypes = accommodationTypes.filter(a => !ACCOMMODATIONS_TO_EXCLUDE.includes(a?.key));
        }
      }

      let mealPlans = resultCounters.boardNames;
      if (mealPlans?.length && boardNames?.length) {
        const mealPlansToMoveTop = resultCounters.boardNames.filter(a => boardNames.includes(a.key));
        const mealPlansToKeep = resultCounters.boardNames.filter(a => !boardNames.includes(a.key));
        mealPlans = [...mealPlansToMoveTop, ...mealPlansToKeep];
      }

      sortedAmenities = getAmenities(resultCounters.amenities);
      amenitiesToShow = isFullAmenities ? sortedAmenities : sortedAmenities.slice(zero, maxAmenitiesCount);
      neighbourhoodsToShow = isFullNeighbourhoods ? resultCounters.neighbourhoods : resultCounters.neighbourhoods.slice(zero, maxAmenitiesCount);
      accessibilitiesToShow = isFullAccessibilities ? accessibilities : accessibilities.slice(zero, maxAmenitiesCount);
      accommodationTypesToShow = isFullAccommodationTypes ? accommodationTypes : accommodationTypes.slice(zero, maxAmenitiesCount);
      boardNamesToShow = isFullMealPlans ? mealPlans : mealPlans.slice(zero, maxAmenitiesCount);

      isShowAmenities = !!amenitiesToShow.filter(({ count }) => !!count).length;
      isShowNeighbourhoods = !!resultCounters.neighbourhoods.filter(({ count }) => !!count).length;
      isShowBudgets = !!budget?.length || !!resultCounters.budgetRanges.filter(({ count }) => !!count).length;
      hasBudgets = !!resultCounters.budgetRanges.filter(({ count }) => !!count).length;
      isShowAccommodationTypes = !!resultCounters.propertyTypes.filter(({ count }) => !!count).length;
      isShowRefundable = !!resultCounters.refundable.filter(({ count }) => !!count).length;
      isFlexibleDates = !!resultCounters.totalFlexibleDates;
      isShowAccessibility = !!resultCounters.accessibilities.filter(({ count }) => !!count).length;
      isShowMealPlans = !!resultCounters.boardNames.filter(({ count }) => !!count).length;
    }

    const applyFilters = [
      amenities,
      accommodationType,
      budget,
      neighbourhoods,
      distance,
      propertyName,
      sortBy,
      starRatings,
      refundable,
      boardNames,
      flexibleDates
    ].filter(String);
    
    const currency = getSelectedCurrency(account);
    const mapHotelsList = mapHotels;
    const isEmptySearch = (hotels && !hotels.length && location?.type !== 'Hotel') || (hotels && !hotels.length && similarHotels && !similarHotels.length && location?.type === 'Hotel');

    const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;
    const background: any = account ? account?.buttonColor : null;
    const styleColor = ((hotels?.length || similarHotels?.length) && !isEmpty(background))? { background } : {};  
        
    return (
      <div className={`result-wrapper ${isMapView ? 'map-view' : ''}`}>
        
        <div className="result-wrapper__back-link">
          {!isDisableHomes ? (
            <div className="back-home" onClick={() => {
              this.props.resetHotelsFull();
              if (isVacationRentals) {
                this.props.history.push(`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
              } else {
                this.props.history.push(`/${accountName}${Routes.Search}${homeParams}`);
              }
            }}>
              <FormattedMessage id="back.home" />
            </div>) : null}

          {isMapView && (
            <div className="result-wrapper__map-header">
              {location.type !== 'Hotel' && (<div className="result-wrapper__filters-view-wrapper" ref={this.filterButtonWrapperRef} onClick={this.showFiltersView}>
                <FontAwesomeIcon icon={faSliders} /> <FormattedMessage id="view.filters" />
              </div>)}
              <div className="result-wrapper__list-view-wrapper" onClick={this.showListView}>
                <FontAwesomeIcon icon={faList} /> <FormattedMessage id="view.list" />
              </div>
            </div>)}
        </div>

        <div className="result-wrapper__wrapper">

          {!loadingFilter && !!resultCounters ?
            <div
              className={`result-wrapper__filters-wrapper ${isMobileFilter ? 'is-mobile' : ''} ${
                isSessionExpired ? 'disabled' : ''} ${!showFilters ? 'hite-filters' : ''}`}
              ref={this.wrapperRef}
            >
              {!isMapView && (<div
                className="result-wrapper__map-view-wrapper"
                onClick={() => this.showMap(Map.getGoogleLocation(location.geoLocation))}
              >
                <div
                  className={`result-wrapper__map-view ${(hotels?.length || similarHotels?.length) ? '' : 'disabled'}`}
                  style={{ backgroundImage: `url(${(hotels?.length || similarHotels?.length) ? MapImage : MapMonoImage})` }}
                >
                  <div className={`result-wrapper__map-button ${(hotels?.length || similarHotels?.length) ? '' : 'disabled'}`} style={styleColor}>
                    <MapSearchSvg /> <FormattedMessage id="view.on.map" />
                  </div>
                </div>
              </div>)}

            <div className="result-wrapper__filters-header-wrapper-sticky">
              <div className="result-wrapper__filters-header-wrapper">
                <div className="result-wrapper__filters-header-item" onClick={this.onMobileFilter}>
                  <CloseBlueSvg />
                  <p className="result-wrapper__filters-header-text">
                    <FormattedMessage id="sort.filter" />
                  </p>
                </div>
                {isFiltersApplied && (
                  <p
                    className="result-wrapper__mobile-reset-filters-link"
                    onClick={() => this.onResetFilters()}
                  >
                    <FormattedMessage id="reset.all" />
                  </p>
                )}
              </div>
              <div
                className={`result-wrapper__sort-select-wrapper ${
                  isSessionExpired ? 'disabled' : ''
                }`}
              >
                <span className="result-wrapper__sort-label">
                  <FormattedMessage id="sort.by" />:{' '}
                </span>
                <div className="result-wrapper__select">
                  <CustomSelect
                    disabled={isSessionExpired}
                    value={sortBy}
                    onChange={this.onSortChange}
                    options={this.getSortOptions()}
                  />
                </div>
              </div>
              
              {isFlexibleDates ? (
                <div className="result-wrapper__filters-flexible-dates">
                  <div className="result-wrapper__filters-flexible-dates-background"></div>
                  <FilterCheckbox
                    disabled={isSessionExpired}
                    onChange={this.onFlexibleDatesChange}
                    values={flexibleDates}
                    options={[{
                      count: resultCounters.totalFlexibleDates,
                      value: 'false',
                      label: <span><FormattedMessage id="result.hotel.filters.flexible_dates" /></span>,
                    }]}
                    title="result.hotel.card.stay_more"
                    titleInformation="result.hotel.filters.flexible_dates.title.information"
                  />
                </div>
              ) : null}

              {isShowRefundable ? (
                <FilterCheckbox
                  disabled={isSessionExpired}
                  onChange={this.onRefundableChange}
                  values={refundable}
                  options={resultCounters.refundable.map(({ count, key }) => {
                    return {
                      count,
                      value: key,
                      label: <span>{key}</span>,
                    };
                  })}
                  title="refundable.type"
                />
              ) : null}

              {location?.type !== LocationsTypes.Hotel &&
                (!isMapView || (isMapView && !matches)) && (
                  <SearchPropertyName
                    disabled={isSessionExpired || (!hotels?.length && !similarHotels?.length)}
                    className="top-search-wrapper"
                    onSaveValue={this.onSearchSave}
                    title="search.property.name"
                    isMapView={isMapView}
                  />
                )}
              <p className="result-wrapper__filters-tittle">
                <FormattedMessage id="filter.by" />
              </p>

              {isShowNeighbourhoods ? (
                <>
                  <FilterCheckbox
                    disabled={isSessionExpired}
                    onChange={this.onNeighborhoodsChange}
                    values={neighbourhoods}
                    options={neighbourhoodsToShow.map(({ count, key }) => {
                      return {
                        count,
                        value: key,
                        label: <span>{key}</span>,
                      };
                    })}
                    title="neighborhoods"
                  />
                  {!isSessionExpired && resultCounters.neighbourhoods.length > maxAmenitiesCount && (
                    <div className="result-wrapper__show-link" onClick={this.toggleNeighbourhoods}>
                      {isFullNeighbourhoods ? (
                        <FormattedMessage id="show.less" />
                      ) : (
                        <FormattedMessage id="show.more" />
                      )}
                    </div>
                  )}
                </>
              ) : null}

              {isShowBudgets ? (
                <FilterCheckbox
                  disabled={isSessionExpired || !hasBudgets}
                  onChange={this.onBudgetChange}
                  displayZero={!hasBudgets}
                  values={budget}
                  options={resultCounters.budgetRanges.filter(b => !!hasBudgets || budget.includes(`${b.key.from}-${b.key.to}`)).map(({ count, key }) => {
                    return {
                      count,
                      value: `${key.from}-${key.to}`,
                      label: this.getBudgetLabel(key, currency),
                    };
                  })}
                  title="budget"
                />) : null}

              <FilterStars
                disabled={isSessionExpired || (!hotels?.length && !similarHotels?.length)}
                title="star.rating"
                options={plainOptionsStar}
                onChange={this.onStarsChange}
                value={starRatings}
              />

              {isShowAmenities ? (
                <>
                  <FilterCheckbox
                    disabled={isSessionExpired}
                    onChange={this.onAmenitiesChange}
                    values={amenities}
                    options={amenitiesToShow.map(({ count, key }) => {
                      const icon = TOP_AMENITIES_ICONS[key.toLowerCase()];

                      return {
                        count,
                        value: key,
                        label: (
                          <div className="filter__icon-item">
                            <span className="filter__icon-wrapper">{icon}</span>
                            <span>{key}</span>
                          </div>
                        ),
                      };
                    })}
                    title="tab.amenities"
                  />
                  {!isSessionExpired && sortedAmenities.length > maxAmenitiesCount && (
                    <div className="result-wrapper__show-link" onClick={this.toggleAmenities}>
                      {isFullAmenities ? (
                        <FormattedMessage id="show.less" />
                      ) : (
                        <FormattedMessage id="show.more" />
                      )}
                    </div>
                  )}
                </>
              ) : null}

              {isShowAccommodationTypes ? (
                <>
                  <FilterCheckbox
                    disabled={isSessionExpired}
                    onChange={this.onAccommodationTypeChange}
                    values={accommodationType}
                    options={accommodationTypesToShow.map(({ count, key }) => {
                      return {
                        count,
                        value: key,
                        label: <span>{key}</span>,
                      };
                    })}
                    title="accommodation.type"
                  />

                  {!isSessionExpired && resultCounters.propertyTypes?.length > maxAmenitiesCount && (
                    <div className="result-wrapper__show-link" onClick={() => this.setState({ isFullAccommodationTypes: !isFullAccommodationTypes })}>
                      {isFullAccommodationTypes ? (
                        <FormattedMessage id="show.less" />
                      ) : (
                        <FormattedMessage id="show.more" />
                      )}
                    </div>)}
                </>) : null}

              {isShowAccessibility ? (
                <>
                  <FilterCheckbox
                    disabled={isSessionExpired}
                    onChange={this.onAccessibilityChange}
                    values={accessibility}
                    options={accessibilitiesToShow.map(({ count, key }) => {
                      return {
                        count,
                        value: key,
                        label: <span>{key}</span>,
                      };
                    })}
                    title="accessibility"
                  />
                  {!isSessionExpired && resultCounters.accessibilities?.length > maxAmenitiesCount && (
                    <div className="result-wrapper__show-link" onClick={() => this.setState({ isFullAccessibilities: !isFullAccessibilities })}>
                      {isFullAccessibilities ? (
                        <FormattedMessage id="show.less" />
                      ) : (
                        <FormattedMessage id="show.more" />
                      )}
                    </div>)}
                </>) : null}

                {isShowPopularLocations ? (
                  <>
                    <FilterCheckbox
                      disabled={isSessionExpired}
                      onChange={this.onPopularLocationChange}
                      values={popularLocations}
                      options={popularLocationsToShow.map(({ count, key }) => {
                        return {
                          count,
                          value: key,
                          label: <span>{key}</span>,
                        };
                      })}
                      title="popular.locations"
                    />
                    {!isSessionExpired && resultCounters.popularLocations?.length > maxAmenitiesCount && (
                      <div className="result-wrapper__show-link" onClick={() => this.setState({ isFullPopularLocations: !isFullPopularLocations })}>
                        {isFullPopularLocations ? (
                          <FormattedMessage id="show.less" />
                        ) : (
                          <FormattedMessage id="show.more" />
                        )}
                      </div>)}
                  </>) : null}

              {isShowMealPlans ? (
                <>
                  <FilterCheckbox
                    disabled={isSessionExpired}
                    onChange={this.onBoardNameChange}
                    values={boardNames}
                    options={boardNamesToShow.map(({ count, key }) => {
                      return {
                        count,
                        value: key,
                        label: <span>{key}</span>,
                      };
                    })}
                    title="meal.plan"
                  />
                  {!isSessionExpired && resultCounters.boardNames?.length > maxAmenitiesCount && (
                    <div className="result-wrapper__show-link" onClick={() => this.setState({ isFullMealPlans: !isFullMealPlans })}>
                      {isFullMealPlans ? (
                        <FormattedMessage id="show.less" />
                      ) : (
                        <FormattedMessage id="show.more" />
                      )}
                    </div>)}
                </>) : null}

              {isFiltersApplied && !isSessionExpired && (
                <div className="result-wrapper__reset-filters-wrapper">
                  <span
                    className="result-wrapper__reset-filters-link"
                    onClick={() => this.onResetFilters()}
                  >
                    <FormattedMessage id="reset.all" />
                  </span>
                </div>
              )}

              {!isSessionExpired && (
                <div className="result-wrapper__filters-apply">
                  <BlueButton onClick={this.onApply} disabled={isSessionExpired}>
                    <FormattedMessage id="apply" />
                    <span className="result-wrapper__filters-apply-counts">
                      {applyFilters.length}
                    </span>
                    <FormattedMessage id="filters" />
                  </BlueButton>
                </div>
              )}
            </div>
            </div> : 
            <div className="result-wrapper__filters-wrapper">
              <SkeletonFilters />
            </div>}

          <div className={`result-wrapper__btn-view-wrapper ${isSessionExpired ? 'disabled' : ''}`}>
            <WhiteButton onClick={this.onMobileFilter} disabled={isSessionExpired}>
              <FilterSvg />
              <FormattedMessage id="sort.filter" />
            </WhiteButton>
            <WhiteButton
              disabled={isSessionExpired}
              onClick={() => this.showMap(Map.getGoogleLocation(location.geoLocation))}
            >
              <MapSvg />
              <FormattedMessage id="view.map" />
            </WhiteButton>
          </div>

          {isMapView ? (
            <div className="result-wrapper__map-wrapper">
              <div className="result-wrapper__map-top">
                <div className="result-wrapper__map-close" onClick={this.showListView}>
                  <CloseSvg />
                </div>
                <div className="result-wrapper__map-location">{hotelsStore.searchedLocation}</div>
                <div
                  className={`result-wrapper__map-filters ${isSessionExpired ? 'disabled' : ''}`}
                  onClick={this.onMobileFilter}
                >
                  <FilterSvg />
                </div>
              </div>


              {matches && (
                <SearchPropertyName
                  disabled={isSessionExpired}
                  onSaveValue={this.onSearchSave}
                  title="search.property.name"
                  isMapView={isMapView}
                />
              )}

              {mapHotelsList ? (
                <MapWrapper
                  bounds={bounds}
                  disabled={isSessionExpired}
                  hotels={mapHotelsList}
                  locations={mapHotelsList.map(({ location, hotelId, pricePerNight, availability }) => ({
                    location,
                    id: hotelId,
                    pricePerNight,
                    availability
                  }))}
                  nights={resultCounters.totalNights}
                  mapOptions={{ disableDefaultUI: true, zoomControl: true }}
                  withEvents={true}
                  selectedHotel={selectedHotel}
                  onSearchUpdate={this.onMapSearchUpdate}
                  setBounds={setBounds}
                  isVacationRentals={isVacationRentals}
                />
              ) : null}
            </div>
          ) : (
            <div
              className={`result-wrapper__content-wrapper ${isMobileFilter ? 'is-mobile' : ''} ${isEmptySearch ? 'is-empty-search' : ''}`}
              style={{ paddingBottom: loadingMore ? '0px' : isEmptySearch ? '100px' : '250px' }}
            >
              {selectedCompareHotels?.length ? (
                <>
                  <div className="result-wrapper__compare-message">
                    {selectedCompareHotels.length > 1 ? (<FormattedMessage id="results.hotel.card.compare.message" values={{ selected: selectedCompareHotels.length, total: maxCompareHotels }} />) : null}
                    {selectedCompareHotels.length === 1 ? (<FormattedMessage id="results.hotel.card.compare.message.select_more" />) : null}
                    <div className="compare-buttons">
                      {selectedCompareHotels.length ? (
                        <Button className="compare-clear-button" onClick={() => this.props.setSelectedCompareHotels([])}>
                          <FormattedMessage id="results.hotel.card.compare.clear_selection" />
                        </Button>) : null}

                      <Button className="compare-button" disabled={selectedCompareHotels.length <= displayCompareLength} onClick={() => this.setState({ showCompareModal: true })}>
                        {HasViewQuotesSharePermission ? <FormattedMessage id="results.hotel.card.compare.share" /> : <FormattedMessage id="results.hotel.card.compare" /> }
                      </Button>
                    </div>
                  </div>

                  <ModalCompare
                    visible={showCompareModal}
                    hotels={selectedCompareHotels}
                    rooms={[...rooms]}
                    checkIn={startDate}
                    checkOut={endDate}
                    isVacationRentals={isVacationRentals}
                    locationCode={location.code?.toString()}
                    sessionKey={sessionKey}
                    onCancel={() => this.setState({ showCompareModal: false })}
                    onRemove={this.handleCompare}
                  />
                </>
              ) : null}

              {isSessionExpired && (
                <div className="result-wrapper__expired-message">
                  <ExpiredSessionModal visible={isSessionExpired} />
                </div>
              )}
              {(isEmptySearch)? (
                isFiltersApplied ? (
                  <>
                    {location?.type !== 'Hotel' && this.homesFilterComponent()}
                    <EmptyFilterMessage account={account} onReset={() => this.onResetFilters()} isVacationRentals={isVacationRentals} filterHomes={this.props.filtersStore.searchHomes} />
                  </>
                ) : (
                  <EmptySearchMessage
                    label={
                      lastSearchedLocation && lastSearchedLocation.type === LocationsTypes.Hotel 
                        ? "sorry.could.not.find.the.specified.hotel"
                        : null
                    }
                    isVacationRentals={isVacationRentals}
                    filterHomes={this.props.filtersStore.searchHomes}
                    location={searchedLocation}
                    account={account}
                  />
                )
              ) : (
                <>
                  <div className="result-wrapper__top-section">
                    <div className="col-12">
                      <div className="row">
                        {location?.type !== 'Hotel' && 
                          <div className="col-md-5 col-sm-8">
                            {this.homesFilterComponent()}
                          </div>
                        }
                        <div className={(location?.type !== 'Hotel' ? "text-center col-md-3" : "col-md-8") + " col-sm-3"}>
                          <p className="result-wrapper__top-header">
                            {((location?.type === 'Hotel' && hotelsStore?.counters?.totalFilteredHotels) || location?.type !== 'Hotel') && (<FormattedMessage
                              id="properties.found"
                              values={{ count: hotelsStore?.counters?.totalFilteredHotels }}
                            />)}
                            {location?.type === 'Hotel' && !hotelsStore?.counters?.totalFilteredHotels && (<FormattedMessage
                              id="properties.found"
                              values={{ count: hotelsStore?.counters?.totalFilteredHotels }}
                            />)}
                          </p>
                        </div>
                        <div className="col-md-4 col-sm-1">
                          <div className="result-wrapper__sort-select">
                            <span className="result-wrapper__sort-label">
                              <FormattedMessage id="sort.by" />{' '}
                            </span>
                            <CustomSelect
                              disabled={isSessionExpired}
                              value={sortBy}
                              onChange={this.onSortChange}
                              options={this.getSortOptions()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {walletHasSliderOnSearch ? (
                    <div className="result-wrapper__top-section" style={{ backgroundColor: '#FFFFFF', marginBottom: '20px', borderRadius: '5px', minHeight: 'unset', border: '1px solid #D2D2D2' }}>
                      <WalletSearchSlider
                        display={true}
                        selectedClientCash={selectedHotelSearchClientCash?.selectedSearchClientCash}
                        onSelectClientCash={this.handleOnSelectClientCash}
                      />
                    </div>) : null}

                  {!loadingFilters ? (
                    hotels.map((hotel: IHotel, index: number) => {
                      const isInCompareList = selectedCompareHotels?.map(h => h.hotelId).includes(hotel.hotelId);
                      return (
                        <div key={hotel.hotelId}>
                          <Responsive>
                            <HotelCardComponent
                              disabled={isSessionExpired}
                              hotel={hotel}
                              nights={!hotel?.possibleStays?.length ? counters.totalNights : get(hotel, 'possibleStays[0].days', 0)}
                              showOnMap={() =>
                                this.showMap(Map.getGoogleLocation(hotel.location), hotel)
                              }
                              compared={isInCompareList}
                              displayCompare={true}
                              disableCompare={!(hotels?.length > displayCompareLength && (isInCompareList || selectedCompareHotels?.length < maxCompareHotels))}
                              onCompareSelect={this.handleCompare}
                              isVacationRentals={isVacationRentals}
                            />
                          </Responsive>
                          {(index === 2 || location?.type === 'Hotel') && (<Banner type={SEARCH_BANNER_TYPE}></Banner>)}
                        </div>
                      );
                    })
                  ) : (
                    <SkeletonContent label={''} />
                  )}

                  {loadingMore && <SkeletonContent label={''} isTitle={false} />}
                </>
              )}

              {!loadingFilters && location?.type === 'Hotel' && (
                <div className="result-wrapper__similar-hotels-container">
                  {(loadingSimilar || similarHotels?.length) && (<div className="similar-hotels-title">
                    {hotels?.length ? 'Other hotels in the same destination' : 'Don\'t worry, we have some other options in the same destination'}
                  </div>)}
                  {!loadingSimilar && similarHotels?.map((hotel: IHotel) => {
                    return (
                      <Responsive key={hotel.hotelId}>
                        <HotelCardComponent
                          disabled={isSessionExpired}
                          hotel={hotel}
                          nights={!hotel?.possibleStays?.length ? counters.totalNights : get(hotel, 'possibleStays[0].days', 0)}
                          isSimilar={true}
                          displayCompare={false}
                          showOnMap={() => this.showMap(Map.getGoogleLocation(hotel.location), hotel)}
                          isVacationRentals={isVacationRentals}
                        />
                      </Responsive>
                    );
                  })}
                  {(loadingSimilar || loadingMore) && (<SkeletonContent label={''} isTitle={false} />)}
                </div>
              )}

            </div>
          )}
        </div>

        {mapLoading && isMapView && <SkeletonMap />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,
    datesStore: state.datesStore,
    locationsStore: state.locationsStore,
    roomsStore: state.roomsStore,
    filtersStore: state.filtersStore,
    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setPageNumber: () => {
    dispatch(hotelsActions.setPageNumber());
  },
  resetHotels: () => {
    dispatch(resetHotels());
  },
  resetHotelsFull: () => {
    dispatch(resetHotelsFull());
  },
  getHotels: (
    location: ILocation,
    isLoadMore: boolean,
    isFiltersChange: boolean,
  ) => {
    dispatch(getHotels(location, isLoadMore, isFiltersChange));
  },
  getMapHotels: (
    location: ILocation,
    startDate: string,
    endDate: string,
    rooms: IRoom[],
    hotelId: number = null,
  ) => {
    dispatch(getMapHotels(location, startDate, endDate, rooms, hotelId));
  },
  getSimilarHotels: (isFiltersChange: boolean, isLoadMore: boolean) => {
    dispatch(getSimilarHotels(isFiltersChange, isLoadMore));
  },
  setBudget: (value: string[]) => {
    dispatch(filtersActions.setBudget(value));
  },
  setAmenities: (value: string[]) => {
    dispatch(filtersActions.setAmenities(value));
  },
  setAccommodationType: (value: string[]) => {
    dispatch(filtersActions.setAccommodationType(value));
  },
  setAccessibility: (value: string[]) => {
    dispatch(filtersActions.setAccessibility(value));
  },
  setPopularLocations: (value: string[]) => {
    dispatch(filtersActions.setPopularLocations(value));
  },
  setBoardName: (value: string[]) => {
    dispatch(filtersActions.setBoardName(value));
  },
  setRefundable: (value: string[]) => {
    dispatch(filtersActions.setRefundable(value));
  },
  setFlexibleDate: (value: string[]) => {
    dispatch(filtersActions.setFlexibleDate(value));
  },
  setNeighbourhoods: (value: string[]) => {
    dispatch(filtersActions.setNeighbourhoods(value));
  },
  setPlaces: (value: string[]) => {
    dispatch(filtersActions.setPlaces(value));
  },
  setStarRating: (value: number[]) => {
    dispatch(filtersActions.setStarRating(value));
  },
  setDistance: (value: number) => {
    dispatch(filtersActions.setDistance(value));
  },
  setSortBy: (value: string) => {
    dispatch(filtersActions.setSortBy(value as SortTypes));
  },
  resetFilters: () => {
    dispatch(filtersActions.resetFilters());
  },
  setIsMapMessageShown: (shown: boolean) => {
    dispatch(hotelsActions.setIsMapMessageShown(shown));
  },
  validateLocation: (error: string) => {
    dispatch(locationActions.setError(error));
  },
  setSelectedCompareHotels: (selectedCompareHotels: IHotel[]) => {
    dispatch(hotelsActions.setSelectedCompareHotels(selectedCompareHotels));
  },
  setBounds: (bounds: IBounds) => {
    dispatch(hotelsActions.setBounds(bounds));
  },
  setMapLoading: (isMapLoading: boolean) => {
    dispatch(hotelsActions.setMapLoading(isMapLoading));
  },
  setIsMapView: (isMapView: boolean) => {
    dispatch(hotelsActions.setIsMapView(isMapView));
  },
  setSearchHomes: (value: string) => {
    dispatch(filtersActions.setSearchHomes(value as HomesTypes));
  },
  setSelectedHotelSearchClientCash: (selectedClientCash: IClientCash) => {
    dispatch(hotelsActions.setSelectedHotelSearchClientCash(selectedClientCash));
  }
});

export const ResultWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ResultWrapperComponent)));
