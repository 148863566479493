import React from 'react';
import img1 from '@assets/images/home/sandiego.jpg';
import img2 from '@assets/images/home/losangeles.jpg';
import img3 from '@assets/images/home/miami.jpg';
import img4 from '@assets/images/home/chicago.jpg';
import img5 from '@assets/images/home/orlando.jpg';
import img6 from '@assets/images/home/sandiego.jpg';

const cities = [
  { name: 'San Francisco', image: img1 },
  { name: 'Los Angeles', image: img2 },
  { name: 'Miami', image: img3 },
  { name: 'Chicago', image: img4 },
  { name: 'Orlando', image: img5 },
  { name: 'San Diego', image: img6 },
];

const ExploreDiscount = () => {
  return (
    <section>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 text-center">
            <h2 className="mb-0">Explore discounts</h2>
            <p className="lead">Find great deals in areas your team visits frequently</p>
          </div>
        </div>

        <div className="row g-4 g-md-5">
          {cities?.map((city, index) => (
            <div key={index} className="col-6 col-sm-4 col-lg-3 col-xl-2">
              <div className="card bg-transparent text-center p-1 h-100 border-0">
                <img src={city.image} className="rounded-circle" alt={city.name} />
                <div className="card-body p-0 pt-3">
                  <h5 className="card-title">
                    <a href="#" className="stretched-link">
                      {city.name}
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { ExploreDiscount };
