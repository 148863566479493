import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SearchingSvg, BookingSvg, EnjoySvg } from '@assets';
import './style.scss';

interface IProps {}

export class TravelingSteps extends React.Component<IProps, null> {
  render(): React.ReactNode {
    return (
      <div className="traveling-steps">
        <div className="traveling-steps__wrapper">
          <div className="traveling-steps__icon">
            <SearchingSvg />
          </div>
          <div className="traveling-steps__text">
            <h3 className="traveling-steps__title">
              <FormattedMessage id="searching.title" />
            </h3>
            <p className="traveling-steps__description">
              <FormattedMessage id="searching.description" />
            </p>
          </div>
        </div>
        <div className="traveling-steps__wrapper">
          <div className="traveling-steps__icon">
            <BookingSvg />
          </div>
          <div className="traveling-steps__text">
            <h3 className="traveling-steps__title">
              <FormattedMessage id="booking.title" />
            </h3>
            <p className="traveling-steps__description">
              <FormattedMessage id="booking.description" />
            </p>
          </div>
        </div>
        <div className="traveling-steps__wrapper">
          <div className="traveling-steps__icon">
            <EnjoySvg />
          </div>
          <div className="traveling-steps__text">
            <h3 className="traveling-steps__title">
              <FormattedMessage id="enjoy.title" />
            </h3>
            <p className="traveling-steps__description">
              <FormattedMessage id="enjoy.description" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
