import { AxiosPromise } from 'axios';
import {
  IAdminHotelBookings,
  IHotelAutocompleteResult,
} from '@common-types';
import { IReservationsParams } from '@share/common-types';
import { axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';
import { isEmpty } from 'lodash';

export interface IReservationsResponse {
  orders: IAdminHotelBookings[];
  totalCount: number;
}

export interface IReservationsAutocompleteResponse {
  results: IHotelAutocompleteResult[];
  totalCount: number;
}

export const ReservationsAPI = {
  getReservations(params: IReservationsParams): AxiosPromise<any> {
    const { orderBy, sortOrder, page, pageSize, bookingId, propertyName, leadName, bookingType, status } =  params;

    const bookingIdParam = `${bookingId? `&bookingId=${bookingId}`:''}`;
    const propertyNameParam = `${propertyName? `&propertyName=${propertyName}`:''}`;
    const leadNameParam = `${leadName? `&leadName=${leadName}`:''}`;
    const bookingTypeParam = `${bookingType ? `&bookingType=${bookingType}` : ''}`;
    const statusParam = `${status ? `&status=${status}` : ''}`;

    const orderParam = `${(orderBy && sortOrder)? `&orderBy=${orderBy}`:''}${sortOrder ? `&sortOrder=${sortOrder}`:''}`;
    const queryString = `?page=${page}&results=${pageSize}${bookingTypeParam}${orderParam}${bookingIdParam}${propertyNameParam}${leadNameParam}${statusParam}`;

    return axiosInstance.get(`${Urls.getReservations}${queryString}`, {
      ...getHeaders(),
    });
  },

  getStatic(id: number): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.getContentByPropertyId}${`?propertyId=${id}`}`, {
      ...getHeaders(),
    });
  },

  searchByText(queryString: string, type?: string): AxiosPromise<any> {
    const params: any = { queryString };
    if (!isEmpty(type)) {
      params.bookingType = type;
    }
    return axiosInstance.get(Urls.reservationsSearchText, {
      params,
      ...getHeaders(),
    });
  },

  cancelBookingAsync(bookingId: string, comment: string): AxiosPromise<any> {
    return axiosInstance.post(Urls.reservationsCancel, { id: bookingId, comment }, {
      ...getHeaders(),
    });
  },

  voucherBookingAsync(bookingId: string, recepientEmail: string, additionalEmail: string): AxiosPromise<any> {
    return axiosInstance.post(Urls.reservationsSendVoucher, { bookingId, recepientEmail, additionalEmail }, {
      ...getHeaders(),
    });
  },
};
