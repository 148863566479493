import React from 'react';
interface GuestsRoomsSelectorProps {
  rooms: any;
  setRooms: (newRooms: any) => void;
  setDisable:any
}
interface Room {
  adultsCount: number;
  kidsAges: number[];
}

const GuestsRoomsSelector: React.FC<GuestsRoomsSelectorProps> = ({
  rooms,
  setRooms,
  setDisable,
}) => {
  const handleUpdateRoom = (index, newData) => {
    const newRooms = [...rooms];
    newRooms[index] = newData;
    setRooms(newRooms);
  };

  const handleAddRoom = () => {
    setRooms([...rooms, { adultsCount: 1, kidsAges: [] }]);
    setDisable(false);
  };

  const handleRemoveRoom = (indexToRemove) => {
    setRooms(rooms.filter((_, index) => index !== indexToRemove));
    setDisable(false);
  };

  const handleAdultChange = (roomIndex, increment) => {
    const newRooms = [...rooms];
    const newCount = increment
      ? newRooms[roomIndex].adultsCount + 1
      : newRooms[roomIndex].adultsCount - 1;
    newRooms[roomIndex].adultsCount = newCount;
    setRooms(newRooms);
    setDisable(false);
  };

  const handleKidAgeChange = (roomIndex, kidIndex, age) => {
    const newRooms = [...rooms];
    newRooms[roomIndex].kidsAges[kidIndex] = age;
    setRooms(newRooms);
    setDisable(false);
  };

  const addKid = (roomIndex) => {
    const newRooms = [...rooms];
    newRooms[roomIndex].kidsAges.push(1);
    setRooms(newRooms);
    setDisable(false);
  };

  const subtractKid = (roomIndex) => {
    const newRooms = [...rooms];
    if (newRooms[roomIndex].kidsAges.length > 0) {
      newRooms[roomIndex].kidsAges.pop();
      setRooms(newRooms);
      setDisable(false);
    }
  };

  const removeKid = (roomIndex, kidIndex) => {
    const newRooms = [...rooms];
    newRooms[roomIndex].kidsAges = newRooms[roomIndex].kidsAges.filter((_, i) => i !== kidIndex);
    setRooms(newRooms);
    setDisable(false);
  };

  return (
    <div className="form-control-border form-control-bg-light form-fs-md d-flex">
      <i className="bi bi-people fs-4 me-2 mt-3"></i>
      <div className="w-100">
        <label className="form-label">Guests & Rooms</label>
        <div className="dropdown guest-selector relative">
          <input
            type="text"
            className="form-guest-selector form-control selection-result"
            value={`${rooms.reduce((acc, room) => acc + room.adultsCount, 0)} Adults, ${
              rooms.length
            } Rooms, ${rooms.reduce(
              (totalKids, room) => totalKids + room.kidsAges.length,
              0,
            )} Kids`}
            data-bs-auto-close="outside"
            data-bs-toggle="dropdown"
            readOnly
          />

          <ul className="dropdown-menu dropdown-menu-block guest-selector-dropdown">
            {rooms.map((room, roomIndex) => (
              <div key={roomIndex}>
                <div className="room-container">
                  <p>Room: {roomIndex + 1}</p>
                  {roomIndex === 0 ? (
                    ''
                  ) : (
                    <button
                      className="btn btn-link kid-remove p-0 d-flex justify-content-center align-content-center align-items-center "
                      onClick={() => handleRemoveRoom(roomIndex)}
                    >
                      Remove
                      <i className="bi bi-dash-circle fs-5 fa-fw btn-bi-color"></i>
                    </button>
                  )}
                </div>

                <li className="d-flex justify-content-between">
                  <div>
                    <h6 className="mb-0">Adults</h6>
                    <small>18 and Up</small>
                  </div>

                  <div className="hstack gap-1 align-items-center">
                    <button
                      type="button"
                      className="btn btn-link adult-remove p-0 mb-0 btn-bi-color"
                      onClick={() => handleAdultChange(roomIndex, false)}
                      disabled={room.adultsCount <= 1}
                    >
                      <i className="bi bi-dash-circle fs-5 fa-fw"></i>
                    </button>
                    <h6 className="guest-selector-count mb-0 adults">{room.adultsCount}</h6>
                    <button
                      type="button"
                      className="btn btn-link adult-add p-0 mb-0 btn-bi-color"
                      onClick={() => handleAdultChange(roomIndex, true)}
                    >
                      <i className="bi bi-plus-circle fs-5 fa-fw"></i>
                    </button>
                  </div>
                </li>

                <li className="d-flex justify-content-between mt-2">
                  <div>
                    <h6 className="mb-0">Children</h6>
                    <small>0 to 17 years old</small>
                  </div>

                  <div className="hstack gap-1 align-items-center">
                    <button
                      type="button"
                      className="btn btn-link room-remove p-0 mb-0 btn-bi-color"
                      onClick={() => subtractKid(roomIndex)}
                    >
                      <i className="bi bi-dash-circle fs-5 fa-fw"></i>
                    </button>
                    <h6 className="guest-selector-count mb-0 rooms">{room.kidsAges.length}</h6>
                    <button
                      type="button"
                      className="btn btn-link kid-add p-0 mb-0 btn-bi-color"
                      onClick={() => addKid(roomIndex)}
                    >
                      <i className="bi bi-plus-circle fs-5 fa-fw"></i>
                    </button>
                  </div>
                </li>

                {room.kidsAges.map((age, kidIndex) => (
                  <li
                    key={kidIndex}
                    className="d-flex justify-content-between align-items-center mt-2"
                  >
                    <div>
                      <h6 className="mb-0 kids-age">Kid {kidIndex + 1} Age</h6>
                    </div>

                    <div className="hstack gap-1 align-items-center">
                      <select
                        defaultValue={1}
                        value={age}
                        onChange={(e) =>
                          handleKidAgeChange(roomIndex, kidIndex, parseInt(e.target.value, 10))
                        }
                        className="form-control-kidAges"
                      >
                        {Array.from({ length: 17 }, (_, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        className="btn btn-link kid-remove p-0 mb-0"
                        onClick={() => removeKid(roomIndex, kidIndex)}
                      >
                        <i className="bi bi-dash-circle fs-5 fa-fw"></i>
                      </button>
                    </div>
                  </li>
                ))}
                <li className="dropdown-divider"></li>
              </div>
            ))}
            <li className=" d-flex justify-content-end align-content-center">
              <button
                className="btn btn-link adult-add p-0 mb-0  d-flex justify-content-end align-content-center align-items-center"
                onClick={handleAddRoom}
              >
                <i className="bi bi-plus-circle fs-5 fa-fw btn-bi-color"></i>
                Add another room
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { GuestsRoomsSelector };
