import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';
import isEqual from 'lodash/isEqual';
import { CondoLocationsEnum, ICounterItem } from '@share/common-types';
import { CondoRequestTypeEnum } from '@share/common-types';
import { filtersInitialState, IFiltersState } from '@share/store/slices';
import { ICondosState } from '@share/store/slices';
import { TOP_AMENITIES } from '@constants';
import { SortTypes } from '@share/constants';

export const getAmenities = (allAmenities: ICounterItem[]): ICounterItem[] => {
  const res: ICounterItem[] = [];

  TOP_AMENITIES.forEach((amenity: string) => {
    const item = allAmenities.find(({ key }) => key.toLowerCase() === amenity.toLowerCase());

    if (item) {
      res.push(item);
    }
  });

  allAmenities.forEach((amenity: ICounterItem) => {
    if (res.every((item: ICounterItem) => item.key.toLowerCase() !== amenity.key.toLowerCase())) {
      res.push(amenity);
    }
  });

  return res;
};

export const isFiltersChanged = (
  prevFilters: IFiltersState,
  currentFilters: IFiltersState,
): boolean => {
  return !isEqual(prevFilters, currentFilters);
};

export const isFiltersEmpty = (currentFilters: IFiltersState): boolean => {  
  return isEqual(currentFilters, filtersInitialState);
};

export const getGetawaySortOptions = (): LabeledValue[] => {
  return [
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.low.to.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.high.to.price" /> },
  ];
};

export const getSortOptions = (hasQuick: boolean, isB2C: boolean, walletWalletSavings: boolean): LabeledValue[] => {
  const sorts = [];
  if (hasQuick) {
    sorts.push({ value: SortTypes.MostBooked, label: <FormattedMessage id="sort.most.booked" /> });
  }

  if (!isB2C || walletWalletSavings) {
    sorts.push({ value: SortTypes.BiggestSavingsPercentage, label: <FormattedMessage id="sort.biggest.percent" /> });
    sorts.push({ value: SortTypes.BiggestSavings, label: <FormattedMessage id="sort.biggest" /> });
  }

  sorts.push(
    { value: SortTypes.GuestRating, label: <FormattedMessage id="sort.guest" /> },
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.lowest.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.highest.price" /> },
    { value: SortTypes.DistanceAsc, label: <FormattedMessage id="sort.nearest" /> },
  );
  return sorts;
};

export const getCondoSortOptions = (condoState: ICondosState, isB2C: boolean, walletWalletSavings: boolean): LabeledValue[] => {
  const { cachedSelectedLocation, lastRequestType } = condoState;
  const isSpecificCondo: boolean =
    cachedSelectedLocation?.locationType === CondoLocationsEnum.GenericCondo;
  const isAnytime: boolean = lastRequestType === CondoRequestTypeEnum.Anytime;

  const options = [];

  if (!isB2C || walletWalletSavings) {
    options.push({ value: SortTypes.BiggestSavingsPercentage, label: <FormattedMessage id="sort.biggest.percent" /> });
    options.push({ value: SortTypes.BiggestSavings, label: <FormattedMessage id="sort.biggest" /> });
  }

  options.push(
    { value: SortTypes.MostPopular, label: <FormattedMessage id="most.popular" /> },
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.lowest.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.highest.price" /> },
  );

  if (isSpecificCondo) {
    options.push({ value: SortTypes.Nearest, label: <FormattedMessage id="sort.nearest" /> });
  }

  if (!isAnytime) {
    options.push({
      value: SortTypes.ClosestDateRange,
      label: <FormattedMessage id="closest.date.range" />,
    });
  }

  if (lastRequestType === CondoRequestTypeEnum.Flexible) {
    options.push({
      value: SortTypes.FarthestDateRange,
      label: <FormattedMessage id="farthest.date.range" />,
    });
  }

  if (isSpecificCondo && isAnytime) {
    return [
      { value: SortTypes.MostPopular, label: <FormattedMessage id="most.popular" /> },
      { value: SortTypes.Nearest, label: <FormattedMessage id="sort.nearest" /> },
    ];
  }

  return options;
};
