import React from 'react';
import moment from 'moment';

const InformationCard = ({ searchHotels }) => {
  const {
    titleImage,
    name,
    address,
    city,
    state,
    zipCode,
    checkIn,
    checkOut,
    adultCount,
    roomsCount,
    roomsTitle,
  } = searchHotels?.bookingCard || {};

  const checkOutDateInput = checkOut;
  const checkOutDate = moment(checkOutDateInput);
  const formattedCheckOutDate = checkOutDate.format('MMM D,YYYY');
  const formattedCheckOutTime = checkOutDate.format('h:mm a');

  const checkInDateInput = checkIn;
  const checkInDate = moment(checkInDateInput);
  const formattedCheckInDate = checkInDate.format('MMM D,YYYY');
  const formattedCheckInTime = checkInDate.format('h:mm a');

  const renderStars = (rating) => {
    const fullStars = Math.floor(parseFloat(rating));
    const halfStar = rating - fullStars >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;
    return (
      <>
        {Array.from({ length: fullStars }).map((_, index) => (
          <li key={index} className="list-inline-item me-0 small">
            <i className="bi bi-star-fill text-warning"></i>
          </li>
        ))}
        {halfStar === 1 && (
          <li className="list-inline-item me-0 small">
            <i className="bi bi-star-half text-warning"></i>
          </li>
        )}
        {Array.from({ length: emptyStars }).map((_, index) => (
          <li key={index} className="list-inline-item me-0 small">
            <i className="bi bi-star text-warning"></i>
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="card shadow">
      <div className="card-header p-4 border-bottom">
        <h4 className="mb-0">
          <i className="bi bi-building me-2"></i>Hotel Information
        </h4>
      </div>

      <div className="card-body p-4">
        <div className="card mb-4">
          <div className="row align-items-center">
            <div className="col-sm-6 col-md-3">
              <img src={titleImage} className="card-img" alt="card-image" />
            </div>

            <div className="col-sm-6 col-md-9">
              <div className="card-body pt-3 pt-sm-0 p-0">
                <h5 className="card-title">
                  <a href="#">{name}</a>
                </h5>
                {address && (
                  <p className="small mb-2">
                    <i className="bi bi-geo-alt me-2"></i>
                    {address}
                    {city ? `, ${city}` : ''}
                    {state ? `, ${state}` : ''}
                    {zipCode ? `, ${zipCode}` : ''}
                  </p>
                )}
                <ul className="list-inline mb-0">
                  {renderStars(searchHotels?.bookingCard?.stars)}
                  <li className="list-inline-item ms-2 h6 small fw-bold mb-0">
                    {searchHotels?.bookingCard?.stars} / 5.0
                  </li>
                </ul>
                {/* <ul className="list-inline mb-0">
                  <li className="list-inline-item me-0 small">
                    <i className="bi bi-star-fill text-warning"></i>
                  </li>
                  <li className="list-inline-item me-0 small">
                    <i className="bi bi-star-fill text-warning"></i>
                  </li>
                  <li className="list-inline-item me-0 small">
                    <i className="bi bi-star-fill text-warning"></i>
                  </li>
                  <li className="list-inline-item me-0 small">
                    <i className="bi bi-star-fill text-warning"></i>
                  </li>
                  <li className="list-inline-item me-0 small">
                    <i className="bi bi-star text-warning"></i>
                  </li>
                  <li className="list-inline-item ms-2 h6 small fw-bold mb-0">
                    {searchHotels?.bookingCard?.stars} /5.0
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-lg-4">
            <div className="bg-light py-3 px-4 rounded-3">
              <h6 className="fw-light small mb-1">Check-in</h6>
              <h6 className="mb-1 fw-medium">{formattedCheckInDate}</h6>
              <small>
                <i className="bi bi-alarm me-1"></i>
                {formattedCheckInTime}
              </small>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="bg-light py-3 px-4 rounded-3">
              <h6 className="fw-light small mb-1">Check out</h6>
              <h6 className="mb-1 fw-medium">{formattedCheckOutDate}</h6>
              <small>
                <i className="bi bi-alarm me-1"></i>
                {formattedCheckOutTime}
              </small>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="bg-light py-3 px-4 rounded-3">
              <h6 className="fw-light small mb-1">Guests/Rooms</h6>
              <h6 className="mb-1 fw-medium">
                {adultCount} Guests - {roomsCount} Rm
              </h6>
              <small>
                <i className="bi bi-moon-stars-fill me-1"></i>{searchHotels?.bookingCard?.nightsCount} Nights
              </small>
            </div>
          </div>
        </div>
        {roomsTitle && (
          <div className="card border mt-4">
            <div className="card-header border-bottom d-md-flex justify-content-md-between">
              <h5 className="card-title mb-0">{roomsTitle}</h5>
              <h5 className="btn btn-link p-0 mb-0">{state}</h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InformationCard;
