/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Spin, Pagination } from 'antd';
import SideNav from '../../company-settings/side-nav/component';
import { AddTeamMember } from '../add-modal';
import useApi from '../../../../api-hook/component';
import { MEMBER_STATUS } from '../../../../constants/index';
import FilterableHeader from './filterable-header';
import UsersTable from './usersTable';
import { API_END_POINT, BASE_URL } from '../../../../constants/api-enums';

const CompanyTeamCard: React.FC = () => {
  const { fetchData } = useApi(BASE_URL.API_BASE_URL);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setEditIsOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [dptRoles, setDptRoles] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [filter, setFilter] = useState(MEMBER_STATUS.ALL);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const fetchUserData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const result = await fetchData(
        `${API_END_POINT.GET_TEAM_MEMBERS}?orderBy=userId&sortOrder=desc&page=${page}&results=${pageSize}`,
        {},
        'get',
      );
      const getRoles = await fetchData(
        `${API_END_POINT.GET_USER_ROLES}?page=1&results=1000`,
        {},
        'get',
      );
      setUserData(result.items);
      setTotalItems(result?.totalPages);
      setDptRoles(getRoles);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSaveSuccess = async () => {
    setLoading(true);
    try {
      await fetchUserData(currentPage, pageSize);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = userData?.filter((user: any) => {
    if (filter === MEMBER_STATUS?.ACTIVE) {
      return user.state === MEMBER_STATUS.ACTIVESTATE;
    } else if (filter === MEMBER_STATUS.INACTIVE) {
      return user.state !== MEMBER_STATUS?.ACTIVESTATE;
    }
    return true;
  });

  const handleEdit = (user) => {
    setEditIsOpen(true);
    setSelectedUser(user);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchUserData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <>
      {isOpen && (
        <AddTeamMember
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dptRoles={dptRoles}
          onSaveSuccess={handleSaveSuccess}
        />
      )}
      {isEditOpen && (
        <AddTeamMember
          isOpen={isEditOpen}
          setIsOpen={setEditIsOpen}
          dptRoles={dptRoles}
          userData={selectedUser}
          onSaveSuccess={handleSaveSuccess}
        />
      )}
      <section className="pt-3">
        <div className="container">
          <div className="row">
            <SideNav />
            <div className="col-lg-8 col-xl-9">
              <div className="card shadow-sm border">
                <div className="card-header bg-transparent border-bottom">
                  <h4 className="card-header-title">
                    <i className="bi bi-people mx-2"></i>Team Members
                  </h4>
                </div>
                <div className="card-body p-2 p-sm-4">
                  <FilterableHeader filter={filter} setFilter={setFilter} setIsOpen={setIsOpen} />

                  <div className="row mt-4">
                    <hr />

                    {loading ? (
                      <Spin />
                    ) : filteredData.length > 0 ? (
                      <>
                        <UsersTable
                          filteredData={filteredData}
                          handleEdit={handleEdit}
                          loading={loading}
                        />
                        <Pagination
                          current={currentPage}
                          pageSize={pageSize}
                          total={totalItems}
                          onChange={handlePageChange}
                          showSizeChanger
                          pageSizeOptions={['10', '25', '50', '100']}
                          className="paginations-page-size"
                        />
                      </>
                    ) : (
                      <div className="text-center">No data available for selected filter.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyTeamCard;
