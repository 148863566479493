import React, { useState } from "react";
import img from "@assets/images/element/forgot-pass.svg";

interface Props {}

const TwoFactorAuth: React.FC<Props> = () => {
  const [code, setCode] = useState<string[]>(Array(6).fill(""));

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);
  };

  const handleResendCode = () => {
    // Handle resend code logic here
  };

  const handleVerifyAndProceed = () => {
    // Handle verification logic here
  };

  return (
    <section className="vh-xxl-100">
      <div className="container h-100 d-flex px-0 px-sm-4">
        <div className="row justify-content-center align-items-center m-auto">
          <div className="col-12">
            <div className="shadow bg-mode rounded-3 overflow-hidden">
              <div className="row g-0 align-items-center">
                <div className="col-lg-6 d-md-flex align-items-center order-2 order-lg-1">
                  <div className="p-3 p-lg-5">
                    <img src={img} alt="ImageTwoFactor" />
                  </div>
                  <div className="vr opacity-1 d-none d-lg-block"></div>
                </div>

                <div className="col-lg-6 order-1">
                  <div className="p-4 p-sm-7">
                    <h1 className="mb-2 h3">Two factor authentication</h1>
                    <p className="mb-sm-0">
                      We have to send a code to <b>example@gmail.com</b>
                    </p>

                    <form className="mt-sm-4">
                      <p className="mb-1">Enter the code we have sent you:</p>
                      <div className="autotab d-flex justify-content-between gap-1 gap-sm-3 mb-2">
                        {code?.map((digit, index) => (
                          <input
                            key={index}
                            type="number"
                            inputMode="numeric"
                            maxLength={1}
                            className="form-control text-center p-3"
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                          />
                        ))}
                      </div>

                      <div className="d-sm-flex justify-content-between small mb-4">
                        <span>Didn't get a code?</span>
                        <button
                          type="button"
                          className="btn btn-sm btn-link p-0 text-decoration-underline mb-0"
                          onClick={handleResendCode}
                        >
                          Click to resend
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-0"
                          onClick={handleVerifyAndProceed}
                        >
                          Verify and Proceed
                        </button>
                      </div>

                      <div className="text-primary-hover mt-3 text-center">
                        Copyright ©2024 TravBusiness.
                      </div>
                    </form>
                    {/* Form END */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoFactorAuth;
