import * as H from 'history';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

import { IAccount, IClientCash, IPublicPrice, IUserWallet } from '@share/common-types';
import { IFullPublicPrice } from '@common-types';
import { countriesCode } from '@constants';
import { getHotelRoutePath, UrlUtils } from '@share/utils';
import { ROOMS_SEARCH_LABEL, SESSION_KEY_LABEL_ROOMS } from '@share/constants';

export const getTopSuppliers = (
  prices: IPublicPrice[] = []
): IFullPublicPrice[] => {
  const res: IFullPublicPrice[] = [];
  const currentFullPrices: IFullPublicPrice[] = prices
    .map(({ price, supplierId }) => {
      return {
        price,
        supplierId,
      };
    })
    .filter((item) => item !== undefined);

  currentFullPrices.forEach((fullPrice: IFullPublicPrice) => {
    if (res.every((price: IFullPublicPrice) => price?.supplierId !== fullPrice?.supplierId)) {
      res.push(fullPrice);
    }
  });

  return res;
};

export const openHotelPage = (hotelId: number, history: H.History<H.LocationState>, disableNewTabOpen: boolean, isTravelAgent?: boolean, isVacationRentals?: boolean, isForceIframe?: boolean): void => {
  UrlUtils.removeFromUrl(ROOMS_SEARCH_LABEL);
  UrlUtils.removeFromUrl(SESSION_KEY_LABEL_ROOMS);

  if (isTravelAgent || isForceIframe) {
    history.push(getHotelRoutePath(hotelId, history, isVacationRentals));
  } else {
    window.open(getHotelRoutePath(hotelId, history, isVacationRentals), disableNewTabOpen ? '_self' : '_blank');
  }
};

export const isNotEmpty = (data: string): boolean => {
  return !isUndefined(data) && !isEmpty(data);
};

export const getFullAddress = (
  address: string,
  city: string,
  state: string,
  zipCode: string,
  countryCode: string,
  countryName = '',
): string[] => {
  const defaultBehaviorItems: string[] = [address, city, state, zipCode, countryName];
  const fullAddress: string[] = [];

  defaultBehaviorItems.forEach((item: string) => {
    if (isNotEmpty(item)) {
      fullAddress.push(item);
    }
  });

  if (isNotEmpty(countryCode) && countryName === '') {
    const country = countriesCode.find(
      ({ isoCode }) => isoCode.toLowerCase() === countryCode.toLowerCase(),
    );

    if (country) {
      fullAddress.push(country.name);
    } else {
      fullAddress.push(countryCode);
    }
  }

  return fullAddress;
};

const FREE_BREAKFAST = 'Free Breakfast';

const freeBreakfasts = [
  FREE_BREAKFAST,
  'Free Breakfast for 2',
  'American Breakfast',
  'Buffet Breakfast',
  'Continental Breakfast',
  'Continental Breakfast, Free Breakfast'
]

export const isFreeBreakfastMealPlan = (mealPLan: string) => {
  return freeBreakfasts.includes(mealPLan);
}

export const isBreakfastMealPlan = (mealPLan: string) => {
  return mealPLan !== FREE_BREAKFAST && freeBreakfasts.includes(mealPLan);
}

export const isAllInclusivetMealPlan = (mealPLan: string) => {
  return mealPLan === 'All Inclusive' || mealPLan === 'All Inclusive, Spa Credit';
}

export enum WalletPriceType {
  Search,
  Details,
  Book,
};

export const getWalletPrices = (account: IAccount, userWalletData: IUserWallet, selectedClientCash: IClientCash, price: number, maxClientCash: number, type: WalletPriceType) => {
  const walletNoDecimals = account?.walletNoDecimals;
  const walletWalletSavings = account?.walletWalletSavings;
  const isMLM = account?.isMLM;
  const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
  
  const maxClientCashAmounParam = (walletWalletSavings && !isMLM) ? Math.floor(maxClientCash) : maxClientCash;

  const priceCalculated = Math.floor(price * 100) / 100;

  const maxClientCashPercentageAllow = userWalletData?.maxClientCashPercentageAllow ? userWalletData?.maxClientCashPercentageAllow : 1;
  const maxClientCashAmountAllowInt = priceCalculated * maxClientCashPercentageAllow;
  const maxClientCashAmountAllow = ((maxClientCashAmounParam || (maxClientCashAmounParam === 0 && walletWalletSavings)) && maxClientCashAmounParam < maxClientCashAmountAllowInt) ? maxClientCashAmounParam : maxClientCashAmountAllowInt;

  const maxClientCashAllowCalculatedData = maxClientCashAmountAllow / convertionRate;
  const maxClientCashAllowCalculated = walletNoDecimals ? Math.floor(maxClientCashAllowCalculatedData) : maxClientCashAllowCalculatedData;

  const maxClientCashAllow = walletNoDecimals ? Math.floor(maxClientCashAllowCalculated) : maxClientCashAllowCalculated;

  const selectedSearchClientCash = selectedClientCash?.selectedSearchClientCash ? selectedClientCash?.selectedSearchClientCash : 0;
  const selectedPropertyClientCash = selectedClientCash?.selectedPropertyClientCash ? selectedClientCash?.selectedPropertyClientCash : 0;
  const selectedPropertyReivewClientCash = selectedClientCash?.selectedPropertyReviewClientCash ? selectedClientCash?.selectedPropertyReviewClientCash : 0;
  const clientCash = type === WalletPriceType.Details ? 
                        selectedPropertyClientCash : 
                        type === WalletPriceType.Book ? 
                          selectedPropertyReivewClientCash :
                          selectedSearchClientCash;
  const clientCashAppliedCalculated = walletNoDecimals ? Math.floor(clientCash) : clientCash;
  const clientCashAmountCalculated = clientCashAppliedCalculated * convertionRate;
  const clientCashApplied = clientCashAmountCalculated > maxClientCashAmountAllow ? maxClientCashAllow : clientCashAppliedCalculated;
  const clientCashAmountAppliedInt = clientCashAmountCalculated > maxClientCashAmountAllow ? maxClientCashAmountAllow : clientCashAmountCalculated;
  const clientCashAmountApplied = clientCashAmountAppliedInt;
  const priceFinal = priceCalculated - (clientCashAmountApplied ? clientCashAmountApplied : 0);
  const priceFinalMax = priceCalculated - (maxClientCashAmountAllow ? maxClientCashAmountAllow : 0);
  const priceSavings = priceCalculated ? Math.floor(maxClientCashAmountAllow / priceCalculated * 100) : 0;

  const maxClientCashPermited = maxClientCashAllow > userWalletData?.balance ? userWalletData?.balance : maxClientCashAllow;
  const maxClientCashAmountPermited = maxClientCashPermited * convertionRate;

  return { clientCashApplied, clientCashAmountApplied, maxClientCashAmountAllow, maxClientCashAllow, maxClientCashAmountPermited, maxClientCashPermited, priceFinal, priceFinalMax, convertionRate, priceSavings }; 
}