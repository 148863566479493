import React from 'react';
import ReactGA from 'react-ga4';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import {
  getHotelPopularDestinationsOffers,
  IPopularDestinationsOffersState,
} from '@store/slices';
import {
  datesActions,
  filtersActions,
  ILoginState,
  locationActions,
  roomsActions
} from '@share/store/slices';
import { getNormalizedRoomsResponse, getSelectedCurrency, RootState, UrlUtils } from '@share/utils';
import { ILocation, IRoom, IRoomResponse } from '@share/common-types';
import { CarouselSlider, Currency } from '@components';

import { HotelSearchArgs, IHotelPopularDestinationsOffers } from '@common-types';
import { DATE_FORMAT, FILTERS_LABEL, H_CLICK_TOP_DEAL, Routes } from '@share/constants';
import { DATE_ONLY_MONTH_FORMAT } from '@share/constants';
import { getAccountDateFnsLocale } from '@i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

import Discount from '@assets/images/discount-red.png';

import './style.scss';

interface IMapStateToProps {
  popularDestinationsOffersStore: IPopularDestinationsOffersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setRooms: (rooms: IRoom[]) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  resetFilters: () => void;
  clearDatesError: () => void;
  clearLocationError: () => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (label: string) => void;

  setIsFocused: (value: boolean) => void;

  applyDates: () => void;
  applyRooms: () => void;
  applyLocation: () => void;

  getHotelPopularDestinationsOffers: (account: any) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

interface IState {
  selectedDestination: number;
}

class PopularDestinationOffersComponent extends React.Component<IProps, IState> {
  state: IState = { selectedDestination: 0 };

  componentDidMount() {
    this.props.getHotelPopularDestinationsOffers(this.props.loginStore.account);
  }

  handleSelectDestination = (selectedDestination: number) => {
    this.setState({ selectedDestination });
  }

  handleSearchHandler: HotelSearchArgs = (selectedLocation, startDate, endDate, rooms, hotelCode) => {
    const {
      setDatesSelected,
      setRooms,
      resetFilters,
      clearDatesError,
      clearLocationError,
      setSelectLocation,
      setSelectLocationLabel,
      applyDates,
      applyRooms,
      applyLocation,
      setIsFocused,
      loginStore,
      history
    } = this.props;
    const { account } = loginStore;

    clearDatesError();
    clearLocationError();
    setSelectLocationLabel(selectedLocation.name);
    setSelectLocation(selectedLocation);

    resetFilters();
    UrlUtils.setUrl(FILTERS_LABEL, null);
    
    setRooms(getNormalizedRoomsResponse(rooms));

    if (startDate && endDate) {
      setDatesSelected({ startDate, endDate });

      applyDates();
      applyRooms();
      applyLocation();
  
      history.push(`/${account.name}${Routes.Hotel}/${hotelCode}${location.search}`);
    } else {
      setIsFocused(true);
    }

    ReactGA.event({
      category: account.name,
      action: `${H_CLICK_TOP_DEAL}_${account.name.toUpperCase()}`,
      label: `User clicked top deal`,
      nonInteraction: false,
    });

  };

  renderDestinationSelection = () => {
    const { popularDestinationsOffersStore, loginStore } = this.props;
    const { popularDestinationsOffers } = popularDestinationsOffersStore;
    const { selectedDestination } = this.state;

    const destinations: any[] = popularDestinationsOffers.slice(0, 3).map((offer) => offer.location.name.split(',')[0]);

    const account = loginStore?.account;
    const color: any = account ? account?.buttonColor : null;

    return (
      <div className="popular-destinations-offers__header">
        {destinations.map((d, i) => {
          const isSelectedDEstination = selectedDestination === i;
          const className = `header-title ${isSelectedDEstination ? 'selected' : 'not-selected'}`;
          const styleColor = (isSelectedDEstination && !isEmpty(color))? { background: color, borderColor: color } : {};  
          return <div key={i} className={className} onClick={() => this.handleSelectDestination(i)} style={styleColor}>{d}</div>;
        })}
      </div>
    );
  }

  render() {
    const { selectedDestination } = this.state;
    const { popularDestinationsOffersStore, loginStore } = this.props;
    const { popularDestinationsOffers, loading } = popularDestinationsOffersStore;
    const { account } = loginStore;

    const currency = getSelectedCurrency(account);
    const destination: IHotelPopularDestinationsOffers = get(popularDestinationsOffers, `[${selectedDestination}]`, null);
    const slidesToShow = (!destination?.properties || destination?.properties?.length >= 4) ? 4 : destination.properties.length;

    if (destination && !loading) {
  
      const rooms = (destination?.rooms && typeof destination?.rooms !== 'string')? destination?.rooms : [{ adultsCount: 2, kidsAges: [] }] as IRoomResponse[];

      return (
        <div className={`popular-destinations-offers ${destination.properties.length + 1}`}>
          <p className="popular-destinations-offers__title">
            <FormattedMessage id="destination.cities.offers" />
          </p>
          <p className="popular-destinations-offers__message">
            <FormattedMessage id="destination.cities.offers.message" />
          </p>
          
          {this.renderDestinationSelection()}

          <div className={`popular-destinations-offers__wrapper properties_size_${destination.properties.length}`}>
            <CarouselSlider lazyLoad="progressive" slidesToShow={slidesToShow}>
              {destination.properties.map((property, index) => {
                const checkIn = moment(property?.checkIn);
                const checkOut = moment(property?.checkOut);

                const locale = getAccountDateFnsLocale(account);

                const formattedStartDate = property?.checkIn? format(new Date(property?.checkIn), (checkIn.year() !== checkOut.year())? DATE_FORMAT : DATE_ONLY_MONTH_FORMAT, { locale }) : null;
                const formattedEndDate = property?.checkOut? format(new Date(property?.checkOut), DATE_FORMAT, { locale }) : null;

                const stars = Math.abs(property.stars);
                const decimals = stars - Math.floor(stars);
                const startFull = Math.floor(stars)
                const startHalf = decimals > 0 ? 1 : 0;
                
                return (
                  <div key={index} 
                      className="popular-destinations-offers__destination-wrapper"
                      onClick={() => this.handleSearchHandler(property.location, property?.checkIn, property?.checkOut, rooms, property?.location?.code)}>
                    <div className="popular-destinations-offers__image-wrapper">
                      {!!property.savings && (<div className="savings" style={{ backgroundImage: `url(${Discount})`}}>
                        <span className="percentage-number">{property.savings}</span>
                        <span className="percentage">% <FormattedMessage id={'popular.destination.off'} /></span>
                      </div>)}

                      <div className="stars-container-background"></div>

                      <img src={property.image} alt={property.location.name} />

                      {(startFull > 0 || startHalf > 0) && (<div className="stars">
                        {Array.from(Array(startFull).keys()).map((s, i) => <FontAwesomeIcon key={i} icon={faStar} size={'1x'} />)}
                        {Array.from(Array(startHalf).keys()).map((s, i) => <FontAwesomeIcon key={i} icon={faStarHalf} size={'1x'} />)}
                      </div>)}

                      <div className="offer-price-container price-absolute">
                        <p className="offer-price-title"><FormattedMessage id={'popular.destination.best_rate'} /></p>
                        <p className="offer-price"><Currency currency={currency} /> {property.offerPrice}</p>
                      </div>
                    </div>
                    <div className="popular-destinations-offers__destination-info">
                      <p className="popular-destinations-offers__destination-name">{property.location.name}</p>
                      
                      {property.publicPrice ? (
                        <div className="popular-destinations-offers__destination-price">
                          <div className="public-price-container">
                            <p className="public-price-title"><FormattedMessage id={'popular.destination.public_internet_rate'} /></p>
                            <p className="public-price"><Currency currency={currency} /> {property.publicPrice}</p>
                          </div>
                        </div>) : null}

                      {formattedStartDate && formattedEndDate && (<p className="popular-destinations-offers__destination-dates">{formattedStartDate} - {formattedEndDate}</p>)}
                    </div>
                  </div>
                )})}
            </CarouselSlider>
          </div>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    popularDestinationsOffersStore: state.popularDestinationsOffersStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getHotelPopularDestinationsOffers,

  setRooms: roomsActions.setRooms,
  setDatesSelected: datesActions.setDatesSelected,
  resetFilters: filtersActions.resetFilters,
  clearDatesError: datesActions.setError,
  clearLocationError: locationActions.setError,

  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setSelectLocation: locationActions.setSelectLocation,

  setIsFocused: datesActions.setIsFocused,

  applyDates: datesActions.applyDates,
  applyRooms: roomsActions.applyRooms,
  applyLocation: locationActions.applyLocation,

};

export const PopularDestinationOffers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PopularDestinationOffersComponent));
