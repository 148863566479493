
import { IGeoLocation, IReservationCancellationFees } from '@share/common-types';

export enum BookingStatusEnum {
  Confirmed = 'confirmed',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Draft = 'draft',
  Pending = 'pending',
  CancellationInProgress = 'cancellationInProgress',
  CancellationFailed = 'cancellationFailed',
}

export enum BookingSubStatusEnum {
  Unknown = 'unknown',
  AuthorizeFailed = 'authorize',
}

export enum HotelBookingStatusEnum {
  Confirmed = 'Confirmed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Pending = 'Pending',
  InProgress = 'In progress',
}

export enum HotelBookingRefundabilityEnum {
  Unknown = 'unknown',
  Refundable = 'refundable',
  Nonrefundable = 'nonrefundable',
}

export interface IBookingSubStatus {
  stage: string;
  status: string;
  displayValue: string;
  release: string;
  void: string;
}

export interface IAdminBookingCondo {
  condoId: number;
  displayName: string;
  heroImageUrl: string;
  address: string;
  city: string;
  country: string;
  stateOrProvince: string;
  fullAddress: string;
  postalCode: string;
  postalCodeExtension: string;
  emails: string[];
  phones: string[];
}

export interface IAdminBookingUnit {
  name: string;
  description: string;
  maxOccupancy: number;
  privacyOccupancy: number;
  bathroom?: string;
  bedroomType: string;
  kitchenType: string;
  bathroomDescription: string;
  bathroomAccessible: boolean;
}

export interface IAdminBookingGuest {
  givenName: string;
  namePrefix: string;
  surname: string;
  countryCode: string;
  phoneCountryCode: string;
  phone: string;
  email: string;
  additionalEmail: string;
  country: string;
  stateCode: string;
  city: string;
  zipCode: string;
  addressLine1: string;
  addressLine2: string;
  isBookingForSomeoneElse: boolean;
}

export interface IAdminBookingHotelGuest {
  id: number;
  givenName: string;
  surname: string;
  fullName: string;
}

export interface IAdminBookingBasePrice {
  publicPrice: number;
  savings: number;
  couponAmount: number;
  payNowPrice: number;
  totalNetPrice: number;
  totalSupplierPrice: number;
  totalProfitAmount: number;
  commissionFeeAmount: number;
  totalLeisureCredits: number;
  totalTaxes?: number;
}

export interface IAdminBookingPrice extends IAdminBookingBasePrice {
  supplierPrice: number;
  profit: number;
  totalTaxes: number;
}

export interface IAdminHotelBookingPrice extends IAdminBookingBasePrice {
  totalSupplierTaxes: number;
  totalFarePrice: number;
  totalMarkupAmount: number;
  markup: number;
}

export interface IAdminBookingCardDetails {
  cardHolderName: string;
  cardNumber: string;
  cardType: string;
}

export interface IAdminBookingPolicies {
  checkInTime: string;
  checkOutTime: string;
  ageRestriction: string;
  pets: string;
  parking: string[];
  smoking: string[];
}

export interface IAdminBookingBeforeYouGo {
  generalUrgentInfoMessages: string[];
  generalUrgentInfoForNonMembersMessages: string[];
  occupancyRestrictions: string;
  specialNotesPrecautions: string;
  mandatoryFees: string[];
  fees: string[];
  housekeepingFees: string[];
}

export interface IAdminBookingTransactionsLog {
  id: number;
  transactionDate: string;
  pgId: string;
  authCode: string;
  purchaseId: string;
  status: string;
  type: string;
  completionStatus: string;
}

export interface IAdminBookingDetails {
  userId: number;
  orderId: number;
  adultsCount: number;
  childrenCount: number;
  nightsCount: number;
  supplierType: string;
  confirmationNumber: string;
  condo: IAdminBookingCondo;
  unit: IAdminBookingUnit;
  guest: IAdminBookingGuest;
  couponCode: string;
  bookingPrice: IAdminBookingPrice;
  netPrice: number;
  markup: number;
  cardDetails: IAdminBookingCardDetails;
  bookingPolicies: IAdminBookingPolicies;
  bookingBeforeYouGo: IAdminBookingBeforeYouGo;
  refundability: string;
  refundabilityText: string;
  cancellationPolicyText: string;
  transactionsLog: IAdminBookingTransactionsLog[];
  location: IGeoLocation;
  bookingId?: number;
  bookedOn: string;
  createDate: string;
  checkIn: string;
  checkOut: string;
  status: BookingStatusEnum;
  subStatuses: IBookingSubStatus[];
  balance: number;
  cancellationReason: string;
  cancellationDate: string;
}

export interface IGuestInfo {
  name: string;
  surname: string;
  email: string;
  phone: string;
  isMainGuest: boolean;
}

export interface IHotelModel {
  hotelId: number;
  name: string;
  phone: string;
  email: string;
  imageUrl: string;
  address: string;
  stars: number;
  city: string;
  state: string;
  location: IGeoLocation;
}

export interface IRoomModel {
  basis: string;
  type: string;
  name: string;
  guests: IGuestInfo[];
}

export interface IRefundTransaction {
  referenceTransactionId: string;
  transactionId: string;
  amount: number;
  paymentGateway?: string;
  authCode?: string;
}

export interface IHotelSegment {
  segmentId: number;
  segmentStatus: string;
  rooms: IRoomModel[];
  transactionBase: ITransactionBase[];
  refundTransaction?: IRefundTransaction;
}

export interface ITransactionBase {
  paymentGateway: string;
  transactionId: string;
  amount: number;
  authCode: string;
  hotelSegmentId: number;
}

export interface IAdminHotelBookingDetails {
  orderId: number;
  numberOfNights: number;
  roomsCount: number;
  confirmationCode: string;
  supplierName: string;
  roomName: string;
  roomDescription: string;
  refundability: HotelBookingRefundabilityEnum;
  refundableUntil: string;
  adultsCount: number;
  childrenCount: number;
  refundabilityText: string;
  cancellationPoliciesText: string;
  cancellationReason?: string;
  bookingRemarks: string;
  mainGuest: IGuestInfo;
  averageNightPrice: number;
  priceDifference: number;
  couponCode: string;
  optionalFees: IReservationCancellationFees[];
  payAtPropertyFees: IReservationCancellationFees[];
  segments: IHotelSegment[];
  bookingPrice: IAdminHotelBookingPrice;
  hotelModel: IHotelModel;
  providerOrderId: number;
  status: HotelBookingStatusEnum;
  subStatus: string;
  checkIn: string;
  checkOut: string;
  bookedOn: string;
  cancellationDate?: string;
  failureReason?: string;
}

export interface IAdminBookings {
  condoName: string;
  totalFare: number;
  subStatuses: IBookingSubStatus[];
  status: BookingStatusEnum;
  checkIn: string;
  checkOut: string;
  orderId: number;
  bookedOn?: string;
  createDate: string;
  address: string;
  city: string;
  country: string;
  region: string;
  markup: number;
  supplierType: string;
  bookingPrice: IAdminBookingPrice;
  member: IAdminBookingsMember;
  unit: IAdminBookingUnit;
  guest: IAdminBookingGuest;
  cardDetails: IBookingsCardDetails;
  netPrice: number;
  supplierId: string;
  checkInTime: string;
  checkOutTime: string;
}

export interface IAdminHotelBookings {
  totalFare: number;
  providerOrderId: number;
  roomsCount: number;
  hotelName: string;
  orderId: number;
  room: IAdminBookingsHotelRoom;
  propertyAddress: IAddress;
  subStatus: string;
  status: HotelBookingStatusEnum;
  subStatuses: IBookingSubStatus[];
  checkIn: string;
  checkOut: string;
  bookedOn?: string;
  bookingPrice: IAdminHotelBookingPrice;
  member: IAdminBookingsHotelMember;
  guest: IAdminBookingHotelGuest;
  supplier: IAdminBookingsHotelSupplier;
}

export interface IBookingsCardDetails {
  cardHolderName: string;
  cardNumber: string;
  cardType: string;
}

export interface IAdminBookingsBaseMember {
  siteId: number;
  id: number;
}

export interface IAdminBookingsMember extends IAdminBookingsBaseMember {
  firstName: string;
  lastName: string;
}

export interface IAdminBookingsHotelMember extends IAdminBookingsBaseMember {
  name: string;
  surname: string;
  fullName: string;
}

export interface IAdminBookingsHotelSupplier {
  id: number;
  name: string;
  confirmationCode: string;
}

export interface IAdminBookingsHotelRoom {
  name: string;
  basis: string;
  type: string;
}

export interface IAddress {
  address: string;
  cityName: string;
  countryName: string;
}

export enum CondoBookingType {
  Order = 'order',
  Booking = 'booking',
}

export enum BookingTabsEnum {
  Condos = 'Condos',
  Hotels = 'Hotels',
}

export enum AccountInformationTabsEnum {
  AccountDetails = 'AccountDetails',
  AccountUsers = 'AccountUsers',
}

export enum BookingDetailsTabsEnum {
  OrderDetails = 'OrderDetails',
  PaymentDetails = 'Payment Details',
  Notes = 'Notes',
}

export enum BookingDetailsActionsEnum {
  AllActions = 'AllActions',
  MarkAsCancelled = 'MarkAsCancelled',
  SendReminder = 'SendReminder',
}

export enum BookingHotelDetailsActionsEnum {
  AllActions = 'AllActions',
  Cancel = 'Cancel',
  SendReminder = 'SendReminder',
}

export interface IAdminBookingsNotes {
  id: number;
  text: string;
  author: string;
  date: string;
  canEdit: boolean;
}

export enum BookingsOrderEnum {
  BookedOnAsc = 'bookedOnAsc',
  BookedOnDesc = 'bookedOnDesc',
  TotalFareAsc = 'totalFareAsc',
  TotalFareDesc = 'totalFareDesc',
  SupplierAsc = 'supplierAsc',
  SupplierDesc = 'supplierDesc',
  CondoNameAsc = 'condoNameAsc',
  CondoNameDesc = 'condoNameDesc',
  PropertyAddressAsc = 'propertyAddressAsc',
  PropertyAddressDesc = 'propertyAddressDesc',
  PropertyCityAsc = 'propertyCityAsc',
  PropertyCityDesc = 'propertyCityDesc',
  PropertyCountryAsc = 'propertyCountryAsc',
  PropertyCountryDesc = 'propertyCountryDesc',
  RegionAsc = 'regionAsc',
  RegionDesc = 'regionDesc',
  MaxSleepsAsc = 'maxSleepsAsc',
  MaxSleepsDesc = 'maxSleepsDesc',
  BathroomTypeAsc = 'bathroomTypeAsc',
  BathroomTypeDesc = 'bathroomTypeDesc',
  BedroomAsc = 'bedroomAsc',
  BedroomDesc = 'bedroomDesc',
  SupplierPriceAsc = 'supplierPriceAsc',
  SupplierPriceDesc = 'supplierPriceDesc',
  SupplierTaxesAsc = 'supplierTaxesAsc',
  SupplierTaxesDesc = 'supplierTaxesDesc',
  PublicPriceAsc = 'publicPriceAsc',
  PublicPriceDesc = 'publicPriceDesc',
  MarkupAsc = 'markupAsc',
  MarkupDesc = 'markupDesc',
  SavingsAsc = 'savingsAsc',
  SavingsDesc = 'savingsDesc',
  DiscountAsc = 'discountAsc',
  DiscountDesc = 'discountDesc',
  ProfitAsc = 'profitAsc',
  ProfitDesc = 'profitDesc',
  TotalPaidAsc = 'totalPaidAsc',
  TotalPaidDesc = 'totalPaidDesc',
  ConfirmationNumberAsc = 'confirmationNumberAsc',
  ConfirmationNumberDesc = 'confirmationNumberDesc',
  CheckinAsc = 'checkinAsc',
  CheckinDesc = 'checkinDesc',
  CheckoutAsc = 'checkoutAsc',
  CheckoutDesc = 'checkoutDesc',
  PrivacyOccupancyAsc = 'privacyOccupancyAsc',
  PrivacyOccupancyDesc = 'privacyOccupancyDesc',
  MemberIdAsc = 'memberIdAsc',
  MemberIdDesc = 'memberIdDesc',
  MemberSiteIdAsc = 'memberSiteIdAsc',
  MemberSiteIdDesc = 'memberSiteIdDesc',
  MemberNameAsc = 'memberNameAsc',
  MemberNameDesc = 'memberNameDesc',
  OrderIdAsc = 'orderIdAsc',
  OrderIdDesc = 'orderIdDesc',
  StatusAsc = 'statusAsc',
  StatusDesc = 'statusDesc',
  GuestNameAsc = 'guestNameAsc',
  GuestNameDesc = 'guestNameDesc',
}

export enum HotelBookingsOrderEnum {
  OrderIdAsc = 'orderIdAsc',
  OrderIdDesc = 'orderIdDesc',
  MemberSiteIdAsc = 'memberSiteIdAsc',
  MemberSiteIdDesc = 'memberSiteIdDesc',
  MemberIdAsc = 'memberIdAsc',
  MemberIdDesc = 'memberIdDesc',
  MemberNameAsc = 'memberNameAsc',
  MemberNameDesc = 'memberNameDesc',
  StatusAsc = 'statusAsc',
  StatusDesc = 'statusDesc',
  CheckinAsc = 'checkinAsc',
  CheckinDesc = 'checkinDesc',
  CheckoutAsc = 'checkoutAsc',
  CheckoutDesc = 'checkoutDesc',
  GuestNameAsc = 'guestNameAsc',
  GuestNameDesc = 'guestNameDesc',
  BookedOnAsc = 'bookedOnAsc',
  BookedOnDesc = 'bookedOnDesc',
  SupplierAsc = 'supplierAsc',
  SupplierDesc = 'supplierDesc',
  ConfirmationNumberAsc = 'confirmationNumberAsc',
  ConfirmationNumberDesc = 'confirmationNumberDesc',
  HotelNameAsc = 'hotelNameAsc',
  HotelNameDesc = 'hotelNameDesc',
  PropertyAddressAsc = 'propertyAddressAsc',
  PropertyAddressDesc = 'propertyAddressDesc',
  PropertyCityAsc = 'propertyCityAsc',
  PropertyCityDesc = 'propertyCityDesc',
  PropertyCountryAsc = 'propertyCountryAsc',
  PropertyCountryDesc = 'propertyCountryDesc',
  RoomCountAsc = 'roomCountAsc',
  RoomCountDesc = 'roomCountDesc',
  RoomNameAsc = 'roomNameAsc',
  RoomNameDesc = 'roomNameDesc',
  RoomBasisAsc = 'roomBasisAsc',
  RoomBasisDesc = 'roomBasisDesc',
  PublicPriceAsc = 'publicPriceAsc',
  PublicPriceDesc = 'publicPriceDesc',
  SupplierPriceAsc = 'supplierPriceAsc',
  SupplierPriceDesc = 'supplierPriceDesc',
  MarkupAsc = 'markupAsc',
  MarkupDesc = 'markupDesc',
  SavingsAsc = 'savingsAsc',
  SavingsDesc = 'savingsDesc',
  DiscountAsc = 'discountAsc',
  DiscountDesc = 'discountDesc',
  ProfitAsc = 'profitAsc',
  ProfitDesc = 'profitDesc',
  TotalPaidAsc = 'totalPaidAsc',
  TotalPaidDesc = 'totalPaidDesc',
}

export enum DateRangeFilterOptions {
  SpecificDates = 'SpecificDates',
  NextSevenDays = 'NextSevenDays',
  NextThirtyDays = 'NextThirtyDays',
}

export interface ISubStatus {
  displayValue: string;
  release: string;
  stage: string;
  status: string;
  void: string;
}

export interface IBookingsParamsBase {
  statuses?: string[];
  supplierTypes?: string[];
  subStatuses?: ISubStatus[];
  status?: string;
  bookingOrderBy?: string;
  searchQuery?: string;
  searchBy?: MatchedByEnum;
  filterOptions?: IFilterOptions;
}

export interface IBookingsParams extends IBookingsParamsBase {
  pageNumber: number;
  pageSize: number;
}

export interface IHotelBookingsParamsBase {
  statuses?: string[];
  suppliers?: string[];
  subStatuses?: ISubStatus[];
  status?: string;
  bookingOrderBy?: string;
  searchQuery?: string;
  searchBy?: MatchedByEnum;
  filterOptions?: IFilterOptions;
}

export interface IHotelBookingsParams extends IHotelBookingsParamsBase {
  pageNumber: number;
  pageSize: number;
}

export interface IFilterOptions {
  travelDatesRange?: IFilterDateRange;
  checkInDateRange?: IFilterDateRange;
  checkOutDateRange?: IFilterDateRange;
  bookedOnDateRange?: IFilterDateRange;
}

export interface IFilterDateRange {
  from: string;
  to: string;
}

export enum MatchedByEnum {
  BookingId = 'bookingId',
  OrderId = 'orderId',
  ConfirmationNumber = 'confirmationNumber',
  MemberInfo = 'memberInfo',
  GuestInfo = 'guestInfo',
  CondoName = 'condoName',
  MemberSiteId = 'memberSiteId',
  MemberId = 'memberId',
  CardInfo = 'cardInfo',
  Unspecified = 'unspecified',
  MemberName = 'memberName',
  GuestName = 'guestName',
  HotelName = 'hotelName',
}

export interface IAutocompleteResult {
  id?: string;
  condoName?: string;
  guestName?: string;
  memberName?: string;
  memberInfo?: string;
  guestInfo?: string;
  orderId?: number;
  confirmationNumber?: string;
  status?: BookingStatusEnum;
  matchedBy: MatchedByEnum;
}

export interface IHotelAutocompleteResult {
  id?: string;
  orderId?: number;
  supplierOrderId?: string;
  supplierOrderRefId?: string;
  confirmationNumber?: string;
  hotelName?: string;
  member?: IAdminBookingsHotelMember;
  guest?: IAdminBookingHotelGuest;
  matchedBy: MatchedByEnum;
}

export interface IExportCsv extends IBookingsParamsBase, IHotelBookingsParamsBase {
  fileName: string;
}

export interface IExportHotelBookingsCsv {
  fileName: string;
  resourceParameters: {
    bookingOrderBy: string;
    searchBy: MatchedByEnum;
    statuses: string[];
    suppliers: string[];
    searchQuery: string;
    filterOptions: IFilterOptions;
  };
}

