import React from 'react';
import { Select } from 'antd';
import { LabeledValue, RefSelectProps, SelectValue } from 'antd/lib/select';

import { DEFAULT_MEDIA_POINT } from '@share/constants';

import { ChevronSvg } from '@share/assets';

import './style.scss';

interface IProps {
  label?: React.ReactNode;
  options: LabeledValue[];
  onChange: (value: SelectValue) => void;
  value: number | string;
  placeHolder?: React.ReactNode;
  disabled?: boolean;
  closeOnScrollParentSelector?: string;
}

const defaultValue = 0;

export class CustomSelect extends React.Component<IProps, null> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  instance: RefSelectProps;

  isDesktop = (): boolean => {
    return window.matchMedia(`(min-width: ${DEFAULT_MEDIA_POINT}px)`).matches;
  };

  getPopupContainer = (): HTMLElement => {
    const { closeOnScrollParentSelector } = this.props;

    return this.wrapperRef && (!closeOnScrollParentSelector || !this.isDesktop())
      ? this.wrapperRef.current
      : document.body;
  };

  setInstance = (instance: RefSelectProps): void => {
    this.instance = instance;
  };

  setScrollHandler = (): void => {
    if (this.instance && this.isDesktop()) {
      this.instance?.blur();
    }
  };

  componentDidMount(): void {
    const { closeOnScrollParentSelector } = this.props;

    if (closeOnScrollParentSelector) {
      document
        .querySelector(closeOnScrollParentSelector)
        ?.addEventListener('scroll', this.setScrollHandler);
      window.addEventListener('scroll', this.setScrollHandler);
    }
  }

  componentWillUnmount(): void {
    const { closeOnScrollParentSelector } = this.props;

    if (closeOnScrollParentSelector) {
      document
        .querySelector(closeOnScrollParentSelector)
        ?.removeEventListener('scroll', this.setScrollHandler);
      window.removeEventListener('scroll', this.setScrollHandler);
    }
  }

  render(): React.ReactNode {
    const { label, value, onChange, options, placeHolder = '', disabled = false } = this.props;

    return (
      <div className="custom-select" ref={this.wrapperRef}>
        {label && <div className="custom-select__label">{label}</div>}
        <Select
          ref={this.setInstance}
          disabled={disabled}
          className="custom-select__select"
          value={value || defaultValue}
          onChange={onChange}
          virtual={false}
          defaultValue={defaultValue}
          suffixIcon={ChevronSvg}
          getPopupContainer={this.getPopupContainer}
          placeholder={placeHolder}
          defaultActiveFirstOption={false}
        >
          {options.map(({ label, value }) => {
            return (
              <Select.Option value={value} key={value}>
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }
}
