import React from 'react';
import { navItems } from "../../../../_mock-api's/index";
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ILoginState } from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
}

const SideNav = () => {
  const location = useLocation();
  const login = useSelector((state: IMapStateToProps) => state.loginStore);
  const isActive = (currentPage: string): string => {
    return location.pathname === `/${currentPage}` ? 'active' : '';
  };

  return (
    <div className="col-lg-4 col-xl-3">
      <div className="offcanvas-lg offcanvas-end" tabIndex={-1} id="offcanvasSidebar">
        <div className="offcanvas-header justify-content-end pb-2">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#offcanvasSidebar"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body p-3 p-lg-0">
          <div className="card bg-light w-100">
            <div className="card-body p-3">
              <div className="text-center mb-3">
                <h5 className="mb-0">Company Settings</h5>
                <hr />
              </div>

              <ul className="nav nav-pills-primary-soft flex-column">
                {navItems?.map((item) => (
                  <li className="nav-item" key={item.href}>
                    <Link
                      className={`nav-link ${isActive(item.href)}`}
                      to={`/${login.account.name}/${item.href}`}
                    >
                      <i className={`bi ${item.icon} fa-fw me-2`}></i>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="d-grid mb-0 d-lg-none w-100">
        <button
          className="btn btn-primary mb-4"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          <i className="fas fa-sliders-h"></i> Account Menu
        </button>
      </div>
    </div>
  );
};

export default SideNav;
