import React from 'react';

export const RefreshSvg: React.FunctionComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0912 2.59464L14.0841 3.38214C12.7109 1.62679 10.5751 0.5 8.17693 0.5C4.03586 0.5 0.684074 3.84821 0.678717 7.99107C0.67336 12.1375 4.03229 15.5 8.17693 15.5C11.4144 15.5 14.1734 13.4464 15.2234 10.5696C15.2501 10.4946 15.2109 10.4107 15.1359 10.3857L14.1234 10.0375C14.0881 10.0254 14.0494 10.0276 14.0157 10.0436C13.982 10.0596 13.9559 10.0882 13.943 10.1232C13.9109 10.2125 13.8751 10.3018 13.8376 10.3893C13.5287 11.1214 13.0859 11.7786 12.5216 12.3429C11.9618 12.9036 11.2991 13.3511 10.5698 13.6607C9.81443 13.9804 9.00907 14.1429 8.1805 14.1429C7.35015 14.1429 6.54657 13.9804 5.79122 13.6607C5.06117 13.3524 4.39822 12.9048 3.83943 12.3429C3.27818 11.7832 2.83119 11.1197 2.52336 10.3893C2.20372 9.63214 2.04122 8.82857 2.04122 7.99822C2.04122 7.16786 2.20372 6.36429 2.52336 5.60714C2.83229 4.875 3.27515 4.21786 3.83943 3.65357C4.40372 3.08929 5.06086 2.64643 5.79122 2.33571C6.54657 2.01607 7.35193 1.85357 8.1805 1.85357C9.01086 1.85357 9.81443 2.01607 10.5698 2.33571C11.2998 2.64401 11.9628 3.09164 12.5216 3.65357C12.6984 3.83036 12.8644 4.01786 13.018 4.21429L11.943 5.05357C11.9217 5.07003 11.9055 5.09215 11.8963 5.1174C11.887 5.14265 11.8851 5.16999 11.8907 5.1963C11.8963 5.2226 11.9092 5.24679 11.9279 5.26608C11.9467 5.28537 11.9704 5.29899 11.9966 5.30536L15.1323 6.07322C15.2216 6.09464 15.3091 6.02679 15.3091 5.93572L15.3234 2.70536C15.3216 2.5875 15.1841 2.52143 15.0912 2.59464Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);
