import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { RootState } from '@share/utils';
import { getGetawaysDetails, getawaysDetailsActions, IGetawaysDetailsState } from '@store/slices';
import { GetawayDetailsInfo } from '../getaway-details-info';
import { GetawayDetailsSkeleton } from '../getaway-details-skeleton/component';
import './style.scss';

const FOUR = 4;
const FIVE = 5;

interface IMapStateToProps {
  getawaysDetailsStore: IGetawaysDetailsState;
}

interface IMapDispatchToProps {
  getGetawaysDetails: (getaway: string, promoId: string) => void;
  setDefaultValues: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

export class GetawayDetailsWrapperComponent extends React.Component<IProps, null> {
  componentDidMount(): void {
    const { getGetawaysDetails } = this.props;
    const promoId = location.pathname.split('/')[FOUR];
    const activeGetaway = location.pathname.split('/')[FIVE];

    getGetawaysDetails(activeGetaway, promoId);
  }

  componentWillUnmount(): void {
    const { setDefaultValues } = this.props;

    setDefaultValues();
  }

  render(): React.ReactNode {
    const { getawaysDetailsStore } = this.props;
    const { getaway, error } = getawaysDetailsStore;

    return (
      <div className="getaway__details">
        {getaway ? (
          <GetawayDetailsInfo getaway={getaway} />
        ) : error ? (
          <div className="getaway__details-error">
            <FormattedMessage id="getaway.message" />
          </div>
        ) : (
          <GetawayDetailsSkeleton />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawaysDetailsStore: state.getawaysDetailsStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getGetawaysDetails: (getaway, promoId) => {
    dispatch(getGetawaysDetails(getaway, promoId));
  },
  setDefaultValues: () => {
    dispatch(getawaysDetailsActions.setDefaultValues());
  },
});

export const GetawayDetailsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetawayDetailsWrapperComponent);
