import React from 'react';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { faqMarkDownContent, MarkDownEnum } from '@common-types';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}
interface IProps extends IMapStateToProps, WrappedComponentProps {
  messageId: MarkDownEnum;
}

class MarkdownComponent extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { intl, messageId, menuStore } = this.props;
    const phone = menuStore.items?.phone;
    const isMLM = menuStore.items?.isMLM;

    return (
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {intl.formatMessage(
          { id: messageId },
          {
            phone: (phone || isMLM) && faqMarkDownContent(phone),
          },
        )}
      </ReactMarkdown>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const Markdown = connect(mapStateToProps, null)(injectIntl(MarkdownComponent));

