
import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { CONDO_SPECIALS_LINK } from '@constants';
import { BlueButton } from '@share/components';
import { IBenefitsState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { getBenefitInfo } from '@share/utils';
import { BenefitsEnum, IBenefits, IBenefitsItems } from '@share/common-types';

import CondoSpecialsBackground from '@share/assets/images/condo/bg-condo-specials.png';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {}

const ZERO = 0;

export class CondoSpecialsComponent extends React.Component<IProps, null> {
  getSpecialsInfo = (benefits: IBenefits): IBenefitsItems[] => {
    return getBenefitInfo(benefits, BenefitsEnum.Condos)[0].childItems?.filter((item) =>
      item.url.indexOf('specials'),
    );
  };

  render(): React.ReactNode {
    const { menuStore, benefitsStore } = this.props;

    return benefitsStore.benefits?.items ? (
      <div className="condo-specials-page">
        <div
          className="condo-specials-page__header-wrapper"
          style={{ backgroundImage: `url(${CondoSpecialsBackground})` }}
        >
          <div className="condo-specials-page__wrapper">
            <div className="condo-specials-page__header-title">
              <FormattedMessage id="condo.specials.title" />
            </div>
          </div>
        </div>
        <div className="condo-specials-page__wrapper condo-specials-wrapper">
          <div className="condo-specials-page__img-wrapper">
            <img
              src={this.getSpecialsInfo(benefitsStore.benefits)[ZERO].imageUrl}
              alt={this.getSpecialsInfo(benefitsStore.benefits)[ZERO].title}
            />
          </div>
          <div className="condo-specials-page__content">
            <p className="condo-specials-page__description">
              <FormattedMessage id="we.will.bring.you.list.of.deeply.discounted.week.stays" />
            </p>
            <p className="condo-specials-page__description">
              <FormattedMessage id="book.on.your.member.website.under" />
            </p>
            <a
              href={CONDO_SPECIALS_LINK}
              target="_blank"
              rel="noreferrer"
              className="condo-specials-page__btn-wrapper"
            >
              <BlueButton>
                <FormattedMessage id="view.current.condo.specials" />
              </BlueButton>
            </a>
            <p className="condo-specials-page__contact">
              <FormattedMessage id="call.or.search.your.member.website" />
              <a
                href={`tel:${menuStore.items?.phone}`}
                className="condo-specials-page__call-number"
              >
                {menuStore.items?.phone}
              </a>
            </p>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
  };
};

export const CondoSpecialsPage = connect(mapStateToProps, null)(CondoSpecialsComponent);
