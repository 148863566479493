import React from "react";
import { MEMBER_STATUS } from "../../../../constants/index.ts";

const FilterableHeader = (props) => {
  const { filter, setFilter, setIsOpen } = props;
  return (
    <div className="row g-4 justify-content-between align-items-center">
      {/* Filter options */}
      <div className="col-lg-8">
        <ul className="nav nav-pills-shadow nav-responsive">
          <li className="nav-item">
            <a
              className={`nav-link mb-0 me-sm-2 ${
                filter === MEMBER_STATUS.ALL && MEMBER_STATUS.ACTIVE
              }`}
              onClick={() => setFilter(MEMBER_STATUS.ALL)}
            >
              All
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link mb-0 me-sm-2 ${
                filter === MEMBER_STATUS.ACTIVE && MEMBER_STATUS.ACTIVE
              }`}
              onClick={() => setFilter(MEMBER_STATUS.ACTIVE)}
            >
              Active
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link mb-0 ${
                filter === MEMBER_STATUS.INACTIVE && MEMBER_STATUS.ACTIVE
              }`}
              onClick={() => setFilter(MEMBER_STATUS.INACTIVE)}
            >
              Locked
            </a>
          </li>
        </ul>
      </div>
      {/* Add New button */}
      <div className="col-lg-4 col-xxl-3">
        <button
          type="button"
          className="btn btn-primary mb-0 float-end"
          onClick={() => setIsOpen(true)}
        >
          <i className="bi bi-person-plus me-2"></i>Add New
        </button>
      </div>
    </div>
  );
};

export default FilterableHeader;
