import React from 'react';
import Hero from '../../components/new-home/hero/hero';
import {
  SolutionTabs,
  CompanyTravels,
  Register,
  Features,
  Brands,
  Reviews,
  PopularDestinations,
  HomeFooter,
} from '../../components/new-home';

const Home = () => {
  return (
    <div>
      <Hero />
      <SolutionTabs />
      <CompanyTravels />
      <Register />
      <Features />
      <Brands />
      <Reviews />
      <PopularDestinations />
      <HomeFooter />
    </div>
  );
};

export { Home };
