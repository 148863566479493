import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HomesTypes } from '@share/constants';
import NoResults from '@assets/images/no_results.png';
import NoHotelResults from '@share/assets/images/no_hotel_results.png';
import NoVacationResults from '@assets/images/no_vacation_results.png';
import { GoTo } from '@components';
import { IAccount } from '@share/common-types';

import './style.scss';

interface IProps {
  label: string;
  unitsSearch?: boolean;
  customerServiceNumber?: string;
  filterHomes?: string;
  location?: string;
  isVacationRentals?: boolean;
  account?: IAccount;
}

export class EmptySearchMessage extends React.Component<IProps, null> {
  render(): React.ReactNode {
    const { label, account, isVacationRentals, unitsSearch = false, customerServiceNumber, filterHomes = '', location = '' } = this.props;
    let newLabel = '';
    let title = 'no.result.oops';
    let msg = "change.search.criteria";
    let image = NoResults;

    const isCapitalVacations = account?.isCapitalVacations;

    if (label !== null) {
      newLabel = label;
    } else if (filterHomes === HomesTypes.VacationRentalOnly) {
      newLabel = 'no.vacation.filter';
      image = NoVacationResults;
    } else if (filterHomes === HomesTypes.HotelOnly) {
      newLabel = 'no.hotel.filter';
      image = NoHotelResults;
    } else {

      
      if (isCapitalVacations) {
        newLabel = 'no.all.filter.capital';
        title = 'no.result.title.capital';
        msg = 'no.search.capital';
      } else {
        newLabel = 'no.all.filter';
        title = 'no.result.title';
        msg = 'no.search';
      }
      image = NoHotelResults;
    }

    return (
      <div className="no-result-msg">
        <img src={image} alt="" />
        <div className="no-result-msg__info text-center">
          <h3><strong><FormattedMessage id={title} /></strong></h3>
          <p className="no-result-msg__title">
            {filterHomes === HomesTypes.All ? <FormattedMessage id={newLabel} values={{ location: location }} /> : <FormattedMessage id={newLabel} />}
          </p>
          <p className="no-result-msg__text">
            <FormattedMessage id={msg} />
            {unitsSearch ? (
              <p className="no-result-msg__text-support-phone">
                <FormattedMessage
                  id="or.call.your.agent.at"
                  values={{ customerServiceNumber: customerServiceNumber }}
                />
              </p>
            ) : null}
          </p>

          {!isCapitalVacations ? (<GoTo isCondo={false} isVacationRentals={isVacationRentals} />) : null}
        </div>
      </div>
    );
  }
}
