import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom';
import { Tabs, Spin } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { Reservations, MyTravelHistory, MyProfile, ChangePassword, MyQuotes, MySideKickRewards, MyAccount } from '@components';
import { ILoginState, IMenuState, LoginType } from '@share/store/slices';
import { RootState, isQuotesFromPath, getAccountUsernameFromPath, isTestDriveManagerFromPath, getPreferenceFromAccount, isReservationsFromPath } from '@share/utils';
import { Loading } from '@components';
import { Routes } from '@share/constants';
import { LoadingSise } from '@common-types';
import { ISideKickStatus } from '@share/common-types';

import HotelBackground from '@assets/images/hotel/bg-hotels.png';

import './style.scss';
import { isEmpty } from 'lodash';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps { }

function AccountPageComponent(props: IProps) {
  const { history, menuStore, loginStore } = props;
  const { account } = loginStore;
  const { items } = menuStore;

  const [activeKey, setActiveKey] = useState('1');

  const styleAccount: any = { backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 100%, rgb(255, 255, 255)), url(${HotelBackground})` };
  const isParentSidekick = items?.sideKickStatus === ISideKickStatus.PARENT;

  const pathName = history.location.pathname;

  useEffect(() => {
    handleChange();
  }, []);

  useEffect(() => {
    handleChange();
  }, [pathName]);

  const handleChange = () => {
    if (!account || account.type !== LoginType.PrivateWithToken) {
      history.push(Routes.NotAuthorized);
    }

    const isQuotes = isQuotesFromPath(history);
    const isTestDriveManager = isTestDriveManagerFromPath(history);
    const isReservations = isReservationsFromPath(history);
    
    if (isQuotes) {
      setActiveKey('6');
    } else if (isTestDriveManager) {
      setActiveKey('7');
    } else if (isReservations) {
      setActiveKey('3');
    } else {
      setActiveKey('1');
    }
  }

  const handleTab = (key: string) => {
    setActiveKey(key);
    const account = getAccountUsernameFromPath(props.history)
    if (key !== '6') {
      history.push(`/${account}${Routes.RsiAccount}${location.search}`);
    } else {
      history.push(`/${account}${Routes.RsiQuotes}`);
    }
  }

  const isPrivateWithToken = account?.type === LoginType.PrivateWithToken;
  const privateTokenFromUrl = account?.privateTokenFromUrl;

  if (isPrivateWithToken && privateTokenFromUrl) {
    return (
      <div className="account-page" style={{ flex: '1' }}>
        {account?.isCapitalVacations ? (
          <div className="account-page__background-wrapper">
            <div className="account-page__account-background" style={styleAccount}></div>
            <div className="account-page__wrapper">
              <h1 className="account-page__header"><FormattedMessage id={account?.isCapitalVacationsCondoRes ? 'reservation.search.title' : 'my.account.title'} /></h1>
            </div>
          </div>) : null}

        <Spin spinning={props.loginStore.loading} indicator={<Loading size={LoadingSise.Small} />} >
          <div className="container account-page__account-container" style={{ paddingTop: '20px' }}>
            {(account?.isCapitalVacations && !account?.isCapitalVacationsCondoRes) ? (
              <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={handleTab}>
                <Tabs.TabPane tab={<FormattedMessage id={'my.account.title'} />} key="1">
                  <MyAccount />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id={'reservation.search.title'} />} key="3">
                  <Reservations />
                </Tabs.TabPane>
              </Tabs>) : (
              <Reservations />)}
          </div>
        </Spin>
      </div>
    );
  }

  const isRSITemplate = account?.isRSITemplate;
  const showMyAccount = items?.showMyAccount;

  if (isRSITemplate && !showMyAccount) {
    history.push({
      pathname: Routes.CustomError,
      state: {
        data: 'UNAUTHORIZED',
      },
    });

    return null;
  }

  return (
    <div className="account-page">
      <div className="account-page__background-wrapper">
        <div className="account-page__account-background" style={styleAccount}></div>
        <div className="account-page__wrapper">
          <h1 className="account-page__header"><FormattedMessage id="my.account.title" /></h1>
        </div>
      </div>

      <Spin spinning={props.loginStore.loading} indicator={<Loading size={LoadingSise.Small} />} >
        <div className="container account-page__account-container">
          <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={handleTab}>

            <Tabs.TabPane tab={<FormattedMessage id="profile" />} key="1">
              <MyProfile />
            </Tabs.TabPane>

            {!items?.isMLM ? (
              <Tabs.TabPane tab={<FormattedMessage id="manage.password" />} key="2">
                <ChangePassword />
              </Tabs.TabPane>) : null}

            <Tabs.TabPane tab={<FormattedMessage id={items?.isMLM ? 'reservation.search.title' : 'my.active.hotel.reservations'} />} key="3">
              <Reservations />
            </Tabs.TabPane>

            {!items?.isMLM ? (
              <Tabs.TabPane tab={<FormattedMessage id="my.travel.history" />} key="5">
                <MyTravelHistory />
              </Tabs.TabPane>) : null}

            {!items?.isMLM ? (
              <Tabs.TabPane tab={<FormattedMessage id="my.quotes" />} key="6">
                <MyQuotes />
              </Tabs.TabPane>) : null}

            {isParentSidekick ? (

              <Tabs.TabPane tab={!isEmpty(items?.sideKickName) ? items.sideKickName : <FormattedMessage id={!items?.isMLM ? 'my.sidekicks' : 'test.drive.manager'} />} key="7">
                <MySideKickRewards />
              </Tabs.TabPane>) : null}

          </Tabs>
        </div>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const AccountPage = connect(mapStateToProps)(withRouter(injectIntl(AccountPageComponent)));