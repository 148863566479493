import {
  IAdminHotelBookings,
  IFilterDateRange,
  HotelBookingStatusEnum,
  IHotelAutocompleteResult,
  MatchedByEnum,
} from '@common-types';
import { RootState } from '@share/utils';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { IAdminHotelBookingsState, TAdminHotelBookingsSorter } from './slice';

export const selectAdminHotelBookingsState = (state: RootState): IAdminHotelBookingsState =>
  state.adminHotelBookingsStore;

export const selectAdminHotelBookingsList = (state: RootState): IAdminHotelBookings[] =>
  selectAdminHotelBookingsState(state).bookings;

export const selectAdminHotelBookingsStatus = (state: RootState): HotelBookingStatusEnum =>
  selectAdminHotelBookingsState(state).status;

export const selectAdminHotelBookingsLoading = (state: RootState): boolean =>
  selectAdminHotelBookingsState(state).loading;

export const selectAdminHotelBookingsTotalCount = (state: RootState): number =>
  selectAdminHotelBookingsState(state).orderCount;

export const selectAdminHotelBookingsPagination = (state: RootState): TablePaginationConfig =>
  selectAdminHotelBookingsState(state).pagination;

export const selectAdminHotelBookingsSort = (state: RootState): TAdminHotelBookingsSorter =>
  selectAdminHotelBookingsState(state).sort;

export const selectAdminHotelBookingsCheckInDateRangeFilter = (
  state: RootState,
): IFilterDateRange => selectAdminHotelBookingsState(state).checkInDateRangeFilter;

export const selectAdminHotelBookingsCheckOutDateRangeFilter = (
  state: RootState,
): IFilterDateRange => selectAdminHotelBookingsState(state).checkOutDateRangeFilter;

export const selectAdminHotelBookingsBookedOnDateRangeFilter = (
  state: RootState,
): IFilterDateRange => selectAdminHotelBookingsState(state).bookedOnDateRangeFilter;

export const selectAdminHotelBookingsStatusesFilters = (state: RootState): string[] =>
  selectAdminHotelBookingsState(state).statusesFilters;

export const selectAdminHotelBookingsSupplierFilters = (state: RootState): string[] =>
  selectAdminHotelBookingsState(state).supplierFilters;

export const selectAdminHotelBookingsSearch = (
  state: RootState,
): Pick<
  IAdminHotelBookingsState,
  'autocompleteResults' | 'search' | 'isSearchLoading' | 'selectedSearchItem'
> => {
  const { autocompleteResults, search, isSearchLoading, selectedSearchItem } =
    selectAdminHotelBookingsState(state);

  return {
    autocompleteResults,
    search,
    isSearchLoading,
    selectedSearchItem,
  };
};

type TMatchedByPropsMap = {
  [key: string]: (val: IHotelAutocompleteResult) => unknown;
};

const matchedByPropsMap: TMatchedByPropsMap = {
  [MatchedByEnum.MemberId]: (val: IHotelAutocompleteResult) => val?.member?.id,
  [MatchedByEnum.MemberName]: (val: IHotelAutocompleteResult) => `${val?.member?.fullName}`,
  [MatchedByEnum.GuestName]: (val: IHotelAutocompleteResult) => `${val?.guest?.fullName}`,
};

export const selectAdminHotelBookingsAutocompleteResults = (
  state: RootState,
): IHotelAutocompleteResult[] => {
  const { autocompleteResults } = selectAdminHotelBookingsState(state);

  return autocompleteResults.map((autocomplete) => {
    const matchedBy = autocomplete.matchedBy;
    const autocompleteField = matchedByPropsMap[matchedBy]?.(autocomplete);

    if (autocompleteField) {
      return { ...autocomplete, [matchedBy]: autocompleteField };
    }

    return autocomplete;
  });
};
