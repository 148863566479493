import React, { RefObject } from 'react';

import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { RootState } from '@share/utils';
import { insuranceActions, InsuranceSelection, IReviewBookState, setCard } from '@store/slices';
import { ICardBooking } from '@common-types';
import { CreditCardsList, PaymentMethodBase } from '@components';
import { IUserCard } from '@share/common-types';

import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setCard: (card: ICardBooking) => void;
  setSelection: (selection: InsuranceSelection) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isRequired: boolean;
  display: boolean;

  onChangeCountryState: () => void;
}

interface IState {
  refreshPayment: boolean;
}

class PaymentMethodComponent extends React.Component<IProps, IState> {
  state: IState = { refreshPayment: false };

  handleCardSelect = async (selectedCard: IUserCard) => {
    const { setCard, setSelection } = this.props;
    await setCard(selectedCard);
    this.setState({ refreshPayment:  true });
    setSelection(null);
  }

  render(): React.ReactNode {
    const { isRequired, formRef, display, reviewBookStore, loginStore, setCard, onChangeCountryState } = this.props;
    const { refreshPayment } = this.state;
    const { card, errorsField, loadingBooking } = reviewBookStore;
    const { account } = loginStore;

  
    return (
      <div>
        <CreditCardsList
          selectedCardId={card?.id}
          onSelectCard={this.handleCardSelect}
          selectable
          hideIfNoData
          isCheckout
        />

        <PaymentMethodBase
          form={null}
          formRef={formRef}
          display={display}
          isRequired={isRequired}

          refreshData={refreshPayment}
          onDataRefreshed={() => this.setState({ refreshPayment:  false })}
          onChangeCountryState={onChangeCountryState}

          card={card}
          errorsField={errorsField}
          loading={loadingBooking}
          setCard={(card) => setCard(card)}

          displaySave={account?.isPrivateTokenAccountType || account?.isPrivateLoginAccountType}

          disableInsurance={!account?.enableInsurance}
        />
      </div>);
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setCard,
  setSelection: insuranceActions.setSelection,
};

export const PaymentMethod = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PaymentMethodComponent));
