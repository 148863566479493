export interface INavigation {
  adminLevel: number;
  baseUrl: string;
  brochureUrl: string;
  customerServiceNumber?: string;
  header: INavigationItem[];
  essentials: INavigationItem[];
  email: string;
  firstName: string;
  footer: INavigationItem[];
  menu: INavigationItem[];
  isActive: boolean;
  lastName: string;
  leisureCredits: string;
  homeUrl: string;
  logoUrl: string;
  logoutUrl: string;
  main: INavigationItem[];
  memberType: string;
  organizationId: number;
  organizationName: string;
  phone: string;
  phone2?: string;
  promo?: boolean;
  rsiId: number;
  siteId: number;
  useful: INavigationItem[];
  user: INavigationItem[];
  userName: string;
  renewalAgency: string;
  pointsSettings: IPointsSettings;

  sideKickStatus: ISideKickStatus;
  
  isMLM?: boolean;

  showHomeUrl?: boolean;
  showBrochure?: boolean;
  showFooter?: boolean;
  showResources?: boolean;
  showContactInfo?: boolean;
  showMyAccount?: boolean;
  
  sideKickName?: string,
  sideKickPointsName?: string,
  sideKickLogoUrl?: string,
  sideKickShortName?: string,
  sideKickUrl?: string,

}

export enum ISideKickStatus {
  NA = 'NA',
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}

export interface IPointsSettings {
  condo: IPointsSettingsItem;
  hotel: IPointsSettingsItem;
}

export interface IPointsSettingsItem {
  isPointsControlEnabled: boolean;
  isRemainingBalanceEnabled: boolean;
}

export interface INavigationItem {
  isPopup?: boolean;
  title: string;
  url?: string;
  childItems?: INavigationItem[];
  imageUrl?: string;
  description?: string;
}

