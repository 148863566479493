import React from 'react';
import Highlighter from 'react-highlight-words';
import debounce from 'lodash/debounce';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Select, Spin, AutoComplete } from 'antd';
import { SelectValue, RefSelectProps } from 'antd/lib/select';
import { OptionGroupData } from 'rc-select/lib/interface';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { ILocationsState, getLocations, locationActions, ILoginState } from '@share/store/slices';
import { getPreferenceFromAccount, RootState } from '@share/utils';
import { ENTER_KEY, HOTEL_RECENT_SEARCHES_LABEL } from '@share/constants';
import { LocationsTypes, ILocation, SEARCH_ICONS_COLOR_FIELD_KEY } from '@share/common-types';

import { HotelSvg, PlaneSvg, ClearSvg, LocationGreySvg } from '@share/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faShip } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  locationsStore: ILocationsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getLocations: (search: string, selectedLocation: ILocation | undefined, onlyCities: boolean) => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (locationLabel: string) => void;
  setLocations: (locations: ILocation[]) => void;
  setLoading: () => void;
  clearError: () => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps {
  isExperiences?: boolean;
  disable?: boolean;
}

interface IState {
  isFocused: boolean;
  isDropdownOpen: boolean;
}

const zero = 0;
const MIN_SEARCH_LENGTH = 3;
const LIST_HEIGHT = 500;
const SEARCH_DEBOUNCE = 500;
const { Option, OptGroup } = Select;

export class LocationsPickerComponent extends React.Component<IProps, IState> {
  selectRef: React.RefObject<RefSelectProps> = React.createRef();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    isFocused: false,
    isDropdownOpen: false,
  };
  lastSearch = '';
  lastSelectedLocation: ILocation;

  onFocus = () => {
    const { isFocused } = this.state;

    if (!isFocused) {
      this.onSearch(this.props.locationsStore.selectedLocationLabel || '');
    }

    this.setState({ isFocused: true });
  };

  onChange = (value: SelectValue, option: OptionGroupData) => {
    const {
      setSelectLocation,
      setSelectLocationLabel,
      setLocations,
      clearError,
      locationsStore,
    } = this.props;
    const { locations } = locationsStore;
    const location = locations.find(({ code }) => code === (option?.key as number));

    this.setState({ isDropdownOpen: false, isFocused: true });
    setSelectLocationLabel(value as string);
    setSelectLocation(location);
    
    setLocations([]);
    this.lastSelectedLocation = location;

    if (option?.key) {
      clearError();
    }
  };

  debouncedSearch = debounce((search: string) => {
    this.props.getLocations(search, this.props.locationsStore.selectedLocation, this.props.isExperiences);
  }, SEARCH_DEBOUNCE);

  onSearch = (search: string) => {
    this.lastSearch = search;

    const recentSearchesStorage = localStorage.getItem(HOTEL_RECENT_SEARCHES_LABEL);
    const recentSearchesList = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
    let recentSearchesLocations: any[] = [];
    if (recentSearchesList?.length) {
      const recentLocations = recentSearchesList
                                  .map((search: any) => ({ ...search.location, realType: search.location.type, type: LocationsTypes.Recents }))
                                  .filter((location: any, index: number, array: any[]) => array.findIndex(v => (v.code === location.code)) === index)
                                  .slice(0, 5);
      recentSearchesLocations = [...recentLocations];
    }

    this.setState({ isDropdownOpen: !!search || (search === '' && recentSearchesLocations?.length > 0) });

    if (search.length >= MIN_SEARCH_LENGTH) {
      this.props.setLoading();
      this.debouncedSearch(search);
    } else {
      this.props.setLocations([...recentSearchesLocations]);
    }
  };

  focusSelect = () => {
    if (this.selectRef && this.selectRef.current) {
      this.selectRef.current.focus();
    }
  };

  getGroupedLocation = (): {
    destinations: ILocation[];
    hotels: ILocation[];
    airports: ILocation[];
    ports: ILocation[];
    recents: ILocation[];
  } => {
    const { isExperiences, locationsStore } = this.props;
    const { locations } = locationsStore;
    const destinations: ILocation[] = [];
    const hotels: ILocation[] = [];
    const airports: ILocation[] = [];
    const ports: ILocation[] = [];
    const recents: ILocation[] = [];

    locations.forEach((location: ILocation) => {
      switch (location.type) {
        case LocationsTypes.Airport: {
          airports.push(location);
          break;
        }
        case LocationsTypes.Destination: {
          destinations.push(location);
          break;
        }
        case LocationsTypes.Hotel: {
          hotels.push(location);
          break;
        }
        case LocationsTypes.Port: {
          ports.push(location);
          break;
        }
        case LocationsTypes.Recents: {
          if (!isExperiences || [LocationsTypes.Destination, LocationsTypes.Recents].includes(location.realType)) {
            recents.push({ ...location, type: location.realType });
          }
          break;
        }
        default:
          return null;
      }
    });

    return {
      destinations,
      hotels,
      airports,
      ports,
      recents
    };
  };

  getOptionGroup = (
    array: ILocation[],
    label: string,
    icon: React.ReactNode,
  ): React.ReactNode | null => {
    return array.length ? (
      <OptGroup label={<FormattedMessage id={label} />}>
        {array.map((location: ILocation) => {
          const inner = (
            <>
              <span className="locations-dropdown__option-icon">{icon}</span>
              <span className="locations-dropdown__location-name">
                <Highlighter
                  highlightClassName="bold-text"
                  searchWords={[this.lastSearch.trim()]}
                  autoEscape={true}
                  textToHighlight={location.name}
                />
              </span>
            </>
          );

          return location.type === LocationsTypes.Hotel ? (
            <Option
              key={location.code}
              value={location.name}
              className="locations-dropdown__option hotel"
            >
              <div className="locations-dropdown__option-wrapper">{inner}</div>
            </Option>
          ) : (
            <Option key={location.code} value={location.name} className="locations-dropdown__option">
              {inner}
            </Option>
          );
        })}
      </OptGroup>
    ) : null;
  };

  getDropdown = (menu: React.ReactElement): React.ReactElement => {
    const { locations, loading, selectedLocationLabel } = this.props.locationsStore;
    const isNothingFound =
      loading || !!locations.length || selectedLocationLabel?.length < MIN_SEARCH_LENGTH;

    return (
      <div className="locations-dropdown">
        {menu}
        {isNothingFound && (
          <div className="locations-dropdown__footer">
            <FormattedMessage id="search.more.label" />
          </div>
        )}
      </div>
    );
  };

  handleClickOutside = (event: MouseEvent) => {
    if (
      (this.wrapperRef && !this.wrapperRef.current.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('locations-picker__fixed-bg')
    ) {
      this.setState({ isFocused: false, isDropdownOpen: false });
      this.props.setLocations([]);
      this.lastSearch = '';
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getPopupContainer = () => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  getNotFoundContent = (): React.ReactNode => {
    const { locationsStore } = this.props;
    const { selectedLocationLabel, loading } = locationsStore;
    let message: React.ReactNode;

    if (selectedLocationLabel?.length < MIN_SEARCH_LENGTH) {
      message = <FormattedMessage id="search.min.symbols" />;
    } else if (loading) {
      message = <FormattedMessage id="searching" />;
    } else {
      message = <FormattedMessage id="nothing.found" />;
    }

    return (
      <div className="locations-dropdown__nothing-found">
        {message}
      </div>);
  };

  onClick = (event: React.MouseEvent<HTMLElement>) => {
    const {
      locationsStore,
      setLocations,
      setSelectLocationLabel,
      setSelectLocation,
      clearError,
    } = this.props;
    const { selectedLocationLabel, locations } = locationsStore;
    const { isFocused, isDropdownOpen } = this.state;

    if (
      selectedLocationLabel &&
      isFocused &&
      !isDropdownOpen &&
      event.target &&
      (event.target as HTMLElement).classList.contains('ant-select-selection-search-input')
    ) {
      this.onSearch(selectedLocationLabel);
    } else if (
      (event.target as HTMLElement).classList.contains('ant-select-item-option-selected') ||
      (event.target as HTMLElement).closest('.ant-select-item-option-selected')
    ) {
      if (this.lastSelectedLocation) {
        this.setState({ isDropdownOpen: false, isFocused: true });
        setLocations([]);
      } else {
        const location = locations.find(
          ({ name }) => name.toLowerCase() === selectedLocationLabel.toLowerCase(),
        );

        if (location) {
          this.setState({ isDropdownOpen: false, isFocused: true });
          setSelectLocationLabel(location.name);
          setSelectLocation(location);
          setLocations([]);
          this.lastSelectedLocation = location;
          clearError();
        }
      }
    }
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    const { isDropdownOpen, isFocused } = this.state;
    const {
      locationsStore,
      setSelectLocationLabel,
      setSelectLocation,
      setLocations,
      clearError,
    } = this.props;
    const { locations } = locationsStore;

    if (
      e.code === ENTER_KEY &&
      isDropdownOpen &&
      isFocused &&
      locations.length &&
      !this.lastSelectedLocation
    ) {
      const item = locations[zero];

      this.setState({ isDropdownOpen: false, isFocused: true });
      setSelectLocationLabel(item.name);
      setSelectLocation(item);
      setLocations([]);
      this.lastSelectedLocation = item;

      if (item) {
        clearError();
      }
    }
  };

  render(): React.ReactNode {
    const { locationsStore, loginStore, disable } = this.props;
    const { selectedLocationLabel, loading, error } = locationsStore;
    const { isFocused, isDropdownOpen } = this.state;
    const { airports, destinations, hotels, ports, recents } = this.getGroupedLocation();
    
    const account = loginStore?.account;
    const color: any = account ? getPreferenceFromAccount(account, SEARCH_ICONS_COLOR_FIELD_KEY) : null;
    const styleColor = !isEmpty(color)? { color } : {};  
    
    return (
      <div className={`locations-picker ${!!error ? 'error' : ''}`} ref={this.wrapperRef}>
        <div
          className={`locations-picker__wrapper ${isFocused ? 'selected' : ''}`}
          onClick={this.focusSelect}
        >
          
          <div className="locations-picker__left">
            <div className="locations-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
          </div>
          <div className="locations-picker__right">
            <div className="locations-picker__label">
              <FormattedMessage id="location" />
            </div>
            <div className="locations-picker__input">
              <AutoComplete
                onKeyDown={this.onKeyDown}
                virtual={false}
                showSearch={true}
                placeholder={<FormattedMessage id="location.input.placeholder" />}
                onChange={this.onChange}
                onSearch={this.onSearch}
                onFocus={this.onFocus}
                showArrow={false}
                ref={this.selectRef}
                open={isDropdownOpen}
                defaultActiveFirstOption={false}
                filterOption={() => true}
                autoClearSearchValue={false}
                dropdownRender={this.getDropdown}
                getPopupContainer={this.getPopupContainer}
                listHeight={LIST_HEIGHT}
                allowClear={true}
                value={selectedLocationLabel}
                notFoundContent={this.getNotFoundContent()}
                onClick={this.onClick}
                disabled={disable}
                clearIcon={
                  <span className="locations-picker__clear">
                    {loading && isDropdownOpen ? <Spin /> : <ClearSvg />}
                  </span>
                }
              >

                {selectedLocationLabel?.length < MIN_SEARCH_LENGTH ? (
                  <Option key={MIN_SEARCH_LENGTH} value={MIN_SEARCH_LENGTH} disabled={true} className="locations-dropdown__option">
                    <FormattedMessage id="search.min.symbols" />
                  </Option>) : null}
                  
                {this.getOptionGroup(destinations, 'destinations', <LocationGreySvg />)}
                {this.getOptionGroup(hotels, 'hotels', <HotelSvg />)}
                {this.getOptionGroup(airports, 'airports', <PlaneSvg />)}
                {this.getOptionGroup(ports, 'ports', <FontAwesomeIcon icon={faShip} style={{ color: '#D2D2D2' }} />)}
                {this.getOptionGroup(recents, 'recents', <LocationGreySvg />)}

              </AutoComplete>
            </div>
          </div>
        </div>
        {isDropdownOpen && <div className="locations-picker__fixed-bg" />}
        {error && <div className="locations-picker__error">{error}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    locationsStore: state.locationsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getLocations: (search: string, selectedLocation: ILocation | undefined, onlyCities: boolean) => {
    dispatch(getLocations(search, selectedLocation, onlyCities));
  },
  setLocations: (locations: ILocation[]) => {
    dispatch(locationActions.setLocations(locations));
  },
  setSelectLocation: (location: ILocation) => {
    dispatch(locationActions.setSelectLocation(location));
  },
  setSelectLocationLabel: (locationLabel: string) => {
    dispatch(locationActions.setSelectLocationLabel(locationLabel));
  },
  setLoading: () => {
    dispatch(locationActions.setLoading(true));
  },
  clearError: () => {
    dispatch(locationActions.setError(''));
  },
});

export const LocationsPicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsPickerComponent);
