import React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { ILoginState, datesActions, locationActions, roomsActions } from '@share/store/slices';
import { ICondoGuestsState, ICondoStrictDatesState, ICondosLocationPickerState, resetCondosFull } from '@share/store/slices';
import { GetHomeParams, RootState, VACATIONS_PAGE } from '@share/utils';
import { ILocation, IRoom } from '@share/common-types';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import SandBeach from '@assets/images/sand-beach.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  condosLocationPickerStore: ICondosLocationPickerState;
  condoGuestsStore: ICondoGuestsState;
  condoStrictDatesStore: ICondoStrictDatesState;
}

interface IMapDispatchToProps {
  resetCondosFull: () => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (locationLabel: string) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  setRooms: (rooms: IRoom[]) => void;

  applyDates: () => void;
  applyRooms: () => void;
  applyLocation: () => void;
}

interface IState {
  loading: boolean;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isCondo: boolean;
  isVacationRentals?: boolean;
}

class GoToComponent extends React.Component<IProps, IState> {
  state: IState = { loading: false };

  hangleGoto = () => {
    const { isCondo, isVacationRentals, loginStore, history } = this.props;
    const { account } = loginStore;
    
    const homeParams = GetHomeParams(account);

    if (account?.isDisableHomes) {
      if (!isEmpty(account?.disableHomesRedirect)) {
        window.location.replace(account?.disableHomesRedirect);
      } else {
        history.push(Routes.NotAuthorized);
      }
    } else {
      if (!isCondo) {
        if (isVacationRentals) {
          this.setState({ loading: true }, () => history.push(`/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`));
        } else {
          this.setState({ loading: true }, () => history.push(`/${account?.name}${Routes.Search}${homeParams}`));
        }
      } else {
        this.setState({ loading: true }, () => history.push(`/${account?.name}${Routes.CondoSearch}${homeParams}`));
      }
    }
  }

  render(): React.ReactNode {
    const { isCondo } = this.props;

    const searchMessage = isCondo ? 'search.hotel' : 'search.condos';
    const searchTitle = isCondo ? 'no.result.search.over' : 'no.result.search.over.condos';

    return (
      <div className="go-to">
        <img src={SandBeach} alt="" />
        <div className="message container-fluid">
          <div className="row">
            <div className="col-lg-9">
              <div className="text">
                <label><FormattedMessage id="no.result.not.finding" /></label>
                <span><FormattedMessage id={searchTitle} /></span>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="button" onClick={this.hangleGoto}>
                <BlueButton>
                  <FormattedMessage id={searchMessage} />
                </BlueButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    condosLocationPickerStore: state.condosLocationPickerStore,
    condoGuestsStore: state.condoGuestsStore,
    condoStrictDatesStore: state.condoStrictDatesStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  resetCondosFull: () => {
    dispatch(resetCondosFull());
  },
  setSelectLocation: (location: ILocation) => {
    dispatch(locationActions.setSelectLocation(location));
  },
  setSelectLocationLabel: (locationLabel: string) => {
    dispatch(locationActions.setSelectLocationLabel(locationLabel));
  },
  setDatesSelected: (dates: { startDate: string; endDate: string }) => {
    dispatch(datesActions.setDatesSelected(dates));
  },
  setRooms: (rooms: IRoom[]) => {
    dispatch(roomsActions.setRooms(rooms));
  },
  applyDates: () => {
    dispatch(datesActions.applyDates());
  },
  applyRooms: () => {
    dispatch(roomsActions.applyRooms());
  },
  applyLocation: () => {
    dispatch(locationActions.applyLocation());
  },

});

export const GoTo = connect(mapStateToProps, mapDispatchToProps)(withRouter(GoToComponent));
