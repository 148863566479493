export * from './common';
export * from './filters';
export * from './hotel';
export * from './country-code';
export * from './booking';
export * from './states';
export * from './condo';
export * from './admin-page';
export * from './concierge';
export * from './resources';
export * from './image-details';
export * from './pages';
export * from './columns';

export const MEMBER_STATUS = {
    ALL:'all',
    ACTIVE:'active',
    INACTIVE:'inactive',
    ACTIVESTATE:'Active'
}

export const BOOKING_STATUS = {
    CONFIRMED:'Confirmed',
    CANCELLED:'Cancelled',
    ERROR: 'Error',
    UNCONFIRMED: 'Unconfirmed'

}

export const QUERY_PARAMS = {
    LOCATION: 'location',
    ROOMS: 'rooms',
    DATE:'dates',
    SESSION_KEY:"sessionKey"

}
