export const useLocalStorage = () => {
    const setValue = <T>(key: string, value: T) => {
      window.localStorage.setItem(key, JSON.stringify(value));
    };
    const getValue = <T>(key: string): T | null => {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    };
  
    return { setValue, getValue };
  };
  