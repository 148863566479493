
import React, { useEffect, useState } from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Form, Input } from 'antd';

import { updateMyProfile, IMyProfileState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { IMyProfile } from '@share/common-types';

import './style.scss';

interface IMapStateToProps {
  myProfileStore: IMyProfileState;
}

interface IMapDispatchToProps {
  update: (data: IMyProfile) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function UpdateProfileComponent(props: IProps) {

  const [ formValid, setFormValid ] = useState(true);
  const [ newValues, setNewValues ] = useState(null);

  const [ form ] = Form.useForm();

  const { myProfileStore, intl, update } = props;
  const { loading, result } = myProfileStore;

  useEffect(() => {
    form.setFieldsValue({
      ...newValues || result
    });

    if (!loading && result.profile) {
      form.validateFields();
    }
  }, [result.profile, loading]);

  const onFinish = (values: any) => {
    setNewValues(values);
    update(values.profile);
  };

  const setButtonDisabled = () => {
    setFormValid(form.getFieldsError().some((field) => field.errors.length > 0))
  };
  
  return (
    <div className="form-profile">
      <Form name="nest-messages" form={form} onFieldsChange={setButtonDisabled} onFinish={onFinish}>
        <Form.Item>
          <div className="row">
            <div className="col-sm-5">
              <Form.Item
                name={['profile', 'firstName']}
                label={intl.formatMessage({ id: 'name' })}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'first.name' }).toUpperCase()} />
              </Form.Item>
            </div>

            <div className="col-sm-2">
              <Form.Item
                name={['profile', 'middleName']}
                rules={[{ required: false }]}
              >
                <Input placeholder="MI" />
              </Form.Item>
            </div>

            <div className="col-sm-5">
              <Form.Item
                name={['profile', 'lastName']}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'last.name' }).toUpperCase()} />
              </Form.Item>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="row">
            <div className="col-sm-12">
              <Form.Item
                name={['profile', 'email']}
                label={intl.formatMessage({ id: 'email' })}
                rules={[{ required: true, message: 'Please enter value', type: 'email' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'email' }).toUpperCase()} />
              </Form.Item>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="row">
            <div className="col-sm-6">
              <Form.Item
                name={['profile', 'phone1']}
                label={intl.formatMessage({ id: 'cell.phone' })}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'cell.phone' }).toUpperCase()} />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item
                name={['profile', 'phone2']}
                label={intl.formatMessage({ id: 'alt.phone' })}
                rules={[{ required: false }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'alt.phone' }).toUpperCase()} />
              </Form.Item>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="row">
            <div className="col-sm-6">
              <Form.Item
                name={['profile', 'address1']}
                label={intl.formatMessage({ id: 'address' })}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'address' }).toUpperCase()} />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item
                name={['profile', 'address2']}
                rules={[{ required: false }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'address' }).toUpperCase()} />
              </Form.Item>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="row">
            <div className="col-sm-3">
              <Form.Item
                name={['profile', 'city']}
                label=""
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'city' }).toUpperCase()} />
              </Form.Item>
            </div>
            <div className="col-sm-4">
              <Form.Item
                name={['profile', 'state']}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'state' }).toUpperCase()} />
              </Form.Item>
            </div>
            <div className="col-sm-3">
              <Form.Item
                name={['profile', 'country']}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'country' }).toUpperCase()} />
              </Form.Item>
            </div>
            <div className="col-sm-2">
              <Form.Item
                name={['profile', 'postalCode']}
                rules={[{ required: true, message: 'Please enter value' }]}
              >
                <Input placeholder={intl.formatMessage({ id: 'postal-code' }).toUpperCase()} />
              </Form.Item>
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="row">
            <div className="col-sm-12">
              <Form.Item>
                <Button block size='large' type="primary" htmlType="submit" disabled={formValid}>
                  <FormattedMessage id="save.changes" />
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div >
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    myProfileStore: state.myProfileStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  update: (data: IMyProfile) => {
    dispatch(updateMyProfile(data));
  },
});

const ComponentIntl = injectIntl(UpdateProfileComponent);

export const UpdateProfile = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
