import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { ErrorAlertSvg } from '@assets';
import './style.scss';
import { connect } from 'react-redux';
import { VACATIONS_PAGE, getAccountUsernameFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {
  isVacationRentals?: boolean;
}

class HotelDetailsServerErrorComponent extends React.Component<IProps> {
  refreshPage = (): void => {
    window.location.reload();
  };

  render(): React.ReactNode {
    const accountName = getAccountUsernameFromPath(this.props.history);
    return (
      <div className="hotel-details-server-error">
        <div className="hotel-details-server-error__wrapper">
          <div className="hotel-details-server-error__icon">
            <ErrorAlertSvg />
          </div>
          <div className="hotel-details-server-error__header">
            <FormattedMessage id="problem.with.our.system" />
          </div>
          <div className="hotel-details-server-error__description">
            <FormattedMessage id="we.are.working.on.the.problem.try" />{' '}
            <a onClick={this.refreshPage}>
              <FormattedMessage id="refresh" />
            </a>{' '}
            <FormattedMessage id="this.page" />
          </div>
          <div className="hotel-details-server-error__footer">
            <Link to={this.props.isVacationRentals ? `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}` : `/${accountName}${Routes.Search}${location.search}`}>
              <BlueButton>
                <FormattedMessage id="back" />
              </BlueButton>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export const HotelDetailsServerError = connect()(withRouter(HotelDetailsServerErrorComponent));
